import React from "react";
import './USGConfirmationBox.css'
import { Button } from "@material-ui/core";
import { IRootState } from "../../store";
import { connect } from "react-redux";




export interface IUSGConfirmationBoxProps {
    language: string,
    duplicatedLatestSchedulingGroup: any,
    tutorInfos: any,
    ageGroups: any,
    genres: any,
    classroomLists: any,
    openingHourLists: any,

    handleDecline: () => void,
    handleProceedWithUSG: () => void,
    handleProceedWithNewUSG: () => void

}

export interface IUSGConfirmationBoxState {

}

class IUSGConfirmationBox extends React.Component<IUSGConfirmationBoxProps, IUSGConfirmationBoxState> {
    constructor(props: IUSGConfirmationBoxProps) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div className="baseAreaOfUSGConfirmationBox">
                <div className="attentionSignRow">
                    <div className="attentionSign">
                    {this.props.language !== "eng" ? "注意 !" : "Attention!"}
                    </div>
                </div>
                <div className="USGTitle">
                <h5>{this.props.language !== "eng" ? "找到相關一直沿用的課堂排程組！" : "Related Unique Schedule Group Found !"}</h5>
                </div>

                <div className="USGDisplayRow">
                    <div className="USGContent">{this.props.openingHourLists.site_key}</div>
                    <div className="USGContent">{(this.props.classroomLists.find((classroom: any) => classroom.id === this.props.duplicatedLatestSchedulingGroup.classroom_id)).classroom}</div>
                    <div className="USGContent">{calcDayOfWeek(this.props.duplicatedLatestSchedulingGroup.day_of_week)} {this.props.duplicatedLatestSchedulingGroup.start_time} - {this.props.duplicatedLatestSchedulingGroup.end_time}</div>
                    <div className="USGContent">{(this.props.tutorInfos.find((tutor: any) => tutor.tutor_id === this.props.duplicatedLatestSchedulingGroup.tutor_id)).name_eng}</div>
                    <div className="USGContent">{(this.props.ageGroups.find((ageGroup: any) => ageGroup.id === this.props.duplicatedLatestSchedulingGroup.age_group_id)).age_group}</div>
                    <div className="USGContent">{(this.props.genres.find((genre: any) => genre.id === this.props.duplicatedLatestSchedulingGroup.genre_id)).genre}</div>


                </div>
                <div className="USGBtnRow">
                    <Button id="newUSGBtn" onClick={this.props.handleProceedWithNewUSG}>
                    <div>{this.props.language !== "eng" ? "使用全新課堂排程組" : "Create New Schedule Group"}</div>
                        <div>{this.props.language !== "eng" ? "(以上課堂排程組往後將不再適用)" : "(The Above Schedule Group Will Permanently Closed)"}</div>
                    </Button>
                    <Button id="useUSGBtn" onClick={this.props.handleProceedWithUSG}>
                    <div>{this.props.language !== "eng" ? "使用以上課堂排程組" : "Reuse Schedule Group"}</div>
                        <div>{this.props.language !== "eng" ? "(如課堂詳情吻合，建議使用)" : "(Recommend If Class Details Matching)"}</div>
                        </Button>
                        <Button id="declineAddRoomOccupancyBtn" onClick={this.props.handleDecline}>{this.props.language !== "eng" ? "取消變更課堂" : "Decline Schedule Change"}</Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        language: state.auth.language,
        tutorInfos: state.schedule.tutorInfos,
        ageGroups: state.schedule.ageGroupList,
        genres: state.schedule.genreTypeList,
        classroomLists: state.schedule.classroomList,
        openingHourLists: state.schedule.openingTimeList
    }
}

// const mapDispatchToProps = (dispatch: ThunkDispatch) => {
//     return {
//         handleHide: async (data:any) => dispatch(await hideThisTutorInfos(data))
//     }
// }
export default connect(mapStateToProps, {})(IUSGConfirmationBox);
// export default IUSGConfirmationBox;

function calcDayOfWeek(day: number) {
    let dayOfWeekDictionary = [
        'SUN',
        'MON',
        'TUE',
        'WED',
        'THU',
        'FRI',
        'SAT',
    ]
    return dayOfWeekDictionary[day]
}