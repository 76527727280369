import { IInvoiceGeneratorState } from "./state";
import { IInvoiceGeneratorActions } from "./actions";

const initialState = {
    allTargetedPackageDetails: []

}

export const invoiceGeneratorReducer = (state: IInvoiceGeneratorState = initialState, actions:IInvoiceGeneratorActions) => {
    switch(actions.type) {
        case "ALL_TARGETED_PACKAGE_DETAILS_LOADED":
            return {
                ...state,
                allTargetedPackageDetails: actions.packageDetails
            }
        default:
            return state;
    }
}