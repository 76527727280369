

export function allTermsLoaded(terms:any) {
    return {
        type: "ALL_TERMS_LOADED" as "ALL_TERMS_LOADED",
        terms
    }
}
export function termCreated () {
    return {
        type: "TERM_CREATED" as "TERM_CREATED"
    }
}
export function termUpdated () {
    return {
        type:"TERM_UPDATED" as "TERM_UPDATED"
    }
}
export function termActiveSettingModified () {
    return {
        type: "TERM_ACTIVE_SETTING_MODIFIED" as "TERM_ACTIVE_SETTING_MODIFIED"
    }
}


type TermsManageActionsCreators = typeof allTermsLoaded |
                                    typeof termCreated |
                                    typeof termUpdated |
                                    typeof termActiveSettingModified;
                                            

export type ITermsManageActions = ReturnType<TermsManageActionsCreators>