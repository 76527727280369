import { ThunkDispatch } from '../../store';
import { allSitesClassroomsLoaded, classroomUpdated, classroomCreated, classroomDeleted, classroomDeleteFailed, siteUpdated, siteCreated } from './actions';

export async function loadSitesClassroomsData() {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/loadAllSitesClassrooms`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });

    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    } else {

        return async (dispatch: ThunkDispatch) => {

            dispatch(allSitesClassroomsLoaded(result.allSites, result.allClassrooms));

        }
    }
}

export async function updateClassroomDetails(classroomDetails: any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/editSelectedClassroomDetails`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            classroomDetails: classroomDetails
        })
    });

    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(classroomUpdated());
        }
    }
}

export async function createClassroomDetails(classroomDetails: any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/addNewClassroomDetails`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            classroomDetails: classroomDetails
        })
    });

    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(classroomCreated());
        }
    }
}

export async function delClassroomDetails(classroomID: number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/delClassroomDetails`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            classroomID: classroomID
        })
    });

    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        if(result.result){
            return async (dispatch: ThunkDispatch) => {
                dispatch(classroomDeleted());
            }
        } else {
            return async (dispatch: ThunkDispatch) => {
                dispatch(classroomDeleteFailed());
            }
        }
    }
}

export async function updateSiteDetails(siteDetails: any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/editSelectedSiteDetails`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            siteDetails: siteDetails
        })
    });

    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(siteUpdated());
        }
    }
}

export async function creteSiteDetails(siteDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/addNewSiteDetails`,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            newSiteDetails: siteDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(siteCreated());
        }
    }
}
