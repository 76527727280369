import { IAttendanceManageState } from './state';
import { IAttendanceManageActions } from './actions';

const initialState = {
    allAttendances: [],
    loading: true
}

export const attendanceManageReducer = (state: IAttendanceManageState = initialState, actions: IAttendanceManageActions) => {
    switch (actions.type) {

        case "ALL_ATTENDANCES_LOADED":
            return {
                ...state,
                loading: false,
                allAttendances: actions.attendances
            }
        case "ATTENDANCE_CREATED":
            return {
                ...state,
                loading: true

            }
        case "ATTENDANCE_UPDATED":
            return {
                ...state,
                loading: true

            }
        case "ATTENDANCE_ACTIVE_SETTING_MODIFIED":
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }
}