import React from "react";
import { connect } from "react-redux";
import './Schedules7Days.css'
import moment from "moment";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import CloseIcon from '@material-ui/icons/Close';
import { ThunkDispatch, IRootState } from "../store";
import { clone7DaysClassOccupancies, loadOccupancyOf7DaysSelected } from "./thunks";
import CircularProgress from '@material-ui/core/CircularProgress';
import MassTokenEditManage from "../Systems/MassTokenEditManage/MassTokenEditManage";

export interface ISchedule7DaysProps {
    handleShowingScale: () => void,
    cloneLast7DaysClassOccupancies: (date: any, siteID: number) => void,
    handleReload7DaySchedules: (loadDate: string, showingSiteID: number) => void,
    handleGoLastWeek: () => void,
    handleGoNextWeek: () => void,
    dateSelected: any,
    classroomList: any,
    openingHourList: any,
    occupancyOf7Days: any,
    roomRentingPrivateCustomerListOf7Days: any,
    tutorListsOf7Days: any,
    tutorInfos: any,
    genreTypeLists: any,
    ageGroupLists: any,
    cloneWeekID: any,
    showingSiteID: number,
    loading: boolean
    language: string,
}
export interface ISchedule7DaysState {
    showInformationBoxOn7Days: boolean
}

class Schedule7Days extends React.Component<ISchedule7DaysProps, ISchedule7DaysState> {
    constructor(props: ISchedule7DaysProps) {
        super(props);
        this.state = {
            showInformationBoxOn7Days: true
        }
    }
    private handleCloneOccupancies = async () => {
        let confirmation = await window.confirm('你確定要從上星期覆製課堂嗎？')
        if (confirmation) {
            let cloningTargetDate = moment(this.props.dateSelected).subtract(7, 'day').format('YYYY-MM-DD');
            await this.props.cloneLast7DaysClassOccupancies(cloningTargetDate, this.props.showingSiteID);
        }
        await this.props.handleReload7DaySchedules(this.props.dateSelected, this.props.showingSiteID);
    }
    private handleHideInfoBox = async () => {
        await this.setState({
            showInformationBoxOn7Days: !this.state.showInformationBoxOn7Days
        })
    }


    render() {
        let clientToken = localStorage.getItem('token');
        let tokenPayload: any = {}
        if (clientToken) {
            tokenPayload = parseJwt(clientToken)
        }

        let startOfWeek = moment(`${this.props.dateSelected}`).startOf('week').add(1, 'day');
        let endOfWeek = moment(`${this.props.dateSelected}`).endOf('week').add(1, 'day');

        if(this.props.dateSelected === moment(`${this.props.dateSelected}`,'YYYY-MM-DD').startOf('week').format('YYYY-MM-DD')){
            startOfWeek = moment(`${this.props.dateSelected}`,'YYYY-MM-DD').subtract(1,'day').startOf('week').add(1,'day');
            endOfWeek = moment(`${this.props.dateSelected}`,'YYYY-MM-DD').subtract(1,'day').endOf('week').add(1,'day');
        }
        
        let dateShouldBeDisplayed = [];
        let tableWidth = vw(91.5)
        let startOfDay: any = "23:00";
        let endOfDay: any = "00:00"
        let topTimePix = 38;
        let topTimeLinePix = 49;
        let tableHeight = 0;
        let timeListingArray = [];


        for (let i = startOfWeek; i <= endOfWeek; i = i.add(1, 'day')) {
            dateShouldBeDisplayed.push(i.format('YYYY-MM-DD'))
        }

        if (this.props.openingHourList.length !== 0) {
            for (let i = 1; i < 7; i++) {
                let startTimeCount = (startOfDay.split(':'))[0];
                let endTimeCount = (endOfDay.split(':'))[0];
                let thisOpenHour = (this.props.openingHourList[i].split('-'))[0];
                let thisCloseHour = (this.props.openingHourList[i].split('-'))[1];
                let thisOpeningCount = (thisOpenHour.split(':'))[0];
                let thisClosingCount = (thisCloseHour.split(':'))[0];
                if (parseInt(thisOpeningCount) < parseInt(startTimeCount)) {
                    startOfDay = thisOpenHour;
                }
                if (parseInt(thisClosingCount) > parseInt(endTimeCount)) {
                    endOfDay = thisCloseHour;
                }
            }
            console.log(startOfDay)
            console.log(endOfDay)
            // let todayOpeningTime = this.props.openingHourList[moment(`${this.state.dateShowing}`).isoWeekday()];
            // startOfDay = moment(`${this.state.dateShowing}${todayOpeningTime.split('-')[0]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
            // startOfDay = "09:00"


            // let hourCounting = moment(`${this.state.dateShowing}${todayOpeningTime.split('-')[0]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
            // let endOfDay = moment(`${this.state.dateShowing}${todayOpeningTime.split('-')[1]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
            //  endOfDay = "22:00"
            // let openingHours = moment.duration(endOfDay.diff(startOfDay)).asMinutes()

            for (let i = moment(`${this.props.dateSelected}${startOfDay}+0800`, 'YYYY-MM-DD HH:mmZZZZ'); i <= moment(`${this.props.dateSelected}${endOfDay}+0800`, 'YYYY-MM-DD HH:mmZZZZ'); i = i.add(30, 'minutes')) {
                if (i.format('mm') === '30') {
                    tableHeight = topTimeLinePix + 1
                    topTimeLinePix += 50;
                } else {
                    tableHeight = topTimeLinePix + 1
                    let smallTimeArr = [i.format('HH:mm'), topTimePix, topTimeLinePix];
                    topTimePix += 100;
                    topTimeLinePix += 50;
                    timeListingArray.push(smallTimeArr);
                }
            }
            return (
                <div className="row">
                    {this.state.showInformationBoxOn7Days ?
                        <div className="dateInformationBox">

                            <div className="lastWeekBtn" onClick={this.props.handleGoLastWeek}>
                                <div className="btnAlignerOn7Days">
                                    <ChevronLeftIcon id="lastWeekArrowOn7Days" />
                                </div>
                            </div>
                            <div className="infoBoxMainContentArea">
                                <div className="closeInfoBoxOn7Days" onClick={this.handleHideInfoBox}>
                                    <CloseIcon />
                                    <div>{this.props.language !== "eng" ? "隱藏" : "HIDE"}</div>
                                </div>
                                <div className="monthDisplayRow">
                                    {moment(`${this.props.dateSelected}`).format('MMMM')}
                                </div>
                                <div className="dateDisplayRow">
                                    {
                                        dateShouldBeDisplayed.map((date: any) =>
                                            <div className="dateSquare">{moment(date).format('DD')}</div>
                                        )
                                    }
                                </div>
                                <div className="displayScaleOn7DaysSchedule">
                                    <div className="showWeekDayBtn01On7Days active">{this.props.language !== "eng" ? "7日" : "7 Day"}</div>
                                    <div className="showWeekDayBtn02On7Days" onClick={this.props.handleShowingScale}>{this.props.language !== "eng" ? "1日" : "1 Day"}</div>
                                </div>
                                {
                                    tokenPayload.post !== 'BOSS' ?
                                        tokenPayload.siteID !== this.props.showingSiteID ?
                                            <div className="clone7DaysBtnDisabled">
                                                <DoubleArrowIcon />
                                                <div className="cloneBtnContent">{this.props.language !== "eng" ? "從上週覆製課堂" : "Duplicate Schedule From Previous Week"}</div>
                                            </div>
                                            :
                                            this.props.occupancyOf7Days.length === 0 ?
                                                <div className="clone7DaysBtn" onClick={this.handleCloneOccupancies}>
                                                    <DoubleArrowIcon />
                                                    <div className="cloneBtnContent">{this.props.language !== "eng" ? "從上週覆製課堂" : "Duplicate Schedule From Previous Week"}</div>
                                                </div>
                                                :
                                                <div className="clone7DaysBtnDisabled">
                                                    <DoubleArrowIcon />
                                                    <div className="cloneBtnContent">{this.props.language !== "eng" ? "從上週覆製課堂" : "Duplicate Schedule From Previous Week"}</div>
                                                </div>
                                        :
                                        this.props.occupancyOf7Days.length === 0 ?
                                            <div className="clone7DaysBtn" onClick={this.handleCloneOccupancies}>
                                                <DoubleArrowIcon />
                                                <div className="cloneBtnContent">{this.props.language !== "eng" ? "從上週覆製課堂" : "Duplicate Schedule From Previous Week"}</div>
                                            </div>
                                            :
                                            <div className="clone7DaysBtnDisabled">
                                                <DoubleArrowIcon />
                                                <div className="cloneBtnContent">{this.props.language !== "eng" ? "從上週覆製課堂" : "Duplicate Schedule From Previous Week"}</div>
                                            </div>

                                }
                            </div>
                            <div className="lastWeekBtn" onClick={this.props.handleGoNextWeek}>
                                <div className="btnAlignerOn7Days">
                                    <ChevronRightIcon />
                                </div>
                            </div>
                        </div>
                        :
                        <div className="infoBoxIconOn7Days" onClick={this.handleHideInfoBox}>
                            <MenuOpenIcon id="infoBoxPopIcon" />
                        </div>
                    }
                    <div className="col-12 ">
                        <div className="row">
                            <div className="col-12 cssBase04On7Days">
                                <div id="tableHeadContainer">
                                    <ul id="tableBase">
                                        <li className="scheduleTimeCol">
                                            <div className="tableHeads" >{this.props.language !== "eng" ? "時間" : "Time"}</div>
                                        </li>
                                        {
                                            dateShouldBeDisplayed.map((date: any) =>

                                                <li className="scheduleHeadCol" style={{ width: (tableWidth / dateShouldBeDisplayed.length) }}>
                                                    <div className="tableHeads">
                                                        {moment(date).format('MMM DD (ddd)')}
                                                    </div>

                                                </li>)
                                        }
                                    </ul>
                                </div>


                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 contentRowFormattingOn7daysSchedules">

                                <div className="tableBodyOn7days">
                                    {
                                        this.props.loading ?
                                            <div className="loadingContainerRow">
                                                <div className="inlineDisplay">
                                                    <div className="loadingContainer">
                                                        <CircularProgress id="loadingCircle" />
                                                        <div className="loadingLabel">Loading ...</div>
                                                    </div>
                                                </div>
                                            </div>
                                            :

                                            <div>
                                                <ul className="mainBodyTime" style={{ height: tableHeight }}>
                                                    <li className="timeBlock">
                                                        {timeListingArray.length === 0 ? null :
                                                            timeListingArray.map((time: any[]) =>
                                                                <div>
                                                                    <div className="time" style={{ top: time[1], right: 27 }}>{time[0]}</div>
                                                                    <div className="timeLine" style={{ top: time[2], right: 0 }}></div>
                                                                </div>

                                                            )
                                                        }
                                                    </li>
                                                </ul>
                                                <div className="mainBodyBG" style={{ height: tableHeight, width: tableWidth }}>
                                                    {
                                                        dateShouldBeDisplayed.map((date: any) =>
                                                            <ul className="dayScheduleCol" style={{ width: (tableWidth / 7) - 0.25 }}>
                                                                {
                                                                    this.props.classroomList.map((classroom: any) =>
                                                                        <li className="roomColOn7Days" style={{ width: (tableWidth / 7 / this.props.classroomList.length) - 0.5 }}>
                                                                            <div className="classroomIndicatorBlockOn7Days">
                                                                                <div className="classroomNameOn7Days">{classroom.classroom}</div>
                                                                            </div>
                                                                            {
                                                                                this.props.occupancyOf7Days.map((occupancy: any) =>
                                                                                    moment(occupancy.start_time).format('YYYY-MM-DD') !== moment(date).format('YYYY-MM-DD') ? null :
                                                                                        occupancy.classroom_id !== classroom.id ? null :
                                                                                            moment.duration(moment(`${occupancy.start_time}`).diff(moment(`${date} ${startOfDay}+0800`, 'YYYY-MM-DD HH:mmZZZZ'))).asMinutes() === 0 ?
                                                                                                /* THE BELOW CASES ARE FOR TOP ROW OCCUPANCY */
                                                                                                occupancy.room_rental && this.props.tutorListsOf7Days ?
                                                                                                    <div className="classBlock" style={{ top: 50, width: (tableWidth / this.props.classroomList.length / 7) - 1, height: (((moment.duration(moment(`${occupancy.end_time}`).diff(moment(`${occupancy.start_time}`))).asMinutes()) / 15) * 25) - 1 }}>
                                                                                                        <div className="classCardOn7Days" style={{ background: '#000000' }} >
                                                                                                            <div className="classInfoContainer">
                                                                                                                <div className="classInfoTimeOn7Days"> <div>{moment(occupancy.start_time).format('HH:mm')}</div> <div> - </div> <div>{moment(occupancy.end_time).format('HH:mm')}</div> </div>
                                                                                                                {/* <div className="classInfoGenreOn7Days">租</div> */}
                                                                                                                {/* <div className="classInfoCustomer" style={{ color: '#000000' }}>顧客 : {(this.props.roomRentingPrivateCustomerListOf7Days.find((customer: any) => customer.id === occupancy.customer_id)).name_eng}</div> */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    occupancy.private_class ?
                                                                                                        <div className="classBlock" style={{ top: 50, width: (tableWidth / this.props.classroomList.length / 7) - 1, height: (((moment.duration(moment(`${occupancy.end_time}`).diff(moment(`${occupancy.start_time}`))).asMinutes()) / 15) * 25) - 1 }}>
                                                                                                            <div className="classCardOn7Days" style={{ background: '#555555' }} >
                                                                                                                <div className="classInfoContainer">
                                                                                                                    <div className="classInfoTimeOn7Days"> <div>{moment(occupancy.start_time).format('HH:mm')}</div> <div> - </div> <div>{moment(occupancy.end_time).format('HH:mm')}</div> </div>
                                                                                                                    {/* <div className="classInfoGenreOn7Days">私</div> */}
                                                                                                                    {/* <div className="classInfoCustomer" style={{ color: '#555555' }}>
                                                                                                導師：{(this.props.tutorInfos.find((tutor: any) => tutor.tutor_id === ((this.props.tutorListsOf7Days.find((tutorList: any) => tutorList.room_occupancy_detail_id === occupancy.id)).tutor_id))).name_eng} | 顧客：{(this.props.roomRentingPrivateCustomerListOf7Days.find((customer: any) => customer.id === occupancy.customer_id)).name_eng}
                                                                                            </div> */}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div className="classBlock" style={{ top: 50, width: (tableWidth / this.props.classroomList.length / 7) - 1, height: (((moment.duration(moment(`${occupancy.end_time}`).diff(moment(`${occupancy.start_time}`))).asMinutes()) / 15) * 25) - 1 }}>
                                                                                                            <div className="classCardOn7Days" style={{ background: (this.props.genreTypeLists.find((genre: any) => genre.id === occupancy.genre_id)).represent_color }} >
                                                                                                                <div className="classInfoContainer">
                                                                                                                    <div className="classInfoTimeOn7Days"> <div>{moment(occupancy.start_time).format('HH:mm')}</div> <div> - </div> <div>{moment(occupancy.end_time).format('HH:mm')}</div> </div>
                                                                                                                    {/* <div className="classInfoGenre">{(this.props.genreTypeLists.find((genre: any) => genre.id === occupancy.genre_id)).genre}</div> */}
                                                                                                                    {/* <div className="classInfoAge">{(this.props.ageGroupLists.find((ageGroup: any) => ageGroup.id === occupancy.age_group_id)).age_group}</div> */}
                                                                                                                    {/* <div className="classInfoTutor" style={{ color: (this.props.genreTypeLists.find((genre: any) => genre.id === occupancy.genre_id)).represent_color }}>{(this.props.tutorInfos.find((tutor: any) => tutor.tutor_id === ((this.props.tutorListsOf7Days.find((tutorList: any) => tutorList.room_occupancy_detail_id === occupancy.id)).tutor_id))).name_eng}</div> */}
                                                                                                                    {/* <div className="classInfoStudentCount" style={{ color: (this.props.genreTypeLists.find((genre: any) => genre.id === occupancy.genre_id)).represent_color }}>7/10</div> */}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                :
                                                                                                /* THE BELOW CASES ARE FOR NON-TOP ROW OCCUPANCY */
                                                                                                !this.props.tutorListsOf7Days ? null :
                                                                                                    occupancy.room_rental ?
                                                                                                        <div className="classBlock" style={{ top: (((moment.duration(moment(`${occupancy.start_time}`).diff(moment(`${date} ${startOfDay}+0800`, 'YYYY-MM-DD HH:mmZZZZ'))).asMinutes()) / 15)) * 25 + 50, width: (tableWidth / this.props.classroomList.length / 7) - 1, height: (((moment.duration(moment(`${occupancy.end_time}`).diff(moment(`${occupancy.start_time}`))).asMinutes()) / 15) * 25) - 1 }}>
                                                                                                            <div className="classCardOn7Days" style={{ background: '#000000' }} >
                                                                                                                <div className="classInfoContainer">
                                                                                                                    <div className="classInfoTimeOn7Days"> <div>{moment(occupancy.start_time).format('HH:mm')}</div> <div> - </div> <div>{moment(occupancy.end_time).format('HH:mm')}</div> </div>
                                                                                                                    {/* <div className="classInfoGenreOn7Days">租</div> */}
                                                                                                                    {/* <div className="classInfoCustomer" style={{ color: '#000000' }}>顧客：{(this.props.roomRentingPrivateCustomerListOf7Days.find((customer: any) => customer.id === occupancy.customer_id)).name_eng}</div> */}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                        occupancy.private_class ?
                                                                                                            <div className="classBlock" style={{ top: (((moment.duration(moment(`${occupancy.start_time}`).diff(moment(`${date} ${startOfDay}+0800`, 'YYYY-MM-DD HH:mmZZZZ'))).asMinutes()) / 15)) * 25 + 50, width: (tableWidth / this.props.classroomList.length / 7) - 1, height: (((moment.duration(moment(`${occupancy.end_time}`).diff(moment(`${occupancy.start_time}`))).asMinutes()) / 15) * 25) - 1 }}>
                                                                                                                <div className="classCardOn7Days" style={{ background: '#555555' }} >
                                                                                                                    <div className="classInfoContainer">
                                                                                                                        <div className="classInfoTimeOn7Days"> <div>{moment(occupancy.start_time).format('HH:mm')}</div> <div> - </div> <div>{moment(occupancy.end_time).format('HH:mm')}</div> </div>
                                                                                                                        {/* <div className="classInfoGenreOn7Days">私</div> */}
                                                                                                                        {/* <div className="classInfoCustomer" style={{ color: '#555555' }}>
                                                                                                                    導師：{(this.props.tutorInfos.find((tutor: any) => tutor.tutor_id === ((this.props.tutorListsOf7Days.find((tutorList: any) => tutorList.room_occupancy_detail_id === occupancy.id)).tutor_id))).name_eng} | 顧客：{(this.props.roomRentingPrivateCustomerListOf7Days.find((customer: any) => customer.id === occupancy.customer_id)).name_eng}
                                                                                                                </div> */}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            this.props.genreTypeLists.length === 0 ? null :
                                                                                                                this.props.tutorListsOf7Days.length === 0 ? null :
                                                                                                                    <div className="classBlock" style={{ top: (((moment.duration(moment(`${occupancy.start_time}`).diff(moment(`${date} ${startOfDay}+0800`, 'YYYY-MM-DD HH:mmZZZZ'))).asMinutes()) / 15)) * 25 + 50, width: (tableWidth / this.props.classroomList.length / 7) - 1, height: (((moment.duration(moment(`${occupancy.end_time}`).diff(moment(`${occupancy.start_time}`))).asMinutes()) / 15) * 25) - 1 }}>
                                                                                                                        <div className="classCardOn7Days" style={{ background: (this.props.genreTypeLists.find((genre: any) => genre.id === occupancy.genre_id)).represent_color }} >
                                                                                                                            <div className="classInfoContainer">
                                                                                                                                <div className="classInfoTimeOn7Days"> <div>{moment(occupancy.start_time).format('HH:mm')}</div> <div> - </div> <div>{moment(occupancy.end_time).format('HH:mm')}</div> </div>
                                                                                                                                {/* <div className="classInfoGenre">{(this.props.genreTypeLists.find((genre: any) => genre.id === occupancy.genre_id)).genre}</div> */}
                                                                                                                                {/* <div className="classInfoAge">{(this.props.ageGroupLists.find((ageGroup: any) => ageGroup.id === occupancy.age_group_id)).age_group}</div> */}
                                                                                                                                {/* <div className="classInfoTutor" style={{ color: (this.props.genreTypeLists.find((genre: any) => genre.id === occupancy.genre_id)).represent_color }}>{(this.props.tutorInfos.find((tutor: any) => tutor.tutor_id === ((this.props.tutorListsOf7Days.find((tutorList: any) => tutorList.room_occupancy_detail_id === occupancy.id)).tutor_id))).name_eng}</div> */}
                                                                                                                                {/* <div className="classInfoStudentCount" style={{ color: (this.props.genreTypeLists.find((genre: any) => genre.id === occupancy.genre_id)).represent_color }}>7/10</div> */}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>



                                                                                )
                                                                            }
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            )
        }
    }
}
const mapStateToProps = (state: IRootState) => {
    return {
        language: state.auth.language
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        cloneLast7DaysClassOccupancies: async (date: any, siteID: number) => dispatch(await clone7DaysClassOccupancies(date, siteID)),
        handleReload7DaySchedules: async (loadDate: string, showingSiteID: number) => dispatch(await loadOccupancyOf7DaysSelected(loadDate, showingSiteID))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule7Days)
// export default Schedule7Days

function vw(v: any) {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return (v * w) / 100;
}

function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};
// let clientToken = localStorage.getItem('token');
// let tokenPayload: any = {}
// if (clientToken) {
//     tokenPayload = parseJwt(clientToken)
// }