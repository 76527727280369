import React from 'react';

const NoMatch =(props:{})=>{
    return(<h1>
        Unauthorized. Please Login with correct username and password.
    </h1>
    )
}


export default NoMatch;