import { ThunkDispatch } from "../../store";
import {  occupancyParticipationRelatedInfoLoaded} from "./actions";

export async function loadOccupancyRelatedParticipationInformation (roomOccupancyID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customerParticipation/loadCustomerParticipationOfSelectedRoomOccupancy?roomOccupancyID=${roomOccupancyID}`, {
        method: 'GET',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,
        }
    });

    let result = await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
            return async (dispatch:ThunkDispatch) => {
                dispatch(occupancyParticipationRelatedInfoLoaded(result));
            }
    }
}