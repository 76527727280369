import { ThunkDispatch, IRootState } from "./store";
import { connect } from "react-redux";
import React from "react";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import "./Layout.css"
import FaceIcon from '@material-ui/icons/Face';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { logout, changeLanguage} from "./Auth/thunks";
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';
import Home from "./Home/Home";
import { PrivateRoute } from "./PrivateRouter";
import { Link } from 'react-router-dom';
import Tutors from "./Tutors/Tutors";
import Schedules from "./Schedule/Schedules";
import { tutorPageInit } from "./Tutors/thunks";
import { schedulePageInit } from "./Schedule/thunks";
import Customers from "./Customers/Customers";
import Systems from './Systems/Systems';
import Accountings from './Accountings/Accountings';
import Stocks from './Stocks/Stocks';








interface ILayoutProps {
    username: string | null,
    level: string | null,
    location: string,
    language: string,
    logout: () => void,
    tutorNav: () => void,
    changeLanguage: (language:string) => void
    // scheduleNav: () => void,
}


class Layout extends React.Component<ILayoutProps, {}> {
    constructor(props: ILayoutProps) {
        super(props);
    }

    private handleLogout = async () => {
        this.props.logout();
    }

    private handleTutorNavClick = async () => {
        this.props.tutorNav();
    }

    private handleScheduleNavClick = async () => {
        // this.props.scheduleNav();
    }

    private handleChangeLanguageClick = async() => {
        if(this.props.language !== "eng"){
            this.props.changeLanguage("eng")
        }else {
            this.props.changeLanguage("chi")
        }
    }



    render() {
        // let accountUserName = this.props.username
        function parseJwt(token: string) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        };
        let clientToken = localStorage.getItem('token');
        let tokenPayload: any = {}
        if (clientToken) {
            tokenPayload = parseJwt(clientToken)
        }
        return (
            <Provider store={store}>
                <ConnectedRouter history={history}>

                    <div className="container-fluid">
                        <div className="row appHeader">
                            <div className="col-10">
                                <div className="navigationBar">
                                    {this.props.location == "/layout/Home" ?
                                        <Link className="navButton currentLocation" to="/layout/Home">
                                            <h4 className="navHeading">
                                                {this.props.language !== "eng" ? "主頁" : "Dashboard"}
                                        </h4>

                                        </Link>
                                        :
                                        <Link className="navButton nonCurrentLocation" to="/layout/Home">
                                            <h4 className="navHeading">
                                            {this.props.language !== "eng" ? "主頁" : "Dashboard"}
                                        </h4>
                                        </Link>
                                    }

                                    {this.props.location == "/layout/Tutors" ?
                                        <Link className="navButton currentLocation" to="/layout/Tutors" onClick={this.handleTutorNavClick}>
                                            <h4 className="navHeading">
                                            {this.props.language !== "eng" ? "導師" : "Tutors"}
                                                </h4>
                                        </Link>

                                        :
                                        <Link className="navButton nonCurrentLocation" to="/layout/Tutors" onClick={this.handleTutorNavClick}>
                                            <h4 className="navHeading">
                                            {this.props.language !== "eng" ? "導師" : "Tutors"}
                                        </h4>
                                        </Link>
                                    }
                                    {this.props.location == "/layout/Schedules" ?
                                        <Link className="navButton currentLocation" to="/layout/Schedules" onClick={this.handleScheduleNavClick}>
                                            <h4 className="navHeading">
                                            {this.props.language !== "eng" ? "時間表" : "Schedule"}
                                                </h4>
                                        </Link>

                                        :
                                        <Link className="navButton nonCurrentLocation" to="/layout/Schedules" onClick={this.handleScheduleNavClick}>
                                            <h4 className="navHeading">
                                            {this.props.language !== "eng" ? "時間表" : "Schedule"}
                                        </h4>
                                        </Link>
                                    }
                                    {this.props.location == "/layout/Customers" ?
                                        <Link className="navButton currentLocation" to="/layout/Customers" >
                                            <h4 className="navHeading">
                                            {this.props.language !== "eng" ? "顧客" : "Customers"}
                                                </h4>
                                        </Link>

                                        :
                                        <Link className="navButton nonCurrentLocation" to="/layout/Customers" >
                                            <h4 className="navHeading">
                                            {this.props.language !== "eng" ? "顧客" : "Customers"}
                                        </h4>
                                        </Link>
                                    }
                                    {
                                        this.props.location == "/layout/Accountings" ?
                                            <Link className="navButton currentLocation" to="/layout/Accountings" >
                                                <h4 className="navHeading">
                                                {this.props.language !== "eng" ? "會計" : "Accounting"}
                                                </h4>
                                            </Link>

                                            :
                                            <Link className="navButton nonCurrentLocation" to="/layout/Accountings" >
                                                <h4 className="navHeading">
                                                {this.props.language !== "eng" ? "會計" : "Accounting"}
                                        </h4>
                                            </Link>
                                    }
                                    {
                                        this.props.location == "/layout/Stocks" ?
                                            <Link className="navButton currentLocation" to="/layout/Stocks" >
                                                <h4 className="navHeading">
                                                {this.props.language !== "eng" ? "存倉" : "Stocking"}
                                                </h4>
                                            </Link>

                                            :
                                            <Link className="navButton nonCurrentLocation" to="/layout/Stocks" >
                                                <h4 className="navHeading">
                                                {this.props.language !== "eng" ? "存倉" : "Stocking"}
                                        </h4>
                                            </Link>
                                    }
                                    {this.props.location == "/layout/Systems" ?
                                        <Link className="navButton currentLocation" to="/layout/Systems" >
                                            <h4 className="navHeading">
                                            {this.props.language !== "eng" ? "系統" : "System"}
                                                </h4>
                                        </Link>

                                        :
                                        <Link className="navButton nonCurrentLocation" to="/layout/Systems" >
                                            <h4 className="navHeading">
                                            {this.props.language !== "eng" ? "系統" : "System"}
                                        </h4>
                                        </Link>
                                    }
                                </div>

                            </div>
                            <div className="col-2 ">
                                <div className="row h-100" >
                                    <div className="col-12 aligner">
                                        <FaceIcon style={{ fontSize: 45, color: 'white' }} />
                                        <div className="accountInfo">
                                            <div className="whiteHeading nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {tokenPayload.username}
                                            </div>
                                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                {/* <button className="dropdown-item" >Profile</button> */}
                                                
                                                <button className="dropdown-item" onClick={this.handleChangeLanguageClick} >
                                                {this.props.language !== "eng" ? "Set Language: English" : "設定語言: 中文"}
                                                    </button>
                                            
                                                <div className="dropdown-divider"></div>
                                                <button className="dropdown-item" onClick={this.handleLogout}>
                                                {this.props.language !== "eng" ? "登出" : "Logout"}

                                                    </button>
                                            </div>
                                            <br></br>
                                            <div className="whiteSubHeading">
                                                {tokenPayload.post}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row bgArea ">
                            <PrivateRoute path='/layout/Home' component={Home} />
                            <PrivateRoute path='/layout/Tutors' component={Tutors} />
                            <PrivateRoute path='/layout/Schedules' component={Schedules} />
                            <PrivateRoute path='/layout/Customers' component={Customers} />
                            <PrivateRoute path='/layout/Systems' component={Systems} />
                            <PrivateRoute path='/layout/Accountings' component={Accountings} />
                            <PrivateRoute path='/layout/Stocks' component={Stocks} />
                        </div>
                    </div>
                </ConnectedRouter>
            </Provider>
        )
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        logout: () => dispatch(logout()),
        tutorNav: async () => dispatch(await tutorPageInit()),
        changeLanguage: async (language:string) => dispatch(await changeLanguage(language))
        // scheduleNav: async () => dispatch(await schedulePageInit())
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        // username: state.auth.accountUsername,
        // level: state.auth.level
        location: state.router.location.pathname,
        language: state.auth.language
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)