import { IHomeState } from "./state";
import { IHomeActions } from "./actions";
import { statement } from "@babel/template";

const initialState = {
    graphData: "",
    graphDisplay: true
}

export const homeReducer = (state: IHomeState = initialState, actions: IHomeActions) => {
    switch (actions.type) {
        case "HOME_PAGE_DATA_LOADED":
            return {
                ...state,
                graphData: actions.graphData,
                graphDisplay: true
            }
        case "GRAPH_REFRESHING":
            return {
                ...state,
                graphDisplay:true
            }


        default:
            return state
    }
}