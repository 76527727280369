import React from "react";
import { IRootState, ThunkDispatch } from "../store";
import { connect } from "react-redux";
import './TutorIndividualBox.css'
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import TutorEditBox from '../TutorEditBox/TutorEditBox'
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import PublishIcon from '@material-ui/icons/Publish';
import RedoIcon from '@material-ui/icons/Redo';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { hideThisTutorInfos } from '../TutorIndividualBox/thunks'
import moment from "moment";
import { tutorPageInit } from "../Tutors/thunks";


export interface ITutorIndividualBoxProps {
    language: string,
    allTutorInfo: any,
    selectedTutorId: number,
    employmentStatusBank: any,
    handleExit: () => void,
    handleHide: (data: any) => void,
    handleRefresh: () => void

}

export interface ITutorIndividualBoxState {
    showEditBox: boolean
}

class TutorIndividualBox extends React.Component<ITutorIndividualBoxProps, ITutorIndividualBoxState> {
    constructor(props: ITutorIndividualBoxProps) {
        super(props);
        this.state = {
            showEditBox: false
        }
    }

    private handleEditExit = async () => {
        this.setState({
            showEditBox: !this.state.showEditBox
        });
        this.props.handleRefresh();

    }

    private handleHideClick = async () => {
        let thisTutorInfo = this.props.allTutorInfo.find((tutor: any) => tutor.tutor_id === this.props.selectedTutorId)
        console.log(thisTutorInfo)
        let data = {
            userId: thisTutorInfo.user_id
        }
        this.props.handleHide(data);
        this.props.handleExit();
    }



    render() {
        let thisTutorInfo = this.props.allTutorInfo.find((tutor: any) => tutor.tutor_id == this.props.selectedTutorId);

        return (
            <div>
                {this.state.showEditBox ? <TutorEditBox handleEditExit={this.handleEditExit} selectedTutorId={this.props.selectedTutorId} /> : null}
                <div className="displayInArea">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 appHeader">
                                <div className="navHeading">{this.props.language !== "eng" ? "導師資料" : "Tutor Info"}</div>
                                <CloseIcon id="exitBtn" onClick={this.props.handleExit} />
                                <EditIcon id="editBtn" onClick={this.handleEditExit} />
                            </div>
                        </div>
                        <div className="row scrollAreaAll">
                            <div className="col-lg-2 col-md-12">

                                <div className="row">
                                    <div className="col-12 sectionAligner">
                                        <div className="sectionHeader">
                                            {this.props.language !== "eng" ? "導師資料" : "Tutor Info"}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 sectionAligner">
                                        <div className="tutorPicOutLine">
                                            <div className="tutorPic"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 sectionAligner">
                                        <div className="sectionSubHeading">
                                            {thisTutorInfo.name_eng}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 sectionAligner">
                                        <div className="row">
                                            <div className="col-6 ">
                                                <div className="row">
                                                    <div className="col-12 sectionAligner2">
                                                        <div className="dataCounting">- -</div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 sectionAligner2">
                                                        <div className="content">{this.props.language !== "eng" ? "班次" : "Classes"}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 " id="students">
                                                <div className="row">
                                                    <div className="col-12 sectionAligner2">
                                                        <div className="dataCounting">- -</div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 sectionAligner2">
                                                        <div className="content">{this.props.language !== "eng" ? "人次" : "Students"}</div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>





                                <div className="row">
                                    <div className="col-12 sectionAligner">
                                        <Button variant="contained" color="primary" startIcon={<PublishIcon />} disabled>{this.props.language !== "eng" ? "上載照片" : "Upload New Pic"}</Button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 sectionAligner">
                                        <Button variant="contained" color="default" startIcon={<RedoIcon />} disabled>{this.props.language !== "eng" ? "重設密碼" : "Reset PWD"}</Button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 sectionAligner">
                                        <Button variant="contained" color="secondary" startIcon={<CancelPresentationIcon />} onClick={this.handleHideClick}>{this.props.language !== "eng" ? "隱藏導師" : "Inactivate Tutor"}</Button>                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12 leftDivider">
                                <div className="row">
                                    <div className="col-12 sectionAligner">
                                        <div className="sectionHeader">
                                            {this.props.language !== "eng" ? "基本資料" : "General Information"}
                                        </div>
                                    </div>
                                    <div className="col-12 sectionAligner">
                                        {this.props.language !== "eng" ?
                                            <TextField className="Inputs" id="englishName" label="英文姓名" variant="outlined" defaultValue={thisTutorInfo.name_eng} disabled />
                                            :
                                            <TextField className="Inputs" id="englishName" label="Name in English" variant="outlined" defaultValue={thisTutorInfo.name_eng} disabled />
                                        }

                                    </div>
                                    <div className="col-12 sectionAligner" id="chiName">
                                        {
                                            this.props.language !== "eng" ?
                                                <TextField className="Inputs" id="chineseName" label="中文姓名" variant="outlined" defaultValue={thisTutorInfo.name_chi} disabled />
                                                :
                                                <TextField className="Inputs" id="chineseName" label="Name in Chinese" variant="outlined" defaultValue={thisTutorInfo.name_chi} disabled />

                                        }

                                    </div>
                                    <div className="col-12 sectionAligner">

                                        <FormControl id="ageInput" className="selectInputs">
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                {this.props.language !== "eng" ? "性別" : "SEX"}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                defaultValue={thisTutorInfo.sex}
                                                disabled
                                            >

                                                <MenuItem value={'M'}>M</MenuItem>
                                                <MenuItem value={'F'}>F</MenuItem>
                                                <MenuItem value={'-'}>{this.props.language !== "eng" ? "其他" : "Others"}</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </div>
                                    <div className="col-12 sectionAligner" id="chiName">
                                        {
                                            this.props.language !== "eng" ?
                                                <TextField className="Inputs" id="chineseName" label="聯絡電話" variant="outlined" defaultValue={thisTutorInfo.tel} disabled />
                                                :
                                                <TextField className="Inputs" id="chineseName" label="Tel" variant="outlined" defaultValue={thisTutorInfo.tel} disabled />

                                        }
                                    </div>
                                    <div className="col-12 sectionAligner" id="chiName">
                                        <TextField className="Inputs" id="chineseName" label="Email" variant="outlined" defaultValue={thisTutorInfo.email} disabled />
                                    </div>
                                    <div className="col-12 sectionAligner" id="chiName">
                                        {
                                            this.props.language !== "eng" ?
                                                <TextField
                                                    id="DateOfBirth"
                                                    className="Inputs"
                                                    label="雇用日期"
                                                    type="date"
                                                    defaultValue={moment(`${thisTutorInfo.date_of_employment}`).format("YYYY-MM-DD")}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled
                                                />
                                                :
                                                <TextField
                                                    id="DateOfBirth"
                                                    className="Inputs"
                                                    label="Employment Date"
                                                    type="date"
                                                    defaultValue={moment(`${thisTutorInfo.date_of_employment}`).format("YYYY-MM-DD")}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled
                                                />
                                        }

                                    </div>
                                    <div className="col-12 sectionAligner">
                                        <FormControl id="ageInput" className="selectInputs">
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                {this.props.language !== "eng" ? "雇用模式" : "Employment Type"}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                defaultValue={thisTutorInfo.employment_status_id}
                                                disabled
                                            >
                                                {this.props.employmentStatusBank.map(
                                                    (status: any) => <MenuItem value={status.id}>{status.status}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-12 sectionAligner" id="chiName">
                                        {
                                            this.props.language !== "eng" ?
                                                <TextField className="Inputs" id="address" label="聯絡地址" variant="outlined" multiline={true} rows="2" defaultValue={thisTutorInfo.address} disabled />
                                                :
                                                <TextField className="Inputs" id="address" label="Address" variant="outlined" multiline={true} rows="2" defaultValue={thisTutorInfo.address} disabled />

                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12 leftDivider">
                                <div className="row">
                                    <div className="col-12 sectionAligner">
                                        <div className="sectionHeader">
                                            {this.props.language !== "eng" ? "文件資料" : "Documentation Information"}
                                        </div>
                                    </div>
                                    <div className="col-12 sectionAligner">
                                        {
                                            this.props.language !== "eng" ?
                                                <TextField
                                                    id="issueDate"
                                                    className="Inputs"
                                                    label="出生日期"
                                                    type="date"
                                                    defaultValue={moment(`${thisTutorInfo.date_of_birth}`).format("YYYY-MM-DD")}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled
                                                />
                                                :
                                                <TextField
                                                    id="issueDate"
                                                    className="Inputs"
                                                    label="DOB"
                                                    type="date"
                                                    defaultValue={moment(`${thisTutorInfo.date_of_birth}`).format("YYYY-MM-DD")}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled
                                                />
                                        }
                                    </div>
                                    <div className="col-12 sectionAligner">
                                        {
                                            this.props.language !== "eng" ?
                                                <TextField className="Inputs" id="nationality" label="國籍簡寫" variant="outlined" defaultValue={thisTutorInfo.nationality} disabled />
                                                :
                                                <TextField className="Inputs" id="nationality" label="Nationality" variant="outlined" defaultValue={thisTutorInfo.nationality} disabled />
                                        }

                                    </div>
                                    <div className="col-12 sectionAligner">
                                        <FormControl id="ageInput" className="selectInputs">
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                {this.props.language !== "eng" ? "身份證明文件" : "Identification Document"}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                defaultValue={thisTutorInfo.identification_doc_type}
                                                disabled
                                            >
                                                <MenuItem value={'HKID'}>HKID</MenuItem>
                                                <MenuItem value={'PASSPORT'}>PASSPORT</MenuItem>
                                                <MenuItem value={'-'}>{this.props.language !== "eng" ? "其他" : "Other"}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-12 sectionAligner">
                                        {
                                            this.props.language !== "eng" ?
                                                <TextField className="Inputs" id="englishName" label="文件編號" variant="outlined" defaultValue={thisTutorInfo.identification_doc_number} disabled />
                                                :
                                                <TextField className="Inputs" id="englishName" label="Document Number" variant="outlined" defaultValue={thisTutorInfo.identification_doc_number} disabled />
                                        }

                                    </div>
                                    <div className="col-12 sectionAligner">
                                        {
                                            this.props.language !== "eng" ?
                                                <TextField
                                                    id="issueDate"
                                                    className="Inputs"
                                                    label="簽發日期"
                                                    type="date"
                                                    defaultValue={moment(`${thisTutorInfo.identification_doc_issue_date}`).format("YYYY-MM-DD")}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled
                                                />
                                                :
                                                <TextField
                                                    id="issueDate"
                                                    className="Inputs"
                                                    label="Issue Date"
                                                    type="date"
                                                    defaultValue={moment(`${thisTutorInfo.identification_doc_issue_date}`).format("YYYY-MM-DD")}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled
                                                />
                                        }
                                    </div>
                                    <div className="col-12 sectionAligner">
                                        {
                                            this.props.language !== "eng" ?
                                            <TextField
                                            id="issueDate"
                                            className="Inputs"
                                            label="到期日"
                                            type="date"
                                            defaultValue={moment(`${thisTutorInfo.identification_doc_expiry_date}`).format("YYYY-MM-DD")}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled
                                        />
                                            :
                                            <TextField
                                            id="issueDate"
                                            className="Inputs"
                                            label="Exp Date"
                                            type="date"
                                            defaultValue={moment(`${thisTutorInfo.identification_doc_expiry_date}`).format("YYYY-MM-DD")}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled
                                        />

                                        }
                                    </div>
                                    <div className="col-12 sectionAligner">
                                        {
                                            this.props.language !== "eng" ?
                                            <TextField className="Inputs" id="englishName" label="備注" variant="outlined" multiline={true} rows="6" defaultValue={thisTutorInfo.remarks} disabled />
                                            :
                                            <TextField className="Inputs" id="englishName" label="Remarks" variant="outlined" multiline={true} rows="6" defaultValue={thisTutorInfo.remarks} disabled />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {this.state.showEditBox ? null : <div className="topClass" onClick={this.props.handleExit}></div>}
            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        language: state.auth.language,
        allTutorInfo: state.tutors.allTutorInfo,
        employmentStatusBank: state.tutors.employmentStatusLists

    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        handleHide: async (data: any) => dispatch(await hideThisTutorInfos(data)),
        handleRefresh: async () => dispatch(await tutorPageInit())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TutorIndividualBox);