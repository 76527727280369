import { usernameInputted, passwordInputted, loginSuccess, loginFailed, logoutSuccess, changeLanguageSuccess } from "./actions";
import { ThunkDispatch } from "../store";
import { push, CallHistoryMethodAction } from "connected-react-router";

export function usernameTyping(username: string) {
    // console.log('Log from Username Typing');
    // console.log(username)
    return async (dispatch: ThunkDispatch) => {
        dispatch(usernameInputted(username));
    }
}

export function passwordTyping(password: string) {
    // console.log('Log from Password Typing');
    // console.log(password)
    return async (dispatch: ThunkDispatch) => {
        dispatch(passwordInputted(password));
    }
}

export async function submitClicking(username: string, password: string) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/login/authentication`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(
            {
                username: username,
                password: password
            }
        )
    })
    let result = await res.json()
    console.log(result);
    if (res.status === 200) {
        return async (dispatch: ThunkDispatch) => {
            localStorage.setItem('token', result.token);
            dispatch(loginSuccess(result));
            if (result.defaultPW) {
                dispatch(push("/staffAcLoginSetUp"));
            } else {
                dispatch(push("/layout/home"));
            }

        }
    } else {
        return async (dispatch: ThunkDispatch) => {

            dispatch(loginFailed());
            dispatch(push('/'));
        }
    }
}

export function logout() {
    return async (dispatch: ThunkDispatch) => {
        dispatch(logoutSuccess());
        localStorage.removeItem('token');
        dispatch(push('/'));
    }
}

export async function changeLanguage(targetLanguage: string){
    const res = await fetch (`${process.env.REACT_APP_API_SERVER}/system/changeLanguageSetting`, {
        method: 'PUT',
        headers: {
            "Content-Type":"application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,

        },
        body: JSON.stringify({
            language:targetLanguage
        })
    });
    let result = await res.json();
    if(res.status === 500){
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async( dispatch: ThunkDispatch) => {
            dispatch(changeLanguageSuccess(targetLanguage));
        }
    }
}
// export async function keepAuthPropsOnRefresh() {
//     const res = await fetch(`${process.env.REACT_APP_API_SERVER}/login/authentication`, {
//         method: 'POST',
//         headers: {
//             "Content-Type":"application/json"
//         },
//         body: JSON.stringify(
//             {
//                 username: username,
//                 password: password
//             }
//         )
//     })
// }
// localStorage.getItem('token')