import thunk, { ThunkDispatch } from 'redux-thunk';
import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import logger from 'redux-logger';
import { RouterState, connectRouter, routerMiddleware, CallHistoryMethodAction } from "connected-react-router";

import { IAuthActions } from "./Auth/actions";
import { IAuthState } from "./Auth/state";
import { createBrowserHistory } from 'history';
import { authReducer } from "./Auth/reducers";
import { ITutorActions } from "./Tutors/actions";
import { ITutorState } from "./Tutors/state";
import { tutorReducer } from "./Tutors/reducers";
import { IScheduleActions } from "./Schedule/actions";
import { IScheduleState } from "./Schedule/state";
import { scheduleReducer } from "./Schedule/reducers";
import { ICustomerActions } from "./Customers/actions";
import { ICustomerState } from "./Customers/state";
import { customerReducer } from "./Customers/reducers";
import { IRoomOccupancyIndividualBoxActions } from "./RoomOccupancyIndividualBox/actions";
import { IRoomOccupancyIndividualBoxState } from "./RoomOccupancyIndividualBox/state";
import { roomOccupancyIndividualBoxReducer } from "./RoomOccupancyIndividualBox/reducers";
import { IAddNewBookingWindowActions } from "./RoomOccupancyIndividualBox/AddNewBookingWindow/actions";
import { IAddNewBookingWindowState } from "./RoomOccupancyIndividualBox/AddNewBookingWindow/state";
import { addNewBookingWindowReducer } from "./RoomOccupancyIndividualBox/AddNewBookingWindow/reducers";
import { ICustomerPurchasePaymentHistoryActions } from "./Customers/CustomerPurchasePaymentHistory/actions";
import { ICustomerPurchasePaymentHistoryState } from "./Customers/CustomerPurchasePaymentHistory/state";
import { customerPurchasePaymentHistoryReducer } from "./Customers/CustomerPurchasePaymentHistory/reducers";
import { IAddNewPrivateClassWindowActions } from "./Schedule/AddNewPrivateClassWindow/actions";
import { IAddNewPrivateClassWindowState } from "./Schedule/AddNewPrivateClassWindow/state";
import { addNewPrivateBookingWindowReducer } from "./Schedule/AddNewPrivateClassWindow/reducers";
import { IAddNewRoomRentalWindowActions } from "./Schedule/AddNewRoomRentalWindow/actions";
import { IAddNewRoomRentalWindowState } from "./Schedule/AddNewRoomRentalWindow/state";
import { addNewRoomRentalWindowReducer } from "./Schedule/AddNewRoomRentalWindow/reducers";
import { ICustomerPackageUsageHistoryActions } from "./Customers/CustomerPackageUsageHistory/actions";
import { ICustomerPackageUsageHistoryState } from "./Customers/CustomerPackageUsageHistory/state";
import { customerPackageUsageHistoryReducer } from "./Customers/CustomerPackageUsageHistory/reducers";
import { ISitesClassroomsManageActions } from "./Systems/SitesClassroomsManage/actions";
import { ISitesClassroomsManageState } from "./Systems/SitesClassroomsManage/state";
import { sitesClassroomsManageReducer } from "./Systems/SitesClassroomsManage/reducers";
import { IGroupingManageActions } from "./Systems/GroupingManage/actions";
import { IGroupingManageState } from "./Systems/GroupingManage/state";
import { groupingManageReducer } from "./Systems/GroupingManage/reducers";
import { ITermsManageActions } from "./Systems/TermsManage/actions";
import { ITermsManageState } from "./Systems/TermsManage/state";
import { termsManageReducer } from "./Systems/TermsManage/reducers";
import { IDiscountsManageActions } from "./Systems/DiscountsManage/actions";
import { IDiscountsManageState } from "./Systems/DiscountsManage/state";
import { discountsManageReducer } from "./Systems/DiscountsManage/reducers";
import { IAttendanceManageActions } from "./Systems/AttendanceManage/actions";
import { IAttendanceManageState } from "./Systems/AttendanceManage/state";
import { attendanceManageReducer } from "./Systems/AttendanceManage/reducers";
import { IPackageManageActions } from "./Systems/PackagesManage/actions";
import { IPackagesManageState } from "./Systems/PackagesManage/state";
import { packageManageReducer } from "./Systems/PackagesManage/reducers";
import { IInvoiceGeneratorActions } from "./Customers/InvoiceGenreator/actions";
import { IInvoiceGeneratorState } from "./Customers/InvoiceGenreator/state";
import { invoiceGeneratorReducer } from "./Customers/InvoiceGenreator/reducers";
import { IIncomeListingActions } from "./Accountings/IncomeListing/actions";
import { IIncomeListingState } from "./Accountings/IncomeListing/state";
import { incomeListingReducer } from "./Accountings/IncomeListing/reducers";
import { IStaffAccManageActions } from "./Systems/StaffAccManage/actions";
import { IStaffAccManageState } from "./Systems/StaffAccManage/state";
import { staffAccManageReducer } from "./Systems/StaffAccManage/reducers";
import { IStaffAcLoginSetUpActions } from "./StaffAcLoginSetUp/actions";
import { IStaffAcLoginSetUpState } from "./StaffAcLoginSetUp/state";
import { staffAcLoginSetUpReducer } from "./StaffAcLoginSetUp/reducer";
import { IMassTokenEditManageActions } from "./Systems/MassTokenEditManage/actions";
import { IMassTokenEditManageState } from "./Systems/MassTokenEditManage/state";
import { massTokenEditManageReducer } from "./Systems/MassTokenEditManage/reducers";
import { IDisplayCxlOccupancyWindowActions } from "./Schedule/DisplayCxlOccupancyWindow/actions";
import { IDisplayCxlOccupancyWindowState } from "./Schedule/DisplayCxlOccupancyWindow/state";
import { displayCxlOccupancyWindowReducer } from "./Schedule/DisplayCxlOccupancyWindow/reducers";
import { IStockAttributeManageActions } from "./Stocks/StockAttributesManage/actions";
import { IStockAttributeManageState } from "./Stocks/StockAttributesManage/state";
import { stockAttributeManageReducer } from "./Stocks/StockAttributesManage/reducers";
import { IStockManageMainActions } from "./Stocks/StockManageMain/actions";
import { IStockManageMainState } from "./Stocks/StockManageMain/state";
import { stockManageMainReducer } from "./Stocks/StockManageMain/reducers";
import { IStockCategoryManageActions } from "./Stocks/StockCategoriesManage/actions";
import { IStockCategoryManageState } from "./Stocks/StockCategoriesManage/state";
import { stockCategoryManageReducer } from "./Stocks/StockCategoriesManage/reducers";
import { ISpecificPurchaseDetailsBoxActions } from "./Customers/SpecificPurchaseDetailsBox/actions";
import { ISpecificPurchaseDetailsBoxState } from "./Customers/SpecificPurchaseDetailsBox/state";
import { specificPurchaseDetailsBoxReducer } from "./Customers/SpecificPurchaseDetailsBox/reducers";
import { IHomeActions } from "./Home/actions";
import { IHomeState } from "./Home/state";
import { homeReducer } from "./Home/reducers";

export const history = createBrowserHistory();

export type IRootAction =   IAuthActions |
                            CallHistoryMethodAction | 
                            ITutorActions |
                            IScheduleActions | 
                            ICustomerActions |
                            IRoomOccupancyIndividualBoxActions |
                            IAddNewBookingWindowActions |
                            ICustomerPurchasePaymentHistoryActions |
                            IAddNewPrivateClassWindowActions |
                            IAddNewRoomRentalWindowActions |
                            ICustomerPackageUsageHistoryActions |
                            ISitesClassroomsManageActions |
                            IGroupingManageActions |
                            ITermsManageActions |
                            IDiscountsManageActions |
                            IAttendanceManageActions|
                            IPackageManageActions |
                            IInvoiceGeneratorActions |
                            IIncomeListingActions |
                            IStaffAccManageActions|
                            IStaffAcLoginSetUpActions |
                            IMassTokenEditManageActions |
                            IDisplayCxlOccupancyWindowActions |
                            IStockAttributeManageActions |
                            IStockManageMainActions |
                            IStockCategoryManageActions |
                            ISpecificPurchaseDetailsBoxActions |
                            IHomeActions

export type ThunkDispatch = ThunkDispatch<IRootState, null, IRootAction>

export interface IRootState {
    auth: IAuthState,
    router: RouterState,
    tutors: ITutorState,
    schedule: IScheduleState,
    customers: ICustomerState,
    roomOccupancyIndividualBox: IRoomOccupancyIndividualBoxState,
    addNewBookingWindow: IAddNewBookingWindowState,
    customerPurchasePaymentHistory: ICustomerPurchasePaymentHistoryState,
    addNewPrivateClassWindow: IAddNewPrivateClassWindowState,
    addNewRoomRentalWindow: IAddNewRoomRentalWindowState,
    customerPackagesUsageHistory: ICustomerPackageUsageHistoryState,
    sitesClassroomsManage: ISitesClassroomsManageState,
    groupingManage: IGroupingManageState,
    termsManage: ITermsManageState,
    discountsManage: IDiscountsManageState,
    attendanceManage: IAttendanceManageState,
    packagesManage: IPackagesManageState,
    invoiceGenerator: IInvoiceGeneratorState
    incomeListing: IIncomeListingState,
    staffAccManage: IStaffAccManageState,
    staffAcLoginSetUp: IStaffAcLoginSetUpState,
    massTokenEditManage: IMassTokenEditManageState,
    displayCxlOccupancyWindow: IDisplayCxlOccupancyWindowState,
    stockAttributeManage: IStockAttributeManageState,
    stockManageMain: IStockManageMainState,
    stockCategoryManage: IStockCategoryManageState,
    specificPurchaseDetailsBox: ISpecificPurchaseDetailsBoxState,
    home: IHomeState
}

const rootReducer = combineReducers<IRootState>({
    auth: authReducer,
    router: connectRouter(history),
    tutors: tutorReducer,
    schedule: scheduleReducer,
    customers: customerReducer,
    roomOccupancyIndividualBox: roomOccupancyIndividualBoxReducer,
    addNewBookingWindow: addNewBookingWindowReducer,
    customerPurchasePaymentHistory: customerPurchasePaymentHistoryReducer,
    addNewPrivateClassWindow: addNewPrivateBookingWindowReducer,
    addNewRoomRentalWindow: addNewRoomRentalWindowReducer,
    customerPackagesUsageHistory: customerPackageUsageHistoryReducer,
    sitesClassroomsManage: sitesClassroomsManageReducer,
    groupingManage: groupingManageReducer,
    termsManage: termsManageReducer,
    discountsManage: discountsManageReducer,
    attendanceManage: attendanceManageReducer,
    packagesManage: packageManageReducer,
    invoiceGenerator: invoiceGeneratorReducer,
    incomeListing: incomeListingReducer,
    staffAccManage: staffAccManageReducer,
    staffAcLoginSetUp: staffAcLoginSetUpReducer,
    massTokenEditManage: massTokenEditManageReducer,
    displayCxlOccupancyWindow: displayCxlOccupancyWindowReducer,
    stockAttributeManage: stockAttributeManageReducer,
    stockManageMain: stockManageMainReducer,
    stockCategoryManage: stockCategoryManageReducer,
    specificPurchaseDetailsBox: specificPurchaseDetailsBoxReducer,
    home: homeReducer
})

//typescript syntax 係global宣告，DEBUGGE有可能裝左
declare global {
    /* tslint:disable:interface-name */
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any

    }
}

//增強器   composeEnhancers applyMiddleware, compose睇實Redux store所有既改動

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore<IRootState,IRootAction, {}, {}> (
    rootReducer,
    composeEnhancers(
        applyMiddleware(thunk,logger),
        applyMiddleware(routerMiddleware(history))
    )
)