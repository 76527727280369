import {ThunkDispatch} from '../../store';
import {  allUniqueStockBasicDetailsLoaded, newInventoryRecordCreated, addStockSKUUniqueChecked } from './actions';

export async function loadAllUniqueStockNameBasicInfo() {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/stockManage/loadAllUniqueStockNameBasicInfo`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(allUniqueStockBasicDetailsLoaded(result.inventorySummary, result.allSites, result.allAttributeTypes, result.allCategoryTypes));
        }
    }
}

export async function submitNewStockToInventory (stockDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/stockManage/createNewInventory`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            stockDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(newInventoryRecordCreated());
        }
    }
}

export async function addStockSKUUniqueCheck (SKU:string) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/stockManage/uniqueSKUCheck?SKU=${SKU}`, {
        method: 'GET',
        headers:{
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    });
    let result = await res.json();
    if(res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(addStockSKUUniqueChecked(result.uniqueCheckResult));
        }
    }
}


