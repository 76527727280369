import React from "react";
import { IRootState, ThunkDispatch } from "../../store";
import { connect } from "react-redux";
import './AddCustomerDiscountBox.css'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import PublishIcon from '@material-ui/icons/Publish';
import { Button } from "@material-ui/core";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import moment from "moment";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CloseIcon from '@material-ui/icons/Close';





export interface IAddCustomerDiscountBoxProps {
    language: string,
    allDiscountTypes: any,
    handleAddExit: () => void,
    handleSaveAdd: (discountDetails: any) => void
}

export interface IAddCustomerDiscountBoxState {
    showAddBox: boolean,
    selectedDiscountDetails: any,

}

class AddCustomerDiscountBox extends React.Component<IAddCustomerDiscountBoxProps, IAddCustomerDiscountBoxState> {
    constructor(props: IAddCustomerDiscountBoxProps) {
        super(props);
        this.state = {
            showAddBox: true,
            selectedDiscountDetails: '',


        }
    }
    private handleUseDiscountClick = async (discount: any) => {
        await this.setState({
            selectedDiscountDetails: discount
        })
    }

    private handleDeniedChanges = () => {
        let res = window.confirm('Discard Changes?');
        if (res) {
            this.props.handleAddExit();
        }
    }

    private handleSave = async () => {

        if (this.state.selectedDiscountDetails !== '') {
            await this.props.handleSaveAdd(this.state.selectedDiscountDetails);
            this.props.handleAddExit();
        } else {
            alert('Please Fill in the Required Fields.')
        }


    }


    render() {
        return (
            <div>
                <div className="baseAreaOfAddNewCustomer">
                    <div className="navDisplayRow">
                        <h3 className="addNewBookingWindowTitle">{this.props.language !== "eng" ? "新增顧客優惠" : "Add Discount For Customer"}</h3>
                        <CloseIcon id="addNewBookingWindowCloseBtn" onClick={this.handleDeniedChanges} />
                    </div>
                    <form>
                        <div>

                            <div className="row">

                                <div className="col-12 leftDivider">
                                    <div className="row">
                                        <div className="col-12 sectionAligner">
                                            <div className="sectionHeader">
                                                {this.props.language !== "eng" ? "請選擇優惠" : "Choose Discount"}
                                        </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="fixedHeightDiscountListingAreaOnAddDiscountToCustomerPage">
                                            <div className="disCountListingAreaBGOnAddDiscountToCustomerPage">
                                                {this.props.allDiscountTypes.map((discount: any) =>
                                                    new Date(`${discount.expiry_date}`) < new Date(`${moment().format('YYYY-MM-DD')}T16:00:00.000Z`) ? null :
                                                    this.state.selectedDiscountDetails === discount ?

                                                    <div className="chosenDiscountListingRow" onClick={() => this.handleUseDiscountClick(discount)}>
                                                        <div className="chosenDiscountName"> {discount.discount_type} </div>
                                                        {discount.expiry_date ?
                                                            <div className="chosenDiscountName"> Expire: {moment(discount.expiry_date).format('YYYY-MM-DD')} </div>
                                                            :
                                                            <div className="chosenDiscountName"> Expire: - - </div>
                                                        }
                                                        {discount.cash_discount ?
                                                            <div className="chosenDiscountName"> $ {discount.cash_discount} </div>
                                                            :
                                                            <div className="chosenDiscountName"> {discount.percentage_discount} %</div>
                                                        }
                                                    </div>
                                                    :
                                                    <div className="discountListingRow" onClick={() => this.handleUseDiscountClick(discount)}>
                                                        <div className="discountName"> {discount.discount_type} </div>
                                                        {discount.expiry_date ?
                                                            <div className="discountName"> Expire: {moment(discount.expiry_date).format('YYYY-MM-DD')} </div>
                                                            :
                                                            <div className="discountName"> Expire: - - </div>}
                                                        {discount.cash_discount ?
                                                            <div className="discountName"> $ {discount.cash_discount} </div>
                                                            :
                                                            <div className="discountName"> {discount.percentage_discount} %</div>
                                                        }
                                                    </div>
                                                )}

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="row">

                                <div className="col-12 leftDivider">
                                    <div className="topDivider">
                                        <Button id="clickToAddNewCustomerBtn" startIcon={<PlaylistAddCheckIcon />} onClick={this.handleSave}>{this.props.language !== "eng" ? "確定新增優惠予顧客" : "Confirm Add Discount"}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}


export default AddCustomerDiscountBox;

