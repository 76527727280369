import { IStockCategoryManageState } from './state';
import { IStockCategoryManageActions } from './actions';

const initialState = {
    allStockCategories: [],
    loading: true
}

export const stockCategoryManageReducer = (state: IStockCategoryManageState = initialState, actions: IStockCategoryManageActions) => {
    switch (actions.type) {

        case "ALL_STOCK_CATEGORIES_LOADED":
            return {
                ...state,
                loading: false,
                allStockCategories: actions.stockCategories
            }
        case "STOCK_CATEGORY_CREATED":
            return {
                ...state,
                loading: true

            }
        case "STOCK_CATEGORY_UPDATED":
            return {
                ...state,
                loading: true

            }
        case "STOCK_CATEGORY_ACTIVE_SETTING_MODIFIED":
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }
}