import { ITutorState } from "./state";
import { ITutorActions } from "./actions";

const initialState = {
    allTutorInfo:  [],
    employmentStatusLists: [],
    genreTypeList: [],
    ageGroupList: [],
}

export const tutorReducer = (state: ITutorState = initialState, actions: ITutorActions) => {

    switch (actions.type) {
        case "ALL_TUTOR_LOADED":
            return {
                ...state,
                allTutorInfo: actions.allTutorInfo,
                employmentStatusLists: actions.employmentStatusLists,
                genreTypeList: actions.genreTypeList,
                ageGroupList: actions.ageGroupList
            }

    
        default:
            return state;
    }
}