


export function customerSearchResultLoaded (searchResult: any) {
    return {
        type: "CUSTOMER_SEARCH_RESULT_LOADED" as "CUSTOMER_SEARCH_RESULT_LOADED",
        customerSearchResult: searchResult
    }
}
export function resetAddNewBookingWindow () {
    return {
        type: "ADD_NEW_BOOKING_WINDOW_RESET" as "ADD_NEW_BOOKING_WINDOW_RESET"
    }
}

export function availablePackagesOfCustomerLoaded (availablePackages:any) {
    return {
        type: "AVAILABLE_PACKAGES_OF_CUSTOMER_LOADED" as "AVAILABLE_PACKAGES_OF_CUSTOMER_LOADED",
        availablePackages
    }
}

export function classOccupanciesWithSameUSGLoaded (classOccupancies: any) {
    return {
        type: "CLASS_OCCUPANCIES_WITH_SAME_USG_LOADED" as "CLASS_OCCUPANCIES_WITH_SAME_USG_LOADED",
        classOccupancies
    }
}

export function newClassParticipationDetailCreated () {
    return {
        type: "NEW_CLASS_PARTICIPATION_DETAIL_CREATED" as "NEW_CLASS_PARTICIPATION_DETAIL_CREATED"
    }
}


type AddNewBookingWindowActionsCreators =  
                                typeof customerSearchResultLoaded |
                                typeof resetAddNewBookingWindow |
                                typeof availablePackagesOfCustomerLoaded |
                                typeof classOccupanciesWithSameUSGLoaded |
                                typeof newClassParticipationDetailCreated ;
                                

export type IAddNewBookingWindowActions = ReturnType<AddNewBookingWindowActionsCreators>;