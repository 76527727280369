 import {ThunkDispatch} from '../../store';
import { allTargetedPackageDetailsLoaded } from './actions';

 export async function loadTargetedPackageDetails(purchaseContent:any) {
     let packageIDs = []
     for(let packageID of purchaseContent.tokenPackages){
         packageIDs.push(packageID.id)
     }
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/staffProcessPurchases/loadTargetedInformationForInvoicePreview`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,

        },
        body: JSON.stringify({
            purchasePackageIDs: packageIDs
        })
    })
    let result: any = await res.json()
    console.log(result);
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(allTargetedPackageDetailsLoaded(result));
        }
    }
 }