

export function allDiscountsLoaded(discounts:any) {
    return {
        type: "ALL_DISCOUNTS_LOADED" as "ALL_DISCOUNTS_LOADED",
        discounts
    }
}
export function discountCreated () {
    return {
        type: "DISCOUNT_CREATED" as "DISCOUNT_CREATED"
    }
}
export function discountUpdated () {
    return {
        type:"DISCOUNT_UPDATED" as "DISCOUNT_UPDATED"
    }
}
export function discountActiveSettingModified () {
    return {
        type: "DISCOUNT_ACTIVE_SETTING_MODIFIED" as "DISCOUNT_ACTIVE_SETTING_MODIFIED"
    }
}


type DiscountsManageActionsCreators = typeof allDiscountsLoaded |
                                    typeof discountCreated |
                                    typeof discountUpdated |
                                    typeof discountActiveSettingModified;
                                            

export type IDiscountsManageActions = ReturnType<DiscountsManageActionsCreators>