import React from "react";
import { IRootState, ThunkDispatch } from "../store";
import { connect } from "react-redux";
import './TutorAddBox.css'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import PublishIcon from '@material-ui/icons/Publish';
import { Button } from "@material-ui/core";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import moment from "moment";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { addThisTutorInfos } from "./thunks";




export interface ITutorAddBoxProps {
    language: string,
    genreTypesBank: any,
    ageGroupsBank: any,
    employmentStatusBank: any,
    handleAddExit: () => void,
    handleSaveAdd: (mainBody: any) => void

}

export interface ITutorAddBoxState {
    showAddBox: boolean,
    tutor_name_eng: string,
    tutor_name_chi: string,
    tutor_sex: string,
    tutor_tel: string,
    tutor_email: string,
    tutor_address: string,
    tutor_DOB: string,
    tutor_nationality: string,
    tutor_identification_doc_type: string,
    tutor_identification_doc_number: string,
    tutor_identification_doc_issue_date: string,
    tutor_identification_doc_expiry_date: string,
    tutor_remarks: string,
    tutor_mark_delete: boolean,
    tutor_age_group_id: number[],
    tutor_genre_id: number[],
    tutor_date_of_employment: string,
    tutor_employment_status_id: number,


}

class TutorAddBox extends React.Component<ITutorAddBoxProps, ITutorAddBoxState> {
    constructor(props: ITutorAddBoxProps) {
        super(props);
        this.state = {
            showAddBox: true,
            tutor_name_eng: '-',
            tutor_name_chi: '-',
            tutor_sex: '-',
            tutor_tel: '-',
            tutor_email: '-',
            tutor_address: '-',
            tutor_DOB: '-',
            tutor_nationality: '-',
            tutor_identification_doc_type: '-',
            tutor_identification_doc_number: '-',
            tutor_identification_doc_issue_date: '-',
            tutor_identification_doc_expiry_date: '-',
            tutor_remarks: '-',
            tutor_mark_delete: false,
            tutor_age_group_id: [],
            tutor_genre_id: [],
            tutor_date_of_employment: '-',
            tutor_employment_status_id: 0,

        }
    }

    private handleInput1 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            tutor_name_eng: event.currentTarget.value
        })
    }

    private handleInput2 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            tutor_name_chi: event.currentTarget.value
        })
    }
    private handleInput3 = (event: any) => {
        this.setState({
            tutor_sex: event.target.value
        })
    }
    private handleInput4 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            tutor_tel: event.currentTarget.value
        })
    }
    private handleInput5 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            tutor_email: event.currentTarget.value
        })
    }
    private handleInput6 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            tutor_address: event.currentTarget.value
        })
    }
    private handleInput7 = (event: any) => {

        let result = this.state.tutor_genre_id.find((genreId: any) => genreId === parseInt(event.currentTarget.value));

        if (result) {
            let newTutorGenreId = this.state.tutor_genre_id.filter((id: number) => id !== parseInt(event.currentTarget.value))
            this.setState({
                tutor_genre_id: newTutorGenreId
            })
        } else {
            let newTutorGenreId = this.state.tutor_genre_id;
            newTutorGenreId.push(parseInt(event.currentTarget.value));
            this.setState({
                tutor_genre_id: newTutorGenreId
            })
        }
    }
    private handleInput8 = (event: any) => {
        this.setState({
            tutor_nationality: event.currentTarget.value
        })
    }
    private handleInput9 = (event: any) => {
        this.setState({
            tutor_identification_doc_type: event.target.value
        })
    }
    private handleInput10 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let DOBISOString = moment(event.currentTarget.value).toISOString()
        this.setState({
            tutor_DOB: moment(DOBISOString).format('YYYY-MM-DD')
        })
    }
    private handleInput11 = (event: any) => {
        this.setState({
            tutor_identification_doc_number: event.currentTarget.value
        })
    }
    private handleInput12 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let ISOString = moment(event.currentTarget.value).toISOString()
        this.setState({
            tutor_identification_doc_issue_date: moment(ISOString).format('YYYY-MM-DD')
        })
    }
    private handleInput13 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let ISOString = moment(event.currentTarget.value).toISOString()
        this.setState({
            tutor_identification_doc_expiry_date: moment(ISOString).format('YYYY-MM-DD')
        })
    }
    private handleInput14 = (event: any) => {
        this.setState({
            tutor_remarks: event.currentTarget.value
        })
    }
    private handleInput15 = (event: any) => {

        let result = this.state.tutor_age_group_id.find((ageGroupId: any) => ageGroupId === parseInt(event.currentTarget.value));

        if (result) {
            let newTutorAgeGroupId = this.state.tutor_age_group_id.filter((id: number) => id !== parseInt(event.currentTarget.value))
            this.setState({
                tutor_age_group_id: newTutorAgeGroupId
            })
        } else {
            let newTutorAgeGroupId = this.state.tutor_age_group_id;
            newTutorAgeGroupId.push(parseInt(event.currentTarget.value));
            this.setState({
                tutor_age_group_id: newTutorAgeGroupId
            })
        }
    }
    private handleInput16 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let ISOString = moment(event.currentTarget.value).toISOString()
        this.setState({
            tutor_date_of_employment: moment(ISOString).format('YYYY-MM-DD')
        })
    }
    private handleInput17 = (event: any) => {
        this.setState({
            tutor_employment_status_id: event.target.value
        })
    }
    private handleDeniedChanges = () => {
        if (this.state.tutor_name_eng !== '-',
            this.state.tutor_name_chi === '-',
            this.state.tutor_sex === '-',
            this.state.tutor_tel === '-',
            this.state.tutor_email === '-',
            this.state.tutor_address === '-',
            this.state.tutor_DOB === '-',
            this.state.tutor_nationality === '-',
            this.state.tutor_identification_doc_type === '-',
            this.state.tutor_identification_doc_number === '-',
            this.state.tutor_identification_doc_issue_date === '-',
            this.state.tutor_identification_doc_expiry_date === '-',
            this.state.tutor_remarks === '-',
            this.state.tutor_mark_delete === false,
            this.state.tutor_age_group_id === [],
            this.state.tutor_genre_id === [],
            this.state.tutor_date_of_employment === '-',
            this.state.tutor_employment_status_id === 0) {
            this.props.handleAddExit(); //this case without any change.
        } else {
            let res = window.confirm('Discard Changes?');
            if (res) {
                this.props.handleAddExit();
            }
        }
    }

    private handleSave = async () => {
        if (this.state.tutor_name_eng !== '-' &&
            this.state.tutor_sex !== '-' &&
            this.state.tutor_tel !== '-' &&
            this.state.tutor_date_of_employment !== '-' &&
            this.state.tutor_employment_status_id !== 0) {
            //[CASE] without empty field
            let mainBody = {
                name_eng: this.state.tutor_name_eng,
                name_chi: (this.state.tutor_name_chi === '-' ? null : this.state.tutor_name_chi),
                sex: this.state.tutor_sex,
                tel: (this.state.tutor_tel === '-' ? null : this.state.tutor_tel),
                email: (this.state.tutor_email === '-' ? null : this.state.tutor_email),
                address: (this.state.tutor_address === '-' ? null : this.state.tutor_address),
                date_of_birth: (this.state.tutor_DOB === '-' ? null : this.state.tutor_DOB),
                nationality: (this.state.tutor_nationality === '-' ? null : this.state.tutor_nationality),
                identification_doc_type: (this.state.tutor_identification_doc_type === '-' ? null : this.state.tutor_identification_doc_type),
                identification_doc_number: (this.state.tutor_identification_doc_number === '-' ? null : this.state.tutor_identification_doc_number),
                identification_doc_issue_date: (this.state.tutor_identification_doc_issue_date === '-' ? null : this.state.tutor_identification_doc_issue_date),
                identification_doc_expiry_date: (this.state.tutor_identification_doc_expiry_date === '-' ? null : this.state.tutor_identification_doc_expiry_date),
                remarks: (this.state.tutor_remarks === '-' ? null : this.state.tutor_remarks),
                mark_delete: this.state.tutor_mark_delete,
                age_group_id: this.state.tutor_age_group_id,
                genre_id: this.state.tutor_genre_id,
                date_of_employment: this.state.tutor_date_of_employment,
                employment_status_id: this.state.tutor_employment_status_id,

            }
            await this.props.handleSaveAdd(mainBody);
            this.props.handleAddExit();
        } else {
            alert('Please Fill in the Required Fields.')
        }


    }


    render() {
        return (
            <div>
                <div className="displayInArea2">
                    <form>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 appHeader">
                                <div className="navHeading">{this.props.language !== "eng" ? "新增導師資料" : "New Tutor Info"}</div>
                                    <ArrowBackIcon id="backBtn" onClick={this.handleDeniedChanges} />
                                </div>
                            </div>
                            <div className="fixedHeightWindowContainerOnAddTutorBox">
                                <div className="row">
                                    <div className="col-lg-2 col-md-12">
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                <div className="sectionHeader">
                                                {this.props.language !== "eng" ? "導師資料" : "Tutor Info"}
                                        </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                <div className="tutorPicOutLine">
                                                    <div className="tutorPic"></div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                        <div className="col-12 sectionAligner">
                                            <div className="sectionSubHeading">
                                                {thisTutorInfo.name_eng}
                                            </div>
                                        </div>
                                    </div> */}
                                        {/* <div className="row">
                                        <div className="col-12 sectionAligner">
                                            <div className="row">
                                                <div className="col-6 ">
                                                    <div className="row">
                                                        <div className="col-12 sectionAligner2">
                                                            <div className="dataCounting">21</div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 sectionAligner2">
                                                            <div className="content">Classes</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 " id="students">
                                                    <div className="row">
                                                        <div className="col-12 sectionAligner2">
                                                            <div className="dataCounting">300</div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 sectionAligner2">
                                                            <div className="content">Students</div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div> */}
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                <div id="highlightedSection1">
                                                    <FormControl component="fieldset" >
                                                        <FormLabel component="legend">{this.props.language !== "eng" ? "允許教授運動種類" : "Applicable Sports"}</FormLabel>
                                                        <FormGroup row >
                                                            {this.props.genreTypesBank.map(
                                                                (genre: any) => <div>
                                                                    {this.state.tutor_genre_id.includes(genre.id) ?

                                                                        <FormControlLabel control={<Checkbox checked onChange={this.handleInput7} />}
                                                                            label={genre.genre}
                                                                            value={genre.id}
                                                                            className="noChange"
                                                                        />
                                                                        :
                                                                        <FormControlLabel control={<Checkbox onChange={this.handleInput7} />}
                                                                            label={genre.genre}
                                                                            value={genre.id}
                                                                            className="noChange"
                                                                        />
                                                                    }
                                                                </div>
                                                            )}
                                                        </FormGroup>
                                                        <FormHelperText>{this.props.language !== "eng" ? "向下滾動" : "Roll Down"}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                <div id="highlightedSection2">
                                                    <FormControl component="fieldset" >
                                                        <FormLabel component="legend">{this.props.language !== "eng" ? "允許教授顧客年齡" : "Applicable Age Group"}</FormLabel>
                                                        <FormGroup row>
                                                            {this.props.ageGroupsBank.map(
                                                                (ageGroup: any) => <div>
                                                                    {this.state.tutor_age_group_id.includes(ageGroup.id) ?
                                                                        <FormControlLabel control={<Checkbox checked onChange={this.handleInput15} />}
                                                                            label={ageGroup.age_group}
                                                                            value={ageGroup.id}
                                                                            className="noChange"
                                                                        />
                                                                        :
                                                                        <FormControlLabel control={<Checkbox onChange={this.handleInput15} />}
                                                                            label={ageGroup.age_group}
                                                                            value={ageGroup.id}
                                                                            className="noChange"
                                                                        />
                                                                    }
                                                                </div>
                                                            )}

                                                        </FormGroup>
                                                        <FormHelperText>{this.props.language !== "eng" ? "向下滾動" : "Roll Down"}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="col-lg-5 col-md-12 leftDivider">
                                        {/* <div className="row">
                                        <div className="col-12 sectionAligner">
                                            <div className="sectionHeader">
                                                General Information
                                        </div>
                                        </div>
                                    </div> */}
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                {
                                                    this.props.language !== "eng" ?
                                                    <TextField className="Inputs" id="englishName" label="*英文姓名" variant="outlined"  onChange={this.handleInput1} />
                                                    :
                                                    <TextField className="Inputs" id="englishName" label="*Name in English" variant="outlined"  onChange={this.handleInput1} />
                                                
                                                }
                                            </div>
                                            <div className="col-12 sectionAligner" id="chiName">
                                                {
                                                    this.props.language !== "eng" ?
                                                    <TextField className="Inputs" id="chineseName" label="中文姓名" variant="outlined" onChange={this.handleInput2} />
                                                    :
                                                    <TextField className="Inputs" id="chineseName" label="Name in Chinese" variant="outlined" onChange={this.handleInput2} />
                                                
                                                }
                                            </div>
                                            <div className="col-12 sectionAligner">
                                                <FormControl id="ageInput" className="selectInputs ">
                                                    <InputLabel id="demo-simple-select-outlined-label">
                                                    {this.props.language !== "eng" ? "*性別" : "*SEX"}
                                                </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        onChange={this.handleInput3}
                                                    >

                                                        <MenuItem value={'M'}>M</MenuItem>
                                                        <MenuItem value={'F'}>F</MenuItem>
                                                        <MenuItem value={'-'}>{this.props.language !== "eng" ? "其他" : "Other"}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                {
                                                    this.props.language !== "eng" ?
                                                    <TextField className="Inputs" id="chineseName" label="*聯絡電話" variant="outlined" onChange={this.handleInput4} />
                                                    :
                                                    <TextField className="Inputs" id="chineseName" label="*Tel" variant="outlined" onChange={this.handleInput4} />
                                                
                                                }
                                            </div>
                                            <div className="col-12 sectionAligner">
                                                <TextField className="Inputs " id="email" label="Email" variant="outlined" onChange={this.handleInput5} />
                                            </div>
                                            <div className="col-12 sectionAligner">
                                                {
                                                    this.props.language !== "eng" ?
                                                    <TextField
                                                        id="DateOfBirth"
                                                        className="Inputs"
                                                        label="*雇用日期"
                                                        type="date"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={this.handleInput16}
                                                    />
                                                    :
                                                    <TextField
                                                        id="DateOfBirth"
                                                        className="Inputs"
                                                        label="*Employment Date"
                                                        type="date"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={this.handleInput16}
                                                    />
                                                }
                                                
                                            </div>

                                            <div className="col-12 sectionAligner">
                                                <FormControl id="ageInput" className="selectInputs ">
                                                    <InputLabel id="demo-simple-select-outlined-label">
                                                    {this.props.language !== "eng" ? "*雇用模式" : "*Employment Type"}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        onChange={this.handleInput17}
                                                    >
                                                        {this.props.employmentStatusBank.map(
                                                            (status: any) => <MenuItem value={status.id}>{status.status}</MenuItem>)}

                                                    </Select>
                                                </FormControl>
                                            </div>

                                            <div className="col-12 sectionAligner">
                                                {
                                                    this.props.language == "chi" ?
                                                    <TextField className="Inputs" id="address" label="聯絡地址" variant="outlined" multiline={true} rows="2" onChange={this.handleInput6} />
                                                    :
                                                    <TextField className="Inputs" id="address" label="Address" variant="outlined" multiline={true} rows="2" onChange={this.handleInput6} />
                                                
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-12 leftDivider">
                                        {/* <div className="row">
                                        <div className="col-12 sectionAligner">
                                            <div className="sectionHeader">
                                                Documentation Information
                                        </div>
                                        </div>
                                    </div> */}
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                {
                                                    this.props.language !== "eng" ?
                                                    <TextField
                                                        id="issueDate"
                                                        className="Inputs"
                                                        label="出生日期"
                                                        type="date"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={this.handleInput10}
                                                    />
                                                    :
                                                    <TextField
                                                        id="issueDate"
                                                        className="Inputs"
                                                        label="DOB"
                                                        type="date"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={this.handleInput10}
                                                    />
                                                }

                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                {
                                                    this.props.language !== "eng" ?
                                                    <TextField className="Inputs" id="nationality" label="國籍簡寫" variant="outlined" onChange={this.handleInput8} />
                                                    :
                                                    <TextField className="Inputs" id="nationality" label="Nationality" variant="outlined" onChange={this.handleInput8} />
                                                
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                <FormControl id="ageInput" className="selectInputs ">
                                                    <InputLabel id="demo-simple-select-outlined-label">
                                                    {this.props.language !== "eng" ? "身份證明文件" : "Identification Document"}
                                                </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        onChange={this.handleInput9}
                                                    >
                                                        <MenuItem value={'HKID'}>HKID</MenuItem>
                                                        <MenuItem value={'PASSPORT'}>PASSPORT</MenuItem>
                                                        <MenuItem value={'-'}>{this.props.language !== "eng" ? "其他" : "Other"}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                {
                                                    this.props.language !== "eng" ?
                                                    <TextField className="Inputs" id="englishName" label="文件編號" variant="outlined" onChange={this.handleInput11} />
                                                    :
                                                    <TextField className="Inputs" id="englishName" label="Document Number" variant="outlined" onChange={this.handleInput11} />
                                                
                                                }
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                {
                                                    this.props.language !== "eng" ?
                                                    <TextField
                                                        id="issueDate"
                                                        className="Inputs"
                                                        label="簽發日期"
                                                        type="date"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={this.handleInput12}
                                                    />
                                                    :
                                                    <TextField
                                                        id="issueDate"
                                                        className="Inputs"
                                                        label="Issue Date"
                                                        type="date"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={this.handleInput12}
                                                    />
                                                }
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                {
                                                    this.props.language !== "eng" ?
                                                    <TextField
                                                        id="issueDate"
                                                        className="Inputs"
                                                        label="到期日"
                                                        type="date"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={this.handleInput13}
                                                    />
                                                    :
                                                    <TextField
                                                        id="issueDate"
                                                        className="Inputs"
                                                        label="Exp Date"
                                                        type="date"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={this.handleInput13}
                                                    />
                                                }
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner" id="formLastRowAlign">
                                                {
                                                    this.props.language !== "eng" ?
                                                    <TextField className="Inputs" id="englishName" label="備注" variant="outlined" multiline={true} rows="6"  onChange={this.handleInput14} />
                                                    :
                                                    <TextField className="Inputs" id="englishName" label="Remarks" variant="outlined" multiline={true} rows="6"  onChange={this.handleInput14} />
                                                
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2">
                                </div>
                                <div className="col-10 leftDivider">
                                    <div className="topDivider">
                                        <Button variant="contained" color="primary" startIcon={<PlaylistAddCheckIcon />} onClick={this.handleSave}>{this.props.language !== "eng" ? "儲存導師資料" : "Save New Tutor"}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="topClass2" onClick={this.handleDeniedChanges}></div>
            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        language: state.auth.language,
        genreTypesBank: state.tutors.genreTypeList,
        ageGroupsBank: state.tutors.ageGroupList,
        employmentStatusBank: state.tutors.employmentStatusLists
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        handleSaveAdd: async (mainBody: any) => dispatch(await addThisTutorInfos(mainBody))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorAddBox);