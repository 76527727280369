import { ThunkDispatch } from '../../store';
import { allGroupingLoaded, ageGroupCreated, ageGroupUpdated, ageGroupActiveSettingModified, genreCreated, genreUpdated, genreActiveSettingModified} from './actions';

export async function loadGroupingsData() {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/loadAllGroupings`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(allGroupingLoaded(result.allAgeGroups, result.allGenres));
        }
    }
}
export async function createAgeGroup(ageGroupDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/createNewAgeGroup`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            ageGroupDetails: ageGroupDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(ageGroupCreated());
        }
    }
}
export async function updateAgeGroup(ageGroupDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/updateAgeGroupDetails`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            ageGroupDetails: ageGroupDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(ageGroupUpdated());
        }
    }
}
export async function updateAgeGroupActiveSetting(ageGroupID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/modifyAgeGroupActiveSetting`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            ageGroupID: ageGroupID
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(ageGroupActiveSettingModified());
        }
    }
}
export async function createGenre(genreDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/createNewGenre`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            genreDetails: genreDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(genreCreated());
        }
    }
}
export async function updateGenre(genreDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/updateGenreDetails`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            genreDetails: genreDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(genreUpdated());
        }
    }
}
export async function updateGenreActiveSetting(genreID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/modifyGenreActiveSetting`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            genreID: genreID
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(genreActiveSettingModified());
        }
    }
}
