import { IAddNewRoomRentalWindowState } from "./state";
import { IAddNewRoomRentalWindowActions } from "./actions";
import { AccordionCollapse } from "react-bootstrap";

const initialState = {
    customerSearchingResult:[],
    availablePackagesOfCustomer: {
        unusedPackages:[],
        usingTokenBasedPackages: []
    },
    classOccupancyWithSameUSGID: []

}

export const addNewRoomRentalWindowReducer = (state: IAddNewRoomRentalWindowState = initialState, actions: IAddNewRoomRentalWindowActions) => {

    switch (actions.type) {

        case "CUSTOMER_SEARCH_RESULT_LOADED":
            return {
                ...state,
                customerSearchingResult: actions.customerSearchResult
            }
        case "ADD_NEW_BOOKING_WINDOW_RESET":
            return initialState
            
        case "AVAILABLE_PACKAGES_OF_CUSTOMER_LOADED" :
            return {
                ...state,
                availablePackagesOfCustomer: actions.availablePackages
            }
        
        case "NEW_ROOM_RENTAL_DETAIL_CREATED" :
            return {
                ...state
            }
        default:
            return state;
    }
}