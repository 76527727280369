import { ITermsManageState } from './state';
import { ITermsManageActions } from './actions';

const initialState = {
    allTerms: [],
    loading: true
}

export const termsManageReducer = (state: ITermsManageState = initialState, actions: ITermsManageActions) => {
    switch (actions.type) {

        case "ALL_TERMS_LOADED":
            return {
                ...state,
                allTerms: actions.terms,
                loading: false
            }
        case "TERM_CREATED":
            return {
                ...state,
                loading: true
            }
        case "TERM_UPDATED":
            return {
                ...state,
                loading: true

            }
        case "TERM_ACTIVE_SETTING_MODIFIED":
            return {
                ...state,
                loading: true

            }

        default:
            return state;
    }
}