import { ThunkDispatch } from ".././store";
import { homePageDataLoad } from "./actions";

export async function homePageDataLoading(data:any) {
    const res= await fetch(`${process.env.REACT_APP_API_SERVER}/accounting/homePageInitLoadChartData?siteID=${data.siteID}&startDate=${data.startDate}&endDate=${data.endDate}`, {
        method: 'GET',
        headers: {
            "Content-Type" : "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result: any = await res.json();
    console.log(result);
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {

        return async (dispatch: ThunkDispatch) => {

            dispatch(homePageDataLoad(result));

        }
    }
}