import { ICustomerState } from "./state";
import { ICustomerActions } from "./actions";

const initialState = {
    allCustomerInfo: [],
    totalCustomerCount: 0,
    ageGroupList: [],
    genreTypeList: [],
    packageWithRightUsageList: [],
    tokenUsageList: [],
    purchasePackagesDetailList: [],
    discountOwnedBySelectedCustomer: [],
    effectiveDiscountOwnedBySelectedCustomer: [],
    packagesOwnByCustomer: [],
    purchaseTermsLibrary: [],
    allDiscountTypes: [],
    relatedGuardianDetails: "",
    allCustomerGroupingList: [],
    allSites: [],
    newlyPurchasedPackagesDetails: [],
    customerPreviousNoTokenBookings: [],
    loading: true,
    uniqueStockBasicDetails: [],
    allAttributeTypes: [],
    allCategoryTypes: [],
    purchaseProductsDetailsList: [],
}

export const customerReducer = (state: ICustomerState = initialState, actions: ICustomerActions) => {

    switch (actions.type) {
        case "ALL_CUSTOMER_LOADED":
            return {
                ...state,
                allCustomerInfo: actions.allCustomerInfo,
                totalCustomerCount: actions.totalCustomerCount,
                allCustomerGroupingList: actions.allCustomerGroupingList,
                loading: false
            }

        case "INFO_INFRASTRUCTURE_LOADED":
            return {
                ...state,
                ageGroupList: actions.ageGroups,
                genreTypeList: actions.genreTypes,
                tokenUsageList: actions.tokenUsages,
                packageWithRightUsageList: [],
                allSites: actions.allSites,
                // loading: false

            }
        case "PACKAGES_WITH_RIGHT_USAGE_LOADED":
            return {
                ...state,
                packageWithRightUsageList: actions.packageList.result,
                loading: false

            }
        case "PURCHASE_PACKAGES_DETAILS_LOADED":
            return {
                ...state,
                purchasePackagesDetailList: actions.packageList.detailsOfPackagesForPurchase,
                loading: false

            }
        case "PURCHASE_PRODUCTS_DETAILS_LOADED":
            return {
                ...state,
                purchaseProductsDetailsList: actions.productList,
                loading: false
            }
        case "TARGET_PRODUCT_RELEASED":
            return {
                ...state,
                purchaseProductsDetailsList: [],
                loading: false
            }
        case "DISCOUNT_OWNED_BY_SELECTED_CUSTOMER_LOADED":
            return {
                ...state,
                discountOwnedBySelectedCustomer: actions.discountList.result,
                loading: false

            }
        case "NEW_PURCHASE_OF_CUSTOMER_UPDATED_SUCCESSFULLY_BY_STAFF":
            return {
                ...state,
                purchaseProductsDetailsList:[],
                purchasePackagesDetailList:[],
                loading: true
            }
        case "NEW_PURCHASE_SUBMITTED_WITH_NO_TOKEN_BOOKING_NEEDS_TO_BE_HANDLED":
            return {
                ...state,
                newlyPurchasedPackagesDetails: actions.newPackageDetails,
                customerPreviousNoTokenBookings: actions.previousNoTokenBooking,
                loading: false
            }
        case "PACKAGES_OWNED_BY_CUSTOMER_LOADED":
            return {
                ...state,
                packagesOwnByCustomer: actions.packagesOwnByCustomer,
                allDiscountTypes: actions.allDiscountTypes,
                loading: false

            }
        case "PERIOD_PACKAGE_OF_CUSTOMER_ACTIVATED":
            return {
                ...state
            }
        case "PURCHASE_TERMS_LIBRARY_LOADED":
            return {
                ...state,
                purchaseTermsLibrary: actions.purchaseTermsLibrary,
                loading: false

            }
        case "DISCOUNT_ADDED_TO_CUSTOMER":
            return {
                ...state,
                loading: true

            }
        case "DISCOUNT_DELETED_FROM_CUSTOMER":
            return {
                ...state,
                loading: true

            }
        case "RELATED_GUARDIAN_DETAILS_LOADED":
            return {
                ...state,
                relatedGuardianDetails: actions.guardianDetails,
                loading: false

            }
        case "RELATED_GUARDIAN_DETAILS_RESET":
            return {
                ...state,
                relatedGuardianDetails: ""
            }
        case "RELATED_GUARDIAN_DETAILS_CREATED":
            return {
                ...state,
                relatedGuardianDetails: actions.guardianDetails
            }
        case "RELATED_GUARDIAN_DETAILS_UPDATED":
            return {
                ...state,
                relatedGuardianDetails: actions.guardianDetails
            }
        case "RELATED_GUARDIAN_DETAILS_DELETED":
            return {
                ...state,
                relatedGuardianDetails: ""
            }
        case "CUSTOMER_GROUPING_ID_UPDATED":
            return {
                ...state,
                loading: true

            }
        case "CUSTOMER_PACKAGE_UPDATED_MANUALLY":
            return {
                ...state,
                loading: true

            }
        case "MARK_REFERRAL_DETAILS_AFTERWARDS_SUCCESS":
            return {
                ...state,

            }
        case "NO_TOKEN_BOOKING_PROCESSED":
            return {
                ...state
            }
        case "EXIT_FROM_NO_TOKEN_BOOKING_WINDOW":
            return {
                ...state,
                customerPreviousNoTokenBookings: []
            }
        case "ALL_PRODUCT_STORAGE_SUMMARY_LOADED":
            return {
                ...state,
                uniqueStockBasicDetails: actions.uniqueStockBasicDetails,
                allAttributeTypes: actions.allAttributeTypes,
                allCategoryTypes: actions.allCategoryTypes,
                allSites: actions.allSites
                }
        case "LOADING":
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }
}