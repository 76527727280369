import { IGroupingManageState } from './state';
import { IGroupingManageActions } from './actions';

const initialState = {
    allAgeGroups: [],
    allGenres: [],
    loading: true
}

export const groupingManageReducer = (state: IGroupingManageState = initialState, actions: IGroupingManageActions) => {
    switch (actions.type) {

        case "ALL_GROUPINGS_LOADED":
            return {
                ...state,
                allAgeGroups: actions.ageGroups,
                allGenres: actions.genres,
                loading:false
            }
        case "AGE_GROUP_CREATED":
            return {
                ...state,
                loading:true
            }
        case "AGE_GROUP_UPDATED":
            return {
                ...state,
                loading:true

            }
        case "AGE_GROUP_ACTIVE_SETTING_MODIFIED":
            return {
                ...state,
                loading:true

            }
        case "GENRE_CREATED":
            return {
                ...state,
                loading:true

            }
        case "GENRE_UPDATED":
            return {
                ...state,
                loading:true

            }
        case "GENRE_ACTIVE_SETTING_MODIFIED":
            return {
                ...state,
                loading:true

            }

        default:
            return state;
    }
}