

export function allTutorLoaded(allTutorArray:any[], allEmploymentStatuses:any[], allGenreTypes : any[] ,allAgeGroups: any[]) {
    return {
        type: "ALL_TUTOR_LOADED" as "ALL_TUTOR_LOADED",
        allTutorInfo: allTutorArray,
        employmentStatusLists: allEmploymentStatuses,
        genreTypeList:allGenreTypes,
        ageGroupList: allAgeGroups
    }
}



type TutorActionsCreators = typeof allTutorLoaded ;

export type ITutorActions = ReturnType<TutorActionsCreators>;