

export function allRequiredDataLoadedForPackageManageInit ( allAgeGroups:any, allGenres: any, allTokenUsages: any, allTokenPackages:any, allTerms:any, allSites:any) {
    return {
        type: "ALL_DATA_LOADED_FOR_PACKAGE_MANAGE_INIT" as "ALL_DATA_LOADED_FOR_PACKAGE_MANAGE_INIT",
        allAgeGroups,
        allGenres,
        allTokenPackages,
        allTokenUsages,
        allTerms,
        allSites
    }

}

export function allPackagesWithRightUsageLoaded (allPackages:any) {
    return {
        type: "ALL_PACKAGES_WITH_RIGHT_USAGE_LOADED" as "ALL_PACKAGES_WITH_RIGHT_USAGE_LOADED",
        allPackages
    }
}

export function packageUpdated () {
    return {
        type: "PACKAGE_UPDATED" as "PACKAGE_UPDATED"
    }
}

export function packageCreated() {
    return {
        type: "PACKAGE_CREATED" as "PACKAGE_CREATED"
    }
}

export function packageDeleted () {
    return {
        type: "PACKAGE_DELETED" as "PACKAGE_DELETED"
    }
}

export function uniquePackageNameChecked (uniqueCheckResult: any) {
    return {
        type: "UNIQUE_PACKAGE_NAME_CHECKED" as "UNIQUE_PACKAGE_NAME_CHECKED",
        uniqueCheckResult
    }
}
export function loading() {
    return {
        type: "LOADING" as "LOADING"
    }
}

export function finishMassUpdatingPackageGenre (changedPackageCount:number) {
    return {
        type: "FINISH_MASS_UPDATING_PACKAGE_GENRE" as "FINISH_MASS_UPDATING_PACKAGE_GENRE",
        changedPackageCount
    }
}
type PackageManageActionsCreators = typeof allRequiredDataLoadedForPackageManageInit |
                                    typeof allPackagesWithRightUsageLoaded |
                                    typeof packageCreated |
                                    typeof packageUpdated |
                                    typeof packageDeleted |
                                    typeof uniquePackageNameChecked |
                                    typeof loading |
                                    typeof finishMassUpdatingPackageGenre

export type IPackageManageActions = ReturnType<PackageManageActionsCreators>