import React from "react";
import { IRootState, ThunkDispatch } from "../../store"
import { connect } from "react-redux";
import { loadSpecificPurchaseDetails } from "./thunks";
import { Button, CircularProgress, Paper } from "@material-ui/core";
import PrintableReceipt from "./PrintableReceipt";

import PrintIcon from '@material-ui/icons/Print';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TocIcon from '@material-ui/icons/Toc';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import GavelIcon from '@material-ui/icons/Gavel'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import './SpecificPurchaseDetailsBox.css';
import { purchasePackagesDetailsLoaded } from "../actions";
import moment from "moment";
import { unmountClear } from "./actions";
import ReactToPrint from "react-to-print";

export interface ISpecificPurchaseDetailsBoxProps {
    language:string,
    purchaseDetails: any,
    paymentDetails: any,
    purchasedPackages: any[],
    purchasedProducts: any[],
    usedDiscounts: any[],
    loading: boolean,
    selectedPurchaseID: number,
    processedTermsList: any[],
    customerDetails: any,
    //BASIC INFO
    siteLists: any[],
    handleLoadSpecificPurchaseDetails: (purchaseID: number) => void,
    handleExit: (purchaseID: number) => void,
    handleReset: () => void
}

export interface ISpecificPurchaseDetailsBoxState {
    displayingSection: number,
    printingReceipt: boolean,
    componentRef: any
}

class SpecificPurchaseDetailsBox extends React.Component<ISpecificPurchaseDetailsBoxProps, ISpecificPurchaseDetailsBoxState> {
    constructor(props: ISpecificPurchaseDetailsBoxProps) {
        super(props);
        this.state = {
            displayingSection: 1,
            printingReceipt: false,
            componentRef: ""
        }
    }

    private handleDisplaySectionChange = async (sectionNumber: number) => {
        await this.setState({
            displayingSection: sectionNumber
        })
    }

    private handlePrintingReceiptClick = async () => {
        await this.setState({
            printingReceipt: !this.state.printingReceipt
        })
    }

    private handleExitClick = async () => {
        this.props.handleReset();
        this.props.handleExit(this.props.selectedPurchaseID);
    }


    componentWillMount() {
        this.props.handleLoadSpecificPurchaseDetails(this.props.selectedPurchaseID);
    }

    render() {
        let componentRef: any = "";
        let amountReceived = 0;
        if (this.props.paymentDetails.length > 0) {
            for (let payment of this.props.paymentDetails) {
                if (!payment.void) {
                    amountReceived += parseFloat(payment.amount_received)
                }
            }
        }
        return (
            <Paper className="col-12 " id="specificPurchaseDetailsBox" >
                {
                    !this.state.printingReceipt ? null :
                        <div className="printableReceipt">
                            <PrintableReceipt ref={el => (componentRef = el)} purchaseDetails={this.props.purchaseDetails} paymentDetails={this.props.paymentDetails} purchasedPackages={this.props.purchasedPackages} purchasedProducts={this.props.purchasedProducts} usedDiscounts={this.props.usedDiscounts} processedTermsList={this.props.processedTermsList} customerDetails={this.props.customerDetails} amountReceived={amountReceived} allSites={this.props.siteLists} language={this.props.language}/>
                            {/* <ReactToPrint trigger={this.state.printingReceipt} ></ReactToPrint> */}
                        </div>
                }
                {
                    this.props.loading ?
                        <div className="w-100 h-100">
                            <div className="loadingContainerRow">
                                <div className="inlineDisplay">
                                    <div className="loadingContainer">
                                        <CircularProgress id="loadingCircle" />
                                        <div className="loadingLabel">Loading ...</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :

                        <div className="row">
                            <div className=" col-12 blockTitleRowOfSpecificPurchase">
                                <div className="row">
                                    <div className="col-2">
                                        <h4 className="blockTitle"> {this.props.language !== "eng" ? "選購詳情" : "Purchase"} #{this.props.purchaseDetails.id}</h4>
                                    </div>
                                    <div className="col-8">
                                        <div className="row">
                                            <div className="col-4">
                                                {
                                                    this.state.displayingSection === 1 ?
                                                        <div className="purchaseDetailsPageButtonIndicator01">
                                                            <div className="buttonContentContainer">
                                                                <TocIcon id="btnIconOfSpecificPurchaseBox" />
                                                                <div className="buttonContent">{this.props.language !== "eng" ? "選購內容" : "Purchase Details"}</div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="purchaseDetailsPageButtonIndicator02" onClick={() => this.handleDisplaySectionChange(1)}>
                                                            <div className="buttonContentContainer">
                                                                <TocIcon id="btnIconOfSpecificPurchaseBox" />
                                                                <div className="buttonContent">{this.props.language !== "eng" ? "選購內容" : "Purchase Details"}</div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                            <div className="col-4">
                                                {
                                                    this.state.displayingSection === 2 ?
                                                        <div className="purchaseDetailsPageButtonIndicator01">
                                                            <div className="buttonContentContainer">
                                                                <AttachMoneyIcon id="btnIconOfSpecificPurchaseBox" />
                                                                <div className="buttonContent">{this.props.language !== "eng" ? "付款詳情" : "Payment Details"}</div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="purchaseDetailsPageButtonIndicator02" onClick={() => this.handleDisplaySectionChange(2)}>
                                                            <div className="buttonContentContainer">
                                                                <AttachMoneyIcon id="btnIconOfSpecificPurchaseBox" />
                                                                <div className="buttonContent">{this.props.language !== "eng" ? "付款詳情" : "Payment Details"}</div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                            <div className="col-4">
                                                {
                                                    this.state.displayingSection === 3 ?
                                                        <div className="purchaseDetailsPageButtonIndicator01">
                                                            <div className="buttonContentContainer">
                                                                <GavelIcon id="btnIconOfSpecificPurchaseBox" />
                                                                <div className="buttonContent">{this.props.language !== "eng" ? "條款細節" : "Terms & Conditions"}</div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="purchaseDetailsPageButtonIndicator02" onClick={() => this.handleDisplaySectionChange(3)}>
                                                            <div className="buttonContentContainer">
                                                                <GavelIcon id="btnIconOfSpecificPurchaseBox" />
                                                                <div className="buttonContent">{this.props.language !== "eng" ? "條款細節" : "Terms & Conditions"}</div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.printingReceipt ?
                                            <div className="col-2 aligner">
                                                <Button startIcon={<ArrowBackIcon />} id="functionBtn02" onClick={this.handlePrintingReceiptClick}> {this.props.language !== "eng" ? "返回" : "Back"}</Button>
                                                <ReactToPrint trigger={() => <Button startIcon={<PrintIcon />} id="functionBtn02" > {this.props.language !== "eng" ? "列印" : "Print"}</Button>} content={() => componentRef}></ReactToPrint>
                                            </div>
                                            :
                                            <div className="col-2 aligner">
                                                <Button startIcon={<ArrowBackIcon />} id="functionBtn" onClick={this.handleExitClick}> {this.props.language !== "eng" ? "返回" : "Back"}</Button>
                                                <Button startIcon={<PrintIcon />} id="functionBtn" onClick={this.handlePrintingReceiptClick}> {this.props.language !== "eng" ? "列印" : "Print"}</Button>
                                            </div>
                                    }
                                </div>
                            </div>

                            <div className="col-3 purchaseSummaryBlock">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="sumUpDataTitle">{this.props.language !== "eng" ? "選購總額" : "Total"} </div>
                                        <div className="amountPayable">{this.props.purchaseDetails.amount_payable}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="rightAligner">
                                            {
                                                this.props.purchaseDetails.void ?
                                                    <div className="sumUpDataTitle"><div className="voidIndicator"></div>{this.props.language !== "eng" ? "已VOID" : "VOIDED"}</div>

                                                    :
                                                    this.props.purchaseDetails.fully_paid ?
                                                        <div className="sumUpDataTitle"><div className="paidIndicator"></div>{this.props.language !== "eng" ? "已全數付款" : "Payments Clear"}</div>
                                                        :
                                                        <div className="sumUpDataTitle"><div className="unpaidIndicator"></div>{this.props.language !== "eng" ? "未完成付款" : "Payment Not Clear"}</div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 breaker"></div>
                                </div>

                                <div className="row">
                                    <div className="col-5">
                                        <div className="sumUpDataTitle">{this.props.language !== "eng" ? "選購編號" : "Purchase ID"} </div>
                                    </div>
                                    <div className="col-7">
                                        <div className="rightAligner">
                                            <div className="sumUpDataContent"> # {this.props.purchaseDetails.id} {this.props.language !== "eng" ? "號" : ""}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-5">
                                        <div className="sumUpDataTitle">{this.props.language !== "eng" ? "已收款項" : "Amount Received"} </div>
                                    </div>
                                    <div className="col-7">
                                        <div className="rightAligner">
                                            <div className="sumUpDataContent">{this.props.purchaseDetails.void ? 0 : amountReceived} {this.props.language !== "eng" ? "元" : ""}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-5">
                                        <div className="sumUpDataTitle">{this.props.language !== "eng" ? "關聯付款" : "Related Payment"} </div>
                                    </div>
                                    <div className="col-7">
                                        <div className="rightAligner">
                                            <div className="sumUpDataContent">{this.props.paymentDetails.length} 次</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-5">
                                        <div className="sumUpDataTitle">{this.props.language !== "eng" ? "使用優惠" : "Discount Used"} </div>
                                    </div>
                                    <div className="col-7">
                                        <div className="rightAligner">
                                            <div className="sumUpDataContent">{this.props.usedDiscounts.length} {this.props.language !== "eng" ? "個" : ""}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 dottedBreaker"></div>
                                </div>

                                <div className="row">
                                    <div className="col-5">
                                        <div className="sumUpDataTitle">{this.props.language !== "eng" ? "內含套券" : "Package Included"} </div>
                                    </div>
                                    <div className="col-7">
                                        <div className="rightAligner">
                                            <div className="sumUpDataContent">{this.props.purchasedPackages.length} {this.props.language !== "eng" ? "項" : ""}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-5">
                                        <div className="sumUpDataTitle">{this.props.language !== "eng" ? "內含產品" : "Product  Included"} </div>
                                    </div>
                                    <div className="col-7">
                                        <div className="rightAligner">
                                            <div className="sumUpDataContent">{this.props.purchasedProducts.length} {this.props.language !== "eng" ? "項" : ""}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 dottedBreaker"></div>
                                </div>

                                <div className="row">
                                    <div className="col-5">
                                        <div className="sumUpDataTitle">{this.props.language !== "eng" ? "適用條款" : "Applicable Terms"} </div>
                                    </div>
                                    <div className="col-7">
                                        <div className="rightAligner">
                                            <div className="sumUpDataContent">{this.props.processedTermsList.length} 條</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 dottedBreaker"></div>
                                </div>

                                <div className="row">
                                    <div className="col-5">
                                        <div className="sumUpDataTitle">{this.props.language !== "eng" ? "顧客編號" : "Customer ID"} </div>
                                    </div>
                                    <div className="col-7">
                                        <div className="rightAligner">
                                            <div className="sumUpDataContent"> # {this.props.purchaseDetails.customer_id} {this.props.language !== "eng" ? "號" : ""}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-5">
                                        <div className="sumUpDataTitle">{this.props.language !== "eng" ? "開單員工" : "Process Staff"} </div>
                                    </div>
                                    <div className="col-7">
                                        <div className="rightAligner">
                                            <div className="sumUpDataContent"> # {this.props.purchaseDetails.process_staff_id} {this.props.language !== "eng" ? "號" : ""}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-5">
                                        <div className="sumUpDataTitle">{this.props.language !== "eng" ? "開單日期" : "Process Date"} </div>
                                    </div>
                                    <div className="col-7">
                                        <div className="rightAligner">
                                            <div className="sumUpDataContent">{moment(this.props.purchaseDetails.created_at).format("YYYY/MM/DD")}</div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !this.props.purchaseDetails.void ? null :
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="sumUpDataTitle">{this.props.language !== "eng" ? "VOID員工" : "VOID Staff"} </div>
                                            </div>
                                            <div className="col-7">
                                                <div className="rightAligner">
                                                    <div className="sumUpDataContent"> # {this.props.purchaseDetails.void_staff_id} {this.props.language !== "eng" ? "號" : ""}</div>
                                                </div>
                                            </div>
                                        </div>
                                }
                                {
                                    !this.props.purchaseDetails.void ? null :
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="sumUpDataTitle">{this.props.language !== "eng" ? "VOID日期" : "VOID Date"} </div>
                                            </div>
                                            <div className="col-7">
                                                <div className="rightAligner">
                                                    <div className="sumUpDataContent">{moment(this.props.purchaseDetails.void_date).format("YYYY/MM/DD")}</div>
                                                </div>
                                            </div>
                                        </div>
                                }
                                <div className="row">
                                    <div className="col-12 breaker"></div>
                                </div>

                            </div>

                            {
                                this.state.displayingSection !== 1 ? null :

                                    <div className="col-9 purchaseDetailsBlock">
                                        {/* <div className="row">
                                    <div className="col-12 ">
                                        <h4 className="blockTitle">{this.props.language !== "eng" ? "選購內容" : "Purchase Details"}</h4>
                                    </div>
                                </div> */}
                                        {
                                            this.props.purchasedPackages.length <= 0 ? null :
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <div className="subBlockBG">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="subBlockTitle ">{this.props.language !== "eng" ? "內含套劵" : "Related Package"} ({this.props.purchasedPackages.length})</div>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.props.purchasedPackages.map((targetPackage: any, index: number) =>
                                                                    <div className="row paymentRow">
                                                                        <div className="col-12">

                                                                            <div className="row itemContentRow">
                                                                                {/* <div className="col-1"></div> */}
                                                                                <div className="col-12 ">
                                                                                    <div className="row ">
                                                                                        <div className="col-10">
                                                                                            <div className="contentMain" key={"purchasedPackage" + index}>{targetPackage.package_name}</div>
                                                                                        </div>
                                                                                        <div className="col-2 ">
                                                                                            <div className="contentMainPrice">
                                                                                                <div key={"packagePrice" + index}>$ {targetPackage.price}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-3 contentRow01">
                                                                                            <div className="inline">{this.props.language !== "eng" ? "獨立編號" : "Unique ID"} :</div>
                                                                                            <div className="inline"> # {targetPackage.id} {this.props.language !== "eng" ? "號" : ""} </div>
                                                                                        </div>
                                                                                        <div className="col-3 contentRow01">
                                                                                            <div className="inline">{this.props.language !== "eng" ? "有效期限" : "Validate Period"} :</div>
                                                                                            <div className="inline"> {targetPackage.validate_period} {this.props.language !== "eng" ? "日" : ""} </div>
                                                                                        </div>
                                                                                        <div className="col-3 contentRow01">
                                                                                            <div className="inline">{this.props.language !== "eng" ? "套券數量" : "Effective Token"} :</div>
                                                                                            <div className="inline"> {targetPackage.token_amount} {this.props.language !== "eng" ? "張" : ""} </div>
                                                                                        </div>
                                                                                        <div className="col-3 contentRow01">
                                                                                            <div className="inline">{this.props.language !== "eng" ? "適用分校" : "Site"} :</div>
                                                                                            <div className="inline"> {targetPackage.site ? (this.props.siteLists.find((site: any) => site.id === parseFloat(`${targetPackage.site}`))).site_key : this.props.language ==="chi" ? "不限" : "Unlimited"}  </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>

                                                    </div>
                                                </div>

                                        }

                                        {
                                            this.props.purchasedProducts.length <= 0 ? null :
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <div className="subBlockBG">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="subBlockTitle ">{this.props.language !== "eng" ? "內含產品" : "Product  Included"} ({this.props.purchasedProducts.length})</div>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.props.purchasedProducts.map((targetProduct: any, index: number) =>
                                                                    <div className="row itemContentRow">
                                                                        {/* <div className="col-1"></div> */}
                                                                        <div className="col-12 ">
                                                                            <div className="row ">
                                                                                <div className="col-10">
                                                                                    <div className="contentMain" key={"purchasedPackage" + index}>{targetProduct.product_name}</div>
                                                                                </div>
                                                                                <div className="col-2 ">
                                                                                    <div className="contentMainPrice">
                                                                                        <div key={"packagePrice" + index}>$ {targetProduct.sold_price}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-3 contentRow01">
                                                                                    <div className="inline">{this.props.language !== "eng" ? "獨立編號" : "Unique ID"} :</div>
                                                                                    <div className="inline"> # {targetProduct.id} {this.props.language !== "eng" ? "號" : ""} </div>
                                                                                </div>
                                                                                <div className="col-3 contentRow01">
                                                                                    <div className="inline">SKU :</div>
                                                                                    <div className="inline">{targetProduct.sku}</div>
                                                                                </div>
                                                                                {/* <div className="col-3 contentRow01">
                                                                        <div className="inline">{this.props.language !== "eng" ? "套券數量" : "Effective Token"} :</div>
                                                                        <div className="inline"> {targetPackage.token_amount} {this.props.language !== "eng" ? "張" : ""} </div>
                                                                    </div>
                                                                    <div className="col-3 contentRow01">
                                                                        <div className="inline">{this.props.language !== "eng" ? "適用分校" : "Site"} :</div>
                                                                        <div className="inline"> {targetPackage.site ? (this.props.siteLists.find((site: any) => site.id === targetPackage.site)).site_key : "不限"}  </div>
                                                                    </div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                        }
                                    </div>
                            }

                            {
                                this.state.displayingSection !== 2 ? null :
                                    <div className="col-9 purchaseDetailsBlock">

                                        <div className="row">
                                            <div className="col-3 ">
                                                <div className="subBlockBG">
                                                    <div className="monetaryDisplay">{this.props.purchaseDetails.total_price}</div>
                                                    <div className="monetaryIndicator">{this.props.language !== "eng" ? "總值" : "Sub-Total"}</div>
                                                </div>
                                            </div>
                                            <div className="col-3 ">
                                                <div className="subBlockBG">

                                                    <div className="monetaryDisplay">{this.props.purchaseDetails.discounted_amount}</div>
                                                    <div className="monetaryIndicator">{this.props.language !== "eng" ? "折扣" : "Discount"}</div>
                                                </div>
                                            </div>
                                            <div className="col-3 ">
                                                <div className="subBlockBG">

                                                    <div className="monetaryDisplay">{this.props.purchaseDetails.amount_payable}</div>
                                                    <div className="monetaryIndicator">{this.props.language !== "eng" ? "應付" : "Amount Payable"}</div>
                                                </div>
                                            </div>
                                            <div className="col-3 ">
                                                <div className="subBlockBG">

                                                    <div className="monetaryDisplay">{amountReceived.toFixed(2)}</div>
                                                    <div className="monetaryIndicator">{this.props.language !== "eng" ? "已收" : "Amount Received"}</div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            this.props.usedDiscounts.length <= 0 ? null :
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="subBlockBG">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="subBlockTitle ">{this.props.language !== "eng" ? "使用折扣" : "Used Discount"} ({this.props.paymentDetails.length})</div>

                                                                </div>
                                                            </div>
                                                            {
                                                                this.props.usedDiscounts.map((discount: any, index: number) =>
                                                                    <div className="row paymentRow">
                                                                        <div className="col-12">
                                                                            <div className="row itemContentRow">
                                                                                <div className="col-12">
                                                                                    <div className="row">
                                                                                        <div className="col-10">
                                                                                            <div className="contentMain" key={"usedDiscount" + index}> {discount.discount_name}</div>
                                                                                        </div>
                                                                                        <div className="col-2">
                                                                                            <div className="contentMainPrice discountColor">
                                                                                                <div key={"packagePrice" + index}>{this.props.language !== "eng" ? "折" : "-"} $ {discount.cash_discount ? parseFloat(`${discount.cash_discount}`).toFixed(2) : (this.props.purchaseDetails.total_price * (discount.percentage_discount / 100)).toFixed(2)}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-4 contentRow01">
                                                                                            <div className="inline">{this.props.language !== "eng" ? "優惠編號" : "Discount ID"} :</div>
                                                                                            <div className="inline"> # {discount.id} {this.props.language !== "eng" ? "號" : ""} </div>
                                                                                        </div>
                                                                                        <div className="col-4 contentRow01">
                                                                                            <div className="inline">{this.props.language !== "eng" ? "批準員工" : "Process Staff"} :</div>
                                                                                            <div className="inline"> # {discount.process_staff_id} {this.props.language !== "eng" ? "號" : ""}</div>
                                                                                        </div>
                                                                                        <div className="col-4 contentRow01">
                                                                                            <div className="inline">{this.props.language !== "eng" ? "折扣總額" : "Discounted Amount"} :</div>
                                                                                            <div className="inline">{discount.cash_discount ? `$ ${discount.cash_discount}` : `${discount.percentage_discount} %`}</div>
                                                                                        </div>
                                                                                        {/* <div className="col-3 contentRow01">
                                                                                    <div className="inline">{this.props.language !== "eng" ? "處理分校" : "Site"} :</div>
                                                                                    <div className="inline"> {(this.props.siteLists.find((site: any) => site.id === payment.site_id)).site_key}</div>
                                                                                </div> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                        }

                                        {
                                            this.props.paymentDetails.length <= 0 ? null :
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <div className="subBlockBG">
                                                            <div className="row ">
                                                                <div className="col-12">
                                                                    <div className="subBlockTitle ">{this.props.language !== "eng" ? "關聯付款" : "Related Payment"} ({this.props.paymentDetails.length})</div>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.props.paymentDetails.map((payment: any, index: number) =>
                                                                    <div className="row paymentRow">
                                                                        <div className="col-12">
                                                                            <div className="row paymentContentRow">
                                                                                <div className="col-12">
                                                                                    <div className="row">
                                                                                        <div className="col-8">
                                                                                            <div className="contentMain" key={"purchasedPackage" + index}>{this.props.language !== "eng" ? "已付" : "Amount Paid"} $ {payment.amount_received}</div>
                                                                                        </div>
                                                                                        <div className="col-2">
                                                                                            {
                                                                                                !payment.void ? null :
                                                                                                    <div className="voidAligner">
                                                                                                        <ErrorOutlineOutlinedIcon id="voidWarningIcon" />
                                                                                                        <div className="voidIndicatorContent"> {this.props.language !== "eng" ? "已 VOID" : "VOIDED"} </div>
                                                                                                    </div>
                                                                                            }
                                                                                        </div>
                                                                                        <div className="col-2">
                                                                                            {
                                                                                                payment.payment_method === 'Cash' ?
                                                                                                    <div className="contentMainPrice paymentColor01">
                                                                                                        <div key={"packagePrice" + index}>{this.props.language !== "eng" ? "現金" : "CASH"}</div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    payment.payment_method === 'Credit Card' ?
                                                                                                        <div className="row contentMainPrice paymentColor02">
                                                                                                            <div key={"packagePrice" + index}>{this.props.language !== "eng" ? "信用卡" : "Creadit Card"}</div>

                                                                                                        </div>
                                                                                                        :
                                                                                                        payment.payment_method === 'EPS' ?
                                                                                                            <div className="row contentMainPrice paymentColor03">
                                                                                                                <div key={"packagePrice" + index}>EPS</div>

                                                                                                            </div>
                                                                                                            :
                                                                                                            payment.payment_method === 'Bank Transfer' ?
                                                                                                                <div className="row contentMainPrice paymentColor04">
                                                                                                                    <div key={"packagePrice" + index}>{this.props.language !== "eng" ? "銀行轉帳" : "Bank Transfer"}</div>

                                                                                                                </div>
                                                                                                                :
                                                                                                                payment.payment_method === 'Bank Deposit Slip' ?
                                                                                                                    <div className="row contentMainPrice paymentColor05">
                                                                                                                        <div key={"packagePrice" + index}>{this.props.language !== "eng" ? "入數" : "Deposit Slip"}</div>

                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    payment.payment_method === 'Other' ?
                                                                                                                        <div className="row contentMainPrice paymentColor06">
                                                                                                                            <div key={"packagePrice" + index}>{this.props.language !== "eng" ? "其他" : "Other"}</div>

                                                                                                                        </div>
                                                                                                                        : null
                                                                                            }
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row">
                                                                                        <div className="col-3 contentRow01">
                                                                                            <div className="inline">{this.props.language !== "eng" ? "付款編號" : "Payment ID"} :</div>
                                                                                            <div className="inline"> # {payment.id} {this.props.language !== "eng" ? "號" : ""} </div>
                                                                                        </div>
                                                                                        <div className="col-3 contentRow01">
                                                                                            <div className="inline">{this.props.language !== "eng" ? "付款日期" : "Payment Date"} :</div>
                                                                                            <div className="inline">{moment(payment.created_at).format("YYYY/MM/DD")}</div>
                                                                                        </div>
                                                                                        <div className="col-3 contentRow01">
                                                                                            <div className="inline">{this.props.language !== "eng" ? "處理員工" : "Process Staff"} :</div>
                                                                                            <div className="inline"> # {payment.process_staff_id} {this.props.language !== "eng" ? "號" : ""}</div>
                                                                                        </div>
                                                                                        <div className="col-3 contentRow01">
                                                                                            <div className="inline">{this.props.language !== "eng" ? "處理分校" : "Site"} :</div>
                                                                                            <div className="inline"> {(this.props.siteLists.find((site: any) => site.id === payment.site_id)).site_key}</div>
                                                                                        </div>
                                                                                        {
                                                                                            !payment.void ? null :
                                                                                                <div className="col-3 contentRow01">
                                                                                                    <div className="inline">{this.props.language !== "eng" ? "VOID員工" : "VOID Staff"} :</div>
                                                                                                    <div className="inline"> # {payment.void_staff_id} {this.props.language !== "eng" ? "號" : ""}</div>
                                                                                                </div>
                                                                                        }
                                                                                        {
                                                                                            !payment.void ? null :
                                                                                                <div className="col-3 contentRow01">
                                                                                                    <div className="inline">{this.props.language !== "eng" ? "VOID日期" : "VOID Date"} :</div>
                                                                                                    <div className="inline">{moment(payment.void_date).format("YYYY/MM/DD")}</div>
                                                                                                </div>
                                                                                        }
                                                                                        <div className="col-12 contentRemarksRow">
                                                                                            <div className="inline">{this.props.language !== "eng" ? "付款備注" : "Remarks"} :</div>
                                                                                            <div className="inline">{payment.payment_remarks}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                payment.void ?
                                                                                    <div className="row paymentBaseLine cxlColor"></div>
                                                                                    :
                                                                                    <div className="row paymentBaseLine paymentColor02"></div>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                            }

                            {
                                this.state.displayingSection !== 3 ? null :
                                    <div className="col-9 purchaseDetailsBlock">
                                        {this.props.processedTermsList.length <= 0 ? null :
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="subBlockBG">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="subBlockTitle ">{this.props.language !== "eng" ? "適用條款" : "Applicable Terms"} ({this.props.processedTermsList.length})</div>

                                                            </div>
                                                        </div>
                                                        {
                                                            this.props.processedTermsList.map((term: any, index: number) =>
                                                                <div className="row paymentRow">
                                                                    <div className="col-12">
                                                                        {index + 1}. {term}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                            }
                        </div>
                }
            </Paper>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        langauge: state.auth.language,
        purchaseDetails: state.specificPurchaseDetailsBox.purchaseDetails,
        paymentDetails: state.specificPurchaseDetailsBox.paymentDetails,
        purchasedPackages: state.specificPurchaseDetailsBox.purchasedPackages,
        purchasedProducts: state.specificPurchaseDetailsBox.purchasedProducts,
        usedDiscounts: state.specificPurchaseDetailsBox.usedDiscounts,
        loading: state.specificPurchaseDetailsBox.loading,
        processedTermsList: state.specificPurchaseDetailsBox.processedTermsList,
        // siteLists: state.customers.allSites
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        handleLoadSpecificPurchaseDetails: async (purchaseID: number) => dispatch(await loadSpecificPurchaseDetails(purchaseID)),
        handleReset: async () => dispatch(await unmountClear())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecificPurchaseDetailsBox);