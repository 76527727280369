


export function occupancyParticipationRelatedInfoLoaded (dataReceived:any) {
    return {
        type: "OCCUPANCY_PARTICIPATION_RELATED_INFO_LOADED" as "OCCUPANCY_PARTICIPATION_RELATED_INFO_LOADED",
        customerParticipationInfos: dataReceived.customerParticipationInfos,
        relatedCustomerPackagesInfos: dataReceived.relatedCustomerPackagesInfos,
        relatedCustomerInfos: dataReceived.relatedCustomerInfos,
        absentReasons : dataReceived.absentReasons
    }
}



type displayCxlOccupancyWindowActionsCreators =  
                                typeof occupancyParticipationRelatedInfoLoaded 
                                

export type IDisplayCxlOccupancyWindowActions = ReturnType<displayCxlOccupancyWindowActionsCreators>;