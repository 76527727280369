import React from "react";
import { IRootState, ThunkDispatch } from "../store";
import { connect } from "react-redux";
import './Customers.css'
import { Table, Button, TextField } from "@material-ui/core";
import MoreIcon from '@material-ui/icons/More';
// import ReceiptIcon from '@material-ui/icons/Receipt';
// import TutorIndividualBox from "../TutorIndividualBox/TutorIndividualBox";
import AddCustomerDiscountBox from './AddCustomerDiscountBox/AddCustomerDiscountBox';
import CustomerAddBox from "../CustomerAddBox/CustomerAddBox";
import MarkReferralDetailsBox from "./MarkReferralDetailsBox/MarkReferralDetailsBox"
import AddIcon from '@material-ui/icons/Add';
import InputBase from '@material-ui/core/InputBase';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress';
import ProcessNoTokenBookingWindow from './ProcessNoTokenBookingWindow/ProcessNoTokenBookingWindow';
import { Beforeunload } from 'react-beforeunload';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

// import SearchIcon from '@material-ui/icons/Search';
// import RemoveIcon from '@material-ui/icons/Remove';
// import ListIcon from '@material-ui/icons/List';
// import { height } from "@material-ui/system";
import {
    customerPage, updateThisCustomerBasicInfos,
    loadGenreTypeList, loadPackagesWithUsageID,
    loadPackagesDetailsForPurchase, loadDiscountsOfCustomer,
    submitNewPurchase, loadPackagesOwnByCustomer,
    periodPackageActivation,
    loadPurchaseTermsLibrary,
    searchCustomerInfo,
    addDiscountToCustomer,
    delCustomerDiscounts,
    loadCustomerRelatedGuardianDetails,
    createCustomerRelatedGuardianDetails,
    updateCustomerRelatedGuardianDetails,
    deleteCustomerRelatedGuardianDetails,
    updateCustomerGroupingID,
    updateCustomerPackageDetailsManually,
    updateReferralDetailsAfterwards,
    processPreviousNoTokenBookingWithNewPackages,
    loadProductsDetailsForPurchase,
    releaseTargetProduct

} from "./thunks";

import SearchIcon from '@material-ui/icons/Search';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FaceIcon from '@material-ui/icons/Face';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import NoteIcon from '@material-ui/icons/Note';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import TranslateIcon from '@material-ui/icons/Translate';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import EditIcon from '@material-ui/icons/Edit';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import WcIcon from '@material-ui/icons/Wc';
import RoomIcon from '@material-ui/icons/Room';
import CakeIcon from '@material-ui/icons/Cake';
import PublicIcon from '@material-ui/icons/Public';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CodeIcon from '@material-ui/icons/Code';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import WarningIcon from '@material-ui/icons/Warning';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PaymentIcon from '@material-ui/icons/Payment';
import InfoIcon from '@material-ui/icons/Info';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import RedeemIcon from '@material-ui/icons/Redeem';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ReceiptIcon from '@material-ui/icons/Receipt';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import InvoiceGenerator from './InvoiceGenreator/InvoiceGenerator'
import moment from "moment";
import CustomerPurchasePaymentHistory from './CustomerPurchasePaymentHistory/CustomerPurchasePaymentHistory';
import CustomerPackageUsageHistory from './CustomerPackageUsageHistory/CustomerPackageUsageHistory';
// import html2pdf from 'html2pdf.js'
import html2canvas from 'html2canvas'
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import { relatedGuardianDetailsReset, loading, exitFromProcessNoTokenBookingWindow } from "./actions";
import ProductListingModule from "./ProductListingModule/ProductListingModule";
import SpecificPurchaseDetailsBox from "./SpecificPurchaseDetailsBox/SpecificPurchaseDetailsBox";

export interface ICustomerProps {
    language: string,
    allCustomerInfo: any,
    totalCustomerCount: number,
    ageGroupList: any,
    genreTypeList: any[],
    packagesWithRightUsage: any[],
    tokenUsageList: any,
    purchasePackagesList: any,
    purchaseProductsList: any,
    discountOwnedBySelectedCustomer: any,
    packagesOwnByCustomer: any,
    purchaseTermsLibrary: any[],
    allDiscountTypes: any,
    relatedGuardianDetails: any,
    allCustomerGroupingList: any,
    allSites: any,
    newlyPurchasedPackagesDetails: any[],
    customerPreviousNoTokenBookings: any[],
    allAttributeTypes: any[],
    allCategoryTypes: any[],
    submitNewPurchase: (dataTrain: any) => void,
    loadDiscountOfCustomer: (userID: number) => void,
    loadPackagesOfCustomer: (customerUserID: number) => void,
    customerPageBeingLoaded: (page: number) => void,
    handleSaveCustomerBasicInfo: (newCustomerBasicInfo: any) => void,
    loadGenreTypeList: () => void,
    loadPackagesWithUsageID: (usageID: number) => void,
    loadPurchasePackagesDetails: (packagesSimInfoArr: any) => void,
    loadPurchaseProductsDetails: (productsSimInfoArr: any) => void,
    periodPackageActivation: (packageID: number) => void,
    loadPurchaseTermsLibrary: () => void,
    handleCustomerSearch: (searchMethod: string, searchInput: any) => void,
    handleAddDiscountToCustomerClick: (discountDetails: any) => void,
    handleDelCustomerDiscountClick: (discountID: number) => void,
    loadRelatedGuardianDetails: (guardianID: number) => void,
    handleCreateGuardianDetails: (guardianDetails: any) => void,
    handleUpdateGuardianDetails: (guardianDetails: any) => void,
    handleDelGuardianDetails: (guardianID: number, customerID: number) => void,
    resetGuardianDetails: () => void,
    handleSaveCustomerGroupingID: (customerGroupingID: number, customerID: number) => void,
    handleUpdateCustomerPackageDetailsManually: (customerPackageDetails: any) => void,
    handleConfirmSubmitReferralDetailsAfterwards: (referringCustomerID: number, customerID: number) => void,
    handleProcessPreviousNoTokenBookingWithNewPackages: (previousNoTokenBooking: any, selectedPackageID: number) => void,
    handleExitFromProcessPreviousNoTokenBookingWindow: () => void,
    triggerLoading: () => void,
    handleReleaseTargetProduct: (productArr: any[]) => void,
    loading: boolean
}
export interface ICustomerState {
    showCustomerManagePage: boolean,
    showCustomerAddBox: boolean,
    selectedCustomerId: number,
    selectedCustomerInfo: any,
    listingPage: number,
    customerPreview: any,
    manageCustomerPageNav: string,
    manageCustomerPage_PurchasePackagesSubNav: string,
    editCustomerBasicDetails: boolean,
    editedCustomerNameChi: any,
    editedCustomerNameEng: any,
    editedCustomerContactNumber: any,
    editedCustomerContactEmail: any,
    editedCustomerContactAddress: any,
    editedCustomerDOB: any,
    editedCustomerNationality: any,
    editedCustomerIdentification_doc_type: any,
    editedCustomerIdentification_doc_num: any,
    editedCustomerIdentification_doc_issue_date: any,
    editedCustomerIdentification_doc_expiry_date: any,
    editedCustomerRemarks: any,
    editedCustomerSex: any,
    customerPurchaseBucket: any,
    showBucketPreview: boolean,
    packagesReadyForPurchase: any,
    productsReadyForPurchase: any[],
    showPaymentPage: boolean,
    paymentMethodChosen: string,
    purchasePaymentSituation: string,
    amountReceived: number,
    usingDiscountForPurchase: any,
    totalPrice: number,
    discountedAmount: number,
    amountPayable: number,
    paymentRemarks: any
    selectPurchaseTermsPage: boolean,
    selectedPurchaseTerms: any[],
    showInvoicePreviewPage: boolean,
    searchingMethod: string,
    searchingInput: string,
    addNewCustomerDiscount: boolean,
    emailPreviewData: any,
    editedGuardianNameChi: string,
    editedGuardianNameEng: string,
    editedGuardianContactNumber: string,
    editedGuardianRelationship: string,
    editGuardianBasicDetails: boolean,
    addGuardianBasicDetails: boolean,
    editCustomerGroupingID: boolean,
    editingCustomerPackageDetails: any,
    showMarkReferralDetailsBox: boolean,
    sendEmailForThisPurchase: boolean,
    showCustomerExpiredPackages: boolean,
    searchTokenWordings: string,
    repeatPreventer: boolean,
    //SHOW PAGE INDICATOR
    showSpecificPurchaseDetailsBox: boolean,
    //PAGE KEY INIT DATA
    selectedPurchaseIDForSpecificPurchaseDetailsBox: number
}

class Customer extends React.Component<ICustomerProps, ICustomerState> {
    constructor(props: ICustomerProps) {
        super(props);
        this.state = {
            showCustomerManagePage: false,
            showCustomerAddBox: false,
            selectedCustomerId: 0,
            selectedCustomerInfo: '-',
            listingPage: 1,
            customerPreview: '-',
            manageCustomerPageNav: '-',
            manageCustomerPage_PurchasePackagesSubNav: 'usage1',
            editCustomerBasicDetails: false,
            editedCustomerNameChi: '-',
            editedCustomerNameEng: '-',
            editedCustomerContactNumber: '-',
            editedCustomerContactEmail: '-',
            editedCustomerContactAddress: '-',
            editedCustomerDOB: '-',
            editedCustomerNationality: '-',
            editedCustomerIdentification_doc_type: '-',
            editedCustomerIdentification_doc_num: '-',
            editedCustomerIdentification_doc_issue_date: '-',
            editedCustomerIdentification_doc_expiry_date: '-',
            editedCustomerRemarks: '-',
            editedCustomerSex: '-',
            customerPurchaseBucket: { tokenPackages: [], products: [] },
            showBucketPreview: false,
            packagesReadyForPurchase: '-',
            productsReadyForPurchase: [],
            showPaymentPage: false,
            paymentMethodChosen: 'Cash',
            purchasePaymentSituation: 'Unpaid',
            amountReceived: 0,
            usingDiscountForPurchase: { discount_name: "", cash_discount: null, percentage_discount: null, count: 0, maxCount: 0 },
            totalPrice: 0,
            discountedAmount: 0,
            amountPayable: 0,
            paymentRemarks: '-',
            selectPurchaseTermsPage: false,
            selectedPurchaseTerms: [],
            showInvoicePreviewPage: false,
            searchingMethod: 'customerID',
            searchingInput: '',
            addNewCustomerDiscount: false,
            emailPreviewData: '',
            editedGuardianNameChi: "",
            editedGuardianNameEng: "",
            editedGuardianContactNumber: "",
            editedGuardianRelationship: "",
            editGuardianBasicDetails: false,
            addGuardianBasicDetails: false,
            editCustomerGroupingID: false,
            editingCustomerPackageDetails: { id: 0 },
            showMarkReferralDetailsBox: false,
            sendEmailForThisPurchase: true,
            showCustomerExpiredPackages: false,
            searchTokenWordings: '',
            showSpecificPurchaseDetailsBox: false,
            selectedPurchaseIDForSpecificPurchaseDetailsBox: 0,
            repeatPreventer: false
        }
    }

    private handleManageClick = async (customerId: number, tokenInBucket: any) => {
        if (this.state.selectedCustomerId === 0) {
            await this.setState({
            })
            await this.props.loadPackagesOfCustomer(customerId);
            await this.props.loadDiscountOfCustomer(customerId);
            let guardianID = (await this.props.allCustomerInfo.filter((customer: any) => customer.user_id === customerId))[0].guardian_id
            if (guardianID) {
                await this.props.loadRelatedGuardianDetails(guardianID);
            }
            let pageShouldBeLoaded = (customerId < 6280 ? Math.ceil(customerId / 10) : Math.floor(customerId / 10));

            await this.setState({
                showCustomerManagePage: !this.state.showCustomerManagePage,
                selectedCustomerId: customerId,
                selectedCustomerInfo: this.props.allCustomerInfo.filter((customer: any) => customer.user_id === customerId),
                manageCustomerPageNav: 'checkCustomerDetails',
                listingPage: pageShouldBeLoaded,

            })
        } else {
            let customer = (await this.props.allCustomerInfo.filter((customer: any) => customer.user_id === this.state.selectedCustomerId))[0]
            let pageShouldBeLoaded = (customer.id < 6280 ? Math.ceil(customer.id / 10) : Math.floor(customer.id / 10));
            this.props.triggerLoading();
            setTimeout(() => this.props.customerPageBeingLoaded(pageShouldBeLoaded), 1000)
            // this.props.customerPageBeingLoaded(pageShouldBeLoaded)
            if (tokenInBucket !== 0) {
                let ans = window.confirm('Discard Content in Bucket?');
                if (ans) {
                    await this.setState({
                        showCustomerManagePage: !this.state.showCustomerManagePage,
                        editCustomerBasicDetails: false,
                        selectedCustomerId: 0,
                        customerPreview: "-",
                        selectedCustomerInfo: '-',
                        customerPurchaseBucket: { tokenPackages: [], products: [] },
                        amountPayable: 0,
                        totalPrice: 0,
                        listingPage: pageShouldBeLoaded,
                        editedGuardianNameChi: "",
                        editedGuardianNameEng: "",
                        editedGuardianContactNumber: "",
                        editedGuardianRelationship: "",
                        editGuardianBasicDetails: false,
                        addGuardianBasicDetails: false,
                        editCustomerGroupingID: false
                    })
                }
            } else {
                await this.setState({
                    showCustomerManagePage: !this.state.showCustomerManagePage,
                    editCustomerBasicDetails: false,
                    selectedCustomerId: 0,
                    customerPreview: "-",
                    selectedCustomerInfo: '-',
                    customerPurchaseBucket: { tokenPackages: [], products: [] },
                    listingPage: pageShouldBeLoaded,
                    editedGuardianNameChi: "",
                    editedGuardianNameEng: "",
                    editedGuardianContactNumber: "",
                    editedGuardianRelationship: "",
                    editGuardianBasicDetails: false,
                    addGuardianBasicDetails: false,
                    editCustomerGroupingID: false,
                    showCustomerExpiredPackages: false

                })
            }
            this.props.resetGuardianDetails();
        }
    }
    private handleAddCustomerClick = async () => {
        this.setState({
            showCustomerAddBox: !this.state.showCustomerAddBox
        })

        this.props.customerPageBeingLoaded(1)
    }

    private handleCustomerSelection = async (userId: any) => {
        await this.setState({
            customerPreview: userId
        })
    }
    private handleClearCustomerPreview = async () => {
        await this.setState({
            customerPreview: '-'
        })

    }
    private handleCustomerPageNavChange = async (pageNav: string) => {
        if (pageNav === 'customerBuyToken') {
            let rightUsageID = await (this.props.tokenUsageList.find((usage: any) => usage.usage === "Class Participation")).id
            await this.props.loadGenreTypeList();
            await this.props.loadPackagesWithUsageID(rightUsageID);
        }
        if (pageNav === 'checkCustomerDetails') {
            await this.props.triggerLoading();
            await setTimeout(() => this.props.loadPackagesOfCustomer(this.state.selectedCustomerId), 1000)
            await setTimeout(() => this.props.loadDiscountOfCustomer(this.state.selectedCustomerId), 1000)
        }
        await this.setState({
            manageCustomerPageNav: pageNav,
            manageCustomerPage_PurchasePackagesSubNav: 'Class Participation'
        })
    }
    private handleDelGuardianDetailsClick = async () => {
        let confirmation = window.confirm("確定要刪除此相關聯絡人嗎？");
        if (confirmation) {
            this.props.handleDelGuardianDetails(this.props.relatedGuardianDetails.id, this.state.selectedCustomerInfo[0].id);
        }
    }

    private handleSaveGuardianEditedDetailsClick = async () => {
        let guardianDetails = {
            id: (this.props.relatedGuardianDetails === "") ? null : this.props.relatedGuardianDetails.id,
            name_chi: this.state.editedGuardianNameChi,
            name_eng: this.state.editedGuardianNameEng,
            contact_number: this.state.editedGuardianContactNumber,
            relationship: this.state.editedGuardianRelationship,
            customer_id: this.state.selectedCustomerInfo[0].id
        }
        if (this.state.editGuardianBasicDetails) {
            this.props.handleUpdateGuardianDetails(guardianDetails);
            await this.setState({
                editGuardianBasicDetails: false,
                editedGuardianNameChi: "",
                editedGuardianNameEng: "",
                editedGuardianContactNumber: "",
                editedGuardianRelationship: ""
            })
        }
        if (this.state.addGuardianBasicDetails) {
            this.props.handleCreateGuardianDetails(guardianDetails);
            await this.setState({
                addGuardianBasicDetails: false,
                editedGuardianNameChi: "",
                editedGuardianNameEng: "",
                editedGuardianContactNumber: "",
                editedGuardianRelationship: ""
            })
        }
    }

    private handleCreateGuardianBasicDetailsClick = async () => {
        await this.setState({
            addGuardianBasicDetails: true
        })
    }
    private handleEditGuardianBasicDetailsClick = async () => {
        if (this.state.addGuardianBasicDetails) {
            await this.setState({
                addGuardianBasicDetails: !this.state.addGuardianBasicDetails
            })
        } else {
            await this.setState({
                editGuardianBasicDetails: !this.state.editGuardianBasicDetails,
                editedGuardianNameChi: this.props.relatedGuardianDetails.name_chi,
                editedGuardianNameEng: this.props.relatedGuardianDetails.name_eng,
                editedGuardianContactNumber: this.props.relatedGuardianDetails.contact_number,
                editedGuardianRelationship: this.props.relatedGuardianDetails.relationship
            })
        }
    }
    private handleNameChiEditGuardianInput = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        await this.setState({
            editedGuardianNameChi: event.target.value
        })
    }
    private handleNameEngEditGuardianInput = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        await this.setState({
            editedGuardianNameEng: event.target.value
        })
    }
    private handleContactNumberEditGuardianInput = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        await this.setState({
            editedGuardianContactNumber: event.target.value
        })
    }
    private handleRelationshipEditGuardianInput = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        await this.setState({
            editedGuardianRelationship: event.target.value
        })
    }

    private handleEditCustomerBasicDetailsClick = async () => {
        await this.setState({
            editCustomerBasicDetails: !this.state.editCustomerBasicDetails,
            editedCustomerNameChi: this.state.selectedCustomerInfo[0].name_chi,
            editedCustomerNameEng: this.state.selectedCustomerInfo[0].name_eng,
            editedCustomerContactNumber: this.state.selectedCustomerInfo[0].tel,
            editedCustomerContactEmail: this.state.selectedCustomerInfo[0].email,
            editedCustomerContactAddress: this.state.selectedCustomerInfo[0].address,
            editedCustomerDOB: this.state.selectedCustomerInfo[0].date_of_birth,
            editedCustomerNationality: this.state.selectedCustomerInfo[0].nationality,
            editedCustomerIdentification_doc_type: this.state.selectedCustomerInfo[0].identification_doc_type,
            editedCustomerIdentification_doc_num: this.state.selectedCustomerInfo[0].identification_doc_number,
            editedCustomerIdentification_doc_issue_date: this.state.selectedCustomerInfo[0].identification_doc_issue_date,
            editedCustomerIdentification_doc_expiry_date: this.state.selectedCustomerInfo[0].identification_doc_expiry_date,
            editedCustomerRemarks: this.state.selectedCustomerInfo[0].remarks,
            editedCustomerSex: this.state.selectedCustomerInfo[0].sex
        })
    }

    private handleNameChiEditInput = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        await this.setState({
            editedCustomerNameChi: event.target.value
        })
    }
    private handleNameEngEditInput = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        await this.setState({
            editedCustomerNameEng: event.target.value
        })
    }
    private handleContactNumberEditInput = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        await this.setState({
            editedCustomerContactNumber: event.target.value
        })
    }
    private handleEmailEditInput = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        await this.setState({
            editedCustomerContactEmail: event.target.value
        })
    }
    private handleAddressEditInput = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        await this.setState({
            editedCustomerContactAddress: event.target.value
        })
    }
    private handleDOBEditInput = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        let DOBISOString = moment(event.currentTarget.value).toISOString()
        await this.setState({
            editedCustomerDOB: DOBISOString
        })

    }
    private handleNationalityEditInput = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        await this.setState({
            editedCustomerNationality: event.target.value
        })
    }
    private handleIdentificationDocTypeEditInput = async (event: React.ChangeEvent<{ value: unknown }>) => {
        await this.setState({
            editedCustomerIdentification_doc_type: event.target.value
        })
    }
    private handleIdentificationDocNumEditInput = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        await this.setState({
            editedCustomerIdentification_doc_num: event.target.value
        })
    }
    private handleRemarksEditInput = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        await this.setState({
            editedCustomerRemarks: event.target.value
        })
    }
    private handleSexEditInput = async (event: React.ChangeEvent<{ value: unknown }>) => {
        await this.setState({
            editedCustomerSex: event.target.value
        })
    }
    private handleIdentificationDocIssueDateEditInput = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        let DOBISOString = moment(event.currentTarget.value).toISOString()
        await this.setState({
            editedCustomerIdentification_doc_issue_date: DOBISOString
        })

    }
    private handleIdentificationDocExpiryDateEditInput = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        let DOBISOString = moment(event.currentTarget.value).toISOString()
        await this.setState({
            editedCustomerIdentification_doc_expiry_date: DOBISOString
        })

    }
    private handleSaveCustomerEditedDetailsClick = async () => {
        let newCustomerBasicDetails = {
            user_id: this.state.selectedCustomerInfo[0].user_id,
            name_chi: this.state.editedCustomerNameChi,
            name_eng: this.state.editedCustomerNameEng,
            tel: this.state.editedCustomerContactNumber,
            email: this.state.editedCustomerContactEmail,
            address: this.state.editedCustomerContactAddress,
            date_of_birth: this.state.editedCustomerDOB,
            nationality: this.state.editedCustomerNationality,
            identification_doc_type: this.state.editedCustomerIdentification_doc_type,
            identification_doc_number: this.state.editedCustomerIdentification_doc_num,
            identification_doc_issue_date: this.state.editedCustomerIdentification_doc_issue_date,
            identification_doc_expiry_date: this.state.editedCustomerIdentification_doc_expiry_date,
            sex: this.state.editedCustomerSex,
            mark_delete: false,
            listingPage: this.state.listingPage
        }
        await this.props.handleSaveCustomerBasicInfo(newCustomerBasicDetails);

        alert("Customer Basic Details Update Completed.")
        await this.setState({
            editCustomerBasicDetails: !this.state.editCustomerBasicDetails,
            selectedCustomerInfo: [{
                ...this.state.selectedCustomerInfo[0],
                name_chi: this.state.editedCustomerNameChi,
                name_eng: this.state.editedCustomerNameEng,
                tel: this.state.editedCustomerContactNumber,
                email: this.state.editedCustomerContactEmail,
                address: this.state.editedCustomerContactAddress,
                date_of_birth: this.state.editedCustomerDOB,
                nationality: this.state.editedCustomerNationality,
                identification_doc_type: this.state.editedCustomerIdentification_doc_type,
                identification_doc_number: this.state.editedCustomerIdentification_doc_num,
                identification_doc_issue_date: this.state.editedCustomerIdentification_doc_issue_date,
                identification_doc_expiry_date: this.state.editedCustomerIdentification_doc_expiry_date,
                sex: this.state.editedCustomerSex,
            }]
        })
    }

    private handleCustomer_PurchasePackagesPageNavChange = async (pageNav: string) => {
        let rightUsageID = (this.props.tokenUsageList.find((usage: any) => usage.usage === pageNav)).id
        await this.props.triggerLoading();
        setTimeout(() => this.props.loadPackagesWithUsageID(rightUsageID), 1000)

        await this.setState({
            manageCustomerPage_PurchasePackagesSubNav: pageNav
        })
    }

    private handlePurchasePackageClick = async (packageID: number, packagePrice: string) => {
        if (this.state.customerPurchaseBucket.tokenPackages.length === 0) {
            let newAddedPackage = { id: packageID, price: parseFloat(packagePrice) }
            await this.setState({
                customerPurchaseBucket: {
                    ...this.state.customerPurchaseBucket,
                    tokenPackages: [newAddedPackage]
                }
            })
        } else {
            let newTokenPackages: any[] = this.state.customerPurchaseBucket.tokenPackages;
            let newAddedPackage = { id: packageID, price: parseFloat(packagePrice) }

            newTokenPackages.push(newAddedPackage)
            await this.setState({
                customerPurchaseBucket: {
                    ...this.state.customerPurchaseBucket,
                    tokenPackages: newTokenPackages
                }
            })
        }
        let newTotalPrice = this.state.totalPrice + parseFloat(packagePrice)

        await this.setState({
            totalPrice: newTotalPrice
        })
    }

    private handlePurchaseProductClick = async (productSKU: string, productPrice: string) => {
        if (this.state.customerPurchaseBucket.products.length === 0) {
            let newAddedProduct = { SKU: productSKU, price: productPrice }
            await this.setState({
                customerPurchaseBucket: {
                    ...this.state.customerPurchaseBucket,
                    products: [newAddedProduct]
                }
            })
        } else {
            let newAddedProducts = this.state.customerPurchaseBucket.products;
            let newAddedProduct = { SKU: productSKU, price: productPrice }
            newAddedProducts.push(newAddedProduct);
            await this.setState({
                customerPurchaseBucket: {
                    ...this.state.customerPurchaseBucket,
                    products: newAddedProducts
                }
            })
        }
        let newTotalPrice = this.state.totalPrice + parseFloat(productPrice)
        await this.setState({
            totalPrice: newTotalPrice
        })
    }

    private handleShowBucketPreview = async () => {
        if (this.state.customerPurchaseBucket.products.length === 0 && this.state.customerPurchaseBucket.tokenPackages.length === 0) {
            alert('Bucket is Empty');
        } else {
            await this.props.triggerLoading();
            await this.setState({
                showBucketPreview: !this.state.showBucketPreview,
            })
            if (this.state.showBucketPreview === false && this.state.showPaymentPage === false && this.state.selectPurchaseTermsPage === false && this.state.showInvoicePreviewPage === false) {
                await this.props.handleReleaseTargetProduct(this.props.purchaseProductsList);
            } else {
                if (this.state.customerPurchaseBucket.tokenPackages.length > 0) {
                    await this.props.loadPurchasePackagesDetails(this.state.customerPurchaseBucket.tokenPackages);
                }
                if (this.state.customerPurchaseBucket.products.length > 0) {
                    await this.props.loadPurchaseProductsDetails(this.state.customerPurchaseBucket.products);
                }
            }
            await this.setState({
                packagesReadyForPurchase: this.props.purchasePackagesList,
                productsReadyForPurchase: this.props.purchaseProductsList
            })
        }
    }

    private handleDelPurchasePackageClick = async (packageProcessingIndex: number) => {
        let x = 1;
        let y = 1
        let newPackageReadyForPurchase = this.state.packagesReadyForPurchase;
        let newCustomerPurchaseBucket = this.state.customerPurchaseBucket.tokenPackages;
        await this.props.handleReleaseTargetProduct(this.props.purchaseProductsList);


        await this.setState({
            totalPrice: this.state.totalPrice - parseFloat(newCustomerPurchaseBucket[packageProcessingIndex].price),
            amountPayable: this.state.totalPrice - parseFloat(newCustomerPurchaseBucket[packageProcessingIndex].price)
        })
        newPackageReadyForPurchase.splice(packageProcessingIndex, 1);

        newCustomerPurchaseBucket.splice(packageProcessingIndex, 1);
        await this.setState({
            packagesReadyForPurchase: newPackageReadyForPurchase,
            customerPurchaseBucket: {
                ...this.state.customerPurchaseBucket,
                tokenPackages: newCustomerPurchaseBucket
            }
        })
        console.log(newCustomerPurchaseBucket,)
        if (newCustomerPurchaseBucket.length === 0 && newPackageReadyForPurchase.length === 0) {
            alert('Bucket is Empty');

            await this.setState({
                showBucketPreview: !this.state.showBucketPreview,

            })
        }

        // for (let i = 0; i < this.state.packagesReadyForPurchase.length; i++) {
        //     if (x === 1) {
        //         if (newPackageReadyForPurchase[i].id === packageID) {
        //             await this.setState({
        //                 totalPrice: this.state.totalPrice - parseFloat(newCustomerPurchaseBucket[i].price),
        //                 amountPayable: this.state.totalPrice - parseFloat(newCustomerPurchaseBucket[i].price)
        //             })
        //             newPackageReadyForPurchase.splice(i, 1);
        //             await this.setState({
        //                 packagesReadyForPurchase: newPackageReadyForPurchase,

        //             })
        //             x = 0;
        //         }
        //     }
        //     if (y === 1) {
        //         if (this.state.customerPurchaseBucket.tokenPackages[i].id === packageID) {
        //             newCustomerPurchaseBucket.splice(i, 1);
        //             await this.setState({
        //                 customerPurchaseBucket: {
        //                     ...this.state.customerPurchaseBucket,
        //                     tokenPackages: newCustomerPurchaseBucket
        //                 }
        //             })
        //             y = 0;
        //             if (newCustomerPurchaseBucket.length && this.state.customerPurchaseBucket.products.length === 0) {
        //                 alert('Bucket is Empty');

        //                 await this.setState({
        //                     showBucketPreview: !this.state.showBucketPreview,

        //                 })
        //             }
        //         }
        //     }
        // }
    }
    private handleDelPurchaseProductClick = async (productSKU: number) => {

        let x = 1;
        let y = 1
        let newProductReadyForPurchase = this.state.productsReadyForPurchase;
        let newCustomerPurchaseBucket = this.state.customerPurchaseBucket.products;
        await this.props.handleReleaseTargetProduct(this.props.purchaseProductsList);

        for (let i = 0; i < this.state.productsReadyForPurchase.length; i++) {
            if (x === 1) {
                console.log(productSKU)
                console.log(newProductReadyForPurchase[i].sku);
                if (newProductReadyForPurchase[i].sku === productSKU) {
                    await this.setState({
                        totalPrice: this.state.totalPrice - parseFloat(newCustomerPurchaseBucket[i].price),
                        amountPayable: this.state.totalPrice - parseFloat(newCustomerPurchaseBucket[i].price)
                    })
                    newProductReadyForPurchase.splice(i, 1);
                    await this.setState({
                        productsReadyForPurchase: newProductReadyForPurchase,

                    })
                    x = 0;
                }
            }
            if (y === 1) {
                if (this.state.customerPurchaseBucket.products[i].SKU === productSKU) {
                    newCustomerPurchaseBucket.splice(i, 1);
                    await this.setState({
                        customerPurchaseBucket: {
                            ...this.state.customerPurchaseBucket,
                            products: newCustomerPurchaseBucket
                        }
                    })
                    y = 0;
                    if (newCustomerPurchaseBucket.length === 0 && this.state.customerPurchaseBucket.tokenPackages.length === 0) {
                        alert('Bucket is Empty');

                        await this.setState({
                            showBucketPreview: !this.state.showBucketPreview,

                        })
                    }
                }
            }
        }
    }

    private handlePaymentClick = async () => {
        await this.props.triggerLoading();
        await setTimeout(async () => await this.props.loadDiscountOfCustomer(this.state.selectedCustomerId), 1000)
        let amountPayable = this.state.totalPrice
        if (this.state.selectPurchaseTermsPage) {
            await this.setState({
                selectPurchaseTermsPage: false
            })
        }
        await this.setState({
            showBucketPreview: !this.state.showBucketPreview,
            showPaymentPage: !this.state.showPaymentPage,
            amountPayable: amountPayable,
            purchasePaymentSituation: 'Partly'
        })

        if (!this.state.showPaymentPage) {
            this.setState({
                usingDiscountForPurchase: { discount_name: "", cash_discount: null, percentage_discount: null, count: 0 },
                discountedAmount: 0,
                amountReceived: 0
            })
        }

    }

    private handlePaymentMethodSelection = async (paymentMethod: string) => {
        await this.setState({
            paymentMethodChosen: paymentMethod
        })
    }

    private handleUseDiscountClick = async (discount: any) => {
        console.log(discount)
        if (this.state.usingDiscountForPurchase.discount_name === discount.discount_name) {

            await this.setState({
                usingDiscountForPurchase: { discount_name: "", cash_discount: null, percentage_discount: null, count: 0 },
                discountedAmount: 0,
                amountPayable: this.state.totalPrice,

            })
        } else {
            await this.setState({
                usingDiscountForPurchase: {
                    ...this.state.usingDiscountForPurchase,
                    discount_name: discount.discount_name,
                    cash_discount: (discount.cash_discount ? discount.cash_discount : null),
                    percentage_discount: (discount.percentage_discount ? discount.percentage_discount : null),
                    count: (discount.count > 5 ? 5 : discount.count),
                    maxCount: discount.count
                },
                discountedAmount: 0,
                amountPayable: this.state.totalPrice
            })

            let discountedAmount = 0
            let usingDiscount = this.props.discountOwnedBySelectedCustomer.find((discountDetails: any) => discountDetails.discount_name == discount.discount_name && discountDetails.cash_discount === discount.cash_discount && discountDetails.percentage_discount === discount.percentage_discount);
            if (usingDiscount.cash_discount) {
                discountedAmount = parseFloat(usingDiscount.cash_discount) * this.state.usingDiscountForPurchase.count
            } else {
                discountedAmount = this.state.totalPrice * ((usingDiscount.percentage_discount / 100) * this.state.usingDiscountForPurchase.count)
                console.log(discountedAmount)
            }
            let newAmountPayable = this.state.amountPayable - discountedAmount
            await this.setState({
                discountedAmount: discountedAmount,
                amountPayable: newAmountPayable
            })
        }
        if (this.state.purchasePaymentSituation === "Fully") {
            await this.setState({
                amountReceived: this.state.amountPayable
            })
        }
        if (this.state.amountReceived === this.state.amountPayable) {
            await this.setState({
                purchasePaymentSituation: "Fully"
            })
        }

    }

    private handlePaymentStatusSelection = async (paymentStatus: string) => {
        if (paymentStatus === 'Fully') {

            await this.setState({
                amountReceived: this.state.amountPayable
            })
        } else if (paymentStatus === 'Partly') {
            await this.setState({
                amountReceived: 0
            })
        } else {
            await this.setState({
                amountReceived: 0
            })
        }

        await this.setState({
            purchasePaymentSituation: paymentStatus,
        })
    }

    private handlePaymentAmountInput = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let re = /^[0-9\b]+$/;
        if (event.currentTarget.value === '') {
            await this.setState({
                amountReceived: 0
            })
        } else if (re.test(event.currentTarget.value)) {
            await this.setState({
                amountReceived: parseInt(event.currentTarget.value)
            })

            let paymentLeft = this.state.amountPayable - this.state.amountReceived;

            if (paymentLeft === 0) {
                await this.setState({
                    purchasePaymentSituation: 'Fully',

                })
            }

        } else {
            alert('Please Insert Numbers.');
        }
    }
    private handleConfirmPurchaseClick = async () => {
        await this.setState({
            repeatPreventer: true
        });
        function parseJwt(token: string) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        };
        let clientToken = localStorage.getItem('token');
        let tokenPayload: any = {}
        if (clientToken) {
            tokenPayload = parseJwt(clientToken)
        }
        let usingDiscountTypeArr = this.props.discountOwnedBySelectedCustomer.filter((discount: any) => discount.discount_name === this.state.usingDiscountForPurchase.discount_name);
        let usingDiscountArr = [];
        for (let i = 0; i < this.state.usingDiscountForPurchase.count; i++) {
            usingDiscountArr.push(usingDiscountTypeArr[i].id);
        }


        let purchaseData = {
            totalPrice: this.state.totalPrice,
            usingDiscountForPurchase: usingDiscountArr,
            discountedAmount: this.state.discountedAmount,
            amountPayable: this.state.amountPayable,
            purchaseContent: this.state.customerPurchaseBucket,
            packagesDetails: this.state.packagesReadyForPurchase,
            productsDetails: this.state.productsReadyForPurchase,
            purchaseTermsIDs: this.state.selectedPurchaseTerms
        }

        let paymentData = {
            paymentStatus: this.state.purchasePaymentSituation,
            paymentMethod: this.state.paymentMethodChosen,
            amountReceived: this.state.amountReceived,
            remarks: this.state.paymentRemarks
        }

        let dataTrain = {
            purchaseData: purchaseData,
            paymentData: paymentData,
            staffID: tokenPayload.id,
            customerUserID: this.state.selectedCustomerId,
            sendEmail: this.state.sendEmailForThisPurchase
        }
        await this.props.triggerLoading();
        await setTimeout(async () => await this.props.submitNewPurchase(dataTrain), 1000)
        await setTimeout(async () => await this.props.loadPackagesOfCustomer(this.state.selectedCustomerId), 1100)
        await setTimeout(async () => await this.props.loadDiscountOfCustomer(this.state.selectedCustomerId), 1100)
        await setTimeout(async () => await this.setState({
            customerPurchaseBucket: { tokenPackages: [], products: [] },
            showBucketPreview: false,
            packagesReadyForPurchase: '-',
            productsReadyForPurchase: [],
            showPaymentPage: false,
            showInvoicePreviewPage: false,
            paymentMethodChosen: 'Cash',
            purchasePaymentSituation: 'Unpaid',
            amountReceived: 0,
            usingDiscountForPurchase: { discount_name: "", cash_discount: null, percentage_discount: null, count: 0 },
            totalPrice: 0,
            discountedAmount: 0,
            amountPayable: 0,
            paymentRemarks: '-',
            manageCustomerPageNav: 'checkCustomerDetails',
            repeatPreventer: false
        }), 1110)
    }

    private handlePeriodPackageActivateClick = async (packageID: number) => {
        let confirmation = window.confirm('確定激活套券嗎？')
        if (confirmation) {
            await this.props.triggerLoading();

            await this.props.periodPackageActivation(packageID);
            await this.props.loadPackagesOfCustomer(this.state.selectedCustomerId)
        }
    }

    private handleGoSelectPurchaseTermsPage = async () => {
        await this.props.triggerLoading();

        this.props.loadPurchaseTermsLibrary()

        await this.setState({
            showPaymentPage: false,
            selectPurchaseTermsPage: true
        })

    }

    private handleGoInvoicePreviewPage = async () => {
        if (this.state.selectedPurchaseTerms.includes(0)) {
            window.alert('未完成填入條款詳情!')
        } else {
            let usingDiscountTypeArr = this.props.discountOwnedBySelectedCustomer.filter((discount: any) => discount.discount_name === this.state.usingDiscountForPurchase.discount_name);
            let usingDiscountArr = [];
            let usingDiscountPreview = []
            for (let i = 0; i < this.state.usingDiscountForPurchase.count; i++) {
                usingDiscountArr.push(usingDiscountTypeArr[i].id);
                usingDiscountPreview.push(usingDiscountTypeArr[i])
            }
            let purchaseData = {
                totalPrice: this.state.totalPrice,
                usingDiscountForPurchase: usingDiscountArr,
                discountedAmount: this.state.discountedAmount,
                amountPayable: this.state.amountPayable,
                purchaseContent: this.state.customerPurchaseBucket,
                packagesDetails: this.state.packagesReadyForPurchase,
                productDetails: this.state.productsReadyForPurchase,
                purchaseTermsIDs: this.state.selectedPurchaseTerms,
            }

            let paymentData = {
                paymentStatus: this.state.purchasePaymentSituation,
                paymentMethod: this.state.paymentMethodChosen,
                amountReceived: this.state.amountReceived,
                remarks: this.state.paymentRemarks
            }
            let usedDiscountDetails = usingDiscountPreview;


            let dataTrain = {
                purchaseData: purchaseData,
                paymentData: paymentData,
                customerDetails: (await this.props.allCustomerInfo.filter((customer: any) => customer.user_id === this.state.selectedCustomerId))[0],
                purchaseTermsLibrary: this.props.purchaseTermsLibrary,
                usedDiscountDetails: usedDiscountDetails
            }
            await this.setState({
                selectPurchaseTermsPage: !this.state.selectPurchaseTermsPage,
                showInvoicePreviewPage: !this.state.showInvoicePreviewPage,
                emailPreviewData: dataTrain
            })
        }
    }

    private handleAddNewTermsSlot = async () => {
        if (this.state.selectedPurchaseTerms.length === 0) {
            let newSelectedTermsSlots = this.state.selectedPurchaseTerms
            newSelectedTermsSlots.push(0)
            await this.setState({
                selectedPurchaseTerms: newSelectedTermsSlots
            })
        } else {
            if (!this.state.selectedPurchaseTerms.includes(0)) {
                let newSelectedTermsSlots = this.state.selectedPurchaseTerms
                newSelectedTermsSlots.push(0)
                await this.setState({
                    selectedPurchaseTerms: newSelectedTermsSlots
                })
            } else {
                window.alert('請為現有空置列填入條款。')
            }
        }
    }

    private handleTermChange = async (event: any, termID: number) => {
        let newSelectedPurchaseTerms = this.state.selectedPurchaseTerms;
        let finishedEditing = false;
        if (this.state.selectedPurchaseTerms.includes(event.target.value)) {
            window.alert('錯誤選擇重覆條款')
        } else {
            for (let i = 0; i < this.state.selectedPurchaseTerms.length; i++) {
                if (newSelectedPurchaseTerms[i] === termID) {
                    newSelectedPurchaseTerms[i] = event.target.value;
                    await this.setState({
                        selectedPurchaseTerms: newSelectedPurchaseTerms
                    })
                    finishedEditing = true;
                    break;
                }
            }
            if (!finishedEditing) {
                for (let id of newSelectedPurchaseTerms) {
                    if (id === 0) {
                        id = event.target.value;
                        await this.setState({
                            selectedPurchaseTerms: newSelectedPurchaseTerms
                        })
                    }
                }
            }
        }
    }

    private handleDelTerms = async (termID: number) => {
        let newSelectedPurchaseTerms = this.state.selectedPurchaseTerms;
        newSelectedPurchaseTerms = newSelectedPurchaseTerms.filter((term: number) => term !== termID);
        await this.setState({
            selectedPurchaseTerms: newSelectedPurchaseTerms
        })
    }

    private handleSearchingMethodChange = async (event: any) => {
        await this.setState({
            searchingMethod: event.target.value
        })
    }

    private handleSearchingInputChange = async (event: any) => {
        await this.setState({
            searchingInput: event.target.value
        })
    }

    private handleSearchClick = async () => {
        this.props.handleCustomerSearch(this.state.searchingMethod, this.state.searchingInput);
        await this.setState({
            customerPreview: "-"
        })
    }
    private handleSearchEnterPress = async (event: any) => {
        event.preventDefault();
        this.props.handleCustomerSearch(this.state.searchingMethod, this.state.searchingInput);
        await this.setState({
            customerPreview: "-"
        })
    }

    private handleShowNextPageCustomerClick = async () => {
        await this.setState({
            listingPage: this.state.listingPage + 1,
            customerPreview: "-"
        })
        this.props.triggerLoading();
        await setTimeout(async () => await this.props.customerPageBeingLoaded(this.state.listingPage), 1000)
        // this.props.customerPageBeingLoaded(this.state.listingPage)
    }

    private handleShowPreviousPageCustomerClick = async () => {
        await this.setState({
            listingPage: this.state.listingPage - 1,
            customerPreview: "-"
        })
        this.props.triggerLoading();
        await setTimeout(async () => await this.props.customerPageBeingLoaded(this.state.listingPage), 1000)
    }
    private handleAddNewCustomerDiscountTriggerClick = async () => {
        await this.setState({
            addNewCustomerDiscount: !this.state.addNewCustomerDiscount
        })
    }

    private handleAddDiscountToCustomer = async (discountDetails: any) => {
        let customerID = (await this.props.allCustomerInfo.filter((customer: any) => customer.user_id === this.state.selectedCustomerId))[0].id;
        discountDetails.customer_id = customerID;
        await this.props.triggerLoading();

        await this.props.handleAddDiscountToCustomerClick(discountDetails);

        await this.props.loadDiscountOfCustomer(this.state.selectedCustomerId);
    }

    private handleDelCustomerDiscountClick = async (discountID: number) => {
        let confirmation = window.confirm('確定要{this.props.language !== "eng"? "刪除" : "Delete"}顧客此優惠？');
        if (confirmation) {
            await this.props.handleDelCustomerDiscountClick(discountID);
        }
        await this.props.triggerLoading();

        await this.props.loadDiscountOfCustomer(this.state.selectedCustomerId);

    }

    private handleGoToFirstPageCustomerClick = async () => {
        this.props.triggerLoading();
        await setTimeout(async () => await this.props.customerPageBeingLoaded(1), 1000)
        await this.setState({
            listingPage: 1,
            customerPreview: "-"
        })

    }
    private handleGoToLastPageCustomerClick = async () => {
        this.props.triggerLoading();
        let lastPage = Math.floor(parseInt(`${this.props.totalCustomerCount}`) / 10)
        await setTimeout(async () => await this.props.customerPageBeingLoaded(lastPage), 1000)
        await this.setState({
            listingPage: lastPage,
            customerPreview: "-"
        })

    }
    private handleEditCustomerGroupingID = async (event: any) => {
        await this.setState({
            selectedCustomerInfo: [{
                ...this.state.selectedCustomerInfo[0],
                customer_groupID: event.target.value
            }]
        })
    }

    private handleEditCustomerGroupingIDTriggerClick = async () => {
        await this.setState({
            editCustomerGroupingID: !this.state.editCustomerGroupingID
        })
    }

    private handleSaveCustomerGroupingIDClick = async () => {
        await this.setState({
            editCustomerGroupingID: false
        })
        await this.props.handleSaveCustomerGroupingID(this.state.selectedCustomerInfo[0].customer_groupID, this.state.selectedCustomerInfo[0].id)
    }

    private handleTriggerEditPackageClick = async (selectedPackageDetails: any) => {
        await this.setState({
            editingCustomerPackageDetails: selectedPackageDetails
        })
    }

    private handleEditPackageTokenAmount = async (event: any) => {
        await this.setState({
            editingCustomerPackageDetails: {
                ...this.state.editingCustomerPackageDetails,
                token_amount: parseInt(event.target.value)
            }
        })
    }

    private handleEditPackageStartingDate = async (event: any) => {
        if (event.target.value === "") {
            await this.setState({
                editingCustomerPackageDetails: {
                    ...this.state.editingCustomerPackageDetails,
                    start_date: null
                }
            })
        } else {
            await this.setState({
                editingCustomerPackageDetails: {
                    ...this.state.editingCustomerPackageDetails,
                    start_date: moment(event.target.value).toISOString()
                }
            })
        }
    }

    private handleEditPackageEndingDate = async (event: any) => {
        if (event.target.value === "") {
            await this.setState({
                editingCustomerPackageDetails: {
                    ...this.state.editingCustomerPackageDetails,
                    end_date: null
                }
            })
        } else {

            await this.setState({
                editingCustomerPackageDetails: {
                    ...this.state.editingCustomerPackageDetails,
                    end_date: moment(event.target.value).add(1, 'day').toISOString()
                }
            })
        }
    }
    private handleSaveCustomerPackageDetailsManually = async () => {
        let confirmation = window.confirm('確定要手動更改套劵詳情嗎？')
        if ((this.state.editingCustomerPackageDetails.start_date && this.state.editingCustomerPackageDetails.end_date) || (!this.state.editingCustomerPackageDetails.start_date && !this.state.editingCustomerPackageDetails.end_date)) {
            if (confirmation) {
                await this.props.triggerLoading();
                this.props.handleUpdateCustomerPackageDetailsManually(this.state.editingCustomerPackageDetails);
                await this.setState({
                    editingCustomerPackageDetails: { id: 0 }
                })
            }
            await this.props.loadPackagesOfCustomer(this.state.selectedCustomerId);
        } else {
            window.alert("所填寫資料未合規格!")
        }
    }

    private handleUsingDiscountCountChange = async (event: any) => {
        if (event.target.value <= 5 && event.target.value > 0 && event.target.value <= this.state.usingDiscountForPurchase.maxCount) {
            await this.setState({
                usingDiscountForPurchase: {
                    ...this.state.usingDiscountForPurchase,
                    count: event.target.value
                }
            })
            let discountedAmount = 0
            let usingDiscount = this.props.discountOwnedBySelectedCustomer.find((discountDetails: any) => discountDetails.discount_name == this.state.usingDiscountForPurchase.discount_name);
            if (usingDiscount.cash_discount) {
                discountedAmount = parseFloat(usingDiscount.cash_discount) * this.state.usingDiscountForPurchase.count
            } else {
                discountedAmount = this.state.totalPrice * ((usingDiscount.percentage_discount / 100) * this.state.usingDiscountForPurchase.count)
            }
            let newAmountPayable = this.state.totalPrice - discountedAmount
            await this.setState({
                discountedAmount: discountedAmount,
                amountPayable: newAmountPayable
            })
        }
    }

    private handleSubmitReferringCustomerIDfromMarkReferralDetailsBox = async (referringCustomerID: number) => {
        this.props.triggerLoading();
        await this.props.handleConfirmSubmitReferralDetailsAfterwards(referringCustomerID, this.state.selectedCustomerInfo[0].id);
        let pageShouldBeLoaded = (this.state.selectedCustomerInfo[0].id < 6280 ? Math.ceil(this.state.selectedCustomerInfo[0].id / 10) : Math.floor(this.state.selectedCustomerInfo[0].id / 10));
        setTimeout(() => this.props.customerPageBeingLoaded(pageShouldBeLoaded), 1000)
        await this.setState({
            showMarkReferralDetailsBox: false
        })
    }

    private handleShowaMarkReferralDetailsBox = async () => {
        await this.setState({
            showMarkReferralDetailsBox: !this.state.showMarkReferralDetailsBox
        })
    }

    private handleSendEmailForThisPurchaseCheckBoxChange = async () => {
        await this.setState({
            sendEmailForThisPurchase: !this.state.sendEmailForThisPurchase
        })
    }

    private handleShowExpiredPackagesClick = async () => {
        await this.setState({
            showCustomerExpiredPackages: !this.state.showCustomerExpiredPackages
        })
    }

    private handleSearchTokenWordingInput = async (event: any) => {
        await this.setState({
            searchTokenWordings: event.target.value
        })
    }

    private handleProcessPreviousNoTokenBooking = async (processData: any) => {
        this.props.triggerLoading();
        await this.props.handleExitFromProcessPreviousNoTokenBookingWindow();
        await this.props.handleProcessPreviousNoTokenBookingWithNewPackages(processData.previousNoTokenBooking, processData.selectedPackageID);
        setTimeout(async () => await this.props.loadPackagesOfCustomer(this.state.selectedCustomerId), 1500);
    }

    private handleTriggerDisplaySpecificPurchaseDetailsBoxFromPaymentHistory = async (purchaseID: number) => {
        await this.setState({
            showSpecificPurchaseDetailsBox: !this.state.showSpecificPurchaseDetailsBox,
            selectedPurchaseIDForSpecificPurchaseDetailsBox: purchaseID
        })
    }

    private handleChangeDetailsForPurchasingPackages = (field: string, packageIndex: number) => async (event: any) => {
        let newPackagesReadyForPurchase = this.state.packagesReadyForPurchase;
        let newCustomerPurchaseBucket = this.state.customerPurchaseBucket;

        // newCustomerPurchaseBucket = newCustomerPurchaseBucket.tokenPackages.find((targetPackageInfo:any)=> targetPackageInfo.id === packageID);
        // newPackagesReadyForPurchase =  newPackagesReadyForPurchase.find((targetPackage:any)=> targetPackage.id === packageID);

        newPackagesReadyForPurchase[packageIndex][field] = (field === "token_amount" ? parseFloat(`${event.target.value}`) : parseFloat(`${event.target.value}`).toFixed(2));


        if (field === "price") {
            newCustomerPurchaseBucket.tokenPackages[packageIndex].price = parseFloat(`${event.target.value}`)
        }
        let newTotalPrice = 0;
        if (newCustomerPurchaseBucket.tokenPackages.length > 0) {
            for (let price of newCustomerPurchaseBucket.tokenPackages) {
                newTotalPrice += price.price
            }
        }
        if (newCustomerPurchaseBucket.products.length > 0) {
            for (let price of newCustomerPurchaseBucket.products) {
                newTotalPrice += parseFloat(`${price.price}`)
            }
        }

        await this.setState({
            packagesReadyForPurchase: newPackagesReadyForPurchase,
            customerPurchaseBucket: newCustomerPurchaseBucket,
            totalPrice: newTotalPrice
        })
    }


    componentWillMount = async () => {
        this.props.triggerLoading();
        this.props.customerPageBeingLoaded(1)
        this.props.loadGenreTypeList()
    }
    componentDidUpdate = async () => {
        let targetCustomer = this.props.allCustomerInfo.find((customer: any) => customer.user_id === this.state.selectedCustomerId);
        if (targetCustomer && targetCustomer !== this.state.selectedCustomerInfo[0]) {
            await this.setState({
                selectedCustomerInfo: [targetCustomer]
            })
        }
    }

    private beforeUnload = async (event: any) => {
        event.preventDefault();
        console.log(event);
        if (this.state.productsReadyForPurchase.length === 0) {
            let userRes = await window.confirm("購物車內有物品，確定捨棄？");
            // if(userRes){
            //     window.reload();
            // }
        }
    }

    render() {
        let displayCustomers = []
        displayCustomers = this.props.allCustomerInfo
        let pageNumbers = []
        let pageCount = Math.ceil(this.props.totalCustomerCount / 10);
        for (let i = 1; i <= pageCount; i++) {
            pageNumbers.push(i);
        }
        let purchaseBucketNum = 0;
        if (this.state.customerPurchaseBucket.tokenPackages.length !== 0 || this.state.customerPurchaseBucket.products.length !== 0) {
            purchaseBucketNum = this.state.customerPurchaseBucket.tokenPackages.length + this.state.customerPurchaseBucket.products.length
        }

        if (this.state.customerPurchaseBucket.products.length !== 0) {
            //PROCESS THE PRODUCT CAL. LATER HERE
        }
        let normalClassTokenPackages = [];
        let privateClassTokenPackages = [];
        let roomRentalTokenPackages = [];
        let tutorRentalTokenPackages = [];

        if (this.props.tokenUsageList.length !== 0 && this.state.packagesReadyForPurchase !== '-') {
            let normalClassUsageID = (this.props.tokenUsageList.find((usage: any) => usage.usage === "Class Participation")).id;
            let privateClassUsageID = (this.props.tokenUsageList.find((usage: any) => usage.usage === "Private Class Booking")).id;
            let roomRentalUsageID = (this.props.tokenUsageList.find((usage: any) => usage.usage === "Classroom Rental")).id;
            let tutorRentalUsageID = (this.props.tokenUsageList.find((usage: any) => usage.usage === "Tutor Rental")).id;

            normalClassTokenPackages = this.state.packagesReadyForPurchase.filter((packagesForPurchase: any) => packagesForPurchase.token_usage_id === normalClassUsageID);
            privateClassTokenPackages = this.state.packagesReadyForPurchase.filter((packagesForPurchase: any) => packagesForPurchase.token_usage_id === privateClassUsageID);
            roomRentalTokenPackages = this.state.packagesReadyForPurchase.filter((packagesForPurchase: any) => packagesForPurchase.token_usage_id === roomRentalUsageID);
            tutorRentalTokenPackages = this.state.packagesReadyForPurchase.filter((packagesForPurchase: any) => packagesForPurchase.token_usage_id === tutorRentalUsageID);
        }
        let paymentLeft = this.state.totalPrice - this.state.discountedAmount - this.state.amountReceived;

        let termsAlreadyExistIDs: any = [];
        for (let targetPackages of this.props.purchasePackagesList) {
            if (targetPackages.package_terms) {
                for (let term of targetPackages.package_terms) {
                    termsAlreadyExistIDs.push(term)
                }
            }
        }
        let discountRenderingArr: any[] = []
        if (this.props.discountOwnedBySelectedCustomer.length !== 0) {
            for (let discount of this.props.discountOwnedBySelectedCustomer) {
                let foundDiscount = false
                for (let targetDiscount of discountRenderingArr) {
                    if (discount.discount_name === targetDiscount.discount_name && discount.cash_discount === targetDiscount.cash_discount && discount.percentage_discount === targetDiscount.percentage_discount) {
                        targetDiscount.count += 1
                        foundDiscount = true
                    }
                }
                if (!foundDiscount) {
                    let discountRenderer = {
                        discount_name: discount.discount_name,
                        cash_discount: discount.cash_discount,
                        percentage_discount: discount.percentage_discount,
                        count: 1
                    }
                    discountRenderingArr.push(discountRenderer)
                }
            }
        }

        //PROCESS SEARCH TOKEN INPUT
        let searchTokenResultRenderArr: any[] = [];
        if (this.state.searchTokenWordings !== "") {

            for (let targetPackage of this.props.packagesWithRightUsage) {
                if (targetPackage.package_name.includes(`${this.state.searchTokenWordings}`)) {
                    searchTokenResultRenderArr.push(targetPackage)
                }
            }

        }
        console.log(this.state.productsReadyForPurchase);
        console.log(typeof this.state.productsReadyForPurchase)
        return (
            <div className="col-12">
                {/* POP UP WINDOWS */}
                {this.state.showCustomerAddBox ? <CustomerAddBox handleAddExit={this.handleAddCustomerClick} /> : null}
                {this.state.addNewCustomerDiscount ? <AddCustomerDiscountBox allDiscountTypes={this.props.allDiscountTypes} handleSaveAdd={(discountDetails: any) => this.handleAddDiscountToCustomer(discountDetails)} handleAddExit={this.handleAddNewCustomerDiscountTriggerClick} language={this.props.language} /> : null}
                {this.state.showSpecificPurchaseDetailsBox ? <SpecificPurchaseDetailsBox selectedPurchaseID={this.state.selectedPurchaseIDForSpecificPurchaseDetailsBox} handleExit={(purchaseID: number) => this.handleTriggerDisplaySpecificPurchaseDetailsBoxFromPaymentHistory(purchaseID)} siteLists={this.props.allSites} customerDetails={this.state.selectedCustomerInfo[0]} language={this.props.language} /> : null}
                {
                    this.state.showBucketPreview || this.state.showPaymentPage || this.state.selectPurchaseTermsPage || this.state.showInvoicePreviewPage ?
                        <div className="row">
                            <div className="col-12">
                                <div className="navBlinder01">
                                    <div className="navBlinderContentContainer">
                                        <NotificationsNoneIcon id="navBlinderIcon" /> {this.props.language !== "eng" ? "請專注於目前操作" : "Focus on Purchase Process"}
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }
                <Beforeunload onBeforeunload={this.beforeUnload} />

                <div className="row" >
                    <div className="col-3" id="leftBasicInfoBar">
                        <div className="row">
                            <div className="col-12 " >
                                <div className="customerSimInfoTitleAligner">
                                    <InfoIcon id="customerInfoIcon" />
                                    <h3 id="sectionHeading">{this.props.language !== "eng" ? "顧客簡訊" : "Customer Details"}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 fixedHeightPreviewCustomerBar">
                                {this.state.customerPreview === '-' ?
                                    <div className="noneCustomerPreviewContainer">
                                        <div className="noneDisplay"> - {this.props.language !== "eng" ? "請選擇顧客" : "Select Customer"} -</div>
                                    </div>
                                    :
                                    <div className="customerPreviewContainer">
                                        <div className="customerProfilePicRow">
                                            <FaceIcon id="customerPreviewNoneIconSize" />
                                        </div>
                                        <div className="customerInfoRow">
                                            <AttachFileIcon className="dataIcons" />
                                            <div className="customerBasicDataName">{this.props.language !== "eng" ? "顧客編號" : "Customer ID"} ：</div>

                                            <div className="customerPreviewDetails">{(this.props.allCustomerInfo.filter((customer: any) => customer.user_id === this.state.customerPreview))[0].id}</div>
                                        </div>
                                        <div className="customerInfoRow">
                                            <TranslateIcon className="dataIcons" />
                                            <div className="customerBasicDataName">{this.props.language !== "eng" ? "中文姓名" : "Chi. Name"} ：</div>
                                            <div className="customerPreviewDetails">{(this.props.allCustomerInfo.filter((customer: any) => customer.user_id === this.state.customerPreview))[0].name_chi ? (this.props.allCustomerInfo.filter((customer: any) => customer.user_id === this.state.customerPreview))[0].name_chi : " - - "}</div>
                                        </div>
                                        <div className="customerInfoRow">
                                            <TranslateIcon className="dataIcons" />
                                            <div className="customerBasicDataName">{this.props.language !== "eng" ? "英文姓名" : "Eng. Name"} ：</div>
                                            <div className="customerPreviewDetails">{(this.props.allCustomerInfo.filter((customer: any) => customer.user_id === this.state.customerPreview))[0].name_eng ? (this.props.allCustomerInfo.filter((customer: any) => customer.user_id === this.state.customerPreview))[0].name_eng : " - - "}</div>
                                        </div>
                                        <div className="customerInfoRow">
                                            <WcIcon className="dataIcons" />
                                            <div className="customerBasicDataName">{this.props.language !== "eng" ? "顧客性別" : "SEX."} ：</div>
                                            <div className="customerPreviewDetails">{(this.props.allCustomerInfo.filter((customer: any) => customer.user_id === this.state.customerPreview))[0].sex ? (this.props.allCustomerInfo.filter((customer: any) => customer.user_id === this.state.customerPreview))[0].sex : " - - "}</div>
                                        </div>
                                        <div className="customerInfoRow">
                                            <PhonelinkRingIcon className="dataIcons" />
                                            <div className="customerBasicDataName">{this.props.language !== "eng" ? "聯絡電話" : "TEL."} ：</div>
                                            <div className="customerPreviewDetails">{(this.props.allCustomerInfo.filter((customer: any) => customer.user_id === this.state.customerPreview))[0].tel ? (this.props.allCustomerInfo.filter((customer: any) => customer.user_id === this.state.customerPreview))[0].tel : " - - "}</div>
                                        </div>
                                        <div className="customerInfoRow">
                                            <AlternateEmailIcon className="dataIcons" />
                                            <div className="customerBasicDataName">{this.props.language !== "eng" ? "聯絡電郵" : "Email"} ：</div>
                                            <div className="customerPreviewDetails">{(this.props.allCustomerInfo.filter((customer: any) => customer.user_id === this.state.customerPreview))[0].email ? (this.props.allCustomerInfo.filter((customer: any) => customer.user_id === this.state.customerPreview))[0].email : " - - "}</div>
                                        </div>
                                        <div className="customerInfoRow">
                                            <NoteIcon className="dataIcons" />
                                            <div className="customerBasicDataName">{this.props.language !== "eng" ? "顧客備注" : "Remarks"} ：</div>
                                            <div className="customerPreviewDetails">{(this.props.allCustomerInfo.filter((customer: any) => customer.user_id === this.state.customerPreview))[0].remarks ? (this.props.allCustomerInfo.filter((customer: any) => customer.user_id === this.state.customerPreview))[0].remarks : " - - "}</div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>

                    {this.state.showPaymentPage ?
                        this.props.loading ?
                            <div className="col-9" id="mainContentBase">
                                <div className="loadingContainerRow">
                                    <div className="inlineDisplay">
                                        <div className="loadingContainer">
                                            <CircularProgress id="loadingCircle" />
                                            <div className="loadingLabel">Loading ...</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            //PAYMENT PAGE
                            <div className="col-9" id="mainContentBase">
                                <div className="row">
                                    <div className="col-12">
                                        <div>
                                            <Button id="hiddenBtn" onClick={this.handlePaymentClick}> <h3 id="sectionHeading">《 {this.props.language !== "eng" ? "返回預覧選購詳情" : "Back"}</h3> </Button>
                                            <h5 id="sectionHeading"> -- {this.props.language !== "eng" ? "確認選購資料" : "Confirm Purchase Details"}</h5>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row">
                                <div className="col-12" id="customerBucketPreviewTitleRow">
                                    <ListAltIcon id="previewBucketListTitleIcon" />
                                    <div className="previewTitleAligner">
                                        <div id="customerBucketPreviewTitle">{this.props.language !== "eng"? "選購詳情" : "Purchase Details"} - Total: HKD$ {purchaseBucketSum.toFixed(2)} <Button startIcon={<PaymentIcon />} id="confirmPreviewBtn" onClick={this.handlePaymentClick}>前往結帳 》》</Button></div>
                                    </div>

                                </div>
                            </div> */}
                                <div className="row">
                                    <div className="col-12" id="customerNavPagesArea">
                                        <div className="row">
                                            <div className="col-7 contentBlock2">
                                                <div className="blockTitle2">{this.props.language !== "eng" ? "可供選擇優惠" : "Applicable Discounts"}</div>
                                                <div className="usagePackagesSumUp">{this.props.language !== "eng" ? `- 共 ${this.props.discountOwnedBySelectedCustomer.length} 個項目` : `- Count ${this.props.discountOwnedBySelectedCustomer.length} `}</div>

                                                <div className="blockTitle2Underline"></div>
                                                <div className="discountListingArea">
                                                    {this.props.discountOwnedBySelectedCustomer.length === 0 ?

                                                        <div className="discountListingAreaFillUp">
                                                            <div className="noDiscountIndicator">- {this.props.language !== "eng" ? "無" : "No Applicable Discount"} -</div>
                                                        </div>
                                                        :

                                                        <div className="disCountListingAreaBG">
                                                            {discountRenderingArr.map((discount: any) =>
                                                                this.state.usingDiscountForPurchase.discount_name === discount.discount_name && this.state.usingDiscountForPurchase.cash_discount === discount.cash_discount ?

                                                                    <div className="chosenDiscountListingRow" >
                                                                        <div className="chosenDiscountName" onClick={() => this.handleUseDiscountClick(discount)}> {discount.discount_name} </div>
                                                                        {discount.expiry_date ?
                                                                            <div className="chosenDiscountName" onClick={() => this.handleUseDiscountClick(discount)}> Expire: {moment(discount.expiry_date).format('YYYY-MM-DD')} </div>
                                                                            : null}
                                                                        {discount.cash_discount ?
                                                                            <div className="chosenDiscountName" onClick={() => this.handleUseDiscountClick(discount)}> $ {discount.cash_discount} </div>
                                                                            :
                                                                            <div className="chosenDiscountName" onClick={() => this.handleUseDiscountClick(discount)}> {discount.percentage_discount} %</div>
                                                                        }
                                                                        {

                                                                            discount.count === 1 ?
                                                                                <div className="discountUsingCounterContainer">
                                                                                    <TextField
                                                                                        id="discountUsingCountOnPurchasePage"
                                                                                        label={this.props.language !== "eng" ? "使用數量" : "Use"}
                                                                                        type="number"
                                                                                        value={this.state.usingDiscountForPurchase.count}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                <div className="discountUsingCounterContainer">

                                                                                    <TextField
                                                                                        id="discountUsingCountOnPurchasePage"
                                                                                        label={this.props.language !== "eng" ? "使用數量" : "Use"}
                                                                                        type="number"
                                                                                        variant="filled"
                                                                                        value={this.state.usingDiscountForPurchase.count}
                                                                                        onChange={this.handleUsingDiscountCountChange}
                                                                                    />
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <div className="discountListingRow" onClick={() => this.handleUseDiscountClick(discount)}>
                                                                        <div className="discountName"> {discount.discount_name} </div>
                                                                        {discount.expiry_date ?
                                                                            <div className="discountName"> Expire: {moment(discount.expiry_date).format('YYYY-MM-DD')} </div>
                                                                            : null}
                                                                        {discount.cash_discount ?
                                                                            <div className="discountName"> $ {discount.cash_discount} </div>
                                                                            :
                                                                            <div className="discountName"> {discount.percentage_discount} %</div>
                                                                        }
                                                                        <div className="discountUsingCounterContainer">
                                                                            {this.props.language !== "eng" ? "可用數" : "Count"}： {discount.count}
                                                                        </div>
                                                                    </div>
                                                            )}

                                                        </div>
                                                    }
                                                </div>

                                                <div className="blockTitle2">{this.props.language !== "eng" ? "選擇付款方法" : "Payment Method"}</div>
                                                <div className="blockTitle2Underline"></div>
                                                {this.state.paymentMethodChosen === 'Credit Card' ?

                                                    <div className="col-4 paymentMethodChosenBlock3 " onClick={() => this.handlePaymentMethodSelection('Credit Card')}>
                                                        <div className="paymentMethodContent">
                                                            <PaymentIcon id="paymentChosenIcon" fontSize={'large'} />
                                                            <div className="paymentChosenName" >
                                                                {this.props.language !== "eng" ? "信用卡" : "Credit Card"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-4 paymentMethodBlock3 " onClick={() => this.handlePaymentMethodSelection('Credit Card')}>
                                                        <div className="paymentMethodContent">
                                                            <PaymentIcon id="paymentIcon" fontSize={'large'} />
                                                            <div className="paymentName" >
                                                                {this.props.language !== "eng" ? "信用卡" : "Credit Card"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.paymentMethodChosen === 'EPS' ?

                                                    <div className="col-4 paymentMethodChosenBlock3 " onClick={() => this.handlePaymentMethodSelection('EPS')}>
                                                        <div className="paymentMethodContent">
                                                            <RedeemIcon id="paymentChosenIcon" fontSize={'large'} />

                                                            <div className="paymentChosenName" >
                                                                EPS
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-4 paymentMethodBlock3 " onClick={() => this.handlePaymentMethodSelection('EPS')}>
                                                        <div className="paymentMethodContent">
                                                            <RedeemIcon id="paymentIcon" fontSize={'large'} />
                                                            <div className="paymentName" >
                                                                EPS
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.paymentMethodChosen === 'Cash' ?

                                                    <div className="col-4 paymentMethodChosenBlock3 " onClick={() => this.handlePaymentMethodSelection('Cash')}>
                                                        <div className="paymentMethodContent">
                                                            <LocalAtmIcon id="paymentChosenIcon" fontSize={'large'} />
                                                            <div className="paymentChosenName" >
                                                                {this.props.language !== "eng" ? "現金" : "CASH"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-4 paymentMethodBlock3 " onClick={() => this.handlePaymentMethodSelection('Cash')}>
                                                        <div className="paymentMethodContent">
                                                            <LocalAtmIcon id="paymentIcon" fontSize={'large'} />
                                                            <div className="paymentName" >
                                                                {this.props.language !== "eng" ? "現金" : "CASH"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.paymentMethodChosen === 'Bank Transfer' ?

                                                    <div className="col-4 paymentMethodChosenBlock3 " onClick={() => this.handlePaymentMethodSelection('Bank Transfer')}>
                                                        <div className="paymentMethodContent">
                                                            <AccountBalanceIcon id="paymentChosenIcon" fontSize={'large'} />
                                                            <div className="paymentChosenName" >
                                                                {this.props.language !== "eng" ? "銀行轉帳" : "Bank Transfer"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-4 paymentMethodBlock3 " onClick={() => this.handlePaymentMethodSelection('Bank Transfer')}>
                                                        <div className="paymentMethodContent">
                                                            <AccountBalanceIcon id="paymentIcon" fontSize={'large'} />
                                                            <div className="paymentName" >
                                                                {this.props.language !== "eng" ? "銀行轉帳" : "Bank Transfer"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.paymentMethodChosen === 'Bank Deposit Slip' ?

                                                    <div className="col-4 paymentMethodChosenBlock3 " onClick={() => this.handlePaymentMethodSelection('Bank Deposit Slip')}>
                                                        <div className="paymentMethodContent">
                                                            <ReceiptIcon id="paymentChosenIcon" fontSize={'large'} />
                                                            <div className="paymentChosenName" >
                                                                {this.props.language !== "eng" ? "入數" : "Deposit Slip"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-4 paymentMethodBlock3 " onClick={() => this.handlePaymentMethodSelection('Bank Deposit Slip')}>
                                                        <div className="paymentMethodContent">
                                                            <ReceiptIcon id="paymentIcon" fontSize={'large'} />
                                                            <div className="paymentName" >
                                                                {this.props.language !== "eng" ? "入數" : "Deposit Slip"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.paymentMethodChosen === 'Other' ?

                                                    <div className="col-4 paymentMethodChosenBlock3 " onClick={() => this.handlePaymentMethodSelection('Other')}>
                                                        <div className="paymentMethodContent">
                                                            <HelpOutlineIcon id="paymentChosenIcon" fontSize={'large'} />
                                                            <div className="paymentChosenName" >
                                                                {this.props.language !== "eng" ? "其他" : "Others"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-4 paymentMethodBlock3 " onClick={() => this.handlePaymentMethodSelection('Other')}>
                                                        <div className="paymentMethodContent">
                                                            <HelpOutlineIcon id="paymentIcon" fontSize={'large'} />
                                                            <div className="paymentName" >
                                                                {this.props.language !== "eng" ? "其他" : "Others"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="blockTitle2">{this.props.language !== "eng" ? "收款金額" : "Amount Received"}</div>
                                                <div className="blockTitle2Underline"></div>
                                                {this.state.purchasePaymentSituation === 'Fully' ?

                                                    <div className="col-4 paymentMethodChosenBlock3 " >
                                                        <div className="paymentMethodContent">
                                                            <HelpOutlineIcon id="paymentChosenIcon" fontSize={'large'} />
                                                            <div className="paymentChosenName" >
                                                                {this.props.language !== "eng" ? "全數" : "Full Pay"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-4 paymentMethodBlock3 " onClick={() => this.handlePaymentStatusSelection('Fully')}>
                                                        <div className="paymentMethodContent">
                                                            <HelpOutlineIcon id="paymentIcon" fontSize={'large'} />
                                                            <div className="paymentName" >
                                                                {this.props.language !== "eng" ? "全數" : "Full Pay"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.purchasePaymentSituation === 'Partly' ?

                                                    <div className="col-4 paymentMethodChosenBlock3 " >
                                                        <div className="paymentMethodContent">
                                                            <HelpOutlineIcon id="paymentChosenIcon" fontSize={'large'} />
                                                            <div className="paymentChosenName" >
                                                                {this.props.language !== "eng" ? "部份" : "Partly"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-4 paymentMethodBlock3 " onClick={() => this.handlePaymentStatusSelection('Partly')}>
                                                        <div className="paymentMethodContent">
                                                            <HelpOutlineIcon id="paymentIcon" fontSize={'large'} />
                                                            <div className="paymentName" >
                                                                {this.props.language !== "eng" ? "部份" : "Partly"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.purchasePaymentSituation === 'Unpaid' ?

                                                    <div className="col-4 paymentMethodChosenBlock3 " >
                                                        <div className="paymentMethodContent">
                                                            <HelpOutlineIcon id="paymentChosenIcon" fontSize={'large'} />
                                                            <div className="paymentChosenName" >
                                                                {this.props.language !== "eng" ? "全數後付" : "Pay Later"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-4 paymentMethodBlock3 " onClick={() => this.handlePaymentStatusSelection('Unpaid')}>
                                                        <div className="paymentMethodContent">
                                                            <HelpOutlineIcon id="paymentIcon" fontSize={'large'} />
                                                            <div className="paymentName" >
                                                                {this.props.language !== "eng" ? "全數後付" : "Pay Later"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {this.state.purchasePaymentSituation === 'Partly' ?
                                                    <div className="col-5 paymentAmountBlock">
                                                        <div className="paymentName">
                                                            {this.props.language !== "eng" ? "收款金額" : "Amount Received"} :
                                                        </div>
                                                        <TextField id="paymentAmount" variant="outlined" onChange={this.handlePaymentAmountInput} value={this.state.amountReceived} />
                                                    </div>
                                                    : null
                                                }




                                            </div>
                                            <div className="col-4 contentBlock2">
                                                <div className="blockTitle2">{this.props.language !== "eng" ? "選購詳情" : "Purchase Details"}</div>
                                                <div className="blockTitle2Underline"></div>
                                                {this.state.packagesReadyForPurchase.length === 0 ?
                                                    null :
                                                    this.state.packagesReadyForPurchase.map((packageForPurchase: any) =>
                                                        <div className="purchaseSumUpItem">
                                                            <div className="purchaseSumUpItemName">
                                                                {packageForPurchase.package_name}
                                                            </div>
                                                            <div className="purchaseSumUpItemPrice">
                                                                $ {packageForPurchase.price}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    this.state.productsReadyForPurchase.length === 0 ?
                                                        null :
                                                        this.state.productsReadyForPurchase.map((productForPurchase: any) =>
                                                            <div className="purchaseSumUpItem">
                                                                <div className="purchaseSumUpItemName">
                                                                    {productForPurchase.product_name}
                                                                </div>
                                                                <div className="purchaseSumUpItemPrice">
                                                                    $ {productForPurchase.markedPrice}
                                                                </div>
                                                            </div>
                                                        )
                                                }

                                                <div className="purchaseSumUpItem2">

                                                    <div className="purchaseSumUpItemName">
                                                        {this.props.language !== "eng" ? "選購總值" : "Sub Total"}:
                                                    </div>
                                                    <div className="purchaseSumUpItemPrice">
                                                        $ {this.state.totalPrice.toFixed(2)}
                                                    </div>
                                                </div>

                                                <div className="purchaseUnderline"></div>


                                                {this.state.discountedAmount === 0 ?
                                                    null :
                                                    <div className="purchaseSumUpItem2">

                                                        <div className="purchaseSumUpItemName">
                                                            {this.props.language !== "eng" ? "優惠金額" : "Discount"}:
                                                        </div>
                                                        <div className="purchaseSumUpItemPrice3">
                                                            - $ {this.state.discountedAmount.toFixed(2)}
                                                        </div>
                                                    </div>
                                                }

                                                <div className="purchaseSumUpItem2">

                                                    <div className="purchaseSumUpItemName">
                                                        {this.props.language !== "eng" ? "應繳款項" : "Payable"}:
                                                    </div>
                                                    <div className="purchaseSumUpItemPrice">
                                                        $ {this.state.amountPayable.toFixed(2)}
                                                    </div>
                                                </div>

                                                <div className="purchaseSumUpItem2">

                                                    <div className="purchaseSumUpItemName">
                                                        {this.props.language !== "eng" ? "已收款項" : "Received"}:
                                                    </div>
                                                    <div className="purchaseSumUpItemPrice">
                                                        $ {this.state.amountReceived.toFixed(2)}
                                                    </div>
                                                </div>

                                                {paymentLeft === 0 ?
                                                    null :
                                                    <div className="purchaseSumUpItem2">

                                                        <div className="purchaseSumUpItemName">
                                                            {this.props.language !== "eng" ? "尚欠餘款" : "Outstanding Balance"}:
                                                        </div>
                                                        <div className="purchaseSumUpItemPrice2">
                                                            ($ {paymentLeft.toFixed(2)})
                                                        </div>
                                                    </div>
                                                }

                                                {paymentLeft < 0 ?
                                                    <Button id="confirmPaymentBtnDisabled" > - {this.props.language !== "eng" ? "錯誤" : "Informative Error"} - </Button>
                                                    :
                                                    this.state.purchasePaymentSituation === 'Partly' && this.state.amountReceived === 0 ?
                                                        <Button id="confirmPaymentBtnDisabled" > - {this.props.language !== "eng" ? "錯誤" : "Informative Error"} - </Button>
                                                        :
                                                        // <Button id="confirmPaymentBtn" onClick={this.handleConfirmPurchaseClick}>確認完成結帳 </Button>}
                                                        <Button id="confirmPaymentBtn" onClick={this.handleGoSelectPurchaseTermsPage}>{this.props.language !== "eng" ? "選擇選購條款" : "Applicable Terms & Conditions"} </Button>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                        this.state.selectPurchaseTermsPage ?
                            this.props.loading ?
                                <div className="col-9" id="mainContentBase">
                                    <div className="loadingContainerRow">
                                        <div className="inlineDisplay">
                                            <div className="loadingContainer">
                                                <CircularProgress id="loadingCircle" />
                                                <div className="loadingLabel">Loading ...</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                //SELECT PURCHASE TERMS PAGE
                                <div className="col-9" id="mainContentBase">
                                    <div className="row">

                                        <div className="col-12">
                                            <div>
                                                <Button id="hiddenBtn" onClick={this.handlePaymentClick}> <h3 id="sectionHeading">《 {this.props.language !== "eng" ? "返回確認選購資料" : "Back"}</h3> </Button>

                                            </div>
                                        </div>
                                        <div className="col-12" id="customerNavPagesArea">


                                            <div className="row">
                                                <div className="col-9 contentBlock2">
                                                    <div className="blockTitle2">{this.props.language !== "eng" ? "選購條款詳情" : "Terms & Conditions"}</div>
                                                    <div className="usagePackagesSumUp">{this.props.language !== "eng" ? `-  共 ${this.state.selectedPurchaseTerms.length} 個條款` : ` - Count ${this.state.selectedPurchaseTerms.length}`}</div>

                                                    <div className="blockTitle2Underline"></div>
                                                    <div className="fixedHeightTermsListingBox">
                                                        {this.state.selectedPurchaseTerms.length === 0 ?
                                                            <div className="noneDisplayBox">
                                                                <div>
                                                                    <div className="aligner"> - {this.props.language !== "eng" ? "目前無已選取條款" : "No Selected Term & Condition"} -</div>
                                                                    <div className="aligner"><Button id="clickToChooseNewTermsBtn" onClick={this.handleAddNewTermsSlot}>+ {this.props.language !== "eng" ? "按此選取新條款" : "Select Terms"}</Button></div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div>
                                                                {this.state.selectedPurchaseTerms.map((term: number) =>
                                                                    <div className="termRow">
                                                                        <FormControl id="termRowSelectForm">
                                                                            <InputLabel id="termLabel">{this.props.language !== "eng" ? "選購條款" : "Terms"} : </InputLabel>
                                                                            <Select
                                                                                labelId="termLabel"
                                                                                // id="demo-simple-select"
                                                                                value={term}
                                                                                onChange={(e) => this.handleTermChange(e, term)}
                                                                            >
                                                                                <MenuItem value={0}>{this.props.language !== "eng" ? "未選擇" : "Please Select"}</MenuItem>
                                                                                {this.props.purchaseTermsLibrary.map((purchaseTerm: any) =>
                                                                                    !purchaseTerm.active || termsAlreadyExistIDs.filter((targetTerm: any) => targetTerm === purchaseTerm.id).length > 0 || purchaseTerm.base_terms ? null :
                                                                                        <MenuItem value={purchaseTerm.id}>{purchaseTerm.content}</MenuItem>
                                                                                )}
                                                                            </Select>
                                                                        </FormControl>
                                                                        <CloseIcon id="delTermsBtn" onClick={() => this.handleDelTerms(term)} />
                                                                    </div>

                                                                )}
                                                                <div>
                                                                    <div className="aligner"><Button id="clickToChooseNewTermsBtn2" onClick={this.handleAddNewTermsSlot}>+ {this.props.language !== "eng" ? "按此選取新條款" : "Select Terms"}</Button></div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-2 contentBlock3">
                                                    <Button id="confirmTermsBtn" onClick={this.handleGoInvoicePreviewPage}>{this.props.language !== "eng" ? "確認條款詳情" : "Confirm Terms"}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :
                            //INVOICE PREVIEW PAGE
                            this.state.showInvoicePreviewPage ?
                                this.props.loading ?
                                    <div className="col-9" id="mainContentBase">
                                        <div className="loadingContainerRow">
                                            <div className="inlineDisplay">
                                                <div className="loadingContainer">
                                                    <CircularProgress id="loadingCircle" />
                                                    <div className="loadingLabel">Loading ...</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="col-9" id="mainContentBase">
                                        <div className="row">

                                            <div className="col-12">
                                                <div>
                                                    <Button id="hiddenBtn" onClick={this.handleGoInvoicePreviewPage}> <h3 id="sectionHeading">《 {this.props.language !== "eng" ? "返回選購條款詳情" : "Back"}</h3> </Button>

                                                </div>
                                            </div>
                                            {/* <div className="col-12" id="customerNavPagesArea"> */}
                                            <div className="col-12" >


                                                <div className="row">
                                                    <div className="col-11 contentBlock2">
                                                        <div className="blockTitle2">{this.props.language !== "eng" ? "選購詳情預覧" : "Purchase Preview"}</div>
                                                        <div className="blockTitle2Underline"></div>


                                                        <InvoiceGenerator purchasePreviewData={this.state.emailPreviewData} />

                                                        <div className="w-100 aligner">
                                                            <div className="sendEmailTriggerContainer">
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            id="sendEmailTrigger"
                                                                            checked={this.state.sendEmailForThisPurchase}
                                                                            onChange={this.handleSendEmailForThisPurchaseCheckBoxChange}
                                                                            name="checkedB"
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label={this.props.language !== "eng" ? "為此次選購向顧客發送電郵" : "Send Purchase Receipt Email to Customer"}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="w-100 aligner">
                                                            {
                                                                this.state.repeatPreventer ?
                                                                    <Button id="confirmPurchaseBtn" disabled>{this.props.language !== "eng" ? "處理選購中" : "Processing Purchase"}</Button>
                                                                    :
                                                                    <Button id="confirmPurchaseBtn" onClick={this.handleConfirmPurchaseClick}>{this.props.language !== "eng" ? "確認選購詳情" : "Confirm Purchase Details"}</Button>
                                                            }
                                                            {/* <Button id="confirmPurchaseBtn" onClick={this.handlePrintInvoice} ref={el => (confirmPrintBtn = el)}>{this.props.language !== "eng"? "確認條款詳情" : "Confirm Terms"}</Button> */}
                                                        </div>
                                                        {/* <ReactToPrint
                                                        trigger={() =>
                                                            <div className="w-100 aligner">
                                                                <Button id="confirmPurchaseBtn" onClick={this.handlePrintInvoice}>{this.props.language !== "eng"? "確認條款詳情" : "Confirm Terms"}</Button>
                                                            </div>
                                                        }
                                                        content={() => refcomponent}
                                                    /> */}

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :

                                this.state.showBucketPreview ?
                                    //BUCKET PREVIEW PAGE
                                    this.props.loading ?
                                        <div className="col-9" id="mainContentBase">
                                            <div className="loadingContainerRow">
                                                <div className="inlineDisplay">
                                                    <div className="loadingContainer">
                                                        <CircularProgress id="loadingCircle" />
                                                        <div className="loadingLabel">Loading ...</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="col-9" id="mainContentBase">
                                            <div className="row">
                                                <div className="col-12">

                                                    <div>
                                                        <Button id="hiddenBtn" onClick={() => this.handleShowBucketPreview()}> <h3 id="sectionHeading">《 {this.props.language !== "eng" ? "返回選購列表" : "Back"}</h3> </Button>
                                                        <h5 id="sectionHeading"> -- {this.props.language !== "eng" ? "預覧選購詳情" : "Preview Purchase Bucket"}</h5>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12" id="customerBucketPreviewTitleRow">
                                                    <ListAltIcon id="previewBucketListTitleIcon" />
                                                    <div className="previewTitleAligner">
                                                        <div id="customerBucketPreviewTitle">{this.props.language !== "eng" ? "選購詳情" : "Purchase Details"} - Total: HKD$ {this.state.totalPrice.toFixed(2)} <Button startIcon={<PaymentIcon />} id="confirmPreviewBtn" onClick={this.handlePaymentClick}>{this.props.language !== "eng" ? "前往結帳" : "Proceed"} 》》</Button></div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12" id="customerNavPagesArea">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div id="contentFixSizeTableHeight_BucketPreviewListingArea" >

                                                                {
                                                                    this.state.packagesReadyForPurchase.length === 0 ?
                                                                        null :
                                                                        <div className="previewBaseArea">
                                                                            {normalClassTokenPackages.length === 0 ?
                                                                                null :
                                                                                <div className="usageGroupBaseArea">
                                                                                    <div className="lilSpace">
                                                                                        <div className="usage1AreaTitle">{this.props.language !== "eng" ? "一般課堂套券" : "Normal Class Package"}</div>
                                                                                        <div className="usagePackagesSumUp"> {this.props.language !== "eng" ? `共 ${normalClassTokenPackages.length} 個項目` : `Count ${normalClassTokenPackages.length}`}</div>
                                                                                    </div>

                                                                                    {this.state.packagesReadyForPurchase.map((packagesOnList: any, index: number) =>
                                                                                        packagesOnList.token_usage_id !== 1 ? null :
                                                                                            <div className="contentBlockOfClassTokenOnPreviewPage">
                                                                                                <CloseIcon id="previewDelIcon" onClick={() => this.handleDelPurchasePackageClick(index)} />
                                                                                                <div className="previewContentBlockAligner">
                                                                                                    <PeopleAltIcon id="previewPackageIcon" />
                                                                                                    <div className="previewPackageName">{packagesOnList.package_name}</div>
                                                                                                    <div className="previewPackageContent">
                                                                                                        <div className="previewPackageContentAligner">
                                                                                                            <div className="inlineElement">{packagesOnList.age_group_id ? <div>{this.props.language !== "eng" ? "年齢" : "Age"}：{(this.props.ageGroupList.find((ageGroup: any) => ageGroup.id === packagesOnList.age_group_id)).age_group}</div> : <div>{this.props.language !== "eng" ? "年齢" : "Age"}：{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>}</div>
                                                                                                            <div className="listingPackageContent">{packagesOnList.genre_id ? <div>{this.props.language !== "eng" ? "種類：" : "Sport"}{packagesOnList.genre_id.length} {this.props.language !== "eng" ? "種" : "Types"}</div> : <div>{this.props.language !== "eng" ? "種類：" : "Sport :"}{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>}</div>
                                                                                                            {packagesOnList.token_based_package ?
                                                                                                                // <div className="inlineElement">{this.props.language !== "eng"? "有效：" : "Valid"}{packagesOnList.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div>
                                                                                                                <div className="inlineElement adminChangingSellingPackageFieldContainer">{this.props.language !== "eng" ? "有效：" : "Valid"}<OutlinedInput id="inputForAdminChangingSellingPackageContent" type="number" value={packagesOnList.token_amount} endAdornment={<InputAdornment position="end">{this.props.language !== "eng" ? "張" : "Token"}</InputAdornment>} onChange={this.handleChangeDetailsForPurchasingPackages("token_amount", index)} /></div>
                                                                                                                :
                                                                                                                <div className="inlineElement">{this.props.language !== "eng" ? "有效：" : "Valid"}{packagesOnList.validate_period} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                            }
                                                                                                            <div className="inlineElement">{packagesOnList.site_id ? <div>{this.props.language !== "eng" ? "校址：" : "Site :"}{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div> : <div>{this.props.language !== "eng" ? "校址：不限" : "Site : Unlimited"}</div>}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* <div className="previewPackagePrice">HKD$ {packagesOnList.price}</div> */}
                                                                                                    <div className="previewPackagePrice adminChangingSellingPackageFieldContainer">HKD$ <OutlinedInput id="inputForAdminChangingSellingPackageContent02" type="number" value={packagesOnList.price} onChange={this.handleChangeDetailsForPurchasingPackages("price", index)} /></div>
                                                                                                </div>
                                                                                            </div>
                                                                                    )
                                                                                    }
                                                                                </div>}
                                                                            {privateClassTokenPackages.length === 0 ?
                                                                                null :
                                                                                <div className="usageGroupBaseArea">
                                                                                    <div className="lilSpace">
                                                                                        <div className="usage2AreaTitle">{this.props.language !== "eng" ? "私人課堂套券" : "Private Class Package"}</div>
                                                                                        <div className="usagePackagesSumUp">{this.props.language !== "eng" ? `共 ${privateClassTokenPackages.length} 個項目` : `Count ${privateClassTokenPackages.length}`}</div>

                                                                                    </div>

                                                                                    {this.state.packagesReadyForPurchase.map((packagesOnList: any, index: number) =>
                                                                                        packagesOnList.token_usage_id !== 2 ? null :
                                                                                            <div className="contentBlockOfPrivateClassTokenOnPreviewPage">
                                                                                                <CloseIcon id="previewDelIcon" onClick={() => this.handleDelPurchasePackageClick(index)} />
                                                                                                <div className="previewContentBlockAligner">
                                                                                                    <PeopleAltIcon id="previewPackageIcon" />
                                                                                                    <div className="previewPackageName">{packagesOnList.package_name}</div>
                                                                                                    <div className="previewPackageContent">
                                                                                                        <div className="previewPackageContentAligner">
                                                                                                            <div className="inlineElement">{packagesOnList.age_group_id ? <div>{this.props.language !== "eng" ? "年齢" : "Age"}：{(this.props.ageGroupList.find((ageGroup: any) => ageGroup.id === packagesOnList.age_group_id)).age_group}</div> : <div>{this.props.language !== "eng" ? "年齢" : "Age"}{this.props.language !== "eng" ? "：不限" : ": Unlimited"}</div>}</div>
                                                                                                            <div className="listingPackageContent">{packagesOnList.genre_id ? <div>{this.props.language !== "eng" ? "種類：" : "Sport"}{packagesOnList.genre_id.length}{this.props.language !== "eng" ? "種" : "Types"}</div> : <div>{this.props.language !== "eng" ? "種類：" : "Sport :"}{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>}</div>
                                                                                                            {packagesOnList.token_based_package ?
                                                                                                                // <div className="inlineElement">{this.props.language !== "eng"? "有效：" : "Valid"}{packagesOnList.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div>
                                                                                                                <div className="inlineElement adminChangingSellingPackageFieldContainer">{this.props.language !== "eng" ? "有效：" : "Valid"}<OutlinedInput id="inputForAdminChangingSellingPackageContent" type="number" value={packagesOnList.token_amount} endAdornment={<InputAdornment position="end">{this.props.language !== "eng" ? "張" : "Token"}</InputAdornment>} onChange={this.handleChangeDetailsForPurchasingPackages("token_amount", index)} /></div>
                                                                                                                :
                                                                                                                <div className="inlineElement">{this.props.language !== "eng" ? "有效：" : "Valid"}{packagesOnList.validate_period} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                            }
                                                                                                            <div className="inlineElement">{packagesOnList.site_id ? <div>{this.props.language !== "eng" ? "校址：" : "Site :"}{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div> : <div>{this.props.language !== "eng" ? "校址：不限" : "Site : Unlimited"}</div>}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="previewPackagePrice adminChangingSellingPackageFieldContainer">HKD$ <OutlinedInput id="inputForAdminChangingSellingPackageContent02" type="number" value={packagesOnList.price} onChange={this.handleChangeDetailsForPurchasingPackages("price", index)} /></div>
                                                                                                </div>
                                                                                            </div>
                                                                                    )
                                                                                    }
                                                                                </div>}

                                                                            {roomRentalTokenPackages.length === 0 ?
                                                                                null :
                                                                                <div className="usageGroupBaseArea">
                                                                                    <div className="lilSpace">
                                                                                        <div className="usage3AreaTitle">{this.props.language !== "eng" ? "租用場地套券" : "Room Rental Package"}</div>
                                                                                        <div className="usagePackagesSumUp">{this.props.language !== "eng" ? `共 ${roomRentalTokenPackages.length} 個項目` : `Count ${roomRentalTokenPackages.length}`}</div>

                                                                                    </div>
                                                                                    {this.state.packagesReadyForPurchase.map((packagesOnList: any, index: number) =>
                                                                                        packagesOnList.token_usage_id !== 3 ? null :
                                                                                            <div className="contentBlockOfRoomRentalTokenOnPreviewPage">
                                                                                                <CloseIcon id="previewDelIcon" onClick={() => this.handleDelPurchasePackageClick(index)} />
                                                                                                <div className="previewContentBlockAligner">
                                                                                                    <PeopleAltIcon id="previewPackageIcon" />
                                                                                                    <div className="previewPackageName">{packagesOnList.package_name}</div>
                                                                                                    <div className="previewPackageContent">
                                                                                                        <div className="previewPackageContentAligner">
                                                                                                            {packagesOnList.token_based_package ?
                                                                                                                // <div className="inlineElement">{this.props.language !== "eng"? "有效：" : "Valid"}{packagesOnList.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div>
                                                                                                                <div className="inlineElement adminChangingSellingPackageFieldContainer">{this.props.language !== "eng" ? "有效：" : "Valid"}<OutlinedInput id="inputForAdminChangingSellingPackageContent" type="number" value={packagesOnList.token_amount} endAdornment={<InputAdornment position="end">{this.props.language !== "eng" ? "張" : "Token"}</InputAdornment>} onChange={this.handleChangeDetailsForPurchasingPackages("token_amount", index)} /></div>
                                                                                                                :
                                                                                                                <div className="inlineElement">{this.props.language !== "eng" ? "有效：" : "Valid"}{packagesOnList.validate_period} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                            }
                                                                                                            <div className="inlineElement">{packagesOnList.site_id ? <div>{this.props.language !== "eng" ? "校址：" : "Site :"}{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div> : <div>{this.props.language !== "eng" ? "校址：不限" : "Site : Unlimited"}</div>}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="previewPackagePrice adminChangingSellingPackageFieldContainer">HKD$ <OutlinedInput id="inputForAdminChangingSellingPackageContent02" type="number" value={packagesOnList.price} onChange={this.handleChangeDetailsForPurchasingPackages("price", index)} /></div>
                                                                                                </div>
                                                                                            </div>
                                                                                    )
                                                                                    }
                                                                                </div>}

                                                                            {tutorRentalTokenPackages.length === 0 ?
                                                                                null :
                                                                                <div className="usageGroupBaseArea">
                                                                                    <div className="lilSpace">
                                                                                        <div className="usage4AreaTitle">{this.props.language !== "eng" ? "租用導師套券" : "Tutor Hiring Package"}</div>
                                                                                        <div className="usagePackagesSumUp">{this.props.language !== "eng" ? `共 ${tutorRentalTokenPackages.length} 個項目` : `Count ${tutorRentalTokenPackages.length}`}</div>

                                                                                    </div>
                                                                                    {this.state.packagesReadyForPurchase.map((packagesOnList: any, index: number) =>
                                                                                        packagesOnList.token_usage_id !== 4 ? null :
                                                                                            <div className="contentBlockOfTutorRentalTokenOnPreviewPage">
                                                                                                <CloseIcon id="previewDelIcon" onClick={() => this.handleDelPurchasePackageClick(index)} />
                                                                                                <div className="previewContentBlockAligner">
                                                                                                    <PeopleAltIcon id="previewPackageIcon" />
                                                                                                    <div className="previewPackageName">{packagesOnList.package_name}</div>
                                                                                                    <div className="previewPackageContent">
                                                                                                        <div className="previewPackageContentAligner">
                                                                                                            {packagesOnList.token_based_package ?
                                                                                                                // <div className="inlineElement">{this.props.language !== "eng"? "有效：" : "Valid"}{packagesOnList.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div>
                                                                                                                <div className="inlineElement adminChangingSellingPackageFieldContainer">{this.props.language !== "eng" ? "有效：" : "Valid"}<OutlinedInput id="inputForAdminChangingSellingPackageContent" type="number" value={packagesOnList.token_amount} endAdornment={<InputAdornment position="end">{this.props.language !== "eng" ? "張" : "Token"}</InputAdornment>} onChange={this.handleChangeDetailsForPurchasingPackages("token_amount", index)} /></div>
                                                                                                                :
                                                                                                                <div className="inlineElement">{this.props.language !== "eng" ? "有效：" : "Valid"}{packagesOnList.validate_period} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="previewPackagePrice adminChangingSellingPackageFieldContainer">HKD$ <OutlinedInput id="inputForAdminChangingSellingPackageContent02" type="number" value={packagesOnList.price} onChange={this.handleChangeDetailsForPurchasingPackages("price", index)} /></div>
                                                                                                </div>
                                                                                            </div>
                                                                                    )
                                                                                    }
                                                                                </div>}
                                                                        </div>
                                                                }
                                                                {
                                                                    this.state.productsReadyForPurchase.length === 0 ?
                                                                        null :
                                                                        <div className="previewBaseArea">
                                                                            <div className="usageGroupBaseArea">
                                                                                <div className="lilSpace">
                                                                                    <div className="usage1AreaTitle">{this.props.language !== "eng" ? "貨品選購" : "Product"}</div>
                                                                                    <div className="usagePackagesSumUp">{this.props.language !== "eng" ? `共 ${this.state.productsReadyForPurchase.length} 個項目` : `Count ${this.state.productsReadyForPurchase.length}`}</div>
                                                                                </div>

                                                                                {this.state.productsReadyForPurchase.map((productOnList: any) =>
                                                                                    <div className="contentBlockOfClassTokenOnPreviewPage">
                                                                                        <div className='w-100'>
                                                                                            <CloseIcon id="previewDelIcon" onClick={() => this.handleDelPurchaseProductClick(productOnList.sku)} />
                                                                                            <div className="previewContentBlockAligner">
                                                                                                <PeopleAltIcon id="previewPackageIcon" />
                                                                                                <div className="previewPackageName">{productOnList.product_name}</div>
                                                                                                <div className="previewPackageContent">
                                                                                                    <div className="previewPackageContentAligner">
                                                                                                        <div className="inlineElement">{<div>SKU：{productOnList.sku}</div>}</div>
                                                                                                        <div className="inlineElement">{<div>{this.props.language !== "eng" ? "種類：" : "Sport:"}{(this.props.allCategoryTypes.find((category: any) => category.id === productOnList.product_category_id)).category}</div>}</div>

                                                                                                        <div className="inlineElement">{<div>{this.props.language !== "eng" ? "存倉：" : "Site :"}{(this.props.allSites.find((site: any) => site.id === productOnList.store_site_id)).site_key}</div>}</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="previewPackagePrice">HKD$ {productOnList.markedPrice}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="inventoryProductAttributeOnBucketPreview">
                                                                                            {
                                                                                                productOnList.attributeArr.map((attribute: any) =>
                                                                                                    <div className="productContentInlineDisplayOnBucketPreview">{attribute.attribute} : <div className="productContentInlineDisplayThin">{attribute.value} </div></div>
                                                                                                )
                                                                                            }

                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>


                                    :

                                    <div className="col-9" id="mainContentBase">
                                        {this.state.showMarkReferralDetailsBox ? <MarkReferralDetailsBox handleClose={this.handleShowaMarkReferralDetailsBox} confirmSubmitReferralDetails={(referringCustomerID: number) => this.handleSubmitReferringCustomerIDfromMarkReferralDetailsBox(referringCustomerID)} language={this.props.language} /> : null}
                                        {/* <div className="tutorSimplifiedHeadCard"> */}
                                        <div className="row">
                                            <div className="col-12">
                                                {this.state.showCustomerManagePage ?
                                                    <div>
                                                        <Button id="hiddenBtn" onClick={() => this.handleManageClick(this.state.selectedCustomerId, purchaseBucketNum)}> <h3 id="sectionHeading">《 {this.props.language !== "eng" ? "返回顧客列表" : "Back to Customer List"}</h3> </Button>
                                                        <h5 id="sectionHeading"> -- {this.props.language !== "eng" ? "顧客詳情" : "Customer Details"} </h5>
                                                    </div>
                                                    :
                                                    <h3 id="sectionHeading" > {this.props.language !== "eng" ? "顧客列表" : "Customer List"} </h3>

                                                }
                                            </div>
                                        </div>
                                        {this.state.showCustomerManagePage ?
                                            <div className="row">
                                                <div className="col-2">
                                                    {this.state.manageCustomerPageNav === 'checkCustomerDetails' ?
                                                        <Button className="onPageBtn" startIcon={<AddIcon />} >{this.props.language !== "eng" ? "查看詳細資料" : "General Info."}</Button>
                                                        :
                                                        <Button className="customerManageNav" startIcon={<AddIcon />} onClick={() => this.handleCustomerPageNavChange('checkCustomerDetails')}>{this.props.language !== "eng" ? "查看詳細資料" : "General Info."}</Button>

                                                    }
                                                </div>

                                                <div className="col-2">
                                                    {this.state.manageCustomerPageNav === 'customerBuyToken' ?

                                                        <Button className="onPageBtn" >{this.props.language !== "eng" ? "購買課程" : "Purchase Package"}</Button>
                                                        :
                                                        <Button className="customerManageNav" onClick={() => this.handleCustomerPageNavChange('customerBuyToken')}>{this.props.language !== "eng" ? "購買課程" : "Purchase Package"}</Button>

                                                    }
                                                </div>

                                                <div className="col-2">
                                                    {this.state.manageCustomerPageNav === 'customerProductListingPage' ?

                                                        <Button className="onPageBtn">{this.props.language !== "eng" ? "購買產品" : "Purchase Product"}</Button>
                                                        :
                                                        <Button className="customerManageNav" onClick={() => this.handleCustomerPageNavChange('customerProductListingPage')}>{this.props.language !== "eng" ? "購買產品" : "Purchase Product"}</Button>

                                                    }
                                                </div>

                                                <div className="col-2">
                                                    {this.state.manageCustomerPageNav === 'checkCustomerPurchasePaymentHistory' ?
                                                        <Button className="onPageBtn">{this.props.language !== "eng" ? "查看消費紀錄" : "Purchase History"}</Button>
                                                        :
                                                        <Button className="customerManageNav" onClick={() => this.handleCustomerPageNavChange('checkCustomerPurchasePaymentHistory')}>{this.props.language !== "eng" ? "查看消費紀錄" : "Purchase History"}</Button>

                                                    }
                                                </div>

                                                <div className="col-2">
                                                    {this.state.manageCustomerPageNav === 'checkCustomerParticipationHistory' ?

                                                        <Button className="onPageBtn">{this.props.language !== "eng" ? "查看服務使用紀錄" : "Activity Records"}</Button>
                                                        :
                                                        <Button className="customerManageNav" onClick={() => this.handleCustomerPageNavChange('checkCustomerParticipationHistory')}>{this.props.language !== "eng" ? "查看服務使用紀錄" : "Activity Records"}</Button>

                                                    }
                                                </div>

                                                <div className="col-2">
                                                    <Button className="customerManageNav">----------</Button>
                                                </div>
                                            </div>
                                            :
                                            <div className="row">
                                                <div className="col-4 ">
                                                    {/* <SearchIcon className="searchIcon" /> */}
                                                    {/* <div className="searchCustomerBar">
                                                        <div className="customerSearchBarAlign">
                                                            <InputBase
                                                                placeholder="Search…"
                                                                inputProps={{ 'aria-label': 'search' }}
                                                                className="searchBar"
                                                            />
                                                            <FormControl className="selectInputs searchObjectInput">
                                                                <InputLabel id="demo-simple-select-outlined-whiteLabel">
                                                                    Search By
                                                    </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-outlined-whiteLabel"
                                                                    id="demo-simple-select-outlined"
                                                                // defaultValue={this.state.tutor_sex}
                                                                // value={this.state.listingPage}
                                                                // onChange={this.handleTutorInput}
                                                                >
                                                                    <MenuItem value={'name'}>Name</MenuItem>

                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                    </div>
                                                    <Button id="customerSearchBtn">Search</Button> */}

                                                    <div className="searchRow">
                                                        <FormControl id="searchOptionInCustomerMainListingPage">
                                                            <InputLabel >
                                                                {this.props.language !== "eng" ? "方法" : "Info"}
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                value={this.state.searchingMethod}
                                                                onChange={(e) => this.handleSearchingMethodChange(e)}
                                                            // labelWidth={labelWidth}
                                                            >
                                                                {/* <MenuItem value="">
                                                                    <em>未選</em>
                                                                    /MenuItem> */}
                                                                <MenuItem value='customerID'>{this.props.language !== "eng" ? "顧客編號" : "Customer ID"} :</MenuItem>
                                                                <MenuItem value='tel'>{this.props.language !== "eng" ? "電話" : "TEL"} :</MenuItem>

                                                            </Select>
                                                        </FormControl>
                                                        <FormControl id="searchingInputInCustomerMainListingPage">
                                                            <TextField
                                                                label="請輸入..."
                                                                value={this.state.searchingInput}
                                                                onChange={(e) => this.handleSearchingInputChange(e)}
                                                                onKeyUp={(e) => (e.keyCode === 13 ? this.handleSearchEnterPress(e) : null)}
                                                            />
                                                        </FormControl>
                                                        <div id="searchBtnContainerInCustomerMainListingPage">
                                                            <Button startIcon={<SearchIcon />} id="searchBtnOnAddNewBookingWindow" onClick={this.handleSearchClick}>{this.props.language !== "eng" ? "搜尋" : "Search"}</Button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-2">
                                                    <Button id="newCustomerBtn" startIcon={<AddIcon />} onClick={this.handleAddCustomerClick}>{this.props.language !== "eng" ? "新增顧客" : "New Customer"}</Button>
                                                </div>

                                                <div className="col-2">
                                                    <Button id="unPayCustomerBtn" >{this.props.language !== "eng" ? "顯示未完成付款顧客" : "Unpaid Customers"}</Button>
                                                </div>
                                                <div className="col-2">
                                                    <Button id="datActiveCustomerBtn">{this.props.language !== "eng" ? "顯示是日活躍顧客" : "Active Customers"}</Button>
                                                </div>

                                                <div className="col-2">
                                                    <Button id="trailCustomerBtn">{this.props.language !== "eng" ? "顯示試堂顧客" : "Trial Customers"}</Button>
                                                </div>
                                            </div>
                                        }

                                        {this.state.showCustomerManagePage ?

                                            <div className="row">
                                                <div className="col-12" id="customerNavPagesArea">
                                                    {this.state.manageCustomerPageNav === 'checkCustomerDetails' ?
                                                        this.props.loading ?
                                                            <div className="loadingContainerRow">
                                                                <div className="inlineDisplay">
                                                                    <div className="loadingContainer">
                                                                        <CircularProgress id="loadingCircle" />
                                                                        <div className="loadingLabel">Loading ...</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :

                                                            // CUSTOMER BASIC DETAILS PAGE 
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    {
                                                                        this.props.customerPreviousNoTokenBookings.length === 0 ? null :
                                                                            <ProcessNoTokenBookingWindow newlyPurchasedToken={this.props.newlyPurchasedPackagesDetails} previousNoTokenBookings={this.props.customerPreviousNoTokenBookings} ageGroupLists={this.props.ageGroupList} genreLists={this.props.genreTypeList} handleSubmit={(processData: any) => this.handleProcessPreviousNoTokenBooking(processData)} language={this.props.language} />
                                                                    }
                                                                    <div className="contentBlock">
                                                                        <div className="blockTitleRowOnCustomerPackagesDisplayTitle">
                                                                            <h3 className="blockTitle">{this.props.language !== "eng" ? "顧客套券一覧" : "Customer Packages"}</h3>
                                                                            {
                                                                                this.state.showCustomerExpiredPackages ?
                                                                                    <Button id="showExpiredPackagesBtn" onClick={this.handleShowExpiredPackagesClick} startIcon={<VisibilityOffIcon />}> {this.props.language !== "eng" ? "隱藏過期套劵" : "Hide Expired Package"}</Button>
                                                                                    :
                                                                                    <Button id="showExpiredPackagesBtn" onClick={this.handleShowExpiredPackagesClick} startIcon={<VisibilityIcon />}>{this.props.language !== "eng" ? "顯示近365日內過期套劵" : "Display Packages Expired Within 365 Days"}</Button>

                                                                            }
                                                                        </div>
                                                                        {this.props.packagesOwnByCustomer.activatePackages.length === 0 && this.props.packagesOwnByCustomer.customerUnusedPackages.length === 0 ?
                                                                            <div className="discountListingAreaFillUp">
                                                                                <div className="noDiscountIndicator">- {this.props.language !== "eng" ? "無" : "No Applicable Discount"} -</div>
                                                                            </div>
                                                                            : null}
                                                                        {
                                                                            //DISPLAYING TOKEN USING OF CUSTOMER
                                                                            this.props.packagesOwnByCustomer.activatePackages.length !== 0 ?

                                                                                this.props.packagesOwnByCustomer.activatePackages.map((activatedPackage: any) =>
                                                                                    activatedPackage.usage === 'Class Participation' ?
                                                                                        this.state.editingCustomerPackageDetails.id !== activatedPackage.id ?
                                                                                            <div className="contentBlockOfClassTokenOfCustomerOnListingPage">
                                                                                                {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>

                                                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(activatedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>
                                                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(), 'days') + 1} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{activatedPackage.site ? (this.props.allSites.find((site: any) => site.id === activatedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>
                                                                                                <div className="editPackageBtnContainer">
                                                                                                    <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(activatedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <div className="contentBlockOfClassTokenOfCustomerOnListingPage">
                                                                                                {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>
                                                                                                <form className="editPackageInputField">
                                                                                                    <TextField
                                                                                                        id="filled-number"
                                                                                                        label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                        type="number"
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true,
                                                                                                        }}
                                                                                                        variant="filled"
                                                                                                        value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                        onChange={this.handleEditPackageTokenAmount}
                                                                                                    />
                                                                                                </form>
                                                                                                <form className="editPackageInputField">
                                                                                                    <TextField
                                                                                                        id="filled-number"
                                                                                                        label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                        type="date"
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true,
                                                                                                        }}
                                                                                                        variant="filled"
                                                                                                        value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                        onChange={this.handleEditPackageStartingDate}
                                                                                                    />
                                                                                                </form>
                                                                                                <form className="editPackageInputField">
                                                                                                    <TextField
                                                                                                        id="editPackageInputField"
                                                                                                        label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                        type="date"
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true,
                                                                                                        }}
                                                                                                        variant="filled"
                                                                                                        value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                        onChange={this.handleEditPackageEndingDate}
                                                                                                    />
                                                                                                </form>

                                                                                                {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                <div className="editPackageBtnContainer">
                                                                                                    <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                </div>
                                                                                            </div>
                                                                                        : activatedPackage.usage === 'Private Class Booking' ?
                                                                                            this.state.editingCustomerPackageDetails.id !== activatedPackage.id ?

                                                                                                <div className="contentBlockOfPrivateClassTokenOfCustomerOnListingPage">
                                                                                                    {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                    <div className="aligner"><PersonIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                    <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>
                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(activatedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>

                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>

                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(), 'days') + 1} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{activatedPackage.site ? (this.props.allSites.find((site: any) => site.id === activatedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>


                                                                                                    <div className="editPackageBtnContainer">
                                                                                                        <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(activatedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <div className="contentBlockOfPrivateClassTokenOfCustomerOnListingPage">
                                                                                                    {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                    <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>
                                                                                                    <form className="editPackageInputField">
                                                                                                        <TextField
                                                                                                            id="filled-number"
                                                                                                            label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                            type="number"
                                                                                                            InputLabelProps={{
                                                                                                                shrink: true,
                                                                                                            }}
                                                                                                            variant="filled"
                                                                                                            value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                            onChange={this.handleEditPackageTokenAmount}
                                                                                                        />
                                                                                                    </form>
                                                                                                    <form className="editPackageInputField">
                                                                                                        <TextField
                                                                                                            id="filled-number"
                                                                                                            label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                            type="date"
                                                                                                            InputLabelProps={{
                                                                                                                shrink: true,
                                                                                                            }}
                                                                                                            variant="filled"
                                                                                                            value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                            onChange={this.handleEditPackageStartingDate}
                                                                                                        />
                                                                                                    </form>
                                                                                                    <form className="editPackageInputField">
                                                                                                        <TextField
                                                                                                            id="editPackageInputField"
                                                                                                            label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                            type="date"
                                                                                                            InputLabelProps={{
                                                                                                                shrink: true,
                                                                                                            }}
                                                                                                            variant="filled"
                                                                                                            value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                            onChange={this.handleEditPackageEndingDate}
                                                                                                        />
                                                                                                    </form>

                                                                                                    {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                    {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                    <div className="editPackageBtnContainer">
                                                                                                        <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            : activatedPackage.usage === 'Classroom Rental' ?
                                                                                                this.state.editingCustomerPackageDetails.id !== activatedPackage.id ?

                                                                                                    <div className="contentBlockOfRoomRentalTokenOfCustomerOnListingPage">
                                                                                                        {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                        <div className="aligner"><RoomIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                        <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>

                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(activatedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>

                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(), 'days') + 1} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{activatedPackage.site ? (this.props.allSites.find((site: any) => site.id === activatedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>


                                                                                                        <div className="editPackageBtnContainer">
                                                                                                            <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(activatedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="contentBlockOfRoomRentalTokenOfCustomerOnListingPage">
                                                                                                        {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                        <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                        <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="filled-number"
                                                                                                                label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                                type="number"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                                onChange={this.handleEditPackageTokenAmount}
                                                                                                            />
                                                                                                        </form>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="filled-number"
                                                                                                                label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                                type="date"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                                onChange={this.handleEditPackageStartingDate}
                                                                                                            />
                                                                                                        </form>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="editPackageInputField"
                                                                                                                label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                                type="date"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                                onChange={this.handleEditPackageEndingDate}
                                                                                                            />
                                                                                                        </form>

                                                                                                        {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                        {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                        <div className="editPackageBtnContainer">
                                                                                                            <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                : activatedPackage.usage === 'Tutor Rental' ?
                                                                                                    this.state.editingCustomerPackageDetails.id !== activatedPackage.id ?

                                                                                                        <div className="contentBlockOfTutorRentalTokenOfCustomerOnListingPage">
                                                                                                            {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                            <div className="aligner"><AssignmentIndIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                            <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>

                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(activatedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>

                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(), 'days') + 1} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{activatedPackage.site ? (this.props.allSites.find((site: any) => site.id === activatedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>


                                                                                                            <div className="editPackageBtnContainer">
                                                                                                                <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(activatedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div className="contentBlockOfTutorRentalTokenOfCustomerOnListingPage">
                                                                                                            {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                            <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                            <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>
                                                                                                            <form className="editPackageInputField">
                                                                                                                <TextField
                                                                                                                    id="filled-number"
                                                                                                                    label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                                    type="number"
                                                                                                                    InputLabelProps={{
                                                                                                                        shrink: true,
                                                                                                                    }}
                                                                                                                    variant="filled"
                                                                                                                    value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                                    onChange={this.handleEditPackageTokenAmount}
                                                                                                                />
                                                                                                            </form>
                                                                                                            <form className="editPackageInputField">
                                                                                                                <TextField
                                                                                                                    id="filled-number"
                                                                                                                    label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                                    type="date"
                                                                                                                    InputLabelProps={{
                                                                                                                        shrink: true,
                                                                                                                    }}
                                                                                                                    variant="filled"
                                                                                                                    value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                                    onChange={this.handleEditPackageStartingDate}
                                                                                                                />
                                                                                                            </form>
                                                                                                            <form className="editPackageInputField">
                                                                                                                <TextField
                                                                                                                    id="editPackageInputField"
                                                                                                                    label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                                    type="date"
                                                                                                                    InputLabelProps={{
                                                                                                                        shrink: true,
                                                                                                                    }}
                                                                                                                    variant="filled"
                                                                                                                    value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                                    onChange={this.handleEditPackageEndingDate}
                                                                                                                />
                                                                                                            </form>

                                                                                                            {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                            {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                            <div className="editPackageBtnContainer">
                                                                                                                <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    : null
                                                                                )
                                                                                : null}
                                                                        {
                                                                            //DISPLAYING TOKEN NOT USED OF CUSTOMER
                                                                            this.props.packagesOwnByCustomer.customerUnusedPackages.length !== 0 ?

                                                                                this.props.packagesOwnByCustomer.customerUnusedPackages.map((activatedPackage: any) =>
                                                                                    activatedPackage.usage === 'Class Participation' ?
                                                                                        activatedPackage.validate_period ?
                                                                                            !activatedPackage.token_based_package ?
                                                                                                this.state.editingCustomerPackageDetails.id !== activatedPackage.id ?

                                                                                                    <div className="contentBlockOfClassTokenOfCustomerOnListingPage">
                                                                                                        <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePeriodPackageActivateClick(activatedPackage.id)} />
                                                                                                        <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                        <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>

                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(activatedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>

                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "未使用" : "NEW"}</div>
                                                                                                        <div className="listingPackageOfCustomerContent">- -</div>
                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{activatedPackage.site ? (this.props.allSites.find((site: any) => site.id === activatedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>
                                                                                                        <div className="editPackageBtnContainer">
                                                                                                            <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(activatedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="contentBlockOfClassTokenOfCustomerOnListingPage">
                                                                                                        {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                        <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                        <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="filled-number"
                                                                                                                label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                                type="number"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                                onChange={this.handleEditPackageTokenAmount}
                                                                                                            />
                                                                                                        </form>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="filled-number"
                                                                                                                label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                                type="date"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                                onChange={this.handleEditPackageStartingDate}
                                                                                                            />
                                                                                                        </form>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="editPackageInputField"
                                                                                                                label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                                type="date"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                                onChange={this.handleEditPackageEndingDate}
                                                                                                            />
                                                                                                        </form>

                                                                                                        {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                        {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                        <div className="editPackageBtnContainer">
                                                                                                            <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                :
                                                                                                this.state.editingCustomerPackageDetails.id !== activatedPackage.id ?

                                                                                                    <div className="contentBlockOfClassTokenOfCustomerOnListingPage">
                                                                                                        <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                        <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>

                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(activatedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>

                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "未使用" : "NEW"}</div>
                                                                                                        <div className="listingPackageOfCustomerContent">- -</div>
                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{activatedPackage.site ? (this.props.allSites.find((site: any) => site.id === activatedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>
                                                                                                        <div className="editPackageBtnContainer">
                                                                                                            <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(activatedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="contentBlockOfClassTokenOfCustomerOnListingPage">
                                                                                                        {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                        <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                        <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="filled-number"
                                                                                                                label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                                type="number"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                                onChange={this.handleEditPackageTokenAmount}
                                                                                                            />
                                                                                                        </form>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="filled-number"
                                                                                                                label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                                type="date"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                                onChange={this.handleEditPackageStartingDate}
                                                                                                            />
                                                                                                        </form>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="editPackageInputField"
                                                                                                                label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                                type="date"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                                onChange={this.handleEditPackageEndingDate}
                                                                                                            />
                                                                                                        </form>

                                                                                                        {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                        {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                        <div className="editPackageBtnContainer">
                                                                                                            <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                            :
                                                                                            this.state.editingCustomerPackageDetails.id !== activatedPackage.id ?

                                                                                                <div className="contentBlockOfClassTokenOfCustomerOnListingPage">
                                                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                    <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>

                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(activatedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>

                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "未使用" : "NEW"}</div>
                                                                                                    <div className="listingPackageOfCustomerContent">- -</div>
                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{activatedPackage.site ? (this.props.allSites.find((site: any) => site.id === activatedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>
                                                                                                    <div className="editPackageBtnContainer">
                                                                                                        <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(activatedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <div className="contentBlockOfClassTokenOfCustomerOnListingPage">
                                                                                                    {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                    <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>
                                                                                                    <form className="editPackageInputField">
                                                                                                        <TextField
                                                                                                            id="filled-number"
                                                                                                            label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                            type="number"
                                                                                                            InputLabelProps={{
                                                                                                                shrink: true,
                                                                                                            }}
                                                                                                            variant="filled"
                                                                                                            value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                            onChange={this.handleEditPackageTokenAmount}
                                                                                                        />
                                                                                                    </form>
                                                                                                    <form className="editPackageInputField">
                                                                                                        <TextField
                                                                                                            id="filled-number"
                                                                                                            label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                            type="date"
                                                                                                            InputLabelProps={{
                                                                                                                shrink: true,
                                                                                                            }}
                                                                                                            variant="filled"
                                                                                                            value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                            onChange={this.handleEditPackageStartingDate}
                                                                                                        />
                                                                                                    </form>
                                                                                                    <form className="editPackageInputField">
                                                                                                        <TextField
                                                                                                            id="editPackageInputField"
                                                                                                            label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                            type="date"
                                                                                                            InputLabelProps={{
                                                                                                                shrink: true,
                                                                                                            }}
                                                                                                            variant="filled"
                                                                                                            value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                            onChange={this.handleEditPackageEndingDate}
                                                                                                        />
                                                                                                    </form>

                                                                                                    {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                    {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                    <div className="editPackageBtnContainer">
                                                                                                        <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                        : activatedPackage.usage === 'Private Class Booking' ?
                                                                                            this.state.editingCustomerPackageDetails.id !== activatedPackage.id ?

                                                                                                <div className="contentBlockOfPrivateClassTokenOfCustomerOnListingPage">
                                                                                                    <div className="aligner"><PersonIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                    <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>

                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(activatedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>

                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "未使用" : "NEW"}</div>
                                                                                                    <div className="listingPackageOfCustomerContent">- -</div>
                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{activatedPackage.site ? (this.props.allSites.find((site: any) => site.id === activatedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>
                                                                                                    <div className="editPackageBtnContainer">
                                                                                                        <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(activatedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <div className="contentBlockOfPrivateClassTokenOfCustomerOnListingPage">
                                                                                                    {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                    <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>
                                                                                                    <form className="editPackageInputField">
                                                                                                        <TextField
                                                                                                            id="filled-number"
                                                                                                            label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                            type="number"
                                                                                                            InputLabelProps={{
                                                                                                                shrink: true,
                                                                                                            }}
                                                                                                            variant="filled"
                                                                                                            value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                            onChange={this.handleEditPackageTokenAmount}
                                                                                                        />
                                                                                                    </form>
                                                                                                    <form className="editPackageInputField">
                                                                                                        <TextField
                                                                                                            id="filled-number"
                                                                                                            label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                            type="date"
                                                                                                            InputLabelProps={{
                                                                                                                shrink: true,
                                                                                                            }}
                                                                                                            variant="filled"
                                                                                                            value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                            onChange={this.handleEditPackageStartingDate}
                                                                                                        />
                                                                                                    </form>
                                                                                                    <form className="editPackageInputField">
                                                                                                        <TextField
                                                                                                            id="editPackageInputField"
                                                                                                            label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                            type="date"
                                                                                                            InputLabelProps={{
                                                                                                                shrink: true,
                                                                                                            }}
                                                                                                            variant="filled"
                                                                                                            value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                            onChange={this.handleEditPackageEndingDate}
                                                                                                        />
                                                                                                    </form>

                                                                                                    {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                    {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                    <div className="editPackageBtnContainer">
                                                                                                        <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            : activatedPackage.usage === 'Classroom Rental' ?
                                                                                                this.state.editingCustomerPackageDetails.id !== activatedPackage.id ?

                                                                                                    <div className="contentBlockOfRoomRentalTokenOfCustomerOnListingPage">
                                                                                                        <div className="aligner"><RoomIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                        <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>

                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(activatedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>

                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "未使用" : "NEW"}</div>
                                                                                                        <div className="listingPackageOfCustomerContent">- -</div>
                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{activatedPackage.site ? (this.props.allSites.find((site: any) => site.id === activatedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>
                                                                                                        <div className="editPackageBtnContainer">
                                                                                                            <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(activatedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="contentBlockOfRoomRentalTokenOfCustomerOnListingPage">
                                                                                                        {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                        <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                        <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="filled-number"
                                                                                                                label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                                type="number"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                                onChange={this.handleEditPackageTokenAmount}
                                                                                                            />
                                                                                                        </form>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="filled-number"
                                                                                                                label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                                type="date"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                                onChange={this.handleEditPackageStartingDate}
                                                                                                            />
                                                                                                        </form>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="editPackageInputField"
                                                                                                                label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                                type="date"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                                onChange={this.handleEditPackageEndingDate}
                                                                                                            />
                                                                                                        </form>

                                                                                                        {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                        {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                        <div className="editPackageBtnContainer">
                                                                                                            <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                : activatedPackage.usage === 'Tutor Rental' ?
                                                                                                    this.state.editingCustomerPackageDetails.id !== activatedPackage.id ?

                                                                                                        <div className="contentBlockOfTutorRentalTokenOfCustomerOnListingPage">
                                                                                                            <div className="aligner"><AssignmentIndIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                            <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>

                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(activatedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>

                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "未使用" : "NEW"}</div>
                                                                                                            <div className="listingPackageOfCustomerContent">- -</div>
                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{activatedPackage.site ? (this.props.allSites.find((site: any) => site.id === activatedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>
                                                                                                            <div className="editPackageBtnContainer">
                                                                                                                <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(activatedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div className="contentBlockOfRoomRentalTokenOfCustomerOnListingPage">
                                                                                                            {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                            <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                            <div className="listingPackageOfCustomerName">{activatedPackage.package_name}</div>
                                                                                                            <form className="editPackageInputField">
                                                                                                                <TextField
                                                                                                                    id="filled-number"
                                                                                                                    label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                                    type="number"
                                                                                                                    InputLabelProps={{
                                                                                                                        shrink: true,
                                                                                                                    }}
                                                                                                                    variant="filled"
                                                                                                                    value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                                    onChange={this.handleEditPackageTokenAmount}
                                                                                                                />
                                                                                                            </form>
                                                                                                            <form className="editPackageInputField">
                                                                                                                <TextField
                                                                                                                    id="filled-number"
                                                                                                                    label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                                    type="date"
                                                                                                                    InputLabelProps={{
                                                                                                                        shrink: true,
                                                                                                                    }}
                                                                                                                    variant="filled"
                                                                                                                    value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                                    onChange={this.handleEditPackageStartingDate}
                                                                                                                />
                                                                                                            </form>
                                                                                                            <form className="editPackageInputField">
                                                                                                                <TextField
                                                                                                                    id="editPackageInputField"
                                                                                                                    label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                                    type="date"
                                                                                                                    InputLabelProps={{
                                                                                                                        shrink: true,
                                                                                                                    }}
                                                                                                                    variant="filled"
                                                                                                                    value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                                    onChange={this.handleEditPackageEndingDate}
                                                                                                                />
                                                                                                            </form>

                                                                                                            {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                            {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                            <div className="editPackageBtnContainer">
                                                                                                                <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    : null
                                                                                )
                                                                                : null
                                                                        }
                                                                        {
                                                                            //DISPLAYING EXPIRED TOKEN PACKAGES OF CUSTOMER
                                                                            !this.state.showCustomerExpiredPackages ? null :
                                                                                this.props.packagesOwnByCustomer.expiredTokenBasedPackages.length !== 0 ?

                                                                                    this.props.packagesOwnByCustomer.expiredTokenBasedPackages.map((expiredTokenBasedPackage: any) =>
                                                                                        expiredTokenBasedPackage.usage === 'Class Participation' ?
                                                                                            this.state.editingCustomerPackageDetails.id !== expiredTokenBasedPackage.id ?
                                                                                                <div className="contentBlockOfExpiredTokenOfCustomerOnListingPage">
                                                                                                    {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                    <div className="listingPackageOfCustomerName">{expiredTokenBasedPackage.package_name}</div>

                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{expiredTokenBasedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(expiredTokenBasedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}{moment(expiredTokenBasedPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "已過期：" : "Expired :"}{moment().diff(moment(expiredTokenBasedPackage.end_date), 'days')} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{expiredTokenBasedPackage.site ? (this.props.allSites.find((site: any) => site.id === expiredTokenBasedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>

                                                                                                    <div className="editPackageBtnContainer">
                                                                                                        <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(expiredTokenBasedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <div className="contentBlockOfExpiredTokenOfCustomerOnListingPage">
                                                                                                    {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                    <div className="listingPackageOfCustomerName">{expiredTokenBasedPackage.package_name}</div>
                                                                                                    <form className="editPackageInputField">
                                                                                                        <TextField
                                                                                                            id="filled-number"
                                                                                                            label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                            type="number"
                                                                                                            InputLabelProps={{
                                                                                                                shrink: true,
                                                                                                            }}
                                                                                                            variant="filled"
                                                                                                            value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                            onChange={this.handleEditPackageTokenAmount}
                                                                                                        />
                                                                                                    </form>
                                                                                                    <form className="editPackageInputField">
                                                                                                        <TextField
                                                                                                            id="filled-number"
                                                                                                            label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                            type="date"
                                                                                                            InputLabelProps={{
                                                                                                                shrink: true,
                                                                                                            }}
                                                                                                            variant="filled"
                                                                                                            value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                            onChange={this.handleEditPackageStartingDate}
                                                                                                        />
                                                                                                    </form>
                                                                                                    <form className="editPackageInputField">
                                                                                                        <TextField
                                                                                                            id="editPackageInputField"
                                                                                                            label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                            type="date"
                                                                                                            InputLabelProps={{
                                                                                                                shrink: true,
                                                                                                            }}
                                                                                                            variant="filled"
                                                                                                            value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                            onChange={this.handleEditPackageEndingDate}
                                                                                                        />
                                                                                                    </form>

                                                                                                    {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                    {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                    <div className="editPackageBtnContainer">
                                                                                                        <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            : expiredTokenBasedPackage.usage === 'Private Class Booking' ?
                                                                                                this.state.editingCustomerPackageDetails.id !== expiredTokenBasedPackage.id ?

                                                                                                    <div className="contentBlockOfExpiredTokenOfCustomerOnListingPage">
                                                                                                        {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                        <div className="aligner"><PersonIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                        <div className="listingPackageOfCustomerName">{expiredTokenBasedPackage.package_name}</div>
                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(expiredTokenBasedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : "Day"}</div>

                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{expiredTokenBasedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>

                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}{moment(expiredTokenBasedPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "已過期：" : "Expired :"}{moment().diff(moment(expiredTokenBasedPackage.end_date), 'days')} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{expiredTokenBasedPackage.site ? (this.props.allSites.find((site: any) => site.id === expiredTokenBasedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>

                                                                                                        <div className="editPackageBtnContainer">
                                                                                                            <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(expiredTokenBasedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="contentBlockOfExpiredTokenOfCustomerOnListingPage">
                                                                                                        {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                        <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                        <div className="listingPackageOfCustomerName">{expiredTokenBasedPackage.package_name}</div>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="filled-number"
                                                                                                                label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                                type="number"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                                onChange={this.handleEditPackageTokenAmount}
                                                                                                            />
                                                                                                        </form>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="filled-number"
                                                                                                                label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                                type="date"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                                onChange={this.handleEditPackageStartingDate}
                                                                                                            />
                                                                                                        </form>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="editPackageInputField"
                                                                                                                label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                                type="date"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                                onChange={this.handleEditPackageEndingDate}
                                                                                                            />
                                                                                                        </form>

                                                                                                        {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                        {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                        <div className="editPackageBtnContainer">
                                                                                                            <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                : expiredTokenBasedPackage.usage === 'Classroom Rental' ?
                                                                                                    this.state.editingCustomerPackageDetails.id !== expiredTokenBasedPackage.id ?

                                                                                                        <div className="contentBlockOfExpiredTokenOfCustomerOnListingPage">
                                                                                                            {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                            <div className="aligner"><RoomIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                            <div className="listingPackageOfCustomerName">{expiredTokenBasedPackage.package_name}</div>

                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{expiredTokenBasedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(expiredTokenBasedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : "Day"}</div>

                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}{moment(expiredTokenBasedPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "已過期：" : "Expired :"}{moment().diff(moment(expiredTokenBasedPackage.end_date), 'days')} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{expiredTokenBasedPackage.site ? (this.props.allSites.find((site: any) => site.id === expiredTokenBasedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>

                                                                                                            <div className="editPackageBtnContainer">
                                                                                                                <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(expiredTokenBasedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div className="contentBlockOfExpiredTokenOfCustomerOnListingPage">
                                                                                                            {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                            <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                            <div className="listingPackageOfCustomerName">{expiredTokenBasedPackage.package_name}</div>
                                                                                                            <form className="editPackageInputField">
                                                                                                                <TextField
                                                                                                                    id="filled-number"
                                                                                                                    label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                                    type="number"
                                                                                                                    InputLabelProps={{
                                                                                                                        shrink: true,
                                                                                                                    }}
                                                                                                                    variant="filled"
                                                                                                                    value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                                    onChange={this.handleEditPackageTokenAmount}
                                                                                                                />
                                                                                                            </form>
                                                                                                            <form className="editPackageInputField">
                                                                                                                <TextField
                                                                                                                    id="filled-number"
                                                                                                                    label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                                    type="date"
                                                                                                                    InputLabelProps={{
                                                                                                                        shrink: true,
                                                                                                                    }}
                                                                                                                    variant="filled"
                                                                                                                    value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                                    onChange={this.handleEditPackageStartingDate}
                                                                                                                />
                                                                                                            </form>
                                                                                                            <form className="editPackageInputField">
                                                                                                                <TextField
                                                                                                                    id="editPackageInputField"
                                                                                                                    label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                                    type="date"
                                                                                                                    InputLabelProps={{
                                                                                                                        shrink: true,
                                                                                                                    }}
                                                                                                                    variant="filled"
                                                                                                                    value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                                    onChange={this.handleEditPackageEndingDate}
                                                                                                                />
                                                                                                            </form>

                                                                                                            {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                            {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                            <div className="editPackageBtnContainer">
                                                                                                                <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    : expiredTokenBasedPackage.usage === 'Tutor Rental' ?
                                                                                                        this.state.editingCustomerPackageDetails.id !== expiredTokenBasedPackage.id ?

                                                                                                            <div className="contentBlockOfExpiredTokenOfCustomerOnListingPage">
                                                                                                                {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                                <div className="aligner"><AssignmentIndIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                                <div className="listingPackageOfCustomerName">{expiredTokenBasedPackage.package_name}</div>

                                                                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{expiredTokenBasedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(expiredTokenBasedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : "Day"}</div>

                                                                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}{moment(expiredTokenBasedPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "已過期：" : "Expired :"}{moment().diff(moment(expiredTokenBasedPackage.end_date), 'days')} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{expiredTokenBasedPackage.site ? (this.props.allSites.find((site: any) => site.id === expiredTokenBasedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>

                                                                                                                <div className="editPackageBtnContainer">
                                                                                                                    <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(expiredTokenBasedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div className="contentBlockOfExpiredTokenOfCustomerOnListingPage">
                                                                                                                {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                                <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                                <div className="listingPackageOfCustomerName">{expiredTokenBasedPackage.package_name}</div>
                                                                                                                <form className="editPackageInputField">
                                                                                                                    <TextField
                                                                                                                        id="filled-number"
                                                                                                                        label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                                        type="number"
                                                                                                                        InputLabelProps={{
                                                                                                                            shrink: true,
                                                                                                                        }}
                                                                                                                        variant="filled"
                                                                                                                        value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                                        onChange={this.handleEditPackageTokenAmount}
                                                                                                                    />
                                                                                                                </form>
                                                                                                                <form className="editPackageInputField">
                                                                                                                    <TextField
                                                                                                                        id="filled-number"
                                                                                                                        label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                                        type="date"
                                                                                                                        InputLabelProps={{
                                                                                                                            shrink: true,
                                                                                                                        }}
                                                                                                                        variant="filled"
                                                                                                                        value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                                        onChange={this.handleEditPackageStartingDate}
                                                                                                                    />
                                                                                                                </form>
                                                                                                                <form className="editPackageInputField">
                                                                                                                    <TextField
                                                                                                                        id="editPackageInputField"
                                                                                                                        label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                                        type="date"
                                                                                                                        InputLabelProps={{
                                                                                                                            shrink: true,
                                                                                                                        }}
                                                                                                                        variant="filled"
                                                                                                                        value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                                        onChange={this.handleEditPackageEndingDate}
                                                                                                                    />
                                                                                                                </form>

                                                                                                                {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                                {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                                <div className="editPackageBtnContainer">
                                                                                                                    <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        : null
                                                                                    )
                                                                                    : null}

                                                                        {
                                                                            //DISPLAYING EXPIRED NON-TOKEN PACKAGE OF CUSTOMER
                                                                            !this.state.showCustomerExpiredPackages ? null :
                                                                                this.props.packagesOwnByCustomer.expiredNonTokenBasedPackages.length !== 0 ?

                                                                                    this.props.packagesOwnByCustomer.expiredNonTokenBasedPackages.map((expiredTokenBasedPackage: any) =>
                                                                                        expiredTokenBasedPackage.usage === 'Class Participation' ?
                                                                                            this.state.editingCustomerPackageDetails.id !== expiredTokenBasedPackage.id ?
                                                                                                <div className="contentBlockOfExpiredTokenOfCustomerOnListingPage">
                                                                                                    {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                    <div className="listingPackageOfCustomerName">{expiredTokenBasedPackage.package_name}</div>

                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{expiredTokenBasedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(expiredTokenBasedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}{moment(expiredTokenBasedPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "已過期：" : "Expired :"}{moment().diff(moment(expiredTokenBasedPackage.end_date), 'days')} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{expiredTokenBasedPackage.site ? (this.props.allSites.find((site: any) => site.id === expiredTokenBasedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>

                                                                                                    <div className="editPackageBtnContainer">
                                                                                                        <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(expiredTokenBasedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                <div className="contentBlockOfExpiredTokenOfCustomerOnListingPage">
                                                                                                    {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                    <div className="listingPackageOfCustomerName">{expiredTokenBasedPackage.package_name}</div>
                                                                                                    <form className="editPackageInputField">
                                                                                                        <TextField
                                                                                                            id="filled-number"
                                                                                                            label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                            type="number"
                                                                                                            InputLabelProps={{
                                                                                                                shrink: true,
                                                                                                            }}
                                                                                                            variant="filled"
                                                                                                            value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                            onChange={this.handleEditPackageTokenAmount}
                                                                                                        />
                                                                                                    </form>
                                                                                                    <form className="editPackageInputField">
                                                                                                        <TextField
                                                                                                            id="filled-number"
                                                                                                            label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                            type="date"
                                                                                                            InputLabelProps={{
                                                                                                                shrink: true,
                                                                                                            }}
                                                                                                            variant="filled"
                                                                                                            value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                            onChange={this.handleEditPackageStartingDate}
                                                                                                        />
                                                                                                    </form>
                                                                                                    <form className="editPackageInputField">
                                                                                                        <TextField
                                                                                                            id="editPackageInputField"
                                                                                                            label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                            type="date"
                                                                                                            InputLabelProps={{
                                                                                                                shrink: true,
                                                                                                            }}
                                                                                                            variant="filled"
                                                                                                            value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                            onChange={this.handleEditPackageEndingDate}
                                                                                                        />
                                                                                                    </form>

                                                                                                    {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                    {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                    <div className="editPackageBtnContainer">
                                                                                                        <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            : expiredTokenBasedPackage.usage === 'Private Class Booking' ?
                                                                                                this.state.editingCustomerPackageDetails.id !== expiredTokenBasedPackage.id ?

                                                                                                    <div className="contentBlockOfExpiredTokenOfCustomerOnListingPage">
                                                                                                        {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                        <div className="aligner"><PersonIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                        <div className="listingPackageOfCustomerName">{expiredTokenBasedPackage.package_name}</div>
                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(expiredTokenBasedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : "Day"}</div>

                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{expiredTokenBasedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>

                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}{moment(expiredTokenBasedPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "已過期：" : "Expired :"}{moment().diff(moment(expiredTokenBasedPackage.end_date), 'days')} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{expiredTokenBasedPackage.site ? (this.props.allSites.find((site: any) => site.id === expiredTokenBasedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>

                                                                                                        <div className="editPackageBtnContainer">
                                                                                                            <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(expiredTokenBasedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="contentBlockOfExpiredTokenOfCustomerOnListingPage">
                                                                                                        {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                        <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                        <div className="listingPackageOfCustomerName">{expiredTokenBasedPackage.package_name}</div>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="filled-number"
                                                                                                                label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                                type="number"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                                onChange={this.handleEditPackageTokenAmount}
                                                                                                            />
                                                                                                        </form>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="filled-number"
                                                                                                                label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                                type="date"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                                onChange={this.handleEditPackageStartingDate}
                                                                                                            />
                                                                                                        </form>
                                                                                                        <form className="editPackageInputField">
                                                                                                            <TextField
                                                                                                                id="editPackageInputField"
                                                                                                                label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                                type="date"
                                                                                                                InputLabelProps={{
                                                                                                                    shrink: true,
                                                                                                                }}
                                                                                                                variant="filled"
                                                                                                                value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                                onChange={this.handleEditPackageEndingDate}
                                                                                                            />
                                                                                                        </form>

                                                                                                        {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                        {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                        <div className="editPackageBtnContainer">
                                                                                                            <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                : expiredTokenBasedPackage.usage === 'Classroom Rental' ?
                                                                                                    this.state.editingCustomerPackageDetails.id !== expiredTokenBasedPackage.id ?

                                                                                                        <div className="contentBlockOfExpiredTokenOfCustomerOnListingPage">
                                                                                                            {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                            <div className="aligner"><RoomIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                            <div className="listingPackageOfCustomerName">{expiredTokenBasedPackage.package_name}</div>

                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{expiredTokenBasedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(expiredTokenBasedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : "Day"}</div>

                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}{moment(expiredTokenBasedPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "已過期：" : "Expired :"}{moment().diff(moment(expiredTokenBasedPackage.end_date), 'days')} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{expiredTokenBasedPackage.site ? (this.props.allSites.find((site: any) => site.id === expiredTokenBasedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>

                                                                                                            <div className="editPackageBtnContainer">
                                                                                                                <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(expiredTokenBasedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                        <div className="contentBlockOfExpiredTokenOfCustomerOnListingPage">
                                                                                                            {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                            <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                            <div className="listingPackageOfCustomerName">{expiredTokenBasedPackage.package_name}</div>
                                                                                                            <form className="editPackageInputField">
                                                                                                                <TextField
                                                                                                                    id="filled-number"
                                                                                                                    label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                                    type="number"
                                                                                                                    InputLabelProps={{
                                                                                                                        shrink: true,
                                                                                                                    }}
                                                                                                                    variant="filled"
                                                                                                                    value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                                    onChange={this.handleEditPackageTokenAmount}
                                                                                                                />
                                                                                                            </form>
                                                                                                            <form className="editPackageInputField">
                                                                                                                <TextField
                                                                                                                    id="filled-number"
                                                                                                                    label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                                    type="date"
                                                                                                                    InputLabelProps={{
                                                                                                                        shrink: true,
                                                                                                                    }}
                                                                                                                    variant="filled"
                                                                                                                    value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                                    onChange={this.handleEditPackageStartingDate}
                                                                                                                />
                                                                                                            </form>
                                                                                                            <form className="editPackageInputField">
                                                                                                                <TextField
                                                                                                                    id="editPackageInputField"
                                                                                                                    label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                                    type="date"
                                                                                                                    InputLabelProps={{
                                                                                                                        shrink: true,
                                                                                                                    }}
                                                                                                                    variant="filled"
                                                                                                                    value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                                    onChange={this.handleEditPackageEndingDate}
                                                                                                                />
                                                                                                            </form>

                                                                                                            {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                            {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                            <div className="editPackageBtnContainer">
                                                                                                                <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    : expiredTokenBasedPackage.usage === 'Tutor Rental' ?
                                                                                                        this.state.editingCustomerPackageDetails.id !== expiredTokenBasedPackage.id ?

                                                                                                            <div className="contentBlockOfExpiredTokenOfCustomerOnListingPage">
                                                                                                                {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                                <div className="aligner"><AssignmentIndIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                                <div className="listingPackageOfCustomerName">{expiredTokenBasedPackage.package_name}</div>

                                                                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}{expiredTokenBasedPackage.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期：" : "Creation Date :"}{moment(expiredTokenBasedPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : "Day"}</div>

                                                                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}{moment(expiredTokenBasedPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "已過期：" : "Expired :"}{moment().diff(moment(expiredTokenBasedPackage.end_date), 'days')} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "適用分校：" : "Site :"}{expiredTokenBasedPackage.site ? (this.props.allSites.find((site: any) => site.id === expiredTokenBasedPackage.site)).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>

                                                                                                                <div className="editPackageBtnContainer">
                                                                                                                    <Button id="editCustomerPackageBtn" onClick={() => this.handleTriggerEditPackageClick(expiredTokenBasedPackage)}>{this.props.language !== "eng" ? "更改套劵詳情" : "Edit Package"}</Button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div className="contentBlockOfExpiredTokenOfCustomerOnListingPage">
                                                                                                                {/* <PlayCircleOutlineIcon id="listingAddIcon" onClick={() => this.handlePurchasePackageClick(activatedPackage.id, activatedPackage.price)} /> */}
                                                                                                                <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                                                <div className="listingPackageOfCustomerName">{expiredTokenBasedPackage.package_name}</div>
                                                                                                                <form className="editPackageInputField">
                                                                                                                    <TextField
                                                                                                                        id="filled-number"
                                                                                                                        label={this.props.language !== "eng" ? "有效未用票券數：" : "Active Token :"}
                                                                                                                        type="number"
                                                                                                                        InputLabelProps={{
                                                                                                                            shrink: true,
                                                                                                                        }}
                                                                                                                        variant="filled"
                                                                                                                        value={this.state.editingCustomerPackageDetails.token_amount}
                                                                                                                        onChange={this.handleEditPackageTokenAmount}
                                                                                                                    />
                                                                                                                </form>
                                                                                                                <form className="editPackageInputField">
                                                                                                                    <TextField
                                                                                                                        id="filled-number"
                                                                                                                        label={this.props.language !== "eng" ? "啟用日期：" : "Start Date :"}
                                                                                                                        type="date"
                                                                                                                        InputLabelProps={{
                                                                                                                            shrink: true,
                                                                                                                        }}
                                                                                                                        variant="filled"
                                                                                                                        value={moment(this.state.editingCustomerPackageDetails.start_date).format('YYYY-MM-DD')}
                                                                                                                        onChange={this.handleEditPackageStartingDate}
                                                                                                                    />
                                                                                                                </form>
                                                                                                                <form className="editPackageInputField">
                                                                                                                    <TextField
                                                                                                                        id="editPackageInputField"
                                                                                                                        label={this.props.language !== "eng" ? "有效期至：" : "Exp. Date :"}
                                                                                                                        type="date"
                                                                                                                        InputLabelProps={{
                                                                                                                            shrink: true,
                                                                                                                        }}
                                                                                                                        variant="filled"
                                                                                                                        value={moment(this.state.editingCustomerPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD')}
                                                                                                                        onChange={this.handleEditPackageEndingDate}
                                                                                                                    />
                                                                                                                </form>

                                                                                                                {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效未用票券數：" : "Active Token :"}{activatedPackage.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div> */}
                                                                                                                {/* <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期至：" : "Exp. Date :"}{moment(activatedPackage.end_date).format("YYYY-MM-DD")} 前</div>
                                                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng"? "有效期：" : " Validate Period :"}{moment(activatedPackage.end_date).diff(moment(activatedPackage.start_date), 'days')} {this.props.language !== "eng"? "日" : "Day"}</div> */}
                                                                                                                <div className="editPackageBtnContainer">
                                                                                                                    <Button id="editCustomerPackageBtn" onClick={() => this.handleSaveCustomerPackageDetailsManually()}>{this.props.language !== "eng" ? "儲存套劵詳情" : "Save Package"}</Button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        : null
                                                                                    )
                                                                                    : null}
                                                                    </div>
                                                                </div>
                                                                <div className="col-8 " >
                                                                    <div className="contentBlock">
                                                                        <div className="blockTitleRow">
                                                                            <h3 className="blockTitle">{this.props.language !== "eng" ? "顧客基本資料" : "Customer General Details"}</h3>
                                                                            {this.state.editCustomerBasicDetails ?
                                                                                <div className="inlineDisplay">
                                                                                    {this.state.editedCustomerNameChi === this.state.selectedCustomerInfo[0].name_chi &&
                                                                                        this.state.editedCustomerNameEng === this.state.selectedCustomerInfo[0].name_eng &&
                                                                                        this.state.editedCustomerContactNumber === this.state.selectedCustomerInfo[0].tel &&
                                                                                        this.state.editedCustomerContactEmail === this.state.selectedCustomerInfo[0].email &&
                                                                                        this.state.editedCustomerContactAddress === this.state.selectedCustomerInfo[0].address &&
                                                                                        this.state.editedCustomerDOB === this.state.selectedCustomerInfo[0].date_of_birth &&
                                                                                        this.state.editedCustomerNationality === this.state.selectedCustomerInfo[0].nationality &&
                                                                                        this.state.editedCustomerIdentification_doc_type === this.state.selectedCustomerInfo[0].identification_doc_type &&
                                                                                        this.state.editedCustomerIdentification_doc_num === this.state.selectedCustomerInfo[0].identification_doc_number &&
                                                                                        this.state.editedCustomerIdentification_doc_issue_date === this.state.selectedCustomerInfo[0].identification_doc_issue_date &&
                                                                                        this.state.editedCustomerIdentification_doc_expiry_date === this.state.selectedCustomerInfo[0].identification_doc_expiry_date &&
                                                                                        this.state.editedCustomerSex === this.state.selectedCustomerInfo[0].sex ?

                                                                                        <Button className="customerBasicDetailsEditBtn" onClick={this.handleSaveCustomerEditedDetailsClick} startIcon={<DoneIcon />} disabled>Save</Button> :
                                                                                        <Button className="customerBasicDetailsEditBtn" onClick={this.handleSaveCustomerEditedDetailsClick} startIcon={<DoneIcon />}>Save</Button>}
                                                                                    <Button onClick={this.handleEditCustomerBasicDetailsClick} startIcon={<CloseIcon />}>Decline</Button>
                                                                                </div>
                                                                                :
                                                                                <Button className="customerBasicDetailsEditBtn" onClick={this.handleEditCustomerBasicDetailsClick} startIcon={<EditIcon />}>Edit</Button>
                                                                            }
                                                                        </div>
                                                                        <div >
                                                                            <div className="customerDataContentRow">
                                                                                <FiberManualRecordIcon className="bulletBeforeData" />
                                                                                <TranslateIcon className="dataIcons" />
                                                                                <div className="customerBasicDataName">{this.props.language !== "eng" ? "中文姓名" : "Chi. Name"} ：</div>
                                                                                {this.state.editCustomerBasicDetails ?
                                                                                    this.state.editedCustomerNameChi === this.state.selectedCustomerInfo[0].name_chi ?
                                                                                        <div className="customerBasicDataContent"><TextField value={this.state.editedCustomerNameChi} onChange={this.handleNameChiEditInput} /></div> :
                                                                                        <div className="customerBasicDataContent"><TextField className="changed" value={this.state.editedCustomerNameChi} onChange={this.handleNameChiEditInput} /></div>
                                                                                    :

                                                                                    this.state.selectedCustomerInfo[0].name_chi ? <div className="customerBasicDataContent"> {this.state.selectedCustomerInfo[0].name_chi}</div> : <div className="customerBasicDataContent"><WarningIcon /></div>}
                                                                            </div>
                                                                            <div className="customerDataContentRow">
                                                                                <FiberManualRecordIcon className="bulletBeforeData" />
                                                                                <TranslateIcon className="dataIcons" />
                                                                                <div className="customerBasicDataName">{this.props.language !== "eng" ? "英文姓名" : "Eng. Name"} ：</div>
                                                                                {this.state.editCustomerBasicDetails ?
                                                                                    this.state.editedCustomerNameEng === this.state.selectedCustomerInfo[0].name_eng ?
                                                                                        <div className="customerBasicDataContent"><TextField value={this.state.editedCustomerNameEng} onChange={this.handleNameEngEditInput} /></div> :
                                                                                        <div className="customerBasicDataContent"><TextField className="changed" value={this.state.editedCustomerNameEng} onChange={this.handleNameEngEditInput} /></div>
                                                                                    :

                                                                                    this.state.selectedCustomerInfo[0].name_eng ? <div className="customerBasicDataContent"> {this.state.selectedCustomerInfo[0].name_eng}</div> : <div className="customerBasicDataContent"><WarningIcon /></div>}
                                                                            </div>
                                                                            <div className="customerDataContentRow">
                                                                                <FiberManualRecordIcon className="bulletBeforeData" />
                                                                                <WcIcon className="dataIcons" />
                                                                                <div className="customerBasicDataName">{this.props.language !== "eng" ? "顧客性別" : "SEX."} ：</div>
                                                                                {this.state.editCustomerBasicDetails ?
                                                                                    this.state.editedCustomerSex === this.state.selectedCustomerInfo[0].sex ?
                                                                                        <div className="customerBasicDataContent">
                                                                                            <FormControl >
                                                                                                <Select
                                                                                                    labelId="demo-simple-select-label"
                                                                                                    id="demo-simple-select"
                                                                                                    value={this.state.editedCustomerSex}
                                                                                                    onChange={this.handleSexEditInput}
                                                                                                >
                                                                                                    <MenuItem value={'F'}>F</MenuItem>
                                                                                                    <MenuItem value={'M'}>M</MenuItem>
                                                                                                </Select>
                                                                                            </FormControl>
                                                                                        </div> :
                                                                                        <div className="customerBasicDataContent">
                                                                                            <FormControl >
                                                                                                <Select
                                                                                                    className="changed"
                                                                                                    labelId="demo-simple-select-label"
                                                                                                    id="demo-simple-select"
                                                                                                    value={this.state.editedCustomerSex}
                                                                                                    onChange={this.handleSexEditInput}
                                                                                                >
                                                                                                    <MenuItem value={'F'}>F</MenuItem>
                                                                                                    <MenuItem value={'M'}>M</MenuItem>
                                                                                                </Select>
                                                                                            </FormControl>
                                                                                        </div>
                                                                                    :

                                                                                    this.state.selectedCustomerInfo[0].sex ? <div className="customerBasicDataContent"> {this.state.selectedCustomerInfo[0].sex}</div> : <div className="customerBasicDataContent"><WarningIcon /></div>}                                                        </div>

                                                                            <div className="customerDataContentRow">
                                                                                <FiberManualRecordIcon className="bulletBeforeData" />
                                                                                <PhonelinkRingIcon className="dataIcons" />
                                                                                <div className="customerBasicDataName">{this.props.language !== "eng" ? "聯絡電話" : "TEL."} ：</div>
                                                                                {this.state.editCustomerBasicDetails ?
                                                                                    this.state.editedCustomerContactNumber === this.state.selectedCustomerInfo[0].tel ?
                                                                                        <div className="customerBasicDataContent"><TextField value={this.state.editedCustomerContactNumber} onChange={this.handleContactNumberEditInput} /></div> :
                                                                                        <div className="customerBasicDataContent"><TextField className="changed" value={this.state.editedCustomerContactNumber} onChange={this.handleContactNumberEditInput} /></div>
                                                                                    :

                                                                                    this.state.selectedCustomerInfo[0].tel ? <div className="customerBasicDataContent"> {this.state.selectedCustomerInfo[0].tel}</div> : <div className="customerBasicDataContent"><WarningIcon /></div>}
                                                                            </div>
                                                                            <div className="customerDataContentRow">
                                                                                <FiberManualRecordIcon className="bulletBeforeData" />
                                                                                <AlternateEmailIcon className="dataIcons" />
                                                                                <div className="customerBasicDataName">{this.props.language !== "eng" ? "聯絡電郵" : "Email"} ：</div>
                                                                                {this.state.editCustomerBasicDetails ?
                                                                                    this.state.editedCustomerContactEmail === this.state.selectedCustomerInfo[0].email ?
                                                                                        <div className="customerBasicDataContent"><TextField value={this.state.editedCustomerContactEmail} onChange={this.handleEmailEditInput} /></div> :
                                                                                        <div className="customerBasicDataContent"><TextField className="changed" value={this.state.editedCustomerContactEmail} onChange={this.handleEmailEditInput} /></div>
                                                                                    :

                                                                                    this.state.selectedCustomerInfo[0].email ? <div className="customerBasicDataContent"> {this.state.selectedCustomerInfo[0].email}</div> : <div className="customerBasicDataContent"><WarningIcon /></div>}                                                        </div>
                                                                            <div className="customerDataContentRow">
                                                                                <FiberManualRecordIcon className="bulletBeforeData" />
                                                                                <RoomIcon className="dataIcons" />
                                                                                <div className="customerBasicDataName">{this.props.language !== "eng" ? "聯絡地址" : "Address"} ：</div>
                                                                                {this.state.editCustomerBasicDetails ?
                                                                                    this.state.editedCustomerContactAddress === this.state.selectedCustomerInfo[0].address ?
                                                                                        <div className="customerBasicDataContent"><TextField value={this.state.editedCustomerContactAddress} onChange={this.handleAddressEditInput} /></div> :
                                                                                        <div className="customerBasicDataContent"><TextField className="changed" value={this.state.editedCustomerContactAddress} onChange={this.handleAddressEditInput} /></div>
                                                                                    :

                                                                                    this.state.selectedCustomerInfo[0].address ? <div className="customerBasicDataContent"> {this.state.selectedCustomerInfo[0].address}</div> : <div className="customerBasicDataContent"><WarningIcon /></div>}                                                         </div>
                                                                            <div className="customerDataContentRow">
                                                                                <FiberManualRecordIcon className="bulletBeforeData" />
                                                                                <CakeIcon className="dataIcons" />
                                                                                <div className="customerBasicDataName">{this.props.language !== "eng" ? "出生日期" : "DOB "} ：</div>
                                                                                {this.state.editCustomerBasicDetails ?
                                                                                    this.state.editedCustomerDOB === this.state.selectedCustomerInfo[0].date_of_birth ?
                                                                                        <div className="customerBasicDataContent">
                                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                                <Grid container justify="space-around">
                                                                                                    <TextField
                                                                                                        id="date"
                                                                                                        type="date"
                                                                                                        defaultValue={moment(this.state.editedCustomerDOB).format('YYYY-MM-DD')}
                                                                                                        onChange={this.handleDOBEditInput}

                                                                                                    />
                                                                                                </Grid>
                                                                                            </MuiPickersUtilsProvider>
                                                                                        </div> :
                                                                                        <div className="customerBasicDataContent">
                                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                                <Grid container justify="space-around">
                                                                                                    <TextField
                                                                                                        id="date"
                                                                                                        className="changed"
                                                                                                        label="Birthday"
                                                                                                        type="date"
                                                                                                        defaultValue={moment(this.state.editedCustomerDOB).format('YYYY-MM-DD')}
                                                                                                        onChange={this.handleDOBEditInput}

                                                                                                    />
                                                                                                </Grid>
                                                                                            </MuiPickersUtilsProvider>
                                                                                        </div>
                                                                                    :

                                                                                    this.state.selectedCustomerInfo[0].date_of_birth ? <div className="customerBasicDataContent"> {moment(this.state.selectedCustomerInfo[0].date_of_birth).format('YYYY / MM / DD')}</div> : <div className="customerBasicDataContent"><WarningIcon /></div>}                                                         </div>
                                                                            <div className="customerDataContentRow">
                                                                                <FiberManualRecordIcon className="bulletBeforeData" />
                                                                                <PublicIcon className="dataIcons" />
                                                                                <div className="customerBasicDataName">{this.props.language !== "eng" ? "出生國籍" : "Nationality"} ：</div>
                                                                                {this.state.editCustomerBasicDetails ?
                                                                                    this.state.editedCustomerNationality === this.state.selectedCustomerInfo[0].nationality ?
                                                                                        <div className="customerBasicDataContent"><TextField value={this.state.editedCustomerNationality} onChange={this.handleNationalityEditInput} /></div> :
                                                                                        <div className="customerBasicDataContent"><TextField className="changed" value={this.state.editedCustomerNationality} onChange={this.handleNationalityEditInput} /></div>
                                                                                    :

                                                                                    this.state.selectedCustomerInfo[0].nationality ? <div className="customerBasicDataContent"> {this.state.selectedCustomerInfo[0].nationality}</div> : <div className="customerBasicDataContent"><WarningIcon /></div>}                                                            </div>
                                                                            <div className="customerDataContentRow">
                                                                                <FiberManualRecordIcon className="bulletBeforeData" />
                                                                                <AssignmentIndIcon className="dataIcons" />
                                                                                <div className="customerBasicDataName">{this.props.language !== "eng" ? "身份證明" : "Identification Doc."} ：</div>
                                                                                {this.state.editCustomerBasicDetails ?
                                                                                    this.state.editedCustomerIdentification_doc_type === this.state.selectedCustomerInfo[0].identification_doc_type ?
                                                                                        <div className="customerBasicDataContent">
                                                                                            <FormControl >
                                                                                                <Select
                                                                                                    labelId="demo-simple-select-label"
                                                                                                    id="demo-simple-select"
                                                                                                    value={this.state.editedCustomerIdentification_doc_type}
                                                                                                    onChange={this.handleIdentificationDocTypeEditInput}
                                                                                                >
                                                                                                    <MenuItem value={'HKID'}>HKID</MenuItem>
                                                                                                    <MenuItem value={'Passport'}>PASSPORT</MenuItem>
                                                                                                </Select>
                                                                                            </FormControl>
                                                                                        </div> :
                                                                                        <div className="customerBasicDataContent">
                                                                                            <FormControl >
                                                                                                <Select
                                                                                                    className="changed"
                                                                                                    labelId="demo-simple-select-label"
                                                                                                    id="demo-simple-select"
                                                                                                    value={this.state.editedCustomerIdentification_doc_type}
                                                                                                    onChange={this.handleIdentificationDocTypeEditInput}
                                                                                                >
                                                                                                    <MenuItem value={'HKID'}>HKID</MenuItem>
                                                                                                    <MenuItem value={'Passport'}>PASSPORT</MenuItem>
                                                                                                </Select>
                                                                                            </FormControl>
                                                                                        </div>
                                                                                    :

                                                                                    this.state.selectedCustomerInfo[0].identification_doc_type ? <div className="customerBasicDataContent"> {this.state.selectedCustomerInfo[0].identification_doc_type}</div> : <div className="customerBasicDataContent"><WarningIcon /></div>}                                                        </div>
                                                                            <div className="customerDataContentRow">
                                                                                <FiberManualRecordIcon className="bulletBeforeData" />
                                                                                <CodeIcon className="dataIcons" />
                                                                                <div className="customerBasicDataName">{this.props.language !== "eng" ? "文件編號" : "Doc. Number"} ：</div>
                                                                                {this.state.editCustomerBasicDetails ?
                                                                                    this.state.editedCustomerIdentification_doc_num === this.state.selectedCustomerInfo[0].identification_doc_number ?
                                                                                        <div className="customerBasicDataContent"><TextField value={this.state.editedCustomerIdentification_doc_num} onChange={this.handleIdentificationDocNumEditInput} /></div> :
                                                                                        <div className="customerBasicDataContent"><TextField className="changed" value={this.state.editedCustomerIdentification_doc_num} onChange={this.handleIdentificationDocNumEditInput} /></div>
                                                                                    :

                                                                                    this.state.selectedCustomerInfo[0].identification_doc_number ? <div className="customerBasicDataContent"> {this.state.selectedCustomerInfo[0].identification_doc_number}</div> : <div className="customerBasicDataContent"><WarningIcon /></div>}
                                                                            </div>
                                                                            <div className="customerDataContentRow">
                                                                                <FiberManualRecordIcon className="bulletBeforeData" />
                                                                                <EventAvailableIcon className="dataIcons" />
                                                                                <div className="customerBasicDataName">{this.props.language !== "eng" ? "發出日期" : "Issue Date"} ：</div>
                                                                                {this.state.editCustomerBasicDetails ?
                                                                                    this.state.editedCustomerIdentification_doc_issue_date === this.state.selectedCustomerInfo[0].identification_doc_issue_date ?
                                                                                        <div className="customerBasicDataContent">
                                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                                <Grid container justify="space-around">
                                                                                                    <TextField
                                                                                                        id="issueDate"
                                                                                                        type="date"
                                                                                                        defaultValue={moment(this.state.editedCustomerIdentification_doc_issue_date).format('YYYY-MM-DD')}
                                                                                                        onChange={this.handleIdentificationDocIssueDateEditInput}
                                                                                                    />
                                                                                                </Grid>
                                                                                            </MuiPickersUtilsProvider>
                                                                                        </div> :
                                                                                        <div className="customerBasicDataContent">
                                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                                <Grid container justify="space-around">
                                                                                                    <TextField
                                                                                                        id="issueDate"
                                                                                                        className="changed"
                                                                                                        type="date"
                                                                                                        defaultValue={moment(this.state.editedCustomerIdentification_doc_issue_date).format('YYYY-MM-DD')}
                                                                                                        onChange={this.handleIdentificationDocIssueDateEditInput}
                                                                                                    />
                                                                                                </Grid>
                                                                                            </MuiPickersUtilsProvider>
                                                                                        </div>
                                                                                    :

                                                                                    this.state.selectedCustomerInfo[0].identification_doc_issue_date ? <div className="customerBasicDataContent"> {moment(this.state.selectedCustomerInfo[0].identification_doc_issue_date).format('YYYY / MM / DD')}</div> : <div className="customerBasicDataContent"><WarningIcon /></div>}                                                        </div>
                                                                            <div className="customerDataContentRow">
                                                                                <FiberManualRecordIcon className="bulletBeforeData" />
                                                                                <EventBusyIcon className="dataIcons" />
                                                                                <div className="customerBasicDataName">{this.props.language !== "eng" ? "到期日期" : "Exp. Date"} ：</div>
                                                                                {this.state.editCustomerBasicDetails ?
                                                                                    this.state.editedCustomerIdentification_doc_expiry_date === this.state.selectedCustomerInfo[0].identification_doc_expiry_date ?
                                                                                        <div className="customerBasicDataContent">
                                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                                <Grid container justify="space-around">
                                                                                                    <TextField
                                                                                                        id="expiryDate"
                                                                                                        type="date"
                                                                                                        defaultValue={moment(this.state.editedCustomerIdentification_doc_expiry_date).format('YYYY-MM-DD')}
                                                                                                        onChange={this.handleIdentificationDocExpiryDateEditInput}
                                                                                                    />
                                                                                                </Grid>
                                                                                            </MuiPickersUtilsProvider>
                                                                                        </div> :
                                                                                        <div className="customerBasicDataContent">
                                                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                                <Grid container justify="space-around">
                                                                                                    <TextField
                                                                                                        id="expiryDate"
                                                                                                        className="changed"
                                                                                                        type="date"
                                                                                                        defaultValue={moment(this.state.editedCustomerIdentification_doc_expiry_date).format('YYYY-MM-DD')}
                                                                                                        onChange={this.handleIdentificationDocExpiryDateEditInput}
                                                                                                    />
                                                                                                </Grid>
                                                                                            </MuiPickersUtilsProvider>
                                                                                        </div>
                                                                                    :

                                                                                    this.state.selectedCustomerInfo[0].identification_doc_expiry_date ? <div className="customerBasicDataContent"> {moment(this.state.selectedCustomerInfo[0].identification_doc_expiry_date).format('YYYY / MM / DD')}</div> : <div className="customerBasicDataContent"><WarningIcon /></div>}
                                                                            </div>
                                                                            {/* <div className="customerDataContentRow">
                                                            <FiberManualRecordIcon className="bulletBeforeData" />
                                                            <AlternateEmailIcon />
                                                            <div className="customerBasicDataName">{this.props.language !== "eng"? "顧客備注" : "Remarks"} ：</div>
                                                            {this.state.editCustomerBasicDetails ?
                                                                this.state.editedCustomerRemarks === this.state.selectedCustomerInfo[0].remarks ?
                                                                    <div className="customerBasicDataContent"><TextField value={this.state.editedCustomerRemarks} onChange={this.handleRemarksEditInput} multiline={true} rows="2"/></div> :
                                                                    <div className="customerBasicDataContent"><TextField className="changed" value={this.state.editedCustomerRemarks} onChange={this.handleRemarksEditInput} multiline={true} rows="2"/></div>
                                                                :

                                                                this.state.selectedCustomerInfo[0].remarks ? <div className="customerBasicDataContent"> {this.state.selectedCustomerInfo[0].remarks}</div> : null}
                                                        </div> */}

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="col-4" >
                                                                    <div className="contentBlock secondRowCustomerDetailsSideCard">
                                                                        <div className="blockTitleRow">
                                                                            <h3 className="blockTitle">{this.props.language !== "eng" ? "顧客組別" : "Customer Group"}</h3>
                                                                            {
                                                                                !this.state.editCustomerGroupingID ?
                                                                                    <Button className="customerBasicDetailsEditBtn" onClick={this.handleEditCustomerGroupingIDTriggerClick} startIcon={<EditIcon />}>{this.props.language !== "eng" ? "更改" : "Edit"}</Button> :
                                                                                    (this.props.allCustomerInfo.filter((customer: any) => customer.id === this.state.selectedCustomerInfo[0].id))[0].customer_groupID === this.state.selectedCustomerInfo[0].customer_groupID ?
                                                                                        <Button className="customerBasicDetailsEditBtn" onClick={this.handleEditCustomerGroupingIDTriggerClick} startIcon={<CloseIcon />}>{this.props.language !== "eng" ? "取消" : "Cxl"}</Button> :
                                                                                        <Button className="customerBasicDetailsEditBtn" onClick={this.handleSaveCustomerGroupingIDClick} startIcon={<DoneIcon />}>{this.props.language !== "eng" ? "儲存" : "Save"}</Button>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            !this.state.editCustomerGroupingID ?
                                                                                <div className="customerGroupingIndicator">
                                                                                    <div> {(this.props.allCustomerGroupingList.filter((customerGroup: any) => customerGroup.id === this.state.selectedCustomerInfo[0].customer_groupID))[0].customer_group} </div>
                                                                                </div>
                                                                                :
                                                                                <FormControl variant="outlined" >
                                                                                    <Select
                                                                                        labelId="demo-simple-select-outlined-label"
                                                                                        id="demo-simple-select-outlined"
                                                                                        value={this.state.selectedCustomerInfo[0].customer_groupID}
                                                                                        onChange={this.handleEditCustomerGroupingID}
                                                                                    >
                                                                                        {
                                                                                            this.props.allCustomerGroupingList.map((customerGroup: any) =>
                                                                                                <MenuItem value={customerGroup.id}>{customerGroup.customer_group}</MenuItem>
                                                                                            )
                                                                                        }
                                                                                    </Select>
                                                                                </FormControl>
                                                                        }
                                                                    </div>
                                                                    <div className="contentBlock secondRowCustomerDetailsSideCard">
                                                                        <div className="blockTitleRow">
                                                                            <h3 className="blockTitle">{this.props.language !== "eng" ? "其他聯絡人" : "Other Contacts"}</h3>
                                                                            {this.state.editGuardianBasicDetails ?
                                                                                <div className="inlineDisplay">
                                                                                    {this.state.editedGuardianNameChi === this.props.relatedGuardianDetails.name_chi &&
                                                                                        this.state.editedGuardianNameEng === this.props.relatedGuardianDetails.name_eng &&
                                                                                        this.state.editedGuardianContactNumber === this.props.relatedGuardianDetails.contact_number &&
                                                                                        this.state.editedGuardianRelationship === this.props.relatedGuardianDetails.relationship ?

                                                                                        <Button className="customerBasicDetailsEditBtn" onClick={this.handleSaveGuardianEditedDetailsClick} startIcon={<DoneIcon />} disabled>{this.props.language !== "eng" ? "儲存" : "Save"}</Button> :
                                                                                        <Button className="customerBasicDetailsEditBtn" onClick={this.handleSaveGuardianEditedDetailsClick} startIcon={<DoneIcon />}>{this.props.language !== "eng" ? "儲存" : "Save"}</Button>}
                                                                                    <Button onClick={this.handleEditGuardianBasicDetailsClick} startIcon={<CloseIcon />}>{this.props.language !== "eng" ? "取消" : "Cxl"}</Button>
                                                                                </div>
                                                                                :
                                                                                this.state.addGuardianBasicDetails ?
                                                                                    <div className="inlineDisplay">
                                                                                        {this.state.editedGuardianNameChi !== "" &&
                                                                                            this.state.editedGuardianNameEng !== "" &&
                                                                                            this.state.editedGuardianContactNumber !== "" &&
                                                                                            this.state.editedGuardianRelationship !== "" ?
                                                                                            <Button className="customerBasicDetailsEditBtn" onClick={this.handleSaveGuardianEditedDetailsClick} startIcon={<DoneIcon />}>{this.props.language !== "eng" ? "儲存" : "Save"}</Button> :
                                                                                            <Button className="customerBasicDetailsEditBtn" onClick={this.handleSaveGuardianEditedDetailsClick} startIcon={<DoneIcon />} disabled>{this.props.language !== "eng" ? "儲存" : "Save"}</Button>
                                                                                        }
                                                                                        <Button onClick={this.handleEditGuardianBasicDetailsClick} startIcon={<CloseIcon />}>{this.props.language !== "eng" ? "取消" : "Cxl"}</Button>
                                                                                    </div>
                                                                                    :
                                                                                    this.props.relatedGuardianDetails === "" ?
                                                                                        <Button className="customerBasicDetailsEditBtn" onClick={this.handleCreateGuardianBasicDetailsClick} startIcon={<EditIcon />}>{this.props.language !== "eng" ? "新增" : "New"}</Button>

                                                                                        :
                                                                                        <div className="inlineDisplay">
                                                                                            <Button className="customerBasicDetailsEditBtn" onClick={this.handleEditGuardianBasicDetailsClick} startIcon={<EditIcon />}>{this.props.language !== "eng" ? "編輯" : "Edit"}</Button>
                                                                                            <Button className="customerBasicDetailsEditBtn" onClick={this.handleDelGuardianDetailsClick} startIcon={<EditIcon />}>{this.props.language !== "eng" ? "刪除" : "Delete"}</Button>
                                                                                        </div>
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                this.state.addGuardianBasicDetails ?
                                                                                    <div>
                                                                                        <div className="customerDataContentRow">
                                                                                            <TranslateIcon className="dataIcons" />
                                                                                            <div className="customerBasicDataName">{this.props.language !== "eng" ? "中文姓名" : "Chi. Name"} ：</div>
                                                                                            <div className="customerGuardianDataContent"><TextField className="changed" value={this.state.editedGuardianNameChi} onChange={this.handleNameChiEditGuardianInput} /></div>
                                                                                        </div>
                                                                                        <div className="customerDataContentRow">
                                                                                            <TranslateIcon className="dataIcons" />
                                                                                            <div className="customerBasicDataName">{this.props.language !== "eng" ? "英文姓名" : "Eng. Name"} ：</div>
                                                                                            <div className="customerGuardianDataContent"><TextField className="changed" value={this.state.editedGuardianNameEng} onChange={this.handleNameEngEditGuardianInput} /></div>
                                                                                        </div>
                                                                                        <div className="customerDataContentRow">
                                                                                            <PhonelinkRingIcon className="dataIcons" />
                                                                                            <div className="customerBasicDataName">{this.props.language !== "eng" ? "聯絡電話" : "TEL."} ：</div>
                                                                                            <div className="customerGuardianDataContent"><TextField className="changed" value={this.state.editedGuardianContactNumber} onChange={this.handleContactNumberEditGuardianInput} /></div>
                                                                                        </div>
                                                                                        <div className="customerDataContentRow">
                                                                                            <TranslateIcon className="dataIcons" />
                                                                                            <div className="customerBasicDataName">{this.props.language !== "eng" ? "聯絡關係" : "Relation"} ：</div>
                                                                                            <div className="customerGuardianDataContent"><TextField className="changed" value={this.state.editedGuardianRelationship} onChange={this.handleRelationshipEditGuardianInput} /></div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    this.props.relatedGuardianDetails === "" ?
                                                                                        <div className="noOtherContactDetails">
                                                                                            <div> - 無{this.props.language !== "eng" ? "其他相關聯絡人" : "Other Contact"} - </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div>
                                                                                            <div className="customerDataContentRow">
                                                                                                <TranslateIcon className="dataIcons" />
                                                                                                <div className="customerGuardianDataName">{this.props.language !== "eng" ? "中文姓名" : "Chi. Name"} ：</div>
                                                                                                {this.state.editGuardianBasicDetails ?
                                                                                                    this.state.editedGuardianNameChi === this.props.relatedGuardianDetails.name_chi ?
                                                                                                        <div className="customerGuardianDataContent"><TextField value={this.state.editedGuardianNameChi} onChange={this.handleNameChiEditGuardianInput} /></div> :
                                                                                                        <div className="customerGuardianDataContent"><TextField className="changed" value={this.state.editedGuardianNameChi} onChange={this.handleNameChiEditGuardianInput} /></div>
                                                                                                    :
                                                                                                    this.props.relatedGuardianDetails.name_chi ? <div className="customerGuardianDataContent"> {this.props.relatedGuardianDetails.name_chi}</div> : <div className="customerGuardianDataContent"><WarningIcon /></div>}
                                                                                            </div>
                                                                                            <div className="customerDataContentRow">
                                                                                                <TranslateIcon className="dataIcons" />
                                                                                                <div className="customerGuardianDataName">{this.props.language !== "eng" ? "英文姓名" : "Eng. Name"} ：</div>
                                                                                                {this.state.editGuardianBasicDetails ?
                                                                                                    this.state.editedGuardianNameEng === this.props.relatedGuardianDetails.name_eng ?
                                                                                                        <div className="customerGuardianDataContent"><TextField value={this.state.editedGuardianNameEng} onChange={this.handleNameEngEditGuardianInput} /></div> :
                                                                                                        <div className="customerGuardianDataContent"><TextField className="changed" value={this.state.editedGuardianNameEng} onChange={this.handleNameEngEditGuardianInput} /></div>
                                                                                                    :
                                                                                                    this.props.relatedGuardianDetails.name_eng ? <div className="customerGuardianDataContent"> {this.props.relatedGuardianDetails.name_eng}</div> : <div className="customerGuardianDataContent"><WarningIcon /></div>}
                                                                                            </div>
                                                                                            <div className="customerDataContentRow">
                                                                                                <PhonelinkRingIcon className="dataIcons" />
                                                                                                <div className="customerGuardianDataName">{this.props.language !== "eng" ? "聯絡電話" : "TEL."} ：</div>
                                                                                                {this.state.editGuardianBasicDetails ?
                                                                                                    this.state.editedGuardianContactNumber === this.props.relatedGuardianDetails.contact_number ?
                                                                                                        <div className="customerGuardianDataContent"><TextField value={this.state.editedGuardianContactNumber} onChange={this.handleContactNumberEditGuardianInput} /></div> :
                                                                                                        <div className="customerGuardianDataContent"><TextField className="changed" value={this.state.editedGuardianContactNumber} onChange={this.handleContactNumberEditGuardianInput} /></div>
                                                                                                    :

                                                                                                    this.props.relatedGuardianDetails.contact_number ? <div className="customerGuardianDataContent"> {this.props.relatedGuardianDetails.contact_number}</div> : <div className="customerGuardianDataContent"><WarningIcon /></div>}
                                                                                            </div>
                                                                                            <div className="customerDataContentRow">
                                                                                                <SupervisorAccountIcon className="dataIcons" />
                                                                                                <div className="customerGuardianDataName">{this.props.language !== "eng" ? "聯絡關係" : "Relation"} ：</div>
                                                                                                {this.state.editGuardianBasicDetails ?
                                                                                                    this.state.editedGuardianRelationship === this.props.relatedGuardianDetails.relationship ?
                                                                                                        <div className="customerGuardianDataContent"><TextField value={this.state.editedGuardianRelationship} onChange={this.handleRelationshipEditGuardianInput} /></div> :
                                                                                                        <div className="customerGuardianDataContent"><TextField className="changed" value={this.state.editedGuardianRelationship} onChange={this.handleRelationshipEditGuardianInput} /></div>
                                                                                                    :
                                                                                                    this.props.relatedGuardianDetails.relationship ? <div className="customerGuardianDataContent"> {this.props.relatedGuardianDetails.relationship}</div> : <div className="customerGuardianDataContent"><WarningIcon /></div>}
                                                                                            </div>
                                                                                        </div>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div className="contentBlock secondRowCustomerDetailsSideCard">
                                                                        <div className="blockTitleRow">
                                                                            <h3 className="blockTitle">{this.props.language !== "eng" ? "介紹資料" : "Referal Details"}</h3>
                                                                        </div>
                                                                        {
                                                                            this.state.selectedCustomerInfo[0].refer_by ?

                                                                                <div className="referralDetails">
                                                                                    {this.props.language !== "eng" ? "此顧客的介紹人" : "Referer"} : #{this.state.selectedCustomerInfo[0].refer_by.customer_id} {this.state.selectedCustomerInfo[0].refer_by.name_eng ? this.state.selectedCustomerInfo[0].refer_by.name_eng : this.state.selectedCustomerInfo[0].refer_by.name_chi}
                                                                                </div>
                                                                                :
                                                                                <div className="referralDetails">
                                                                                    {this.props.language !== "eng" ? "此顧客沒有經會員介紹加入" : "Without Referer."}
                                                                                </div>
                                                                        }
                                                                        {
                                                                            this.state.selectedCustomerInfo[0].referral_customer_list ?
                                                                                this.state.selectedCustomerInfo[0].referral_customer_list.map((referral: any, index: number) =>
                                                                                    <div className="referralDetails" key={"referralCustomer" + index}>
                                                                                        {this.props.language !== "eng" ? "已介紹顧客" : "Referee"} ({index + 1}): #{referral.customer_id} {referral.name_eng ? referral.name_eng : referral.name_chi}
                                                                                    </div>
                                                                                )
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="contentBlock">
                                                                        <div className="blockTitleRow">
                                                                            <h3 className="blockTitle">{this.props.language !== "eng" ? "顧客優惠資料" : "Customer Discount Details"}</h3>
                                                                            <div className="usagePackagesSumUp">{this.props.language !== "eng" ? `- 共 ${this.props.discountOwnedBySelectedCustomer.length} 個優惠` : `- Count ${this.props.discountOwnedBySelectedCustomer.length}`}</div>

                                                                            <Button className="customerBasicDetailsEditBtn" onClick={this.handleAddNewCustomerDiscountTriggerClick} startIcon={<EditIcon />}>{this.props.language !== "eng" ? "新增優惠" : "New Discount"}</Button>
                                                                        </div>
                                                                        {this.props.discountOwnedBySelectedCustomer.length === 0 ?
                                                                            <div className="noOtherContactDetails">
                                                                                <div> - {this.props.language !== "eng" ? "暫無優惠" : "No Applicable Discount"} - </div>
                                                                            </div>
                                                                            :
                                                                            <div className="disCountListingAreaBGOnAddDiscountToCustomerPage">
                                                                                {this.props.discountOwnedBySelectedCustomer.map((discount: any) =>
                                                                                    <div className="discountListingRowOnCustomerDetailsPage" >
                                                                                        <div className="discountName"> {discount.discount_name} </div>
                                                                                        {discount.expiry_date ?
                                                                                            <div className="discountName"> Expire: {moment(discount.expiry_date).format('YYYY-MM-DD')} </div>
                                                                                            :
                                                                                            <div className="discountName"> {this.props.language !== "eng" ? "無特定過期日" : "No Exp. Date"} </div>}
                                                                                        {discount.cash_discount ?
                                                                                            <div className="discountName"> $ {discount.cash_discount} </div>
                                                                                            :
                                                                                            <div className="discountName"> {discount.percentage_discount} %</div>
                                                                                        }
                                                                                        <DeleteForeverIcon onClick={() => this.handleDelCustomerDiscountClick(discount.id)} />
                                                                                    </div>
                                                                                )}

                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="contentBlock">
                                                                        <div className="blockTitleRow">
                                                                            <h3 className="blockTitle">{this.props.language !== "eng" ? "顧客資料其他操作" : "Other Details Configurations"}</h3>
                                                                        </div>
                                                                        {
                                                                            !this.state.selectedCustomerInfo[0].refer_by ?
                                                                                <Button id="otherCustomerDataOperationBtn" onClick={this.handleShowaMarkReferralDetailsBox}>{this.props.language !== "eng" ? "新增介紹此顧客的人" : "Mark Referer of This Customer"}</Button>
                                                                                :
                                                                                <Button id="otherCustomerDataOperationBtn" onClick={this.handleShowaMarkReferralDetailsBox} disabled>{this.props.language !== "eng" ? "新增介紹此顧客的人" : "Mark Referer of This Customer"}</Button>

                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        :
                                                        this.state.manageCustomerPageNav === 'customerBuyToken' ?
                                                            //PURCHASE PACKAGES LISTING PAGE
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="contentFixSizePackagesBlock">
                                                                        <div className="blockTitleRow">
                                                                            {/* <h3 className="blockTitle">顧客選購</h3> */}

                                                                            <div className="subPageBtnRow">
                                                                                {this.state.manageCustomerPage_PurchasePackagesSubNav === 'Class Participation' ?
                                                                                    <Button className="onSubPageBtn">{this.props.language !== "eng" ? "一般課堂套券" : "Normal Class Package"}</Button>
                                                                                    :
                                                                                    <Button className="customerManage_PurchasePackagesSubNav" onClick={() => this.handleCustomer_PurchasePackagesPageNavChange('Class Participation')}>{this.props.language !== "eng" ? "一般課堂套券" : "Normal Class Package"}</Button>
                                                                                }
                                                                                {this.state.manageCustomerPage_PurchasePackagesSubNav === 'Private Class Booking' ?
                                                                                    <Button className="onSubPageBtn">{this.props.language !== "eng" ? "私人課堂套券" : "Private Class Package"}</Button>
                                                                                    :
                                                                                    <Button className="customerManage_PurchasePackagesSubNav" onClick={() => this.handleCustomer_PurchasePackagesPageNavChange('Private Class Booking')}>{this.props.language !== "eng" ? "私人課堂套券" : "Private Class Package"}</Button>
                                                                                }
                                                                                {this.state.manageCustomerPage_PurchasePackagesSubNav === 'Classroom Rental' ?
                                                                                    <Button className="onSubPageBtn">{this.props.language !== "eng" ? "租用場地套券" : "Room Rental Package"}</Button>
                                                                                    :
                                                                                    <Button className="customerManage_PurchasePackagesSubNav" onClick={() => this.handleCustomer_PurchasePackagesPageNavChange('Classroom Rental')}>{this.props.language !== "eng" ? "租用場地套券" : "Room Rental Package"}</Button>
                                                                                }
                                                                                {this.state.manageCustomerPage_PurchasePackagesSubNav === 'Tutor Rental' ?
                                                                                    <Button className="onSubPageBtn">{this.props.language !== "eng" ? "租用導師套券" : "Tutor Hiring Package"}</Button>
                                                                                    :
                                                                                    <Button className="customerManage_PurchasePackagesSubNav" onClick={() => this.handleCustomer_PurchasePackagesPageNavChange('Tutor Rental')}>{this.props.language !== "eng" ? "租用導師套券" : "Tutor Hiring Package"}</Button>
                                                                                }

                                                                            </div>
                                                                            <div className="purchaseBucketArea">
                                                                                <div className="aligner w-100 h-100">
                                                                                    <button className="bucketPresentationElements itemsInBucketDisplay " onClick={() => this.handleShowBucketPreview()}>

                                                                                        <div className="aligner">
                                                                                            {purchaseBucketNum}
                                                                                            <ShoppingBasketIcon id="shoppingCartIcon" />
                                                                                            <div id="BucketSumDisplay">Total:$ {this.state.totalPrice.toFixed(2)}</div>
                                                                                        </div>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div className="searchTokenInputContainer">
                                                                                <TextField label={this.props.language !== "eng" ? "搜尋套劵" : "Search Package"} variant="outlined" value={this.state.searchTokenWordings} onChange={this.handleSearchTokenWordingInput} />
                                                                            </div>
                                                                        </div>

                                                                        <div id="contentFixSizeTableHeight" className="row">

                                                                            {this.props.packagesWithRightUsage.length === 0 ?
                                                                                null :
                                                                                this.state.manageCustomerPage_PurchasePackagesSubNav === 'Class Participation' ?
                                                                                    this.props.loading ?
                                                                                        <div className="loadingContainerRow">
                                                                                            <div className="inlineDisplay">
                                                                                                <div className="loadingContainer">
                                                                                                    <CircularProgress id="loadingCircle" />
                                                                                                    <div className="loadingLabel">Loading ...</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        searchTokenResultRenderArr.length !== 0 ?
                                                                                            searchTokenResultRenderArr.map((packagesOnList: any) =>
                                                                                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                                                                    <div className="contentBlockOfClassTokenOnListingPage" onClick={() => this.handlePurchasePackageClick(packagesOnList.id, packagesOnList.price)}>
                                                                                                        <AddIcon id="listingAddIcon" />
                                                                                                        <div className="aligner"><PeopleAltIcon id="listingPackageIcon" /></div>
                                                                                                        <div className="listingPackagePrice">HKD$ {packagesOnList.price}</div>
                                                                                                        <div className="listingPackageNameContainer">
                                                                                                            <div className="listingPackageName">{packagesOnList.package_name}</div>
                                                                                                        </div>
                                                                                                        <div className="listingPackageContentRow">
                                                                                                            <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "年齢" : "Age"}：</div>
                                                                                                            {
                                                                                                                packagesOnList.age_group_id ?
                                                                                                                    <div className="listingPackageContentDetails">
                                                                                                                        <div className="packageListingContentAligner">
                                                                                                                            <div>{(this.props.ageGroupList.find((ageGroup: any) => ageGroup.id === packagesOnList.age_group_id)).age_group}</div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <div className="listingPackageContentDetails">
                                                                                                                        <div className="packageListingContentAligner">
                                                                                                                            <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                            }
                                                                                                        </div>

                                                                                                        {/* <div className="listingPackageContent">{packagesOnList.age_group_id ? <div>{this.props.language !== "eng"? "年齢" : "Age"}：{(this.props.ageGroupList.find((ageGroup: any) => ageGroup.id === packagesOnList.age_group_id)).age_group}</div> : <div>{this.props.language !== "eng"? "年齢" : "Age"}{this.props.language !== "eng"? "：不限" : ": Unlimited"}</div>}</div> */}
                                                                                                        {packagesOnList.token_based_package ?
                                                                                                            // <div className="listingPackageContent">{this.props.language !== "eng"? "有效：" : "Valid"}{packagesOnList.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div>
                                                                                                            <div className="listingPackageContentRow">
                                                                                                                <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效：" : "Valid"}</div>
                                                                                                                <div className="listingPackageContentDetails">
                                                                                                                    <div className="packageListingContentAligner">
                                                                                                                        <div>{packagesOnList.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                            :
                                                                                                            <div className="listingPackageContentRow">
                                                                                                                <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效：" : "Valid"}</div>
                                                                                                                <div className="listingPackageContentDetails">
                                                                                                                    <div className="packageListingContentAligner">
                                                                                                                        <div>{packagesOnList.validate_period} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </div>}
                                                                                                        {/* <div className="listingPackageContent">{packagesOnList.site_id ? <div>{this.props.language !== "eng"? "校址：" : "Site :"}{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div> : <div>{this.props.language !== "eng"? "校址：不限" : "Site : Unlimited"}</div>}</div> */
                                                                                                            <div className="listingPackageContentRow">
                                                                                                                <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "校址：" : "Site :"}</div>
                                                                                                                <div className="listingPackageContentDetails">
                                                                                                                    <div className="packageListingContentAligner">
                                                                                                                        {
                                                                                                                            packagesOnList.site_id ?
                                                                                                                                <div>{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div>
                                                                                                                                :
                                                                                                                                <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {/* <div className="listingPackageContent">{packagesOnList.genre_id ? <div>{this.props.language !== "eng"? "種類：" : "Sport"}{packagesOnList.genre_id.map((id: number) => this.props.genreTypeList.find(((genre: any) => genre.id === id)).genre + ' ')}</div> : <div>種類：不限</div>}</div> */}
                                                                                                        <div className="listingPackageContentRow">
                                                                                                            <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "種類：" : "Sport:"}</div>
                                                                                                            <div className="listingPackageContentGenreDetails">
                                                                                                                {
                                                                                                                    packagesOnList.genre_id ?
                                                                                                                        packagesOnList.genre_id.map((id: number) =>
                                                                                                                            <div className="packageListingContentAligner">
                                                                                                                                <div>{this.props.genreTypeList.find(((genre: any) => genre.id === id)).genre}</div>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                        :

                                                                                                                        <div className="aligner">
                                                                                                                            <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                                                        </div>

                                                                                                                }

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                            :
                                                                                            this.props.packagesWithRightUsage.map((packagesOnList: any) =>
                                                                                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                                                                    <div className="contentBlockOfClassTokenOnListingPage" onClick={() => this.handlePurchasePackageClick(packagesOnList.id, packagesOnList.price)}>
                                                                                                        <AddIcon id="listingAddIcon" />
                                                                                                        <div className="aligner"><PeopleAltIcon id="listingPackageIcon" /></div>
                                                                                                        <div className="listingPackagePrice">HKD$ {packagesOnList.price}</div>
                                                                                                        <div className="listingPackageNameContainer">
                                                                                                            <div className="listingPackageName">{packagesOnList.package_name}</div>
                                                                                                        </div>
                                                                                                        <div className="listingPackageContentRow">
                                                                                                            <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "年齢" : "Age"}：</div>
                                                                                                            {
                                                                                                                packagesOnList.age_group_id ?
                                                                                                                    <div className="listingPackageContentDetails">
                                                                                                                        <div className="packageListingContentAligner">
                                                                                                                            <div>{(this.props.ageGroupList.find((ageGroup: any) => ageGroup.id === packagesOnList.age_group_id)).age_group}</div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <div className="listingPackageContentDetails">
                                                                                                                        <div className="packageListingContentAligner">
                                                                                                                            <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                            }
                                                                                                        </div>

                                                                                                        {/* <div className="listingPackageContent">{packagesOnList.age_group_id ? <div>{this.props.language !== "eng"? "年齢" : "Age"}：{(this.props.ageGroupList.find((ageGroup: any) => ageGroup.id === packagesOnList.age_group_id)).age_group}</div> : <div>{this.props.language !== "eng"? "年齢" : "Age"}{this.props.language !== "eng"? "：不限" : ": Unlimited"}</div>}</div> */}
                                                                                                        {packagesOnList.token_based_package ?
                                                                                                            // <div className="listingPackageContent">{this.props.language !== "eng"? "有效：" : "Valid"}{packagesOnList.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div>
                                                                                                            <div className="listingPackageContentRow">
                                                                                                                <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效：" : "Valid"}</div>
                                                                                                                <div className="listingPackageContentDetails">
                                                                                                                    <div className="packageListingContentAligner">
                                                                                                                        <div>{packagesOnList.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                            :
                                                                                                            <div className="listingPackageContentRow">
                                                                                                                <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效：" : "Valid"}</div>
                                                                                                                <div className="listingPackageContentDetails">
                                                                                                                    <div className="packageListingContentAligner">
                                                                                                                        <div>{packagesOnList.validate_period} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </div>}
                                                                                                        {/* <div className="listingPackageContent">{packagesOnList.site_id ? <div>{this.props.language !== "eng"? "校址：" : "Site :"}{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div> : <div>{this.props.language !== "eng"? "校址：不限" : "Site : Unlimited"}</div>}</div> */
                                                                                                            <div className="listingPackageContentRow">
                                                                                                                <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "校址：" : "Site :"}</div>
                                                                                                                <div className="listingPackageContentDetails">
                                                                                                                    <div className="packageListingContentAligner">
                                                                                                                        {
                                                                                                                            packagesOnList.site_id ?
                                                                                                                                <div>{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div>
                                                                                                                                :
                                                                                                                                <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {/* <div className="listingPackageContent">{packagesOnList.genre_id ? <div>{this.props.language !== "eng"? "種類：" : "Sport"}{packagesOnList.genre_id.map((id: number) => this.props.genreTypeList.find(((genre: any) => genre.id === id)).genre + ' ')}</div> : <div>種類：不限</div>}</div> */}
                                                                                                        <div className="listingPackageContentRow">
                                                                                                            <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "種類：" : "Sport:"}</div>
                                                                                                            <div className="listingPackageContentGenreDetails">
                                                                                                                {
                                                                                                                    packagesOnList.genre_id ?
                                                                                                                        packagesOnList.genre_id.map((id: number) =>
                                                                                                                            <div className="packageListingContentAligner">
                                                                                                                                <div>{this.props.genreTypeList.find(((genre: any) => genre.id === id)).genre}</div>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                        :

                                                                                                                        <div className="aligner">
                                                                                                                            <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                                                        </div>

                                                                                                                }

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                    :
                                                                                    this.state.manageCustomerPage_PurchasePackagesSubNav === 'Private Class Booking' ?
                                                                                        this.props.loading ?
                                                                                            <div className="loadingContainerRow">
                                                                                                <div className="inlineDisplay">
                                                                                                    <div className="loadingContainer">
                                                                                                        <CircularProgress id="loadingCircle" />
                                                                                                        <div className="loadingLabel">Loading ...</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            searchTokenResultRenderArr.length !== 0 ?
                                                                                                searchTokenResultRenderArr.map((packagesOnList: any) =>
                                                                                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                                                                        <div className="contentBlockOfPrivateClassTokenOnListingPage" onClick={() => this.handlePurchasePackageClick(packagesOnList.id, packagesOnList.price)}>
                                                                                                            <AddIcon id="listingAddIcon" />
                                                                                                            <div className="aligner"><PersonIcon id="listingPackageIcon" /></div>
                                                                                                            <div className="listingPackagePrice">HKD$ {packagesOnList.price}</div>
                                                                                                            <div className="listingPackageNameContainer">
                                                                                                                <div className="listingPackageName">{packagesOnList.package_name}</div>
                                                                                                            </div>
                                                                                                            <div className="listingPackageContentRow">
                                                                                                                <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "年齢" : "Age"}：</div>
                                                                                                                {
                                                                                                                    packagesOnList.age_group_id ?
                                                                                                                        <div className="listingPackageContentDetails">
                                                                                                                            <div className="packageListingContentAligner">
                                                                                                                                <div>{(this.props.ageGroupList.find((ageGroup: any) => ageGroup.id === packagesOnList.age_group_id)).age_group}</div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        <div className="listingPackageContentDetails">
                                                                                                                            <div className="packageListingContentAligner">
                                                                                                                                <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                }
                                                                                                            </div>

                                                                                                            {/* <div className="listingPackageContent">{packagesOnList.age_group_id ? <div>{this.props.language !== "eng"? "年齢" : "Age"}：{(this.props.ageGroupList.find((ageGroup: any) => ageGroup.id === packagesOnList.age_group_id)).age_group}</div> : <div>{this.props.language !== "eng"? "年齢" : "Age"}{this.props.language !== "eng"? "：不限" : ": Unlimited"}</div>}</div> */}
                                                                                                            {packagesOnList.token_amount ?
                                                                                                                // <div className="listingPackageContent">{this.props.language !== "eng"? "有效：" : "Valid"}{packagesOnList.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div>
                                                                                                                <div className="listingPackageContentRow">
                                                                                                                    <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效：" : "Valid"}</div>
                                                                                                                    <div className="listingPackageContentDetails">
                                                                                                                        <div className="packageListingContentAligner">
                                                                                                                            <div>{packagesOnList.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                                :
                                                                                                                <div className="listingPackageContentRow">
                                                                                                                    <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效：" : "Valid"}</div>
                                                                                                                    <div className="listingPackageContentDetails">
                                                                                                                        <div className="packageListingContentAligner">
                                                                                                                            <div>{packagesOnList.validate_period}{this.props.language !== "eng" ? "日" : " Days"}</div>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </div>}
                                                                                                            {
                                                                                                                // <div className="listingPackageContent">{this.props.language !== "eng"? "有效：" : "Valid"}{packagesOnList.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div>
                                                                                                                <div className="listingPackageContentRow">
                                                                                                                    <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "時長：" : "Duration :"}</div>
                                                                                                                    <div className="listingPackageContentDetails">
                                                                                                                        <div className="packageListingContentAligner">
                                                                                                                            <div>{packagesOnList.effective_time ? packagesOnList.effective_time : "-"} {this.props.language !== "eng" ? "分鐘" : "Min"}</div>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            }
                                                                                                            {/* <div className="listingPackageContent">{packagesOnList.site_id ? <div>{this.props.language !== "eng"? "校址：" : "Site :"}{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div> : <div>{this.props.language !== "eng"? "校址：不限" : "Site : Unlimited"}</div>}</div> */
                                                                                                                <div className="listingPackageContentRow">
                                                                                                                    <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "校址：" : "Site :"}</div>
                                                                                                                    <div className="listingPackageContentDetails">
                                                                                                                        <div className="packageListingContentAligner">
                                                                                                                            {
                                                                                                                                packagesOnList.site_id ?
                                                                                                                                    <div>{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div>
                                                                                                                                    :
                                                                                                                                    <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                            {/* <div className="listingPackageContent">{packagesOnList.genre_id ? <div>{this.props.language !== "eng"? "種類：" : "Sport"}{packagesOnList.genre_id.map((id: number) => this.props.genreTypeList.find(((genre: any) => genre.id === id)).genre + ' ')}</div> : <div>種類：不限</div>}</div> */}
                                                                                                            <div className="listingPackageContentRow">
                                                                                                                <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "種類：" : "Sport:"}</div>
                                                                                                                <div className="listingPackageContentGenreDetails">
                                                                                                                    {
                                                                                                                        packagesOnList.genre_id ?
                                                                                                                            packagesOnList.genre_id.map((id: number) =>
                                                                                                                                <div className="packageListingContentAligner">
                                                                                                                                    <div>{this.props.genreTypeList.find(((genre: any) => genre.id === id)).genre}</div>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                            :

                                                                                                                            <div className="aligner">
                                                                                                                                <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                                                            </div>

                                                                                                                    }

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                                :
                                                                                                this.props.packagesWithRightUsage.map((packagesOnList: any) =>
                                                                                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                                                                        <div className="contentBlockOfPrivateClassTokenOnListingPage" onClick={() => this.handlePurchasePackageClick(packagesOnList.id, packagesOnList.price)}>
                                                                                                            <AddIcon id="listingAddIcon" />
                                                                                                            <div className="aligner"><PersonIcon id="listingPackageIcon" /></div>
                                                                                                            <div className="listingPackagePrice">HKD$ {packagesOnList.price}</div>
                                                                                                            <div className="listingPackageNameContainer">
                                                                                                                <div className="listingPackageName">{packagesOnList.package_name}</div>
                                                                                                            </div>
                                                                                                            <div className="listingPackageContentRow">
                                                                                                                <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "年齢" : "Age"}：</div>
                                                                                                                {
                                                                                                                    packagesOnList.age_group_id ?
                                                                                                                        <div className="listingPackageContentDetails">
                                                                                                                            <div className="packageListingContentAligner">
                                                                                                                                <div>{(this.props.ageGroupList.find((ageGroup: any) => ageGroup.id === packagesOnList.age_group_id)).age_group}</div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        <div className="listingPackageContentDetails">
                                                                                                                            <div className="packageListingContentAligner">
                                                                                                                                <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                }
                                                                                                            </div>

                                                                                                            {/* <div className="listingPackageContent">{packagesOnList.age_group_id ? <div>{this.props.language !== "eng"? "年齢" : "Age"}：{(this.props.ageGroupList.find((ageGroup: any) => ageGroup.id === packagesOnList.age_group_id)).age_group}</div> : <div>{this.props.language !== "eng"? "年齢" : "Age"}{this.props.language !== "eng"? "：不限" : ": Unlimited"}</div>}</div> */}
                                                                                                            {packagesOnList.token_amount ?
                                                                                                                // <div className="listingPackageContent">{this.props.language !== "eng"? "有效：" : "Valid"}{packagesOnList.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div>
                                                                                                                <div className="listingPackageContentRow">
                                                                                                                    <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效：" : "Valid"}</div>
                                                                                                                    <div className="listingPackageContentDetails">
                                                                                                                        <div className="packageListingContentAligner">
                                                                                                                            <div>{packagesOnList.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                                :
                                                                                                                <div className="listingPackageContentRow">
                                                                                                                    <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效：" : "Valid"}</div>
                                                                                                                    <div className="listingPackageContentDetails">
                                                                                                                        <div className="packageListingContentAligner">
                                                                                                                            <div>{packagesOnList.validate_period} 曰</div>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </div>}
                                                                                                            {
                                                                                                                // <div className="listingPackageContent">{this.props.language !== "eng"? "有效：" : "Valid"}{packagesOnList.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div>
                                                                                                                <div className="listingPackageContentRow">
                                                                                                                    <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "時長：" : "Duration :"}</div>
                                                                                                                    <div className="listingPackageContentDetails">
                                                                                                                        <div className="packageListingContentAligner">
                                                                                                                            <div>{packagesOnList.effective_time ? packagesOnList.effective_time : "-"} {this.props.language !== "eng" ? "分鐘" : "Min"}</div>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            }
                                                                                                            {/* <div className="listingPackageContent">{packagesOnList.site_id ? <div>{this.props.language !== "eng"? "校址：" : "Site :"}{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div> : <div>{this.props.language !== "eng"? "校址：不限" : "Site : Unlimited"}</div>}</div> */
                                                                                                                <div className="listingPackageContentRow">
                                                                                                                    <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "校址：" : "Site :"}</div>
                                                                                                                    <div className="listingPackageContentDetails">
                                                                                                                        <div className="packageListingContentAligner">
                                                                                                                            {
                                                                                                                                packagesOnList.site_id ?
                                                                                                                                    <div>{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div>
                                                                                                                                    :
                                                                                                                                    <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                            {/* <div className="listingPackageContent">{packagesOnList.genre_id ? <div>{this.props.language !== "eng"? "種類：" : "Sport"}{packagesOnList.genre_id.map((id: number) => this.props.genreTypeList.find(((genre: any) => genre.id === id)).genre + ' ')}</div> : <div>種類：不限</div>}</div> */}
                                                                                                            <div className="listingPackageContentRow">
                                                                                                                <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "種類：" : "Sport:"}</div>
                                                                                                                <div className="listingPackageContentGenreDetails">
                                                                                                                    {
                                                                                                                        packagesOnList.genre_id ?
                                                                                                                            packagesOnList.genre_id.map((id: number) =>
                                                                                                                                <div className="packageListingContentAligner">
                                                                                                                                    <div>{this.props.genreTypeList.find(((genre: any) => genre.id === id)).genre}</div>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                            :

                                                                                                                            <div className="aligner">
                                                                                                                                <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                                                            </div>

                                                                                                                    }

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                        :
                                                                                        this.state.manageCustomerPage_PurchasePackagesSubNav === 'Classroom Rental' ?
                                                                                            this.props.loading ?
                                                                                                <div className="loadingContainerRow">
                                                                                                    <div className="inlineDisplay">
                                                                                                        <div className="loadingContainer">
                                                                                                            <CircularProgress id="loadingCircle" />
                                                                                                            <div className="loadingLabel">Loading ...</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                searchTokenResultRenderArr.length !== 0 ?
                                                                                                    searchTokenResultRenderArr.map((packagesOnList: any) =>
                                                                                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                                                                            <div className="contentBlockOfRoomRentalTokenOnListingPage" onClick={() => this.handlePurchasePackageClick(packagesOnList.id, packagesOnList.price)}>
                                                                                                                <AddIcon id="listingAddIcon" />
                                                                                                                <div className="aligner"><RoomIcon id="listingPackageIcon" /></div>
                                                                                                                <div className="listingPackagePrice">HKD$ {packagesOnList.price}</div>
                                                                                                                <div className="listingPackageNameContainer">
                                                                                                                    <div className="listingPackageName">{packagesOnList.package_name}</div>
                                                                                                                </div>
                                                                                                                {packagesOnList.token_amount ?
                                                                                                                    // <div className="listingPackageContent">{this.props.language !== "eng"? "有效：" : "Valid"}{packagesOnList.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div>
                                                                                                                    <div className="listingPackageContentRow">
                                                                                                                        <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效：" : "Valid"}</div>
                                                                                                                        <div className="listingPackageContentDetails">
                                                                                                                            <div className="packageListingContentAligner">
                                                                                                                                <div>{packagesOnList.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <div className="listingPackageContentRow">
                                                                                                                        <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效：" : "Valid"}</div>
                                                                                                                        <div className="listingPackageContentDetails">
                                                                                                                            <div className="packageListingContentAligner">
                                                                                                                                <div>{packagesOnList.validate_period} 曰</div>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>}
                                                                                                                {
                                                                                                                    // <div className="listingPackageContent">{this.props.language !== "eng"? "有效：" : "Valid"}{packagesOnList.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div>
                                                                                                                    <div className="listingPackageContentRow">
                                                                                                                        <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "時長：" : "Duration :"}</div>
                                                                                                                        <div className="listingPackageContentDetails">
                                                                                                                            <div className="packageListingContentAligner">
                                                                                                                                <div>{packagesOnList.effective_time ? packagesOnList.effective_time : "-"} {this.props.language !== "eng" ? "分鐘" : "Min"}</div>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                }
                                                                                                                {/* <div className="listingPackageContent">{packagesOnList.site_id ? <div>{this.props.language !== "eng"? "校址：" : "Site :"}{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div> : <div>{this.props.language !== "eng"? "校址：不限" : "Site : Unlimited"}</div>}</div> */
                                                                                                                    <div className="listingPackageContentRow">
                                                                                                                        <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "校址：" : "Site :"}</div>
                                                                                                                        <div className="listingPackageContentDetails">
                                                                                                                            <div className="packageListingContentAligner">
                                                                                                                                {
                                                                                                                                    packagesOnList.site_id ?
                                                                                                                                        <div>{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div>
                                                                                                                                        :
                                                                                                                                        <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )

                                                                                                    :
                                                                                                    this.props.packagesWithRightUsage.map((packagesOnList: any) =>
                                                                                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                                                                            <div className="contentBlockOfRoomRentalTokenOnListingPage" onClick={() => this.handlePurchasePackageClick(packagesOnList.id, packagesOnList.price)}>
                                                                                                                <AddIcon id="listingAddIcon" />
                                                                                                                <div className="aligner"><RoomIcon id="listingPackageIcon" /></div>
                                                                                                                <div className="listingPackagePrice">HKD$ {packagesOnList.price}</div>
                                                                                                                <div className="listingPackageNameContainer">
                                                                                                                    <div className="listingPackageName">{packagesOnList.package_name}</div>
                                                                                                                </div>
                                                                                                                {packagesOnList.token_amount ?
                                                                                                                    // <div className="listingPackageContent">{this.props.language !== "eng"? "有效：" : "Valid"}{packagesOnList.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div>
                                                                                                                    <div className="listingPackageContentRow">
                                                                                                                        <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效：" : "Valid"}</div>
                                                                                                                        <div className="listingPackageContentDetails">
                                                                                                                            <div className="packageListingContentAligner">
                                                                                                                                <div>{packagesOnList.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <div className="listingPackageContentRow">
                                                                                                                        <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效：" : "Valid"}</div>
                                                                                                                        <div className="listingPackageContentDetails">
                                                                                                                            <div className="packageListingContentAligner">
                                                                                                                                <div>{packagesOnList.validate_period} 曰</div>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>}
                                                                                                                {
                                                                                                                    // <div className="listingPackageContent">{this.props.language !== "eng"? "有效：" : "Valid"}{packagesOnList.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div>
                                                                                                                    <div className="listingPackageContentRow">
                                                                                                                        <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "時長：" : "Duration :"}</div>
                                                                                                                        <div className="listingPackageContentDetails">
                                                                                                                            <div className="packageListingContentAligner">
                                                                                                                                <div>{packagesOnList.effective_time ? packagesOnList.effective_time : "-"} {this.props.language !== "eng" ? "分鐘" : "Min"}</div>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                }
                                                                                                                {/* <div className="listingPackageContent">{packagesOnList.site_id ? <div>{this.props.language !== "eng"? "校址：" : "Site :"}{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div> : <div>{this.props.language !== "eng"? "校址：不限" : "Site : Unlimited"}</div>}</div> */
                                                                                                                    <div className="listingPackageContentRow">
                                                                                                                        <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "校址：" : "Site :"}</div>
                                                                                                                        <div className="listingPackageContentDetails">
                                                                                                                            <div className="packageListingContentAligner">
                                                                                                                                {
                                                                                                                                    packagesOnList.site_id ?
                                                                                                                                        <div>{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div>
                                                                                                                                        :
                                                                                                                                        <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                            :
                                                                                            this.state.manageCustomerPage_PurchasePackagesSubNav === 'Tutor Rental' ?
                                                                                                this.props.loading ?
                                                                                                    <div className="loadingContainerRow">
                                                                                                        <div className="inlineDisplay">
                                                                                                            <div className="loadingContainer">
                                                                                                                <CircularProgress id="loadingCircle" />
                                                                                                                <div className="loadingLabel">Loading ...</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    searchTokenResultRenderArr.length !== 0 ?
                                                                                                        searchTokenResultRenderArr.map((packagesOnList: any) =>
                                                                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                                                                                <div className="contentBlockOfTutorRentalTokenOnListingPage" onClick={() => this.handlePurchasePackageClick(packagesOnList.id, packagesOnList.price)}>
                                                                                                                    <AddIcon id="listingAddIcon" />
                                                                                                                    <div className="aligner"><AssignmentIndIcon id="listingPackageIcon" /></div>
                                                                                                                    <div className="listingPackagePrice">HKD$ {packagesOnList.price}</div>
                                                                                                                    <div className="listingPackageNameContainer">
                                                                                                                        <div className="listingPackageName">{packagesOnList.package_name}</div>
                                                                                                                    </div>
                                                                                                                    {packagesOnList.token_amount ?
                                                                                                                        // <div className="listingPackageContent">{this.props.language !== "eng"? "有效：" : "Valid"}{packagesOnList.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div>
                                                                                                                        <div className="listingPackageContentRow">
                                                                                                                            <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效：" : "Valid"}</div>
                                                                                                                            <div className="listingPackageContentDetails">
                                                                                                                                <div className="packageListingContentAligner">
                                                                                                                                    <div>{packagesOnList.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        <div className="listingPackageContentRow">
                                                                                                                            <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效：" : "Valid"}</div>
                                                                                                                            <div className="listingPackageContentDetails">
                                                                                                                                <div className="packageListingContentAligner">
                                                                                                                                    <div>{packagesOnList.validate_period} 曰</div>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </div>}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                        :
                                                                                                        this.props.packagesWithRightUsage.map((packagesOnList: any) =>
                                                                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                                                                                <div className="contentBlockOfTutorRentalTokenOnListingPage" onClick={() => this.handlePurchasePackageClick(packagesOnList.id, packagesOnList.price)}>
                                                                                                                    <AddIcon id="listingAddIcon" />
                                                                                                                    <div className="aligner"><AssignmentIndIcon id="listingPackageIcon" /></div>
                                                                                                                    <div className="listingPackagePrice">HKD$ {packagesOnList.price}</div>
                                                                                                                    <div className="listingPackageNameContainer">
                                                                                                                        <div className="listingPackageName">{packagesOnList.package_name}</div>
                                                                                                                    </div>
                                                                                                                    {packagesOnList.token_amount ?
                                                                                                                        // <div className="listingPackageContent">{this.props.language !== "eng"? "有效：" : "Valid"}{packagesOnList.token_amount} {this.props.language !== "eng"? "張" : "Token"}</div>
                                                                                                                        <div className="listingPackageContentRow">
                                                                                                                            <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效：" : "Valid"}</div>
                                                                                                                            <div className="listingPackageContentDetails">
                                                                                                                                <div className="packageListingContentAligner">
                                                                                                                                    <div>{packagesOnList.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        <div className="listingPackageContentRow">
                                                                                                                            <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效：" : "Valid"}</div>
                                                                                                                            <div className="listingPackageContentDetails">
                                                                                                                                <div className="packageListingContentAligner">
                                                                                                                                    <div>{packagesOnList.validate_period} {this.props.language !== "eng" ? "日" : "Days"}</div>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </div>}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                : null
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : this.state.manageCustomerPageNav === 'checkCustomerPurchasePaymentHistory' ?
                                                                <CustomerPurchasePaymentHistory customerUserID={this.state.selectedCustomerId} triggerDisplaySpecificPurchaseDetailsBox={(purchaseID: number) => this.handleTriggerDisplaySpecificPurchaseDetailsBoxFromPaymentHistory(purchaseID)} language={this.props.language} />
                                                                : this.state.manageCustomerPageNav === 'checkCustomerParticipationHistory' ?
                                                                    <CustomerPackageUsageHistory selectedCustomerID={(displayCustomers.filter((customer: any) => customer.user_id === this.state.selectedCustomerId))[0].id} />
                                                                    : this.state.manageCustomerPageNav === 'customerProductListingPage' ?
                                                                        <ProductListingModule purchaseProductClick={(productSKU: string, productPrice: string) => this.handlePurchaseProductClick(productSKU, productPrice)} purchaseBucketNum={purchaseBucketNum} totalPrice={this.state.totalPrice} redirectToBucketPreview={this.handleShowBucketPreview} />
                                                                        : null
                                                    }
                                                </div>
                                            </div>

                                            :
                                            //CUSTOMER LISTING MAIN PAGE
                                            <div className="row" >
                                                <div className="col-12">
                                                    <Table >
                                                        <thead>
                                                            <tr className="memTableHead">
                                                                <td className="tutorSimInfo tutorID">#{this.props.language !== "eng" ? "顧客" : "ID"}</td>
                                                                <td className="tutorSimInfo nameEng">{this.props.language !== "eng" ? "姓名" : "Name"}</td>
                                                                <td className="tutorSimInfo sex">{this.props.language !== "eng" ? "性別" : "SEX"}</td>
                                                                <td className="tutorSimInfo memStatus">{this.props.language !== "eng" ? "會員等級" : "Level"}</td>
                                                                <td className="tutorSimInfo paymentStatus">{this.props.language !== "eng" ? "款項支付狀態" : "Payment Status"}</td>
                                                                <td className="tutorSimInfo lastActivity"><div id="lastActivity">{this.props.language !== "eng" ? "顧客分類" : "Customer Group"}</div></td>
                                                            </tr>
                                                        </thead>
                                                    </Table>
                                                </div>
                                                <div className="col-12" id="customerListBGArea">
                                                    <Table>
                                                        <tbody >

                                                            {
                                                                this.props.loading ?
                                                                    <div className="loadingContainerRow">
                                                                        <div className="inlineDisplay">
                                                                            <div className="loadingContainer">
                                                                                <CircularProgress id="loadingCircle" />
                                                                                <div className="loadingLabel">Loading ...</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    displayCustomers.map((customer: any) =>

                                                                        <tr className="tutorSimplifiedCard" onMouseOver={() => this.handleCustomerSelection(customer.user_id)} onMouseLeave={this.handleClearCustomerPreview}>
                                                                            <td className="tutorID"><div className="tutorSimInfo lilSpace">{customer.id}</div></td>
                                                                            <td className="tutorSimInfo nameEng"><div className="tutorName">{customer.name_eng ? customer.name_eng : customer.name_chi}</div></td>
                                                                            <td className="tutorSimInfo sex">{customer.sex}</td>
                                                                            {/* <td className="tutorSimInfo danceType">{customer.genre_id.map((id: number) => (this.props.genreTypeLists.find((genreType: any) => genreType.id == id)).genre + ' ')}</td> */}
                                                                            <td className="tutorSimInfo memStatus">Basic Customer</td>
                                                                            <td className="tutorSimInfo paymentStatus">{customer.payments_clear ?
                                                                                <div className="inlineDisplay everythingPaid">{this.props.language !== "eng" ? "所有款項已清付" : "Clear"}</div> :
                                                                                <div className="inlineDisplay unfinishedPayment">{this.props.language !== "eng" ? "有未完成支付款項" : "Not Clear"}</div>
                                                                            }</td>
                                                                            <td className="tutorSimInfo lastActivity"><div className="inlineDisplay activityParti">{(this.props.allCustomerGroupingList.filter((customerGroup: any) => customerGroup.id === customer.customer_groupID))[0].customer_group}</div></td>
                                                                            {/* <td className="tutorSimInfo paymentStatus"><div className="inlineDisplay unfinishedPayment">UNFINISHED PAYMENT</div></td> */}
                                                                            <td className="manageBtn"> <Button variant="contained" color="primary" startIcon={<MoreIcon />} onClick={() => this.handleManageClick(customer.user_id, purchaseBucketNum)} >Manage</Button></td>
                                                                            {/* <td className="payrollBtn"> <Button variant="contained" color="default" startIcon={<ReceiptIcon />}>Payroll</Button></td> */}

                                                                        </tr>
                                                                    )}

                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div className="col-12" id="pageIdentifyingRow">
                                                    <div className="pageIndicator">
                                                        <div className="pageIndicatorContainer">
                                                            {
                                                                this.state.listingPage === 1 ?
                                                                    <FirstPageIcon style={{ color: "#999999" }} />
                                                                    :
                                                                    <FirstPageIcon onClick={this.handleGoToFirstPageCustomerClick} />
                                                            }
                                                            {
                                                                this.state.listingPage === 1 ?
                                                                    <NavigateBeforeIcon style={{ color: "#999999" }} />
                                                                    :
                                                                    <NavigateBeforeIcon onClick={this.handleShowPreviousPageCustomerClick} />
                                                            }

                                                            <div className="indicator">
                                                                {
                                                                    this.props.language !== "eng" ?
                                                                        `第 ${this.state.listingPage} 頁 - 共 ${Math.ceil(parseInt(`${this.props.totalCustomerCount}`) / 10)} 頁`
                                                                        :
                                                                        `Page ${this.state.listingPage} / ${Math.ceil(parseInt(`${this.props.totalCustomerCount}`) / 10)}`
                                                                }
                                                            </div>
                                                            {
                                                                this.state.listingPage === Math.ceil(parseInt(`${this.props.totalCustomerCount}`) / 10) ?
                                                                    <NavigateNextIcon style={{ color: "#999999" }} />
                                                                    :
                                                                    <NavigateNextIcon onClick={this.handleShowNextPageCustomerClick} />

                                                            }
                                                            {
                                                                this.state.listingPage === Math.ceil(parseInt(`${this.props.totalCustomerCount}`) / 10) ?
                                                                    <LastPageIcon style={{ color: "#999999" }} />
                                                                    :
                                                                    <LastPageIcon onClick={this.handleGoToLastPageCustomerClick} />

                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>}



                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        language: state.auth.language,
        allCustomerInfo: state.customers.allCustomerInfo,
        totalCustomerCount: state.customers.totalCustomerCount,
        ageGroupList: state.customers.ageGroupList,
        genreTypeList: state.customers.genreTypeList,
        packagesWithRightUsage: state.customers.packageWithRightUsageList,
        tokenUsageList: state.customers.tokenUsageList,
        purchasePackagesList: state.customers.purchasePackagesDetailList,
        purchaseProductsList: state.customers.purchaseProductsDetailsList,
        discountOwnedBySelectedCustomer: state.customers.discountOwnedBySelectedCustomer,
        packagesOwnByCustomer: state.customers.packagesOwnByCustomer,
        purchaseTermsLibrary: state.customers.purchaseTermsLibrary,
        allDiscountTypes: state.customers.allDiscountTypes,
        relatedGuardianDetails: state.customers.relatedGuardianDetails,
        allCustomerGroupingList: state.customers.allCustomerGroupingList,
        allSites: state.customers.allSites,
        newlyPurchasedPackagesDetails: state.customers.newlyPurchasedPackagesDetails,
        customerPreviousNoTokenBookings: state.customers.customerPreviousNoTokenBookings,
        loading: state.customers.loading,
        allAttributeTypes: state.customers.allAttributeTypes,
        allCategoryTypes: state.customers.allCategoryTypes,
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        customerPageBeingLoaded: async (page: number) => dispatch(await customerPage(page)),
        handleSaveCustomerBasicInfo: async (newCustomerBasicInfo: any) => dispatch(await updateThisCustomerBasicInfos(newCustomerBasicInfo)),
        loadGenreTypeList: async () => dispatch(await loadGenreTypeList()),
        loadPackagesWithUsageID: async (usageID: number) => dispatch(await loadPackagesWithUsageID(usageID)),
        loadPurchasePackagesDetails: async (packagesSimInfoArr: number) => dispatch(await loadPackagesDetailsForPurchase(packagesSimInfoArr)),
        loadPurchaseProductsDetails: async (productSimInfoArr: number) => dispatch(await loadProductsDetailsForPurchase(productSimInfoArr)),
        loadDiscountOfCustomer: async (userID: number) => dispatch(await loadDiscountsOfCustomer(userID)),
        submitNewPurchase: async (dataTrain: any) => dispatch(await submitNewPurchase(dataTrain)),
        loadPackagesOfCustomer: async (customerUserID: number) => dispatch(await loadPackagesOwnByCustomer(customerUserID)),
        periodPackageActivation: async (packageID: number) => dispatch(await periodPackageActivation(packageID)),
        loadPurchaseTermsLibrary: async () => dispatch(await loadPurchaseTermsLibrary()),
        handleCustomerSearch: async (searchMethod: string, searchInput: any) => dispatch(await searchCustomerInfo(searchMethod, searchInput)),
        handleAddDiscountToCustomerClick: async (discountDetails: any) => dispatch(await addDiscountToCustomer(discountDetails)),
        handleDelCustomerDiscountClick: async (discountID: number) => dispatch(await delCustomerDiscounts(discountID)),
        loadRelatedGuardianDetails: async (guardianID: number) => dispatch(await loadCustomerRelatedGuardianDetails(guardianID)),
        handleCreateGuardianDetails: async (guardianDetails: any) => dispatch(await createCustomerRelatedGuardianDetails(guardianDetails)),
        handleUpdateGuardianDetails: async (guardianDetails: any) => dispatch(await updateCustomerRelatedGuardianDetails(guardianDetails)),
        handleDelGuardianDetails: async (guardianID: number, customerID: number) => dispatch(await deleteCustomerRelatedGuardianDetails(guardianID, customerID)),
        resetGuardianDetails: async () => dispatch(await relatedGuardianDetailsReset()),
        handleSaveCustomerGroupingID: async (customerGroupingID: number, customerID: number) => dispatch(await updateCustomerGroupingID(customerGroupingID, customerID)),
        handleUpdateCustomerPackageDetailsManually: async (customerPackageDetails: any) => dispatch(await updateCustomerPackageDetailsManually(customerPackageDetails)),
        handleConfirmSubmitReferralDetailsAfterwards: async (referringCustomerID: number, customerID: number) => dispatch(await updateReferralDetailsAfterwards(referringCustomerID, customerID)),
        handleExitFromProcessPreviousNoTokenBookingWindow: async () => dispatch(await exitFromProcessNoTokenBookingWindow()),
        handleProcessPreviousNoTokenBookingWithNewPackages: async (previousNoTokenBooking: any, selectedPackageID: number) => dispatch(await processPreviousNoTokenBookingWithNewPackages(previousNoTokenBooking, selectedPackageID)),
        triggerLoading: async () => dispatch(await loading()),
        handleReleaseTargetProduct: async (productArr: any[]) => dispatch(await releaseTargetProduct(productArr))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Customer)

