

export function allAttendanceLoaded( attendances:any) {
    return {
        type: "ALL_ATTENDANCES_LOADED" as "ALL_ATTENDANCES_LOADED",
        attendances
    }
}
export function attendanceCreated () {
    return {
        type: "ATTENDANCE_CREATED" as "ATTENDANCE_CREATED"
    }
}
export function attendanceUpdated () {
    return {
        type:"ATTENDANCE_UPDATED" as "ATTENDANCE_UPDATED"
    }
}
export function attendanceActiveSettingModified () {
    return {
        type: "ATTENDANCE_ACTIVE_SETTING_MODIFIED" as "ATTENDANCE_ACTIVE_SETTING_MODIFIED"
    }
}



type AttendanceManageActionsCreators = typeof allAttendanceLoaded |
                                    typeof attendanceCreated |
                                    typeof attendanceUpdated |
                                    typeof attendanceActiveSettingModified ;
                                            

export type IAttendanceManageActions = ReturnType<AttendanceManageActionsCreators>