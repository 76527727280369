import React from "react";


export interface IPrintableClassAttnListProps {
    language: string,
    classOccupancyDetails: any,
    customerLists: any

}

class PrintableClassAttnList extends React.Component<IPrintableClassAttnListProps, {}> {
    constructor(props: IPrintableClassAttnListProps) {
        super(props);
    }

    componentDidMount() {
        window.print();
    }
    render() {
        
        let renderList : any[] = [];
        let validBooking = this.props.customerLists.filter((participation:any)=> !participation.absent_reason_id);
        console.log(validBooking)
        for(let i = 0; i < 25; i++){
            let renderUnit = {}
            if(i < validBooking.length){
                renderUnit = {
                    count: i + 1,
                    id: validBooking[i].id,
                    name_eng: validBooking[i].name_eng,
                    name_chi: (validBooking[i].name_chi? validBooking[i].name_chi: null),
                    packageUsed: validBooking[i].packageUsed,
                    atToken: validBooking[i].atToken
                }
            } else {
                renderUnit = {
                    count: i + 1
                }
            }
            renderList.push(renderUnit)
        }
        return (
            <div id="attnListBase" >
                <div className="a4Width">
                    <table className="attnListTable">
                        <thead>
                            <tr>
                                <th>{this.props.language !== "eng" ? "課堂詳情" : "Class Details"}</th>
                                <th>{this.props.language !== "eng" ? "運動種類" : "Sport"}</th>
                                <th>{this.props.language !== "eng" ? "課堂導師" : "Tutor"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{this.props.classOccupancyDetails.date} | {this.props.classOccupancyDetails.time} | {this.props.classOccupancyDetails.room}</th>
                                <th>{this.props.classOccupancyDetails.genre} | {this.props.classOccupancyDetails.ageGroup}</th>
                                <th>{this.props.classOccupancyDetails.tutor}</th>
                            </tr>
                        </tbody>
                    </table>
                    <table className="attnListTable">
                        <thead>
                            <tr>
                                <th>{this.props.language !== "eng" ? "人數" : "Count"}</th>
                                <th>{this.props.language !== "eng" ? "顧客編號" : "Customer ID"}</th>
                                <th>{this.props.language !== "eng" ? "顧客" : "Customer"}</th>
                                <th>{this.props.language !== "eng" ? "使用套券" : "Using Package"}</th>
                                <th>{this.props.language !== "eng" ? "顧客簽名" : "Signature"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                renderList.map((customer: any) =>
                                    <tr>
                                        <td>{customer.count}</td>
                                        <td>{customer.id}</td>
                                        <td>{customer.name_eng ? customer.name_eng : customer.name_chi}</td>
                                        <td>{customer.packageUsed} {customer.atToken}</td>
                                        <td></td>
                                    </tr>
                                )
                            }
                            
                        </tbody>
                        <tfoot >
                            <tr>
                                <td colSpan={2}>{this.props.language !== "eng" ? "顧客總數 :" : "Head Count :"}</td>
                                <td>{this.props.language !== "eng" ? "處理員工 :" : "Process Staff :"}</td>
                                <td colSpan={2}>{this.props.language !== "eng" ? "導師簽名 :" : "Tutor Signature :"}</td>
                            </tr>
                        </tfoot>

                    </table>
                </div>
            </div>
        )
    }
}

export default PrintableClassAttnList;