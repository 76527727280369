
import { IPackagesManageState } from "./state";
import { IPackageManageActions } from "./actions";

const initialState = {
    allAgeGroups: [],
    allGenres: [],
    allTokenUsages: [],
    allPackagesWithRightUsage: [],
    allTerms: [],
    allSites: [],
    uniquePackageNameCheckResult: { packageName: "", unique: false },
    loading: true,
    massedUpdatedGenrePackageCount: 0

}

export const packageManageReducer = (state: IPackagesManageState = initialState, actions: IPackageManageActions) => {
    switch (actions.type) {
        case "ALL_DATA_LOADED_FOR_PACKAGE_MANAGE_INIT":
            return {
                ...state,
                allAgeGroups: actions.allAgeGroups,
                allGenres: actions.allGenres,
                allPackagesWithRightUsage: actions.allTokenPackages,
                allTokenUsages: actions.allTokenUsages,
                allTerms: actions.allTerms,
                allSites: actions.allSites,
                loading: false
            }
        case "ALL_PACKAGES_WITH_RIGHT_USAGE_LOADED":
            return {
                ...state,
                allPackagesWithRightUsage: actions.allPackages,
                loading: false
            }
        case "PACKAGE_CREATED":
            return {
                ...state,
                loading: true
            }
        case "PACKAGE_UPDATED":
            return {
                ...state,
                loading: true
            }
        case "PACKAGE_DELETED":
            return {
                ...state,
                loading: true
            }
        case "LOADING":
            return {
                ...state,
                loading: true
            }

        case "UNIQUE_PACKAGE_NAME_CHECKED":
            return {
                ...state,
                uniquePackageNameCheckResult: {
                    packageName: actions.uniqueCheckResult.packageName,
                    unique: actions.uniqueCheckResult.unique
                }
            }

        case "FINISH_MASS_UPDATING_PACKAGE_GENRE":
            return {
                ...state,
                massedUpdatedGenrePackageCount: actions.changedPackageCount

            }
        default:
            return state;
    }
}