import { ThunkDispatch } from '../../store';
import { allPaymentsLoaded } from './actions';


export async function loadIncomeOfSiteOfSelectedDate (startDate:string, endDate:string, siteID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/accounting/loadAllPaymentsOfSiteWithinDateRange?startDate=${startDate}&endDate=${endDate}&siteID=${siteID}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(allPaymentsLoaded(result.allPayments, result.allCustomerDetails, result.allSites, result.last7DayIncome));
        }
    }
}