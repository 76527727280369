import site1Logo from '../../src/site1Logo.png';
import React from "react";
import { ThunkDispatch, IRootState } from '../../store';
import { connect } from 'react-redux';
import { loadTargetedPackageDetails } from './thunks'
import './InvoiceGenerator.css'
import moment from 'moment';
import MoreOutlinedIcon from '@material-ui/icons/MoreOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

export interface IInvoiceGeneratorProps {
    language:string,
    purchasePreviewData: any,
    allPackageDetails: any,
    loadAllTargetedData: (purchaseContent: any) => void,
}
export interface IInvoiceGeneratorState {

}

class InvoiceGenerator extends React.Component<IInvoiceGeneratorProps, IInvoiceGeneratorState>{
    constructor(props: IInvoiceGeneratorProps) {
        super(props);
        this.state = {

        }
    }
    componentWillMount() {
        this.props.loadAllTargetedData(this.props.purchasePreviewData.purchaseData.purchaseContent);
    }
    render() {
        return (
            <div className="fixedHeightInvoicePreviewBox">
                <div className="invoiceSection">
                    <div className="sectionHeaderOfInvoicePreview">
                        {this.props.language !== "eng"? "選購內容" : "Purchase Details"} :
                        </div>
                    <div className="sectionContentOfInvoicePreview">
                        {
                            this.props.purchasePreviewData.purchaseData.packagesDetails.length === 0 ? null :
                            this.props.purchasePreviewData.purchaseData.packagesDetails.map((targetPackageDetails: any) =>
                                    <div className="contentRow">
                                        <div className="packageNameOnInvoicePreview">{this.props.language !== "eng"? "套券名稱" : "Package"} : {targetPackageDetails.package_name}</div>
                                        <div className="packageDetailsOnInvoicePreview">{this.props.language !== "eng"? "套券數量" : "Active Token"} : {targetPackageDetails.token_amount} 張</div>
                                        <div className="packageDetailsOnInvoicePreview">{this.props.language !== "eng"? "套券有效期" : "Validate Period"} : {targetPackageDetails.validate_period} 日</div>
                                        <div className="packageDetailsOnInvoicePreview">{this.props.language !== "eng"? "價錢" : "Price"} : $ {targetPackageDetails.price}</div>
                                        <div className="contentRowDividerOnInvoicePreview"></div>
                                    </div>
                                )
                        }
                        {
                            this.props.purchasePreviewData.purchaseData.productDetails.length === 0 ?
                                null :
                                this.props.purchasePreviewData.purchaseData.productDetails.map((product: any) =>
                                    <div className="contentRow">
                                        <div className="inventoryProductAttributeOnInvoicePreview">
                                            <div className="productAttributeAlignerOnInvoicePreview">
                                                <div className="inventoryProductAttributeOnInvoicePreview">{this.props.language !== "eng"? "貨品編號" : "ID"} : #{product.id}</div>
                                            </div>
                                        </div>
                                        <div className="inventoryProductAttributeOnInvoicePreview">
                                            <div className="productAttributeAlignerOnInvoicePreview">
                                                <div className="packageDetailsOnInvoicePreview">{this.props.language !== "eng"? "貨品名稱" : "Product"} : {product.product_name}</div>
                                                <div className="packageDetailsOnInvoicePreview">SKU : {product.sku}</div>
                                                <div className="packageDetailsOnInvoicePreview">{this.props.language !== "eng"? "價錢" : "Price"} : $ {product.markedPrice}</div>
                                            </div>
                                        </div>
                                        <div className="inventoryProductAttributeOnInvoicePreview">
                                            <div className="productAttributeAlignerOnInvoicePreview">
                                                {
                                                    product.attributeArr.map((attribute: any) =>
                                                        <div className="productContentInlineDisplayOnInvoicePreview">{attribute.attribute} : <div className="productContentInlineDisplayThin">{attribute.value} </div></div>
                                                    )
                                                }
                                            </div>

                                        </div>
                                        <div className="contentRowDividerOnInvoicePreview"></div>
                                    </div>)
                        }
                        <div className="sectionSummaryOfInvoicePreview">
                            <div className="inlineDisplay">{this.props.language !== "eng"? "總額" : "Sub-Total"} : $ {parseFloat(this.props.purchasePreviewData.purchaseData.totalPrice).toFixed(2)}</div>
                        </div>
                        {
                            !this.props.purchasePreviewData.usedDiscountDetails ? null :
                                this.props.purchasePreviewData.usedDiscountDetails.map((discount: any) =>
                                    <div className="contentRow">
                                        <div className="discountDetails">{this.props.language !== "eng"? "優惠：" : "Discount :"}{discount.discount_name}</div>
                                        <div className="discountDetails">{this.props.language !== "eng"? "優惠額：" : "Discounted Amount :"}{discount.cash_discount ? ` $ ${discount.cash_discount}` : ` ${discount.percentage_discount} %`}</div>
                                        <div className="contentRowDividerOnInvoicePreview"></div>
                                    </div>
                                )

                        }
                        <div className="sectionSummaryOfInvoicePreview">
                            <div className="inlineDisplay">{this.props.language !== "eng"? "應繳付總額：" : "Total Payable :"} : $ {parseFloat(this.props.purchasePreviewData.purchaseData.amountPayable).toFixed(2)}</div>
                        </div>
                        <div className="sectionRowDividerOnInvoicePreview"></div>
                        {
                            this.props.purchasePreviewData.paymentData.amountReceived === 0 ? null :
                                <div className="invoiceSection">
                                    <div className="contentRow">
                                        <div className="sectionHeaderOfInvoicePreview">{this.props.language !== "eng"? "付款詳情" : "Payment Details"} : # -</div>
                                        <div className="packageDetailsOnInvoicePreview">{this.props.language !== "eng"? "付款日" : "Payment Date"}: {moment().format('YYYY-MM-DD')} </div>
                                        <div className="packageDetailsOnInvoicePreview">{this.props.language !== "eng"? "付款方法：" : "Payment Method :"}{this.props.purchasePreviewData.paymentData.paymentMethod}</div>
                                        <div className="packageDetailsOnInvoicePreview">{this.props.language !== "eng"? "付款總數：" : "Amount Paid :"}$ {this.props.purchasePreviewData.paymentData.amountReceived}</div>
                                        <div className="contentRowDividerOnInvoicePreview"></div>
                                    </div>
                                    <div className="sectionSummaryOfInvoicePreview">
                                        <div className="inlineDisplay">{this.props.language !== "eng"? "已繳付總額" : "Total Paid "} : $ {parseFloat(this.props.purchasePreviewData.paymentData.amountReceived).toFixed(2)}</div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                <div className="termsSection">
                    <div className="termsHeaderOfInvoicePreview">
                        {this.props.language !== "eng"? "條款內容細則" : "Terms & Conditions "} :
                </div>
                    {
                        //PURCHASE TERMS
                        this.props.purchasePreviewData.purchaseData.purchaseTermsIDs.length === 0 ? null :
                            this.props.purchasePreviewData.purchaseData.purchaseTermsIDs.map((termID: number) =>
                                <div className="termsOnInvoicePreview"> - {(this.props.purchasePreviewData.purchaseTermsLibrary.filter((term: any) => term.id === termID))[0].content}</div>
                            )
                    }
                    {
                        //PACKAGE TERMS
                        this.props.allPackageDetails.map((targetPackage: any) =>
                            !targetPackage.package_terms ? null :
                                targetPackage.package_terms.map((termID: number) =>
                                    <div className="termsOnInvoicePreview"> - {(this.props.purchasePreviewData.purchaseTermsLibrary.filter((term: any) => term.id === termID))[0].content}</div>
                                )
                        )
                    }
                    {
                        //BASE TERMS
                        this.props.purchasePreviewData.purchaseTermsLibrary.map((term: any) =>
                            !term.base_terms ? null :
                                <div className="termsOnInvoicePreview"> - {term.content}</div>
                        )
                    }
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        loadAllTargetedData: async (purchaseContent: any) => dispatch(await loadTargetedPackageDetails(purchaseContent))
    }
}
const mapStateToProps = (state: IRootState) => {
    return {
        allPackageDetails: state.invoiceGenerator.allTargetedPackageDetails,
        language: state.auth.language
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(InvoiceGenerator);