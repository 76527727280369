

export function requiredStaffDetailsLoaded (staffDetails: any) {
    return {
        type: "REQUIRED_STAFF_DETAILS_LOADED" as "REQUIRED_STAFF_DETAILS_LOADED",
        staffDetails
    }
}

export function loginDetailsUpdated () {
    return {
        type: "LOGIN_DETAILS_UPDATED" as "LOGIN_DETAILS_UPDATED"
    }
}

export function loginDetailsProcessing() {
    return {
        type: "LOGIN_DETAILS_PROCESSING" as "LOGIN_DETAILS_PROCESSING"
    }
}

type StaffAcLoginSetUpActionsCreators = typeof requiredStaffDetailsLoaded |
                                        typeof loginDetailsUpdated |
                                        typeof loginDetailsProcessing;

export type IStaffAcLoginSetUpActions = ReturnType <StaffAcLoginSetUpActionsCreators>