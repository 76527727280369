import React from "react";
import { IRootState, ThunkDispatch } from "../store";
import { connect } from "react-redux";
import './RoomOccupancyIndividualBox.css'
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import TutorEditBox from '../TutorEditBox/TutorEditBox'
import { Button, TextField, InputLabel, Select, MenuItem } from "@material-ui/core";
import PublishIcon from '@material-ui/icons/Publish';
import RedoIcon from '@material-ui/icons/Redo';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { delThisRoomOccupancy, updateClassOccupancyAndTutorList, updateClassOccupancyAndTutorListWithNewUSG, updateClassOccupancyAndTutorListWithUSG, loadOccupancyRelatedParticipationInformation, updateParticipationDetailsWithAcceptableCxlReason, delRentalPrivateOccupancyBookingWithReason, delParticipationWithoutReasonByStaff, updateParticipationDetailsWithAcceptableCxlReasonBackward } from './thunks'
import { handleUSGConfirmationBoxDeclineClick, resetRoomOccupancyIndividualBox } from './actions'
import moment from "moment";
import IUpdateUSGConfirmationBox from './UpdateUSGConfirmationBox/UpdateUSGConfirmationBox'
import FormControl from '@material-ui/core/FormControl';
import FaceIcon from '@material-ui/icons/Face';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import IAddNewBookingWindow from './AddNewBookingWindow/AddNewBookingWindow';
import IAttendanceWindow from './AttendanceWindow/AttendanceWindow'
import CancelIcon from '@material-ui/icons/Cancel';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CircularProgress from '@material-ui/core/CircularProgress';
import PrintableClassAttnList from './PrintableClassAttnList';
import PrintIcon from '@material-ui/icons/Print';
import TextsmsIcon from '@material-ui/icons/Textsms';


export interface IRoomOccupancyIndividualBoxProps {
    language: string,
    allOccupancyInfo: any,
    selectedOccupancyId: number,
    tutorInfos: any,
    tutorList: any,
    genreTypeLists: any,
    ageGroupLists: any,
    classroomList: any,
    roomRentingPrivateCustomerList: any,
    dateShowing: any,
    occupancyOfTheDay: any,
    openingHourList: any,
    submittedEditingOccupancyNewDetails: any,
    repeatedUSGMatchWithSubmittedOccupancyNewDetails: any,
    groupClassCustomerParticipationInfos: any[],
    relatedCustomerPackagesInfos: any[],
    relatedCustomerInfos: any[],
    absentReasons: any[],
    loading: boolean,
    allSites: any[],
    handleExit: () => void,
    reloadSchedule: () => void,
    handleCxlClassOccupancy: (data: any) => void,
    saveEditedClassOccupancy: (editedOccupancy: any) => void,
    handleUseUSGConfirmation: (editedOccupancyDetails: any, newUSGDetails: any) => void,
    handleNewUSGConfirmation: (editedOccupancyDetails: any, newUSGDetails: any) => void,
    handleDeclineUSGConfirmation: () => void,
    handleReloadParticipationRelatedInformation: (roomOccupancyID: number) => void,
    handleCxlParticipationBooking: (participationDetails: any, absentReasonID: number, staffUserID: number) => void,
    handleCxlParticipationBookingBackward: (participationDetails: any) => void,
    handleCxlPrivateRentalOccupancyBookingWithReason: (occupancyDetails: any) => void,
    handleCxlParticipationByStaffNoReason: (participationDetails: any) => void,
    handleResetComponent: () => void
}

export interface IRoomOccupancyIndividualBoxState {
    editOccupancy: boolean,
    originalOccupancyDetails: any | null,
    originalTutorList: any | null,
    newClassStartingTimeList: any[],
    newClassStartingTime: string | null,
    newClassEndingTime: string | null,
    newClassDurationList: any[],
    newClassDuration: string | null,
    newClassRoomInput: any | null,
    newClassGenre: number | null,
    newClassAgeGroup: number | null,
    newClassTutor: number | null,
    newClassTutorList: any[],
    newClassFullSpaceOccupying: boolean,
    newClassPrivateClass: boolean,
    newClassRoomRental: boolean,
    newClassSiteID: number,
    newClassOriginalUSGID: number,
    newClassCapacity: number | null,
    showAddBookingWindow: boolean,
    showAttendanceWindow: boolean,
    cxlAttnBoxPositionSizingDetails: any,
    showCxlAttnReasonBox: boolean,
    selectedCxlReasonID: number,
    selectedParticipationDetails: any
    printClassAttnList: boolean,
    classOccupancyForAttnList: any
    customerDetailsForAttnList: any
}

class RoomOccupancyIndividualBox extends React.Component<IRoomOccupancyIndividualBoxProps, IRoomOccupancyIndividualBoxState> {
    constructor(props: IRoomOccupancyIndividualBoxProps) {
        super(props);
        this.state = {
            editOccupancy: false,
            originalOccupancyDetails: null,
            originalTutorList: null,
            newClassStartingTimeList: [],
            newClassStartingTime: null,
            newClassEndingTime: null,
            newClassDurationList: [],
            newClassDuration: null,
            newClassRoomInput: null,
            newClassGenre: null,
            newClassAgeGroup: null,
            newClassTutor: null,
            newClassTutorList: [],
            newClassFullSpaceOccupying: true,
            newClassPrivateClass: false,
            newClassRoomRental: false,
            newClassSiteID: 0,
            newClassOriginalUSGID: 0,
            newClassCapacity: null,
            showAddBookingWindow: false,
            showAttendanceWindow: false,
            cxlAttnBoxPositionSizingDetails: { x: 0, y: 0, height: 0, width: 0 },
            showCxlAttnReasonBox: false,
            selectedCxlReasonID: 0,
            selectedParticipationDetails: '',
            printClassAttnList: false,
            classOccupancyForAttnList: '',
            customerDetailsForAttnList: ''
        }
    }

    private handleHideClick = async () => {
        let res = window.confirm('Delete This Occupancy?');
        if (res) {
            let thisOccupancyInfo = this.props.allOccupancyInfo.find((occupancy: any) => occupancy.id === this.props.selectedOccupancyId)
            if (!thisOccupancyInfo.private_class && !thisOccupancyInfo.room_rental) {
                let clientToken = localStorage.getItem('token');
                let tokenPayload: any = {}
                if (clientToken) {
                    tokenPayload = parseJwt(clientToken)
                }
                let data = {
                    occupancyId: thisOccupancyInfo.id,
                    staffUserID: tokenPayload.id

                }
                await this.props.handleCxlClassOccupancy(data);
            } else {
                await this.props.handleCxlPrivateRentalOccupancyBookingWithReason(thisOccupancyInfo);
            }
            await this.props.handleExit();
            await this.props.reloadSchedule();
        }
    }

    private handleEditClick = async () => {
        if (!this.state.editOccupancy) {
            let thisOccupancyInfo = this.props.allOccupancyInfo.find((occupancy: any) => occupancy.id === this.props.selectedOccupancyId);
            if (!thisOccupancyInfo.room_rental) {
                //EDIT CLASS CASE
                let thisOccupancyTutorList = (this.props.tutorList.find((tutorList: any) => tutorList.room_occupancy_detail_id === thisOccupancyInfo.id));

                let occupancies: any = []
                this.props.occupancyOfTheDay.map((occupancy: any) => {
                    if (occupancy.classroom_id === thisOccupancyInfo.classroom_id) {
                        occupancies.push(occupancy)
                    }
                })
                let occupanciesExceptShowing = occupancies.filter((occupancy: any) => occupancy.id !== thisOccupancyInfo.id)


                let tempNewClassStartingTimeList: any = []
                let todayOpeningTime = this.props.openingHourList[moment(`${this.props.dateShowing}`).isoWeekday()];
                let endOfDay = moment(`${this.props.dateShowing}${todayOpeningTime.split('-')[1]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
                for (let i = moment(`${this.props.dateShowing}${todayOpeningTime.split('-')[0]}+0800`, 'YYYY-MM-DD HH:mmZZZZ'); i <= endOfDay; i = i.add(15, 'minutes')) {
                    tempNewClassStartingTimeList.push(i.format('HH:mm'))
                };
                //CALCULATION OF STARTING TIME LIST
                let duplicatedTime: any = []
                occupanciesExceptShowing.map((occupancy: any) => {
                    let startTime = moment(occupancy.start_time);
                    let endTime = moment(occupancy.end_time);
                    for (let i = 0; i < tempNewClassStartingTimeList.length; i++) {
                        if (moment(`${this.props.dateShowing} ${tempNewClassStartingTimeList[i]}+0800`, 'YYYY-MM-DD HH:mmZZZZ') >= startTime && moment(`${this.props.dateShowing} ${tempNewClassStartingTimeList[i]}+0800`, 'YYYY-MM-DD HH:mmZZZZ') < endTime) {
                            duplicatedTime.push(tempNewClassStartingTimeList[i]);
                        }
                    }
                })
                for (let i = 0; i < duplicatedTime.length; i++) {
                    tempNewClassStartingTimeList = tempNewClassStartingTimeList.filter((time: any) => time !== duplicatedTime[i])
                }
                //--------------------------------------------------

                //CALCULATION OF DURATION LIST
                if (occupanciesExceptShowing.length === 0) {
                    let todayOpeningTime = this.props.openingHourList[moment(`${this.props.dateShowing}`).isoWeekday()];
                    let startOfCal = moment(`${this.props.dateShowing}${moment(thisOccupancyInfo.start_time).format('HH:mm')}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
                    let endOfDay = moment(`${this.props.dateShowing}${todayOpeningTime.split('-')[1]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
                    let officeHoursLeft = moment.duration(endOfDay.diff(startOfCal)).asMinutes();
                    let newClassDurations = []
                    for (let i = 30; i <= officeHoursLeft; i = i + 15) {
                        newClassDurations.push(`${i} min`);
                    }
                    await this.setState({
                        newClassDurationList: newClassDurations
                    })
                } else {
                    let occupanciesOnwards: any = []
                    for (let occupancy of occupanciesExceptShowing) {
                        if (new Date(occupancy.start_time) > new Date(`${this.props.dateShowing} ${moment(thisOccupancyInfo.start_time).format('HH:mm')}+0800`)) {
                            occupanciesOnwards.push(occupancy);
                        }
                    }
                    let theNextOccupancy: any = []
                    for (let occupancy of occupanciesOnwards) {
                        if (theNextOccupancy.length === 0) {
                            theNextOccupancy = occupancy
                        } else {
                            if (new Date(occupancy.start_time) < new Date(theNextOccupancy.start_time)) {
                                theNextOccupancy = occupancy
                            }
                        }
                    }
                    if (theNextOccupancy.length === 0) {
                        let todayOpeningTime = this.props.openingHourList[moment(`${this.props.dateShowing}`).isoWeekday()];
                        let startOfCal = moment(`${this.props.dateShowing}${moment(thisOccupancyInfo.start_time).format('HH:mm')}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
                        let endOfDay = moment(`${this.props.dateShowing}${todayOpeningTime.split('-')[1]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
                        let officeHoursRemains = moment.duration(endOfDay.diff(startOfCal)).asMinutes();
                        let newDurations = []
                        for (let i = 30; i <= officeHoursRemains; i = i + 15) {
                            newDurations.push(`${i} min`);
                        }

                        await this.setState({
                            newClassDurationList: newDurations
                        })
                    }
                    let startOfNextOccupancy = moment(theNextOccupancy.start_time);
                    let newClassStart = moment(`${this.props.dateShowing} ${moment(thisOccupancyInfo.start_time).format('HH:mm')}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
                    let result = moment.duration(startOfNextOccupancy.diff(newClassStart)).asMinutes();
                    if (result < 30) {
                        await this.setState({
                            newClassDurationList: []
                        })
                    } else {
                        let newClassDurations = []
                        for (let i = 30; i <= result; i = i + 15) {
                            newClassDurations.push(`${i} min`);
                        }
                        await this.setState({
                            newClassDurationList: newClassDurations
                        })
                    }
                }
                //--------------------------------------------------
                //SET OCCUPANCY PRESET
                await this.setState({
                    originalOccupancyDetails: thisOccupancyInfo,
                    originalTutorList: thisOccupancyTutorList,
                    newClassStartingTimeList: tempNewClassStartingTimeList,
                    newClassGenre: thisOccupancyInfo.genre_id,
                    newClassAgeGroup: thisOccupancyInfo.age_group_id,
                    newClassDuration: moment(thisOccupancyInfo.end_time).diff(moment(thisOccupancyInfo.start_time), 'minutes') + ' min',
                    newClassRoomInput: thisOccupancyInfo.classroom_id,
                    newClassStartingTime: moment(thisOccupancyInfo.start_time).format('HH:mm'),
                    newClassTutor: thisOccupancyTutorList.tutor_id,
                    newClassFullSpaceOccupying: thisOccupancyInfo.fully_occupying_space,
                    newClassPrivateClass: thisOccupancyInfo.private_class,
                    newClassRoomRental: thisOccupancyInfo.room_rental,
                    newClassSiteID: thisOccupancyInfo.site_id,
                    newClassOriginalUSGID: thisOccupancyInfo.unique_scheduling_group_id,
                    newClassCapacity: thisOccupancyInfo.capacity
                })
                //--------------------------------------------------
                //CALCULATE THE TUTOR LIST BY GENRE & AGE GROUP
                let tutorForGenre = this.props.tutorInfos.filter((tutor: any) => tutor.genre_id.includes(this.state.newClassGenre));
                let suitableTutors = tutorForGenre.filter((tutor: any) => tutor.age_group_id.includes(this.state.newClassAgeGroup));
                await this.setState({
                    newClassTutorList: suitableTutors,
                    newClassEndingTime: moment(thisOccupancyInfo.end_time).format('HH:mm'),
                    editOccupancy: !this.state.editOccupancy
                })
                //--------------------------------------------------

            }
            //EDIT RENTAL CASE
            await this.setState({
                originalOccupancyDetails: thisOccupancyInfo
            })
        } else {
            //SAVE EDIT CASE
            if (this.state.newClassAgeGroup === this.state.originalOccupancyDetails.age_group_id
                && this.state.newClassGenre === this.state.originalOccupancyDetails.genre_id
                && moment(`${this.props.dateShowing} ${this.state.newClassStartingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ').format('YYYY-MM-DD HH:mm') === moment(`${this.state.originalOccupancyDetails.start_time}`).format('YYYY-MM-DD HH:mm')
                && moment(`${this.props.dateShowing} ${this.state.newClassEndingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ').format('YYYY-MM-DD HH:mm') === moment(`${this.state.originalOccupancyDetails.end_time}`).format('YYYY-MM-DD HH:mm')
                && this.state.newClassRoomInput === this.state.originalOccupancyDetails.classroom_id
                && this.state.newClassTutor === this.state.originalTutorList.tutor_id
                && this.state.newClassCapacity === this.state.originalOccupancyDetails.capacity) {
                //NOTHING CHANGED
                await this.setState({
                    editOccupancy: !this.state.editOccupancy
                })
            } else {
                //CHANGED DETAILS
                if (!this.state.originalOccupancyDetails.room_rental) {
                    //SAVING CLASS OCCUPANCY
                    let editedOccupancyDetails = {
                        occupancyId: this.props.selectedOccupancyId,
                        ageGroupId: this.state.newClassAgeGroup,
                        genreId: this.state.newClassGenre,
                        start_time: moment(`${this.props.dateShowing} ${this.state.newClassStartingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ').toISOString(),
                        end_time: moment(`${this.props.dateShowing} ${this.state.newClassEndingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ').toISOString(),
                        classroomId: this.state.newClassRoomInput,
                        tutorId: this.state.newClassTutor,
                        fully_occupying_space: this.state.newClassFullSpaceOccupying,
                        private_class: this.state.newClassPrivateClass,
                        room_rental: this.state.newClassRoomRental,
                        site_id: this.state.newClassSiteID,
                        unique_scheduling_group_id: this.state.newClassOriginalUSGID,
                        day_of_week: moment(`${this.props.dateShowing}`).day(),
                        capacity: (this.state.newClassCapacity && this.state.newClassCapacity !== 0 ? this.state.newClassCapacity : null)
                    }
                    console.log(editedOccupancyDetails);
                    await this.props.saveEditedClassOccupancy(editedOccupancyDetails);

                    if (this.props.repeatedUSGMatchWithSubmittedOccupancyNewDetails.length === 0) {
                        //SAVED WITHOUT USG PROBLEMS
                        await this.props.reloadSchedule();
                        await this.setState({
                            editOccupancy: !this.state.editOccupancy
                        })
                    }
                } else {
                    //SAVING RENT OCCUPANCY
                }
            }
            // await this.props.reloadSchedule();
            // await this.setState({
            //     editOccupancy: !this.state.editOccupancy
            // })
        }
    }
    private handleNewClassRoomInput = async (event: any) => {
        await this.setState({
            newClassRoomInput: event.target.value
        })
        let occupancies: any = []
        this.props.occupancyOfTheDay.map((occupancy: any) => {
            if (occupancy.classroom_id === this.state.newClassRoomInput) {
                occupancies.push(occupancy)
            }
        })
        let occupanciesExceptShowing = occupancies.filter((occupancy: any) => occupancy.id !== this.state.originalOccupancyDetails.id)


        let tempNewClassStartingTimeList: any = []
        let todayOpeningTime = this.props.openingHourList[moment(`${this.props.dateShowing}`).isoWeekday()];
        let endOfDay = moment(`${this.props.dateShowing}${todayOpeningTime.split('-')[1]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
        for (let i = moment(`${this.props.dateShowing}${todayOpeningTime.split('-')[0]}+0800`, 'YYYY-MM-DD HH:mmZZZZ'); i <= endOfDay; i = i.add(15, 'minutes')) {
            tempNewClassStartingTimeList.push(i.format('HH:mm'))
        };

        let duplicatedTime: any = []
        occupanciesExceptShowing.map((occupancy: any) => {
            let startTime = moment(occupancy.start_time);
            let endTime = moment(occupancy.end_time);
            for (let i = 0; i < tempNewClassStartingTimeList.length; i++) {
                if (moment(`${this.props.dateShowing} ${tempNewClassStartingTimeList[i]}+0800`, 'YYYY-MM-DD HH:mmZZZZ') >= startTime && moment(`${this.props.dateShowing} ${tempNewClassStartingTimeList[i]}+0800`, 'YYYY-MM-DD HH:mmZZZZ') < endTime) {
                    duplicatedTime.push(tempNewClassStartingTimeList[i]);
                }
            }
        })
        console.log(duplicatedTime)
        for (let i = 0; i < duplicatedTime.length; i++) {
            tempNewClassStartingTimeList = tempNewClassStartingTimeList.filter((time: any) => time !== duplicatedTime[i])
        }
        console.log(tempNewClassStartingTimeList)
        await this.setState({
            newClassStartingTimeList: tempNewClassStartingTimeList,
            newClassDuration: "-"
        })
    }

    private handleStringTimeInput = async (event: any) => {
        await this.setState({
            newClassStartingTime: event.target.value
        })
        let occupancies: any = []
        this.props.occupancyOfTheDay.map((occupancy: any) => {
            if (occupancy.classroom_id === this.state.newClassRoomInput) {
                occupancies.push(occupancy)
            }
        })
        let occupanciesExceptShowing = occupancies.filter((occupancy: any) => occupancy.id !== this.state.originalOccupancyDetails.id)

        if (occupanciesExceptShowing.length === 0) {
            let todayOpeningTime = this.props.openingHourList[moment(`${this.props.dateShowing}`).isoWeekday()];
            let startOfCal = moment(`${this.props.dateShowing}${this.state.newClassStartingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
            let endOfDay = moment(`${this.props.dateShowing}${todayOpeningTime.split('-')[1]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
            let officeHoursLeft = moment.duration(endOfDay.diff(startOfCal)).asMinutes();
            let newClassDurations = []
            for (let i = 30; i <= officeHoursLeft; i = i + 15) {
                newClassDurations.push(`${i} min`);
            }
            await this.setState({
                newClassDurationList: newClassDurations,
                newClassDuration: '-'
            })
        } else {
            let occupanciesOnwards: any = []
            for (let occupancy of occupanciesExceptShowing) {
                if (new Date(occupancy.start_time) > new Date(`${this.props.dateShowing} ${this.state.newClassStartingTime}+0800`)) {
                    occupanciesOnwards.push(occupancy);
                }
            }
            let theNextOccupancy: any = []
            for (let occupancy of occupanciesOnwards) {
                if (theNextOccupancy.length === 0) {
                    theNextOccupancy = occupancy
                } else {
                    if (new Date(occupancy.start_time) < new Date(theNextOccupancy.start_time)) {
                        theNextOccupancy = occupancy
                    }
                }
            }
            console.log(theNextOccupancy)
            if (theNextOccupancy.length === 0) {
                let todayOpeningTime = this.props.openingHourList[moment(`${this.props.dateShowing}`).isoWeekday()];
                let startOfCal = moment(`${this.props.dateShowing}${this.state.newClassStartingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
                let endOfDay = moment(`${this.props.dateShowing}${todayOpeningTime.split('-')[1]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
                let officeHoursRemains = moment.duration(endOfDay.diff(startOfCal)).asMinutes();
                let newDurations = []
                for (let i = 30; i <= officeHoursRemains; i = i + 15) {
                    newDurations.push(`${i} min`);
                }

                await this.setState({
                    newClassDurationList: newDurations,
                    newClassDuration: "-"
                })
            }

            if (theNextOccupancy.start_time) {
                let startOfNextOccupancy = moment(theNextOccupancy.start_time);
                let newClassStart = moment(`${this.props.dateShowing} ${this.state.newClassStartingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
                let result = moment.duration(startOfNextOccupancy.diff(newClassStart)).asMinutes();
                if (result < 30) {
                    await this.setState({
                        newClassDurationList: [],
                        newClassDuration: "-"
                    })
                } else {
                    let newClassDurations = []
                    for (let i = 30; i <= result; i = i + 15) {
                        newClassDurations.push(`${i} min`);
                    }
                    await this.setState({
                        newClassDurationList: newClassDurations,
                        newClassDuration: "-"
                    })
                }
            }
        }
    }
    private handleGenreInput = async (event: any) => {
        await this.setState({
            newClassGenre: event.target.value
        })
    }

    private handleAgeGroupInput = async (event: any) => {
        await this.setState({
            newClassAgeGroup: event.target.value
        })

        if (this.state.newClassGenre && this.state.newClassAgeGroup) {
            let tutorForGenre = this.props.tutorInfos.filter((tutor: any) => tutor.genre_id.includes(this.state.newClassGenre));
            let suitableTutors = tutorForGenre.filter((tutor: any) => tutor.age_group_id.includes(this.state.newClassAgeGroup));
            this.setState({
                newClassTutorList: suitableTutors
            })
        }
    }
    private handleDurationInput = async (event: any) => {
        await this.setState({
            newClassDuration: event.target.value
        })
        if (this.state.newClassDuration && this.state.newClassStartingTime) {
            let newClassEndingTime = moment(`${this.props.dateShowing} ${this.state.newClassStartingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ').add((parseInt(this.state.newClassDuration)), 'minutes');
            await this.setState({
                newClassEndingTime: newClassEndingTime.format('HH:mm')
            })
        }
    }
    private handleTutorInput = async (event: any) => {
        await this.setState({
            newClassTutor: event.target.value
        })
    }
    private handleCapacityInput = async (event: any) => {
        await this.setState({
            newClassCapacity: event.target.value
        })
    }

    private handleUSGConfirmationBoxDecline = async () => {
        this.props.handleDeclineUSGConfirmation();
        await this.props.reloadSchedule();
        await this.setState({
            editOccupancy: !this.state.editOccupancy
        })
    }

    private handleUSGConfirmationBoxProceedWithUSG = async () => {
        await this.props.handleUseUSGConfirmation(this.props.submittedEditingOccupancyNewDetails[0], this.props.repeatedUSGMatchWithSubmittedOccupancyNewDetails);
        // await this.props.dateChange(this.state.dateShowing);
        await this.props.reloadSchedule();
        await this.setState({
            editOccupancy: !this.state.editOccupancy
        })
    }

    private handleUSGConfirmationBoxProceedWithNewUSG = async () => {
        await this.props.handleNewUSGConfirmation(this.props.submittedEditingOccupancyNewDetails[0], this.props.repeatedUSGMatchWithSubmittedOccupancyNewDetails)
        // await this.props.dateChange(this.state.dateShowing);
        await this.props.reloadSchedule();
        await this.setState({
            editOccupancy: !this.state.editOccupancy
        })
    }
    private handleShowAddBookingWindowClick = async () => {
        await this.setState({
            showAddBookingWindow: !this.state.showAddBookingWindow
        })
    }

    private handleExitFromAddNewBookingWindow = async () => {
        await this.props.handleReloadParticipationRelatedInformation(this.props.selectedOccupancyId);
        await this.props.reloadSchedule();
    }

    private handleShowAttendanceWindowClick = async () => {

        if (this.state.showAttendanceWindow) {
            await this.props.handleReloadParticipationRelatedInformation(this.props.selectedOccupancyId);
            await this.props.reloadSchedule();
        }
        await this.setState({
            showAttendanceWindow: !this.state.showAttendanceWindow
        })

    }

    private handleCxlParticipationClick = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, participationDetails: any) => {
        if (event.currentTarget.parentElement) {
            let positionDetails = event.currentTarget.parentElement.getBoundingClientRect()

            await this.setState({
                cxlAttnBoxPositionSizingDetails: { x: positionDetails.left, y: positionDetails.top, height: positionDetails.height, width: positionDetails.width },
                showCxlAttnReasonBox: !this.state.showCxlAttnReasonBox
            })
        }
        if (this.state.selectedParticipationDetails === '') {
            await this.setState({
                selectedParticipationDetails: participationDetails
            })
        } else {
            await this.setState({
                selectedParticipationDetails: ''
            })
        }
    }

    private handleCxlReasonChange = async (event: any) => {
        await this.setState({
            selectedCxlReasonID: event.target.value
        })

    }

    private handleCxlSubmit = async () => {
        //JWT PAYLOAD

        let clientToken = localStorage.getItem('token');
        let tokenPayload: any = {}
        if (clientToken) {
            tokenPayload = parseJwt(clientToken)
        }
        if (this.state.selectedCxlReasonID !== 0) {
            let confirm = window.confirm(`確認取消預約?`)
            if (confirm) {
                await this.props.handleCxlParticipationBooking(this.state.selectedParticipationDetails, this.state.selectedCxlReasonID, tokenPayload.id)
                await this.props.handleReloadParticipationRelatedInformation(this.props.selectedOccupancyId);
                await this.setState({
                    showCxlAttnReasonBox: !this.state.showCxlAttnReasonBox,
                    selectedCxlReasonID: 0,
                    selectedParticipationDetails: ""
                })
            }
        } else {
            if (this.state.selectedCxlReasonID === 0) {
                window.alert('請選取消原因！');
            }
        }
    }

    private handleStaffCxlSubmit = async () => {
        let confirmation = window.confirm('確認刪除預約?');
        if (confirmation) {
            await this.props.handleCxlParticipationByStaffNoReason(this.state.selectedParticipationDetails);
            await this.props.handleReloadParticipationRelatedInformation(this.props.selectedOccupancyId);
            await this.setState({
                showCxlAttnReasonBox: !this.state.showCxlAttnReasonBox,
                selectedCxlReasonID: 0,
                selectedParticipationDetails: ""
            })
        }
    }

    private handleBackwardCxl = async (participationDetails: any) => {
        let confirmation01 = true;
        let thisOccupancyInfo = this.props.allOccupancyInfo.find((occupancy: any) => occupancy.id === this.props.selectedOccupancyId);
        let bookingCount = (await this.props.groupClassCustomerParticipationInfos.filter((participation: any) => !participation.absent_reason_id)).length
        if (bookingCount >= (thisOccupancyInfo.capacity ? thisOccupancyInfo.capacity : (this.props.classroomList.find((room: any) => room.id === thisOccupancyInfo.classroom_id)).capacity)) {
            confirmation01 = window.confirm((this.props.language==="chi"?'選擇要預約的課堂已達人數上限，是否要繼續？':"Class is Full, Sure To Continue ?"))
        }
        if (confirmation01) {

            let confirmation02 = window.confirm((this.props.language ==="chi"?'確定要取消請假?' : "Confirm Cancel Customer Leave?"));
            if (confirmation02) {
                await this.props.handleCxlParticipationBookingBackward(participationDetails);
                await this.props.handleReloadParticipationRelatedInformation(this.props.selectedOccupancyId);
            }
        }
    }

    private handlePrintAttnList = async () => {
        let classOccupancyDetails = {
            date: moment((this.props.occupancyOfTheDay.find((occupancy: any) => occupancy.id === this.props.selectedOccupancyId)).start_time).format('DD/MM/YYYY (ddd)'),
            time: `${moment((this.props.occupancyOfTheDay.find((occupancy: any) => occupancy.id === this.props.selectedOccupancyId)).start_time).format('HH:mm')} - ${moment((this.props.occupancyOfTheDay.find((occupancy: any) => occupancy.id === this.props.selectedOccupancyId)).end_time).format('HH:mm')}`,
            room: (this.props.classroomList.find((classroom: any) => classroom.id === (this.props.occupancyOfTheDay.find((occupancy: any) => occupancy.id === this.props.selectedOccupancyId)).classroom_id)).classroom,
            genre: (this.props.genreTypeLists.find((genre: any) => genre.id === (this.props.occupancyOfTheDay.find((occupancy: any) => occupancy.id === this.props.selectedOccupancyId)).genre_id)).genre,
            ageGroup: (this.props.ageGroupLists.find((age: any) => age.id === (this.props.occupancyOfTheDay.find((occupancy: any) => occupancy.id === this.props.selectedOccupancyId)).age_group_id)).age_group,
            tutor: (this.props.tutorInfos.find((tutor: any) => tutor.tutor_id === (this.props.tutorList.find((list: any) => list.room_occupancy_detail_id === this.props.selectedOccupancyId)).tutor_id)).name_eng,
        }
        let customerDetails = []
        for (let participation of this.props.groupClassCustomerParticipationInfos) {
            if (!participation.absent_reason_id) {
                let customer = await this.props.relatedCustomerInfos.find((customerDetails: any) => customerDetails.id === participation.customer_id)
                let customerDetail = {
                    id: customer.id,
                    name_eng: (customer.name_eng ? customer.name_eng : null),
                    name_chi: (customer.name_chi ? customer.name_chi : null),
                    packageUsed: (participation.no_token_booking ? "無套劵預約" : (this.props.relatedCustomerPackagesInfos.find((targetPackage: any) => targetPackage.customer_id === customer.id)).package_name),
                    atToken: (participation.no_token_booking ? null : (this.props.relatedCustomerPackagesInfos.find((targetPackage: any) => targetPackage.customer_id === customer.id)).at_token ? (this.props.relatedCustomerPackagesInfos.find((targetPackage: any) => targetPackage.customer_id === customer.id)).at_token : null)
                }
                customerDetails.push(customerDetail)
            }
        }
        await this.setState({
            classOccupancyForAttnList: classOccupancyDetails,
            customerDetailsForAttnList: customerDetails,
            printClassAttnList: true
        })

        // let content =  null
        // if(document.getElementById('attnListBase')){
        //     content = document.getElementById('attnListBase')?.innerHTML 
        // }
        // let printWindow = window.open('', 'print');
        // if(content && printWindow){
        //     printWindow?.document.write('<html><head><title>Print</title><link media="all" type="text/css" rel="stylesheet" href="./App.css"/>');
        //     printWindow?.document.write('</head><body >');
        //     printWindow?.document.write(content);
        //     printWindow?.document.write('</body></html>');

        //     printWindow?.document.close();
        //     printWindow.focus()
        //     printWindow.print();
        //     printWindow.close();
        //     return true
        // }

    }
    private handleExitPrintAttnList = async () => {
        await this.setState({
            printClassAttnList: false
        })
    }


    componentWillMount() {
        this.props.handleReloadParticipationRelatedInformation(this.props.selectedOccupancyId)
    }
    componentWillUnmount() {
        this.props.handleResetComponent();
    }

    render() {
        let thisOccupancyInfo = this.props.allOccupancyInfo.find((occupancy: any) => occupancy.id === this.props.selectedOccupancyId);
        let thisOccupancyTutorId: any = ""
        let privateRentalPackageID = 0;
        if (thisOccupancyInfo.room_rental || thisOccupancyInfo.private_class) {
            privateRentalPackageID = thisOccupancyInfo.consumed_package_id[0]
        }
        if (!thisOccupancyInfo.room_rental) {
            thisOccupancyTutorId = (this.props.tutorList.find((tutorList: any) => tutorList.room_occupancy_detail_id === thisOccupancyInfo.id)).tutor_id
        }
        let numberOfCustomerPresent = 0
        if (this.props.groupClassCustomerParticipationInfos.length !== 0) {
            numberOfCustomerPresent = (this.props.groupClassCustomerParticipationInfos.filter((participation: any) => participation.absent === false)).length
        }
        let numberOfCustomerBooking = (this.props.groupClassCustomerParticipationInfos.filter((customer: any) => !customer.absent_reason_id)).length
        let numberOfCustomerLeave = (this.props.groupClassCustomerParticipationInfos.filter((customer: any) => customer.absent_reason_id)).length
        let browserWidth = vw(100);
        let cxlBoxWidth = vw(15);

        console.log(browserWidth)
        console.log(cxlBoxWidth)
        console.log(privateRentalPackageID)
        return (
            <div>
                {
                    !this.state.printClassAttnList ? null :
                        <div className="printableAttnList" onClick={this.handleExitPrintAttnList}>
                            <PrintableClassAttnList classOccupancyDetails={this.state.classOccupancyForAttnList} customerLists={this.state.customerDetailsForAttnList} language={this.props.language} />
                        </div>
                }
                {this.state.showCxlAttnReasonBox ?
                    //OTHER CXL BOX
                    browserWidth - (this.state.cxlAttnBoxPositionSizingDetails.x + this.state.cxlAttnBoxPositionSizingDetails.width - 30) > cxlBoxWidth ?
                        <div className="cxlReasonBox" style={{ height: this.state.cxlAttnBoxPositionSizingDetails.height, left: this.state.cxlAttnBoxPositionSizingDetails.x + this.state.cxlAttnBoxPositionSizingDetails.width - 30, top: this.state.cxlAttnBoxPositionSizingDetails.y - 90 }}>
                            <div className="attentionSignOnCxlBox cxlBoxTitleRow">
                                <ArrowBackIcon id="cxlPointing" />
                                <div className="inlineDisplayOnOccupancyIndividualBox">{this.props.language !== "eng" ? "注意 ! 正取消此預約" : "ATTENTION! Cxling Target Booking"}</div>
                            </div>
                            {
                                thisOccupancyInfo.private_class ? null :
                                    <div className="cxlBoxAligner">
                                        <FormControl id="cxlBookingReasonSelectionForm">
                                            <InputLabel id="demo-simple-select-label">{this.props.language !== "eng" ? "取消原因 :" : "Cxl Reason :"}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={this.state.selectedCxlReasonID}
                                                onChange={this.handleCxlReasonChange}
                                            >
                                                <MenuItem value={0}>{this.props.language !== "eng" ? "未選擇" : "Please Select"}</MenuItem>
                                                {
                                                    this.props.absentReasons.map((reason: any) =>
                                                        <MenuItem value={reason.id}>{reason.absent_reason}</MenuItem>
                                                    )
                                                }

                                            </Select>
                                        </FormControl>
                                    </div>
                            }
                            {
                                thisOccupancyInfo.private_class ? null :

                                    <div className="cxlConfirmRow">
                                        <Button id="cxlConfirmBtn" onClick={this.handleCxlSubmit}>{this.props.language !== "eng" ? "顧客取消預約" : "Customer Request"}</Button>
                                    </div>
                            }
                            <div className="cxlConfirmRow2">
                                <Button id="cxlConfirmBtn2" onClick={this.handleStaffCxlSubmit}>{this.props.language !== "eng" ? "員工刪除預約" : "Staff Cxl No Reason"}</Button>
                            </div>

                        </div>
                        :
                        //THE RIGHTEST CXL BOX
                        <div className="cxlReasonBox" style={{ left: this.state.cxlAttnBoxPositionSizingDetails.x - cxlBoxWidth - 30, top: this.state.cxlAttnBoxPositionSizingDetails.y - 90 }}>
                            <div className="attentionSignOnCxlBox cxlBoxTitleRow">
                                <div className="inlineDisplayOnOccupancyIndividualBox">{this.props.language !== "eng" ? "注意 ! 正取消此預約" : "ATTENTION! Cxling Target Booking"}</div>
                                <ArrowForwardIcon id="cxlPointing" />
                            </div>
                            {
                                thisOccupancyInfo.private_class ? null :
                                    <div className="cxlBoxAligner">
                                        <FormControl id="cxlBookingReasonSelectionForm">
                                            <InputLabel id="demo-simple-select-label">{this.props.language !== "eng" ? "取消原因 :" : "Cxl Reason :"}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={this.state.selectedCxlReasonID}
                                                onChange={this.handleCxlReasonChange}
                                            >
                                                <MenuItem value={0}>{this.props.language !== "eng" ? "未選擇" : "Please Select"}</MenuItem>
                                                {
                                                    this.props.absentReasons.map((reason: any) =>
                                                        <MenuItem value={reason.id}>{reason.absent_reason}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                            }
                            {
                                thisOccupancyInfo.private_class ? null :
                                    <div className="cxlConfirmRow">
                                        <Button id="cxlConfirmBtn" onClick={this.handleCxlSubmit}>{this.props.language !== "eng" ? "顧客取消預約" : "Customer Request"}</Button>
                                    </div>
                            }
                            <div className="cxlConfirmRow2">
                                <Button id="cxlConfirmBtn2" onClick={this.handleStaffCxlSubmit}>{this.props.language !== "eng" ? "員工刪除預約" : "Staff Cxl No Reason"}</Button>
                            </div>

                        </div>
                    : null}
                <div className="classDisplayInArea">

                    <div className="container-fluid">
                        {/* <div className="row">
                            <div className="col-12 appHeader">
                                <div className="navHeading">Tutor Details</div>
                                <CloseIcon id="exitBtn" onClick={this.props.handleExit} />
                                <EditIcon id="editBtn" onClick={this.handleEditExit} />
                            </div>
                        </div> */}
                        {
                            this.props.loading ?
                                <div className="loadingContainerRow">
                                    <div className="inlineDisplay">
                                        <div className="loadingContainer">
                                            <CircularProgress id="loadingCircle" />
                                            <div className="loadingLabel">Loading ...</div>
                                        </div>
                                    </div>
                                </div>
                                :

                                <div className="row">
                                    {this.state.editOccupancy ?
                                        <div className="col-3" style={{ height: '80vh' }}>

                                            <div className="row">
                                                <div className="col-12 sectionAligner">
                                                    <div className="sectionHeader">
                                                        {this.props.language !== "eng" ? "更改佔用詳情" : "Edit Occupancy"}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ height: '60vh' }}>
                                                <div className="col-1"></div>
                                                <div className="col-10" id="editForm">
                                                    <TextField className="Inputs w-100" id="newOccupancyDate" label="Date" variant="standard" value={this.props.dateShowing} />


                                                    <FormControl className="selectInputs newOccupancyInputLeft">
                                                        <InputLabel id="demo-simple-select-outlined-label">
                                                            {this.props.language !== "eng" ? "課室" : "Room"}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            defaultValue={this.state.originalOccupancyDetails.classroom_id}
                                                            onChange={this.handleNewClassRoomInput}
                                                        >
                                                            <MenuItem value="">{this.props.language !== "eng" ? "未選擇" : "None"}</MenuItem>
                                                            {this.props.classroomList.map((room: any) =>
                                                                <MenuItem value={room.id}>{room.classroom}</MenuItem>
                                                            )}

                                                        </Select>
                                                    </FormControl>
                                                    <FormControl className="selectInputs newOccupancyInputRight">
                                                        <InputLabel id="demo-simple-select-outlined-label">
                                                            {this.props.language !== "eng" ? "開始時間" : "Start"}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            defaultValue={moment(this.state.originalOccupancyDetails.start_time).format('HH:mm')}
                                                            onChange={this.handleStringTimeInput}
                                                        >
                                                            {this.state.newClassStartingTimeList.map((time: any) =>
                                                                <MenuItem value={time}>{time}</MenuItem>
                                                            )}


                                                        </Select>
                                                    </FormControl>
                                                    <FormControl className="selectInputs newOccupancyInputLeft">
                                                        <InputLabel id="demo-simple-select-outlined-label">
                                                            {this.props.language !== "eng" ? "佔用時長" : "Duration"}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={this.state.newClassDuration}
                                                            onChange={this.handleDurationInput}
                                                        >
                                                            <MenuItem value={'-'}> - </MenuItem>
                                                            {this.state.newClassDurationList.map((duration: any) =>
                                                                <MenuItem value={duration}>{duration}</MenuItem>
                                                            )}


                                                        </Select>
                                                    </FormControl>
                                                    <FormControl className="selectInputs newOccupancyInputRight">
                                                        <InputLabel id="demo-simple-select-outlined-label">
                                                            {this.props.language !== "eng" ? "運動種類" : "Sport"}
                                                        </InputLabel>
                                                        {
                                                            this.props.groupClassCustomerParticipationInfos.length === 0 ?

                                                                <Select
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="demo-simple-select-outlined"
                                                                    defaultValue={this.state.originalOccupancyDetails.genre_id}
                                                                    onChange={this.handleGenreInput}
                                                                >
                                                                    {this.props.genreTypeLists.map((genre: any) =>
                                                                        <MenuItem value={genre.id}>{genre.genre}</MenuItem>
                                                                    )}


                                                                </Select>
                                                                :
                                                                <Select
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="demo-simple-select-outlined"
                                                                    defaultValue={this.state.originalOccupancyDetails.genre_id}
                                                                    onChange={this.handleGenreInput}
                                                                    disabled
                                                                >
                                                                    {this.props.genreTypeLists.map((genre: any) =>
                                                                        <MenuItem value={genre.id}>{genre.genre}</MenuItem>
                                                                    )}


                                                                </Select>
                                                        }
                                                    </FormControl>
                                                    <FormControl className="selectInputs newOccupancyInputLeft">
                                                        <InputLabel id="demo-simple-select-outlined-label">
                                                            {this.props.language !== "eng" ? "年齡組別" : "Age Group"}
                                                        </InputLabel>
                                                        {
                                                            this.props.groupClassCustomerParticipationInfos.length === 0 ?
                                                                <Select
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="demo-simple-select-outlined"
                                                                    defaultValue={this.state.originalOccupancyDetails.age_group_id}
                                                                    onChange={this.handleAgeGroupInput}
                                                                >
                                                                    {this.props.ageGroupLists.map((ageGroup: any) =>
                                                                        <MenuItem value={ageGroup.id}>{ageGroup.age_group}</MenuItem>
                                                                    )}

                                                                </Select>
                                                                :
                                                                <Select
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="demo-simple-select-outlined"
                                                                    defaultValue={this.state.originalOccupancyDetails.age_group_id}
                                                                    onChange={this.handleAgeGroupInput}
                                                                    disabled
                                                                >
                                                                    {this.props.ageGroupLists.map((ageGroup: any) =>
                                                                        <MenuItem value={ageGroup.id}>{ageGroup.age_group}</MenuItem>
                                                                    )}

                                                                </Select>
                                                        }
                                                    </FormControl>
                                                    <FormControl className="selectInputs newOccupancyInputRight">
                                                        <InputLabel id="demo-simple-select-outlined-label">
                                                            {this.props.language !== "eng" ? "導師" : "Tutor"}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            defaultValue={this.state.originalTutorList.tutor_id}
                                                            onChange={this.handleTutorInput}
                                                        >
                                                            {!this.state.newClassTutorList ? null :
                                                                this.state.newClassTutorList.map((tutor: any) =>
                                                                    <MenuItem value={tutor.tutor_id}>{tutor.name_eng}</MenuItem>
                                                                )}

                                                        </Select>

                                                    </FormControl>
                                                    <FormControl className="selectInputs ">
                                                        {this.props.language !== "eng" ?
                                                            <TextField type="number" label="課堂人數限制（如適用）" defaultValue={this.state.originalOccupancyDetails.capacity === null ? "" : this.state.originalOccupancyDetails.capacity} onChange={this.handleCapacityInput} />
                                                            :
                                                            <TextField type="number" label="Room Capacity (Optional)" defaultValue={this.state.originalOccupancyDetails.capacity === null ? "" : this.state.originalOccupancyDetails.capacity} onChange={this.handleCapacityInput} />
                                                        }
                                                    </FormControl>
                                                </div>
                                                <div className="col-1"></div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 sectionAligner">
                                                    <Button id="sectionDivider" variant="contained" color="default" startIcon={<EditIcon />} onClick={this.handleEditClick}>{this.props.language !== "eng" ? "儲存佔用詳情" : "Save Occupancy Details"}</Button>
                                                </div>
                                            </div>



                                        </div>
                                        :
                                        thisOccupancyInfo.room_rental ?
                                            //FOR ROOM RENTAL
                                            <div className="col-3" style={{ height: '80vh' }}>

                                                <div className="row">
                                                    <div className="col-12 sectionAligner">
                                                        <div className="sectionHeader">
                                                            {this.props.language !== "eng" ? "課室佔用詳情" : "Occupancy Details"}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-1"></div>
                                                    <div className="col-10">
                                                        <div className="detailsCard" style={{ background: `#000000` }}>
                                                            <div className="details">
                                                                <div>
                                                                    <div className="detailsDate">
                                                                        {/* <div className="detailsMonthContent">{moment(`${thisOccupancyInfo.start_time}`).format('MMM')}</div> */}
                                                                        <div className="detailsDateContent">{moment(`${thisOccupancyInfo.start_time}`).format('DD')}</div>
                                                                        <div className="detailsDay" >{moment(`${thisOccupancyInfo.start_time}`).format('ddd')}</div>
                                                                    </div>
                                                                    <div className="detailsRoomContent">
                                                                        <div className="detailsStartContent">{(this.props.classroomList.find((room: any) => room.id === thisOccupancyInfo.classroom_id)).classroom}</div>

                                                                        <div className="detailsStartContent">{moment(`${thisOccupancyInfo.start_time}`).format('HH:mm')}  - {moment(`${thisOccupancyInfo.end_time}`).format('HH:mm')}</div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="detailsGenreContent">
                                                                        {this.props.language !== "eng" ? "課室租用" : "Rental"}
                                                                    </div>
                                                                    <div className="detailsAgeGroupContent">
                                                                        --
                                                            </div>
                                                                </div>
                                                                <div className="detailsTutorContent" style={{ color: `#000000` }}>
                                                                    {this.props.language !== "eng" ? "顧客 :" : "Customer :"}#{thisOccupancyInfo.customer_id} {(this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === thisOccupancyInfo.customer_id)).name_eng ? (this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === thisOccupancyInfo.customer_id)).name_eng : (this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === thisOccupancyInfo.customer_id)).name_chi}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-1"></div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-12 sectionAligner">

                                                        <Button variant="contained" id="editOccupancyBtn" startIcon={<EditIcon />} onClick={this.handleEditClick}>{this.props.language !== "eng" ? "更改佔用詳情" : "Edit Occupancy"}</Button>

                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12 sectionAligner">

                                                        <Button variant="contained" id="cxlOccupancyBtn" startIcon={<CancelPresentationIcon />} onClick={this.handleHideClick}>{this.props.language !== "eng" ? "取消佔用時段" : "Cxl Occupancy"}</Button>

                                                    </div>
                                                </div>

                                            </div>
                                            :
                                            thisOccupancyInfo.private_class ?
                                                //FOR PRIVATE CLASS
                                                <div className="col-3" style={{ height: '80vh' }}>

                                                    <div className="row">
                                                        <div className="col-12 sectionAligner">
                                                            <div className="sectionHeader">
                                                                {this.props.language !== "eng" ? "課室佔用詳情" : "Occupancy Details"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-1"></div>
                                                        <div className="col-10">
                                                            <div className="detailsCard" style={{ background: `#555555` }}>
                                                                <div className="details">
                                                                    <div>
                                                                        <div className="detailsDate">
                                                                            {/* <div className="detailsMonthContent">{moment(`${thisOccupancyInfo.start_time}`).format('MMM')}</div> */}
                                                                            <div className="detailsDateContent">{moment(`${thisOccupancyInfo.start_time}`).format('DD')}</div>
                                                                            <div className="detailsDay" >{moment(`${thisOccupancyInfo.start_time}`).format('ddd')}</div>
                                                                        </div>
                                                                        <div className="detailsRoomContent">
                                                                            <div className="detailsStartContent">{(this.props.classroomList.find((room: any) => room.id === thisOccupancyInfo.classroom_id)).classroom}</div>

                                                                            <div className="detailsStartContent">{moment(`${thisOccupancyInfo.start_time}`).format('HH:mm')}  - {moment(`${thisOccupancyInfo.end_time}`).format('HH:mm')}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="detailsGenreContent">
                                                                            {this.props.language !== "eng" ? "私人課堂" : "Private"}
                                                                        </div>
                                                                        <div className="detailsAgeGroupContent">
                                                                            --
                                                            </div>
                                                                    </div>
                                                                    <div className="detailsTutorContent" style={{ color: `#555555` }}>
                                                                        {this.props.language !== "eng" ? "導師 :" : "Tutor :"}{(this.props.tutorInfos.find((tutor: any) => tutor.tutor_id === thisOccupancyTutorId)).name_eng}

                                                                    </div>
                                                                    <div className="detailsTutorContent" style={{ color: `#555555` }}>
                                                                        {this.props.language !== "eng" ? "顧客 :" : "Customer :"}{(this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === thisOccupancyInfo.customer_id)).name_eng ? (this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === thisOccupancyInfo.customer_id)).name_eng : (this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === thisOccupancyInfo.customer_id)).name_chi}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-1"></div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-12 sectionAligner">

                                                            <Button variant="contained" id="editOccupancyBtn" startIcon={<EditIcon />} onClick={this.handleEditClick}>{this.props.language !== "eng" ? "更改佔用詳情" : "Edit Occupancy"}</Button>

                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-12 sectionAligner">

                                                            <Button variant="contained" id="cxlOccupancyBtn" startIcon={<CancelPresentationIcon />} onClick={this.handleHideClick}>{this.props.language !== "eng" ? "取消佔用時段" : "Cxl Occupancy"}</Button>

                                                        </div>
                                                    </div>

                                                </div>

                                                :
                                                //FOR CLASS OCCUPANCY
                                                <div className="col-3" style={{ height: '80vh' }}>

                                                    <div className="row">
                                                        <div className="col-12 sectionAligner">
                                                            <div className="sectionHeader">
                                                                {this.props.language !== "eng" ? "課室佔用詳情" : "Occupancy Details"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-1"></div>
                                                        <div className="col-10">
                                                            <div className="detailsCard" style={{ background: `${(this.props.genreTypeLists.find((genre: any) => genre.id === thisOccupancyInfo.genre_id)).represent_color}` }}>
                                                                <div className="details">
                                                                    <div>
                                                                        <div className="detailsDate">
                                                                            {/* <div className="detailsMonthContent">{moment(`${thisOccupancyInfo.start_time}`).format('MMM')}</div> */}
                                                                            <div className="detailsDateContent">{moment(`${thisOccupancyInfo.start_time}`).format('DD')}</div>
                                                                            <div className="detailsDay" >{moment(`${thisOccupancyInfo.start_time}`).format('ddd')}</div>
                                                                        </div>
                                                                        <div className="detailsRoomContent">
                                                                            <div className="detailsStartContent">{(this.props.classroomList.find((room: any) => room.id === thisOccupancyInfo.classroom_id)).classroom}</div>

                                                                            <div className="detailsStartContent">{moment(`${thisOccupancyInfo.start_time}`).format('HH:mm')}  - {moment(`${thisOccupancyInfo.end_time}`).format('HH:mm')}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="detailsGenreContent">
                                                                            {(this.props.genreTypeLists.find((genre: any) => genre.id === thisOccupancyInfo.genre_id)).genre}
                                                                        </div>
                                                                        <div className="detailsAgeGroupContent">
                                                                            {(this.props.ageGroupLists.find((ageGroup: any) => ageGroup.id === thisOccupancyInfo.age_group_id)).age_group}
                                                                        </div>
                                                                    </div>
                                                                    <div className="detailsTutorContent" style={{ color: `${(this.props.genreTypeLists.find((genre: any) => genre.id === thisOccupancyInfo.genre_id)).represent_color}` }}>
                                                                        {this.props.language !== "eng" ? "導師 :" : "Tutor :"}{(this.props.tutorInfos.find((tutor: any) => tutor.tutor_id === thisOccupancyTutorId)).name_eng}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-1"></div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-12 sectionAligner">
                                                            <Button id="editOccupancyBtn" variant="contained" color="default" startIcon={<EditIcon />} onClick={this.handleEditClick}>{this.props.language !== "eng" ? "更改佔用詳情" : "Edit Occupancy"}</Button>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-12 sectionAligner">

                                                            {this.props.groupClassCustomerParticipationInfos.find((participation: any) => participation.absent === false) ?
                                                                <Button variant="contained" id="cxlOccupancyBtn2" disabled startIcon={<CancelPresentationIcon />} onClick={this.handleHideClick}>{this.props.language !== "eng" ? "取消佔用時段" : "Cxl Occupancy"}</Button>
                                                                :
                                                                <Button variant="contained" id="cxlOccupancyBtn" startIcon={<CancelPresentationIcon />} onClick={this.handleHideClick}>{this.props.language !== "eng" ? "取消佔用時段" : "Cxl Occupancy"}</Button>

                                                            }

                                                        </div>
                                                    </div>

                                                </div>
                                    }

                                    <div className="col-9 leftDivider">
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                <CloseIcon id="exitBtn" onClick={this.props.handleExit} />

                                                <div className="sectionHeader">
                                                    {this.props.language !== "eng" ? "預約詳情" : "Booking Details"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {this.props.repeatedUSGMatchWithSubmittedOccupancyNewDetails.length === 0 ? null : <IUpdateUSGConfirmationBox duplicatedLatestSchedulingGroup={this.props.repeatedUSGMatchWithSubmittedOccupancyNewDetails[0]} handleDecline={this.handleUSGConfirmationBoxDecline} handleProceedWithUSG={this.handleUSGConfirmationBoxProceedWithUSG} handleProceedWithNewUSG={this.handleUSGConfirmationBoxProceedWithNewUSG} />}
                                        </div>
                                        {thisOccupancyInfo.room_rental ?
                                            null :
                                            //DISPLAY ONLY FOR GROUP CLASS OCCUPANCY
                                            <div className="row">
                                                <div className="col-12">
                                                    <div>
                                                        <div className="col-6 functionBarBtnInlineOnRoomOccupancyIndividualBox functionBarRow">
                                                            <div className="flexerOnRoomOccupancyIndividualBox">
                                                                <div className="inlineDisplay">
                                                                    <div className="bookingCount">
                                                                        <BookmarkIcon />
                                                                        <div className="wordingBorder">{this.props.language !== "eng" ? "預約" : "Booking"}：{numberOfCustomerBooking} {this.props.language !== "eng" ? "人" : ""}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="inlineDisplay">
                                                                    <div className="bookingCount">
                                                                        <BookmarkIcon />
                                                                        <div className="wordingBorder">{this.props.language !== "eng" ? "容量" : "CAP"}：{thisOccupancyInfo.capacity ? thisOccupancyInfo.capacity : (this.props.classroomList.find((room: any) => room.id === thisOccupancyInfo.classroom_id)).capacity} {this.props.language !== "eng" ? "人" : ""}</div>
                                                                    </div>
                                                                </div>
                                                                {thisOccupancyInfo.mark_attendance_for_group_class ?
                                                                    <div className="inlineDisplay">
                                                                        <div className="bookingCount">
                                                                            <DoneIcon />
                                                                            <div className="wordingBorder">{this.props.language !== "eng" ? "出席" : "Attend"}：{numberOfCustomerPresent} {this.props.language !== "eng" ? "人" : ""}</div>
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                                }
                                                                {thisOccupancyInfo.mark_attendance_for_group_class ?
                                                                    <div className="inlineDisplay">
                                                                        <div className="bookingCount">
                                                                            <TextsmsIcon />
                                                                            <div className="wordingBorder">{this.props.language !== "eng" ? "請假" : "Leave"}：{numberOfCustomerLeave} {this.props.language !== "eng" ? "人" : ""}</div>
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-2 functionBarBtnInlineOnRoomOccupancyIndividualBox">
                                                            <div className="inlineDisplay w-100">
                                                                <div className="bookingCount">
                                                                    <Button id="newBookingBtn" startIcon={<AddIcon />} onClick={this.handleShowAddBookingWindowClick}>{this.props.language !== "eng" ? "新增預約" : "New Booking"}</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-2 functionBarBtnInlineOnRoomOccupancyIndividualBox">
                                                            <div className="inlineDisplay w-100">
                                                                <div className="bookingCount">
                                                                    {
                                                                        !thisOccupancyInfo.private_class ?
                                                                            <Button id="newBookingBtn" startIcon={<CreateIcon />} onClick={this.handleShowAttendanceWindowClick}>{this.props.language !== "eng" ? "課堂點名" : "Mark Attendance"}</Button>
                                                                            :
                                                                            <Button id="newBookingBtn" startIcon={<CreateIcon />} onClick={this.handleShowAttendanceWindowClick} disabled>{this.props.language !== "eng" ? "課堂點名" : "Mark Attendance"}</Button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-2 functionBarBtnInlineOnRoomOccupancyIndividualBox">
                                                            <div className="inlineDisplay w-100">
                                                                <div className="bookingCount">
                                                                    {
                                                                        !thisOccupancyInfo.private_class ?
                                                                            <Button id="newBookingBtn" startIcon={<PrintIcon />} onClick={this.handlePrintAttnList}>{this.props.language !== "eng" ? "列印點名表" : "Print Attendance Sheet"}</Button>
                                                                            :
                                                                            <Button id="newBookingBtn" startIcon={<PrintIcon />} onClick={this.handlePrintAttnList} disabled>{this.props.language !== "eng" ? "列印點名表" : "Print Attendance Sheet"}</Button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        }
                                        <div className="fixedHeightScrollingArea">
                                            {this.state.showAddBookingWindow ? <IAddNewBookingWindow allSites={this.props.allSites} roomOccupancyDetails={thisOccupancyInfo} occupancyTutorList={this.props.tutorList.find((tutor: any) => tutor.room_occupancy_detail_id === this.props.selectedOccupancyId)} occupancyParticipationDetails={this.props.groupClassCustomerParticipationInfos} handleCloseClick={this.handleShowAddBookingWindowClick} handleExitClick={this.handleExitFromAddNewBookingWindow} /> : null}
                                            {this.state.showAttendanceWindow ? <IAttendanceWindow customerBookingCount={numberOfCustomerBooking} classParticipantsDetails={this.props.groupClassCustomerParticipationInfos} customerInfos={this.props.relatedCustomerInfos} relatedPackageInfo={this.props.relatedCustomerPackagesInfos} handleCloseClick={this.handleShowAttendanceWindowClick} handleExitClick={this.handleExitFromAddNewBookingWindow} /> : null}

                                            {
                                                !thisOccupancyInfo.private_class ? null :
                                                    <div className="participationRow4">
                                                        {
                                                            (this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === thisOccupancyInfo.customer_id)).payments_clear ?
                                                                <div className="iconRow"><FaceIcon id="customerParticipationIcon" /></div>
                                                                :
                                                                <div className="iconRow"><FaceIcon id="customerParticipationYellowIcon" /></div>
                                                        }

                                                        <div className="inlineDisplay customerIDInParticipationBox" ># {(this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === thisOccupancyInfo.customer_id)).id}</div>

                                                        <div className=" inlineDisplay customerNameInParticipationBox"> <div>{this.props.language !== "eng" ? "顧客 :" : "Customer :"}{(this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === thisOccupancyInfo.customer_id)).name_eng ? (this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === thisOccupancyInfo.customer_id)).name_eng : (this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === thisOccupancyInfo.customer_id)).name_chi} </div></div>
                                                        {
                                                            <div className="inlineDisplay customerPackageOfPrivateClassInParticipationBox"> <div>{this.props.language !== "eng" ? "使用套券" : "Package"}：{(this.props.relatedCustomerPackagesInfos.length === 0 ? null : (this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === privateRentalPackageID)).package_name)} &nbsp; &nbsp; &nbsp; {(this.props.relatedCustomerPackagesInfos.length === 0 ? null : (this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === privateRentalPackageID)).token_based_package ? (this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === privateRentalPackageID)).at_token : null)}</div></div>
                                                        }
                                                        {
                                                            thisOccupancyInfo.mark_attendance_for_group_class ?
                                                                <div className="inlineDisplay attnDisplayOnParticipationBox">
                                                                    <div className="customerAttendanceDisplay"> <RemoveIcon /><div>{this.props.language !== "eng" ? "未點名" : "-"} </div> </div>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                            }
                                            {this.props.groupClassCustomerParticipationInfos.length === 0 ?
                                                null :
                                                //GROUP CLASS OCCUPANCY DISPLAY
                                                this.props.groupClassCustomerParticipationInfos.map((participation: any) =>
                                                    participation.absent === null ?
                                                        <div className="participationRow1">
                                                            <div className="cxlClassBookingIcon" onClick={(e) => this.handleCxlParticipationClick(e, participation)}>
                                                                <CancelIcon />
                                                            </div>
                                                            {
                                                                participation.no_token_booking ?
                                                                    <div className="iconRow"><FaceIcon id="customerParticipationYellowIcon" /></div>
                                                                    :
                                                                    (this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).payments_clear ?
                                                                        <div className="iconRow"><FaceIcon id="customerParticipationIcon" /></div>
                                                                        :
                                                                        <div className="iconRow"><FaceIcon id="customerParticipationYellowIcon" /></div>
                                                            }

                                                            <div className="inlineDisplay customerIDInParticipationBox" ># {(this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).id}</div>

                                                            <div className=" inlineDisplay customerNameInParticipationBox"> <div>{this.props.language !== "eng" ? "顧客 :" : "Customer :"}{(this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).name_eng ? (this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).name_eng : (this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).name_chi} </div></div>
                                                            {
                                                                participation.no_token_booking ?
                                                                    <div className="inlineDisplay customerPackageInParticipationBox"> <div>{this.props.language !== "eng" ? "使用套券：無套劵預約" : "Package : Pre-Booking"}</div> </div>
                                                                    :
                                                                    thisOccupancyInfo.private_class ?
                                                                        <div className="inlineDisplay customerPackageOfPrivateClassInParticipationBox"> <div>{this.props.language !== "eng" ? "使用套券" : "Package"}：{(this.props.relatedCustomerPackagesInfos.length === 0 ? null : (this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === privateRentalPackageID)).package_name)} &nbsp; &nbsp; &nbsp; {(this.props.relatedCustomerPackagesInfos.length === 0 ? null : (this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === privateRentalPackageID)).token_based_package ? (this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === privateRentalPackageID)).at_token : null)}</div></div>

                                                                        // <div className="inlineDisplay customerPackageOfPrivateClassInParticipationBox"> <div>使用套券：{(this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === participation.customer_package_id)).package_name} &nbsp; &nbsp; &nbsp; {(this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === participation.customer_package_id)).token_based_package ? (this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === participation.customer_package_id)).at_token : null}</div></div>
                                                                        :
                                                                        <div className="inlineDisplay customerPackageInParticipationBox"> <div>{this.props.language !== "eng" ? "使用套券" : "Package"}：{(this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === participation.customer_package_id)).package_name} &nbsp; &nbsp; &nbsp; {(this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === participation.customer_package_id)).token_based_package ? (this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === participation.customer_package_id)).at_token : null}</div></div>

                                                            }
                                                            {
                                                                thisOccupancyInfo.mark_attendance_for_group_class ?
                                                                    <div className="inlineDisplay attnDisplayOnParticipationBox">
                                                                        <div className="customerAttendanceDisplay"> <RemoveIcon /><div> {this.props.language !== "eng" ? "未點名" : "-"} </div> </div>
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                        :
                                                        participation.absent ?
                                                            participation.absent_reason_id ?

                                                                null
                                                                :
                                                                <div className="participationRow4">

                                                                    {
                                                                        participation.no_token_booking ?
                                                                            <div className="iconRow"><FaceIcon id="customerParticipationYellowIcon" /></div>
                                                                            :
                                                                            (this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).payments_clear ?
                                                                                <div className="iconRow"><FaceIcon id="customerParticipationIcon" /></div>
                                                                                :
                                                                                <div className="iconRow"><FaceIcon id="customerParticipationYellowIcon" /></div>
                                                                    }

                                                                    <div className="inlineDisplay customerIDInParticipationBox" ># {(this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).id}</div>

                                                                    <div className=" inlineDisplay customerNameInParticipationBox"> <div>{this.props.language !== "eng" ? "顧客 :" : "Customer :"}{(this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).name_eng ? (this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).name_eng : (this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).name_chi} </div></div>
                                                                    {
                                                                        participation.no_token_booking ?
                                                                            <div className="inlineDisplay customerPackageInParticipationBox"> <div>{this.props.language !== "eng" ? "使用套券：無套劵預約" : "Package : Pre-Booking"}</div> </div>
                                                                            :
                                                                            <div className="inlineDisplay customerPackageInParticipationBox"> <div>{this.props.language !== "eng" ? "使用套券" : "Package"}：{(this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === participation.customer_package_id)).package_name} &nbsp; &nbsp; &nbsp; {(this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === participation.customer_package_id)).token_based_package ? (this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === participation.customer_package_id)).at_token : null}</div></div>
                                                                    }
                                                                    {
                                                                        thisOccupancyInfo.mark_attendance_for_group_class ?
                                                                            <div className="inlineDisplay attnDisplayOnParticipationBox">
                                                                                <div className="customerAttendanceDisplay"> <CloseIcon /><div> {this.props.language !== "eng" ? "已缺席" : "Absent"} </div> </div>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>

                                                            :
                                                            <div className="participationRow3">

                                                                {
                                                                    participation.no_token_booking ?
                                                                        <div className="iconRow"><FaceIcon id="customerParticipationYellowIcon" /></div>
                                                                        :
                                                                        (this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).payments_clear ?
                                                                            <div className="iconRow"><FaceIcon id="customerParticipationIcon" /></div>
                                                                            :
                                                                            <div className="iconRow"><FaceIcon id="customerParticipationYellowIcon" /></div>
                                                                }

                                                                <div className="inlineDisplay customerIDInParticipationBox" ># {(this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).id}</div>

                                                                <div className=" inlineDisplay customerNameInParticipationBox"> <div>{this.props.language !== "eng" ? "顧客 :" : "Customer :"}{(this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).name_eng ? (this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).name_eng : (this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).name_chi} </div></div>
                                                                {
                                                                    participation.no_token_booking ?
                                                                        <div className="inlineDisplay customerPackageInParticipationBox"> <div>{this.props.language !== "eng" ? "使用套券：無套劵預約" : "Package : Pre-Booking"}</div> </div>
                                                                        :
                                                                        <div className="inlineDisplay customerPackageInParticipationBox"> <div>{this.props.language !== "eng" ? "使用套券" : "Package"}：{(this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === participation.customer_package_id)).package_name} &nbsp; &nbsp; &nbsp; {(this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === participation.customer_package_id)).token_based_package ? (this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === participation.customer_package_id)).at_token : null}</div></div>
                                                                }
                                                                {
                                                                    thisOccupancyInfo.mark_attendance_for_group_class ?
                                                                        <div className="inlineDisplay attnDisplayOnParticipationBox">
                                                                            <div className="customerAttendanceDisplay"> <DoneIcon /><div> {this.props.language !== "eng" ? "已出席" : "Present"} </div> </div>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>

                                                )
                                            }
                                            {
                                                this.props.groupClassCustomerParticipationInfos.length === 0 ?
                                                    null :
                                                    this.props.groupClassCustomerParticipationInfos.map((participation: any) =>
                                                        participation.absent && participation.absent_reason_id ?
                                                            <div className="participationRow2">
                                                                <div className="cxlClassBookingIcon" onClick={() => this.handleBackwardCxl(participation)}>
                                                                    <AutorenewIcon />
                                                                </div>
                                                                {
                                                                    participation.no_token_booking ?
                                                                        <div className="iconRow"><FaceIcon id="customerParticipationYellowIcon" /></div>
                                                                        :
                                                                        (this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).payments_clear ?
                                                                            <div className="iconRow"><FaceIcon id="customerParticipationIcon" /></div>
                                                                            :
                                                                            <div className="iconRow"><FaceIcon id="customerParticipationYellowIcon" /></div>
                                                                }

                                                                <div className="inlineDisplay customerIDInParticipationBox" ># {(this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).id}</div>

                                                                <div className=" inlineDisplay customerNameInParticipationBox"> <div>{this.props.language !== "eng" ? "顧客 :" : "Customer :"}{(this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).name_eng ? (this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).name_eng : (this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).name_chi} </div></div>
                                                                {
                                                                    participation.no_token_booking ?
                                                                        <div className="inlineDisplay customerPackageInParticipationBox"> <div>{this.props.language !== "eng" ? "使用套券：無套劵預約" : "Package : Pre-Booking"}</div> </div>
                                                                        :
                                                                        <div className="inlineDisplay customerPackageInParticipationBox"> <div>{this.props.language !== "eng" ? "使用套券" : "Package"}：{(this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === participation.customer_package_id)).package_name} &nbsp; &nbsp; &nbsp;{(this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === participation.customer_package_id)).token_based_package ? (this.props.relatedCustomerPackagesInfos.find((customerPackage: any) => customerPackage.id === participation.customer_package_id)).at_token : null}</div></div>
                                                                }

                                                                <div className="inlineDisplay attnDisplayOnParticipationBox">
                                                                    <div className="customerAttendanceDisplay"> <RemoveIcon /><div> {this.props.language !== "eng" ? "已請假" : "Leave"} : {(this.props.absentReasons.find((reason: any) => reason.id === participation.absent_reason_id)).absent_reason}</div> </div>
                                                                </div>

                                                            </div>

                                                            : null
                                                    )
                                            }
                                        </div>
                                    </div>
                                    {/* <div className="col-5 leftDivider">
                                <div className="row">
                                    <div className="col-12 sectionAligner">
                                        <div className="sectionHeader">
                                            Documentation Information
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                                </div>
                        }
                    </div>

                </div>
                {/* {this.state.showEditBox ? null : <div className="topClass" onClick={this.props.handleExit}></div>} */}
                <div className="topClass" onClick={this.props.handleExit}>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        language: state.auth.language,
        allOccupancyInfo: state.schedule.occupancyOfTheShowingDate,
        genreTypeLists: state.schedule.genreTypeList,
        ageGroupLists: state.schedule.ageGroupList,
        classroomList: state.schedule.classroomList,
        roomRentingPrivateCustomerList: state.schedule.roomRentingPrivateCustomerList,
        occupancyOfTheDay: state.schedule.occupancyOfTheShowingDate,
        openingHourList: state.schedule.openingTimeList,
        submittedEditingOccupancyNewDetails: state.roomOccupancyIndividualBox.submittedEditingOccupancyNewDetails,
        repeatedUSGMatchWithSubmittedOccupancyNewDetails: state.roomOccupancyIndividualBox.repeatedUSGMatchWithSubmittedOccupancyNewDetails,
        groupClassCustomerParticipationInfos: state.roomOccupancyIndividualBox.groupClassCustomerParticipationInfos,
        relatedCustomerPackagesInfos: state.roomOccupancyIndividualBox.relatedCustomerPackagesInfos,
        relatedCustomerInfos: state.roomOccupancyIndividualBox.relatedCustomerInfos,
        absentReasons: state.roomOccupancyIndividualBox.absentReasons,

    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        handleCxlClassOccupancy: async (data: any) => dispatch(await delThisRoomOccupancy(data)),
        saveEditedClassOccupancy: async (editedOccupancy: any) => dispatch(await updateClassOccupancyAndTutorList(editedOccupancy)),
        handleNewUSGConfirmation: async (editedOccupancyDetails: any, newUSGDetails: any) => dispatch(await updateClassOccupancyAndTutorListWithNewUSG(editedOccupancyDetails, newUSGDetails)),
        handleUseUSGConfirmation: async (editedOccupancyDetails: any, newUSGDetails: any) => dispatch(await updateClassOccupancyAndTutorListWithUSG(editedOccupancyDetails, newUSGDetails)),
        handleDeclineUSGConfirmation: () => dispatch(handleUSGConfirmationBoxDeclineClick()),
        handleReloadParticipationRelatedInformation: async (roomOccupancyID: number) => dispatch(await loadOccupancyRelatedParticipationInformation(roomOccupancyID)),
        handleCxlParticipationBooking: async (participationDetails: any, absentReasonID: number, staffUserID: number) => dispatch(await updateParticipationDetailsWithAcceptableCxlReason(participationDetails, absentReasonID, staffUserID)),
        handleCxlPrivateRentalOccupancyBookingWithReason: async (occupancyDetails: any) => dispatch(await delRentalPrivateOccupancyBookingWithReason(occupancyDetails)),
        handleCxlParticipationByStaffNoReason: async (participationDetails: any) => dispatch(await delParticipationWithoutReasonByStaff(participationDetails)),
        handleCxlParticipationBookingBackward: async (participationDetails: any) => dispatch(await updateParticipationDetailsWithAcceptableCxlReasonBackward(participationDetails)),
        handleResetComponent: () => dispatch(resetRoomOccupancyIndividualBox())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoomOccupancyIndividualBox);

function vw(v: any) {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return (v * w) / 100;
}

function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};