


export function allStaffsLoaded (staffDetails:any){
    return{
        type: "ALL_STAFFS_LOADED" as "ALL_STAFFS_LOADED",
        staffDetails
    }
}
export function pageInitInformationLoaded (employmentStatuses:any , allSites:any, staffDetails:any){
    return {
        type: "PAGE_INIT_INFO_LOADED" as "PAGE_INIT_INFO_LOADED",
        employmentStatuses,
        allSites,
        staffDetails
    }
}
export function newStaffDetailsCreated () {
    return {
        type: "NEW_STAFF_DETAILS_CREATED" as "NEW_STAFF_DETAILS_CREATED",
    }
}

export function staffDetailsSaved () {
    return {
        type: "STAFF_DETAILS_SAVED" as "STAFF_DETAILS_SAVED"
    }
}

export function staffLoginDetailsReset () {
    return {
        type: "STAFF_LOGIN_DETAILS_RESET" as "STAFF_LOGIN_DETAILS_RESET"
    }
}


type StaffAccManageActionsCreators = typeof allStaffsLoaded |
                                    typeof pageInitInformationLoaded |
                                    typeof newStaffDetailsCreated |
                                    typeof staffDetailsSaved |
                                    typeof staffLoginDetailsReset;

export type IStaffAccManageActions = ReturnType<StaffAccManageActionsCreators>;