import React from "react";
import './DisplayCxlOccupancyWindow.css'
import { Button, FormControl, InputLabel, Select, MenuItem, TextField } from "@material-ui/core";
import { IRootState, ThunkDispatch } from "../../store";
import { connect } from "react-redux";
import CloseIcon from '@material-ui/icons/Close';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import moment from "moment";
import { loadOccupancyRelatedParticipationInformation } from "./thunks";
import PersonIcon from '@material-ui/icons/Person';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';



export interface IDisplayCxlOccupancyWindowProps {
    language: string,
    cxlOccupancyOfTheDay: any,
    classroomList: any,
    tutorInfos: any,
    tutorListsForCxl: any,
    groupClassCustomerParticipationInfos: any[],
    relatedCustomerPackagesInfos: any[],
    relatedCustomerInfos: any[],
    absentReasons: any[]
    handleCloseClick: () => void,
    handleLoadSelectedOccupancyDetails: (occupancyID: number) => void
}

export interface IDisplayCxlOccupancyWindowState {
    selectedOccupancyID: number

}

class IDisplayCxlOccupancyWindow extends React.Component<IDisplayCxlOccupancyWindowProps, IDisplayCxlOccupancyWindowState> {
    constructor(props: IDisplayCxlOccupancyWindowProps) {
        super(props);
        this.state = {
            selectedOccupancyID: 0
        }
    }

    private handleCloseClick = () => {
        this.props.handleCloseClick();
    }

    private handleOccupancyClick = async (occupancyID: number) => {

        await this.props.handleLoadSelectedOccupancyDetails(occupancyID);
        await this.setState({
            selectedOccupancyID: occupancyID
        })
    }
    private handleExitOccupancyListClick = async () => {
        await this.setState({
            selectedOccupancyID: 0
        })
    }



    render() {
        let selectedOccupancyDetails = this.props.cxlOccupancyOfTheDay.find((occupancy:any)=> occupancy.id === this.state.selectedOccupancyID)

        return (

            <div className="baseAreaOfDisplayCxlOccupancyWindow">
                <div className="navDisplayRow">
                    <div className="inlineDisplay">
                        <h3 className="addNewBookingWindowTitle">{this.props.language !== "eng" ? "已取消的課室佔用時段" : "Cxl Room Occupancies"}</h3>
                        <div className="subtitle">{this.props.language !== "eng" ? `- 共 ${this.props.cxlOccupancyOfTheDay.length} 項` : `- ${this.props.cxlOccupancyOfTheDay.length} Class -`}</div>
                        {
                            this.state.selectedOccupancyID === 0 ? null :
                                <Button onClick={this.handleExitOccupancyListClick} id="exitBtnOnCxlOccupancyCustomerListingPage" startIcon={<ArrowBackIcon />}>{this.props.language !== "eng" ? "返回時段列表" : "Back to Schedule"}</Button>
                        }
                    </div>
                    <CloseIcon id="addNewBookingWindowCloseBtn" onClick={this.handleCloseClick} />
                </div>
                {
                    this.state.selectedOccupancyID === 0 ?

                        <div className="displayAreaForCxlOccupancy">
                            <div className="titleRowOfCxlOccupancyDisplayArea">
                                <div className="occupancyID">#</div>
                                <div className="occupancyStartingTime">{this.props.language !== "eng" ? "開始時間" : "Start"}</div>
                                <div className="occupancyEndingTime">{this.props.language !== "eng" ? "結束時間" : "End"}</div>
                                <div className="occupancyRoom">{this.props.language !== "eng" ? "佔用課室" : "Room"}</div>
                                <div className="occupyType">{this.props.language !== "eng" ? "佔用類型" : "Occ. Type"}</div>
                                <div className="occupancyType">{this.props.language !== "eng" ? "佔用種類" : "Class Type"}</div>
                                <div className="occupancyTutorName">{this.props.language !== "eng" ? "關連導師" : "Tutor"}</div>
                                <div className="occupancyCustomerCount">{this.props.language !== "eng" ? "關連顧客數" : "Customer Count"}</div>
                            </div>
                            {
                                this.props.cxlOccupancyOfTheDay.map((occupancy: any) =>
                                    <div className="cxlOccupancyRow" onClick={() => this.handleOccupancyClick(occupancy.id)}>
                                        <div className="occupancyID">{occupancy.id}</div>
                                        <div className="occupancyStartingTime">{moment(occupancy.start_time).format('HH:mmA')}</div>
                                        <div className="occupancyEndingTime">{moment(occupancy.end_time).format('HH:mmA')}</div>
                                        <div className="occupancyRoom">{this.props.classroomList.find((classroom: any) => classroom.id === occupancy.classroom_id).classroom}</div>
                                        <div className="occupyType">{this.props.language !== "eng" ? "完整佔用課室" : "Individual Usage"}</div>
                                        {
                                            this.props.language !== "eng" ?
                                            <div className="occupancyType">{occupancy.private_class ? "私人課堂" : occupancy.room_rental ? "課室租用" : "一般課堂"}</div>
                                            :
                                            <div className="occupancyType">{occupancy.private_class ? "Private" : occupancy.room_rental ? "Rental" : "Normal"}</div>

                                        }
                                        <div className="occupancyTutorName">{occupancy.private_class || occupancy.room_rental ? "- -" : this.props.tutorInfos.find((tutor: any) => tutor.id === (this.props.tutorListsForCxl.find((targetTutorList: any) => targetTutorList.room_occupancy_detail_id === occupancy.id).tutor_id)).name_eng}</div>
                                        <div className="occupancyCustomerCount">{occupancy.private_class ? "1" : occupancy.room_rental ? "1" : occupancy.participationCount}</div>
                                    </div>
                                )
                            }
                        </div>
                        :
                        <div className="displayAreaForCxlOccupancyOnListingCustomerPage">
                            {
                                selectedOccupancyDetails.room_rental? 
                                <div className="paymentRecordRow">
                                            <div className="paymentIconContainer">
                                                <div className="paymentIconBG">
                                                    <PersonIcon id="paymentListingIcon" />

                                                </div>
                                            </div>
                                            <div className="paymentPurchaseIDContainer">
                                                <div className="paymentPurchaseID">{this.props.language !== "eng" ? "顧客" : "Customer"} - #{this.props.relatedCustomerInfos[0].id}</div>
                                                {/* <div className="paymentPurchaseIDTitle">選購編號</div> */}
                                            </div>
                                            <div className="paymentPurchaseIDContainer">
                                                <div className="paymentPurchaseID">{(this.props.relatedCustomerInfos[0].name_eng ? this.props.relatedCustomerInfos[0].name_eng : this.props.relatedCustomerInfos[0].name_chi)}</div>
                                                {/* <div className="paymentPurchaseIDTitle">收款分校 : {this.props.allSites.find((site: any) => site.id === payment.site_id).site_key}</div> */}
                                            </div>
                                            <div className="paymentCustomerNameContainer">
                                                <div className="paymentPurchaseID">{this.props.relatedCustomerPackagesInfos[0].package_name}</div>
                                                {/* <div className="paymentPurchaseIDTitle"> # {this.props.allPaymentCustomer.find((customer: any) => customer.id === payment.customer_id).id}</div> */}
                                            </div>
                                            {/* <div className="paymentPurchaseIDContainer">
                                                <div className="paymentPurchaseID">處理員工 - #{participation.last_update_staff_id}</div>
                                                <div className="paymentPurchaseIDTitle">處理員工 - #{participation.last_update_staff_id}</div>
                                            </div> */}
                                            <div className="paymentPurchaseIDContainer">
                                                {
                                                    
                                                        <div className="paymentPurchaseID"> - {this.props.language !== "eng" ? "已取消" : "Canceled"} - </div>
                                                }

                                            </div>
                                        </div>
                                :
                                this.props.groupClassCustomerParticipationInfos.length === 0 ?
                                    <div className="w-100 h-100">
                                        <div className="displayFlex">
                                            <div className="displayInline"> - {this.props.language !== "eng" ? "無牽涉顧客" : "No Customer Involved"} - </div>
                                        </div>
                                    </div>
                                    :
                                    this.props.groupClassCustomerParticipationInfos.map((participation: any) =>
                                        <div className="paymentRecordRow">
                                            <div className="paymentIconContainer">
                                                <div className="paymentIconBG">
                                                    <PersonIcon id="paymentListingIcon" />

                                                </div>
                                            </div>
                                            <div className="paymentPurchaseIDContainer">
                                                <div className="paymentPurchaseID">{this.props.language !== "eng" ? "顧客" : "Customer"} - #{participation.customer_id}</div>
                                                {/* <div className="paymentPurchaseIDTitle">選購編號</div> */}
                                            </div>
                                            <div className="paymentPurchaseIDContainer">
                                                <div className="paymentPurchaseID">{(this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).name_eng ? (this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).name_eng : (this.props.relatedCustomerInfos.find((customer: any) => customer.id === participation.customer_id)).name_chi}</div>
                                                {/* <div className="paymentPurchaseIDTitle">收款分校 : {this.props.allSites.find((site: any) => site.id === payment.site_id).site_key}</div> */}
                                            </div>
                                            <div className="paymentCustomerNameContainer">
                                                <div className="paymentPurchaseID">{this.props.relatedCustomerPackagesInfos.find((targetPackage: any) => targetPackage.customer_id === participation.customer_id).package_name}</div>
                                                {/* <div className="paymentPurchaseIDTitle"> # {this.props.allPaymentCustomer.find((customer: any) => customer.id === payment.customer_id).id}</div> */}
                                            </div>
                                            <div className="paymentPurchaseIDContainer">
                                                <div className="paymentPurchaseID">{this.props.language !== "eng" ? "處理員工" : "Staff"} - #{participation.last_update_staff_id}</div>
                                                {/* <div className="paymentPurchaseIDTitle">處理員工 - #{participation.last_update_staff_id}</div> */}
                                            </div>
                                            <div className="paymentPurchaseIDContainer">
                                                {
                                                    participation.absent_reason_id ?
                                                        <div className="paymentPurchaseID"> - {this.props.language !== "eng"? "已請假" : "Applied Leave"} - </div>
                                                        :
                                                        <div className="paymentPurchaseID"> - {this.props.language !== "eng" ? "已取消" :"Canceled"} - </div>
                                                }

                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                }

            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        language: state.auth.language,
        groupClassCustomerParticipationInfos: state.displayCxlOccupancyWindow.groupClassCustomerParticipationInfos,
        relatedCustomerPackagesInfos: state.displayCxlOccupancyWindow.relatedCustomerPackagesInfos,
        relatedCustomerInfos: state.displayCxlOccupancyWindow.relatedCustomerInfos,
        absentReasons: state.displayCxlOccupancyWindow.absentReasons
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        handleLoadSelectedOccupancyDetails: async (occupancyID: number) => dispatch(await loadOccupancyRelatedParticipationInformation(occupancyID))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IDisplayCxlOccupancyWindow);
