import React from 'react';
import { Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined'; import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import ListIcon from '@material-ui/icons/List';
import { IRootState, ThunkDispatch } from '../../store';
import { connect } from 'react-redux';
import { loadAllProductStorageSummary } from '../thunks';
import './ProductListingModule.css';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';



export interface IProductListingModuleProps {
    language: string,
    uniqueStockBasicDetails: any[],
    allAttributeTypes: any[],
    allCategoryTypes: any[],
    loading: boolean,
    purchaseBucketNum: number,
    totalPrice: number,
    loadAllProductStorageSummary: () => void,
    purchaseProductClick: (productSKU: string, productPrice: string) => void,
    redirectToBucketPreview: () => void
}

export interface IProductListingModuleState {

}

class ProductListingModule extends React.Component<IProductListingModuleProps, IProductListingModuleState> {
    constructor(props: IProductListingModuleProps) {
        super(props);
        this.state = {

        }
    }

    async componentWillMount() {
        await this.props.loadAllProductStorageSummary();

    }

    private handlePurchaseProductClick = async (productSKU: string, productPrice: string) => {
        this.props.purchaseProductClick(productSKU, productPrice);
    }

    private handleShowBucketPreview = async () => {
        this.props.redirectToBucketPreview();
    }

    render() {
        return (
            <div className="productManageAreaOnCustomerListing">
                <div className="staffListingAreaTitleRow">
                    <div className="staffListingAreaTitleOnCustomerListing">{this.props.language !== "eng"? "存倉貨品" : "Inventory"} <div className="staffListingAreaSubtitle">{this.props.language !== "eng"? `- 共 ${this.props.uniqueStockBasicDetails.length} 項` : `-Count ${this.props.uniqueStockBasicDetails.length}`}</div></div>
                    <div className="purchaseBucketArea">
                        <div className="aligner w-100 h-100">
                            <button className="bucketPresentationElements itemsInBucketDisplay " onClick={() => this.handleShowBucketPreview()}>

                                <div className="aligner">
                                    {this.props.purchaseBucketNum}
                                    <ShoppingBasketIcon id="shoppingCartIcon" />
                                    <div id="BucketSumDisplay">Total:$ {this.props.totalPrice.toFixed(2)}</div>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="searchTokenInputContainer">
                        {/* <TextField label="搜尋套劵" variant="outlined" value={this.state.searchTokenWordings} onChange={this.handleSearchTokenWordingInput} /> */}
                    </div>
                </div>
                <div className="inventoryProductTitleRow">

                    <div className="inventoryProductNameTitleOnCustomerListing">
                        <div className="productContent">{this.props.language !== "eng"? "存倉產品名稱" : "Product Name"}</div>
                    </div>
                    <div className="inventoryProductSKUOnCustomerListing">
                        <div className="productContent">SKU</div>
                    </div>
                    <div className="inventoryProductCategoryOnCustomerListing">
                        <div className="productContent">{this.props.language !== "eng"? "種類" : "Category"}</div>
                    </div>
                    <div className="inventoryProductQuantityOnCustomerListing">
                        <div className="productContent">{this.props.language !== "eng"? "存倉" : "Stocking"}</div>
                    </div>
                    <div className="inventoryProductPriceOnCustomerListing">
                        <div className="productContent">{this.props.language !== "eng"? "目前售價" : "Price"}</div>
                    </div>
                    {/* <div className="inventoryProductAttributeOnCustomerListing">
                        <div className="productContent">產品特性</div>
                    </div> */}
                    <div className="inventoryProductActionOnCustomerListing">
                        <div className="btnAligner">
                            <div className="productContent">{this.props.language !== "eng"? "客户選購" : "Action"}    </div>
                        </div>
                    </div>
                </div>

                {
                    this.props.loading ?
                        <div className="loadingContainerRow">
                            <div className="inlineDisplay">
                                <div className="loadingContainer">
                                    <CircularProgress id="loadingCircle" />
                                    <div className="loadingLabel">Loading ...</div>
                                </div>
                            </div>
                        </div>
                        :
                        this.props.uniqueStockBasicDetails.map((SKU: any) =>
                            <div className="inventoryProductRowOnCustomerListing">
                                <div className="productDetailsRowOnCustomerListing">
                                    <div className="productNameContainerOnCustomerListing">
                                        <div className="productNameAligner">
                                            <div className="productIconContainer">
                                                <div className="productIconBG">
                                                    <ListIcon id="productListingIcon" />
                                                </div>
                                            </div>
                                            {/* className="inventoryProductName" */}
                                            <div >
                                                <div className="productContent">{SKU.product_name}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inventoryProductSKUOnCustomerListing">
                                        <div className="productContent">{SKU.sku}</div>
                                    </div>
                                    <div className="inventoryProductCategoryOnCustomerListing">
                                        <div className="productContent">{(this.props.allCategoryTypes.find((category: any) => category.id === SKU.productCategoryID)).category}</div>
                                    </div>
                                    <div className="inventoryProductQuantityOnCustomerListing">
                                        <div className="productContent">{`${SKU.quantity} / ${SKU.totalBatchSize}`}</div>
                                    </div>
                                    <div className="inventoryProductPriceOnCustomerListing">
                                        <div className="productContent">{`$${SKU.marked_price}`}</div>
                                    </div>

                                    <div className="inventoryProductAction">
                                        <div className="btnAligner">
                                            {
                                                SKU.quantity > 0 ?
                                                    <Button startIcon={<ShoppingBasketOutlinedIcon />} onClick={() => this.handlePurchaseProductClick(SKU.sku, SKU.marked_price)}>{this.props.language !== "eng"? "購買" : "Purchase"}</Button>
                                                    :
                                                    <Button startIcon={<UpdateOutlinedIcon />} onClick={() => this.handlePurchaseProductClick(SKU.sku, SKU.marked_price)}>{this.props.language !== "eng"? "預訂" : "Order"}</Button>

                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="inventoryProductAttributeOnCustomerListing">
                                    {
                                        SKU.productAttributes.map((attribute: any) =>

                                            <div className="productContentInlineDisplay">{(this.props.allAttributeTypes.find((attributeType: any) => attributeType.id === attribute.attribute_id)).attribute} : <div className="productContentInlineDisplayThin">{attribute.value} </div></div>
                                        )
                                    }

                                </div>
                            </div>
                        )
                }
            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        uniqueStockBasicDetails: state.customers.uniqueStockBasicDetails,
        allAttributeTypes: state.customers.allAttributeTypes,
        allCategoryTypes: state.customers.allCategoryTypes,
        loading: state.customers.loading,
        language: state.auth.language
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        loadAllProductStorageSummary: async () => dispatch(await loadAllProductStorageSummary())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListingModule);