import { IDisplayCxlOccupancyWindowState } from "./state";
import { IDisplayCxlOccupancyWindowActions } from "./actions";
import { AccordionCollapse } from "react-bootstrap";

const initialState = {
    groupClassCustomerParticipationInfos: [],
    relatedCustomerPackagesInfos: [],
    relatedCustomerInfos: [],
    absentReasons: []
}

export const displayCxlOccupancyWindowReducer = (state: IDisplayCxlOccupancyWindowState = initialState, actions: IDisplayCxlOccupancyWindowActions) => {

    switch (actions.type) {

        case "OCCUPANCY_PARTICIPATION_RELATED_INFO_LOADED":
            return {
                ...state,
                groupClassCustomerParticipationInfos: actions.customerParticipationInfos,
                relatedCustomerPackagesInfos: actions.relatedCustomerPackagesInfos,
                relatedCustomerInfos: actions.relatedCustomerInfos,
                absentReasons: actions.absentReasons
            }
        
        default:
            return state;
    }
}