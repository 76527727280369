import { ThunkDispatch } from "../../store";

import {customerPackageUsagesHistoryRelatedDataLoaded} from './actions';

export async function loadCustomerPackageUsageHistoryRelatedData (customerID:number) {

    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customer/loadCustomerPackagesUsagesDetails?customerID=${customerID}`,{
        method: 'GET',
        headers: {
            "Content-Type" : "application/json",
            Authorization : `Bearer ${localStorage.getItem('token')}`
        }
    });

    let result = await res.json();
    console.log(result)
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch:ThunkDispatch) => {
            
            dispatch(customerPackageUsagesHistoryRelatedDataLoaded(result.result));
        }
    }


} 