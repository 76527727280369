import React from "react";
import './Systems.css';
import SitesClassroomsManage from './SitesClassroomsManage/SitesClassroomsManage'
import GroupingManage from './GroupingManage/GroupingManage';
import TermsManage from './TermsManage/TermsManage';
import DiscountManage from './DiscountsManage/DiscountsManage';
import AttendanceManage from './AttendanceManage/AttendanceManage';
import PackagesManage from './PackagesManage/PackagesManage';
import StaffAccManage from './StaffAccManage/StaffAccManage';
import MassTokenEditManage from './MassTokenEditManage/MassTokenEditManage';

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import CategoryIcon from '@material-ui/icons/Category';
import GavelIcon from '@material-ui/icons/Gavel';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import { connect } from "react-redux";
import { IRootState } from "../store";

export interface ISystemsProps {
    language: string
}
export interface ISystemsState {
    systemNavPosition: string
}

class Systems extends React.Component<ISystemsProps, ISystemsState> {
    constructor(props: ISystemsProps) {
        super(props);
        this.state = {
            systemNavPosition: 'sitesClassrooms'
        }
    }
    private handleNavBtnClick = async (navPosition: string) => {
        await this.setState({
            systemNavPosition: navPosition
        })
    }


    render() {
        function parseJwt(token: string) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        };
        let clientToken = localStorage.getItem('token');
        let tokenPayload: any = {}
        if (clientToken) {
            tokenPayload = parseJwt(clientToken)
        }
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-2" id="systemLeftNavBar">
                        <div className="systemNavBarDisplayArea">
                            <div className="systemNavBarTitleArea" >
                                <div className="systemNavBarTitle">{this.props.language !== "eng" ? "系統管理列表" : "System Manage"}</div>
                            </div>

                            <div className="systemNavBtnArea">
                                {
                                    this.state.systemNavPosition === 'sitesClassrooms' ?
                                        <div className="systemNavBtnActive">
                                            <HomeWorkIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "場地管理" : "Site Manage"}</div>
                                        </div>
                                        :
                                        <div className="systemNavBtn" onClick={() => this.handleNavBtnClick('sitesClassrooms')}>
                                            <HomeWorkIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "場地管理" : "Site Manage"}</div>
                                        </div>

                                }
                                {
                                    tokenPayload.post !== 'BOSS' ? null :
                                    this.state.systemNavPosition === 'staffs' ?
                                        <div className="systemNavBtnActive">
                                            <AssignmentIndIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "員工管理" : "Staff Manage"}</div>
                                        </div>
                                        :
                                        <div className="systemNavBtn" onClick={() => this.handleNavBtnClick('staffs')}>
                                            <AssignmentIndIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "員工管理" : "Staff Manage"}</div>
                                        </div>

                                }
                                {
                                    this.state.systemNavPosition === 'tokenPackages' ?
                                        <div className="systemNavBtnActive">
                                            <ConfirmationNumberIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "套券管理" : "Package Manage"}</div>
                                        </div>
                                        :
                                        <div className="systemNavBtn" onClick={() => this.handleNavBtnClick('tokenPackages')}>
                                            <ConfirmationNumberIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "套券管理" : "Package Manage"}</div>
                                        </div>

                                }
                                {
                                    this.state.systemNavPosition === 'discounts' ?
                                        <div className="systemNavBtnActive">
                                            <CardGiftcardIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "優惠管理" : "Discount Manage"}</div>
                                        </div>
                                        :
                                        <div className="systemNavBtn" onClick={() => this.handleNavBtnClick('discounts')}>
                                            <CardGiftcardIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "優惠管理" : "Discount Manage"}</div>
                                        </div>

                                }
                                {
                                    this.state.systemNavPosition === 'groupings' ?
                                        <div className="systemNavBtnActive">
                                            <CategoryIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "分類管理" : "Category Manage"}</div>
                                        </div>
                                        :
                                        <div className="systemNavBtn" onClick={() => this.handleNavBtnClick('groupings')}>
                                            <CategoryIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "分類管理" : "Category Manage"}</div>
                                        </div>

                                }
                                {
                                    this.state.systemNavPosition === 'attendances' ?
                                        <div className="systemNavBtnActive">
                                            <BeenhereIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "點名管理" : "Attendance Manage"}</div>
                                        </div>
                                        :
                                        <div className="systemNavBtn" onClick={() => this.handleNavBtnClick('attendances')}>
                                            <BeenhereIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "點名管理" : "Attendance Manage"}</div>
                                        </div>

                                }
                                {
                                    this.state.systemNavPosition === 'terms' ?
                                        <div className="systemNavBtnActive">
                                            <GavelIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "條款管理" : "Terms Manage"}</div>
                                        </div>
                                        :
                                        <div className="systemNavBtn" onClick={() => this.handleNavBtnClick('terms')}>
                                            <GavelIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "條款管理" : "Terms Manage"}</div>
                                        </div>

                                }
                                {
                                    this.state.systemNavPosition === 'massToken' ?
                                        <div className="systemNavBtnActive">
                                            <AllInclusiveIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "集體管理" : "Mass Package Manage"}</div>
                                        </div>
                                        :
                                        <div className="systemNavBtn" onClick={() => this.handleNavBtnClick('massToken')}>
                                            <AllInclusiveIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "集體管理" : "Mass Package Manage"}</div>
                                        </div>

                                }
                            </div>

                        </div>
                    </div>
                    <div className="col-10" id="systemMainBG">
                        {this.state.systemNavPosition !== 'sitesClassrooms' ? null : <SitesClassroomsManage />}
                        {this.state.systemNavPosition !== 'groupings' ? null : <GroupingManage />}
                        {this.state.systemNavPosition !== 'terms' ? null : <TermsManage/>}
                        {this.state.systemNavPosition !== 'discounts' ? null : <DiscountManage/>}
                        {this.state.systemNavPosition !== 'attendances' ? null : <AttendanceManage/>}
                        {this.state.systemNavPosition !== 'tokenPackages' ? null : <PackagesManage/>}
                        {this.state.systemNavPosition !== 'staffs' ? null : <StaffAccManage/>}
                        {this.state.systemNavPosition !== 'massToken' ? null : <MassTokenEditManage/>}
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state: IRootState) =>{
    return {
        language: state.auth.language
    }
}
export default connect(mapStateToProps, {})(Systems)
// export default Systems