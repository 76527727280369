import React from "react";
import { IRootState, ThunkDispatch } from "../store";
import { connect } from "react-redux";
import "./Home.css";
import "./DashBoardChart.css";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { Avatar } from "@material-ui/core";
import { Line } from 'react-chartjs-2'
import { SportsRugbySharp } from "@material-ui/icons";
// import moment from "chart.js/node_modules/moment";
import { homePageDataLoading } from "./thunks";
import Chartist from "chartist";
import moment from "moment";
import { parseJwt } from "../Schedule/Schedules";
import { async } from "q";
import { graphRefresh } from "./actions";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import RoomIcon from '@material-ui/icons/Room';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SignalCellularNoSimIcon from '@material-ui/icons/SignalCellularNoSim';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FaceIcon from '@material-ui/icons/Face';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 40,
            borderRadius: 5,
        },
        colorPrimary: {
            //   backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
            backgroundColor: "rgb(255, 198, 101)",
        },
        bar: {
            borderRadius: 5,
            backgroundColor: 'rgb(0, 128, 203)',
        },
    }),
)(LinearProgress);



export interface IHomeProps {
    graphDisplay: boolean,
    graphData: any,
    language: string,
    loadChartData: (data: any) => void,
    toggleGraphDisplay: () => void
}
export interface IHomeState {
    staffAccessSiteID: number,
    staffPost: string,
    username: string
}

class Home extends React.Component<IHomeProps, IHomeState> {
    constructor(props: IHomeProps) {
        super(props);
        this.state = {
            staffAccessSiteID: 0,
            staffPost: "",
            username: ""
        }
    }
    async componentWillMount() {
        await this.props.toggleGraphDisplay();

        if (this.state.staffAccessSiteID === 0) {

            let clientToken = localStorage.getItem('token');
            let tokenPayload: any = {}
            if (clientToken) {
                tokenPayload = await parseJwt(clientToken)
                console.log(tokenPayload)
                this.setState({
                    staffAccessSiteID: tokenPayload.siteID,
                    staffPost: tokenPayload.post,
                    username: tokenPayload.username
                })
            }
        }
        // if (this.props.graphData === "") {
        let queryData = {
            siteID: this.state.staffAccessSiteID,
            startDate: moment(moment().format("YYYY-MM-DDT00:00:00+08")).toISOString(),
            endDate: moment(moment().add(1, 'day').format("YYYY-MM-DDT23:59:00+08")).toISOString()
        }
        this.props.loadChartData(queryData)
        // }


    }

    render() {
        //Sales Analytics Data
        let dateLabel = [];
        let siteRevenue = [];
        let staffRevenue = [];
        let cashPaymentOfDay = 0;
        let cardPaymentOfDay = 0;
        let epsPaymentOfDay = 0;
        let bankPaymentOfDay = 0;
        let depositPaymentOfDay = 0;
        let otherPaymentOfDay = 0;
        if (this.props.graphData !== "") {
            dateLabel = this.props.graphData.chart01.last7DayIncomeOfSite.map((dateDetails: any) => moment(dateDetails.date).format("MM-DD"));
            siteRevenue = this.props.graphData.chart01.last7DayIncomeOfSite.map((dateDetails: any) => dateDetails.income);
            staffRevenue = this.props.graphData.chart01.last7DayIncomeOfStaff.map((staffDateDetails: any) => staffDateDetails.income);
            // CASH
            let cashPaymentArr = (this.props.graphData.chart01.allPaymentsOfToday.filter((payment: any) => payment.payment_method === "Cash"))
            cashPaymentOfDay = cashPaymentArr.reduce((acc: number, num: any) => acc + parseFloat(num.amount_received), 0);
            // CARD
            let cardPaymentArr = (this.props.graphData.chart01.allPaymentsOfToday.filter((payment: any) => payment.payment_method === "Credit Card"))
            cardPaymentOfDay = cardPaymentArr.reduce((acc: number, num: any) => acc + parseFloat(num.amount_received), 0);
            // EPS
            let epsPaymentArr = (this.props.graphData.chart01.allPaymentsOfToday.filter((payment: any) => payment.payment_method === "EPS"))
            epsPaymentOfDay = epsPaymentArr.reduce((acc: number, num: any) => acc + parseFloat(num.amount_received), 0);
            // Bank
            let bankPaymentArr = (this.props.graphData.chart01.allPaymentsOfToday.filter((payment: any) => payment.payment_method === "Bank Transfer"))
            bankPaymentOfDay = bankPaymentArr.reduce((acc: number, num: any) => acc + parseFloat(num.amount_received), 0)
            // Deposit
            let depositPaymentArr = (this.props.graphData.chart01.allPaymentsOfToday.filter((payment: any) => payment.payment_method === "Bank Deposit Slip"))
            depositPaymentOfDay = depositPaymentArr.reduce((acc: number, num: any) => acc + parseFloat(num.amount_received), 0)
            // Other
            let OtherPaymentArr = (this.props.graphData.chart01.allPaymentsOfToday.filter((payment: any) => payment.payment_method === "Other"))
            otherPaymentOfDay = OtherPaymentArr.reduce((acc: number, num: any) => acc + parseFloat(num.amount_received), 0)
        }
        new Chartist.Line('#salesAnalyticChart', {
            labels: dateLabel,
            series: [
                staffRevenue,
                siteRevenue
            ]
        }, {
            low: 2,
            showArea: true,
            plugins: []
        });

        // Sport Type Popularity Data
        let chartSeries = [];
        let chartLabel = [];
        let otherSport = {
            sport: "Others",
            participation: 0
        }
        let totlaParticipation = 0;
        if (this.props.graphData !== "") {
            for (let i = 0; i < this.props.graphData.dailyBookingSumup.participationOfOccupancies.length; i++) {
                totlaParticipation += this.props.graphData.dailyBookingSumup.participationOfOccupancies[i].participation;
                if (i < 6) {
                    chartSeries.push(this.props.graphData.dailyBookingSumup.participationOfOccupancies[i].participation);
                    chartLabel.push(this.props.graphData.dailyBookingSumup.participationOfOccupancies[i].sport)
                } else {
                    otherSport.participation += this.props.graphData.dailyBookingSumup.participationOfOccupancies[i].participation
                }
            }
            chartSeries.push(otherSport.participation);

        }
        new Chartist.Pie("#sportPopularityDonutChart", {
            series: chartSeries,
            labels: chartLabel
        }, {
            donut: true,
            showLabel: false,
            plugins: []
        });

        console.log(chartSeries)
        console.log(chartLabel)

        return (
            <div className="col-12 dashBoardArea areaScroll">
                {/* <h1>This is the Home Page Dashboard Area.</h1> */}
                <div className="row">
                    <div className="col-xl-3 col-lg-6 dashBoardRow2">
                        <div className="dashBoardCard">
                            <div className="floatLeftIconContainer float-start mini-stat-img me-4">
                                <BeenhereIcon id="keyInfoIcon" />
                            </div>
                            <div className="fw-bold font-size-16 text-uppercase text-white-50">{this.props.language !== "eng" ? "今日總人次" : "Daily Bookings"}</div>
                            <div className="fw-semibold font-size-36 text-white ">{this.props.graphData === "" ? null : this.props.graphData.dailyBookingSumup.allPresent} / {this.props.graphData === "" ? null : this.props.graphData.dailyBookingSumup.allParticipationCount}
                                {this.props.graphData === "" ? null :
                                    !this.props.graphData.dailyBookingSumup.participantsDiff || this.props.graphData.dailyBookingSumup.participantsDiff === 0 ? null :
                                        this.props.graphData.dailyBookingSumup.participantsDiff > 0 ? <ArrowUpwardIcon id="incrementIndicator" /> : <ArrowDownwardIcon id="incrementIndicator" />}
                                <div className="font-size-16 inlineDisplay">{this.props.graphData === "" ? null : !this.props.graphData.dailyBookingSumup.participantsDiff ? "" : this.props.graphData.dailyBookingSumup.participantsDiff === 0 ? "" : this.props.graphData.dailyBookingSumup.participantsDiff < 0 ? `${(this.props.graphData.dailyBookingSumup.participantsDiff * -1).toFixed(0)}%` : `${(this.props.graphData.dailyBookingSumup.participantsDiff).toFixed(0)}%`}</div>
                            </div>
                            <div className="text-white-50 mb-0 mt-1">{this.props.language !== "eng" ? "對比 7 天前" : "Comparing 7 Days Before"}
                                {/* <div className="float-end"><ArrowForwardIcon /></div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 dashBoardRow2">
                        <div className="dashBoardCard">
                            <div className="floatLeftIconContainer float-start mini-stat-img me-4">
                                <AccessTimeIcon id="keyInfoIcon" />
                            </div>
                            <div className="fw-bold font-size-16 text-uppercase text-white-50">{this.props.language !== "eng" ? "今日總課堂數" : "Daily Classes"}</div>
                            <div className="fw-semibold font-size-36 text-white ">{this.props.graphData === "" ? null : this.props.graphData.dailyBookingSumup.tickedAttendanceClass} / {this.props.graphData === "" ? null : this.props.graphData.dailyBookingSumup.allOccupancies}
                                {this.props.graphData === "" ? null :
                                    !this.props.graphData.dailyBookingSumup.occupancyDiff || this.props.graphData.dailyBookingSumup.occupancyDiff === 0 ? null :
                                        this.props.graphData.dailyBookingSumup.occupancyDiff > 0 ? <ArrowUpwardIcon id="incrementIndicator" /> : <ArrowDownwardIcon id="incrementIndicator" />}
                                <div className="font-size-16 inlineDisplay">{this.props.graphData === "" ? null : !this.props.graphData.dailyBookingSumup.occupancyDiff || this.props.graphData.dailyBookingSumup.occupancyDiff === 0 ? "" : this.props.graphData.dailyBookingSumup.occupancyDiff < 0 ? `${(this.props.graphData.dailyBookingSumup.occupancyDiff * -1).toFixed(0)}%` : `${(this.props.graphData.dailyBookingSumup.occupancyDiff).toFixed(0)}%`}</div>
                            </div>
                            <div className="text-white-50 mb-0 mt-1">{this.props.language !== "eng" ? "對比 7 天前" : "Comparing 7 Days Before"}
                                {/* <div className="float-end"><ArrowForwardIcon /></div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 dashBoardRow2">
                        <div className="dashBoardCard">
                            <div className="floatLeftIconContainer float-start mini-stat-img me-4">
                                <SignalCellularNoSimIcon id="keyInfoIcon" />
                            </div>
                            <div className="fw-bold font-size-16 text-uppercase text-white-50">{this.props.language !== "eng" ? "總無套券預約數" : "No Token Bookings"}</div>
                            <div className="fw-semibold font-size-36 text-white ">{this.props.graphData === "" ? null : this.props.graphData.dailyBookingSumup.allValidNoTokenBookings} </div>
                            <div className="text-white-50 mb-0 mt-1">{this.props.language !== "eng" ? "此分校總數" : "Site Total"}
                                {/* <div className="float-end"><ArrowForwardIcon /></div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 dashBoardRow2">
                        <div className="dashBoardCard">
                            <div className="floatLeftIconContainer float-start mini-stat-img me-4">
                                <RemoveShoppingCartIcon id="keyInfoIcon" />
                            </div>
                            <div className="fw-bold font-size-16 text-uppercase text-white-50">{this.props.language !== "eng" ? "總未清付 / 月總單數" : "Not Clear / Purcahse"}</div>
                            <div className="fw-semibold font-size-36 text-white "> {this.props.graphData === "" ? null : this.props.graphData.dailyBookingSumup.allNotClearedPurhaseOfSite} / {this.props.graphData === "" ? null : this.props.graphData.dailyBookingSumup.monthlyAllPurchaseOfSite}
                                {this.props.graphData === "" ? null :
                                    !this.props.graphData.dailyBookingSumup.monthlyPurchaseDiff || this.props.graphData.dailyBookingSumup.monthlyPurchaseDiff === 0 ? null :
                                        this.props.graphData.dailyBookingSumup.monthlyPurchaseDiff > 0 ? <ArrowUpwardIcon id="incrementIndicator" /> : <ArrowDownwardIcon id="incrementIndicator" />}
                                <div className="font-size-16 inlineDisplay">{this.props.graphData === "" ? null : !this.props.graphData.dailyBookingSumup.monthlyPurchaseDiff ? "" : this.props.graphData.dailyBookingSumup.monthlyPurchaseDiff === 0 ? "" : this.props.graphData.dailyBookingSumup.monthlyPurchaseDiff < 0 ? `${(this.props.graphData.dailyBookingSumup.monthlyPurchaseDiff * -1).toFixed(0)}%` : `${(this.props.graphData.dailyBookingSumup.monthlyPurchaseDiff).toFixed(0)}%`}</div>

                            </div>
                            <div className="text-white-50 mb-0 mt-1">{this.props.language !== "eng" ? "對比上月" : "Comparing Previous Month"}
                                {/* <div className="float-end"><ArrowForwardIcon /></div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-12  dashBoardRow1">
                        <div className="dashBoardCard01">
                            <h4 className="card-title mb-4">{this.props.language !== "eng" ? "顧客群簡況" : "Customer Pool Analysis"}</h4>
                            {
                                this.props.graphData === "" ? null :
                                    <BorderLinearProgress variant="determinate" value={this.props.graphData.customerPoolAnalysis.activeCustomerCount / (this.props.graphData.customerPoolAnalysis.activeCustomerCount + this.props.graphData.customerPoolAnalysis.endingCustomerCount) * 100} />
                            }
                            <div className="row">
                                <div className="col-6 barChartIndicatorContainer borderRightOfBarChartIndicator mt-4">
                                    <div className="barChartIndicatorTitle mb-4">
                                        {this.props.language !== "eng" ? "活躍客戶" : "Active Customers"}
                                        <div className="lineSample03  w-100"></div>
                                    </div>
                                    <h2 className="barChartIndicatorData">{this.props.graphData === "" ? null : this.props.graphData.customerPoolAnalysis.activeCustomerCount}</h2>
                                </div>
                                <div className="col-6 barChartIndicatorContainer mt-4">
                                    <div className="barChartIndicatorTitle mb-4">{this.props.language !== "eng" ? "期末客戶" : "Ending Customers"}
                                        <div className="lineSample04  w-100"></div>
                                    </div>
                                    <h2 className="barChartIndicatorData">{this.props.graphData === "" ? null : this.props.graphData.customerPoolAnalysis.endingCustomerCount}</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 mt-4 secondTierDataBox mt-4">
                                    <div className="barChartIndicatorTitle mb-2">{this.props.language !== "eng" ? "總顧客數" : "Total Customers"}
                                        <div className="lineSample05  w-100"></div>
                                    </div>
                                    <h3 >{this.props.graphData === "" ? null : this.props.graphData.customerPoolAnalysis.totalCustomerCount}</h3>
                                </div>
                                <div className="col-4 secondTierDataBox mt-4">
                                    <div className="barChartIndicatorTitle mb-2">{this.props.language !== "eng" ? "非活躍顧客(半年)" : "Inactive (6 Mon.)"}
                                        <div className="lineSample05  w-100"></div>
                                    </div>
                                    <h3>{this.props.graphData === "" ? null : this.props.graphData.customerPoolAnalysis.inactiveCustomerCount}</h3>
                                </div>
                                <div className="col-4 secondTierDataBox mt-4">
                                    <div className="barChartIndicatorTitle mb-2">{this.props.language !== "eng" ? "本月新增顧客" : "Monthly New Intake"}
                                        <div className="lineSample05  w-100"></div>
                                    </div>
                                    <h3>{this.props.graphData === "" ? null : this.props.graphData.customerPoolAnalysis.intakeOfMonth}</h3>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-xl-8 ">
                        <div className=" dashBoardRow1">
                            <div className="dashBoardCard01">
                                <div className="cardTitleRowOnDashboard">
                                    <h4 className="card-title mb-4 inline">{this.props.language !== "eng" ? "業績數據" : "Earnings"}</h4>
                                    <div className="lineIndicatorContainer">
                                        <div className="indicatorAligner">
                                            <div className="lineIndicator">
                                                <div className="lineSample01"></div>
                                                <div className="lineDetails">{this.props.language !== "eng" ? "個人銷售業績" : "Personal Sales"}</div>
                                            </div>
                                            <div className="lineIndicator">
                                                <div className="lineSample02"></div>
                                                <div className="lineDetails">{this.props.language !== "eng" ? "店鋪銷售業績" : "Site Sales"}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-7 col-md-12" id="SAChartAligner">
                                        <div>
                                            {
                                                this.props.graphDisplay === false ? null :
                                                    <div id="salesAnalyticChart" className="ct-chart earning ct-golden-section" key="salesAnalyticGraph">
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12">
                                                {/* <div className="text-center">
                                                    <p className="text-muted mb-4">This month</p>
                                                    <h3>$34,252</h3>
                                                    <p className="text-muted mb-5">It will be as simple as in fact it
                                                                will be occidental.</p>
                                                    <span className="peity-donut"
                                                        data-peity='{ "fill": ["#02a499", "#f2f2f2"], "innerRadius": 28, "radius": 32 }'
                                                        data-width="72" data-height="72">4/5</span>
                                                </div> */}
                                                <div className="paymentTypeSummaryArea revenueDashBoardAlign">
                                                    <div className="revenueDashBoardAlign">
                                                        <div className="methodColorIndicator" id="cashAvatarOnDash"></div>
                                                        {/* <Avatar id="cashAvatarOnDash" className="paymentMethodAvatar orange">現{this.props.language !== "eng" ? "現" : "C"}</Avatar> */}
                                                        <div className="paymentTypeDetailsContainer inlineDisplay">
                                                            <div className="dashNumberExplain02">{this.props.language !== "eng" ? "現金" : "CASH"}</div>
                                                            <div className="dashNumberFont dashNumbers02"> ${cashPaymentOfDay} </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="paymentTypeSummaryArea revenueDashBoardAlign">
                                                    <div className="revenueDashBoardAlign">
                                                        <div className="methodColorIndicator" id="cardAvatarOnDash"></div>

                                                        {/* <Avatar id="cardAvatarOnDash" className="paymentMethodAvatar orange">卡</Avatar> */}
                                                        <div className="paymentTypeDetailsContainer inlineDisplay">
                                                            <div className="dashNumberExplain02">{this.props.language !== "eng" ? "信用卡" : "Credit Card"}</div>
                                                            <div className="dashNumberFont dashNumbers02"> ${cardPaymentOfDay} </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="paymentTypeSummaryArea revenueDashBoardAlign">
                                                    <div className="revenueDashBoardAlign">
                                                        <div className="methodColorIndicator" id="bankAvatarOnDash"></div>
                                                        {/* <Avatar id="bankAvatarOnDash" className="paymentMethodAvatar orange">轉</Avatar> */}
                                                        <div className="paymentTypeDetailsContainer inlineDisplay">
                                                            <div className="dashNumberExplain02">{this.props.language !== "eng" ? "銀行轉帳" : "Bank Transfer"}</div>
                                                            <div className="dashNumberFont dashNumbers02"> ${bankPaymentOfDay} </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                {/* <div className="text-center">
                                                    <p className="text-muted mb-4">Last month</p>
                                                    <h3>$36,253</h3>
                                                    <p className="text-muted mb-5">It will be as simple as in fact it
                                                                will be occidental.</p>
                                                    <span className="peity-donut"
                                                        data-peity='{ "fill": ["#02a499", "#f2f2f2"], "innerRadius": 28, "radius": 32 }'
                                                        data-width="72" data-height="72">3/5</span>
                                                </div> */}
                                                <div className="paymentTypeSummaryAreaRight revenueDashBoardAlign">
                                                    <div className="revenueDashBoardAlign">
                                                        <div className="methodColorIndicator" id="EPSAvatarOnDash"></div>
                                                        {/* <Avatar id="EPSAvatarOnDash" className="paymentMethodAvatar orange">E</Avatar> */}
                                                        <div className="paymentTypeDetailsContainer inlineDisplay">
                                                            <div className="dashNumberExplain02">EPS</div>
                                                            <div className="dashNumberFont dashNumbers02"> ${epsPaymentOfDay} </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="paymentTypeSummaryAreaRight revenueDashBoardAlign">
                                                    <div className="revenueDashBoardAlign">
                                                        <div className="methodColorIndicator" id="depositAvatarOnDash"></div>
                                                        {/* <Avatar id="depositAvatarOnDash" className="paymentMethodAvatar orange">入</Avatar> */}
                                                        <div className="paymentTypeDetailsContainer inlineDisplay">
                                                            <div className="dashNumberExplain02">{this.props.language !== "eng" ? "入數" : "Deposit Slip"}</div>
                                                            <div className="dashNumberFont dashNumbers02"> ${depositPaymentOfDay} </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="paymentTypeSummaryAreaRight revenueDashBoardAlign">
                                                    <div className="revenueDashBoardAlign">
                                                        <div className="methodColorIndicator" id="otherAvatarOnDash"></div>
                                                        {/* <Avatar id="otherAvatarOnDash" className="paymentMethodAvatar orange">其</Avatar> */}
                                                        <div className="paymentTypeDetailsContainer inlineDisplay">
                                                            <div className="dashNumberExplain02">{this.props.language !== "eng" ? "其他" : "Other"}</div>
                                                            <div className="dashNumberFont dashNumbers02"> ${otherPaymentOfDay} </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="text-center ">
                                                    <p className="text-muted mb-2 mt-4">{this.props.language !== "eng" ? "今日總單數" : "Daily Sales Count"}</p>
                                                    <h1>{this.props.graphData === "" ? "0" : this.props.graphData.chart01.allPaymentsOfToday.length}</h1>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="text-center">
                                                    <p className="text-muted mb-2 mt-4">{this.props.language !== "eng" ? "今日總營收" : "Daily Sales Revenue"}</p>
                                                    <h1>${this.props.graphData === "" ? "0" : this.props.graphData.chart01.last7DayIncomeOfSite[6].income}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end row --> */}
                            </div>
                        </div>
                        {/* <!-- end card --> */}
                    </div>

                </div>

                <div className="row">

                    <div className="col-xl-4 col-lg-12  dashBoardRow3">
                        <div className="dashBoardCard01">
                            <div className="row h-100">
                                <div className="cardTitleRowOnDashboard">
                                    <h4 className="card-title mb-4 inline">{this.props.language !== "eng" ? "30日熱銷數據" : "30 Days Popular Packages"}</h4>
                                </div>
                                <div className="col-md-12 scrollArea">
                                    {this.props.graphData === "" ? null :
                                        this.props.graphData.popularPackageArr.length === 0 ? null :
                                            this.props.graphData.popularPackageArr.map((targetPackage: any, index: number) =>
                                                targetPackage.usage === "Class Participation" ?
                                                    <div className="row targetPackageRowOnSAGraph groupClassPackageBGColor">
                                                        <div className="col-md-2 aligner"><div className="packageRankNumber">{index + 1}</div><PeopleAltIcon id="packageIconOnSAGraph" /></div>
                                                        <div className="col-md-8 text-center ">{targetPackage.packageName}</div>
                                                        <div className="col-md-2 text-center">{this.props.language !== "eng" ? "已賣" : "SOLD"} {targetPackage.count}</div>
                                                    </div>
                                                    :
                                                    targetPackage.usage === "Private Class Booking" ?
                                                        <div className="row targetPackageRowOnSAGraph privateClassPackageBGColor">
                                                            <div className="col-md-2 aligner"><div className="packageRankNumber">{index + 1}</div><PersonIcon id="packageIconOnSAGraph" /></div>
                                                            <div className="col-md-8 text-center">{targetPackage.packageName}</div>
                                                            <div className="col-md-2 text-center">{this.props.language !== "eng" ? "已賣" : "SOLD"} {targetPackage.count}</div>
                                                        </div>
                                                        :
                                                        targetPackage.usage === "Classroom Rental" ?
                                                            <div className="row targetPackageRowOnSAGraph roomRentalPackageBGColor">
                                                                <div className="col-md-2 aligner"><div className="packageRankNumber">{index + 1}</div><RoomIcon id="packageIconOnSAGraph" /></div>
                                                                <div className="col-md-8 text-center">{targetPackage.packageName}</div>
                                                                <div className="col-md-2 text-center">{this.props.language !== "eng" ? "已賣" : "SOLD"} {targetPackage.count}</div>
                                                            </div>
                                                            :
                                                            targetPackage.usage === "Tutor Rental" ?
                                                                <div className="row targetPackageRowOnSAGraph tutorRentalPackageBGColor">
                                                                    <div className="col-md-2 aligner"><div className="packageRankNumber">{index + 1}</div><AssignmentIndIcon id="packageIconOnSAGraph" /></div>
                                                                    <div className="col-md-8 text-center">{targetPackage.packageName}</div>
                                                                    <div className="col-md-2 text-center">{this.props.language !== "eng" ? "已賣" : "SOLD"} {targetPackage.count}</div>
                                                                </div>
                                                                : null
                                            )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-xl-4 col-lg-12  dashBoardRow3">
                        <div className="dashBoardCard01">
                            <h4 className="card-title mb-4">{this.props.language !== "eng" ? "顧客群簡況" : "Customer Pool Analysis"}</h4>
                            <BorderLinearProgress variant="determinate" value={50} />
                            <div className="row">
                                <div className="col-6 barChartIndicatorContainer borderRightOfBarChartIndicator mt-4">
                                    <div className="barChartIndicatorTitle mb-4">
                                        {this.props.language !== "eng" ? "活躍客戶" : "Active Customers"}
                                        <div className="lineSample03  w-100"></div>
                                    </div>
                                    <h2 className="barChartIndicatorData">238</h2>
                                </div>
                                <div className="col-6 barChartIndicatorContainer mt-4">
                                    <div className="barChartIndicatorTitle mb-4">{this.props.language !== "eng" ? "期末客戶" : "Ending Customers"}
                                        <div className="lineSample04  w-100"></div>
                                    </div>
                                    <h2 className="barChartIndicatorData">20</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 mt-4 secondTierDataBox mt-4">
                                    <div className="barChartIndicatorTitle mb-2">{this.props.language !== "eng" ? "總顧客數" : "Total Customers"}
                                        <div className="lineSample05  w-100"></div>
                                    </div>
                                    <h3 >3555</h3>
                                </div>
                                <div className="col-4 secondTierDataBox mt-4">
                                    <div className="barChartIndicatorTitle mb-2">{this.props.language !== "eng" ? "非活躍顧客(半年)" : "Inactive (6 Mon.)"}
                                        <div className="lineSample05  w-100"></div>
                                    </div>
                                    <h3>20</h3>
                                </div>
                                <div className="col-4 secondTierDataBox mt-4">
                                    <div className="barChartIndicatorTitle mb-2">{this.props.language !== "eng" ? "本月新增顧客" : "Monthly New Intake"}
                                        <div className="lineSample05  w-100"></div>
                                    </div>
                                    <h3>20</h3>
                                </div>
                            </div>
                            
                        </div>
                    </div> */}

                    <div className="col-xl-5 col-lg-12  dashBoardRow3 ">
                        <div className="dashBoardCard01">
                            <div className="row h-100">

                                <div className="col-md-12 ">
                                    <h4 className="card-title mb-4">{this.props.language !== "eng" ? "關注顧客" : "Monitor Customers"} ( {this.props.graphData === "" ? null : this.props.graphData.dailyBookingSumup.notokenBookingCustomer.length + this.props.graphData.customerPoolAnalysis.endingCustomer.length + this.props.graphData.dailyBookingSumup.unclearCustomer.length} )</h4>
                                    <div className="row monitorCustomerTitleRow">
                                        <div className="col-3">#ID</div>
                                        <div className="col-5">{this.props.language !== "eng" ? "顧客" : "Customer"}</div>
                                        <div className="col-4">{this.props.language !== "eng" ? "狀況" : "Details"}</div>
                                    </div>
                                    <div className="row monitorCustomerTable">
                                        <div className="col-12">
                                            {
                                                this.props.graphData === "" || this.props.graphData.dailyBookingSumup.notokenBookingCustomer.length === 0 ? null :
                                                    this.props.graphData.dailyBookingSumup.notokenBookingCustomer.map((customer: any) =>
                                                        <div className="row monitorCustomerRow">
                                                            <div className="col-3 underline">#{customer.id}</div>
                                                            {
                                                                this.props.language !== "eng" ?
                                                                    <div className="col-5"><FaceIcon id="momitorCustomerIcon" />{customer.name_chi === "" ? customer.name_eng : customer.name_chi}</div>
                                                                    :
                                                                    <div className="col-5"><FaceIcon id="momitorCustomerIcon" />{customer.name_eng === "" ? customer.name_chi : customer.name_eng}</div>
                                                            }
                                                            <div className="col-4">{this.props.language !== "eng" ? "無套券預約" : "No Token"} - {moment(`${customer.time}`).format("HH:mm")}</div>
                                                        </div>
                                                    )
                                            }
                                            {
                                                this.props.graphData === "" || this.props.graphData.customerPoolAnalysis.endingCustomer.length === 0 ? null :
                                                    this.props.graphData.customerPoolAnalysis.endingCustomer.map((customer: any) =>
                                                        <div className="row monitorCustomerRow">
                                                            <div className="col-3 underline">#{customer.id}</div>
                                                            {
                                                                this.props.language !== "eng" ?
                                                                    <div className="col-5"><FaceIcon id="momitorCustomerIcon02" />{customer.name_chi === "" ? customer.name_eng : customer.name_chi}</div>
                                                                    :
                                                                    <div className="col-5"><FaceIcon id="momitorCustomerIcon02" />{customer.name_eng === "" ? customer.name_chi : customer.name_eng}</div>
                                                            }
                                                            <div className="col-4">{this.props.language !== "eng" ? "過期" : "Expiring"} - {moment(`${customer.end_date}`).format("YYYY-MM-DD")}</div>
                                                        </div>
                                                    )
                                            }
                                            {
                                                this.props.graphData === "" || this.props.graphData.dailyBookingSumup.unclearCustomer.length === 0 ? null :
                                                    this.props.graphData.dailyBookingSumup.unclearCustomer.map((customer: any) =>
                                                        <div className="row monitorCustomerRow">
                                                            <div className="col-3 underline">#{customer.id}</div>
                                                            {
                                                                this.props.language !== "eng" ?
                                                                    <div className="col-5"><FaceIcon id="momitorCustomerIcon" />{customer.name_chi === "" ? customer.name_eng : customer.name_chi}</div>
                                                                    :
                                                                    <div className="col-5"><FaceIcon id="momitorCustomerIcon" />{customer.name_eng === "" ? customer.name_chi : customer.name_eng}</div>
                                                            }
                                                            <div className="col-4">{this.props.language !== "eng" ? "未完成付款" : "Unclear Payment"}</div>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-xl-3 col-lg-12  dashBoardRow3">
                        <div className="dashBoardCard01">
                            <h4 className="card-title mb-4">{this.props.language !== "eng" ? "運動熱門程度" : "Sports Popularity"}</h4>

                            <div className="cleafix">
                                <p className="float-start"><i className="mdi mdi-calendar me-1 text-primary"></i> {moment().startOf("month").format("MMM DD")} - {moment().endOf("month").format("MMM DD")}</p>
                                <h5 className="font-18 text-end">{totlaParticipation} {this.props.language !== "eng" ? "人次" : "Person Times"}</h5>
                            </div>
                            <div id="sportPopularityDonutChart" className="ct-chart wid"></div>
                            <div className="mt-4">
                                <table className="table mb-0">
                                    <tbody>
                                        {
                                            this.props.graphData === "" ? null :
                                                this.props.graphData.dailyBookingSumup.participationOfOccupancies.map((sport: any, index: number) => {
                                                    let specifiClassName = `badge sportPopularityChartColor-${toAlpha(index)} pl-3 pr-3`;
                                                    if (index < 6) {
                                                        return <tr>
                                                            <td><span className={specifiClassName}>{index + 1} </span></td>
                                                            <td>{this.props.graphData === "" ? null : (this.props.graphData.allSportTypes.find((sportType: any) => sportType.id === sport.sport)).genre}</td>
                                                            <td className="text-end">{this.props.graphData === "" ? null : ((sport.participation / totlaParticipation) * 100).toFixed(1)}%</td>
                                                        </tr>
                                                    } else {
                                                        return null
                                                    }
                                                }
                                                )
                                        }
                                        {
                                            this.props.graphData === "" ? null :
                                                this.props.graphData.dailyBookingSumup.participationOfOccupancies.map((sport: any, index: number) => {
                                                    let specifiClassName = `badge sportPopularityChartColor-${toAlpha(6)} pl-3 pr-3`;
                                                    if (index !== 6) {
                                                        return null;
                                                    } else {
                                                        return <tr>
                                                            <td><span className={specifiClassName}>7 </span></td>
                                                            <td>{this.props.language !== "eng" ? "其他" : "Others"}</td>
                                                            <td className="text-end">{this.props.graphData === "" ? null : ((otherSport.participation / totlaParticipation) * 100).toFixed(1)}%</td>
                                                        </tr>
                                                    }
                                                })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        graphData: state.home.graphData,
        graphDisplay: state.home.graphDisplay,
        language: state.auth.language
        // username: state.auth.accountUsername,
        // level: state.auth.level
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        loadChartData: async (data: any) => dispatch(await homePageDataLoading(data)),
        toggleGraphDisplay: async () => dispatch(await graphRefresh())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Home)




const toAlpha = (num: number) => {
    if (num < 0 || num > 26 || typeof num !== 'number') {
        return -1;
    }
    const leveller = 97;

    return String.fromCharCode(num + leveller);
};

