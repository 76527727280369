import React from 'react';
import { Button, TextField } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import HomeWorkIcon from '@material-ui/icons/HomeWork';


export interface ICreateSiteBoxProps {
    handleExit: () => void,
    confirmAddNewMassEdit: (newStaffDetails: any) => void,
    // finishing: boolean
    // employmentStatusList: any[]
    language: string,
}
export interface ICreateSiteBoxState {
    createStaffSteps: any[],
    activeStep: number,
    newSite: any
}

class CreateSiteBox extends React.Component<ICreateSiteBoxProps, ICreateSiteBoxState> {
    constructor(props: ICreateSiteBoxProps) {
        super(props);
        this.state = {
            createStaffSteps: (this.props.language !== "eng" ? ['輸入分校資料', '輸入分校營業時間', '確認分校詳情'] : ['Site Details', 'Site Operational Hour', 'Confirm Details']),
            activeStep: 0,
            newSite: {
                sites: "",
                site_key: "",
                office_hour_mon: "00:00-00:00",
                office_hour_tue: "00:00-00:00",
                office_hour_wed: "00:00-00:00",
                office_hour_thu: "00:00-00:00",
                office_hour_fri: "00:00-00:00",
                office_hour_sat: "00:00-00:00",
                office_hour_sun: "00:00-00:00"
            }

        }
    }
    private handleDeclineCreateNewStaff = async () => {
        let confirmation = true;
        if(this.props.language !== "eng"){
            confirmation = window.confirm('你確定要取消新增分校嗎？');
        } else {
            confirmation = window.confirm('你確定要取消新增分校嗎？');

        }
        if (confirmation) {
            await this.props.handleExit();
        }
    }

    private handleNextStepClick = async () => {
        await this.setState({
            activeStep: this.state.activeStep + 1
        })
    }

    private handleNewStaffDetailsInput = (field: string) => async (event: any) => {

        await this.setState({
            newSite: {
                ...this.state.newSite,
                [field]: event.target.value
            }
        })
    }
    private handleNewSiteTimeInput = (field: string, inputSlot: string) => async (event: any) => {
        if (inputSlot === 'open') {
            let newOpeningTime = this.state.newSite[field];
            newOpeningTime = newOpeningTime.split('-');
            newOpeningTime[0] = event.target.value;
            await this.setState({
                newSite: {
                    ...this.state.newSite,
                    [field]: `${newOpeningTime[0]}-${newOpeningTime[1]}`
                }
            })
        } else {
            let newOpeningTime = this.state.newSite[field];
            newOpeningTime = newOpeningTime.split('-');
            newOpeningTime[1] = event.target.value;
            await this.setState({
                newSite: {
                    ...this.state.newSite,
                    [field]: `${newOpeningTime[0]}-${newOpeningTime[1]}`
                }
            })
        }
    }

    private handleConfirmAddClick = async () => {
        let newSite = this.state.newSite;
        await this.props.confirmAddNewMassEdit(newSite);
        await this.setState({
            activeStep: 1
        })
    }

    async componentDidUpdate() {
        // if (this.state.activeStep === 1 && this.props.finishing) {
        //     setTimeout(async () => await this.setState({
        //         activeStep: 2
        //     }), 1000);

        // }
    }

    render() {
        return (
            <div className="systemMainDisplayArea">
                <div className="systemManagePageTitle"> <HomeWorkIcon id="systemManPageTitleIcon" /> {this.props.language !== "eng" ? "場地管理" : "Site Manage"}</div>
                <div className="createStaffArea">
                    <div className="staffListingAreaTitleRow">
                        <div className="staffListingAreaTitle">{this.props.language !== "eng" ? "新增分校" : "Create New Site"} </div>
                        {
                            this.state.activeStep === 0 ?
                                <Button id="cxlCreateStaffBtn" startIcon={<CloseIcon />} onClick={this.handleDeclineCreateNewStaff}> {this.props.language !== "eng" ? "取消新增" : "Cxl Creation"} </Button>
                                : null
                        }
                    </div>
                    <div className="stepsIndicatorRow">
                        <Stepper id="stepIconColor" activeStep={this.state.activeStep} alternativeLabel>
                            {this.state.createStaffSteps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    <div className="fixedHeightWindowOnCreateNewSiteBox">
                        <div className="mainInputRow">
                            {
                                this.state.activeStep === 0 ?
                                    //FIRST STEP
                                    <div className="tokenContentInputContainer">
                                        <div className="inputRowOnAddPackageComponent">
                                            <div className="tokenNameLabel">*{this.props.language !== "eng" ? "*分校名稱" : "* Site Name"} :</div>
                                            <TextField className="packageNameInput" variant="outlined" value={this.state.newSite.sites} onChange={this.handleNewStaffDetailsInput('sites')} />
                                        </div>
                                        <div className="inputRowOnAddPackageComponent">
                                            <div className="tokenNameLabel">*{this.props.language !== "eng" ? "*分校簡稱" : "* Site Key"} :</div>
                                            <TextField className="packageNameInput"  variant="outlined" value={this.state.newSite.site_key} onChange={this.handleNewStaffDetailsInput('site_key')} />
                                        </div>

                                    </div>
                                    :
                                    this.state.activeStep === 1 ?
                                        <div className="tokenContentInputContainer">
                                            <div className="inputRowOnAddPackageComponent">
                                                <div className="tokenNameLabel">*{this.props.language !== "eng" ? "星期一" : "Mon"} :</div>
                                                <TextField
                                                    id="time"
                                                    label={this.props.language !== "eng" ?  "開門時間" : "Opening"} 
                                                    type="time"
                                                    value={(this.state.newSite.office_hour_mon.split('-'))[0]}
                                                    className="openingTimeInput"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 1800, // 5 min
                                                    }}
                                                    onChange={this.handleNewSiteTimeInput('office_hour_mon', 'open')}
                                                />
                                                <TextField
                                                    id="time"
                                                    label={this.props.language !== "eng" ? "關門時間" : "Closing"}
                                                    type="time"
                                                    value={(this.state.newSite.office_hour_mon.split('-'))[1]}
                                                    className="openingTimeInput"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 1800, // 5 min
                                                    }}
                                                    onChange={this.handleNewSiteTimeInput('office_hour_mon', 'close')}
                                                />
                                            </div>
                                            <div className="inputRowOnAddPackageComponent">
                                                <div className="tokenNameLabel">*{this.props.language !== "eng" ? "星期二" : "Tue"} :</div>
                                                <TextField
                                                    id="time"
                                                    label={this.props.language !== "eng" ? "開門時間" : "Opening"}
                                                    type="time"
                                                    value={(this.state.newSite.office_hour_tue.split('-'))[0]}
                                                    className="openingTimeInput"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 1800, // 5 min
                                                    }}
                                                    onChange={this.handleNewSiteTimeInput('office_hour_tue', 'open')}
                                                />
                                                <TextField
                                                    id="time"
                                                    label={this.props.language !== "eng" ? "關門時間" : "Closing"}
                                                    type="time"
                                                    value={(this.state.newSite.office_hour_tue.split('-'))[1]}
                                                    className="openingTimeInput"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 1800, // 5 min
                                                    }}
                                                    onChange={this.handleNewSiteTimeInput('office_hour_tue', 'close')}
                                                />
                                            </div>
                                            <div className="inputRowOnAddPackageComponent">
                                                <div className="tokenNameLabel">*{this.props.language !== "eng" ? "星期三" : "Wed"} :</div>
                                                <TextField
                                                    id="time"
                                                    label={this.props.language !== "eng" ? "開門時間" : "Opening"}
                                                    type="time"
                                                    value={(this.state.newSite.office_hour_wed.split('-'))[0]}
                                                    className="openingTimeInput"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 1800, // 5 min
                                                    }}
                                                    onChange={this.handleNewSiteTimeInput('office_hour_wed', 'open')}
                                                />
                                                <TextField
                                                    id="time"
                                                    label={this.props.language !== "eng" ? "關門時間" : "Closing"}
                                                    type="time"
                                                    value={(this.state.newSite.office_hour_wed.split('-'))[1]}
                                                    className="openingTimeInput"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 1800, // 5 min
                                                    }}
                                                    onChange={this.handleNewSiteTimeInput('office_hour_wed', 'close')}
                                                />
                                            </div>
                                            <div className="inputRowOnAddPackageComponent">
                                                <div className="tokenNameLabel">*{this.props.language !== "eng" ? "星期四" : "Thu"} :</div>
                                                <TextField
                                                    id="time"
                                                    label={this.props.language !== "eng" ? "開門時間" : "Opening"}
                                                    type="time"
                                                    value={(this.state.newSite.office_hour_thu.split('-'))[0]}
                                                    className="openingTimeInput"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 1800, // 5 min
                                                    }}
                                                    onChange={this.handleNewSiteTimeInput('office_hour_thu', 'open')}
                                                />
                                                <TextField
                                                    id="time"
                                                    label={this.props.language !== "eng" ? "關門時間" : "Closing"}
                                                    type="time"
                                                    value={(this.state.newSite.office_hour_thu.split('-'))[1]}
                                                    className="openingTimeInput"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 1800, // 5 min
                                                    }}
                                                    onChange={this.handleNewSiteTimeInput('office_hour_thu', 'close')}
                                                />
                                            </div>
                                            <div className="inputRowOnAddPackageComponent">
                                                <div className="tokenNameLabel">*{this.props.language !== "eng" ? "星期五" : "Fri"} :</div>
                                                <TextField
                                                    id="time"
                                                    label={this.props.language !== "eng" ? "開門時間" : "Opening"}
                                                    type="time"
                                                    value={(this.state.newSite.office_hour_fri.split('-'))[0]}
                                                    className="openingTimeInput"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 1800, // 5 min
                                                    }}
                                                    onChange={this.handleNewSiteTimeInput('office_hour_fri', 'open')}
                                                />
                                                <TextField
                                                    id="time"
                                                    label={this.props.language !== "eng" ? "關門時間" : "Closing"}
                                                    type="time"
                                                    value={(this.state.newSite.office_hour_fri.split('-'))[1]}
                                                    className="openingTimeInput"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 1800, // 5 min
                                                    }}
                                                    onChange={this.handleNewSiteTimeInput('office_hour_fri', 'close')}
                                                />
                                            </div>
                                            <div className="inputRowOnAddPackageComponent">
                                                <div className="tokenNameLabel">*{this.props.language !== "eng" ? "星期六" : "Sat"} :</div>
                                                <TextField
                                                    id="time"
                                                    label={this.props.language !== "eng" ? "開門時間" : "Opening"}
                                                    type="time"
                                                    value={(this.state.newSite.office_hour_sat.split('-'))[0]}
                                                    className="openingTimeInput"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 1800, // 5 min
                                                    }}
                                                    onChange={this.handleNewSiteTimeInput('office_hour_sat', 'open')}
                                                />
                                                <TextField
                                                    id="time"
                                                    label={this.props.language !== "eng" ? "關門時間" : "Closing"}
                                                    type="time"
                                                    value={(this.state.newSite.office_hour_sat.split('-'))[1]}
                                                    className="openingTimeInput"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 1800, // 5 min
                                                    }}
                                                    onChange={this.handleNewSiteTimeInput('office_hour_sat', 'close')}
                                                />
                                            </div>
                                            <div className="inputRowOnAddPackageComponent">
                                                <div className="tokenNameLabel">*{this.props.language !== "eng" ? "星期日" : "Sun"} :</div>
                                                <TextField
                                                    id="time"
                                                    label={this.props.language !== "eng" ? "開門時間" : "Opening"}
                                                    type="time"
                                                    value={(this.state.newSite.office_hour_sun.split('-'))[0]}
                                                    className="openingTimeInput"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 1800, // 5 min
                                                    }}
                                                    onChange={this.handleNewSiteTimeInput('office_hour_sun', 'open')}
                                                />
                                                <TextField
                                                    id="time"
                                                    label={this.props.language !== "eng" ? "關門時間" : "Closing"}
                                                    type="time"
                                                    value={(this.state.newSite.office_hour_sun.split('-'))[1]}
                                                    className="openingTimeInput"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 1800, // 5 min
                                                    }}
                                                    onChange={this.handleNewSiteTimeInput('office_hour_sun', 'close')}
                                                />
                                            </div>

                                        </div>
                                        :
                                        this.state.activeStep === 2 ?
                                        <div className="tokenContentInputContainer">
                                        <div className="inputRowOnAddPackageComponent">
                                            <div className="tokenNameLabel">*{this.props.language !== "eng" ? "*分校名稱" : "* Site Name"} :</div>
                                            <TextField className="packageNameInput" variant="outlined" value={this.state.newSite.sites} onChange={this.handleNewStaffDetailsInput('sites')} disabled/>
                                        </div>
                                        <div className="inputRowOnAddPackageComponent">
                                            <div className="tokenNameLabel">*{this.props.language !== "eng" ? "*分校簡稱" : "* Site Key"} :</div>
                                            <TextField className="packageNameInput"  variant="outlined" value={this.state.newSite.site_key} onChange={this.handleNewStaffDetailsInput('site_key')} disabled/>
                                        </div>
                                        <div className="inputRowOnAddPackageComponent">
                                            <div className="tokenNameLabel">*{this.props.language !== "eng" ? "週一營業" : "Monday"} :</div>
                                            <TextField className="packageNameInput"  variant="outlined" value={this.state.newSite.office_hour_mon} onChange={this.handleNewStaffDetailsInput('site_key')} disabled/>
                                        </div>
                                        <div className="inputRowOnAddPackageComponent">
                                            <div className="tokenNameLabel">*{this.props.language !== "eng" ? "週二營業" : "Tuesday"} :</div>
                                            <TextField className="packageNameInput"  variant="outlined" value={this.state.newSite.office_hour_tue} onChange={this.handleNewStaffDetailsInput('site_key')} disabled/>
                                        </div>
                                        <div className="inputRowOnAddPackageComponent">
                                            <div className="tokenNameLabel">*{this.props.language !== "eng" ? "週三營業" : "Wednesday"} :</div>
                                            <TextField className="packageNameInput"  variant="outlined" value={this.state.newSite.office_hour_wed} onChange={this.handleNewStaffDetailsInput('site_key')} disabled/>
                                        </div>
                                        <div className="inputRowOnAddPackageComponent">
                                            <div className="tokenNameLabel">*{this.props.language !== "eng" ? "週四營業" : "Thursday"} :</div>
                                            <TextField className="packageNameInput"  variant="outlined" value={this.state.newSite.office_hour_thu} onChange={this.handleNewStaffDetailsInput('site_key')} disabled/>
                                        </div>
                                        <div className="inputRowOnAddPackageComponent">
                                            <div className="tokenNameLabel">*{this.props.language !== "eng" ? "週五營業" : "Friday"} :</div>
                                            <TextField className="packageNameInput"  variant="outlined" value={this.state.newSite.office_hour_fri} onChange={this.handleNewStaffDetailsInput('site_key')} disabled/>
                                        </div>
                                        <div className="inputRowOnAddPackageComponent">
                                            <div className="tokenNameLabel">*{this.props.language !== "eng" ? "週六營業" : "Saturday"} :</div>
                                            <TextField className="packageNameInput"  variant="outlined" value={this.state.newSite.office_hour_sat} onChange={this.handleNewStaffDetailsInput('site_key')} disabled/>
                                        </div>
                                        <div className="inputRowOnAddPackageComponent">
                                            <div className="tokenNameLabel">*{this.props.language !== "eng" ? "週日營業" : "Sunday"} :</div>
                                            <TextField className="packageNameInput"  variant="outlined" value={this.state.newSite.office_hour_sun} onChange={this.handleNewStaffDetailsInput('site_key')} disabled/>
                                        </div>

                                    </div>

                                            : null
                            }
                        </div>
                    </div>

                    <div className="modifyStepsRowOnCreateMassTokenEdit">
                        {/* {this.state.activeStep === 0 ? <Button id="stepButtonNoShow" disabled>上一步</Button> : <Button id="stepButton" onClick={this.handlePreviousStepClick}>上一步</Button>} */}
                        {this.state.activeStep === 0 && this.state.newSite.sites !== '' && this.state.newSite.site_key !== '' ?
                            // <Button id="stepButtonOnCreateMassTokenEdit" onClick={this.handleConfirmAddClick}>確定新增集體改動</Button>
                            <Button id="stepButton" onClick={this.handleNextStepClick}>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>
                            :
                            this.state.activeStep === 1 ?
                                <Button id="stepButton" onClick={this.handleNextStepClick}>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>
                                :
                                this.state.activeStep === 2 ?
                                    <Button id="stepButtonOnCreateMassTokenEdit" onClick={this.handleConfirmAddClick}>{this.props.language !== "eng" ? "確定新增分校" : "Confirm New Site"}</Button>

                                    :
                                    <Button id="stepButtonDisabled" onClick={this.handleNextStepClick} disabled>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>


                        }
                    </div>

                </div>
            </div>
        )
    }
}

export default CreateSiteBox;