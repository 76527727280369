


export function allMassTokenEditRecordsLoaded (massTokenEditRecords:any){
    return{
        type: "ALL_MASS_TOKEN_EDIT_RECORDS_LOADED" as "ALL_MASS_TOKEN_EDIT_RECORDS_LOADED",
        massTokenEditRecords
    }
}

export function loading(){
    return {
        type: "LOADING" as "LOADING"
    }
}

export function newMassTokenEditRecordCreated () {
    return{
        type: "NEW_MASS_TOKEN_EDIT_RECORD_CREATED" as "NEW_MASS_TOKEN_EDIT_RECORD_CREATED"
    }
}


type MassTokenEditManageActionsCreators = typeof allMassTokenEditRecordsLoaded |
                                    typeof loading |
                                    typeof newMassTokenEditRecordCreated ;

export type IMassTokenEditManageActions = ReturnType<MassTokenEditManageActionsCreators>;