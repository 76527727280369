import { ThunkDispatch } from "../store";
import {    allCustomerLoaded , infoInfrastructureLoaded ,
            packagesWithRightUsageLoaded ,purchasePackagesDetailsLoaded ,
            discountOwnBySelectedCustomerLoaded, newPurchaseSubmittedSuccessfully,
            packagesOwnByCustomerLoaded, periodPackageActivated, purchaseTermLibraryLoaded, discountAddedToCustomer,  discountDelFromCustomer, relatedGuardianDetailsLoaded, relatedGuardianDetailsCreated, relatedGuardianDetailsUpdated, relatedGuardianDetailsDeleted, customerGroupingIDUpdated, customerPackageUpdatedManually, markReferralDetailsAfterwardsSuccess, newPurchaseSubmittedWithNoTokenBookingNeedsToBeHandled, noTokenBookingProcessed, allProductStorageSummaryLoaded, purchaseProductsDetailsLoaded, targetProductReleased
        
        } from "./actions";

export async function customerPage(page: number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customer/loadAllCustomerInfos?listingPage=${page}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,

        }
    })
    let result: any = await res.json()
    console.log(result);
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    } else {

        return async (dispatch: ThunkDispatch) => {

            dispatch(allCustomerLoaded(result.result, result.totalCustomers[0].count, result.allCustomerGroupings ));

        }
    }
}

export async function updateThisCustomerBasicInfos(newCustomerInfos: any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customer/updateThisCustomerBasicInfos`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,

        },
        body: JSON.stringify(newCustomerInfos)
    })
    let result: any = await res.json()
    console.log(result);
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    } else {
        const res02 = await fetch(`${process.env.REACT_APP_API_SERVER}/customer/loadAllCustomerInfos?listingPage=${newCustomerInfos.listingPage}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`,

            }
        })
        let result02: any = await res02.json()

        if (res02.status == 500) {
            return async (dispatch: ThunkDispatch) => {

                console.log(result);
            }
        } else {

            return async (dispatch: ThunkDispatch) => {

                dispatch(allCustomerLoaded(result02.result, result02.totalCustomers[0].count,result02.allCustomerGroupings));

            }
        }
    }
}

export async function loadGenreTypeList() {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/manageCustomerPurchasePackages/loadInfoInfrastructures`,{
        method: 'GET',
        headers:{
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    })

    let result:any  = await res.json();
    if(res.status === 500){
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    } else {
        return async( dispatch: ThunkDispatch) => {
            dispatch(infoInfrastructureLoaded(result.genreTypes, result.ageGroups ,result.tokenUsages, result.allSites))
        }
    }
}

export async function loadPackagesWithUsageID(usageID: Number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/manageCustomerPurchasePackages/loadAllPackagesWithRightUsage?packageUsageID=${usageID}`,{
        method: 'GET',
        headers:{
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    })

    let result = await res.json();
    if(res.status === 500){
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    } else {
        return async( dispatch: ThunkDispatch) => {
            dispatch(packagesWithRightUsageLoaded(result));
        }
    }
}

export async function loadPackagesDetailsForPurchase(packagesSimInfoArr: any) {
    let suitableQuery = '';
    for(let packageSimInfo of packagesSimInfoArr){
        suitableQuery += `packagesForPurchase[]=${packageSimInfo.id}&` 
    }
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/manageCustomerPurchasePackages/loadDetailsOfPackagesForPurchase?${suitableQuery}`,{
        method: 'GET',
        headers:{
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    })
    let result = await res.json();
    if(res.status === 500){
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    } else {
        return async( dispatch: ThunkDispatch) => {
            dispatch(purchasePackagesDetailsLoaded(result));
        }
    }
}

export async function loadProductsDetailsForPurchase(productSimInfoArr:any) {
    let productSKUArray = [];
    for(let productSimInfo of productSimInfoArr){
        productSKUArray.push(productSimInfo.SKU);
    }
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/stockManage/loadDetailsOfProductsForPurchase`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
            productsForPurchase: productSKUArray
        })
    });

    let result = await res.json();
    if(res.status === 500) {
        return async (dispatch:ThunkDispatch) => {
            console.log(result)
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(purchaseProductsDetailsLoaded(result.productDetails));
        }
    }
}

export async function releaseTargetProduct(productArr: any[]) {
    let productIDArray = [];
    for(let product of productArr){
        productIDArray.push(product.id)
    }
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/stockManage/releaseTargetProductForPurchase`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
            productIDArray
        })
    });

    let result = await res.json();
    if(res.status === 500) {
        return async (dispatch:ThunkDispatch) => {
            console.log(result)
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(targetProductReleased());
        }
    }
}

export async function loadDiscountsOfCustomer(userID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/manageCustomerDiscounts/loadAllDiscountOwnByCustomer?userID=${userID}`,{
        method: 'GET',
        headers:{
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    })
    let result = await res.json();
    if(res.status === 500) {
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    }else {
        return async( dispatch: ThunkDispatch) => {
            dispatch(discountOwnBySelectedCustomerLoaded(result));
        }
    }
}

export async function submitNewPurchase(dataTrain : any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/staffProcessPurchases/staffProcessedNewPurchases`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(dataTrain)
    });

    const result = await res.json();
    if(res.status === 500) {
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    }else {
        if(result.previousNoTokenBooking.length === 0){
            return async( dispatch: ThunkDispatch) => {
                dispatch(newPurchaseSubmittedSuccessfully());
            }
        } else {
            return async( dispatch: ThunkDispatch) => {
                dispatch(newPurchaseSubmittedWithNoTokenBookingNeedsToBeHandled(result.suitableNewPackages, result.previousNoTokenBooking));
            }
        }
    }
}

export async function loadPackagesOwnByCustomer(customerUserID: number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customer/loadCustomerPackages?customerUserID=${customerUserID}`,{
        method: 'GET',
        headers:{
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    })
    const result = await res.json();
    if(res.status === 500) {
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    }else {
        return async( dispatch: ThunkDispatch) => {
            dispatch(packagesOwnByCustomerLoaded(result));
        }
    }
}

export async function periodPackageActivation(packageID : number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/manageCustomerPurchasePackages/updatePeriodPackagesActivation`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            packageID : packageID
        })
    });
    const result = await res.json();

    if(res.status === 500) {
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    }else {
        return async( dispatch: ThunkDispatch) => {
            dispatch(periodPackageActivated());
        }
    }
}

export async function loadPurchaseTermsLibrary() {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/staffProcessPurchases/loadPurchaseTermsLibrary`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });
    const result = await res.json();

    if(res.status === 500) {
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    }else {
        return async( dispatch: ThunkDispatch) => {
            dispatch(purchaseTermLibraryLoaded(result));
        }
    }
}
export async function searchCustomerInfo (searchMethod:string,searchInput: any) {
    
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customerParticipation/loadCustomerInfoBySearchingInput?searchMethod=${searchMethod}&searchInput=${searchInput}`, {
        method: 'GET',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        }
    })
    let result : any= await res.json();

    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        
        return async (dispatch:ThunkDispatch) => {
            
            // dispatch(customerSearchResultLoaded(result.customerSearchResult));
            dispatch(allCustomerLoaded(result.customerSearchResult, result.customerSearchResult.length,result.allCustomerGroupings));

            console.log(result)
        }
    }
}

export async function addDiscountToCustomer(discountDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customer/addDiscountForCustomer`, {
        method: 'POST',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            discountDetails:discountDetails
        })
    });
    let result : any= await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch:ThunkDispatch) => {
            dispatch(discountAddedToCustomer());

        }
    }
}


export async function delCustomerDiscounts (customerDiscountID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customer/delDiscountForCustomer`, {
        method: 'DELETE',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            customerDiscountID : customerDiscountID
        })
    });
    let result : any= await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch:ThunkDispatch) => {
            dispatch(discountDelFromCustomer());
        }
    }
}

export async function loadCustomerRelatedGuardianDetails (guardianID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customer/loadCustomerGuardianDetails?customerGuardianID=${guardianID}`, {
        method: 'GET',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result : any= await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch:ThunkDispatch) => {
            dispatch(relatedGuardianDetailsLoaded(result));
        }
    }
}
export async function createCustomerRelatedGuardianDetails (guardianDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customer/createGuardianDetails`, {
        method: 'POST',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            guardianDetails:guardianDetails
        })
    });
    let result : any= await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch:ThunkDispatch) => {
            dispatch(relatedGuardianDetailsCreated(guardianDetails));
        }
    }
}
export async function updateCustomerRelatedGuardianDetails (guardianDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customer/updateGuardianDetails`, {
        method: 'PUT',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            guardianDetails:guardianDetails
        })
    });
    let result : any= await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch:ThunkDispatch) => {
            dispatch(relatedGuardianDetailsUpdated(guardianDetails));
        }
    }
}
export async function deleteCustomerRelatedGuardianDetails (guardianID:any, customerID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customer/delGuardianDetails`, {
        method: 'DELETE',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            guardianID:guardianID,
            customerID:customerID
        })
    });
    let result : any= await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch:ThunkDispatch) => {
            dispatch(relatedGuardianDetailsDeleted());
        }
    }
}

export async function updateCustomerGroupingID (customerGroupingID:number, customerID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customer/updateCustomerGroupingID`, {
        method: 'PUT',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            customerGroupID:customerGroupingID,
            customerID:customerID
        })
    });
    let result : any= await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch:ThunkDispatch) => {
            dispatch(customerGroupingIDUpdated());
        }
    }
}

export async function updateCustomerPackageDetailsManually (packageDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/manageCustomerPurchasePackages/updateCustomerPackageDetailsManually`, {
        method: 'PUT',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            packageDetails:packageDetails
        })
    });
    let result : any= await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch:ThunkDispatch) => {
            dispatch(customerPackageUpdatedManually());
        }
    }
}

export async function updateReferralDetailsAfterwards (referringCustomerID:number, customerID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/manageCustomerDiscounts/markReferralDetailsAfterwards`, {
        method: 'POST',
        headers:{
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            referringCustomerID: referringCustomerID,
            customerID: customerID
        })
    });
    let result : any= await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch:ThunkDispatch) => {
            dispatch(markReferralDetailsAfterwardsSuccess());
        }
    }
}

export async function processPreviousNoTokenBookingWithNewPackages (previousNoTokenBooking:any, selectedPackageID:number){
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customerParticipation/createNewClassParticipationDetailsForPreviousNoTokenBooking`, {
        method: 'POST',
        headers:{
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            previousBookingDetails: previousNoTokenBooking,
            selectedPackageID: selectedPackageID
        })
    });
    let result : any= await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch:ThunkDispatch) => {
            dispatch(noTokenBookingProcessed());
        }
    }
}

//PRODUCTS

export async function loadAllProductStorageSummary () {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/stockManage/loadAllUniqueStockNameBasicInfo`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(allProductStorageSummaryLoaded(result.inventorySummary, result.allSites, result.allAttributeTypes, result.allCategoryTypes));
        }
    }}