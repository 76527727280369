import { ThunkDispatch } from "../store";
import { allTutorLoaded } from "../Tutors/actions";
import { newOccpancyDetailsUpdateSubmittedWithFailedUniqueCheck, newOccupancyDetailsUpdateSubmitted, occupancyParticipationRelatedInfoLoaded ,cxlClassParticipationWithReasonProcessed, delRentalPrivateOccupancyBookingWithReasonCompleted, delCustomerParticipationByStaffWihtoutReason, backwardCxlClassParticipationWithReasonProcessed} from "./actions";

export async function delThisRoomOccupancy (data:any) {
    let occupancyId = data.occupancyId
    let staffUserID = data.staffUserID
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/schedule/delClassOccupancy`, {
        method: 'DELETE',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        },
        body: JSON.stringify({ occupancyId, staffUserID })
    })
    let result : any= await res.json()
    console.log(result);
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            
            console.log(result);
        }
    } else {
        
        return async (dispatch:ThunkDispatch) => {
            
            // dispatch(allTutorLoaded(result02.data,result02.employmentStatuses,result02.genreTypes, result02.ageGroups));
            console.log(result)
        }
    }
}

export async function updateClassOccupancyAndTutorList (editedOccupancyDetails: any) {
    
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/schedule/updateClassOccupancy&TutorList`, {
        method: 'PUT',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        },
        body: JSON.stringify({ editedOccupancyDetails })
    })
    let result : any= await res.json()
    console.log(result);
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            
            console.log(result);
        }
    } else {
        if(result.result === 'Normal'){
            return async (dispatch:ThunkDispatch) => {
                dispatch(newOccupancyDetailsUpdateSubmitted());
            }
        } else {
            return async (dispatch:ThunkDispatch) => {
                dispatch(newOccpancyDetailsUpdateSubmittedWithFailedUniqueCheck(result));
            }
        }
        
    }
}

export async function updateClassOccupancyAndTutorListWithNewUSG (editedOccupancyDetails: any, newUSGDetails: any) {
    
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/schedule/updateClassOccupancy&TutorListWithNewUSG`, {
        method: 'PUT',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        },
        body: JSON.stringify({ 
            editedOccupancyDetails : editedOccupancyDetails,
            newUSGDetails: newUSGDetails
        })
    })
    let result : any= await res.json()
    console.log(result);
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
            return async (dispatch:ThunkDispatch) => {
                dispatch(newOccupancyDetailsUpdateSubmitted());
            }
    }
}

export async function updateClassOccupancyAndTutorListWithUSG (editedOccupancyDetails: any, newUSGDetails: any) {
    
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/schedule/updateClassOccupancy&TutorListWithUSG`, {
        method: 'PUT',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        },
        body: JSON.stringify({ 
            editedOccupancyDetails : editedOccupancyDetails,
            USGID: newUSGDetails[0].id
        })
    })
    let result : any= await res.json()
    console.log(result);
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
            return async (dispatch:ThunkDispatch) => {
                dispatch(newOccupancyDetailsUpdateSubmitted());
            }
    }
}

export async function loadOccupancyRelatedParticipationInformation (roomOccupancyID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customerParticipation/loadCustomerParticipationOfSelectedRoomOccupancy?roomOccupancyID=${roomOccupancyID}`, {
        method: 'GET',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,
        }
    });

    let result = await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
            return async (dispatch:ThunkDispatch) => {
                dispatch(occupancyParticipationRelatedInfoLoaded(result));
            }
    }
}

export async function updateParticipationDetailsWithAcceptableCxlReason (participationDetails: any, absentReasonID: number, staffUserID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customerParticipation/cxlClassParticipationWithAcceptableReason`, {
        method: 'PUT',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        },
        body: JSON.stringify({ 
            participationDetails : participationDetails,
            absentReasonID: absentReasonID,
            staffUserID: staffUserID
        })
    })

    let result = await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
            return async (dispatch:ThunkDispatch) => {
                dispatch(cxlClassParticipationWithReasonProcessed());
            }
    }
}

export async function updateParticipationDetailsWithAcceptableCxlReasonBackward (participationDetails: any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customerParticipation/backwardCxlClassParticipationWithAcceptableReason`, {
        method: 'PUT',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        },
        body: JSON.stringify({ 
            participationDetails : participationDetails
        })
    })

    let result = await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
            return async (dispatch:ThunkDispatch) => {
                dispatch(backwardCxlClassParticipationWithReasonProcessed());
            }
    }
}

export async function delRentalPrivateOccupancyBookingWithReason (occupancyDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/schedule/delRentalPrivateOccupancyBooking`, {
        method: 'PUT',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        },
        body: JSON.stringify({occupancyDetails:occupancyDetails})
    });

    let result = await res.json();

    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        
        return async (dispatch:ThunkDispatch) => {
            dispatch(delRentalPrivateOccupancyBookingWithReasonCompleted());
            console.log(result)
        }
    }
}

export async function delParticipationWithoutReasonByStaff (participationDetails: any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customerParticipation/cxlClassParticipationWithoutReason`, {
        method: 'DELETE',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        },
        body: JSON.stringify({participationDetails:participationDetails})
    });
    let result = await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        
        return async (dispatch:ThunkDispatch) => {
            dispatch(delCustomerParticipationByStaffWihtoutReason());
            console.log(result)
        }
    }
}