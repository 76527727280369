import { ISitesClassroomsManageState } from './state';
import { ISitesClassroomsManageActions } from './actions';

const initialState = {
    allSites: [],
    allClassrooms: [],
    delClassroomFailed: false,
    loading: true
}

export const sitesClassroomsManageReducer = (state: ISitesClassroomsManageState = initialState, actions: ISitesClassroomsManageActions) => {
    switch (actions.type) {

        case "ALL_SITES_CLASSROOMS_LOADED":
            return {
                ...state,
                allSites: actions.sites,
                allClassrooms: actions.classrooms,
                loading: false
            }
        case "CLASSROOM_UPDATED" :
            return {
                ...state,
                loading: true
            }
        case "CLASSROOM_CREATED" :
            return {
                ...state,
                loading:true
            }
        case "CLASSROOM_DELETED" :
            return {
                ...state,
                loading:true
            }
        case "CLASSROOM_DELETE_FAILED":
            return {
                ...state,
                delClassroomFailed: true,
                loading:false
            }
        case "CLASSROOM_DELETE_FAILED_NOTICED":
            return {
                ...state,
                delClassroomFailed: false,
                loading: true
            }
        case "SITE_UPDATED":
            return {
                ...state,
                loading: true
            }
        case "SITE_CREATED" :
            return {
                ...state,
                loading: true
            }
            default:
                return state;
    }
}