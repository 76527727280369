import React from "react";
import './TermsManage.css'
import { connect } from "react-redux";
import { IRootState, ThunkDispatch } from "../../store";
import { loadTermsData, updateTerm, createTerm, updateTermActiveSetting } from './thunks';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Button, TextField } from "@material-ui/core";
import { ChromePicker } from 'react-color';
import EditIcon from '@material-ui/icons/Edit';
import GavelIcon from '@material-ui/icons/Gavel';
import CircularProgress from '@material-ui/core/CircularProgress';


export interface ITermsManageProps {
    loadAllTerms: () => void,
    updateTermDetails: (termDetails: any) => void,
    createNewTermDetails: (termDetails: any) => void,
    delTermDetails: (termID: number) => void,
    // exitFromNoticeWindow: () => void,
    // updateSiteDetails: (siteDetails:any) => void,
    allTerms: any,
    loading: boolean,
    language: string
    // delClassroomFailed: boolean
}
export interface ITermsManageState {
    selectedTermID: number,
    selectedTermDetails: any,
    addNewTerm: boolean,
}
class TermsManage extends React.Component<ITermsManageProps, ITermsManageState> {
    constructor(props: ITermsManageProps) {
        super(props);
        this.state = {
            selectedTermID: 0,
            selectedTermDetails: '',
            addNewTerm: false
        }
    }

    private handleEditTermClick = async (termID: number) => {
        let termDetails = this.props.allTerms.filter((term: any) => term.id === termID)
        await this.setState({
            selectedTermID: termID,
            selectedTermDetails: termDetails[0]
        })
    }
    private handleTermContentInput = async (event: any) => {
        await this.setState({
            selectedTermDetails: {
                ...this.state.selectedTermDetails,
                content: event.target.value
            }
        })
    }

    private handleSaveTermDetails = async () => {
        this.props.updateTermDetails(this.state.selectedTermDetails);
        await this.setState({
            selectedTermID: 0,
            selectedTermDetails: ''
        })
        setTimeout(async () => await this.props.loadAllTerms(), 1000);
    }
    private handleAddNewTermDetails = async () => {
        await this.setState({
            addNewTerm: true,
            selectedTermDetails: {
                content: '',
                active: true,
                base_terms: false,
                term_order: 1
            }
        })
    }
    private handleSaveNewTermDetails = async () => {
        if (this.state.selectedTermDetails.content === '') {
            window.alert('請輸入條款內容！')
        } else {
            await this.props.createNewTermDetails(this.state.selectedTermDetails);
            await this.setState({
                selectedTermID: 0,
                selectedTermDetails: '',
                addNewTerm: false,
            })
            setTimeout(async () => await this.props.loadAllTerms(), 1000);

        }
    }

    private handleDelTermDetails = async (termID: number) => {
        let confirmation = await window.confirm('你確定要隱藏此條款嗎？');
        if (confirmation) {
            await this.props.delTermDetails(termID);
            setTimeout(async () => await this.props.loadAllTerms(), 1000);

        }
    }

    private handleBaseTermInput = async () => {
        await this.setState({
            selectedTermDetails: {
                ...this.state.selectedTermDetails,
                base_terms: !this.state.selectedTermDetails.base_terms
            }
        })
    }

    private handleTermOrderInput = async (event: any) => {
        if (parseInt(event.target.value) >= 0) {
            await this.setState({
                selectedTermDetails: {
                    ...this.state.selectedTermDetails,
                    term_order: parseInt(event.target.value)
                }
            })
        }
    }

    componentWillMount() {
        this.props.loadAllTerms();
    }

    render() {
        let termsRenderArr = this.props.allTerms;
        termsRenderArr.sort(function (a: any, b: any) {
            if (a.term_order === b.term_order) {
                return (a.id < b.id) ? -1 : (a.id > b.id) ? 1 : 0
            } else {
                return (a.term_order > b.term_order) ? 1 : -1
            }
        });

        return (
            <div className="row">
                <div className="col-12">
                    <div className="systemMainDisplayArea row">
                        <div className="systemManagePageTitle col-12"> <GavelIcon id="systemManPageTitleIcon" /> {this.props.language !== "eng" ? "條款管理" : "Terms Manage"}</div>
                        {
                            this.props.loading ?
                                <div className="loadingContainerRow">
                                    <div className="inlineDisplayNoMargin">
                                        <div className="loadingContainer">
                                            <CircularProgress id="loadingCircle" />
                                            <div className="loadingLabel">Loading ...</div>
                                        </div>
                                    </div>
                                </div>
                                :

                                <div className="siteDisplayArea col-12">
                                    <div className="siteTitle">{this.props.language !== "eng" ? "條款詳情" : "Terms Details"}</div>
                                    {/* <div className="groupingFixedHeightBox"> */}
                                    {//RENDER ALL ACTIVE
                                        termsRenderArr.map((term: any) =>
                                            !term.active ? null :
                                                <div className="classroomRow row ">
                                                    <div className="termContentBox col-xl-6 col-lg-6 col-md-12 mb-1">
                                                        <div className="containerOnSitesMan row">
                                                            <div className="inlineDisplayNoMargin col-lg-2 col-md-3">{this.props.language !== "eng" ? "條款" : "Terms"} : </div>
                                                            {
                                                                this.state.selectedTermID !== term.id ?
                                                                    <div className="inlineDisplayNoMargin termDetailsDisplayField col-lg-10 col-md-9"> {term.content} </div>
                                                                    :
                                                                    <div className="col-lg-10 col-md-9">
                                                                        <TextField className="termDetailsEditingInputs" variant="outlined" value={this.state.selectedTermDetails.content} onChange={this.handleTermContentInput} />
                                                                    </div>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="baseTermBox col-xl-2 col-lg-2 col-md-4 aligner">
                                                        <div className="containerOnSitesMan row">
                                                            {
                                                                this.state.selectedTermID !== term.id ?
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={term.base_terms}
                                                                                name="baseTerm"
                                                                                color="primary"
                                                                            />
                                                                        }

                                                                        label={this.props.language !== "eng" ? "基本條款" : "Root Term"}
                                                                        labelPlacement="start"
                                                                        className="flexendAligner"
                                                                    />
                                                                    :
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={this.state.selectedTermDetails.base_terms}
                                                                                onChange={this.handleBaseTermInput}
                                                                                name="baseTerm"
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label={this.props.language !== "eng" ? "基本條款" : "Root Term"}
                                                                        labelPlacement="start"
                                                                        className="flexendAligner"
                                                                    />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="termStatusBox col-xl-2 col-lg-2 col-md-4">
                                                        <div className="containerOnSitesMan row">
                                                            <div className="inlineDisplayNoMargin col-6">{this.props.language !== "eng" ? "次序" : "Order"} : </div>
                                                            {
                                                                this.state.selectedTermID !== term.id ?
                                                                    <div className="inlineDisplayNoMargin detailsDisplayField col-6"> {term.term_order} </div>
                                                                    :
                                                                    <div className="col-6">
                                                                        <TextField className="detailsEditingInputs" type="number" variant="outlined" value={this.state.selectedTermDetails.term_order} onChange={this.handleTermOrderInput} />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="termStatusBox col-xl-2 col-lg-2 col-md-4">
                                                        <div className="containerOnSitesMan row">
                                                            <div className="inlineDisplayNoMargin col-6">{this.props.language !== "eng" ? "狀態" : "State"} :  </div>
                                                            {
                                                                this.state.selectedTermID !== term.id ?
                                                                    <div className="inlineDisplayNoMargin detailsDisplayField col-6"> {this.props.language !== "eng" ? term.active ? "現正生效" : "已隱藏" : term.active ? "Active" : "Inactive"} </div>
                                                                    :
                                                                    <div className="col-6">
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.props.language !== "eng" ? this.state.selectedTermDetails.active ? "現正生效" : "已隱藏" : this.state.selectedTermDetails.active ? "Active" : "Inactive"} disabled />
                                                                    </div>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="editClassroomBtn">
                                                        {
                                                            this.state.selectedTermID !== term.id ?
                                                                <div className="btnContainer">
                                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={() => this.handleEditTermClick(term.id)}>{this.props.language !== "eng" ? "修改" : "Edit"}</Button>
                                                                    <Button id="clickToDelClassroomBtn" onClick={() => this.handleDelTermDetails(term.id)}>{this.props.language !== "eng" ? "隱藏條款" : "Inactive Term"}</Button>
                                                                </div>
                                                                :
                                                                <div className="btnContainer">
                                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveTermDetails} >{this.props.language !== "eng" ? "儲存條款" : "Save Term"}</Button>
                                                                </div>
                                                        }
                                                    </div>

                                                </div>
                                        )
                                    }
                                    {//RENDER ALL INACTIVE
                                        termsRenderArr.map((term: any) =>
                                            term.active ? null :
                                                <div className="classroomRow">
                                                    <div className="termContentBox">
                                                        <div className="containerOnSitesMan">
                                                            <div className="inlineDisplayNoMargin">{this.props.language !== "eng" ? "條款" : "Terms"} : </div>
                                                            {
                                                                this.state.selectedTermID !== term.id ?
                                                                    <div className="inlineDisplayNoMargin termDetailsDisplayField"> {term.content} </div>
                                                                    :
                                                                    <TextField className="termDetailsEditingInputs" variant="outlined" value={this.state.selectedTermDetails.content} onChange={this.handleTermContentInput} />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="baseTermBox">
                                                        <div className="containerOnSitesMan">
                                                            {
                                                                this.state.selectedTermID !== term.id ?
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={term.base_terms}
                                                                                name="baseTerm"
                                                                                color="primary"
                                                                            />
                                                                        }

                                                                        label={this.props.language !== "eng" ? "基本條款" : "Root Term"}
                                                                        labelPlacement="top"
                                                                    />
                                                                    :
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={this.state.selectedTermDetails.base_terms}
                                                                                onChange={this.handleBaseTermInput}
                                                                                name="baseTerm"
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label={this.props.language !== "eng" ? "基本條款" : "Root Term"}
                                                                        labelPlacement="top"
                                                                    />

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="termStatusBox">
                                                        <div className="containerOnSitesMan">
                                                            <div className="inlineDisplayNoMargin">{this.props.language !== "eng" ? "次序" : "Order"} : </div>
                                                            {
                                                                this.state.selectedTermID !== term.id ?
                                                                    <div className="inlineDisplayNoMargin detailsDisplayField"> {term.term_order} </div>
                                                                    :
                                                                    <TextField className="detailsEditingInputs" type="number" variant="outlined" value={this.state.selectedTermDetails.term_order} disabled />
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="termStatusBox">
                                                        <div className="containerOnSitesMan">
                                                            <div className="inlineDisplayNoMargin">{this.props.language !== "eng" ? "狀態" : "State"} :  </div>
                                                            {
                                                                this.state.selectedTermID !== term.id ?
                                                                    <div className="inlineDisplayNoMargin detailsDisplayField"> {this.props.language !== "eng" ? term.active ? "現正生效" : "已隱藏" : term.active ? "Active" : "Inactive"} </div>
                                                                    :
                                                                    <TextField className="detailsEditingInputs" variant="outlined" value={this.props.language !== "eng" ? this.state.selectedTermDetails.active ? "現正生效" : "已隱藏" : this.state.selectedTermDetails.active ? "Active" : "Inactive"} disabled />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="editClassroomBtn">
                                                        {
                                                            this.state.selectedTermID !== term.id ?
                                                                <div className="btnContainer">
                                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={() => this.handleEditTermClick(term.id)}>{this.props.language !== "eng" ? "修改" : "Edit"}</Button>
                                                                    <Button id="clickToRecoverBtn" onClick={() => this.handleDelTermDetails(term.id)}>{this.props.language !== "eng" ? "恢復條款" : "Activate Term"}</Button>
                                                                </div>
                                                                :
                                                                <div className="btnContainer">
                                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveTermDetails} >{this.props.language !== "eng" ? "儲存條款" : "Save Term"}</Button>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                        )
                                    }
                                    {/* </div> */}
                                    {
                                        !this.state.addNewTerm ?
                                            <div className="addClassroomRow">
                                                <Button id="clickToAddNewClassroomBtn" onClick={() => this.handleAddNewTermDetails()}>{this.props.language !== "eng" ? "新增條款" : "Create  New Term"}</Button>
                                            </div>
                                            :
                                            <div className="classroomRow">
                                                <div className="createTermContentBox">
                                                    <div className="containerOnSitesMan">
                                                        <div className="inlineDisplayNoMargin">{this.props.language !== "eng" ? "條款" : "Terms"} : </div>
                                                        <TextField className="termDetailsEditingInputs" variant="outlined" value={this.state.selectedTermDetails.content} onChange={this.handleTermContentInput} />

                                                    </div>
                                                </div>
                                                <div className="baseTermBox">
                                                    <div className="containerOnSitesMan">
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={this.state.selectedTermDetails.base_terms}
                                                                    onChange={this.handleBaseTermInput}
                                                                    name="baseTerm"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={this.props.language !== "eng" ? "基本條款" : "Root Term"}
                                                            labelPlacement="top"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="termStatusBox">
                                                    <div className="containerOnSitesMan">
                                                        <div className="inlineDisplayNoMargin">{this.props.language !== "eng" ? "次序" : "Order"} : </div>

                                                        <TextField className="detailsEditingInputs" type="number" variant="outlined" value={this.state.selectedTermDetails.term_order} onChange={this.handleTermOrderInput} />

                                                    </div>
                                                </div>

                                                <div className="editClassroomBtn">
                                                    <div className="btnContainer">
                                                        <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveNewTermDetails} >{this.props.language !== "eng" ? "儲存條款" : "Save Term"}</Button>
                                                    </div>
                                                </div>

                                            </div>
                                    }


                                </div>

                        }
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        allTerms: state.termsManage.allTerms,
        loading: state.termsManage.loading,
        language: state.auth.language
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        loadAllTerms: async () => dispatch(await loadTermsData()),
        updateTermDetails: async (genreDetails: any) => dispatch(await updateTerm(genreDetails)),
        createNewTermDetails: async (genreDetails: any) => dispatch(await createTerm(genreDetails)),
        delTermDetails: async (genreID: number) => dispatch(await updateTermActiveSetting(genreID)),
        // exitFromNoticeWindow: async () => dispatch(await classroomDeleteFailedNoticed()),
        // updateSiteDetails: async (siteDetails:any) => dispatch(await updateSiteDetails(siteDetails))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsManage)
