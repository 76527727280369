import React from "react";
import { IRootState, ThunkDispatch } from "../../store";
import { loadCustomerPackageUsageHistoryRelatedData } from './thunks'
import { connect } from "react-redux";
import './CustomerPackageUsageHistory.css'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import moment from "moment";


export interface ICustomerPackageUsageHistoryProps {
    language: string,
    customerPackages: any[],
    relatedParticipation: any[],
    relatedClassOccupancy: any[],
    relatedPrivateRentalOccupancy: any[],
    genreList: any[],
    relatedTutorListOfOccupancy: any[],
    requiredTutorInfo: any[],
    requiredPurchasedPackageDetails: any[],
    selectedCustomerID: number,
    siteLists: any[],
    handleLoadCustomerPackageUsageHistoryRelatedData: (customerID: number) => void
}

export interface ICustomerPackageUsageHistoryState {
    selectedPackageID: number,
    selectedPackageDetails: any,
    selectedPurchasedPackageDetails: any
}

class CustomerPackageUsageHistory extends React.Component<ICustomerPackageUsageHistoryProps, ICustomerPackageUsageHistoryState> {
    constructor(props: ICustomerPackageUsageHistoryProps) {
        super(props);
        this.state = {
            selectedPackageID: 0,
            selectedPackageDetails: '',
            selectedPurchasedPackageDetails: ''
        }
    }

    private handleSelectPackage = async (packageID: number) => {
        if (packageID === this.state.selectedPackageID) {
            await this.setState({
                selectedPackageID: 0,
                selectedPackageDetails: '',
                selectedPurchasedPackageDetails: ''
            })
        } else {
            await this.setState({
                selectedPackageID: packageID,
                selectedPackageDetails: this.props.customerPackages.find((targetPackage: any) => targetPackage.id === packageID),
                selectedPurchasedPackageDetails: this.props.requiredPurchasedPackageDetails.find((targetPackage: any) => targetPackage.id === (this.props.customerPackages.find((targetPackage: any) => targetPackage.id === packageID)).purchased_package_id)
            })
        }
    }

    componentWillMount() {
        this.props.handleLoadCustomerPackageUsageHistoryRelatedData(this.props.selectedCustomerID);
    }

    render() {
        let packageUsageRenderer = [];
        if (this.state.selectedPackageID === 0) {
            for (let occupancy of this.props.relatedClassOccupancy) {
                occupancy.class = true
                packageUsageRenderer.push(occupancy)
            }
            for (let occupancy of this.props.relatedPrivateRentalOccupancy) {
                occupancy.class = false
                packageUsageRenderer.push(occupancy)
            }

        } else {
            for (let occupancy of this.props.relatedPrivateRentalOccupancy) {
                if (occupancy.consumed_package_id[0] === this.state.selectedPackageID) {
                    occupancy.class = false
                    packageUsageRenderer.push(occupancy)
                }
            }
            if (packageUsageRenderer.length === 0) {
                let targetOccupancyIDs = []
                for (let participation of this.props.relatedParticipation) {
                    if (participation.customer_package_id === this.state.selectedPackageID) {
                        targetOccupancyIDs.push(participation.room_occupancy_detail_id)
                    }
                }
                for (let id of targetOccupancyIDs) {
                    let targetOccupancy = this.props.relatedClassOccupancy.find((occupancy: any) => occupancy.id === id);
                    packageUsageRenderer.push(targetOccupancy)
                }
            }

        }
        packageUsageRenderer.sort((a: any, b: any) =>
            Date.parse(`${b.start_time}`) - Date.parse(`${a.start_time}`)
        )
        console.log(packageUsageRenderer)
        return (
            <div className="contentFixSizePackagesBlockOnCustomerPackageUsageHistory">
                <div className="mainContentAreaOnCustomerPackageUsageHistory">
                    <div className="packagePickingBar">
                        {/* <div className="customerPackageUsageHistoryBlockTitleRow">
                            <h3 className="blockTitleRowOnCustomerPackageUsageHistory">顧客服務使用紀錄</h3>
                        </div> */}

                        <div className="packageBarTitleRow">
                            <div className="packageBarTitle">{this.props.language !== "eng" ? "顧客擁有的課程" : "Customer Package List"} :</div>
                        </div>
                        <div className="packageBarDisplayArea">
                            {
                                this.props.customerPackages.map((targetPackage: any) =>
                                    this.state.selectedPackageID === targetPackage.id ?
                                        !targetPackage.end_date ?
                                            //NOT ACTIVE PACKAGE
                                            <div className="packageRowClicked" onClick={() => this.handleSelectPackage(targetPackage.id)}>
                                                <div className="packageName">{targetPackage.package_name}</div>
                                                <FiberManualRecordIcon id="packageInactive" />
                                            </div>
                                            :
                                            moment(targetPackage.end_date) > moment() && targetPackage.token_based_package && targetPackage.token_amount ?
                                                //TOKEN PACKAGE VALID
                                                <div className="packageRowClicked" onClick={() => this.handleSelectPackage(targetPackage.id)}>
                                                    <div className="packageName">{targetPackage.package_name}</div>
                                                    <FiberManualRecordIcon id="packageAvailable" />
                                                </div>
                                                :
                                                moment(targetPackage.end_date) > moment() && !targetPackage.token_based_package ?
                                                    //NON TOKEN PACKAGE VALID
                                                    <div className="packageRowClicked" onClick={() => this.handleSelectPackage(targetPackage.id)}>
                                                        <div className="packageName">{targetPackage.package_name}</div>
                                                        <FiberManualRecordIcon id="packageAvailable" />
                                                    </div>
                                                    :
                                                    //PACKAGE NOT VALID
                                                    <div className="packageRowClicked" onClick={() => this.handleSelectPackage(targetPackage.id)}>
                                                        <div className="packageName">{targetPackage.package_name}</div>
                                                        <FiberManualRecordIcon id="packageNotAvailable" />
                                                    </div>
                                        :
                                        !targetPackage.end_date ?
                                            //NOT ACTIVE PACKAGE
                                            <div className="packageRow" onClick={() => this.handleSelectPackage(targetPackage.id)}>
                                                <div className="packageName">{targetPackage.package_name}</div>
                                                <FiberManualRecordIcon id="packageInactive" />
                                            </div>
                                            :

                                            moment(targetPackage.end_date) > moment() && targetPackage.token_based_package && targetPackage.token_amount > 0 ?
                                                //TOKEN PACKAGE VALID
                                                <div className="packageRow" onClick={() => this.handleSelectPackage(targetPackage.id)}>
                                                    <div className="packageName">{targetPackage.package_name}</div>
                                                    <FiberManualRecordIcon id="packageAvailable" />
                                                </div>
                                                :
                                                moment(targetPackage.end_date) > moment() && !targetPackage.token_based_package ?
                                                    //NON TOKEN PACKAGE VALID
                                                    <div className="packageRow" onClick={() => this.handleSelectPackage(targetPackage.id)}>
                                                        <div className="packageName">{targetPackage.package_name}</div>
                                                        <FiberManualRecordIcon id="packageAvailable" />
                                                    </div>
                                                    :
                                                    //PACKAGE NOT VALID
                                                    <div className="packageRow" onClick={() => this.handleSelectPackage(targetPackage.id)}>
                                                        <div className="packageName">{targetPackage.package_name}</div>
                                                        <FiberManualRecordIcon id="packageNotAvailable" />
                                                    </div>
                                )
                            }
                        </div>
                    </div>

                </div>
                <div className="fixedHeightPackageUsageViewingPort">
                    <div className="customerPackageUsageHistoryBlockTitleRow">
                        <h3 className="blockTitleRowOnCustomerPackageUsageHistory">{this.state.selectedPackageID === 0 ? '所有服務使用紀錄 :' : '已選課程的詳細紀錄 :'}</h3>
                    </div>
                    {
                        this.state.selectedPackageID === 0 ? null :
                            <div className="selectedPackageStatusRow">
                                <div className="inlineDisplay start_dateOnCustomerPackageUsageHistorySelected"> <div>{this.props.language !== "eng" ? "啟用日" : "Start Date"}</div><div>{this.state.selectedPackageDetails.start_date ? moment(this.state.selectedPackageDetails.start_date).format('YYYY-MM-DD') : "- -"}</div></div>
                                <div className="inlineDisplay end_dateOnCustomerPackageUsageHistorySelected"> <div>{this.props.language !== "eng" ? "到期日" : "End Date"}</div><div>{this.state.selectedPackageDetails.end_date ? moment(this.state.selectedPackageDetails.end_date).subtract(1, 'day').format('YYYY-MM-DD ') : "- -"}</div></div>
                                <div className="inlineDisplay packageOnCustomerPackageUsageHistorySelected"> <div>{this.props.language !== "eng" ? "課程" : "Package"}</div><div>{this.state.selectedPackageDetails.package_name}</div></div>
                                <div className="inlineDisplay originalTokenOnCustomerPackageUsageHistory"> <div>{this.props.language !== "eng" ? "套券數量" : "Active Token"}</div><div>{this.state.selectedPurchasedPackageDetails.token_amount}</div></div>
                                <div className="inlineDisplay usedTokenOnCustomerPackageUsageHistory"> <div>{this.props.language !== "eng" ? (this.props.customerPackages.find((targetPackage: any) => targetPackage.id === this.state.selectedPackageID)).token_based_package ? "已使用" : "已參與" : (this.props.customerPackages.find((targetPackage: any) => targetPackage.id === this.state.selectedPackageID)).token_based_package ? "Used" : "Participated"}</div><div>{(this.props.customerPackages.find((targetPackage: any) => targetPackage.id === this.state.selectedPackageID)).token_based_package ? parseInt(this.state.selectedPurchasedPackageDetails.token_amount) - parseInt(this.state.selectedPackageDetails.token_amount) : packageUsageRenderer.length}</div></div>
                                <div className="inlineDisplay tokenLeftOnCustomerPackageUsageHistory"> <div> {this.props.language !== "eng" ? "尚餘數量" : "Remaining Token"}</div><div>{this.state.selectedPackageDetails.token_amount}</div></div>
                            </div>
                    }
                    <div className="listingAreaTitleRowOnCustomerPackageUsageHistory">
                        <div className="inlineDisplay createdAtOnCustomerPackageUsageHistory">{this.props.language !== "eng" ? "處理日期" : "Process Date"}</div>
                        <div className="inlineDisplay start_timeOnCustomerPackageUsageHistory">{this.props.language !== "eng" ? "使用日期" : "Usage Date"}</div>
                        <div className="inlineDisplay genreOnCustomerPackageUsageHistory">{this.props.language !== "eng" ? "種類" : "Sport"}</div>
                        <div className="inlineDisplay tutorOnCustomerPackageUsageHistory">{this.props.language !== "eng" ? "導師" : "Tutor"}</div>
                        <div className="inlineDisplay siteOnCustomerPackageUsageHistory">{this.props.language !== "eng" ? "分校" : "Site"}</div>
                        <div className="inlineDisplay tokenUsageOnCustomerPackageUsageHistoryTitle">{this.props.language !== "eng" ? "套券用途" : "Package"}</div>
                        <div className="inlineDisplay attnOnCustomerPackageUsageHistoryTitle"> {this.props.language !== "eng" ? "狀態" : "Status"}</div>
                    </div>
                    {
                        this.state.selectedPackageID === 0 ?
                            <div className="fixedHeightScrollingListingArea2">
                                {packageUsageRenderer.map((packageUsage: any) =>
                                    <div className="packageUsageRow" >
                                        {
                                            packageUsage.class ?
                                                <div className="inlineDisplay createdAtOnCustomerPackageUsageHistory">
                                                    {moment((this.props.relatedParticipation.find((participation) => participation.room_occupancy_detail_id === packageUsage.id)).created_at).format('YYYY-MM-DD')}
                                                </div>
                                                :
                                                <div className="inlineDisplay createdAtOnCustomerPackageUsageHistory">
                                                    {moment(packageUsage.created_at).format('YYYY-MM-DD HH:mm')}
                                                </div>
                                        }
                                        <div className="inlineDisplay start_timeOnCustomerPackageUsageHistory"> {moment(packageUsage.start_time).format('YYYY-MM-DD HH:mm')}</div>
                                        {
                                            packageUsage.class ?
                                                <div className="inlineDisplay genreOnCustomerPackageUsageHistory">{(this.props.genreList.find((genre: any) => genre.id === packageUsage.genre_id)).genre}</div>
                                                : <div className="inlineDisplay genreOnCustomerPackageUsageHistory">- -</div>
                                        }
                                        {
                                            packageUsage.class ?
                                                <div className="inlineDisplay tutorOnCustomerPackageUsageHistory"> {this.props.requiredTutorInfo.find((tutor: any) => tutor.id === (this.props.relatedTutorListOfOccupancy.find((tutorlist: any) => tutorlist.room_occupancy_detail_id === packageUsage.id)).tutor_id).name_eng} </div>
                                                : <div className="inlineDisplay tutorOnCustomerPackageUsageHistory">- -</div>
                                        }
                                        <div className="inlineDisplay siteOnCustomerPackageUsageHistory">{(this.props.siteLists.find((site: any) => site.id === packageUsage.site_id)).site_key}</div>
                                        {
                                            packageUsage.class ?
                                                <div className="inlineDisplay tokenUsageOnCustomerPackageUsageHistory">{
                                                    (this.props.customerPackages.find((targetPackage: any) => targetPackage.id === (this.props.relatedParticipation.find((participation) => participation.room_occupancy_detail_id === packageUsage.id)).customer_package_id)).usage === 'Class Participation' ? this.props.language !== "eng" ? '課堂參與' : "Group" : null
                                                }</div>
                                                :
                                                <div className="inlineDisplay tokenUsageOnCustomerPackageUsageHistory">
                                                    {this.props.language !== "eng" ?
                                                        (this.props.customerPackages.find((targetPackage: any) => targetPackage.id === packageUsage.consumed_package_id[0])).usage === 'Classroom Rental' ? '課室租用' :
                                                            (this.props.customerPackages.find((targetPackage: any) => targetPackage.id === packageUsage.consumed_package_id[0])).usage === 'Private Class Booking' ? '私人課堂' :
                                                                (this.props.customerPackages.find((targetPackage: any) => targetPackage.id === (this.props.relatedParticipation.find((participation) => participation.room_occupancy_detail_id === packageUsage.id)).customer_package_id)).usage === 'Tutor Rental' ? '預約老師' : null
                                                        :
                                                        (this.props.customerPackages.find((targetPackage: any) => targetPackage.id === packageUsage.consumed_package_id[0])).usage === 'Classroom Rental' ? 'Rental' :
                                                            (this.props.customerPackages.find((targetPackage: any) => targetPackage.id === packageUsage.consumed_package_id[0])).usage === 'Private Class Booking' ? 'Private' :
                                                                (this.props.customerPackages.find((targetPackage: any) => targetPackage.id === (this.props.relatedParticipation.find((participation) => participation.room_occupancy_detail_id === packageUsage.id)).customer_package_id)).usage === 'Tutor Rental' ? 'Hiring' : null
                                                    }
                                                </div>

                                        }
                                        {
                                            packageUsage.class ?
                                                this.props.relatedParticipation.find((participation: any) => participation.room_occupancy_detail_id === packageUsage.id).class_occupancy_cxl ?

                                                    <div className="inlineDisplay attnOnCustomerPackageUsageHistoryCxl"> {this.props.language !== "eng"? "取消" : "C."}</div>
                                                    :
                                                    this.props.relatedParticipation.find((participation: any) => participation.room_occupancy_detail_id === packageUsage.id).absent_reason_id ?
                                                        <div className="inlineDisplay attnOnCustomerPackageUsageHistoryLeave"> {this.props.language !== "eng"? "請假" : "L."}</div>
                                                        :
                                                        packageUsage.mark_attendance_for_group_class ?
                                                            this.props.relatedParticipation.find((participation: any) => participation.room_occupancy_detail_id === packageUsage.id).absent === null ?
                                                                <div className="inlineDisplay attnOnCustomerPackageUsageHistoryLeave"> {this.props.language !== "eng"? "點名" : "At."}</div>
                                                                :
                                                                this.props.relatedParticipation.find((participation: any) => participation.room_occupancy_detail_id === packageUsage.id).absent ?

                                                                    <div className="inlineDisplay attnOnCustomerPackageUsageHistoryAbsent"> {this.props.language !== "eng"? "缺席" : "Ab."}</div>
                                                                    :
                                                                    <div className="inlineDisplay attnOnCustomerPackageUsageHistory"> {this.props.language !== "eng"? "出席" : "P."}</div>
                                                            :
                                                            <div className="inlineDisplay attnOnCustomerPackageUsageHistoryBooking">{this.props.language !== "eng"? "預約" : "B."}</div>
                                                :
                                                packageUsage.occupancy_cxl ?
                                                    <div className="inlineDisplay attnOnCustomerPackageUsageHistoryCxl"> {this.props.language !== "eng"? "取消" : "C."}</div>
                                                    :
                                                    <div className="inlineDisplay attnOnCustomerPackageUsageHistory"> {this.props.language !== "eng"? "正常" : "N."}</div>
                                        }
                                    </div>
                                )}
                            </div>

                            :

                            <div className="fixedHeightScrollingListingArea1">
                                {packageUsageRenderer.map((packageUsage: any) =>
                                    <div className="packageUsageRow" >
                                        {
                                            packageUsage.class ?
                                                <div className="inlineDisplay createdAtOnCustomerPackageUsageHistory">
                                                    {moment((this.props.relatedParticipation.find((participation) => participation.room_occupancy_detail_id === packageUsage.id)).created_at).format('YYYY-MM-DD HH:mm')}
                                                </div>
                                                :
                                                <div className="inlineDisplay createdAtOnCustomerPackageUsageHistory">
                                                    {moment(packageUsage.created_at).format('YYYY-MM-DD HH:mm')}
                                                </div>
                                        }
                                        <div className="inlineDisplay start_timeOnCustomerPackageUsageHistory"> {moment(packageUsage.start_time).format('YYYY-MM-DD HH:mm')}</div>
                                        {
                                            packageUsage.class ?
                                                <div className="inlineDisplay genreOnCustomerPackageUsageHistory">{(this.props.genreList.find((genre: any) => genre.id === packageUsage.genre_id)).genre}</div>
                                                : <div className="inlineDisplay genreOnCustomerPackageUsageHistory">- -</div>
                                        }
                                        {
                                            packageUsage.class ?
                                                <div className="inlineDisplay tutorOnCustomerPackageUsageHistory"> {this.props.requiredTutorInfo.find((tutor: any) => tutor.id === (this.props.relatedTutorListOfOccupancy.find((tutorlist: any) => tutorlist.room_occupancy_detail_id === packageUsage.id)).tutor_id).name_eng} </div>
                                                : <div className="inlineDisplay tutorOnCustomerPackageUsageHistory">- -</div>
                                        }
                                        <div className="inlineDisplay siteOnCustomerPackageUsageHistory">{(this.props.siteLists.find((site: any) => site.id === packageUsage.site_id)).site_key}</div>

                                        {
                                            packageUsage.class ?
                                                <div className="inlineDisplay tokenUsageOnCustomerPackageUsageHistory">{
                                                    (this.props.customerPackages.find((targetPackage: any) => targetPackage.id === (this.props.relatedParticipation.find((participation) => participation.room_occupancy_detail_id === packageUsage.id)).customer_package_id)).usage === 'Class Participation' ? '課堂參與' : null
                                                }</div>
                                                :
                                                <div className="inlineDisplay tokenUsageOnCustomerPackageUsageHistory">
                                                    {(this.props.customerPackages.find((targetPackage: any) => targetPackage.id === packageUsage.consumed_package_id[0])).usage === 'Classroom Rental' ? '課室租用' :
                                                        (this.props.customerPackages.find((targetPackage: any) => targetPackage.id === packageUsage.consumed_package_id[0])).usage === 'Private Class Booking' ? '私人課堂' :
                                                            (this.props.customerPackages.find((targetPackage: any) => targetPackage.id === (this.props.relatedParticipation.find((participation) => participation.room_occupancy_detail_id === packageUsage.id)).customer_package_id)).usage === 'Tutor Rental' ? '預約老師' : null}
                                                </div>

                                        }
                                        {
                                            packageUsage.class ?
                                                this.props.relatedParticipation.find((participation: any) => participation.room_occupancy_detail_id === packageUsage.id).class_occupancy_cxl ?

                                                    <div className="inlineDisplay attnOnCustomerPackageUsageHistoryCxl"> {this.props.language !== "eng"? "取消" : "C."}</div>
                                                    :
                                                    this.props.relatedParticipation.find((participation: any) => participation.room_occupancy_detail_id === packageUsage.id).absent_reason_id ?
                                                        <div className="inlineDisplay attnOnCustomerPackageUsageHistoryLeave"> {this.props.language !== "eng"? "請假" : "L."}</div>
                                                        :
                                                        packageUsage.mark_attendance_for_group_class ?
                                                            this.props.relatedParticipation.find((participation: any) => participation.room_occupancy_detail_id === packageUsage.id).absent === null ?
                                                                <div className="inlineDisplay attnOnCustomerPackageUsageHistoryLeave"> {this.props.language !== "eng"? "點名" : "Att."}</div>
                                                                :
                                                                this.props.relatedParticipation.find((participation: any) => participation.room_occupancy_detail_id === packageUsage.id).absent ?

                                                                    <div className="inlineDisplay attnOnCustomerPackageUsageHistoryAbsent"> {this.props.language !== "eng"? "缺席" : "Abs."}</div>
                                                                    :
                                                                    <div className="inlineDisplay attnOnCustomerPackageUsageHistory"> {this.props.language !== "eng"? "出席" : "P."}</div>
                                                            :
                                                            <div className="inlineDisplay attnOnCustomerPackageUsageHistoryBooking">{this.props.language !== "eng"? "預約" : "B."}</div>
                                                :
                                                packageUsage.occupancy_cxl ?
                                                    <div className="inlineDisplay attnOnCustomerPackageUsageHistoryCxl"> {this.props.language !== "eng"? "取消" : "C."}</div>
                                                    :
                                                    <div className="inlineDisplay attnOnCustomerPackageUsageHistory"> {this.props.language !== "eng"? "正常" : "N."}</div>
                                        }
                                    </div>
                                )}
                            </div>}
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        language: state.auth.language,
        customerPackages: state.customerPackagesUsageHistory.customerPackages,
        relatedParticipation: state.customerPackagesUsageHistory.relatedParticipation,
        relatedClassOccupancy: state.customerPackagesUsageHistory.relatedClassOccupancy,
        relatedPrivateRentalOccupancy: state.customerPackagesUsageHistory.relatedPrivateRentalOccupancy,
        genreList: state.customers.genreTypeList,
        relatedTutorListOfOccupancy: state.customerPackagesUsageHistory.relatedTutorListOfOccupancy,
        requiredTutorInfo: state.customerPackagesUsageHistory.requiredTutorInfo,
        requiredPurchasedPackageDetails: state.customerPackagesUsageHistory.requiredPurchasedPackageDetails,
        siteLists: state.customerPackagesUsageHistory.siteList
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        handleLoadCustomerPackageUsageHistoryRelatedData: async (customerID: number) => dispatch(await loadCustomerPackageUsageHistoryRelatedData(customerID))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPackageUsageHistory);