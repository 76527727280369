import { ThunkDispatch } from "../../store";
import { allCustomerPurchasePaymentHistoryLoaded, newPaymentRecorded, purchaseEmailReceiptResent, targetedPreviousPaymentVoided, targetedPreviousPurchaseVoided, targetedPreviousPurchaseVoidFailed } from "./actions";



export async function loadCustomerPurchasePaymentHistory(customerUserID: number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customer/loadCustomerPurchasePaymentDetails?customerUserID=${customerUserID}`,{
        method: 'GET',
        headers:{
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    })

    let result:any  = await res.json();
    if(res.status === 500){
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    } else {
        return async( dispatch: ThunkDispatch) => {
            dispatch(allCustomerPurchasePaymentHistoryLoaded(result.customerPurchases, result.customerPayments ,result.purchasedPackages, result.purchasedProducts))
        }
    }
}

export async function recordNewPayment(staffID:number,customerUserID:number,purchaseID:number,paymentData:any){
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/staffProcessPurchases/staffProcessNewPaymentForPreviousPurchase`,{
        method: 'POST',
        headers:{
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body:JSON.stringify({
            staffID,
            customerUserID,
            purchaseID,
            paymentData
        })
    })

    let result :any = await res.json();
    if(res.status === 500){
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    } else {
        return async( dispatch: ThunkDispatch) => {
            dispatch(newPaymentRecorded());
        }
    }
}

export async function voidTargetedPreviousPayment (paymentID:number, staffUserID:number){
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/staffProcessPurchases/voidTargetedPreviousPayment`,{
        method: 'PUT',
        headers:{
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body:JSON.stringify({
            paymentID,
            staffUserID
        })
    })

    let result = await res.json();
    if(res.status === 500){
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    } else {
        return async( dispatch: ThunkDispatch) => {
            dispatch(targetedPreviousPaymentVoided());
        }
    }
}
export async function voidTargetedPreviousPurchase (purchaseID:number, customerUserID:number){
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/staffProcessPurchases/voidTargetedPurchase`,{
        method: 'DELETE',
        headers:{
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body:JSON.stringify({
            purchaseID,
            customerUserID
        })
    })
    const result = await res.json();
    if(res.status === 500){
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    } else {
        if(result.result){
            return async( dispatch: ThunkDispatch) => {
                dispatch(targetedPreviousPurchaseVoided());
            }
        } else {
            return async( dispatch: ThunkDispatch) => {
                dispatch(targetedPreviousPurchaseVoidFailed());
            }
        }
    }
}

export async function resendTargetPurchaseEmailReceiptToCustomer (customerUserID:number, purchaseID:number, purchasedProductData: any[] ) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/staffProcessPurchases/resendPurchaseEmailReceipts`,{
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body:JSON.stringify({
            customerUserID,
            purchaseID,
            purchasedProductData
        })
    });
    const result = await res.json();
    if(res.status === 500){
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async( dispatch: ThunkDispatch) => {
            dispatch(purchaseEmailReceiptResent());
        }
    }
}