


export function allUniqueStockBasicDetailsLoaded (uniqueStockBasicDetails:any, allSites:any, allAttributeTypes: any, allCategoryTypes:any){
    return{
        type: "ALL_UNIQUE_STOCK_BASIC_DETAILS_LOADED" as "ALL_UNIQUE_STOCK_BASIC_DETAILS_LOADED",
        uniqueStockBasicDetails,
        allSites,
        allAttributeTypes,
        allCategoryTypes
    }
}

export function loading(){
    return {
        type: "LOADING" as "LOADING"
    }
}

export function newInventoryRecordCreated () {
    return{
        type: "NEW_INVENTORY_RECORD_CREATED" as "NEW_INVENTORY_RECORD_CREATED"
    }
}

export function addStockSKUUniqueChecked (uniqueCheckResult:any) {
    return {
        type: "ADD_STOCK_SKU_UNIQUE_CHECKED" as "ADD_STOCK_SKU_UNIQUE_CHECKED",
        uniqueCheckResult
    }
}


type StockManageMainActionsCreators = typeof allUniqueStockBasicDetailsLoaded |
                                    typeof loading |
                                    typeof newInventoryRecordCreated |
                                    typeof addStockSKUUniqueChecked ;

export type IStockManageMainActions = ReturnType<StockManageMainActionsCreators>;