import React from 'react';
import { connect } from 'react-redux';
import { IRootState, ThunkDispatch } from '../store';
import { usernameTyping, passwordTyping, submitClicking } from "../Auth/thunks";


import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia';
import { pageInit, updateStaffLoginDetails, finishChangePWExitClick } from './thunks';
import { loginDetailsProcessing } from './actions';



interface IStaffAcLoginSetUpProps {
    staffDetails: any,
    loading: boolean,
    loginDetailsUpdated: boolean,
    loadStaffBasicDetails: () => void,
    updateNewStaffLoginDetails: (staffLoginDetails: any) => void,
    handleLoadTime: () => void,
    finishClick:() => void
}

interface IStaffAcLoginSetUpState {
    staffLoginDetails: any,
    confirmPassword: string,
    bothPasswordCorrect: boolean
}
class StaffAcLoginSetUp extends React.Component<IStaffAcLoginSetUpProps, IStaffAcLoginSetUpState> {
    constructor(props: IStaffAcLoginSetUpProps) {
        super(props);
        this.state = {
            staffLoginDetails: {
                password: ''
            },
            confirmPassword: '',
            bothPasswordCorrect: true
        }
    }

    private handleLoginDetailsInput = (field: any) => async (event: any) => {
        await this.setState({
            staffLoginDetails: {
                ...this.state.staffLoginDetails,
                [field]: event.target.value
            }
        })
    }
    private handleConfirmPasswordInput = async (event: any) => {
        await this.setState({
            confirmPassword: event.target.value
        })
        if (this.state.staffLoginDetails.password !== this.state.confirmPassword) {
            await this.setState({
                bothPasswordCorrect: false
            })
        } else {
            await this.setState({
                bothPasswordCorrect: true
            })
        }
    }

    private handleSubmit = async () => {
        if (this.state.staffLoginDetails.password !== '' && this.state.confirmPassword === this.state.staffLoginDetails.password) {
            let staffLoginDetails = this.state.staffLoginDetails
            staffLoginDetails.staffID = this.props.staffDetails.staffID
            await this.props.handleLoadTime();
            await this.props.updateNewStaffLoginDetails(staffLoginDetails);
        } else {
            window.alert('密碼不一致!')
        }
    }
    private handleFinishClick = async () => {
        this.props.finishClick();
    }


    async componentWillMount() {
        await this.props.handleLoadTime();
        await this.props.loadStaffBasicDetails();
    }

    render() {


        return (
            <div className="container" id="loginContainer">
                <div className="row">
                    <div className="col-12">
                        <Card className="loginBoard">

                            <div className="row loginBaseCard ">
                                <div className="col-7 pictureArea" id="pictureArea">
                                    {/* <img src={Image} alt="Innovation" ></img> */}

                                </div>
                                {
                                    this.props.loginDetailsUpdated ?
                                        <div className="col-5" id="inputForm">
                                            <form noValidate autoComplete="off">
                                                <h1 className="titleOnLoginPage">已成功更改密碼。<br></br></h1>
                                                <Button variant="contained" onClick={this.handleFinishClick} >
                                                    完成
                                             </Button>

                                            </form>
                                        </div>
                                        :
                                        <div className="col-5" id="inputForm">
                                            <form noValidate autoComplete="off">
                                                <h1 className="titleOnLoginPage">更改登入密碼<br></br></h1>

                                                <TextField
                                                    id="loginInput"
                                                    label="新密碼"
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={this.state.staffLoginDetails.password}
                                                    onChange={this.handleLoginDetailsInput('password')}
                                                    type="password"
                                                />
                                                <br></br>
                                                <div className="spacingOnLoginPage"></div>
                                                <TextField
                                                    id="loginInput"
                                                    label="確認新密碼"
                                                    margin="normal"
                                                    variant="outlined"
                                                    value={this.state.confirmPassword}
                                                    onChange={this.handleConfirmPasswordInput}
                                                    type="password"
                                                />


                                                <Button variant="contained" onClick={this.handleSubmit} >
                                                    submit
                                             </Button>

                                            </form>
                                        </div>
                                }
                            </div>
                        </Card>
                    </div>
                </div>
            </div>

        )
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        loadStaffBasicDetails: async () => dispatch(await pageInit()),
        updateNewStaffLoginDetails: async (staffLoginDetails: any) => dispatch(await updateStaffLoginDetails(staffLoginDetails)),
        handleLoadTime: () => dispatch(loginDetailsProcessing()),
        finishClick: async () => dispatch(await finishChangePWExitClick())
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        staffDetails: state.staffAcLoginSetUp.staffDetails,
        loading: state.staffAcLoginSetUp.loading,
        loginDetailsUpdated: state.staffAcLoginSetUp.loginDetailsUpdated
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffAcLoginSetUp)