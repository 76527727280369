import { ICustomerPurchasePaymentHistoryState } from './state'
import { ICustomerPurchasePaymentHistoryActions } from './actions'

const initialState = {
    customerPurchasesList: [],
    customerPayments: [],
    purchasedPackagesList: [],
    purchasedProductList: [],
    showDeclineVoidPurchaseBox: false,
    loading: true,
    showEmailResentConfirmationBox: false
}

export const customerPurchasePaymentHistoryReducer = (state: ICustomerPurchasePaymentHistoryState = initialState, actions: ICustomerPurchasePaymentHistoryActions) => {

    switch (actions.type) {
        case "ALL_CUSTOMER_PURCHASE_PAYMENT_HISTORY_LOADED":
            return {
                ...state,
                customerPurchasesList: actions.customerPurchases,
                customerPayments: actions.customerPayments,
                purchasedPackagesList: actions.purchasedPackagesList,
                purchasedProductList: actions.purchasedProductsList,
                loading: false
            }
        case "NEW_PAYMENT_RECORDED":
            return {
                ...state,
                loading: true
            }
        case "TARGETED_PREVIOUS_PAYMENT_VOIDED":
            return {
                ...state,
                loading: true
            }
        case "TARGETED_PREVIOUS_PURCHASE_VOIDED":
            return {
                ...state,
                loading: true
            }
        case "TARGETED_PREVIOUS_PURCHASE_VOID_FAILED":
            return {
                ...state,
                showDeclineVoidPurchaseBox: true
            }
        case "BACK_FROM_DECLINE_OF_VOID_PURCHASE":
            return {
                ...state,
                showDeclineVoidPurchaseBox: false
            }
        case "PURCHASE_EMAIL_RECEIPT_RESENT":
            return {
                ...state,
                showEmailResentConfirmationBox: true
            }
        case "BACK_FROM_EMAIL_RESENT_CONFIRMATION_BOX":
            return {
                ...state,
                showEmailResentConfirmationBox: false
            }

        default:
            return state;
    }
}