import { ThunkDispatch } from '../store';
import { requiredStaffDetailsLoaded, loginDetailsUpdated } from './actions';
import { push } from 'connected-react-router';

export async function pageInit () {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/loadStaffBasicDetails`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            console.log(result)
            dispatch(requiredStaffDetailsLoaded(result.basicStaffDetails));
        }
    }
}

export async function updateStaffLoginDetails (staffLoginDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/newLoginDetailsForStaff`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            staffID: staffLoginDetails.staffID,
            PW: staffLoginDetails.password
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(loginDetailsUpdated());
        }
    }
}

export async function finishChangePWExitClick () {
    return async (dispatch: ThunkDispatch) => {
            dispatch(push("/layout/home"));
    }
}