import React from "react";
import { connect } from "react-redux";
import { IRootState, ThunkDispatch } from "../../store";
import { loadStockCategoryData, createStockCategory, updateStockCategory, delStockCategory } from './thunks';
import { Button, TextField } from "@material-ui/core";
import { ChromePicker } from 'react-color';
import EditIcon from '@material-ui/icons/Edit';
// import { classroomDeleteFailedNoticed } from "./actions";
import BeenhereIcon from '@material-ui/icons/Beenhere';
import CircularProgress from '@material-ui/core/CircularProgress';



export interface IStockCategoryManageProps {
    loadAllStockCategories: () => void,
    updateStockCategoryDetails: (stockCategoryDetails: any) => void,
    createNewStockCategoryDetails: (stockCategoryDetails: any) => void,
    delStockCategoryDetails: (stockCategoryID: number) => void,
    // exitFromNoticeWindow: () => void,
    // updateSiteDetails: (siteDetails:any) => void,
    language: string,
    loading: boolean,
    allStockCategories: any,
    // delClassroomFailed: boolean
}
export interface IStockCategoryManageState {
    selectedStockCategoryID: number,
    selectedStockCategoryDetails: any,
    addNewStockCategory: boolean,
}
class StockCategoryManage extends React.Component<IStockCategoryManageProps, IStockCategoryManageState> {
    constructor(props: IStockCategoryManageProps) {
        super(props);
        this.state = {
            selectedStockCategoryID: 0,
            selectedStockCategoryDetails: '',
            addNewStockCategory: false,
        }
    }

    private handleEditStockCategoryClick = async (stockCategoryID: number) => {
        let stockCategoryDetails = this.props.allStockCategories.filter((stockCategory: any) => stockCategory.id === stockCategoryID)
        await this.setState({
            selectedStockCategoryID: stockCategoryID,
            selectedStockCategoryDetails: stockCategoryDetails[0]
        })
    }
    private handleStockCategoryNameInput = async (event: any) => {
        await this.setState({
            selectedStockCategoryDetails: {
                ...this.state.selectedStockCategoryDetails,
                category: event.target.value
            }
        })
    }

    private handleSaveStockCategoryDetails = async () => {
        this.props.updateStockCategoryDetails(this.state.selectedStockCategoryDetails);
        setTimeout(async () => await this.props.loadAllStockCategories(), 1000);
        await this.setState({
            selectedStockCategoryID: 0,
            selectedStockCategoryDetails: ''
        })
    }

    private handleAddNewStockCategoryDetails = async () => {
        await this.setState({
            addNewStockCategory: true,
            selectedStockCategoryDetails: {
                category: '',
            }
        })
    }
    private handleSaveNewStockCategoryDetails = async () => {
        if (this.state.selectedStockCategoryDetails.category === '') {
            window.alert((this.props.language !== "eng" ? '請輸入產品種類！' : "Please Enter Product Category."))
        } else {
            await this.props.createNewStockCategoryDetails(this.state.selectedStockCategoryDetails);
            await this.setState({
                selectedStockCategoryID: 0,
                selectedStockCategoryDetails: '',
                addNewStockCategory: false,
            })
            setTimeout(async () => await this.props.loadAllStockCategories(), 1000); 
            // this.props.loadAllStockCategories();
        }
    }

    private handleDelStockCategoryDetails = async (stockCategoryID: number) => {
        let confirmation = await window.confirm((this.props.language ==="chi" ? '你確定要刪除此產品種類嗎？': "Confirm Deleting the Product."));
        if (confirmation) {
            await this.props.delStockCategoryDetails(stockCategoryID);
            this.props.loadAllStockCategories();
        }
    }


    componentWillMount() {
        this.props.loadAllStockCategories();
    }

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="systemMainDisplayArea">
                        <div className="systemManagePageTitle"> <BeenhereIcon id="systemManPageTitleIcon" /> {this.props.language !== "eng" ? "產品種類管理" : "Product Category Manage"}</div>
                        {
                            this.props.loading ?
                                <div className="loadingContainerRow">
                                    <div className="inlineDisplay">
                                        <div className="loadingContainer">
                                            <CircularProgress id="loadingCircle" />
                                            <div className="loadingLabel">Loading ...</div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="siteDisplayArea">
                                    <div className="siteTitle">{this.props.language !== "eng" ? "產品種類" : "Product Category"}</div>
                                    <div>
                                        {//RENDER ALL ACTIVE
                                            this.props.allStockCategories.map((stockCategory: any) =>
                                            !stockCategory.active ? null :
                                                <div className="classroomRow">
                                                    <div className="classroomNameBox">
                                                        <div className="containerOnSitesMan">
                                                            <div className="inlineDisplay">{this.props.language !== "eng" ? "產品種類" : "Product Category"} : </div>
                                                            {
                                                                this.state.selectedStockCategoryID !== stockCategory.id ?
                                                                    <div className="inlineDisplay detailsDisplayField"> {stockCategory.category} </div>
                                                                    :
                                                                    <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedStockCategoryDetails.category} onChange={this.handleStockCategoryNameInput} />
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="classroomCapacityBox">
                                                        <div className="containerOnSitesMan">
                                                            <div className="inlineDisplay">{this.props.language !== "eng" ? "狀態" : "State"} :  </div>
                                                            {
                                                                this.state.selectedStockCategoryID !== stockCategory.id ?
                                                                    <div className="inlineDisplay detailsDisplayField"> {this.props.language !== "eng" ? "現正生效" : "Active"} </div>
                                                                    :
                                                                    <TextField className="detailsEditingInputs" variant="outlined" value={this.props.language !== "eng" ? "現正生效" : "Active"} disabled />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="editClassroomBtn">
                                                        {
                                                            this.state.selectedStockCategoryID !== stockCategory.id ?
                                                                <div className="btnContainer">
                                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={() => this.handleEditStockCategoryClick(stockCategory.id)}>{this.props.language !== "eng" ? "修改" : "Edit"}</Button>
                                                                    <Button id="clickToDelClassroomBtn" onClick={() => this.handleDelStockCategoryDetails(stockCategory.id)}>{this.props.language !== "eng" ? "隱藏產品種類" : "Inactivate Category"}</Button>
                                                                </div>
                                                                :
                                                                <div className="btnContainer">
                                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveStockCategoryDetails} >{this.props.language !== "eng" ? "儲存產品種類" : "Save Category"}</Button>
                                                                </div>
                                                        }
                                                    </div>

                                                </div>
                                            )
                                        }
                                        {//RENDER ALL NOT ACTIVE
                                            this.props.allStockCategories.map((stockCategory: any) =>
                                            stockCategory.active ? null :
                                                <div className="classroomRow">
                                                    <div className="classroomNameBox">
                                                        <div className="containerOnSitesMan">
                                                            <div className="inlineDisplay">{this.props.language !== "eng" ? "產品種類" : "Product Category"} : </div>
                                                            {
                                                                this.state.selectedStockCategoryID !== stockCategory.id ?
                                                                    <div className="inlineDisplay detailsDisplayField"> {stockCategory.category} </div>
                                                                    :
                                                                    <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedStockCategoryDetails.category} onChange={this.handleStockCategoryNameInput} />
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="classroomCapacityBox">
                                                        <div className="containerOnSitesMan">
                                                            <div className="inlineDisplay">{this.props.language !== "eng" ? "狀態" : "State"} :  </div>
                                                            {
                                                                this.state.selectedStockCategoryID !== stockCategory.id ?
                                                                    <div className="inlineDisplay detailsDisplayField"> {this.props.language ==="chi" ? "已隱藏" : "Inactive"} </div>
                                                                    :
                                                                    <TextField className="detailsEditingInputs" variant="outlined" value={this.props.language ==="chi" ? "已隱藏" : "Inactive"} disabled />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="editClassroomBtn">
                                                        {
                                                            this.state.selectedStockCategoryID !== stockCategory.id ?
                                                                <div className="btnContainer">
                                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={() => this.handleEditStockCategoryClick(stockCategory.id)}>{this.props.language !== "eng" ? "修改" : "Edit"}</Button>
                                                                    <Button id="clickToDelClassroomBtn" onClick={() => this.handleDelStockCategoryDetails(stockCategory.id)}>{this.props.language !== "eng" ? "回復產品種類" : "Activate Category"}</Button>
                                                                </div>
                                                                :
                                                                <div className="btnContainer">
                                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveStockCategoryDetails} >{this.props.language !== "eng" ? "儲存產品種類" : "Save Category"}</Button>
                                                                </div>
                                                        }
                                                    </div>

                                                </div>
                                            )
                                        }

                                    </div>
                                    {
                                        !this.state.addNewStockCategory ?
                                            <div className="addClassroomRow">
                                                <Button id="clickToAddNewClassroomBtn" onClick={() => this.handleAddNewStockCategoryDetails()}>{this.props.language !== "eng" ? "新增產品種類" : "Create New Category"}</Button>
                                            </div>
                                            :
                                            <div className="classroomRow">
                                                <div className="classroomNameBox">
                                                    <div className="containerOnSitesMan">
                                                        <div className="inlineDisplay">{this.props.language !== "eng" ? "產品種類" : "Product Category"} : </div>
                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedStockCategoryDetails.category} onChange={this.handleStockCategoryNameInput} />
                                                    </div>
                                                </div>
                                                <div className="classroomCapacityBox">
                                                </div>
                                                <div className="editClassroomBtn">
                                                    <div className="btnContainer">
                                                        <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveNewStockCategoryDetails} >{this.props.language !== "eng" ? "儲存產品種類" : "Save Category"}</Button>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                        }
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        allStockCategories: state.stockCategoryManage.allStockCategories,
        loading: state.stockCategoryManage.loading,
        language: state.auth.language
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        loadAllStockCategories: async () => dispatch(await loadStockCategoryData()),
        updateStockCategoryDetails: async (stockCategoryDetails: any) => dispatch(await updateStockCategory(stockCategoryDetails)),
        createNewStockCategoryDetails: async (stockCategoryDetails: any) => dispatch(await createStockCategory(stockCategoryDetails)),
        delStockCategoryDetails: async (stockCategoryID: number) => dispatch(await delStockCategory(stockCategoryID)),
        // exitFromNoticeWindow: async () => dispatch(await classroomDeleteFailedNoticed()),
        // updateSiteDetails: async (siteDetails:any) => dispatch(await updateSiteDetails(siteDetails))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StockCategoryManage)
