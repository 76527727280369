


export function specificPurchaseDetailsLoaded (purchaseDetails:any, paymentDetails:any, purchasedPackages:any[], purchasedProducts:any[], usedDiscounts: any[], suitableTerms: any[]) {
    return {
        type:"SPECIFIC_PURCHASE_DETAILS_LOADED" as "SPECIFIC_PURCHASE_DETAILS_LOADED",
        purchaseDetails,
        paymentDetails,
        purchasedPackages,
        purchasedProducts,
        usedDiscounts,
        suitableTerms
    }
}

export function loading () {
    return {
        type:"LOADING" as "LOADING"
    }
}
export function unmountClear(){
    return {
        type:"UNMOUNT_CLEAR"as "UNMOUNT_CLEAR"
    }
}

type SpecificPurchaseDetailsBoxActionsCreators = typeof specificPurchaseDetailsLoaded | typeof loading | typeof unmountClear;

export type ISpecificPurchaseDetailsBoxActions = ReturnType<SpecificPurchaseDetailsBoxActionsCreators>;