import React from "react";
import './AttendanceManage.css'
import { connect } from "react-redux";
import { IRootState, ThunkDispatch } from "../../store";
import { loadAttendanceData, createAttendance, updateAttendance, delAttendance } from './thunks';
import { Button, TextField } from "@material-ui/core";
import { ChromePicker } from 'react-color';
import EditIcon from '@material-ui/icons/Edit';
// import { classroomDeleteFailedNoticed } from "./actions";
import BeenhereIcon from '@material-ui/icons/Beenhere';
import CircularProgress from '@material-ui/core/CircularProgress';



export interface IAttendanceManageProps {
    loadAllAttendances: () => void,
    updateAttendanceDetails: (attendanceDetails: any) => void,
    createNewAttendanceDetails: (attendanceDetails: any) => void,
    delAttendanceDetails: (attendanceID: number) => void,
    // exitFromNoticeWindow: () => void,
    // updateSiteDetails: (siteDetails:any) => void,
    language: string,
    loading: boolean,
    allAttendances: any,
    // delClassroomFailed: boolean
}
export interface IAttendanceManageState {
    selectedAttendanceID: number,
    selectedAttendanceDetails: any,
    addNewAttendance: boolean,
}
class AttendanceManage extends React.Component<IAttendanceManageProps, IAttendanceManageState> {
    constructor(props: IAttendanceManageProps) {
        super(props);
        this.state = {
            selectedAttendanceID: 0,
            selectedAttendanceDetails: '',
            addNewAttendance: false,
        }
    }

    private handleEditAttendanceClick = async (attendanceID: number) => {
        let attendanceDetails = this.props.allAttendances.filter((attendance: any) => attendance.id === attendanceID)
        await this.setState({
            selectedAttendanceID: attendanceID,
            selectedAttendanceDetails: attendanceDetails[0]
        })
    }
    private handleAttendanceNameInput = async (event: any) => {
        await this.setState({
            selectedAttendanceDetails: {
                ...this.state.selectedAttendanceDetails,
                absent_reason: event.target.value
            }
        })
    }

    private handleSaveAttendanceDetails = async () => {
        this.props.updateAttendanceDetails(this.state.selectedAttendanceDetails);
        setTimeout(async () => await this.props.loadAllAttendances(), 1000);
        await this.setState({
            selectedAttendanceID: 0,
            selectedAttendanceDetails: ''
        })
    }

    private handleAddNewAttendanceDetails = async () => {
        await this.setState({
            addNewAttendance: true,
            selectedAttendanceDetails: {
                absent_reason: '',
                compensate: true,
            }
        })
    }
    private handleSaveNewAttendanceDetails = async () => {
        if (this.state.selectedAttendanceDetails.absent_reason === '') {
            window.alert('請輸入請假原因內容！')
        } else {
            await this.props.createNewAttendanceDetails(this.state.selectedAttendanceDetails);
            await this.setState({
                selectedAttendanceID: 0,
                selectedAttendanceDetails: '',
                addNewAttendance: false,
            })
            setTimeout(async () => await this.props.loadAllAttendances(), 1000);
            // this.props.loadAllAttendances();
        }
    }

    private handleDelAttendanceDetails = async (attendanceID: number) => {
        let confirmation = await window.confirm('你確定要刪除此請假原因嗎？');
        if (confirmation) {
            await this.props.delAttendanceDetails(attendanceID);
            this.props.loadAllAttendances();
        }
    }


    componentWillMount() {
        this.props.loadAllAttendances();
    }

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="systemMainDisplayArea row">
                        <div className="systemManagePageTitle col-12"> <BeenhereIcon id="systemManPageTitleIcon" /> {this.props.language !== "eng" ? `點名管理` : `Attendance Manage`}</div>
                        {
                            this.props.loading ?
                                <div className="loadingContainerRow">
                                    <div className="inlineDisplay">
                                        <div className="loadingContainer">
                                            <CircularProgress id="loadingCircle" />
                                            <div className="loadingLabel">Loading ...</div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="siteDisplayArea col-12">
                                    <div className="siteTitle">{this.props.language !== "eng" ? `請假原因種類` : `Applied Leave Reasons Manage`}</div>
                                    <div className="groupingFixedHeightBox">
                                        {//RENDER ALL ACTIVE
                                            this.props.allAttendances.map((attendance: any) =>
                                                <div className="classroomRow row">
                                                    <div className="classroomNameBox col-lg-7 col-md-12 mb-1">
                                                        <div className="containerOnSitesMan  row">
                                                            <div className="inlineDisplayNoMargin col-4">{this.props.language !== "eng" ? `請假原因` : `Applied Leave Reason`} : </div>
                                                            {
                                                                this.state.selectedAttendanceID !== attendance.id ?
                                                                    <div className="inlineDisplayNoMargin col-8 detailsDisplayField"> {attendance.absent_reason} </div>
                                                                    :
                                                                    <div className="col-8">
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedAttendanceDetails.absent_reason} onChange={this.handleAttendanceNameInput} />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="classroomCapacityBox col-lg-5 col-md-12">
                                                        <div className="containerOnSitesMan row">
                                                            <div className="inlineDisplayNoMargin col-4">{this.props.language !== "eng" ? "狀態" : "State"} : </div>
                                                            {
                                                                this.state.selectedAttendanceID !== attendance.id ?
                                                                    <div className="inlineDisplayNoMargin col-8 detailsDisplayField"> {this.props.language !== "eng" ? `現正生效` : `Active`} </div>
                                                                    :
                                                                    <div className="col-8">
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.props.language !== "eng" ? `現正生效` : `Active`} disabled />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="editClassroomBtn">
                                                        {
                                                            this.state.selectedAttendanceID !== attendance.id ?
                                                                <div className="btnContainer">
                                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={() => this.handleEditAttendanceClick(attendance.id)}>{this.props.language !== "eng" ? `修改` : `Edit`}</Button>
                                                                    <Button id="clickToDelClassroomBtn" onClick={() => this.handleDelAttendanceDetails(attendance.id)}>{this.props.language !== "eng" ? `刪除原因` : `Delete`}</Button>
                                                                </div>
                                                                :
                                                                <div className="btnContainer">
                                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveAttendanceDetails} >{this.props.language !== "eng" ? `儲存原因` : `Save`}</Button>
                                                                </div>
                                                        }
                                                    </div>

                                                </div>
                                            )
                                        }

                                    </div>
                                    {
                                        !this.state.addNewAttendance ?
                                            <div className="addClassroomRow">
                                                <Button id="clickToAddNewClassroomBtn" onClick={() => this.handleAddNewAttendanceDetails()}>{this.props.language !== "eng" ? `新增原因` : `Create`}</Button>
                                            </div>
                                            :
                                            <div className="classroomRow row">
                                                <div className="classroomNameBox col-lg-10 col-md-12">
                                                    <div className="containerOnSitesMan row">
                                                        <div className="inlineDisplayNoMargin col-lg-3  col-md-4">{this.props.language !== "eng" ? `請假原因` : `Leave Reason`} : </div>
                                                        <div className="col-lg-9 col-md-8">
                                                            <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedAttendanceDetails.absent_reason} onChange={this.handleAttendanceNameInput} />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="editClassroomBtn col-lg-2 col-md-12">
                                                    <div className="btnContainer">
                                                        <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveNewAttendanceDetails} >{this.props.language !== "eng" ? `儲存原因` : `Save Reason`}</Button>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                        }
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        allAttendances: state.attendanceManage.allAttendances,
        loading: state.attendanceManage.loading,
        language: state.auth.language
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        loadAllAttendances: async () => dispatch(await loadAttendanceData()),
        updateAttendanceDetails: async (attendanceDetails: any) => dispatch(await updateAttendance(attendanceDetails)),
        createNewAttendanceDetails: async (attendanceDetails: any) => dispatch(await createAttendance(attendanceDetails)),
        delAttendanceDetails: async (attendanceID: number) => dispatch(await delAttendance(attendanceID)),
        // exitFromNoticeWindow: async () => dispatch(await classroomDeleteFailedNoticed()),
        // updateSiteDetails: async (siteDetails:any) => dispatch(await updateSiteDetails(siteDetails))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceManage)
