

export function allStockCategoryLoaded( stockCategories:any) {
    return {
        type: "ALL_STOCK_CATEGORIES_LOADED" as "ALL_STOCK_CATEGORIES_LOADED",
        stockCategories
    }
}
export function stockCategoryCreated () {
    return {
        type: "STOCK_CATEGORY_CREATED" as "STOCK_CATEGORY_CREATED"
    }
}
export function stockCategoryUpdated () {
    return {
        type:"STOCK_CATEGORY_UPDATED" as "STOCK_CATEGORY_UPDATED"
    }
}
export function stockCategoryActiveSettingModified () {
    return {
        type: "STOCK_CATEGORY_ACTIVE_SETTING_MODIFIED" as "STOCK_CATEGORY_ACTIVE_SETTING_MODIFIED"
    }
}



type StockCategoryManageActionsCreators = typeof allStockCategoryLoaded |
                                    typeof stockCategoryCreated |
                                    typeof stockCategoryUpdated |
                                    typeof stockCategoryActiveSettingModified ;
                                            

export type IStockCategoryManageActions = ReturnType<StockCategoryManageActionsCreators>