import moment from "moment";
import React from "react";
import '../../fonts/D-DINCondensed.ttf';
import './SpecificPurchaseDetailsBox.css'

export interface IPrintableReceiptProps {
    language: string,
    purchaseDetails: any,
    paymentDetails: any,
    purchasedPackages: any[],
    purchasedProducts: any[],
    usedDiscounts: any[],
    processedTermsList: any[],
    customerDetails: any,
    amountReceived: number,
    allSites: any[]
}
class PrintableReceipt extends React.Component<IPrintableReceiptProps, {}> {
    constructor(props: IPrintableReceiptProps) {
        super(props);
    }

    // componentDidMount() {
    //     window.print();
    // }

    render() {
        return (
            <div id="receiptBase">
                {/* <div className="w-100"> */}
                <div className="receiptMainPart">
                    <div id="pageContainer">
                        <div className="col-3 attnColBG">
                            <div>
                                {/* <div className="logo01"></div> */}

                                <div className="attnSectionTitle02">T O</div>
                                {/* <div className="attnSectionContent">  </div> */}


                                <div className="attnSectionTitle"> # {this.props.customerDetails.id}</div>
                                <div className="attnSectionTitle"> {this.props.customerDetails.name_eng ? this.props.customerDetails.name_eng : this.props.customerDetails.name_chi}</div>
                                <div className="attnSectionContent"> {this.props.customerDetails.email ? this.props.customerDetails.email : null} </div>

                                <div className="attnSectionTitle"> P H O N E</div>
                                <div className="attnSectionContent"> +852 {this.props.customerDetails.tel ? this.props.customerDetails.tel : null} </div>

                                <div className="attnSectionTitle"> D A T E</div>
                                <div className="attnSectionContent"> {moment(this.props.purchaseDetails.created_at).format("DD, MMMM YYYY")} </div>
                            </div>
                            <div className="logoContainer">
                                <div className="logo01"></div>
                                <div className="logo02"></div>
                            </div>
                        </div>
                        <div className="col-9 mainPadding">
                            <div>
                                <div className="receiptTitleContainer">
                                    <div className="receiptTitle">R &nbsp; E &nbsp; C &nbsp; E &nbsp; I &nbsp; P &nbsp; T</div>
                                    <div className="rightAlignerOfReceipt01">
                                        <div className="purchaseIDUnderline"></div>
                                        <div className="purchaseID"># {this.props.purchaseDetails.id}</div>
                                    </div>
                                </div>

                                <div className="contentTableHead">
                                    {/* <div className="lineDivider"></div> */}
                                    <div className="spaceBetweenContainer">
                                        <div className="tableHeader">I T E M &nbsp; D E S C R I P T I O N S</div>
                                        <div className="tableHeader02">P R I C E</div>
                                    </div>
                                    <div className="lineDivider"></div>
                                </div>
                                <div className="receiptMainContentArea">
                                    {
                                        this.props.purchasedPackages.length <= 0 ? null :
                                            this.props.purchasedPackages.map((targetPackage: any) =>

                                                <div className="itemGroup">
                                                    <div className="spaceBetweenContainer">
                                                        <div className="itemDetailsContainer">
                                                            <div className="itemName">{this.props.language !== "eng" ? "套券" : "Package"} : {targetPackage.package_name}</div>
                                                            <div className="itemDetailsInline">{this.props.language !== "eng" ? "套券數量" : "Active Token"} : {targetPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>
                                                            <div className="itemDetailsInline">{this.props.language !== "eng" ? "套券有效期" : "Validate Period"} : {targetPackage.validate_period} {this.props.language !== "eng" ? "日" : ""}</div>
                                                            <div className="itemDetailsInline">{this.props.language !== "eng" ? "適用分校" : "Applicable Site"} : {targetPackage.site ? (this.props.allSites.find((site: any) => site.id === parseFloat(`${targetPackage.site}`))).site_key : this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                        </div>
                                                        <div className="priceContainer">
                                                            <div className="tableHeader02">$ {targetPackage.price}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    }
                                    {
                                        this.props.purchasedProducts.length <= 0 ? null :
                                            this.props.purchasedProducts.map((targetProduct: any) =>

                                                <div className="itemGroup">
                                                    <div className="spaceBetweenContainer">
                                                        <div className="itemDetailsContainer">
                                                            <div className="itemName">{this.props.language !== "eng" ? "產品" : "Product"} : {targetProduct.product_name}</div>
                                                            <div className="itemDetails">SKU : {targetProduct.sku} </div>
                                                            {
                                                                targetProduct.attributes.length <= 0 ? null :
                                                                    targetProduct.attributes.map((attribute: any) =>
                                                                        <div className="itemDetailsInline">{attribute.attribute} : {attribute.value} </div>

                                                                    )
                                                            }
                                                        </div>
                                                        <div className="priceContainer">
                                                            <div className="tableHeader02">$ {targetProduct.sold_price}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    }
                                    {
                                        this.props.usedDiscounts.length <= 0 ? null :
                                            <div className="discountSectionContainer">
                                                <div className="contentTableHead">
                                                    {/* <div className="lineDivider"></div> */}
                                                    <div className="spaceBetweenContainer">
                                                        <div className="tableHeader">D I S C O U N T S</div>
                                                        <div className="tableHeader02">V A L U E</div>
                                                    </div>
                                                    <div className="lineDivider"></div>
                                                </div>

                                                {this.props.usedDiscounts.map((discount: any) =>
                                                    <div className="itemGroup">
                                                        <div className="spaceBetweenContainer">
                                                            <div className="itemDetailsContainer">
                                                                <div className="itemName">{discount.discount_name}</div>

                                                            </div>
                                                            <div className="priceContainer">
                                                                <div className="tableHeader02"> - $ {discount.cash_discount ? parseFloat(discount.cash_discount).toFixed(2) : (this.props.purchaseDetails.total_price * (discount.percentage_discount / 100)).toFixed(2)}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                    }
                                </div>
                                <div className="contentTableHead">
                                    <div className="lineDivider"></div>
                                    <div className="spaceBetweenContainer">
                                        <div className="tableHeader">G R A N D &nbsp; T O T A L</div>
                                        <div className="tableHeader02">$ {this.props.purchaseDetails.amount_payable}</div>
                                    </div>
                                    <div className="lineDivider"></div>
                                </div>

                                <div className="contentTableHead02">
                                    {/* <div className="lineDivider"></div> */}
                                    <div className="spaceBetweenContainer">
                                        <div className="tableHeader tableHeaderBreakUnderline">P A Y M E N T S</div>
                                        {/* <div className="tableHeader02 tableHeaderBreakUnderline">A M O U N T</div> */}
                                    </div>
                                    {/* <div className="lineDivider"></div> */}
                                </div>
                                {
                                    this.props.paymentDetails.length <= 0 ? null :
                                        this.props.paymentDetails.map((payment: any) =>
                                            payment.void ? null :
                                                <div className="itemGroup02">
                                                    <div className="spaceBetweenContainer">
                                                        <div className="itemDetailsContainer">
                                                            <div className="itemName">{this.props.language !== "eng" ? "日期" : "Date"} : {moment(payment.created_at).format("DD, MMMM YYYY")}</div>
                                                            <div className="itemDetailsInline">{this.props.language !== "eng" ? "付款方法" : "Payment Method"} : {payment.payment_method} </div>
                                                            <div className="itemDetailsInline">{this.props.language !== "eng" ? "處理員工" : "Process Staff"} : # {payment.process_staff_id} {this.props.language !== "eng" ? "號" : ""}</div>
                                                        </div>
                                                        <div className="priceContainer">
                                                            <div className="tableHeader02">$ {payment.amount_received}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                        )
                                }
                                <div className="contentTableHead">
                                    <div className="lineDivider"></div>
                                    <div className="spaceBetweenContainer">
                                        <div className="tableHeader">T O T A L &nbsp; P A I D</div>
                                        <div className="tableHeader02">$ {this.props.amountReceived.toFixed(2)}</div>
                                    </div>
                                    <div className="lineDivider"></div>
                                </div>
                            </div>
                            <div className="footer">
                                <div className="footerContentContainer">
                                    <div className="studioName">Crystal Dance - Yuen Long</div>
                                    <div className="studioDetails">2443 7373</div>
                                    <div className="studioDetails">WtsApp : 9840 5054</div>
                                    <div className="studioDetails">{
                                        this.props.language !== "eng" ?
                                            "元朗 宏業南街22號 虹方地下3號鋪"
                                            :
                                            "Shop3,G/F,The Rainbow,22Wang Yip South Street,Yuen Long"
                                    }</div>
                                </div>

                                <div className="footerContentContainer02">
                                    <div className="studioName">More Dance - Mong Kok</div>
                                    <div className="studioDetails">2365 6223</div>
                                    <div className="studioDetails">WtsApp : 9245 2523</div>
                                    <div className="studioDetails">
                                    {
                                        this.props.language !== "eng"?
                                        "旺角 奶路臣街38號 麥花臣匯2樓"
                                        :
                                        "2/F,MacPherson Residence,38 Nelson Street,Kowloon"
                                        }</div>
                                </div>


                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                    {/* </div> */}

                    {/* <div className="w-100"> */}
                    {/* <div className="receiptMainPart"> */}
                    <div id="pageContainer">

                        <div className="col-3 attnColBG">
                            <div>
                                <div className="attnSectionTitle02">T O</div>
                                {/* <div className="attnSectionContent">  </div> */}

                                <div className="attnSectionTitle"> # {this.props.customerDetails.id}</div>
                                <div className="attnSectionTitle"> {this.props.customerDetails.name_eng ? this.props.customerDetails.name_eng : this.props.customerDetails.name_chi}</div>
                                <div className="attnSectionContent"> {this.props.customerDetails.email ? this.props.customerDetails.email : null} </div>

                                <div className="attnSectionTitle"> P H O N E</div>
                                <div className="attnSectionContent"> +852 {this.props.customerDetails.tel ? this.props.customerDetails.tel : null} </div>

                                <div className="attnSectionTitle"> D A T E</div>
                                <div className="attnSectionContent"> {moment(this.props.purchaseDetails.created_at).format("DD, MMMM YYYY")} </div>
                            </div>
                            <div className="logoContainer">
                                <div className="logo01"></div>
                                <div className="logo02"></div>
                            </div>
                        </div>
                        <div className="col-9 mainPadding">
                            <div>
                                <div className="receiptTitleContainer">
                                    <div className="receiptTitle">R &nbsp; E &nbsp; C &nbsp; E &nbsp; I &nbsp; P &nbsp; T</div>
                                    <div className="rightAlignerOfReceipt01">
                                        <div className="purchaseIDUnderline"></div>
                                        <div className="purchaseID"># {this.props.purchaseDetails.id}</div>
                                    </div>
                                </div>

                                <div className="contentTableHead">
                                    <div className="lineDivider"></div>
                                    <div className="spaceBetweenContainer">
                                        <div className="tableHeader">T E R M S &nbsp; A N D &nbsp; C O N D I T I O N S</div>
                                        {/* <div className="tableHeader02">P R I C E</div> */}
                                    </div>
                                    <div className="lineDivider"></div>
                                </div>
                                <div className="receiptMainContentArea">
                                    {
                                        this.props.processedTermsList.length <= 0 ? null :
                                            this.props.processedTermsList.map((term: any, index: number) =>
                                                <div className="itemDetails02">{index + 1}. {term}</div>


                                                // <div className="itemGroup">
                                                //     <div className="spaceBetweenContainer">
                                                //         <div className="itemDetailsContainer">
                                                //             <div className="itemName">{this.props.language !== "eng" ? "套券" : "Package"} : {targetPackage.package_name}</div>
                                                //             <div className="itemDetails">{this.props.language !== "eng" ? "套券數量" : "Active Token"} : {targetPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>
                                                //             <div className="itemDetails">{this.props.language !== "eng" ? "套券有效期" : "Validate Period"} : {targetPackage.validate_period} {this.props.language !== "eng" ? "日" : ""}</div>
                                                //         </div>
                                                //         <div className="priceContainer">
                                                //             <div className="tableHeader02">$ {targetPackage.price}</div>
                                                //         </div>
                                                //     </div>
                                                // </div>
                                            )
                                    }

                                </div>
                            </div>
                            <div className="footer">
                                <div className="footerContentContainer">
                                    <div className="studioName">Crystal Dance - Yuen Long</div>
                                    <div className="studioDetails">2443 7373</div>
                                    <div className="studioDetails">WtsApp : 9840 5054</div>
                                    <div className="studioDetails">元朗 宏業南街22號 虹方地下3號鋪</div>
                                </div>

                                <div className="footerContentContainer02">
                                    <div className="studioName">More Dance - Mong Kok</div>
                                    <div className="studioDetails">2365 6223</div>
                                    <div className="studioDetails">WtsApp : 9245 2523</div>
                                    <div className="studioDetails">旺角 奶路臣街38號 麥花臣匯2樓</div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PrintableReceipt;