import { IAuthState } from "./state";
import { IAuthActions } from "./actions";
import { RestoreOutlined } from "@material-ui/icons";

const initialState = {
    usernameTyped: '',
    passwordTyped: '',
    authenticated: (localStorage.getItem('token') != null),
    // accountUsername: null,
    // level: null,
    staffId: 0,
    invalidAttempt: false,
    staffAccessSite: 0,
    staffPost: "",
    language: ""
}

export const authReducer = (state: IAuthState = initialState, actions: IAuthActions) => {

    switch (actions.type) {
        case "USERNAME_INPUTTED":
            return {
                ...state,
                usernameTyped: actions.username
            }

        case "PASSWORD_INPUTTED":
            return {
                ...state,
                passwordTyped: actions.password
            }

        case "LOGIN_SUCCESS":
            return {
                ...state,
                usernameTyped: '',
                passwordTyped: '',
                authenticated: true,
                staffId: actions.result.staffId,
                staffAccessSite: actions.result.staffAccessSiteID,
                staffPost: actions.result.post,
                language: actions.result.language
                // accountUsername: actions.result.username,
                // level: actions.result.level
            }

        case "LOGIN_FAIL":
            return {
                ...state,
                usernameTyped: '',
                passwordTyped: '',
                authenticated: false,
                // accountUsername: null,
                // level: null,
                invalidAttempt:true
            }
        case "LOGOUT":
            return {
                ...state,
                authenticated: false,
                // accountUsername: null,
                // level: null,
                invalidAttempt: false
            }

            case "LANGUAGE_CHANGED":
                return {
                    ...state,
                    language: actions.language
                }
        default:
            return state;
    }
}