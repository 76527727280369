

export function allPaymentsLoaded (allPayments:any, allCustomers:any, allSites:any, graphData:any[]) {
    return {
        type: "ALL_PAYMENTS_LOADED" as "ALL_PAYMENTS_LOADED",
        allPayments,
        allCustomers,
        allSites,
        graphData
    }
}
export function loading () {
    return {
        type: "LOADING" as "LOADING"
    }
}

type IncomeListingActionsCreators = typeof allPaymentsLoaded |
                                    typeof loading;

export type IIncomeListingActions = ReturnType<IncomeListingActionsCreators>;