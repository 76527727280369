import React from "react";
import IncomeListing from "./IncomeListing/IncomeListing"
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import './Accountings.css';
import { connect } from "react-redux";
import { IRootState } from "../store";

export interface IAccountingsProps {
    language: string
}
export interface IAccountingsState {
    systemNavPosition: string
}

class Accountings extends React.Component<IAccountingsProps, IAccountingsState> {
    constructor(props: IAccountingsProps) {
        super(props);
        this.state = {
            systemNavPosition: 'incomeManage'
        }
    }
    private handleNavBtnClick = async (navPosition: string) => {
        await this.setState({
            systemNavPosition: navPosition
        })
    }


    render() {
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-2" id="systemLeftNavBar">
                        <div className="systemNavBarDisplayArea">
                            <div className="systemNavBarTitleArea" >
                                <div className="systemNavBarTitle">{this.props.language !== "eng" ? "會計管理列表" : "Account Manage List"}</div>
                            </div>

                            <div className="systemNavBtnArea">
                                {
                                    this.state.systemNavPosition === 'incomeManage' ?
                                        <div className="systemNavBtnActive">
                                            <HomeWorkIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "收入列表" : "Sales Records"}</div>
                                        </div>
                                        :
                                        <div className="systemNavBtn" onClick={() => this.handleNavBtnClick('incomeManage')}>
                                            <HomeWorkIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "收入列表" : "Sales Records"}</div>
                                        </div>

                                }
                            </div>

                        </div>
                    </div>
                    <div className="col-10" id="accountingMainBG">
                        {this.state.systemNavPosition !== 'incomeManage' ? null : <IncomeListing language={this.props.language}/> }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state:IRootState) => {
    return {
        language: state.auth.language
    }
}
export default connect(mapStateToProps, {})(Accountings)
// export default Accountings