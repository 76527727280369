import {IStaffAccManageState} from './state';
import {IStaffAccManageActions} from './actions'

const initialState = {
    allStaffDetails: [],
    allEmploymentStatus: [],
    allSites: [],
    loading: true
}

export const staffAccManageReducer = (state: IStaffAccManageState = initialState, actions: IStaffAccManageActions) => {
    switch (actions.type) {

        case "ALL_STAFFS_LOADED":
            return {
                ...state,
                allStaffDetails: actions.staffDetails,
                loading: false
            }
        case "PAGE_INIT_INFO_LOADED":
            return {
                ...state,
                allEmploymentStatus: actions.employmentStatuses,
                allSites: actions.allSites,
                allStaffDetails: actions.staffDetails,
                loading: false
            }
        case "NEW_STAFF_DETAILS_CREATED":
            return {
                ...state,
                loading:true
            }
        case "STAFF_DETAILS_SAVED" :
            return {
                ...state,
                loading:true

            }
        case "STAFF_LOGIN_DETAILS_RESET" :
            return {
                ...state,
                loading:true

            }
        default: 
        return state;
    }
}