import React from 'react';
import { Button, TextField } from "@material-ui/core";
import { IRootState, ThunkDispatch } from '../../store';
import { connect } from 'react-redux';


export interface IEditSoldPackageConfirmationBoxProps {
    handleExit: (confirmation: boolean) => void,
    language : string
}

export interface IEditSoldPackageConfirmationBoxState {

}

class EditSoldPackageConfirmationBox extends React.Component<IEditSoldPackageConfirmationBoxProps, IEditSoldPackageConfirmationBoxState>  {
    constructor(props: IEditSoldPackageConfirmationBoxProps) {
        super(props);
        this.state = {

        }
    }

    private handleConfirmationClick = async (confirmation: boolean) => {
        await this.props.handleExit(confirmation);
    }

    render() {
        return (
            <div className="editSoldPackageConfirmationBox">
                <div className="editSoldPackageConfirmationDisplay vAligner inlineDisplay">
                    <div className="editSoldPackageCount ">{this.props.language !== "eng" ? "要更改所有已售套券嗎？" : "Edit Applicable on All Sold Packages ?"}</div>
                    <div className="btnRow">
                        <Button id="editSoldPackageConfirmationBtn" onClick={() => this.handleConfirmationClick(false)}>{this.props.language !== "eng" ? "不更改" : "Not Applicable"}</Button>
                        <Button id="editSoldPackageConfirmationBtn02" onClick={() => this.handleConfirmationClick(true)}>{this.props.language !== "eng" ? "確定更改" : "Applicable"}</Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditSoldPackageConfirmationBox;