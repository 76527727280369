

export function allStockAttributeLoaded( stockAttributes:any) {
    return {
        type: "ALL_STOCK_ATTRIBUTES_LOADED" as "ALL_STOCK_ATTRIBUTES_LOADED",
        stockAttributes
    }
}
export function stockAttributeCreated () {
    return {
        type: "STOCK_ATTRIBUTE_CREATED" as "STOCK_ATTRIBUTE_CREATED"
    }
}
export function stockAttributeUpdated () {
    return {
        type:"STOCK_ATTRIBUTE_UPDATED" as "STOCK_ATTRIBUTE_UPDATED"
    }
}
export function stockAttributeActiveSettingModified () {
    return {
        type: "STOCK_ATTRIBUTE_ACTIVE_SETTING_MODIFIED" as "STOCK_ATTRIBUTE_ACTIVE_SETTING_MODIFIED"
    }
}



type StockAttributeManageActionsCreators = typeof allStockAttributeLoaded |
                                    typeof stockAttributeCreated |
                                    typeof stockAttributeUpdated |
                                    typeof stockAttributeActiveSettingModified ;
                                            

export type IStockAttributeManageActions = ReturnType<StockAttributeManageActionsCreators>