import React from 'react';
import { connect } from 'react-redux';
import { IRootState, ThunkDispatch } from './store';
import { usernameTyping, passwordTyping, submitClicking } from "./Auth/thunks";
import './Login.css'

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card'
import Image from './loginImage.png'
import CardMedia from '@material-ui/core/CardMedia';



interface ILoginProps {
    usernameTyped: string,
    passwordTyped: string,
    invalidAttempt: boolean,
    handleUsername: (username: string) => void,
    handlePassword: (password: string) => void,
    handleSubmit: (username: string, password: string) => void
}

class Login extends React.Component<ILoginProps, {}> {
    constructor(props: ILoginProps) {
        super(props);
    }

    private handleUsernameInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.handleUsername(event.currentTarget.value)
    }
    private handlePasswordInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.handlePassword(event.currentTarget.value)
    }

    private handleSubmitClick = async () => {
        // event.preventDefault();
        if (this.props.usernameTyped && this.props.passwordTyped) {
            await this.props.handleSubmit(this.props.usernameTyped, this.props.passwordTyped);

        } else {
            // event.preventDefault() //this is temp.
            alert("Please type in Username & Password.")
        }
    }


    componentWillMount() {

    }

    render() {
        

        return (
            <div className="container" id="loginContainer">
                <div className="row">
                    <div className="col-lg-12">
                        <Card className="loginBoard">

                            <div className="row">
                                <div className="col-7 pictureArea" id="pictureArea">
                                    {/* <img src={Image} alt="Innovation" ></img> */}
                                   
                                </div>
                                <div className="col-5">
                                    {/* <Card className="loginBoard"> */}

                                    <form id="inputForm" noValidate autoComplete="off">
                                        <h1 id="title">Login<br></br></h1>
                                        <TextField
                                            id="standard-basic"
                                            label="Username"
                                            margin="normal"
                                            value={this.props.usernameTyped}
                                            onChange={this.handleUsernameInput}

                                        />
                                        <br></br>
                                        <TextField
                                            id="standard-basic"
                                            label="Password"
                                            margin="normal"
                                            value={this.props.passwordTyped}
                                            onChange={this.handlePasswordInput}
                                            type="password"
                                        />

                                        {this.props.invalidAttempt? 
                                            <p className="warning">Invalid Username / Password</p> : 
                                            <p className="hidden">Invalid Username / Password</p>}
                                        <div>
                                            {/* <input type="submit" value="submit"></input> */}
                                            <Button variant="contained" onClick={this.handleSubmitClick}>
                                                Login
                                            </Button>
                                        </div>
                                    </form>

                                    {/* </Card> */}
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>

        )
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        handleUsername: (username: string) => dispatch(usernameTyping(username)),
        handlePassword: (password: string) => dispatch(passwordTyping(password)),
        handleSubmit: async (username: string, password: string) => dispatch(await submitClicking(username, password))
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        usernameTyped: state.auth.usernameTyped,
        passwordTyped: state.auth.passwordTyped,
        invalidAttempt: state.auth.invalidAttempt
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)