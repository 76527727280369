import { ICustomerPackageUsageHistoryState } from './state';
import { ICustomerPackageUsageHistoryActions } from './actions';

const initialState = {
    customerPackages: [],
    relatedParticipation: [],
    relatedClassOccupancy: [],
    relatedPrivateRentalOccupancy: [],
    relatedTutorListOfOccupancy: [],
    requiredTutorInfo: [],
    requiredPurchasedPackageDetails: [],
    siteList: [],
}

export const customerPackageUsageHistoryReducer = (state: ICustomerPackageUsageHistoryState = initialState, actions: ICustomerPackageUsageHistoryActions) => {

    switch (actions.type) {
        case "CUSTOMER_PACKAGE_USAGES_HISTORY_RELATED_DATA_LOADED":
            return {
                ...state,
                customerPackages: actions.customerPackages,
                relatedParticipation: actions.relatedParticipation,
                relatedClassOccupancy: actions.relatedClassOccupancy,
                relatedPrivateRentalOccupancy: actions.relatedPrivateRentalOccupancy,
                relatedTutorListOfOccupancy : actions.relatedTutorListOfOccupancy,
                requiredTutorInfo : actions.requiredTutorInfo,
                requiredPurchasedPackageDetails: actions.requiredPurchasedPackageDetails,
                siteList: actions.siteList
            }

        default:
            return state;
    }
}