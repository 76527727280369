import { ThunkDispatch } from "../../store";
import { specificPurchaseDetailsLoaded } from "./actions";

export async function loadSpecificPurchaseDetails (purchaseID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customer/loadSpecificPurchaseDetails?purchaseID=${purchaseID}`, {
        method: "GET",
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result:any = await res.json();
    if(res.status === 500) {
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(specificPurchaseDetailsLoaded(result.purchaseDetails, result.paymentDetails, result.purchasedPackages, result.purchasedProducts, result.usedDiscounts ,result.suitableTerms))
        }
    }
}