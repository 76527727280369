import React from "react";
import './DiscountsManage.css'
import { connect } from "react-redux";
import { IRootState, ThunkDispatch } from "../../store";
import { loadDiscountsData, updateDiscount, createDiscount, updateDiscountActiveSetting } from './thunks';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Switch from '@material-ui/core/Switch';
import { Button, TextField } from "@material-ui/core";
import { ChromePicker } from 'react-color';
import EditIcon from '@material-ui/icons/Edit';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';


export interface IDiscountsManageProps {
    loadAllDiscounts: () => void,
    updateDiscountDetails: (discountDetails: any) => void,
    createNewDiscountDetails: (discountDetails: any) => void,
    delDiscountDetails: (discountID: number) => void,
    // exitFromNoticeWindow: () => void,
    // updateSiteDetails: (siteDetails:any) => void,
    language: string,
    loading: boolean,
    allDiscounts: any,
    // delClassroomFailed: boolean
}
export interface IDiscountsManageState {
    selectedDiscountID: number,
    selectedDiscountDetails: any,
    addNewDiscount: boolean,
}
class DiscountsManage extends React.Component<IDiscountsManageProps, IDiscountsManageState> {
    constructor(props: IDiscountsManageProps) {
        super(props);
        this.state = {
            selectedDiscountID: 0,
            selectedDiscountDetails: '',
            addNewDiscount: false
        }
    }

    private handleEditDiscountClick = async (discountID: number) => {
        let discountDetails = (this.props.allDiscounts.filter((discount: any) => discount.id === discountID))[0]
        if (discountDetails.cash_discount) {
            discountDetails.discountCash = true
        } else {
            discountDetails.discountCash = false

        }
        await this.setState({
            selectedDiscountID: discountID,
            selectedDiscountDetails: discountDetails
        })
    }
    private handleDiscountNameInput = async (event: any) => {
        await this.setState({
            selectedDiscountDetails: {
                ...this.state.selectedDiscountDetails,
                discount_type: event.target.value
            }
        })
    }

    private handleSaveDiscountDetails = async () => {
        this.props.updateDiscountDetails(this.state.selectedDiscountDetails);
        await this.setState({
            selectedDiscountID: 0,
            selectedDiscountDetails: ''
        })
        setTimeout(async () => await this.props.loadAllDiscounts(), 1000);
    }
    private handleAddNewDiscountDetails = async () => {
        await this.setState({
            addNewDiscount: true,
            selectedDiscountDetails: {
                discount_type: '',
                percentage_discount: 0,
                cash_discount: null,
                expiry_date: null,
                discountCash: false
            }
        })
    }
    private handleSaveNewDiscountDetails = async () => {
        if (this.state.selectedDiscountDetails.discount_type === '') {
            window.alert('請輸入優惠名稱！')
        } else {
            await this.props.createNewDiscountDetails(this.state.selectedDiscountDetails);
            await this.setState({
                selectedDiscountID: 0,
                selectedDiscountDetails: '',
                addNewDiscount: false,
            })
            setTimeout(async () => await this.props.loadAllDiscounts(), 1000);

        }
    }

    private handleDelDiscountDetails = async (discountID: number) => {
        let confirmation = await window.confirm('你確定要刪除此優惠嗎？');
        if (confirmation) {
            await this.props.delDiscountDetails(discountID);
            setTimeout(async () => await this.props.loadAllDiscounts(), 1000);

        }
    }
    private handleEditDiscountCountMethod = async () => {
        if (!this.state.selectedDiscountDetails.discountCash) {
            console.log('good01')

            await this.setState({
                selectedDiscountDetails: {
                    ...this.state.selectedDiscountDetails,
                    percentage_discount: null,
                    cash_discount: 0.00,
                    discountCash: true

                }
            })
        } else {
            console.log('good02')

            await this.setState({
                selectedDiscountDetails: {
                    ...this.state.selectedDiscountDetails,
                    cash_discount: null,
                    percentage_discount: 0,
                    discountCash: false
                }
            })
        }
    }

    private handleDiscountAmountInput = async (event: any) => {
        console.log(event.target.value)

        if (event.target.value === '') {
            if (this.state.selectedDiscountDetails.percentage_discount) {
                await this.setState({
                    selectedDiscountDetails: {
                        ...this.state.selectedDiscountDetails,
                        percentage_discount: 0
                    }
                })
            } else {
                await this.setState({
                    selectedDiscountDetails: {
                        ...this.state.selectedDiscountDetails,
                        cash_discount: 0
                    }
                })
            }
        } else {
            if (parseFloat(event.target.value) >= 0) {
                console.log('hi')
                if (this.state.selectedDiscountDetails.percentage_discount || this.state.selectedDiscountDetails.percentage_discount === 0) {
                    await this.setState({
                        selectedDiscountDetails: {
                            ...this.state.selectedDiscountDetails,
                            percentage_discount: parseFloat(event.target.value)
                        }
                    })
                } else {
                    await this.setState({
                        selectedDiscountDetails: {
                            ...this.state.selectedDiscountDetails,
                            cash_discount: parseFloat(event.target.value).toFixed(2)
                        }
                    })
                }
            }
        }
    }


    private handleExpiryDateChange = async (event: any) => {
        if (event.target.value !== "") {
            console.log('bye')
            await this.setState({
                selectedDiscountDetails: {
                    ...this.state.selectedDiscountDetails,
                    expiry_date: moment(event.target.value).toISOString()
                }
            })
        } else {
            console.log('hi')
            await this.setState({
                selectedDiscountDetails: {
                    ...this.state.selectedDiscountDetails,
                    expiry_date: null
                }
            })
        }
    }


    componentWillMount() {
        this.props.loadAllDiscounts();
    }

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="systemMainDisplayArea row">
                        <div className="systemManagePageTitle col-12"> <CardGiftcardIcon id="systemManPageTitleIcon" /> {this.props.language !== "eng" ? "優惠管理" : "Discounts Manage"}</div>
                        {
                            this.props.loading ?
                                <div className="loadingContainerRow">
                                    <div className="inlineDisplay">
                                        <div className="loadingContainer">
                                            <CircularProgress id="loadingCircle" />
                                            <div className="loadingLabel">Loading ...</div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="siteDisplayArea col-12">
                                    <div className="siteTitle">{this.props.language !== "eng" ? "優惠種類" : "Discounts Type"}</div>
                                    {/* <div className="groupingFixedHeightBox"> */}
                                    {//RENDER ALL ACTIVE
                                        this.props.allDiscounts.map((discount: any) =>
                                            <div className="classroomRow row">
                                                <div className="discountPropsBox col-xl-3 col-lg-12 col-md-12">
                                                    <div className="containerOnSitesMan row">
                                                        <div className="inlineDisplayNoMargin  col-4">{this.props.language !== "eng" ? "優惠" : "Discount"} : </div>
                                                        {
                                                            this.state.selectedDiscountID !== discount.id ?
                                                                <div className="inlineDisplayNoMargin detailsDisplayField col-8"> {discount.discount_type} </div>
                                                                :
                                                                <div className="col-8">
                                                                    <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedDiscountDetails.discount_type} onChange={this.handleDiscountNameInput} />
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="baseDiscountBox col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="containerOnSitesMan row">
                                                        <div className="col-4">
                                                            {
                                                                this.props.language !== "eng" ?
                                                                    this.state.selectedDiscountID !== discount.id ?
                                                                        discount.percentage_discount ?
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Switch
                                                                                        checked={true}
                                                                                        name="baseDiscount"
                                                                                        color="primary"
                                                                                    />
                                                                                }

                                                                                label="優惠率"
                                                                                labelPlacement="top"
                                                                            />
                                                                            :
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Switch
                                                                                        checked={false}
                                                                                        name="baseDiscount"
                                                                                        color="primary"
                                                                                    />
                                                                                }
                                                                                label="優惠額"
                                                                                labelPlacement="top"
                                                                            />
                                                                        :
                                                                        !this.state.selectedDiscountDetails.discountCash ?
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Switch
                                                                                        checked={!this.state.selectedDiscountDetails.discountCash}

                                                                                        onChange={this.handleEditDiscountCountMethod}
                                                                                        name="baseDiscount"
                                                                                        color="primary"
                                                                                    />
                                                                                }

                                                                                label="優惠率"
                                                                                labelPlacement="top"
                                                                            />
                                                                            :
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Switch
                                                                                        checked={!this.state.selectedDiscountDetails.discountCash}
                                                                                        defaultChecked={false}
                                                                                        onChange={this.handleEditDiscountCountMethod}
                                                                                        name="baseDiscount"
                                                                                        color="primary"
                                                                                    />
                                                                                }
                                                                                label="優惠額"
                                                                                labelPlacement="top"
                                                                            />
                                                                    :
                                                                    this.state.selectedDiscountID !== discount.id ?
                                                                        discount.percentage_discount ?
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Switch
                                                                                        checked={true}
                                                                                        name="baseDiscount"
                                                                                        color="primary"
                                                                                    />
                                                                                }

                                                                                label="Rate"
                                                                                labelPlacement="top"
                                                                            />
                                                                            :
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Switch
                                                                                        checked={false}
                                                                                        name="baseDiscount"
                                                                                        color="primary"
                                                                                    />
                                                                                }
                                                                                label="Amount"
                                                                                labelPlacement="top"
                                                                            />
                                                                        :
                                                                        !this.state.selectedDiscountDetails.discountCash ?
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Switch
                                                                                        checked={!this.state.selectedDiscountDetails.discountCash}

                                                                                        onChange={this.handleEditDiscountCountMethod}
                                                                                        name="baseDiscount"
                                                                                        color="primary"
                                                                                    />
                                                                                }

                                                                                label="Rate"
                                                                                labelPlacement="top"
                                                                            />
                                                                            :
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Switch
                                                                                        checked={!this.state.selectedDiscountDetails.discountCash}
                                                                                        defaultChecked={false}
                                                                                        onChange={this.handleEditDiscountCountMethod}
                                                                                        name="baseDiscount"
                                                                                        color="primary"
                                                                                    />
                                                                                }
                                                                                label="Amount"
                                                                                labelPlacement="top"
                                                                            />
                                                            }
                                                        </div>
                                                        {
                                                            this.state.selectedDiscountID !== discount.id ?
                                                                discount.percentage_discount ?
                                                                    // <div className="col-8">
                                                                    <div className="inlineDisplayNoMargin detailsDisplayField col-8"> {discount.percentage_discount} % </div>
                                                                    // </div>
                                                                    :
                                                                    // <div className="col-8">
                                                                    <div className="inlineDisplayNoMargin detailsDisplayField col-8"> $ {discount.cash_discount} </div>
                                                                // </div>
                                                                :
                                                                !this.state.selectedDiscountDetails.discountCash ?
                                                                    <div className="col-8">
                                                                        <TextField className="discountAmountDetailsEditingInputs" variant="outlined" value={parseInt(this.state.selectedDiscountDetails.percentage_discount)} onChange={this.handleDiscountAmountInput} />
                                                                    </div>
                                                                    :
                                                                    <div className="col-8">
                                                                        <TextField className="discountAmountDetailsEditingInputs" variant="outlined" value={parseInt(this.state.selectedDiscountDetails.cash_discount)} onChange={this.handleDiscountAmountInput} />
                                                                    </div>
                                                        }
                                                    </div>
                                                </div>

                                                {/* <div className="discountAmountBox col-xl-2 col-lg-3 col-md-8 col-sm-8">
                                                    <div className="containerOnSitesMan row"> */}
                                                {/* <div className="inlineDisplay col-4">
                                                            {
                                                                this.props.language !== "eng" ?
                                                                    this.state.selectedDiscountID !== discount.id ?
                                                                        discount.percentage_discount ?
                                                                            "優惠率 :"
                                                                            :
                                                                            "優惠額 :"
                                                                        :
                                                                        !this.state.selectedDiscountDetails.discountCash ?
                                                                            "優惠率 :"
                                                                            :
                                                                            "優惠額 :"
                                                                    :
                                                                    this.state.selectedDiscountID !== discount.id ?
                                                                        discount.percentage_discount ?
                                                                            "Rate :"
                                                                            :
                                                                            "Amount :"
                                                                        :
                                                                        !this.state.selectedDiscountDetails.discountCash ?
                                                                            "Rate :"
                                                                            :
                                                                            "Amount :"
                                                            }
                                                        </div> */}
                                                {/* {
                                                            this.state.selectedDiscountID !== discount.id ?
                                                                discount.percentage_discount ?
                                                                    <div className="inlineDisplay discountAmountDetailsDisplayField col-12"> {discount.percentage_discount} % </div>
                                                                    :
                                                                    <div className="inlineDisplay discountAmountDetailsDisplayField col-12"> $ {discount.cash_discount} </div>

                                                                :
                                                                !this.state.selectedDiscountDetails.discountCash ?
                                                                    <TextField className="discountAmountDetailsEditingInputs" variant="outlined" value={parseInt(this.state.selectedDiscountDetails.percentage_discount)} onChange={this.handleDiscountAmountInput} />
                                                                    :
                                                                    <TextField className="discountAmountDetailsEditingInputs" variant="outlined" value={parseInt(this.state.selectedDiscountDetails.cash_discount)} onChange={this.handleDiscountAmountInput} />
                                                        } */}
                                                {/* </div>
                                                </div> */}
                                                <div className="discountPropsBox col-xl-3 col-lg-12 col-md-12 col-sm-12 mb-1">
                                                    <div className="containerOnSitesMan row">
                                                        <div className="inlineDisplayNoMargin col-4">{this.props.language === " chi" ? "統一到期日" : "Exp. Date"} : </div>
                                                        {
                                                            this.state.selectedDiscountID !== discount.id ?
                                                                <div className="inlineDisplayNoMargin discountDateDetailsDisplayField col-8"> {discount.expiry_date ? moment(discount.expiry_date).format('YYYY-MM-DD') : this.props.language !== "eng" ? "不限" : "Unlimited"} </div>
                                                                :
                                                                <div className="col-8">
                                                                    <TextField
                                                                        className="discountExDateDetailsEditingInputs"
                                                                        // variant="outlined"
                                                                        type="date"
                                                                        value={moment(this.state.selectedDiscountDetails.expiry_date).format('YYYY-MM-DD')}
                                                                        onChange={this.handleExpiryDateChange}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="discountStatusBox col-xl-2 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="containerOnSitesMan row ">
                                                        <div className="inlineDisplayNoMargin col-4">{this.props.language !== "eng" ? "狀態" : "State"} : </div>
                                                        {
                                                            this.state.selectedDiscountID !== discount.id ?
                                                                <div className="inlineDisplayNoMargin col-8 detailsDisplayField"> {this.props.language !== "eng" ? "現正生效" : "Active"}  </div>
                                                                :
                                                                <div className="col-8">
                                                                    <TextField className="detailsEditingInputs" variant="outlined" value={this.props.language !== "eng" ? "現正生效" : "Active"} disabled />
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="editClassroomBtn">
                                                    {
                                                        this.state.selectedDiscountID !== discount.id ?
                                                            <div className="btnContainer">
                                                                <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={() => this.handleEditDiscountClick(discount.id)}>{this.props.language !== "eng" ? "修改" : "Edit"}</Button>
                                                                <Button id="clickToDelClassroomBtn" onClick={() => this.handleDelDiscountDetails(discount.id)}>{this.props.language !== "eng" ? "刪除優惠" : "Delete Discount"}</Button>
                                                            </div>
                                                            :
                                                            <div className="btnContainer">
                                                                <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveDiscountDetails} >{this.props.language !== "eng" ? "儲存優惠" : "Save Discount"}</Button>
                                                            </div>
                                                    }
                                                </div>

                                            </div>
                                        )
                                    }

                                    {
                                        !this.state.addNewDiscount ?
                                            <div className="addClassroomRow">
                                                <Button id="clickToAddNewClassroomBtn" onClick={() => this.handleAddNewDiscountDetails()}>{this.props.language !== "eng" ? "新增優惠" : "Create Discount"}</Button>
                                            </div>
                                            :
                                            <div className="classroomRow row">
                                                <div className="discountPropsBox col-xl-3 col-lg-12 col-md-12">
                                                    <div className="containerOnSitesMan  row">
                                                        <div className="inlineDisplayNoMargin col-4">{this.props.language !== "eng" ? "優惠" : "Discount"} : </div>
                                                        <div className="col-8">
                                                            <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedDiscountDetails.discount_type} onChange={this.handleDiscountNameInput} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="baseDiscountBox col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="containerOnSitesMan row">
                                                        <div className="col-4">
                                                            {
                                                                this.props.language !== "eng" ?
                                                                    !this.state.selectedDiscountDetails.discountCash ?
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={!this.state.selectedDiscountDetails.discountCash}

                                                                                    onChange={this.handleEditDiscountCountMethod}
                                                                                    name="baseDiscount"
                                                                                    color="primary"
                                                                                />
                                                                            }

                                                                            label="優惠率"
                                                                            labelPlacement="top"
                                                                        />
                                                                        :
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={!this.state.selectedDiscountDetails.discountCash}
                                                                                    defaultChecked={false}
                                                                                    onChange={this.handleEditDiscountCountMethod}
                                                                                    name="baseDiscount"
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label="優惠額"
                                                                            labelPlacement="top"
                                                                        />
                                                                    :
                                                                    !this.state.selectedDiscountDetails.discountCash ?
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={!this.state.selectedDiscountDetails.discountCash}

                                                                                    onChange={this.handleEditDiscountCountMethod}
                                                                                    name="baseDiscount"
                                                                                    color="primary"
                                                                                />
                                                                            }

                                                                            label="Rate"
                                                                            labelPlacement="top"
                                                                        />
                                                                        :
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={!this.state.selectedDiscountDetails.discountCash}
                                                                                    defaultChecked={false}
                                                                                    onChange={this.handleEditDiscountCountMethod}
                                                                                    name="baseDiscount"
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label="Amount"
                                                                            labelPlacement="top"
                                                                        />
                                                            }
                                                        </div>
                                                        {
                                                            !this.state.selectedDiscountDetails.discountCash ?
                                                                <div className="col-8">
                                                                    <TextField className="discountAmountDetailsEditingInputs" variant="outlined" value={parseInt(this.state.selectedDiscountDetails.percentage_discount)} onChange={this.handleDiscountAmountInput} />
                                                                </div>
                                                                :
                                                                <div className="col-8">
                                                                    <TextField className="discountAmountDetailsEditingInputs" variant="outlined" value={parseInt(this.state.selectedDiscountDetails.cash_discount)} onChange={this.handleDiscountAmountInput} />
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                {/* <div className="discountAmountBox">
                                                    <div className="containerOnSitesMan">
                                                        <div className="inlineDisplay">
                                                            {
                                                                this.props.language !== "eng" ?
                                                                    !this.state.selectedDiscountDetails.discountCash ?
                                                                        "優惠率 :"
                                                                        :
                                                                        "優惠額 :"
                                                                    :
                                                                    !this.state.selectedDiscountDetails.discountCash ?
                                                                        "Rate :"
                                                                        :
                                                                        "Amount :"
                                                            }
                                                        </div>
                                                        {
                                                            !this.state.selectedDiscountDetails.discountCash ?
                                                                <TextField className="discountAmountDetailsEditingInputs" variant="outlined" value={parseInt(this.state.selectedDiscountDetails.percentage_discount)} onChange={this.handleDiscountAmountInput} />
                                                                :
                                                                <TextField className="discountAmountDetailsEditingInputs" variant="outlined" value={parseInt(this.state.selectedDiscountDetails.cash_discount)} onChange={this.handleDiscountAmountInput} />
                                                        }
                                                    </div>
                                                </div> */}
                                                <div className="discountPropsBox col-xl-3 col-lg-12 col-md-12 col-sm-12 mb-1">
                                                    <div className="containerOnSitesMan row">
                                                        <div className="inlineDisplayNoMargin col-4">{this.props.language === " chi" ? "統一到期日" : "Exp. Date"} : </div>

                                                        {
                                                            <div className="col-8">
                                                                <TextField
                                                                    className="discountExDateDetailsEditingInputs"
                                                                    // variant="outlined"
                                                                    type="date"
                                                                    value={moment(this.state.selectedDiscountDetails.expiry_date).format('YYYY-MM-DD')}
                                                                    onChange={this.handleExpiryDateChange}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="editClassroomBtn">
                                                    <div className="btnContainer">
                                                        <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveNewDiscountDetails} >{this.props.language === " chi" ? "儲存條款" : "Save Discount"}</Button>
                                                    </div>
                                                </div>

                                            </div>
                                    }


                                </div>

                        }
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        allDiscounts: state.discountsManage.allDiscounts,
        loading: state.discountsManage.loading,
        language: state.auth.language
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        loadAllDiscounts: async () => dispatch(await loadDiscountsData()),
        updateDiscountDetails: async (genreDetails: any) => dispatch(await updateDiscount(genreDetails)),
        createNewDiscountDetails: async (genreDetails: any) => dispatch(await createDiscount(genreDetails)),
        delDiscountDetails: async (genreID: number) => dispatch(await updateDiscountActiveSetting(genreID)),
        // exitFromNoticeWindow: async () => dispatch(await classroomDeleteFailedNoticed()),
        // updateSiteDetails: async (siteDetails:any) => dispatch(await updateSiteDetails(siteDetails))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscountsManage)
