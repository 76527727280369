import React from 'react';
import { Button, TextField } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { IRootState } from '../../store';
import { connect } from 'react-redux';

export interface ICreateMassTokenEditBoxProps {
    handleExit: () => void,
    confirmAddNewMassEdit: (newStaffDetails: any) => void,
    finishing: boolean,
    allSites: any[],
    language: string,
    // employmentStatusList: any[]
}
export interface ICreateMassTokenEditBoxState {
    createStaffSteps: any[],
    activeStep: number,
    newMassEdit: any
}

class CreateMassTokenEditBox extends React.Component<ICreateMassTokenEditBoxProps, ICreateMassTokenEditBoxState> {
    constructor(props: ICreateMassTokenEditBoxProps) {
        super(props);
        this.state = {
            createStaffSteps: (this.props.language !== "eng" ? ['輸入集體改動資料', '正處理集體改動', '完成集體改動'] : ['Enter Mass Edit Details', 'Processing', 'Finished']),
            activeStep: 0,
            newMassEdit: {
                edit_days: 0,
                edit_remarks: '',
                edit_range_site_id: -1
            }

        }
    }
    private handleDeclineCreateNewStaff = async () => {
        let confirmation = true;
        if(this.props.language !== "eng"){
            confirmation = window.confirm('你確定要取消新增集體改動嗎？');
        } else {
            confirmation = window.confirm('Confirm Aborting the New Mass Edit ?');

        }
        if (confirmation) {
            await this.props.handleExit();
        }
    }

    private handleNextStepClick = async () => {
        await this.setState({
            activeStep: this.state.activeStep + 1
        })
    }

    private handleNewStaffDetailsInput = (field: string) => async (event: any) => {
        if (field === "edit_days") {
            await this.setState({
                newMassEdit: {
                    ...this.state.newMassEdit,
                    [field]: parseInt(event.target.value)
                }
            })
            return;
        }
        await this.setState({
            newMassEdit: {
                ...this.state.newMassEdit,
                [field]: event.target.value
            }
        })
    }

    private handleConfirmAddClick = async () => {
        let newMassEdit = this.state.newMassEdit;
        if (newMassEdit.edit_days === 0 || newMassEdit.edit_remarks === '' || newMassEdit.edit_range_site_id === -1) {
            window.alert('請填寫所需資料')
        } else {
            await this.props.confirmAddNewMassEdit(newMassEdit);
            await this.setState({
                activeStep: 1
            })
        }
    }

    async componentDidUpdate() {
        if (this.state.activeStep === 1 && this.props.finishing) {
            setTimeout(async () => await this.setState({
                activeStep: 2
            }), 1000);

        }
    }

    render() {
        return (
            <div className="createStaffArea">
                <div className="staffListingAreaTitleRow">
                    <div className="staffListingAreaTitle">{this.props.language !== "eng" ? "新增集體改動" : "Create Mass Package Edit"}</div>
                    {
                        this.state.activeStep === 0 ?
                            <Button id="cxlCreateStaffBtn" startIcon={<CloseIcon />} onClick={this.handleDeclineCreateNewStaff}> {this.props.language !== "eng" ? "取消新增" : "Aboard"}  </Button>
                            : null
                    }
                </div>
                <div className="stepsIndicatorRow">
                    <Stepper id="stepIconColor" activeStep={this.state.activeStep} alternativeLabel>
                        {this.state.createStaffSteps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                <div className="fixedHeightWindowOnCreateNewMassTokenEditBox">
                    <div className="mainInputRow">
                        {
                            this.state.activeStep === 0 ?
                                //FIRST STEP
                                <div className="tokenContentInputContainer">
                                    <div className="inputRowOnAddPackageComponent">
                                        <div className="tokenNameLabel">*{this.props.language !== "eng" ? "改動備注" : "Edit Remarks"}  :</div>
                                        <TextField className="packageNameInput" variant="outlined" value={this.state.newMassEdit.edit_remarks} onChange={this.handleNewStaffDetailsInput('edit_remarks')} />
                                    </div>
                                    <div className="inputRowOnAddPackageComponent">
                                        <div className="tokenNameLabel">*{this.props.language !== "eng" ? "改動日數" : "Edit Days"} :</div>
                                        <TextField className="packageNameInput" type="number" variant="outlined" value={this.state.newMassEdit.edit_days} onChange={this.handleNewStaffDetailsInput('edit_days')} />
                                    </div>
                                    <div className="inputRowOnAddPackageComponent">
                                        <div className="tokenNameLabel">*{this.props.language !== "eng" ? "改動範圍" : "Site Param"} :</div>
                                        <FormControl variant="outlined" className="packageNameInput">
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={this.state.newMassEdit.edit_range_site_id}
                                                onChange={this.handleNewStaffDetailsInput('edit_range_site_id')}
                                            >
                                                <MenuItem value={-1}>{this.props.language ===  "chi" ? "未選擇" : "Please Choose"}</MenuItem>
                                                <MenuItem value={0}>{this.props.language ===  "chi" ? "只改不限分校套券" : "Unlimited Site ONLY"}</MenuItem>
                                                {
                                                    this.props.allSites.map((site: any) =>
                                                        <MenuItem value={site.id}>{this.props.language ===  "chi" ? "只改" : "Edit"} {site.site_key} {this.props.language ===  "chi" ? "分校套券" : "Applicable Packages ONLY"}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>

                                </div>
                                :
                                this.state.activeStep === 1 ?
                                    <div className="loadingContainerRow">
                                        <div className="inlineDisplay">
                                            <div className="loadingContainer">
                                                <CircularProgress id="loadingCircle" />
                                                <div className="loadingLabel">Loading ...</div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    this.state.activeStep === 2 ?
                                        <div className="loadingContainerRow">
                                            <div className="inlineDisplay">
                                                <div className="loadingContainer">
                                                    <DoneIcon />
                                                    <div className="loadingLabel">{this.props.language ===  "chi" ? "已完成集體改動" : "Finish Targeted Mass Edit"}</div>
                                                </div>
                                            </div>
                                        </div>

                                        : null
                        }
                    </div>
                </div>

                <div className="modifyStepsRowOnCreateMassTokenEdit">
                    {/* {this.state.activeStep === 0 ? <Button id="stepButtonNoShow" disabled>上一步</Button> : <Button id="stepButton" onClick={this.handlePreviousStepClick}>上一步</Button>} */}
                    {this.state.activeStep === 0 && this.state.newMassEdit.edit_days !== 0 && this.state.newMassEdit.edit_remarks !== '' && this.state.newMassEdit.edit_range_site_id !== -1?
                        <Button id="stepButtonOnCreateMassTokenEdit" onClick={this.handleConfirmAddClick}>{this.props.language !== "eng" ? "確定新增集體改動" : "Confirm Mass Edit"}</Button>
                        :
                        this.state.activeStep === 1 ?
                            <Button id="stepButtonDisabled" onClick={this.handleNextStepClick} disabled>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>
                            :
                            this.state.activeStep === 2 ?
                                <Button id="stepButtonOnCreateMassTokenEdit" onClick={this.props.handleExit}>{this.props.language !== "eng" ? "完成新增集體改動" : "Finished Mass Edit"}</Button>
                                :
                                <Button id="stepButtonDisabled" onClick={this.handleNextStepClick} disabled>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>


                    }
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        allSites: state.sitesClassroomsManage.allSites,
        language: state.auth.language
    }
}
export default connect (mapStateToProps, {})(CreateMassTokenEditBox)
// export default CreateMassTokenEditBox;