import { ThunkDispatch } from "../../store";
import { customerSearchResultLoaded ,availablePackagesOfCustomerLoaded, newRoomRentalDetailCreated} from "./actions";

export async function searchCustomerInfo (searchMethod:string,searchInput: any) {
    
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customerParticipation/loadCustomerInfoBySearchingInput?searchMethod=${searchMethod}&searchInput=${searchInput}`, {
        method: 'GET',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        }
    })
    let result : any= await res.json();

    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        
        return async (dispatch:ThunkDispatch) => {
            
            dispatch(customerSearchResultLoaded(result.customerSearchResult));
            console.log(result)
        }
    }
}

export async function loadAvailablePackagesForAddingNewPrivateClass (customerID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customerParticipation/loadCustomerPackagesforAddingRoomRentalUse?customerID=${customerID}`, {
        method: 'GET',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        }
    });

    let result = await res.json();

    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        
        return async (dispatch:ThunkDispatch) => {
            
            dispatch(availablePackagesOfCustomerLoaded(result));
            console.log(result)
        }
    }

}

export async function createNewRoomRentalDetails (customerID:number, siteID:number, classroomID:number, startTime: any, endTime:any, tokenPackageIDs: any[], bookingCount:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/schedule/newRoomRentalOccupancy`, {
        method: 'POST',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        },
        body: JSON.stringify({
            bookingCount: bookingCount,
            tokenPackageIDs: tokenPackageIDs,
            occupancyDetails : {
                site_id: siteID,
                classroom_id: classroomID,
                start_time: startTime,
                end_time:endTime,
                customer_id:customerID
            }
        })
    });

    let result = await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        
        return async (dispatch:ThunkDispatch) => {
            dispatch(newRoomRentalDetailCreated());
            console.log(result)
        }
    }
}