

export function allCustomerLoaded(allCustomerArray:any[], customerCount:number, allCustomerGroupingList:any) {
    return {
        type: "ALL_CUSTOMER_LOADED" as "ALL_CUSTOMER_LOADED",
        allCustomerInfo: allCustomerArray,
        totalCustomerCount: customerCount,
        allCustomerGroupingList: allCustomerGroupingList
    }
}
export function infoInfrastructureLoaded ( genreTypes:any, ageGroups:any, tokenUsages:any ,allSites:any) {
    return {
        type: "INFO_INFRASTRUCTURE_LOADED" as "INFO_INFRASTRUCTURE_LOADED",
        genreTypes: genreTypes,
        ageGroups: ageGroups,
        tokenUsages: tokenUsages,
        allSites: allSites
    }
}

export function packagesWithRightUsageLoaded (packageList: any) {
    return {
        type: "PACKAGES_WITH_RIGHT_USAGE_LOADED" as "PACKAGES_WITH_RIGHT_USAGE_LOADED",
        packageList: packageList
    }
}

export function purchasePackagesDetailsLoaded (packageList:any) {
    return {
        type: "PURCHASE_PACKAGES_DETAILS_LOADED" as "PURCHASE_PACKAGES_DETAILS_LOADED",
        packageList: packageList
    }
}

export function purchaseProductsDetailsLoaded (productList:any) {
    return {
        type: "PURCHASE_PRODUCTS_DETAILS_LOADED" as "PURCHASE_PRODUCTS_DETAILS_LOADED",
        productList: productList
    }
}

export function targetProductReleased () {
    return {
        type: "TARGET_PRODUCT_RELEASED" as "TARGET_PRODUCT_RELEASED"
    }
}

export function discountOwnBySelectedCustomerLoaded (discountList:any) {
    return {
        type: "DISCOUNT_OWNED_BY_SELECTED_CUSTOMER_LOADED" as "DISCOUNT_OWNED_BY_SELECTED_CUSTOMER_LOADED",
        discountList : discountList
    }
}

export function newPurchaseSubmittedSuccessfully () {
    return {
        type: "NEW_PURCHASE_OF_CUSTOMER_UPDATED_SUCCESSFULLY_BY_STAFF" as "NEW_PURCHASE_OF_CUSTOMER_UPDATED_SUCCESSFULLY_BY_STAFF",
    }
}

export function newPurchaseSubmittedWithNoTokenBookingNeedsToBeHandled (newPackageDetails:any, previousNoTokenBooking:any[]){
    return {
        type: "NEW_PURCHASE_SUBMITTED_WITH_NO_TOKEN_BOOKING_NEEDS_TO_BE_HANDLED" as "NEW_PURCHASE_SUBMITTED_WITH_NO_TOKEN_BOOKING_NEEDS_TO_BE_HANDLED",
        newPackageDetails, 
        previousNoTokenBooking
    }
}

export function packagesOwnByCustomerLoaded (customerPackagesAndDiscounts: any) {
    return {
        type: "PACKAGES_OWNED_BY_CUSTOMER_LOADED" as  "PACKAGES_OWNED_BY_CUSTOMER_LOADED",
        packagesOwnByCustomer: customerPackagesAndDiscounts.customerPackages,
        allDiscountTypes: customerPackagesAndDiscounts.allDiscountTypes
    }
}

export function periodPackageActivated () {
    return {
        type: "PERIOD_PACKAGE_OF_CUSTOMER_ACTIVATED" as "PERIOD_PACKAGE_OF_CUSTOMER_ACTIVATED"
    }
}

export function purchaseTermLibraryLoaded (purchaseTermsLibrary: any[]) {
    return{
        type: "PURCHASE_TERMS_LIBRARY_LOADED" as "PURCHASE_TERMS_LIBRARY_LOADED",
        purchaseTermsLibrary
    }
}

export function discountAddedToCustomer () {
    return {
        type: "DISCOUNT_ADDED_TO_CUSTOMER" as "DISCOUNT_ADDED_TO_CUSTOMER"
    }
}

export function discountDelFromCustomer () {
    return {
        type:"DISCOUNT_DELETED_FROM_CUSTOMER" as "DISCOUNT_DELETED_FROM_CUSTOMER"
    }
}

export function relatedGuardianDetailsLoaded (guardianDetails:any){
    return {
        type: "RELATED_GUARDIAN_DETAILS_LOADED" as "RELATED_GUARDIAN_DETAILS_LOADED",
        guardianDetails: guardianDetails.guardianDetails
    }
}

export function relatedGuardianDetailsReset () {
    return {
        type: "RELATED_GUARDIAN_DETAILS_RESET" as "RELATED_GUARDIAN_DETAILS_RESET"
    }
}

export function relatedGuardianDetailsCreated (guardianDetails:any) {
    return {
        type: "RELATED_GUARDIAN_DETAILS_CREATED" as "RELATED_GUARDIAN_DETAILS_CREATED",
        guardianDetails
    }
}

export function relatedGuardianDetailsUpdated (guardianDetails:any) {
    return {
        type: "RELATED_GUARDIAN_DETAILS_UPDATED" as "RELATED_GUARDIAN_DETAILS_UPDATED",
        guardianDetails
    }
}

export function relatedGuardianDetailsDeleted () {
    return {
        type: "RELATED_GUARDIAN_DETAILS_DELETED" as "RELATED_GUARDIAN_DETAILS_DELETED"
    }
}

export function customerGroupingIDUpdated () {
    return {
        type: "CUSTOMER_GROUPING_ID_UPDATED" as "CUSTOMER_GROUPING_ID_UPDATED"
    }
}

export function customerPackageUpdatedManually () {
    return {
        type: "CUSTOMER_PACKAGE_UPDATED_MANUALLY" as "CUSTOMER_PACKAGE_UPDATED_MANUALLY"
    }
}

export function markReferralDetailsAfterwardsSuccess () {
    return {
        type: "MARK_REFERRAL_DETAILS_AFTERWARDS_SUCCESS" as "MARK_REFERRAL_DETAILS_AFTERWARDS_SUCCESS"
    }
}
export function noTokenBookingProcessed () {
    return {
        type: "NO_TOKEN_BOOKING_PROCESSED" as "NO_TOKEN_BOOKING_PROCESSED"

    }
}
export function exitFromProcessNoTokenBookingWindow () {
    return {
        type: "EXIT_FROM_NO_TOKEN_BOOKING_WINDOW" as "EXIT_FROM_NO_TOKEN_BOOKING_WINDOW"
    }
}

export function allProductStorageSummaryLoaded (uniqueStockBasicDetails:any, allSites:any, allAttributeTypes: any, allCategoryTypes:any) {
    return {
        type: "ALL_PRODUCT_STORAGE_SUMMARY_LOADED" as "ALL_PRODUCT_STORAGE_SUMMARY_LOADED",
        uniqueStockBasicDetails,
        allSites,
        allAttributeTypes,
        allCategoryTypes
    }
}

export function loading () {
    return {
        type: "LOADING" as "LOADING"
    }
}


type CustomerActionsCreators =  typeof allCustomerLoaded | 
                                typeof infoInfrastructureLoaded | 
                                typeof packagesWithRightUsageLoaded | 
                                typeof purchasePackagesDetailsLoaded | 
                                typeof purchaseProductsDetailsLoaded |
                                typeof targetProductReleased |
                                typeof discountOwnBySelectedCustomerLoaded|
                                typeof newPurchaseSubmittedSuccessfully|
                                typeof packagesOwnByCustomerLoaded |
                                typeof periodPackageActivated |
                                typeof purchaseTermLibraryLoaded |
                                typeof discountAddedToCustomer|
                                typeof discountDelFromCustomer |
                                typeof relatedGuardianDetailsLoaded |
                                typeof relatedGuardianDetailsReset |
                                typeof relatedGuardianDetailsCreated |
                                typeof relatedGuardianDetailsUpdated |
                                typeof relatedGuardianDetailsDeleted |
                                typeof customerGroupingIDUpdated |
                                typeof customerPackageUpdatedManually |
                                typeof markReferralDetailsAfterwardsSuccess |
                                typeof newPurchaseSubmittedWithNoTokenBookingNeedsToBeHandled |
                                typeof noTokenBookingProcessed |
                                typeof exitFromProcessNoTokenBookingWindow |
                                typeof allProductStorageSummaryLoaded |
                                typeof loading;

export type ICustomerActions = ReturnType<CustomerActionsCreators>;