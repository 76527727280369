import React from 'react';
import { Button, TextField, InputAdornment, Input, FilledInput, OutlinedInput } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { IRootState, ThunkDispatch } from '../../store';
import { connect } from 'react-redux';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import { addStockSKUUniqueCheck } from './thunks';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export interface IAddStockBoxProps {
    handleExit: () => void,
    confirmAddNewStockToInventory: (newStaffDetails: any) => void,
    processUniqueSKUCheck: (SKU: string) => void,
    finishing: boolean,
    allSites: any[]
    allAttributeTypes: any[],
    allCategoryTypes: any[],
    addStockSKUUniqueCheck: any,
    selectedProductBasicDetails: any,
    language: string
    // employmentStatusList: any[]
}
export interface IAddStockBoxState {
    createInventoryRecordSteps: any[],
    activeStep: number,
    stockDetails: stockDetails,
    addNewProductAttribute: boolean
    newAttribute: any,
    SKU1: string,
    SKU2: string,
    SKU3: string,
    SKU4: string,
}

export interface stockDetails {
    productName: string,
    storeSiteID: number,
    stockCount: number,
    attributeDataArray: any[],
    SKU: string,
    cost: number,
    markedPrice: number,
    productCategoryID: number
}

class AddStockBox extends React.Component<IAddStockBoxProps, IAddStockBoxState> {
    constructor(props: IAddStockBoxProps) {
        super(props);
        this.state = {
            createInventoryRecordSteps: (this.props.language !== "eng"?['輸入相關產品資料', '輸入相關倉務資料', '核對所有資料', '正更新倉務紀錄', '{this.props.language !== "eng" ? "完成倉務操作" : "Finish Inventory Update"}'] : ['Stock Details', 'Inventory Details', 'Confirm Details', 'Updating Inventory', 'Finish']),
            activeStep: 0,
            stockDetails: {
                productName: '',
                storeSiteID: 0,
                stockCount: 0,
                attributeDataArray: [],
                SKU: "",
                cost: 0,
                markedPrice: 0,
                productCategoryID: 0
            },
            addNewProductAttribute: false,
            newAttribute: { "attribute_id": 0, "value": "" },
            SKU1: "",
            SKU2: "",
            SKU3: "",
            SKU4: "",

        }
    }
    private handleDeclineCreateNewStaff = async () => {
        let confirmation = window.confirm((this.props.language !== "eng" ?'你確定要取消新增相關倉務資料嗎？' : "Confirm Abandoning the New Inventory Update ?"));
        if (confirmation) {
            await this.props.handleExit();
        }
    }

    private handleNextStepClick = async () => {
        await this.setState({
            activeStep: this.state.activeStep + 1
        })
    }

    private handleLastStepClick = async () => {
        await this.setState({
            activeStep: this.state.activeStep - 1
        })
    }

    private handleNewStockDetailsInput = (field: string) => async (event: any) => {

        await this.setState({
            stockDetails: {
                ...this.state.stockDetails,
                [field]: event.target.value
            }
        })
        return;

    }

    private handleConfirmAddClick = async () => {
        this.props.confirmAddNewStockToInventory(this.state.stockDetails);
        await this.setState({
            activeStep: this.state.activeStep + 1
        })
        // if (newStockDetails.edit_days === 0 || newStockDetails.edit_remarks === '' || newStockDetails.edit_range_site_id === -1) {
        //     window.alert('請填寫所需資料')
        // } else {
        //     await this.props.confirmAddNewMassEdit(newStockDetails);
        //     await this.setState({
        //         activeStep: 1
        //     })
        // }
    }

    private handleAddNewProductAttributeTrigger = async () => {
        await this.setState({
            addNewProductAttribute: !this.state.addNewProductAttribute
        })
    }
    private handleNewProductAttributeAddClick = async () => {
        let newAttributeDataArray = this.state.stockDetails.attributeDataArray;
        newAttributeDataArray.push(this.state.newAttribute);
        await this.setState({
            stockDetails: {
                ...this.state.stockDetails,
                attributeDataArray: newAttributeDataArray
            },
            addNewProductAttribute: false,
            newAttribute: { "attribute_id": 0, "value": "" }
        })
    }

    private handleDelProductAttribute = async (attributeID: number) => {
        await this.setState({
            stockDetails: {
                ...this.state.stockDetails,
                attributeDataArray: this.state.stockDetails.attributeDataArray.filter((attribute: any) => attribute.id !== attributeID)
            }
        })
    }

    private enterProductAttribute = (field: string) => async (event: any) => {
        await this.setState({
            newAttribute: {
                ...this.state.newAttribute,
                [field]: event.target.value
            }
        })

    }

    private enterProductSKU = (field: string) => async (event: any) => {
        if (field === "SKU1" && event.target.value.split("").length <= 3) {
            await this.setState({
                SKU1: event.target.value.toUpperCase()
            })
        }
        if (field === "SKU2" && event.target.value.split("").length <= 3) {
            await this.setState({
                SKU2: event.target.value.toUpperCase()
            })
        }
        if (field === "SKU3" && event.target.value.split("").length <= 4) {
            await this.setState({
                SKU3: event.target.value.toUpperCase()
            })
        }
        if (field === "SKU4") {
            await this.setState({
                SKU4: event.target.value.toUpperCase()
            })
        }
        let newSKU = `${this.state.SKU1}-${this.state.SKU2}-${this.state.SKU3}-${this.state.SKU4}`
        if (this.state.SKU4 === "") {
            newSKU = `${this.state.SKU1}-${this.state.SKU2}-${this.state.SKU3}`
        }
        await this.setState({
            stockDetails: {
                ...this.state.stockDetails,
                SKU: newSKU
            }
        })
    }

    async componentDidUpdate() {
        if (this.state.activeStep === 3 && this.props.finishing) {
            setTimeout(async () => await this.setState({
                activeStep: 4
            }), 2000);
        }
        if (this.state.SKU1 !== "" && this.state.SKU2 !== "" && this.state.SKU3 !== "" && this.state.stockDetails.SKU !== this.props.addStockSKUUniqueCheck.SKU) {
            this.props.processUniqueSKUCheck(this.state.stockDetails.SKU)
        }
    }

    async componentWillMount() {
        if (this.props.selectedProductBasicDetails !== "") {
            let SKUSeparation = this.props.selectedProductBasicDetails.sku.split("-");
            await this.setState({
                stockDetails: {
                    productName: this.props.selectedProductBasicDetails.product_name,
                    storeSiteID: 0,
                    stockCount: 0,
                    attributeDataArray: this.props.selectedProductBasicDetails.productAttributes,
                    SKU: this.props.selectedProductBasicDetails.sku,
                    cost: 0,
                    markedPrice: this.props.selectedProductBasicDetails.marked_price,
                    productCategoryID: this.props.selectedProductBasicDetails.productCategoryID
                }
            })
            for (let i = 0; i < SKUSeparation.length; i++) {
                if (i === 0) {
                    await this.setState({
                        SKU1: SKUSeparation[i]
                    })
                }
                if (i === 1) {
                    await this.setState({
                        SKU2: SKUSeparation[i]
                    })
                }
                if (i === 2) {
                    await this.setState({
                        SKU3: SKUSeparation[i]
                    })
                }
                if (i === 3) {
                    await this.setState({
                        SKU4: SKUSeparation[i]
                    })
                }
            }
        }
    }

    render() {
        return (
            <div className="createStaffArea">
                <div className="staffListingAreaTitleRow">
                    <div className="staffListingAreaTitle">{this.props.language !== "eng" ? "新增相關倉務資料" : "Create New Inventory Update "} </div>
                    {
                        this.state.activeStep === 0 ?
                            <Button id="cxlCreateStaffBtn" startIcon={<CloseIcon />} onClick={this.handleDeclineCreateNewStaff}> {this.props.language !== "eng" ? "取消新增" : "Abandon Inventory Update "} </Button>
                            : null
                    }
                </div>
                <div className="stepsIndicatorRow">
                    <Stepper id="stepIconColor" activeStep={this.state.activeStep} alternativeLabel>
                        {this.state.createInventoryRecordSteps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                <div className="fixedHeightWindowOnCreateNewStockToInventory">
                    <div className="mainInputRow">
                        {
                            this.state.activeStep === 0 ?
                                //FIRST STEP
                                <div className="tokenContentInputContainer">
                                    <div className="inputRowOnAddPackageComponent">
                                        <div className="tokenNameLabel">*{this.props.language !== "eng" ? "產品名稱" : "Product"} :</div>
                                        {
                                            this.props.selectedProductBasicDetails === "" ?
                                                <TextField className="packageNameInput" variant="outlined" value={this.state.stockDetails.productName} onChange={this.handleNewStockDetailsInput('productName')} />
                                                :
                                                <TextField className="packageNameInput" disabled variant="outlined" value={this.state.stockDetails.productName} onChange={this.handleNewStockDetailsInput('productName')} />
                                        }
                                        {
                                            this.state.stockDetails.productName !== "" ?
                                                <CheckCircleIcon id="newProductFormGoodIndicator" />
                                                :
                                                <CheckCircleIcon id="newProductFormBadIndicator" />
                                        }
                                    </div>
                                    <div className="inputRowOnAddPackageComponent">
                                        <div className="tokenNameLabel">*{this.props.language !== "eng" ? "產品SKU" : "Product SKU"} :</div>
                                        {
                                            this.props.selectedProductBasicDetails === "" ?
                                                <TextField className="packageNameInput03" variant="outlined" value={this.state.SKU1} onChange={this.enterProductSKU('SKU1')} helperText={this.props.language !== "eng" ? `3(產品 + 性別)` : `3(Product + SEX)`} />
                                                :
                                                <TextField className="packageNameInput03" disabled variant="outlined" value={this.state.SKU1} onChange={this.enterProductSKU('SKU1')} helperText={this.props.language !== "eng" ? `3(產品 + 性別)` : `3(Product + SEX)`} />
                                        }
                                        <div className="SKUDivider">-</div>
                                        {
                                            this.props.selectedProductBasicDetails === "" ?
                                                <TextField className="packageNameInput03" variant="outlined" value={this.state.SKU2} onChange={this.enterProductSKU('SKU2')} helperText={this.props.language !== "eng" ? `3(品牌簡稱)` : `3(Brand)`} />
                                                :
                                                <TextField className="packageNameInput03" disabled variant="outlined" value={this.state.SKU2} onChange={this.enterProductSKU('SKU2')} helperText={this.props.language !== "eng" ? `3(品牌簡稱)` : `3(Brand)`} />
                                        }
                                        <div className="SKUDivider">-</div>
                                        {
                                            this.props.selectedProductBasicDetails === "" ?
                                                <TextField className="packageNameInput03" variant="outlined" value={this.state.SKU3} onChange={this.enterProductSKU('SKU3')} helperText={this.props.language !== "eng" ? `3(顏色 + 尺碼)` : `3(Colour + Size)`} />
                                                :
                                                <TextField className="packageNameInput03" disabled variant="outlined" value={this.state.SKU3} onChange={this.enterProductSKU('SKU3')} helperText={this.props.language !== "eng" ? `3(顏色 + 尺碼)` : `3(Colour + Size)`} />
                                        }
                                        <div className="SKUDivider">-</div>
                                        {
                                            this.props.selectedProductBasicDetails === "" ?
                                                <TextField className="packageNameInput03" variant="outlined" value={this.state.SKU4} onChange={this.enterProductSKU('SKU4')} helperText={this.props.language !== "eng" ? `其他識別碼` : `Optional`} />
                                                :
                                                <TextField className="packageNameInput03" disabled variant="outlined" value={this.state.SKU4} onChange={this.enterProductSKU('SKU4')} helperText={this.props.language !== "eng" ? `其他識別碼` : `Optional`} />
                                        }
                                        {
                                            this.props.selectedProductBasicDetails === "" ?
                                                this.state.stockDetails.SKU !== "" && this.state.stockDetails.SKU === this.props.addStockSKUUniqueCheck.SKU && this.props.addStockSKUUniqueCheck.unique ?
                                                    <CheckCircleIcon id="newProductFormGoodIndicator" />
                                                    :
                                                    <CheckCircleIcon id="newProductFormBadIndicator" />
                                                :
                                                <CheckCircleIcon id="newProductFormGoodIndicator" />
                                        }
                                    </div>
                                    <div className="inputRowOnAddPackageComponent">
                                        <div className="tokenNameLabel">*{this.props.language !== "eng" ? "產品種類" : "Product Cat."} :</div>
                                        <FormControl variant="outlined" className="packageNameInput">
                                            {this.props.selectedProductBasicDetails === "" ?
                                                <Select

                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={this.state.stockDetails.productCategoryID}
                                                    onChange={this.handleNewStockDetailsInput('productCategoryID')}
                                                >
                                                    <MenuItem value={0}>{this.props.language !== "eng" ? "未選擇" : "Please Select"}</MenuItem>
                                                    {
                                                        this.props.allCategoryTypes.map((category: any) =>
                                                            <MenuItem value={category.id}>{category.category}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                                :
                                                <Select
                                                    disabled
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={this.state.stockDetails.productCategoryID}
                                                    onChange={this.handleNewStockDetailsInput('productCategoryID')}
                                                >
                                                    <MenuItem value={0}>{this.props.language !== "eng" ? "未選擇" : "Please Select"}</MenuItem>
                                                    {
                                                        this.props.allCategoryTypes.map((category: any) =>
                                                            <MenuItem value={category.id}>{category.category}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            }
                                        </FormControl>
                                        {
                                            this.state.stockDetails.productCategoryID !== 0 ?
                                                <CheckCircleIcon id="newProductFormGoodIndicator" />
                                                :
                                                <CheckCircleIcon id="newProductFormBadIndicator" />
                                        }
                                    </div>

                                    {
                                        <div className="inputRowOnAddPackageComponent">
                                            <div className="tokenNameLabel">*{this.props.language !== "eng" ? "產品特性" : "Stock Attribute"} :</div>
                                            {
                                                this.state.stockDetails.attributeDataArray.length === 0 ?
                                                    <div className="genreListingRow">
                                                        <div className="suitableGenreDisplay">
                                                            <div className="genreContainer">
                                                                <AllInclusiveIcon /><div className="genreName">{this.props.language !== "eng" ? "暫未有特性" : "No Attribute"}</div>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.addNewProductAttribute ?
                                                                <div className="suitableGenreDisplay" onClick={this.handleAddNewProductAttributeTrigger}>
                                                                    <div className="genreContainer" >
                                                                        <CloseIcon /><div className="genreName">{this.props.language !== "eng" ? "取消新增" : "Abandon Inventory Update "}</div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="suitableGenreDisplay" onClick={this.handleAddNewProductAttributeTrigger}>
                                                                    <div className="genreContainer" >
                                                                        <AddIcon /><div className="genreName">{ this.props.language !== "eng" ? "新增" : "Add" }</div>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                    :
                                                    <div className="genreListingRow">
                                                        {
                                                            this.state.stockDetails.attributeDataArray.map((productAttribute: any) =>
                                                                <div className="suitableGenreDisplay" onClick={() => this.handleDelProductAttribute(productAttribute.attribute_id)}>
                                                                    <div className="genreContainer">
                                                                        <ClearIcon /><div className="genreName">{productAttribute.attribute_id === 0 ? " - -" : (this.props.allAttributeTypes.find((attribute: any) => attribute.id === productAttribute.attribute_id)).attribute + ":"} {productAttribute.value}</div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            this.props.selectedProductBasicDetails === "" ?
                                                                this.state.addNewProductAttribute ?
                                                                    <div className="suitableGenreDisplay" onClick={this.handleAddNewProductAttributeTrigger}>
                                                                        <div className="genreContainer" >
                                                                            <CloseIcon /><div className="genreName">{this.props.language !== "eng" ? "取消新增" : "Abandon Inventory Update "}</div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="suitableGenreDisplay" onClick={this.handleAddNewProductAttributeTrigger}>
                                                                        <div className="genreContainer" >
                                                                            <AddIcon /><div className="genreName">{this.props.language !== "eng" ? "新增" : "Add"}</div>
                                                                        </div>
                                                                    </div>
                                                                : null
                                                        }
                                                    </div>
                                            }
                                            {
                                                this.state.stockDetails.attributeDataArray.length !== 0 ?
                                                    <CheckCircleIcon id="newProductFormGoodIndicator" />
                                                    :
                                                    <CheckCircleIcon id="newProductFormBadIndicator" />
                                            }

                                        </div>
                                    }
                                    {
                                        !this.state.addNewProductAttribute ? null :
                                            <div className="inputRowOnAddPackageComponent">
                                                <div className="tokenNameLabel">*{this.props.language !== "eng" ? "填入特性" : "Fill Attribute"} :</div>
                                                <FormControl variant="outlined" className="packageNameInput02">
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={this.state.newAttribute.attribute_id}
                                                        onChange={this.enterProductAttribute('attribute_id')}
                                                    >
                                                        <MenuItem value={0}>{this.props.language !== "eng" ? "未選擇" : "Please Select"}</MenuItem>
                                                        {
                                                            this.props.allAttributeTypes.map((attribute: any) =>
                                                                this.state.stockDetails.attributeDataArray.find((productAttribute: any) => productAttribute.id === attribute.id) ? null :
                                                                    <MenuItem value={attribute.id}>{attribute.attribute} :</MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>
                                                <TextField className="packageNameInput02" variant="outlined" value={this.state.newAttribute.value} onChange={this.enterProductAttribute('value')} />
                                                {
                                                    this.state.newAttribute.attribute_id === 0 || this.state.newAttribute.value === "" ?
                                                        <Button id="completeInputAttributeBtnDeactivated" >{this.props.language !== "eng" ? "完成此特性" : "Add Attribute"}</Button>
                                                        :
                                                        <Button id="completeInputAttributeBtn" onClick={this.handleNewProductAttributeAddClick}>{this.props.language !== "eng" ? "完成此特性" : "Add Attribute"}</Button>

                                                }
                                                <CheckCircleIcon id="newProductFormBadIndicator" />


                                            </div>

                                    }

                                </div>
                                // null
                                :
                                this.state.activeStep === 1 ?
                                    <div className="tokenContentInputContainer">
                                        <div className="inputRowOnAddPackageComponent">
                                            <div className="tokenNameLabel">*{this.props.language !== "eng" ? "入倉數量" : "Intake Amount "} :</div>
                                            <TextField className="packageNameInput" type="number" variant="outlined" value={this.state.stockDetails.stockCount} onChange={this.handleNewStockDetailsInput('stockCount')} />
                                        </div>
                                        <div className="inputRowOnAddPackageComponent">
                                            <div className="tokenNameLabel">*{this.props.language !== "eng" ? "入貨成本" : "Cost"} :</div>
                                            <OutlinedInput className="packageNameInput" type="number" value={this.state.stockDetails.cost} onChange={this.handleNewStockDetailsInput('cost')} startAdornment={<InputAdornment position="start">HK$</InputAdornment>} />
                                        </div>
                                        <div className="inputRowOnAddPackageComponent">
                                            <div className="tokenNameLabel">*{this.props.language !== "eng" ? "預定售價" : "Price"} :</div>
                                            <OutlinedInput className="packageNameInput" type="number" value={this.state.stockDetails.markedPrice} onChange={this.handleNewStockDetailsInput('markedPrice')} startAdornment={<InputAdornment position="start">HK$</InputAdornment>} />
                                        </div>
                                        <div className="inputRowOnAddPackageComponent">
                                            <div className="tokenNameLabel">*{this.props.language !== "eng" ? "存倉分校" : "Storage Site"} :</div>
                                            <FormControl variant="outlined" className="packageNameInput">
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={this.state.stockDetails.storeSiteID}
                                                    onChange={this.handleNewStockDetailsInput('storeSiteID')}
                                                >
                                                    <MenuItem value={0}>{this.props.language !== "eng" ? "未選擇" : "Please Select"}</MenuItem>
                                                    {
                                                        this.props.allSites.map((site: any) =>
                                                            <MenuItem value={site.id}>{site.site_key}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    :
                                    this.state.activeStep === 2 ?
                                        <div className="tokenContentInputContainer">
                                            <div className="inputRowOnAddPackageComponent">
                                                <div className="tokenNameLabel">*{this.props.language !== "eng" ? "產品名稱" : "Product"} :</div>
                                                <TextField className="packageNameInput" variant="outlined" value={this.state.stockDetails.productName} onChange={this.handleNewStockDetailsInput('productName')} disabled />
                                            </div>
                                            <div className="inputRowOnAddPackageComponent">
                                                <div className="tokenNameLabel">*{this.props.language !== "eng" ? "產品SKU" : "Product SKU"} :</div>
                                                <TextField className="packageNameInput03" variant="outlined" value={this.state.SKU1} onChange={this.enterProductSKU('SKU1')} helperText="3(產品 + 性別)" disabled />
                                                <div className="SKUDivider">-</div>
                                                <TextField className="packageNameInput03" variant="outlined" value={this.state.SKU2} onChange={this.enterProductSKU('SKU2')} helperText="3(品牌簡稱)" disabled />
                                                <div className="SKUDivider">-</div>
                                                <TextField className="packageNameInput03" variant="outlined" value={this.state.SKU3} onChange={this.enterProductSKU('SKU3')} helperText="3(顏色 + 尺碼)" disabled />
                                                <div className="SKUDivider">-</div>
                                                <TextField className="packageNameInput03" variant="outlined" value={this.state.SKU4} onChange={this.enterProductSKU('SKU4')} helperText="其他識別碼" disabled />
                                            </div>
                                            <div className="inputRowOnAddPackageComponent">
                                                <div className="tokenNameLabel">*{this.props.language !== "eng" ? "產品種類" : "Product Cat."} :</div>
                                                <FormControl variant="outlined" className="packageNameInput">

                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={this.state.stockDetails.productCategoryID}
                                                        onChange={this.handleNewStockDetailsInput('productCategoryID')}
                                                        disabled
                                                    >
                                                        <MenuItem value={0}>{this.props.language !== "eng" ? "未選擇" : "Please Select"}</MenuItem>
                                                        {
                                                            this.props.allCategoryTypes.map((category: any) =>
                                                                <MenuItem value={category.id}>{category.category}</MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>

                                            {
                                                <div className="inputRowOnAddPackageComponent">
                                                    <div className="tokenNameLabel">*{this.props.language !== "eng" ? "產品特性" : "Stock Attribute"} :</div>
                                                    {
                                                        <div className="genreListingRow">
                                                            {
                                                                this.state.stockDetails.attributeDataArray.map((productAttribute: any) =>
                                                                    <div className="suitableProductAttributeDisplay02" onClick={() => this.handleDelProductAttribute(productAttribute.id)}>
                                                                        <div className="genreContainer">
                                                                            {productAttribute.attribute_id === 0 ? " - -" : (this.props.allAttributeTypes.find((attribute: any) => attribute.id === productAttribute.attribute_id)).attribute + ":"} {productAttribute.value}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            <div className="inputRowOnAddPackageComponent">
                                                <div className="tokenNameLabel">*{this.props.language !== "eng" ? "入倉數量" : "Intake Amount "} :</div>
                                                <TextField className="packageNameInput" type="number" variant="outlined" value={this.state.stockDetails.stockCount} onChange={this.handleNewStockDetailsInput('stockCount')} disabled />
                                            </div>
                                            <div className="inputRowOnAddPackageComponent">
                                                <div className="tokenNameLabel">*{this.props.language !== "eng" ? "入貨成本" : "Cost"} :</div>
                                                <OutlinedInput className="packageNameInput" type="number" value={this.state.stockDetails.cost} onChange={this.handleNewStockDetailsInput('cost')} startAdornment={<InputAdornment position="start">HK$</InputAdornment>} disabled />
                                            </div>
                                            <div className="inputRowOnAddPackageComponent">
                                                <div className="tokenNameLabel">*{this.props.language !== "eng" ? "預定售價" : "Price"} :</div>
                                                <OutlinedInput className="packageNameInput" type="number" value={this.state.stockDetails.markedPrice} onChange={this.handleNewStockDetailsInput('markedPrice')} startAdornment={<InputAdornment position="start">HK$</InputAdornment>} disabled />
                                            </div>
                                            <div className="inputRowOnAddPackageComponent">
                                                <div className="tokenNameLabel">*{this.props.language !== "eng" ? "存倉分校" : "Storage Site"} :</div>
                                                <FormControl variant="outlined" className="packageNameInput">
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={this.state.stockDetails.storeSiteID}
                                                        onChange={this.handleNewStockDetailsInput('storeSiteID')}
                                                        disabled
                                                    >
                                                        <MenuItem value={0}>{this.props.language !== "eng" ? "未選擇" : "Please Select"}</MenuItem>
                                                        {
                                                            this.props.allSites.map((site: any) =>
                                                                <MenuItem value={site.id}>{site.site_key}</MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        :
                                        this.state.activeStep === 3 ?
                                            <div className="loadingContainerRow">
                                                <div className="inlineDisplay">
                                                    <div className="loadingContainer">
                                                        <CircularProgress id="loadingCircle" />
                                                        <div className="loadingLabel">Loading ...</div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            this.state.activeStep === 4 ?
                                                <div className="loadingContainerRow">
                                                    <div className="inlineDisplay">
                                                        <div className="loadingContainer">
                                                            <DoneIcon />
                                                            <div className="loadingLabel">{this.props.language !== "eng" ? "已完成倉務操作" : "Inventory Updated"}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                : null
                        }
                    </div>
                </div>

                <div className="modifyStepsRowOnCreateMassTokenEdit">
                    {this.state.activeStep === 1 || this.state.activeStep === 2 ? <Button id="stepButtonOnCreateMassTokenEdit" onClick={this.handleLastStepClick}>{this.props.language !== "eng" ? "上一步" : "Back"}</Button> : <Button id="stepButtonDisabled" disabled>{this.props.language !== "eng" ? "上一步" : "Back"}</Button>}
                    {this.state.activeStep === 0 && this.props.selectedProductBasicDetails === "" ?
                        this.state.stockDetails.productName !== "" && this.state.SKU1 !== "" && this.state.SKU2 !== "" && this.state.SKU3 !== "" && this.state.stockDetails.productCategoryID !== 0 && this.state.stockDetails.attributeDataArray.length !== 0 && this.state.stockDetails.SKU === this.props.addStockSKUUniqueCheck.SKU && this.props.addStockSKUUniqueCheck.unique ?
                            <Button id="stepButtonOnCreateMassTokenEdit" onClick={this.handleNextStepClick}>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>
                            :
                            <Button id="stepButtonDisabled" onClick={this.handleNextStepClick} disabled>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>
                        :
                        this.state.activeStep === 0 ?
                            <Button id="stepButtonOnCreateMassTokenEdit" onClick={this.handleNextStepClick}>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>
                            :
                            this.state.activeStep === 1 && this.state.stockDetails.stockCount !== 0 && this.state.stockDetails.storeSiteID !== 0 ?
                                <Button id="stepButtonOnCreateMassTokenEdit" onClick={this.handleNextStepClick} >{this.props.language !== "eng" ? "下一步" : "Next"}</Button>
                                :
                                this.state.activeStep === 2 ?
                                    <Button id="stepButtonOnCreateMassTokenEdit" onClick={this.handleConfirmAddClick} >{this.props.language !== "eng" ? "確認資料無誤" : "Confirm Details"}</Button>
                                    :

                                    this.state.activeStep === 4 ?
                                        <Button id="stepButtonOnCreateMassTokenEdit" onClick={this.props.handleExit}>{this.props.language !== "eng" ? "完成倉務操作" : "Finish Inventory Update"}</Button>
                                        :
                                        <Button id="stepButtonDisabled" onClick={this.handleNextStepClick} disabled>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>


                    }
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        allSites: state.stockManageMain.allSites,
        allAttributeTypes: state.stockManageMain.allAttributeTypes,
        allCategoryTypes: state.stockManageMain.allCategoryTypes,
        addStockSKUUniqueCheck: state.stockManageMain.addStockSKUUniqueCheck,
        language: state.auth.language
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        processUniqueSKUCheck: async (SKU: string) => dispatch(await addStockSKUUniqueCheck(SKU))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddStockBox);