import {IStockManageMainState} from './state';
import {IStockManageMainActions} from './actions'

const initialState = {
    uniqueStockBasicDetails: [],
    loading: true,
    finishingCreateNewInventoryRecord: false,
    allSites:[],
    allAttributeTypes: [],
    allCategoryTypes: [],
    addStockSKUUniqueCheck: {"sku":"","unique":true}
}

export const stockManageMainReducer = (state: IStockManageMainState = initialState, actions: IStockManageMainActions) => {
    switch (actions.type) {

        case "ALL_UNIQUE_STOCK_BASIC_DETAILS_LOADED":
            return {
                ...state,
                loading: false,
                uniqueStockBasicDetails: actions.uniqueStockBasicDetails,
                allSites: actions.allSites,
                allAttributeTypes: actions.allAttributeTypes,
                allCategoryTypes: actions.allCategoryTypes
            }
        case "NEW_INVENTORY_RECORD_CREATED":
            return {
                ...state,
                loading: false,
                finishingCreateNewInventoryRecord: true
            }
        case "LOADING":
            return {
                ...state,
                loading: true
            }
        case "ADD_STOCK_SKU_UNIQUE_CHECKED":
            return {
                ...state,
                addStockSKUUniqueCheck: actions.uniqueCheckResult
            }
        default: 
        return state;
    }
}