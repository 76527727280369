
import React from "react";
import { IRootState, ThunkDispatch } from "../../store";
import { connect } from "react-redux";
import { loadCustomerPurchasePaymentHistory, recordNewPayment } from './thunks'
import './CustomerPurchasePaymentHistory.css'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PaymentIcon from '@material-ui/icons/Payment';
import RedeemIcon from '@material-ui/icons/Redeem';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';


import { Button, TextField } from "@material-ui/core";

import moment from "moment";

export interface IAddNewPaymentProps {
    language:string,
    customerUserID: number,
    customerPurchasesList: any[],
    customerPayments: any[],
    // staffId: number,
    selectedPurchaseID: number,
    loadCustomerPurchasePaymentHistory: (customerUserID: number) => void,
    recordNewPayment: (staffID:number,customerUserID:number,purchaseID:number,paymentData:any) => void,
    handleExit : () => void
}

export interface IAddNewPaymentState {
    showPaymentBox: boolean,
    selectedPurchaseID: number,
    // usingDiscountForPurchase: number,
    paymentMethodChosen: string,
    purchasePaymentSituation: string,
    amountReceived: number,
    paymentRemarks: string,

}

class AddNewPayment extends React.Component<IAddNewPaymentProps, IAddNewPaymentState>{
    constructor(props: IAddNewPaymentProps) {
        super(props);
        this.state = {
            showPaymentBox: false,
            selectedPurchaseID: 0,
            // usingDiscountForPurchase : 0,
            paymentMethodChosen: "Cash",
            purchasePaymentSituation: "Partly",
            amountReceived: 0,
            paymentRemarks: '-'

        }
    }
    private handleSelectPurchaseRecord = async (purchaseID: number) => {
        if (this.state.selectedPurchaseID === purchaseID) {
            await this.setState({
                selectedPurchaseID: 0
            })
        } else {
            await this.setState({
                selectedPurchaseID: purchaseID
            })
        }
    }
    private handlePaymentMethodSelection = async (paymentMethod: string) => {
        await this.setState({
            paymentMethodChosen: paymentMethod
        })
    }
    private handlePaymentStatusSelection = async (paymentStatus: string) => {
        if (paymentStatus === 'Fully') {
            let previousPayments = this.props.customerPayments.filter((payment: any) => payment.purchase_id === this.props.selectedPurchaseID);
            let previousPaymentList = previousPayments.filter((payment:any) => !payment.void);
            let paymentPaid = 0
            for (let payment of previousPaymentList) {
                paymentPaid += parseFloat(payment.amount_received);
            }
            let purchase = (this.props.customerPurchasesList.filter((purchase: any) => purchase.id === this.props.selectedPurchaseID))[0]
            console.log(purchase)
            let amountReceived = parseFloat(purchase.amount_payable) - paymentPaid
            console.log(parseFloat(purchase.amount_payable))
            console.log(paymentPaid)
            await this.setState({
                amountReceived: parseFloat(purchase.amount_payable) - paymentPaid
            })
        } else if (paymentStatus === 'Partly') {
            await this.setState({
                amountReceived: 0
            })
        } else {
            await this.setState({
                amountReceived: 0
            })
        }

        await this.setState({
            purchasePaymentSituation: paymentStatus,
        })
    }

    private handlePaymentAmountInput = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let re = /^[0-9\b]+$/;
        if (event.currentTarget.value === '') {
            await this.setState({
                amountReceived: 0
            })
        } else if (re.test(event.currentTarget.value)) {
            await this.setState({
                amountReceived: parseInt(event.currentTarget.value)
            })
            let previousPaymentList = this.props.customerPayments.filter((payment: any) => payment.purchase_id === this.props.selectedPurchaseID);
            let paymentPaid = 0
            for (let payment of previousPaymentList) {
                paymentPaid += parseFloat(payment.amountReceived);
            }

            let paymentLeft = parseFloat((this.props.customerPurchasesList.filter((purchase: any) => purchase.id === this.props.selectedPurchaseID))[0]) - paymentPaid - this.state.amountReceived;

            if (paymentLeft === 0) {
                await this.setState({
                    purchasePaymentSituation: 'Fully',

                })
            }

        } else {
            alert('Please Insert Numbers.');
        }
    }
    private handleConfirmPurchaseClick = async () => {
        //[PRODUCT IS NOT INCLUDED]

        let paymentData = {
            paymentStatus: this.state.purchasePaymentSituation,
            paymentMethod: this.state.paymentMethodChosen,
            amountReceived: this.state.amountReceived,
            remarks: this.state.paymentRemarks
        }

        function parseJwt(token: string) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        };
        let clientToken = localStorage.getItem('token');
        let tokenPayload: any = {}
        if (clientToken) {
            tokenPayload = parseJwt(clientToken)
        }

        await this.props.recordNewPayment(tokenPayload.id,this.props.customerUserID,this.props.selectedPurchaseID,paymentData);
        this.props.handleExit();
    }

    async componentWillMount() {
        await this.props.loadCustomerPurchasePaymentHistory(this.props.customerUserID);
        if (this.props.customerPurchasesList.length !== 0) {
            await this.setState({
                selectedPurchaseID: this.props.customerPurchasesList[0].id
            })
        }
    }

    render() {
        let theSelectedPurchase = (this.props.customerPurchasesList.filter((purchase: any) => purchase.id === this.props.selectedPurchaseID))[0]
        let previousPayments = this.props.customerPayments.filter((payment: any) => payment.purchase_id === this.props.selectedPurchaseID);
        let previousPaymentForPurchase = previousPayments.filter((payment:any)=> !payment.void)
        let amountPaid = 0
        for(let payment of previousPaymentForPurchase){
            amountPaid += parseFloat(payment.amount_received);
        }
        return (
            
                    <div className="contentFixSizePackagesBlock">
                        <div className="blockTitleRow2">
                            <h3 className="blockTitle">{this.props.language !== "eng"? "顧客過往消費紀錄" : "Customer Purchase Histories"}</h3>
                        </div>
                        <div className="purchaseHistoryWindow">
                            <div className="col-12" id="mainContentBase">


                                <div className="row">
                                    <div className="col-12" id="customerNavPagesArea">
                                        <div className="row">
                                            <div className="col-7 contentBlock2">

                                                <div className="blockTitle2">{this.props.language !== "eng"? "選擇付款方法" : "Payment Method"}</div>
                                                <div className="blockTitle2Underline"></div>
                                                {this.state.paymentMethodChosen === 'Credit Card' ?

                                                    <div className="col-4 paymentMethodChosenBlock3 " onClick={() => this.handlePaymentMethodSelection('Credit Card')}>
                                                        <div className="paymentMethodContent">
                                                            <PaymentIcon id="paymentChosenIcon" fontSize={'large'} />
                                                            <div className="paymentChosenName" >
                                                                {this.props.language !== "eng"? "信用卡" : "Credit Card"}
                                                    </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-4 paymentMethodBlock3 " onClick={() => this.handlePaymentMethodSelection('Credit Card')}>
                                                        <div className="paymentMethodContent">
                                                            <PaymentIcon id="paymentIcon" fontSize={'large'} />
                                                            <div className="paymentName" >
                                                                {this.props.language !== "eng"? "信用卡" : "Credit Card"}
                                                    </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.paymentMethodChosen === 'EPS' ?

                                                    <div className="col-4 paymentMethodChosenBlock3 " onClick={() => this.handlePaymentMethodSelection('EPS')}>
                                                        <div className="paymentMethodContent">
                                                            <RedeemIcon id="paymentChosenIcon" fontSize={'large'} />

                                                            <div className="paymentChosenName" >
                                                                EPS
                                                        </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-4 paymentMethodBlock3 " onClick={() => this.handlePaymentMethodSelection('EPS')}>
                                                        <div className="paymentMethodContent">
                                                            <RedeemIcon id="paymentIcon" fontSize={'large'} />
                                                            <div className="paymentName" >
                                                                EPS
                                                        </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.paymentMethodChosen === 'Cash' ?

                                                    <div className="col-4 paymentMethodChosenBlock3 " onClick={() => this.handlePaymentMethodSelection('Cash')}>
                                                        <div className="paymentMethodContent">
                                                            <LocalAtmIcon id="paymentChosenIcon" fontSize={'large'} />
                                                            <div className="paymentChosenName" >
                                                                {this.props.language !== "eng"? "現金" : "CASH"}
                                                        </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-4 paymentMethodBlock3 " onClick={() => this.handlePaymentMethodSelection('Cash')}>
                                                        <div className="paymentMethodContent">
                                                            <LocalAtmIcon id="paymentIcon" fontSize={'large'} />
                                                            <div className="paymentName" >
                                                                {this.props.language !== "eng"? "現金" : "CASH"}
                                                        </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.paymentMethodChosen === 'Bank Transfer' ?

                                                    <div className="col-4 paymentMethodChosenBlock3 " onClick={() => this.handlePaymentMethodSelection('Bank Transfer')}>
                                                        <div className="paymentMethodContent">
                                                            <AccountBalanceIcon id="paymentChosenIcon" fontSize={'large'} />
                                                            <div className="paymentChosenName" >
                                                                {this.props.language !== "eng"? "銀行轉帳" : "Bank Transfer"}
                                                        </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-4 paymentMethodBlock3 " onClick={() => this.handlePaymentMethodSelection('Bank Transfer')}>
                                                        <div className="paymentMethodContent">
                                                            <AccountBalanceIcon id="paymentIcon" fontSize={'large'} />
                                                            <div className="paymentName" >
                                                                {this.props.language !== "eng"? "銀行轉帳" : "Bank Transfer"}
                                                        </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.paymentMethodChosen === 'Bank Deposit Slip' ?

                                                    <div className="col-4 paymentMethodChosenBlock3 " onClick={() => this.handlePaymentMethodSelection('Bank Deposit Slip')}>
                                                        <div className="paymentMethodContent">
                                                            <ReceiptIcon id="paymentChosenIcon" fontSize={'large'} />
                                                            <div className="paymentChosenName" >
                                                                {this.props.language !== "eng"? "入數" : "Deposit Slip"}
                                                        </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-4 paymentMethodBlock3 " onClick={() => this.handlePaymentMethodSelection('Bank Deposit Slip')}>
                                                        <div className="paymentMethodContent">
                                                            <ReceiptIcon id="paymentIcon" fontSize={'large'} />
                                                            <div className="paymentName" >
                                                                {this.props.language !== "eng"? "入數" : "Deposit Slip"}
                                                        </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.paymentMethodChosen === 'Other' ?

                                                    <div className="col-4 paymentMethodChosenBlock3 " onClick={() => this.handlePaymentMethodSelection('Other')}>
                                                        <div className="paymentMethodContent">
                                                            <HelpOutlineIcon id="paymentChosenIcon" fontSize={'large'} />
                                                            <div className="paymentChosenName" >
                                                                {this.props.language !== "eng"? "其他" : "Other"}
                                                        </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-4 paymentMethodBlock3 " onClick={() => this.handlePaymentMethodSelection('Other')}>
                                                        <div className="paymentMethodContent">
                                                            <HelpOutlineIcon id="paymentIcon" fontSize={'large'} />
                                                            <div className="paymentName" >
                                                                {this.props.language !== "eng"? "其他" : "Other"}
                                                        </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="blockTitle2">{this.props.language !== "eng"? "收款金額" : "Payment Amount"}</div>
                                                <div className="blockTitle2Underline"></div>
                                                {this.state.purchasePaymentSituation === 'Fully' ?

                                                    <div className="col-4 paymentMethodChosenBlock3 " >
                                                        <div className="paymentMethodContent">
                                                            <HelpOutlineIcon id="paymentChosenIcon" fontSize={'large'} />
                                                            <div className="paymentChosenName" >
                                                                {this.props.language !== "eng"? "全數" : "Full Pay"}
                                                        </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-4 paymentMethodBlock3 " onClick={() => this.handlePaymentStatusSelection('Fully')}>
                                                        <div className="paymentMethodContent">
                                                            <HelpOutlineIcon id="paymentIcon" fontSize={'large'} />
                                                            <div className="paymentName" >
                                                                {this.props.language !== "eng"? "全數" : "Full Pay"}
                                                        </div>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.purchasePaymentSituation === 'Partly' ?

                                                    <div className="col-4 paymentMethodChosenBlock3 " >
                                                        <div className="paymentMethodContent">
                                                            <HelpOutlineIcon id="paymentChosenIcon" fontSize={'large'} />
                                                            <div className="paymentChosenName" >
                                                                {this.props.language !== "eng"? "部份" : "Partly"}
                                                        </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-4 paymentMethodBlock3 " onClick={() => this.handlePaymentStatusSelection('Partly')}>
                                                        <div className="paymentMethodContent">
                                                            <HelpOutlineIcon id="paymentIcon" fontSize={'large'} />
                                                            <div className="paymentName" >
                                                                {this.props.language !== "eng"? "部份" : "Partly"}
                                                        </div>
                                                        </div>
                                                    </div>
                                                }
                                                

                                                {this.state.purchasePaymentSituation === 'Partly' ?
                                                    <div className="col-5 paymentAmountBlock">
                                                        <div className="paymentName">
                                                            {this.props.language !== "eng"? "收款金額" : "Payment Amount"} :
                                                    </div>
                                                        <TextField id="paymentAmount" variant="outlined" onChange={this.handlePaymentAmountInput} value={this.state.amountReceived} />
                                                    </div>
                                                    : null
                                                }




                                            </div>
                                            <div className="col-4 contentBlock2">
                                                <div className="blockTitle2">{this.props.language !== "eng"? "付款詳情" : "Payment Details"}</div>
                                                <div className="blockTitle2Underline"></div>
                                                {
                                                    <div className="purchaseSumUpItem">
                                                        <div className="purchaseSumUpItemName">
                                                            {this.props.language !== "eng"? "選購單" : "Purchase"} #{theSelectedPurchase.id}
                                                        </div>
                                                        <div className="purchaseSumUpItemPrice">
                                                            $ {theSelectedPurchase.amount_payable}
                                                        </div>
                                                    </div>
                                                }

                                                <div className="purchaseSumUpItem2">

                                                    <div className="purchaseSumUpItemName">
                                                        {this.props.language !== "eng"? "選購總值" : "Sub-Total"}:
                                                </div>
                                                    <div className="purchaseSumUpItemPrice">
                                                        $ {theSelectedPurchase.amount_payable}
                                                    </div>
                                                </div>

                                                <div className="purchaseUnderline"></div>


                                                {previousPaymentForPurchase.length === 0 ?
                                                    null :
                                                    previousPaymentForPurchase.map((payment: any) =>
                                                        <div className="purchaseSumUpItem2">

                                                            <div className="purchaseSumUpItemName">
                                                                {this.props.language !== "eng"? "付款單" : "Payment"} #{payment.id}
                                                        </div>
                                                            <div className="purchaseSumUpItemPrice3">
                                                                $ {payment.amount_received}
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                <div className="purchaseSumUpItem2">

                                                    <div className="purchaseSumUpItemName">
                                                        {this.props.language !== "eng"? "應繳尚欠款項" : "Payable Balance"}:
                                                </div>
                                                    <div className="purchaseSumUpItemPrice">
                                                        $ {parseFloat(theSelectedPurchase.amount_payable) - amountPaid}
                                                    </div>
                                                </div>

                                                <div className="purchaseSumUpItem2">

                                                    <div className="purchaseSumUpItemName">
                                                        {this.props.language !== "eng"? "已收款項" : "Paid Amount"}:
                                                </div>
                                                    <div className="purchaseSumUpItemPrice">
                                                        $ {this.state.amountReceived.toFixed(2)}
                                                    </div>
                                                </div>

                                                {parseFloat(theSelectedPurchase.amount_payable) - amountPaid - this.state.amountReceived === 0 ?
                                                null :
                                                    <div className="purchaseSumUpItem2">

                                                        <div className="purchaseSumUpItemName">
                                                            {this.props.language !== "eng"? "尚欠餘款" : "Outstanding Balance"}:
                                                </div>
                                                        <div className="purchaseSumUpItemPrice2">
                                                            ($ {parseFloat(theSelectedPurchase.amount_payable) - amountPaid - this.state.amountReceived})
                                                </div>
                                                    </div>
                                                }

                                                {parseFloat(theSelectedPurchase.amount_payable) - amountPaid - this.state.amountReceived < 0 ?
                                                    <Button id="confirmPaymentBtnDisabled" > - {this.props.language !== "eng"? "錯誤" : "Informative Error"} - </Button>
                                                    :
                                                    this.state.purchasePaymentSituation === 'Partly' && this.state.amountReceived === 0 ?
                                                        <Button id="confirmPaymentBtnDisabled" > - {this.props.language !== "eng"? "錯誤" : "Informative Error"} - </Button>
                                                        :
                                                        // <Button id="confirmPaymentBtn" onClick={this.handleConfirmPurchaseClick}>確認完成結帳 </Button>}
                                                        <Button id="confirmPaymentBtn" onClick={this.handleConfirmPurchaseClick}>{this.props.language !== "eng"? "新增付款紀錄" : "Create New Payment"}</Button>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                
        );
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        language: state.auth.language,
        genreTypesBank: state.tutors.genreTypeList,
        ageGroupsBank: state.tutors.ageGroupList,
        // staffId: state.auth.staffId,
        customerPurchasesList: state.customerPurchasePaymentHistory.customerPurchasesList,
        customerPayments: state.customerPurchasePaymentHistory.customerPayments,
        purchasedPackagesList: state.customerPurchasePaymentHistory.purchasedPackagesList
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        // handleSaveAdd: async (mainBody: any) => dispatch(await addThisTutorInfos(mainBody))
        // loadCustomerPurchasePaymentHistory: async (customerUserID: number) => dispatch(await loadCustomerPurchasePaymentHistory(customerUserID)),
        recordNewPayment: async(staffID:number,customerUserID:number,purchaseID:number,paymentData:any) => dispatch(await recordNewPayment(staffID,customerUserID,purchaseID,paymentData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewPayment);
// export default CustomerPurchasePaymentHistory;