import React from 'react';
import './App.css';
import Login from "./Login";
import { Provider } from 'react-redux'
import store ,{ history }from './store';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router';
import { PrivateRoute } from './PrivateRouter';
import NoMatch from './NoMatch';
import Layout from './Layout'
import StaffAcLoginSetUp from './StaffAcLoginSetUp/StaffAcLoginSetUp';


const App: React.FC = () => {
  // let timeNow = moment().format('YYYY-MM-DD HH:mm:ss');

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" exact={true} component={Login} />

          {/* privateRole={ {admin:true} */}
          <PrivateRoute path="/staffAcLoginSetUp" exact={true} component={StaffAcLoginSetUp} />
          <PrivateRoute path="/layout" component={Layout}  /> 

          <Route component={NoMatch} />
        </Switch>
       
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
