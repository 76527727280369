import React from "react";
import './AddNewRoomRentalWindow.css'
import { Button, FormControl, InputLabel, Select, MenuItem, TextField } from "@material-ui/core";
import { IRootState, ThunkDispatch } from "../../store";
import { connect } from "react-redux";
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { searchCustomerInfo, createNewRoomRentalDetails, loadAvailablePackagesForAddingNewPrivateClass } from './thunks';
import { resetAddNewBookingWindow } from './actions';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import moment from "moment";


export interface IAddNewRoomRentalWindowProps {
    // duplicatedLatestSchedulingGroup: any,
    language: string,
    tutorInfos: any,
    // ageGroups: any,
    // genres: any,
    classroomLists: any,
    openingHourLists: any,
    customerSearchingResult: any[],
    // roomOccupancyDetails: any,
    // occupancyParticipationDetails: any[],
    availablePackagesOfCustomer: any,
    // classOccupancyWithSameUSGID: any[],
    // occupancyTutorList: any[],
    occupancyOfTheDay: any[],
    dateShowing: string,
    classroomList: any[],
    handleCloseClick: () => void,
    handleResetClick: () => void,
    handleExitClick: () => void,
    // handleProceedWithUSG: () => void,
    // handleProceedWithNewUSG: () => void
    handleCustomerSearch: (searchMethod: string, searchInput: any) => void,
    handleCustomerClick: (customerID: number) => void,
    handleSubmitNewPrivateClassDetails: (customerID: number, siteID: number, classroomID: number, startTime: any, endTime: any, tokenPackageIDs: any[], bookingCount: number) => void

}

export interface IAddNewRoomRentalWindowState {
    searchingMethod: string,
    searchingInput: string,
    selectedCustomerID: number,
    selectedPackageID: number,
    selectedPackageDetails: any
    windowNav: string,
    repeatBooking: boolean,
    repeatBookOccupancyIDs: any,
    newClassStartingTimeList: any[],
    newClassRoomInput: number,
    newClassStartingTime: string,
    newClassTutor: number,
    bookingCount: number,
    repeatPreventer: boolean

}

class IAddNewRoomRentalWindow extends React.Component<IAddNewRoomRentalWindowProps, IAddNewRoomRentalWindowState> {
    constructor(props: IAddNewRoomRentalWindowProps) {
        super(props);
        this.state = {
            searchingMethod: 'customerID',
            searchingInput: '',
            selectedCustomerID: 0,
            selectedPackageID: 0,
            selectedPackageDetails: '',
            windowNav: 'searchPage',
            repeatBooking: false,
            repeatBookOccupancyIDs: [],
            newClassStartingTimeList: [],
            newClassRoomInput: 0,
            newClassStartingTime: '',
            newClassTutor: 0,
            bookingCount: 1,
            repeatPreventer: false
        }
    }


    private handleSearchingMethodChange = async (event: any) => {
        await this.setState({
            searchingMethod: event.target.value
        })
    }

    private handleSearchingInputChange = async (event: any) => {
        await this.setState({
            searchingInput: event.target.value
        })
    }

    private handleSearchClick = async () => {
        this.props.handleCustomerSearch(this.state.searchingMethod, this.state.searchingInput);
    }
    private handleSearchEnterPress = async (event: any) => {
        event.preventDefault();
        this.props.handleCustomerSearch(this.state.searchingMethod, this.state.searchingInput);

    }

    private handleSelectCustomerClick = async (customerID: number) => {
        await this.setState({
            selectedCustomerID: customerID,
            windowNav: 'availablePackageDisplayPage'
        })

        this.props.handleCustomerClick(this.state.selectedCustomerID)
    }

    private handleCloseClick = () => {
        this.props.handleCloseClick();
        this.props.handleResetClick();
    }

    private handleBackToSearchClick = async () => {
        await this.setState({
            windowNav: 'searchPage'
        })
    }

    private handlePackageSelectionClick = async (packageID: number, packageDetails: any) => {
        await this.setState({
            selectedPackageID: packageID,
            selectedPackageDetails: packageDetails
        })

        // this.props.handleLoadClassOccupanciesWithSameUSGID(this.props.roomOccupancyDetails.unique_scheduling_group_id, this.props.roomOccupancyDetails.end_time)
    }

    private handleDeselectPackageClick = async () => {
        await this.setState({
            selectedPackageID: 0,
            selectedPackageDetails: '',
            repeatBooking: false,
            repeatBookOccupancyIDs: []
        })
    }

    // private handleDecideRepeatBookingClick = async () => {
    //     await this.setState({
    //         repeatBooking: !this.state.repeatBooking,
    //         repeatBookOccupancyIDs: []
    //     })
    // }

    // private handleSelectOccupancyForRepeatBooking = async (occupancyID: number) => {
    //     let newRepeatBookingOccupancyIDs = this.state.repeatBookOccupancyIDs
    //     if (newRepeatBookingOccupancyIDs.includes(occupancyID)) {
    //         newRepeatBookingOccupancyIDs = newRepeatBookingOccupancyIDs.filter((id: number) => id !== occupancyID);
    //         await this.setState({
    //             repeatBookOccupancyIDs: newRepeatBookingOccupancyIDs
    //         })
    //     } else {
    //         newRepeatBookingOccupancyIDs.push(occupancyID)
    //         await this.setState({
    //             repeatBookOccupancyIDs: newRepeatBookingOccupancyIDs
    //         })
    //     }
    // }
    private handleSubmitNewParticipationDetails = async () => {
        await this.setState({
            repeatPreventer: true
        })

        let startTime = moment(`${this.props.dateShowing} ${this.state.newClassStartingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
        let endTime = await moment(`${this.props.dateShowing} ${this.state.newClassStartingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ').add(parseInt(this.state.selectedPackageDetails.effective_time), 'minutes');
        let siteID = (this.props.classroomLists.filter((room: any) => room.id === this.state.newClassRoomInput))[0].site_id
        await this.props.handleSubmitNewPrivateClassDetails(this.state.selectedCustomerID, siteID, this.state.newClassRoomInput, startTime.format('YYYY-MM-DD HH:mmZZ'), endTime.format('YYYY-MM-DD HH:mmZZ'), [this.state.selectedPackageID], this.state.bookingCount)
        await this.props.handleCloseClick();
        await this.props.handleResetClick();
        await this.props.handleExitClick();
    }

    private handleNewClassRoomInput = async (event: any) => {
        await this.setState({
            newClassRoomInput: event.target.value
        })
        let occupancies: any = []
        this.props.occupancyOfTheDay.map((occupancy: any) => {
            if (occupancy.classroom_id === this.state.newClassRoomInput) {
                occupancies.push(occupancy)
            }
        })

        let tempNewClassStartingTimeList: any = []
        let todayOpeningTime = this.props.openingHourLists[moment(`${this.props.dateShowing}`).isoWeekday()];
        let endOfDay = moment(`${this.props.dateShowing}${todayOpeningTime.split('-')[1]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
        for (let i = moment(`${this.props.dateShowing}${todayOpeningTime.split('-')[0]}+0800`, 'YYYY-MM-DD HH:mmZZZZ'); i <= endOfDay; i = i.add(15, 'minutes')) {
            tempNewClassStartingTimeList.push(i.format('HH:mm'))
        };

        let duplicatedTime: any = []
        occupancies.map((occupancy: any) => {
            let startTime = moment(occupancy.start_time);
            let endTime = moment(occupancy.end_time);
            for (let i = 0; i < tempNewClassStartingTimeList.length; i++) {
                // SPACE OCCUPYING CONDITION HERE
                if (moment(`${this.props.dateShowing} ${tempNewClassStartingTimeList[i]}+0800`, 'YYYY-MM-DD HH:mmZZZZ') >= startTime && moment(`${this.props.dateShowing} ${tempNewClassStartingTimeList[i]}+0800`, 'YYYY-MM-DD HH:mmZZZZ') < endTime) {
                    duplicatedTime.push(tempNewClassStartingTimeList[i]);
                }
            }
        })
        console.log(duplicatedTime)

        for (let i = 0; i < duplicatedTime.length; i++) {
            tempNewClassStartingTimeList = tempNewClassStartingTimeList.filter((time: any) => time !== duplicatedTime[i])
        }

        console.log(tempNewClassStartingTimeList) //STARTING TIME LIST FILTERED DUPLICATED TIME SLOT

        //PROCESS SLOT FULFILLING BOTH CLASSROOM & DURATION CONDITIONS
        let finalNewClassStartingTimeList = tempNewClassStartingTimeList
        let currentCountingOccupancy = 0
        if (occupancies.length === 0) {
            let popCount = parseFloat(this.state.selectedPackageDetails.effective_time) / 15
            for (let i = 0; i < popCount; i++) {
                finalNewClassStartingTimeList.pop()
            }
        } else {
            console.log(tempNewClassStartingTimeList);
            for (let startingTime of tempNewClassStartingTimeList) {
                console.log(startingTime)
                let targetOccupancyStartTime = occupancies[currentCountingOccupancy].start_time;
                if (moment.duration(moment(`${targetOccupancyStartTime}`).diff(moment(`${this.props.dateShowing} ${startingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ'))).asMinutes() > 0) {
                    let result = moment.duration(moment(`${targetOccupancyStartTime}`).diff(moment(`${this.props.dateShowing} ${startingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ'))).asMinutes();
                    console.log(result)
                    console.log(startingTime)
                    if (result < parseFloat(this.state.selectedPackageDetails.effective_time)) {
                        finalNewClassStartingTimeList = finalNewClassStartingTimeList.filter((time: any) => time !== startingTime)
                    }
                } else {
                    if (currentCountingOccupancy < occupancies.length - 1) {
                        currentCountingOccupancy += 1
                        let targetOccupancyStartTime = occupancies[currentCountingOccupancy].start_time;
                        if (moment.duration(moment(`${targetOccupancyStartTime}`).diff(moment(`${this.props.dateShowing} ${startingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ'))).asMinutes() > 0) {
                            let result = moment.duration(moment(`${targetOccupancyStartTime}`).diff(moment(`${this.props.dateShowing} ${startingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ'))).asMinutes();
                            console.log(result)
                            console.log(startingTime)
                            if (result < parseFloat(this.state.selectedPackageDetails.effective_time)) {
                                finalNewClassStartingTimeList = finalNewClassStartingTimeList.filter((time: any) => time !== startingTime)
                            }
                        }
                    }
                }
            }
        }

        await this.setState({
            newClassStartingTimeList: finalNewClassStartingTimeList
        })

    }
    private handleStringTimeInput = async (event: any) => {
        await this.setState({
            newClassStartingTime: event.target.value
        })
    }

    private handleTutorInput = async (event: any) => {
        await this.setState({
            newClassTutor: event.target.value
        })
    }

    private handleBookingCountInput = async (event: any) => {
        await this.setState({
            bookingCount: event.target.value
        })
    }

    render() {
        // let alreadyInClassResult = undefined
        // if (this.props.customerSearchingResult.length !== 0) {
        //     alreadyInClassResult = this.props.occupancyParticipationDetails.find((participation: any) =>
        //         participation.customer_id === this.props.customerSearchingResult[0].id
        //     )
        // }
        let selectedCustomerDetails = null
        if (this.props.customerSearchingResult.length !== 0 && this.state.selectedCustomerID !== 0) {
            selectedCustomerDetails = this.props.customerSearchingResult.find((customer: any) =>
                customer.id === this.state.selectedCustomerID
            )
        }
        let gotTokenLeft = true
        if (this.state.selectedPackageDetails.token_based_package && this.state.repeatBookOccupancyIDs.length >= this.state.selectedPackageDetails.token_amount - 1) {
            gotTokenLeft = false
        }
        let checkSimilarPackage = [];
        // let similarPackages = this.props.availablePackagesOfCustomer.unusedPackages.filter((targetPackage: any) => targetPackage.id !== this.state.selectedPackageID &&
        //     targetPackage.package_name === this.state.selectedPackageDetails.package_name &&
        //     targetPackage.effective_time === this.state.selectedPackageDetails.effective_time)
        // for (let i = 0; i < similarPackages.length; i++) {
        //     checkSimilarPackage.push(i + 2);
        // }
        for (let i = 2; i <= this.state.selectedPackageDetails.token_amount; i++) {
            checkSimilarPackage.push(i);
        }

        return (
            <div className="baseAreaOfAddNewBookingWindow">
                <div className="navDisplayRow">
                    {this.state.windowNav === 'searchPage' ?
                        <h3 className="addNewBookingWindowTitle">{this.props.language !== "eng" ? "搜尋顧客" : "Search Customer"}</h3>
                        :
                        <h3 className="addNewBookingWindowTitle">{this.props.language !== "eng" ? "選擇要使用的套券:" : "Choose Target Package for Booking:"}</h3>
                    }
                    <CloseIcon id="addNewBookingWindowCloseBtn" onClick={this.handleCloseClick} />
                </div>
                {this.state.windowNav === 'searchPage' ?
                    <div className="searchRow">
                        <FormControl id="searchOptionInAddNewBookingWindow">
                            <InputLabel >
                                {this.props.language !== "eng" ? "方法" : "Method"}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.searchingMethod}
                                onChange={(e) => this.handleSearchingMethodChange(e)}
                            // labelWidth={labelWidth}
                            >
                                {/* <MenuItem value="">
                                <em>未選</em>
                            </MenuItem> */}
                                <MenuItem value='customerID'>{this.props.language !== "eng" ? "顧客編號 :" : "Customer ID :"}</MenuItem>
                                <MenuItem value='tel'>{this.props.language !== "eng" ? "顧客電話 :" : "TEL :"}</MenuItem>

                            </Select>
                        </FormControl>
                        <FormControl id="searchingInput">
                            <TextField
                                label={this.props.language !== "eng" ? "請輸入..." : "Please Enter..."}
                                value={this.state.searchingInput}
                                onChange={(e) => this.handleSearchingInputChange(e)}
                                onKeyUp={(e) => (e.keyCode === 13 ? this.handleSearchEnterPress(e) : null)}

                            />
                        </FormControl>
                        <div id="searchBtnContainerOnAddNewBookingWindow">
                            <Button startIcon={<SearchIcon />} id="searchBtnOnAddNewBookingWindow" onClick={this.handleSearchClick}>{this.props.language !== "eng" ? "搜尋" : "Search"}</Button>
                        </div>
                    </div>
                    :
                    <div className="searchRow2">

                        <div className="customerResultRow2">
                            {selectedCustomerDetails ?
                                <div className="customerDetailDisplayRow">
                                    <div className="customerDetails customerDetailsID"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "顧客編號 :" : "Customer ID :"}{selectedCustomerDetails.id}</div></div></div>
                                    <div className="customerDetails customerDetailsNAME"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "姓名 :" : "Name :"}{selectedCustomerDetails.name_eng ? selectedCustomerDetails.name_eng : selectedCustomerDetails.name_chi}</div></div></div>
                                    <div className="customerDetails customerDetailsSEX"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "性別 :" : "SEX :"}{selectedCustomerDetails.sex}</div></div></div>
                                    <div className="customerDetails customerDetailsTEL"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "電話 :" : "TEL :"}{selectedCustomerDetails.tel}</div></div></div>
                                </div>
                                : null
                            }
                        </div>
                        <div id="searchBtnContainerOnAddNewBookingWindow">
                            {this.state.windowNav === 'searchPage' ?
                                <Button startIcon={<SearchIcon />} id="searchBtnOnAddNewBookingWindow" onClick={this.handleSearchClick}>{this.props.language !== "eng" ? "搜尋" : "Search"}</Button>
                                :
                                <Button startIcon={<SearchIcon />} id="searchBtnOnAddNewBookingWindow" onClick={this.handleBackToSearchClick}>{this.props.language !== "eng" ? "返回搜尋" : "Back to Search"}</Button>

                            }
                        </div>
                    </div>
                }

                <div className="searchResultTitle">
                    {this.props.language !== "eng" ?
                        this.state.windowNav === 'searchPage' ?
                            '搜尋結果 :'
                            :
                            this.state.selectedPackageID === 0 ?
                                '顧客可用套券 :'
                                :
                                '即將使用套券 :'
                        :
                        this.state.windowNav === 'searchPage' ?
                            'Search Result :'
                            :
                            this.state.selectedPackageID === 0 ?
                                'Usable Package :'
                                :
                                'Using Package :'}
                </div>

                <div className="fixedHeightScrollingAreaOnAddNewBookingWindow">
                    {
                        this.state.windowNav === 'searchPage' ?
                            //SEARCH CUSTOMER RESULT DISPLAY AREA
                            this.props.customerSearchingResult.length === 0 ?
                                <div className="noneRow"> <div>{this.props.language !== "eng" ? "- 無顧客符合搜尋條件 -" : "- No Match Result-"}</div> </div>
                                :
                                this.props.customerSearchingResult.map((customer: any) =>

                                    <div className="customerResultRow">
                                        <div className="customerDetails customerDetailsID"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "顧客編號 :" : "Customer ID :"}{customer.id}</div></div></div>
                                        <div className="customerDetails customerDetailsNAME"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "姓名 :" : "Name :"}{customer.name_eng ? customer.name_eng : customer.name_chi}</div></div></div>
                                        <div className="customerDetails customerDetailsSEX"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "性別 :" : "SEX :"}{customer.sex}</div></div></div>
                                        <div className="customerDetails customerDetailsTEL"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "電話 :" : "TEL :"}{customer.tel}</div></div></div>
                                        <div className="selectBtnContainer">

                                            <Button id="selectCustomerBtn" onClick={() => this.handleSelectCustomerClick(customer.id)}>{this.props.language !== "eng" ? "選擇顧客" : "Select"}</Button>
                                        </div>
                                    </div>
                                )

                            :
                            //CASE: DISPLAY SELECTED PACKAGE AND BOOKING METHOD
                            this.state.selectedPackageID !== 0 ?
                                <div className="selectedPackagesWindowDisplayArea">
                                    <div className="searchRow2">
                                        <div className="selectedPackageDisplayRow">
                                            <div className="packageDetails packageID"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "課程編號 :" : "Package ID :"}{this.state.selectedPackageDetails.id}</div></div></div>
                                            <div className="packageDetails packageNAME"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "套券名稱 :" : "Package :"}{this.state.selectedPackageDetails.package_name}</div></div></div>
                                            {this.state.selectedPackageDetails.token_based_package ?
                                                <div className="packageDetails packageTOKEN"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{this.state.selectedPackageDetails.token_amount} {this.props.language !== "eng" ? "張" : ""}</div></div></div>
                                                :
                                                <div className="packageDetails packageTOKEN"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "有效未用套券數 : - 張" : "Non Token Based"}</div></div></div>
                                            }
                                            {this.state.selectedPackageDetails.end_date ?
                                                <div className="packageDetails packageENDDATE"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "有效期至:" : "Valid Tru :"}{moment(this.state.selectedPackageDetails.end_date).subtract(1, 'day').format("YYYY-MM-DD")}</div></div></div>
                                                :
                                                <div className="packageDetails packageENDDATE"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "未啟用" : "Not In Use"}</div></div></div>

                                            }
                                            <div className="packageDetails"><div className="detailsAligner"><CloseIcon id="deselectPackageBtn" onClick={this.handleDeselectPackageClick} /></div></div>
                                        </div>
                                    </div>
                                    {/* <div className="bookingMethodRow">
                                        {!this.state.repeatBooking ?
                                            <div id="bookingSingleClassBtn" ><div className="detailsAligner2"><div>此為一次性操作</div></div></div>
                                            :
                                            <div id="bookingSingleClassBtn2" onClick={this.handleDecideRepeatBookingClick}><div className="detailsAligner2"><div>此為一次性操作</div></div></div>
                                        }
                                        {this.state.repeatBooking ?
                                            <div id="bookingMultipleClassBtn" ><div className="detailsAligner2"><div>需要重覆性操作</div></div></div>
                                            :
                                            <div id="bookingMultipleClassBtn2" ><div className="detailsAligner2"><div>需要重覆性操作</div></div></div>
                                        }
                                    </div> */}
                                    <div className="classOccupanciesSpecificationInputArea">
                                        {
                                            this.state.bookingCount === 1 ?
                                                <div className="privateClassSpecInputRow">
                                                    <div className="bookingMethod">{this.props.language !== "eng" ? "此為一次性操作" : "1-Time Operation"}</div>
                                                    <div className="bookingSpecInputs">
                                                        <FormControl className="selectInputs newOccupancyInputLeft">
                                                            <InputLabel id="demo-simple-select-outlined-label">
                                                                {this.props.language !== "eng" ? "課室" : "Room"}
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                value={this.state.newClassRoomInput}
                                                                onChange={this.handleNewClassRoomInput}
                                                            >
                                                                {/* <MenuItem value={0}>None</MenuItem> */}
                                                                {this.props.classroomList.map((room: any) =>
                                                                    <MenuItem value={room.id}>{room.classroom}</MenuItem>
                                                                )}
                                                                {/* <MenuItem value={'M'}>M</MenuItem>
                                                <MenuItem value={'F'}>F</MenuItem>
                                                <MenuItem value={'-'}>Other</MenuItem> */}
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl className="selectInputs newOccupancyInputRight newOccupancyInputLeft">
                                                            <InputLabel id="demo-simple-select-outlined-label">
                                                                {this.props.language !== "eng" ? "開始時間" : "Start"}
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                // defaultValue={this.state.newClassStartingTime}
                                                                value={this.state.newClassStartingTime}
                                                                onChange={this.handleStringTimeInput}
                                                            >
                                                                {this.state.newClassStartingTimeList.map((time: any) =>
                                                                    <MenuItem value={time}>{time}</MenuItem>
                                                                )}
                                                                {/* <MenuItem value={'M'}>M</MenuItem>
                                            <MenuItem value={'F'}>F</MenuItem>
                                            <MenuItem value={'-'}>Other</MenuItem> */}
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl className="selectInputs newOccupancyInputLeft newOccupancyInputRight">
                                                            <InputLabel id="demo-simple-select-outlined-label">
                                                                {this.props.language !== "eng" ? "佔用時長" : "Duration"}
                                                            </InputLabel>
                                                            <Select
                                                                disabled
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                // defaultValue={this.state.newClassDuration}
                                                                value={this.state.selectedPackageDetails.effective_time}
                                                            // onChange={this.handleDurationInput}
                                                            >
                                                                <MenuItem value={this.state.selectedPackageDetails.effective_time}>{this.state.selectedPackageDetails.effective_time}min</MenuItem>



                                                            </Select>
                                                        </FormControl>

                                                        <FormControl className="selectInputs newOccupancyInputRight">
                                                            <InputLabel id="demo-simple-select-outlined-label">
                                                                {this.props.language !== "eng" ? "預約次數" : "Booking Count"}
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                // defaultValue={this.state.tutor_sex}
                                                                value={this.state.bookingCount}
                                                                onChange={this.handleBookingCountInput}
                                                            >
                                                                <MenuItem value={1}>{this.props.language !== "eng" ? "1次" : "1"}</MenuItem>
                                                                {!this.props.tutorInfos ? null :
                                                                    checkSimilarPackage.map((count: any) =>
                                                                        <MenuItem value={count}>{count}{this.props.language !== "eng" ? "次" : ""}</MenuItem>
                                                                    )}

                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                :
                                                <div className="privateClassSpecInputRow2">
                                                    <div className="bookingMethod">{this.props.language !== "eng" ? "需要重覆性操作" : "Repeatable"}</div>
                                                    <div className="bookingSpecInputs">
                                                        <FormControl className="selectInputs newOccupancyInputLeft">
                                                            <InputLabel id="demo-simple-select-outlined-label">
                                                                {this.props.language !== "eng" ? "課室" : "Room"}
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                value={this.state.newClassRoomInput}
                                                                onChange={this.handleNewClassRoomInput}
                                                            >
                                                                {/* <MenuItem value={0}>None</MenuItem> */}
                                                                {this.props.classroomList.map((room: any) =>
                                                                    <MenuItem value={room.id}>{room.classroom}</MenuItem>
                                                                )}
                                                                {/* <MenuItem value={'M'}>M</MenuItem>
                                                <MenuItem value={'F'}>F</MenuItem>
                                                <MenuItem value={'-'}>Other</MenuItem> */}
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl className="selectInputs newOccupancyInputRight newOccupancyInputLeft">
                                                            <InputLabel id="demo-simple-select-outlined-label">
                                                                {this.props.language !== "eng" ? "開始時間" : "Start"}
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                // defaultValue={this.state.newClassStartingTime}
                                                                value={this.state.newClassStartingTime}
                                                                onChange={this.handleStringTimeInput}
                                                            >
                                                                {this.state.newClassStartingTimeList.map((time: any) =>
                                                                    <MenuItem value={time}>{time}</MenuItem>
                                                                )}
                                                                {/* <MenuItem value={'M'}>M</MenuItem>
                                            <MenuItem value={'F'}>F</MenuItem>
                                            <MenuItem value={'-'}>Other</MenuItem> */}
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl className="selectInputs newOccupancyInputLeft newOccupancyInputRight">
                                                            <InputLabel id="demo-simple-select-outlined-label">
                                                                {this.props.language !== "eng" ? "佔用時長" : "Duration"}
                                                            </InputLabel>
                                                            <Select
                                                                disabled
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                // defaultValue={this.state.newClassDuration}
                                                                value={this.state.selectedPackageDetails.effective_time}
                                                            // onChange={this.handleDurationInput}
                                                            >
                                                                <MenuItem value={this.state.selectedPackageDetails.effective_time}>{this.state.selectedPackageDetails.effective_time}min</MenuItem>



                                                            </Select>
                                                        </FormControl>

                                                        <FormControl className="selectInputs newOccupancyInputRight">
                                                            <InputLabel id="demo-simple-select-outlined-label">
                                                                {this.props.language !== "eng" ? "預約次數" : "Booking Count"}
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                // defaultValue={this.state.tutor_sex}
                                                                value={this.state.bookingCount}
                                                                onChange={this.handleBookingCountInput}
                                                            >
                                                                <MenuItem value={1}>{this.props.language !== "eng" ? "1次" : "1"}</MenuItem>
                                                                {!this.props.tutorInfos ? null :
                                                                    checkSimilarPackage.map((count: any) =>
                                                                        <MenuItem value={count}>{count}{this.props.language !== "eng" ? "次" : ""}</MenuItem>
                                                                    )}

                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>

                                        }
                                    </div>
                                    <div className="confirmBtnRow">
                                        {
                                            this.state.repeatPreventer ?
                                                <Button id="confirmAddBookingBtn" disabled>{this.props.language !== "eng" ? `處理預約中(${this.state.bookingCount})` : `PROCESSING BOOKING (${this.state.bookingCount})`}</Button>
                                                :
                                                <Button id="confirmAddBookingBtn" onClick={this.handleSubmitNewParticipationDetails}>{this.props.language !== "eng" ? `完成預約操作(${this.state.bookingCount})` : `CONFIRM BOOKING (${this.state.bookingCount})`}</Button>
                                        }
                                    </div>
                                </div>

                                :
                                //CASE: CUSTOMER AVAILABLE PACKAGES DISPLAY AREA
                                this.props.availablePackagesOfCustomer.unusedPackages.length === 0
                                    && this.props.availablePackagesOfCustomer.usingTokenBasedPackages.length === 0 ?
                                    <div className="noneRow"> <div>{this.props.language !== "eng" ? "- 此顧客無可用套券 -" : "- No Applicable Package -"}</div> </div>
                                    :

                                    <div >
                                        {/* DISPLAY USING TOKEN PACKAGES */}
                                        {
                                            this.props.availablePackagesOfCustomer.usingTokenBasedPackages.map((eachPackage: any) =>
                                                eachPackage.token_based_package ?

                                                    <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>
                                                        <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                        <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>

                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至:" : "Valid Tru :"}{moment(eachPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>

                                                    </div>

                                                    : null
                                            )
                                        }

                                        {/* DISPLAY UNUSED PACKAGES */}
                                        {this.props.availablePackagesOfCustomer.unusedPackages.map((eachPackage: any) =>
                                            eachPackage.validate_period ?

                                                <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>
                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                    <div className="listingPackageOfCustomerName"> {eachPackage.package_name}</div>

                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>

                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "未啟用" : "Not In Use"}</div>

                                                </div>
                                                :
                                                <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>
                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                    <div className="listingPackageOfCustomerName"> {eachPackage.package_name}</div>

                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>

                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "未啟用" : "Not In Use"}</div>

                                                </div>
                                        )}
                                    </div>
                    }

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        language: state.auth.language,
        tutorInfos: state.schedule.tutorInfos,
        ageGroups: state.schedule.ageGroupList,
        genres: state.schedule.genreTypeList,
        classroomLists: state.schedule.classroomList,
        openingHourLists: state.schedule.openingTimeList,
        customerSearchingResult: state.addNewBookingWindow.customerSearchingResult,
        availablePackagesOfCustomer: state.addNewBookingWindow.availablePackagesOfCustomer,
        classOccupancyWithSameUSGID: state.addNewBookingWindow.classOccupancyWithSameUSGID,
        occupancyOfTheDay: state.schedule.occupancyOfTheShowingDate,
        classroomList: state.schedule.classroomList,
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        handleCustomerSearch: async (searchMethod: string, searchInput: any) => dispatch(await searchCustomerInfo(searchMethod, searchInput)),
        handleResetClick: () => dispatch(resetAddNewBookingWindow()),
        handleCustomerClick: async (customerID: number) => dispatch(await loadAvailablePackagesForAddingNewPrivateClass(customerID)),
        handleSubmitNewPrivateClassDetails: async (customerID: number, siteID: number, classroomID: number, startTime: any, endTime: any, tokenPackageIDs: any[], bookingCount: number) => dispatch(await createNewRoomRentalDetails(customerID, siteID, classroomID, startTime, endTime, tokenPackageIDs, bookingCount))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IAddNewRoomRentalWindow);
