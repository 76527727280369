import React from 'react';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import RoomIcon from '@material-ui/icons/Room';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { Button, Checkbox, FormGroup, TextField } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Previous from '@material-ui/icons/SkipPrevious';
import NextIcon from '@material-ui/icons/SkipNext';
import CallSplitOutlinedIcon from '@material-ui/icons/CallSplitOutlined';
import Switch from '@material-ui/core/Switch';
import CheckIcon from '@material-ui/icons/Check';
import EditSoldPackageConfirmationBox from './EditSoldPackageConfirmationBox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CompareArrowsOutlined } from '@material-ui/icons';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

export interface IMassEditPackageGenreComponentProps {
    language: string,
    allGenres: any,
    allPackageDetails: any,
    confirmEditPackageGenre: (targetChangingPackages: any, changeAllTargetedSoldPackage: boolean, changePackageType: boolean, changeBothSoldPackageAndPackageType: boolean) => Promise<void>
}

export interface IMassEditPackageGenreCompoentState {
    changeAllTargetedSoldPackage: boolean,
    changePackageType: boolean,
    changeBothSoldPackageAndPackageType: boolean,
    selectedMassEditPackageIDs: number[],
    addGenre: boolean,
    targetGenreIDs: number[],
    activeStep: number,
    createPackageSteps: any

}

class MassEditPakageGenreComponent extends React.Component<IMassEditPackageGenreComponentProps, IMassEditPackageGenreCompoentState> {
    constructor(props: IMassEditPackageGenreComponentProps) {
        super(props);
        this.state = {
            changeAllTargetedSoldPackage: false,
            changePackageType: false,
            changeBothSoldPackageAndPackageType: false,
            selectedMassEditPackageIDs: [],
            addGenre: true,
            targetGenreIDs: [],
            activeStep: 0,
            createPackageSteps: (this.props.language !== "eng" ? ['更改運動種類', '修改套券'] : ['Edit Genre Type', 'Edit Package'])
        }
    }

    private handleGenreEditClicks = async (event: any) => {
        let newTargetGenreIDs: number[] = this.state.targetGenreIDs
        if (newTargetGenreIDs.find((target: any) => target === parseInt(event.currentTarget.value))) {
            newTargetGenreIDs = newTargetGenreIDs.filter((target: any) => target !== parseInt(event.currentTarget.value))
        } else {
            newTargetGenreIDs.push(parseInt(event.currentTarget.value));
        }
        await this.setState({
            targetGenreIDs: newTargetGenreIDs
        })
    }

    private handleNextStepClick = async () => {
        await this.setState({
            activeStep: this.state.activeStep + 1
        })
    }
    private handlePreviousStepClick = async () => {
        await this.setState({
            activeStep: this.state.activeStep - 1
        })
    }
    private handlePackageEditClicks = async (event: any) => {
        console.log(event.currentTarget.value)
        let newTargetPackageIDs: number[] = this.state.selectedMassEditPackageIDs
        if (newTargetPackageIDs.find((target: any) => target === parseInt((event.currentTarget.value).replace(/[^0-9]/g, '')))) {
            
            newTargetPackageIDs = newTargetPackageIDs.filter((target: any) => target !== parseInt((event.currentTarget.value).replace(/[^0-9]/g, '')))
        } else {
            newTargetPackageIDs.push(parseInt((event.currentTarget.value).replace(/[^0-9]/g, '')));
        }
        await this.setState({
            selectedMassEditPackageIDs: newTargetPackageIDs
        })
    }

    private handleSelectChangingTarget = async (targetType: string) => {
        switch (targetType) {
            case 'previousOnly': await this.setState({
                changeAllTargetedSoldPackage: true,
                changePackageType: false,
                changeBothSoldPackageAndPackageType: false,
            });
            break;
            case 'packageTypeOnly' : await this.setState({
                changeAllTargetedSoldPackage: false,
                changePackageType: true,
                changeBothSoldPackageAndPackageType: false,
            });
            break;
            case 'bothPackageType&Sold' : await this.setState({
                changeAllTargetedSoldPackage: false,
                changePackageType: false,
                changeBothSoldPackageAndPackageType: true,
            })
        }
    }

    private handleShowEditSoldPackageConfirmationBoxBtn = async () => {
        let targetChangingPackages = [];

        if (this.state.addGenre) {
            for (let targetGenreID of this.state.targetGenreIDs) {
                for (let targetPackageID of this.state.selectedMassEditPackageIDs) {
                    let targetPackageDetails = this.props.allPackageDetails.find((targetPackage: any) => targetPackage.id === targetPackageID);
                    if (!targetPackageDetails.genre_id || !targetPackageDetails.genre_id.find((targetGenre: any) => parseInt(targetGenre) === targetGenreID)) {
                        let newGenreIDs = (targetPackageDetails.genre_id? targetPackageDetails.genre_id : []);
                        newGenreIDs.push(targetGenreID);
                        let targetChangingPackage = {
                            packageID: targetPackageID,
                            genreIDs: newGenreIDs
                        }
                        targetChangingPackages.push(targetChangingPackage);
                    } else {
                        let newGenreIDs = targetPackageDetails.genre_id;
                        newGenreIDs = newGenreIDs.filter((originGenreID:any) => originGenreID !== targetGenreID);
                        let targetChangingPackage = {
                            packageID: targetPackageID,
                            genreIDs: newGenreIDs
                        }
                        targetChangingPackages.push(targetChangingPackage);
                    }
                }
            }
        }
        this.props.confirmEditPackageGenre(targetChangingPackages, this.state.changeAllTargetedSoldPackage, this.state.changePackageType, this.state.changeBothSoldPackageAndPackageType)
    }
    render() {
        let rows: any = []
        let packageSuitableForEdits = this.props.allPackageDetails

        for (let targetGenre of this.props.allGenres) {
            let genre = {
                genreID: targetGenre.id,
                genre: targetGenre.genre
            }
            rows.push(genre);
        }

        return (
            <div className="systemMainDisplayArea row">
                <div className="systemManagePageTitle"> <ConfirmationNumberIcon id="systemManPageTitleIcon" /> {this.props.language !== "eng" ? "套券管理" : "Package Manage"}</div>
                <div className="newPackageTitleRow"><div>{this.props.language !== "eng" ? "更改套券" : "Edit Package"}</div></div>
                <div className="stepsIndicatorRow">
                    <Stepper id="stepIconColor" activeStep={this.state.activeStep} alternativeLabel>
                        {this.state.createPackageSteps.map((label: any) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                <div className="fixedHeightWindowOnNewPackageComponent">
                    <div className="mainInputRow">
                        {
                            this.state.activeStep === 0 ?
                                <div className="inputRowOnAddPackagecomponent row">
                                    {
                                        this.props.allGenres.map((thisGenre: any) =>
                                            <div className="massEditGenreCheckListAlignment">
                                                <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-12 offset-sm-0 col-xs-12 offset-xs-0"></div>

                                                <div className="col-xl-5 col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                                    <FormGroup className="massEditGenreCheckListItemAlignment" >
                                                        <FormControlLabel id="GenreItems" control={<Checkbox color="primary" onChange={this.handleGenreEditClicks} />}
                                                            label={thisGenre.genre}
                                                            value={thisGenre.id}
                                                        />
                                                    </FormGroup>
                                                </div>

                                            </div>
                                        )
                                    }
                                </div>
                                :
                                this.state.activeStep === 1 ?
                                    <div className="inputRowOnAddPackagecomponent row">
                                        {this.state.addGenre ?
                                            this.props.allPackageDetails.map((thisPackage: any) =>
                                                <div className="massEditGenreCheckListAlignment">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-12 offset-sm-0 col-xs-12 offset-xs-0"></div>

                                                    <div className="col-xl-5 col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                                        <FormGroup className="msddEditGenrePackageListItemAlignment" key={"package" + thisPackage.id}>
                                                            <FormControlLabel id="PackageItems" control={<Checkbox color="primary" onChange={this.handlePackageEditClicks} />}
                                                                label={thisPackage.package_name}
                                                                value={'Package' + thisPackage.id}

                                                            />
                                                        </FormGroup>
                                                    </div>

                                                </div>
                                            )
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    this.state.activeStep === 2 ?
                                        <div className="inputRowOnAddPackagecomponent row">
                                            {
                                                this.state.changeAllTargetedSoldPackage ?
                                                    <div className="col-lg-4  col-md-12 col-sm-12">
                                                        <div className="contentBlockOfClassTokenOnListingPage">
                                                            <div className="aligner"><Previous id="listingPackageIcon" /></div>
                                                            <div className="tokenUsage">只更改已售課堂套券</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-lg-4  col-md-12 col-sm-12">
                                                        <div className="usageBlockNotChosen" onClick={()=>this.handleSelectChangingTarget("previousOnly")}>
                                                            <div className="aligner"><Previous id="listingPackageIcon" /></div>
                                                            <div className="tokenUsage">只更改已售課堂套券</div>
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                this.state.changeBothSoldPackageAndPackageType ?
                                                    <div className="col-lg-4  col-md-12 col-sm-12">
                                                        <div className="contentBlockOfClassTokenOnListingPage">
                                                            <div className="aligner"><CallSplitOutlinedIcon id="listingPackageIcon" /></div>
                                                            <div className="tokenUsage">更改已售及未售課堂套券</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-lg-4  col-md-12 col-sm-12">
                                                        <div className="usageBlockNotChosen" onClick={()=>this.handleSelectChangingTarget("bothPackageType&Sold")}>
                                                            <div className="aligner"><CallSplitOutlinedIcon id="listingPackageIcon" /></div>
                                                            <div className="tokenUsage">更改已售及未售課堂套券</div>
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                this.state.changePackageType ?
                                                    <div className="col-lg-4  col-md-12 col-sm-12">
                                                        <div className="contentBlockOfClassTokenOnListingPage">
                                                            <div className="aligner"><NextIcon id="listingPackageIcon" /></div>
                                                            <div className="tokenUsage">只更改未售課堂套券</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-lg-4  col-md-12 col-sm-12">
                                                        <div className="usageBlockNotChosen" onClick={()=>this.handleSelectChangingTarget("packageTypeOnly")}>
                                                            <div className="aligner"><NextIcon id="listingPackageIcon" /></div>
                                                            <div className="tokenUsage">只更改未售課堂套券</div>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                        : null
                        }
                    </div>
                </div>
                <div className="modifyStepsRow">
                    {this.state.activeStep === 0 ? <Button id="stepButtonNoShow" disabled>{this.props.language !== "eng" ? "上一步" : "Back"}</Button> : <Button id="stepButton" onClick={this.handlePreviousStepClick}>{this.props.language !== "eng" ? "上一步" : "Back"}</Button>}
                    {
                        this.state.activeStep === 0 && this.state.targetGenreIDs.length !== 0 ?
                            <Button id="stepButton" onClick={this.handleNextStepClick}>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>
                            :
                            this.state.activeStep === 1 && this.state.selectedMassEditPackageIDs.length > 0 ?
                                <Button id="stepButton" onClick={this.handleNextStepClick}>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>
                                :
                                this.state.activeStep === 2 && (this.state.changeAllTargetedSoldPackage || this.state.changeBothSoldPackageAndPackageType || this.state.changePackageType)?
                                    <Button id="stepButton" onClick={this.handleShowEditSoldPackageConfirmationBoxBtn}>{this.props.language !== "eng" ? "確定更改套券" : "Confirm Edit Package"}</Button>
                                    :
                                    <Button id="stepButtonDisabled" onClick={this.handleNextStepClick} disabled>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>


                    }
                </div>
            </div>
        )
    }

}

export default MassEditPakageGenreComponent;