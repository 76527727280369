import React from "react";
import "./MarkReferralDetailsBox.css";
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { Button } from "@material-ui/core";

export interface IMarkReferralDetailsBoxProps {
    language:string,
    confirmSubmitReferralDetails: (referringCustomerID: number) => void,
    handleClose: () => void
}
export interface IMarkReferralDetailsBoxState {
    referringCustomerID: number
}

class IMarkReferralDetailsBox extends React.Component<IMarkReferralDetailsBoxProps, IMarkReferralDetailsBoxState>{
    constructor(props: IMarkReferralDetailsBoxProps) {
        super(props);
        this.state = {
            referringCustomerID: 0
        }
    }
    private handleReferringCustomerIDInput = async (event: any) => {
        if (parseInt(event.target.value) > 0) {
            await this.setState({
                referringCustomerID: parseInt(event.target.value)
            })
        } else {
            await this.setState({
                referringCustomerID: 0
            })
        }
    }

    private handleSubmitReferringDetails = async () => {
        if (this.state.referringCustomerID !== 0) {
            let confirmation = window.confirm("確認要進行此操作？")
            if (confirmation) {
                this.props.confirmSubmitReferralDetails(this.state.referringCustomerID);
            }
        } else {
            window.alert("請輸入有效顧客編號！");
        }
    }
    private handleCloseClick = async () => {
        this.props.handleClose();
    }

    render() {
        return (
            <div className="shadeOfMarkReferralDetailsBox">
                <div className="baseAreaOfMarkReferralDetailsBox">
                    <div className="navDisplayRow">
                        <div className="inlineDisplay">
                            <h3 className="addNewBookingWindowTitle">{this.props.language !== "eng"? "新增介紹此顧客的資料" : "Mark Referer of this Customer"}</h3>
                        </div>
                        <CloseIcon id="addNewBookingWindowCloseBtn" onClick={this.handleCloseClick} />
                    </div>
                    <div className="inputsContainerOnMarkReferralDetailsBox">
                        <TextField
                            className="inputFieldOnMarkReferralDetailsBox"
                            label={this.props.language !== "eng" ?"介紹此人的顧客編號" : "Customer ID of Member Referring This Customer"}
                            type="number"
                            value={this.state.referringCustomerID}
                            onChange={this.handleReferringCustomerIDInput}
                        />
                        <Button id="confirmationBtnOnMarkReferralDetailsBox" onClick={this.handleSubmitReferringDetails}>{this.props.language !== "eng"? "確認新增" : "Confirm"}</Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default IMarkReferralDetailsBox