

export function scheduleLoaded(openingTimeArray:any[], siteArray:any[],classroomArray: any[], occupancyOfTheDate : any[], cxlOccupancyOfTheDay : any[], genreTypeArray: any[], ageGroupArray: any[],tutorArray: any[], tutorListArray: any[], tutorListArrayForCxl: any[], roomRentingPrivateCustomerArray: any[], roomRentingPrivateCustomerArrayForCxl:any[]) {
    return {
        type: "SCHEDULE_LOADED" as "SCHEDULE_LOADED",
        openingTimeList: openingTimeArray,
        siteList: siteArray,
        classroomList: classroomArray,
        occupancyOfTheDate: occupancyOfTheDate,
        cxlOccupancyOfTheDay: cxlOccupancyOfTheDay,
        genreTypeList: genreTypeArray,
        ageGroupList: ageGroupArray,
        tutorInfos: tutorArray,
        tutorLists: tutorListArray,
        TutorListsForCxl: tutorListArrayForCxl,
        roomRentingPrivateCustomerList: roomRentingPrivateCustomerArray,
        roomRentingPrivateCustomerListForCxl: roomRentingPrivateCustomerArrayForCxl
    }
}
export function occupancyOfTheDateLoaded(occupancyOfTheDate : any[], cxlOccupancyOfTheDay:any[],tutorListArray: any[], tutorListArrayForCxl:any[], roomRentingPrivateCustomerList: any[], roomRentingPrivateCustomerArrayForCxl:any[]) {
    return {
        type: "OCCUPANCY_OF_THE_DATE_LOADED" as "OCCUPANCY_OF_THE_DATE_LOADED",
        occupancyOfTheDate: occupancyOfTheDate,
        cxlOccupancyOfTheDay: cxlOccupancyOfTheDay,
        tutorListArray: tutorListArray,
        TutorListsForCxl: tutorListArrayForCxl,
        roomRentingPrivateCustomerList: roomRentingPrivateCustomerList,
        roomRentingPrivateCustomerListForCxl: roomRentingPrivateCustomerArrayForCxl
    }
}

export function newOccpancySubmittedWithFailedUniqueCheck (failedResponse: any) {
    return {
        type: "NEW_OCCUPANCY_SUBMITTED_WITH_FAILED_UNIQUE_CHECK" as "NEW_OCCUPANCY_SUBMITTED_WITH_FAILED_UNIQUE_CHECK",
        duplicatedLatestSchedulingGroup: failedResponse.USGDetails,
        newOccupancyDetailsTempStorage: failedResponse.newOccupancyDetails
    }
}

export function handleUSGConfirmationBoxDeclineClick () {
    return {
        type: "USG_CONFIRMATION_DECLINE_CLICK_HANDLED" as "USG_CONFIRMATION_DECLINE_CLICK_HANDLED"
    }
}

export function handleUSGConfirmationUseUSGClick () {
    return {
        type: "USG_CONFIRMATION_USE_CLICK_HANDLED" as "USG_CONFIRMATION_USE_CLICK_HANDLED"
    }
}

export function handleUSGConfirmationNewUSGClick () {
    return {
        type: "USG_CONFIRMATION_CREATE_CLICK_HANDLED" as "USG_CONFIRMATION_CREATE_CLICK_HANDLED"
    }
}
export function occupancyOf7DaysLoaded(occupancyOf7Days : any[], tutorListArrayOf7Days: any[], roomRentingPrivateCustomerListOf7Days: any[], cloneWeekID:any) {
    return {
        type: "OCCUPANCY_OF_7_DAYS_LOADED" as "OCCUPANCY_OF_7_DAYS_LOADED",
        occupancyOf7Days: occupancyOf7Days,
        tutorListArrayOf7Days: tutorListArrayOf7Days,
        roomRentingPrivateCustomerListOf7Days: roomRentingPrivateCustomerListOf7Days,
        cloneWeekID: cloneWeekID
    }
}
export function occupancyOf7DaysCloned() {
    return {
        type: "OCCUPANCY_OF_7_DAYS_CLONED" as "OCCUPANCY_OF_7_DAYS_CLONED"
    }
}

export function loading () {
    return {
        type: "LOADING" as "LOADING"
    }
}

type ScheduleActionsCreators =  typeof scheduleLoaded | 
                                typeof occupancyOfTheDateLoaded | 
                                typeof newOccpancySubmittedWithFailedUniqueCheck |
                                typeof handleUSGConfirmationBoxDeclineClick |
                                typeof handleUSGConfirmationUseUSGClick |
                                typeof handleUSGConfirmationNewUSGClick |
                                typeof occupancyOf7DaysLoaded |
                                typeof occupancyOf7DaysCloned |
                                typeof loading;

export type IScheduleActions = ReturnType<ScheduleActionsCreators>;