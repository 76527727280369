

export function usernameInputted(username:string) {
    return {
        type:"USERNAME_INPUTTED" as "USERNAME_INPUTTED",
        username
    }
}

export function passwordInputted(password:string) {
    return {
        type:"PASSWORD_INPUTTED" as "PASSWORD_INPUTTED",
        password
    }
}

export function loginSuccess(result:any) {
    return {
        type:"LOGIN_SUCCESS" as "LOGIN_SUCCESS",
        result
    }
}

export function loginFailed() {
    return {
        type:"LOGIN_FAIL" as "LOGIN_FAIL"
    }
}

export function logoutSuccess() {
    return {
        type:"LOGOUT" as "LOGOUT"
    }
}

export function changeLanguageSuccess(language:string){
    return{
        type:"LANGUAGE_CHANGED" as "LANGUAGE_CHANGED",
        language
    }
}

type AuthActionsCreators = typeof usernameInputted | typeof passwordInputted | typeof loginSuccess | typeof loginFailed | typeof logoutSuccess | typeof changeLanguageSuccess;

export type IAuthActions = ReturnType<AuthActionsCreators>


// interface result {
//     isCan: boolean,
//     id: number,
//     username: string,
//     level: string
// }