import React from 'react';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import { IRootState, ThunkDispatch } from '../../store';
import { connect } from 'react-redux';
import { pageInit, loadAllPackagesWithUsageID, createPackage, updatePackage, delPackage, uniquePackageNameCheck, massUpdatePackageGenre } from './thunks';
import { Button } from "@material-ui/core";
import './PackagesManage.css';
import EditIcon from '@material-ui/icons/Edit';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import RoomIcon from '@material-ui/icons/Room';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AddIcon from '@material-ui/icons/Add';
import AddPackageComponent from './AddPackageComponent';
import EditPackageComponent from './EditPackageComponent';
import MassEditPakageGenreComponent from './MassEditPackageGenreComponent';
import { loading } from './actions';
import CircularProgress from '@material-ui/core/CircularProgress';


export interface IPackagesManageProps {
    language: string,
    uniquePackageNameCheckResult: any,
    allAgeGroups: any,
    allGenres: any,
    allTokenUsages: any,
    allPackagesWithRightUsage: any,
    allTerms: any,
    allSites: any,
    loading: boolean,
    triggerLoading: () => void,
    handlePageInit: () => void,
    handleLoadPackages: (tokenUsageID: number) => void,
    handleCreatePackage: (packageDetails: any) => void,
    handleEditPackage: (packageDetails: any, changeAllTargetedSoldPackage: boolean) => void,
    handleDelPackage: (packageID: number) => void,
    uniquePackageNameCheck: (packageName: string) => void,
    handleMassEditGenrePackage: (targetChangingPackages:any, changeAllTargetedSoldPackage:boolean, changePackageType:boolean, changeBothSoldPackageAndPackageType:boolean) => void

}
export interface IPackagesManageState {
    displayingTokenUsage: string,
    selectedPackageDetails: any,
    addNewPackage: boolean,
    editPackage: boolean,
    massEditPackageGenre: boolean
}

class PackagesManage extends React.Component<IPackagesManageProps, IPackagesManageState> {
    constructor(props: IPackagesManageProps) {
        super(props);
        this.state = {
            displayingTokenUsage: "Class Participation",
            selectedPackageDetails: '',
            addNewPackage: false,
            editPackage: false,
            massEditPackageGenre: false
        }
    }

    private handleDisplayingTokenUsageChange = async (displayTokenUsage: string) => {
        await this.setState({
            displayingTokenUsage: displayTokenUsage
        })
        let usageID = (this.props.allTokenUsages.filter((usage: any) => usage.usage === this.state.displayingTokenUsage))[0].id;
        await this.props.triggerLoading();
        setTimeout(async () => await this.props.handleLoadPackages(usageID), 1000);

    }

    private handlePackageEditClick = async (packageID: number) => {
        let selectedPackage = (this.props.allPackagesWithRightUsage.filter((targetPackage: any) => targetPackage.id === packageID))[0]
        await this.setState({
            selectedPackageDetails: selectedPackage
        })
        await this.setState({
            editPackage: true
        })
    }
    private handlePackageDelClick = async (packageID: number) => {
        let confirmation = window.confirm('確定要刪除此套券？');
        if (confirmation) {
            await this.props.handleDelPackage(packageID);
            let usageID = (this.props.allTokenUsages.filter((usage: any) => usage.usage === this.state.displayingTokenUsage))[0].id;
            setTimeout(async () => await this.props.handleLoadPackages(usageID), 1000);
        }
    }
    private handleAddPackageTriggerClick = async () => {
        await this.setState({
            addNewPackage: true
        })
    }
    private handleMassEditPackageGenreClick = async () => {
        await this.setState({
            massEditPackageGenre: true
        })
    }

    private handleConfirmAddNewPackage = async (newPackageDetails: any) => {
        await this.props.handleCreatePackage(newPackageDetails);
        let usageID = (this.props.allTokenUsages.filter((usage: any) => usage.usage === this.state.displayingTokenUsage))[0].id;
        await this.setState({
            addNewPackage: false
        })
        setTimeout(async () => await this.props.handleLoadPackages(usageID), 1000);

    }
    private handleConfirmEditNewPackage = async (newPackageDetails: any, changeAllTargetedSoldPackage: boolean) => {
        await this.props.handleEditPackage(newPackageDetails, changeAllTargetedSoldPackage);
        let usageID = (this.props.allTokenUsages.filter((usage: any) => usage.usage === this.state.displayingTokenUsage))[0].id;
        await this.setState({
            editPackage: false
        })
        setTimeout(async () => await this.props.handleLoadPackages(usageID), 1000);

    }

    private handleConfirmMassEditPackageGenre = async (targetChangingPackages: any, changeAllTargetedSoldPackage: boolean, changePackageType:boolean, changeBothSoldPackageAndPackageType: boolean) => {
        await this.props.handleMassEditGenrePackage(targetChangingPackages,changeAllTargetedSoldPackage, changePackageType, changeBothSoldPackageAndPackageType);
        await this.setState({
            massEditPackageGenre:false
        })
    }

    private handlePackageNameUniqueCheck = async (packageName: string) => {
        await this.props.uniquePackageNameCheck(packageName);
    }

    componentWillMount() {
        this.props.handlePageInit()
    }

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    {
                        this.state.addNewPackage ?
                            <AddPackageComponent allSites={this.props.allSites} allTokenUsages={this.props.allTokenUsages} allAgeGroups={this.props.allAgeGroups} allGenres={this.props.allGenres} allTerms={this.props.allTerms} confirmAddTokenPackage={(newPackageDetails: any) => this.handleConfirmAddNewPackage(newPackageDetails)} uniquePackageNameCheck={(packageName: string) => this.handlePackageNameUniqueCheck(packageName)} uniquePackageNameCheckResult={this.props.uniquePackageNameCheckResult} language={this.props.language} />
                            :
                            this.state.editPackage ?
                                <EditPackageComponent allSites={this.props.allSites} allTokenUsages={this.props.allTokenUsages} allAgeGroups={this.props.allAgeGroups} allGenres={this.props.allGenres} allTerms={this.props.allTerms} selectedPackageDetails={this.state.selectedPackageDetails} confirmEditTokenPackage={(newPackageDetails: any, changeAllTargetedSoldPackage: boolean) => this.handleConfirmEditNewPackage(newPackageDetails, changeAllTargetedSoldPackage)} uniquePackageNameCheck={(packageName: string) => this.handlePackageNameUniqueCheck(packageName)} uniquePackageNameCheckResult={this.props.uniquePackageNameCheckResult} language={this.props.language} />
                                :
                                this.state.massEditPackageGenre?
                                    <MassEditPakageGenreComponent allGenres={this.props.allGenres} allPackageDetails={this.props.allPackagesWithRightUsage} language={this.props.language} confirmEditPackageGenre={(targetchangingPackage:any, changeAllTargetedSoldPackage:boolean, changePackageType:boolean, changeBothSoldPackageAndPackageType:boolean)=>this.handleConfirmMassEditPackageGenre(targetchangingPackage,changeAllTargetedSoldPackage,changePackageType, changeBothSoldPackageAndPackageType)}/>
                                :

                                <div className="systemMainDisplayArea row">
                                    <div className="systemManagePageTitle col-12"> <ConfirmationNumberIcon id="systemManPageTitleIcon" /> {this.props.language !== "eng" ? "套券管理" : "Package Manage"}</div>
                                    <div className="blockTitleRow row">
                                        {/* <h3 className="blockTitle">顧客選購</h3> */}
                                        {/* <div className="subPageBtnRowOnPackageMan"> */}
                                        {this.state.displayingTokenUsage === 'Class Participation' ?
                                            <Button className="onSubPageBtn col-lg-3 col-sm-6">{this.props.language !== "eng" ? "一般課堂套券" : "Group Class"}</Button>
                                            :
                                            <Button className="customerManage_PurchasePackagesSubNav col-lg-3 col-sm-6" onClick={() => this.handleDisplayingTokenUsageChange('Class Participation')}>{this.props.language !== "eng" ? "一般課堂套券" : "Group Class"}</Button>
                                        }
                                        {this.state.displayingTokenUsage === 'Private Class Booking' ?
                                            <Button className="onSubPageBtn col-lg-3 col-sm-6">{this.props.language !== "eng" ? "私人課堂套券" : "Private Class"}</Button>
                                            :
                                            <Button className="customerManage_PurchasePackagesSubNav col-lg-3 col-sm-6" onClick={() => this.handleDisplayingTokenUsageChange('Private Class Booking')}>{this.props.language !== "eng" ? "私人課堂套券" : "Private Class"}</Button>
                                        }
                                        {this.state.displayingTokenUsage === 'Classroom Rental' ?
                                            <Button className="onSubPageBtn col-lg-3 col-sm-6">{this.props.language !== "eng" ? "租用場地套券" : "Room Rental"}</Button>
                                            :
                                            <Button className="customerManage_PurchasePackagesSubNav col-lg-3 col-sm-6" onClick={() => this.handleDisplayingTokenUsageChange('Classroom Rental')}>{this.props.language !== "eng" ? "租用場地套券" : "Room Rental"}</Button>
                                        }
                                        {this.state.displayingTokenUsage === 'Tutor Rental' ?
                                            <Button className="onSubPageBtn col-lg-3 col-sm-6">{this.props.language !== "eng" ? "租用導師套券" : "Tutor Hiring"}</Button>
                                            :
                                            <Button className="customerManage_PurchasePackagesSubNav col-lg-3 col-sm-6" onClick={() => this.handleDisplayingTokenUsageChange('Tutor Rental')}>{this.props.language !== "eng" ? "租用導師套券" : "Tutor Hiring"}</Button>
                                        }

                                        {/* </div> */}
                                        {/* <div className="purchaseBucketArea"> */}
                                        <Button id="addPackageTriggerBtn" className="col-lg-6 col-sm-12" onClick={this.handleAddPackageTriggerClick} startIcon={<AddIcon />} >{this.props.language !== "eng" ? "新增套券" : "Create Package"}</Button>
                                        <Button id="massEditGenreBtn" className="col-lg-6 col-sm-12" onClick={this.handleMassEditPackageGenreClick} startIcon={<AddIcon />} >{this.props.language !== "eng" ? "運動種類快速管理" : "Mass Edit Package Genre"}</Button>
                                        {/* </div> */}
                                    </div>
                                    <div className="fixedHeightPackageListingAreaOnPackageMan col-12">
                                        <div className="row">
                                            {
                                                this.props.loading ?
                                                    <div className="loadingContainerRow">
                                                        <div className="inlineDisplay">
                                                            <div className="loadingContainer">
                                                                <CircularProgress id="loadingCircle" />
                                                                <div className="loadingLabel">Loading ...</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    this.props.allPackagesWithRightUsage.length === 0 ?
                                                        null :
                                                        this.state.displayingTokenUsage === 'Class Participation' ?
                                                            this.props.allPackagesWithRightUsage.map((packagesOnList: any) =>
                                                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                                    <div className="contentBlockOfClassTokenOnListingPage">
                                                                        <div className="aligner"><PeopleAltIcon id="listingPackageIcon" /></div>
                                                                        <div className="listingPackagePrice">HKD$ {packagesOnList.price}</div>
                                                                        <div className="listingPackageNameContainer">
                                                                            <div className="listingPackageName">{packagesOnList.package_name}</div>
                                                                        </div>
                                                                        <div className="listingPackageContentRow">
                                                                            <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "年齢" : "Age"}：</div>
                                                                            {
                                                                                packagesOnList.age_group_id ?
                                                                                    <div className="listingPackageContentDetails">
                                                                                        <div className="packageListingContentAligner">
                                                                                            <div>{(this.props.allAgeGroups.find((ageGroup: any) => ageGroup.id === packagesOnList.age_group_id)).age_group}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="listingPackageContentDetails">
                                                                                        <div className="packageListingContentAligner">
                                                                                            <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                        </div>

                                                                        {/* <div className="listingPackageContent">{packagesOnList.age_group_id ? <div>年齢：{(this.props.ageGroupList.find((ageGroup: any) => ageGroup.id === packagesOnList.age_group_id)).age_group}</div> : <div>年齢：不限</div>}</div> */}
                                                                        {packagesOnList.token_based_package ?
                                                                            // <div className="listingPackageContent">{this.props.language !== "eng" ? "有效" : "Active"} :{packagesOnList.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                            <div className="listingPackageContentRow">
                                                                                <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效" : "Active"} :</div>
                                                                                <div className="listingPackageContentDetails">
                                                                                    <div className="packageListingContentAligner">
                                                                                        <div>{packagesOnList.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            :
                                                                            <div className="listingPackageContentRow">
                                                                                <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效" : "Active"} :</div>
                                                                                <div className="listingPackageContentDetails">
                                                                                    <div className="packageListingContentAligner">
                                                                                        <div>{packagesOnList.validate_period} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>}
                                                                        {/* <div className="listingPackageContent">{packagesOnList.site_id ? <div>{this.props.language !== "eng" ? "校址" : "Site"} :{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div> : <div>{this.props.language !== "eng" ? "校址" : "Site"} :不限</div>}</div> */
                                                                            <div className="listingPackageContentRow">
                                                                                <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "校址" : " Site "} :</div>
                                                                                <div className="listingPackageContentDetails">
                                                                                    <div className="packageListingContentAligner">
                                                                                        {
                                                                                            packagesOnList.site_id ?
                                                                                                <div>{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div>
                                                                                                :
                                                                                                <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {/* <div className="listingPackageContent">{packagesOnList.genre_id ? <div>{this.props.language !== "eng" ? "種類" : "Sport"} :{packagesOnList.genre_id.map((id: number) => this.props.genreTypeList.find(((genre: any) => genre.id === id)).genre + ' ')}</div> : <div>{this.props.language !== "eng" ? "種類" : "Sport"} :不限</div>}</div> */}
                                                                        <div className="listingPackageContentRow">
                                                                            <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "種類" : "Sport"} :</div>
                                                                            <div className="listingPackageContentGenreDetails">
                                                                                {
                                                                                    packagesOnList.genre_id ?
                                                                                        packagesOnList.genre_id.map((id: number) =>
                                                                                            <div className="packageListingContentAligner">
                                                                                                <div>{this.props.allGenres.find(((genre: any) => genre.id === id)).genre}</div>
                                                                                            </div>
                                                                                        )
                                                                                        :

                                                                                        <div className="aligner">
                                                                                            <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                        </div>

                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        <div className="editDelButtonListingRow">
                                                                            <Button id="clickToEditPackageBtn" startIcon={<EditIcon />} onClick={() => this.handlePackageEditClick(packagesOnList.id)}>{this.props.language !== "eng" ? "修改套券" : "Edit"}</Button>
                                                                            <Button id="clickToDelClassroomBtn" onClick={() => this.handlePackageDelClick(packagesOnList.id)}>{this.props.language !== "eng" ? "刪除套券" : "Delete"}</Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                            :
                                                            this.state.displayingTokenUsage === 'Private Class Booking' ?
                                                                this.props.allPackagesWithRightUsage.map((packagesOnList: any) =>
                                                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                                        <div className="contentBlockOfPrivateClassTokenOnListingPage">
                                                                            <div className="aligner"><PersonIcon id="listingPackageIcon" /></div>
                                                                            <div className="listingPackagePrice">HKD$ {packagesOnList.price}</div>
                                                                            <div className="listingPackageNameContainer">
                                                                                <div className="listingPackageName">{packagesOnList.package_name}</div>
                                                                            </div>
                                                                            <div className="listingPackageContentRow">
                                                                                <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "年齢" : "Age"}：</div>
                                                                                {
                                                                                    packagesOnList.age_group_id ?
                                                                                        <div className="listingPackageContentDetails">
                                                                                            <div className="packageListingContentAligner">
                                                                                                <div>{(this.props.allAgeGroups.find((ageGroup: any) => ageGroup.id === packagesOnList.age_group_id)).age_group}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="listingPackageContentDetails">
                                                                                            <div className="packageListingContentAligner">
                                                                                                <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                }
                                                                            </div>

                                                                            {/* <div className="listingPackageContent">{packagesOnList.age_group_id ? <div>年齢：{(this.props.ageGroupList.find((ageGroup: any) => ageGroup.id === packagesOnList.age_group_id)).age_group}</div> : <div>年齢：不限</div>}</div> */}
                                                                            {packagesOnList.token_amount ?
                                                                                // <div className="listingPackageContent">{this.props.language !== "eng" ? "有效" : "Active"} :{packagesOnList.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                <div className="listingPackageContentRow">
                                                                                    <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效" : "Active"} :</div>
                                                                                    <div className="listingPackageContentDetails">
                                                                                        <div className="packageListingContentAligner">
                                                                                            <div>{packagesOnList.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                :
                                                                                <div className="listingPackageContentRow">
                                                                                    <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效" : "Active"} :</div>
                                                                                    <div className="listingPackageContentDetails">
                                                                                        <div className="packageListingContentAligner">
                                                                                            <div>{packagesOnList.validate_period} {this.props.language !== "eng" ? "日" : "Days"}</div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>}
                                                                            {
                                                                                // <div className="listingPackageContent">{this.props.language !== "eng" ? "有效" : "Active"} :{packagesOnList.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                <div className="listingPackageContentRow">
                                                                                    <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "時長" : "Duration"} :</div>
                                                                                    <div className="listingPackageContentDetails">
                                                                                        <div className="packageListingContentAligner">
                                                                                            <div>{packagesOnList.effective_time ? packagesOnList.effective_time : "-"} {this.props.language !== "eng" ? "分鐘" : "Min"}</div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            }
                                                                            {/* <div className="listingPackageContent">{packagesOnList.site_id ? <div>{this.props.language !== "eng" ? "校址" : "Site"} :{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div> : <div>{this.props.language !== "eng" ? "校址" : "Site"} :不限</div>}</div> */
                                                                                <div className="listingPackageContentRow">
                                                                                    <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "校址" : " Site "} :</div>
                                                                                    <div className="listingPackageContentDetails">
                                                                                        <div className="packageListingContentAligner">
                                                                                            {
                                                                                                packagesOnList.site_id ?
                                                                                                    <div>{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div>
                                                                                                    :
                                                                                                    <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {/* <div className="listingPackageContent">{packagesOnList.genre_id ? <div>{this.props.language !== "eng" ? "種類" : "Sport"} :{packagesOnList.genre_id.map((id: number) => this.props.genreTypeList.find(((genre: any) => genre.id === id)).genre + ' ')}</div> : <div>{this.props.language !== "eng" ? "種類" : "Sport"} :不限</div>}</div> */}
                                                                            <div className="listingPackageContentRow">
                                                                                <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "種類" : "Sport"} :</div>
                                                                                <div className="listingPackageContentGenreDetails">
                                                                                    {
                                                                                        packagesOnList.genre_id ?
                                                                                            packagesOnList.genre_id.map((id: number) =>
                                                                                                <div className="packageListingContentAligner">
                                                                                                    <div>{this.props.allGenres.find(((genre: any) => genre.id === id)).genre}</div>
                                                                                                </div>
                                                                                            )
                                                                                            :

                                                                                            <div className="aligner">
                                                                                                <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                            </div>

                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                            <div className="editDelButtonListingRow">
                                                                                <Button id="clickToEditPackageBtn" startIcon={<EditIcon />} onClick={() => this.handlePackageEditClick(packagesOnList.id)} style={{ color: "rgb(25, 189, 104)" }}>{this.props.language !== "eng" ? "修改套券" : "Edit"}</Button>
                                                                                <Button id="clickToDelClassroomBtn" onClick={() => this.handlePackageDelClick(packagesOnList.id)}>{this.props.language !== "eng" ? "刪除套券" : "Delete"}</Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                                :
                                                                this.state.displayingTokenUsage === 'Classroom Rental' ?
                                                                    this.props.allPackagesWithRightUsage.map((packagesOnList: any) =>
                                                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                                            <div className="contentBlockOfRoomRentalTokenOnListingPage">
                                                                                <div className="aligner"><RoomIcon id="listingPackageIcon" /></div>
                                                                                <div className="listingPackagePrice">HKD$ {packagesOnList.price}</div>
                                                                                <div className="listingPackageNameContainer">
                                                                                    <div className="listingPackageName">{packagesOnList.package_name}</div>
                                                                                </div>
                                                                                {packagesOnList.token_amount ?
                                                                                    // <div className="listingPackageContent">{this.props.language !== "eng" ? "有效" : "Active"} :{packagesOnList.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                    <div className="listingPackageContentRow">
                                                                                        <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效" : "Active"} :</div>
                                                                                        <div className="listingPackageContentDetails">
                                                                                            <div className="packageListingContentAligner">
                                                                                                <div>{packagesOnList.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    :
                                                                                    <div className="listingPackageContentRow">
                                                                                        <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "有效" : "Active"} :</div>
                                                                                        <div className="listingPackageContentDetails">
                                                                                            <div className="packageListingContentAligner">
                                                                                                <div>{packagesOnList.validate_period} {this.props.language !== "eng" ? "日" : "Days"}</div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>}
                                                                                {
                                                                                    // <div className="listingPackageContent">{this.props.language !== "eng" ? "有效" : "Active"} :{packagesOnList.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                    <div className="listingPackageContentRow">
                                                                                        <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "時長" : "Duration"} :</div>
                                                                                        <div className="listingPackageContentDetails">
                                                                                            <div className="packageListingContentAligner">
                                                                                                <div>{packagesOnList.effective_time ? packagesOnList.effective_time : "-"} {this.props.language !== "eng" ? "分鐘" : "Min"}</div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                }
                                                                                {/* <div className="listingPackageContent">{packagesOnList.site_id ? <div>{this.props.language !== "eng" ? "校址" : "Site"} :{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div> : <div>{this.props.language !== "eng" ? "校址" : "Site"} :不限</div>}</div> */
                                                                                    <div className="listingPackageContentRow">
                                                                                        <div className="listingPackageContentTitle">{this.props.language !== "eng" ? "校址" : " Site "} :</div>
                                                                                        <div className="listingPackageContentDetails">
                                                                                            <div className="packageListingContentAligner">
                                                                                                {
                                                                                                    packagesOnList.site_id ?
                                                                                                        <div>{(this.props.allSites.find((site: any) => site.id === packagesOnList.site_id)).site_key}</div>
                                                                                                        :
                                                                                                        <div>{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }

                                                                                <div className="editDelButtonListingRow">
                                                                                    <Button id="clickToEditPackageBtn" startIcon={<EditIcon />} onClick={() => this.handlePackageEditClick(packagesOnList.id)} style={{ color: "rgb(33, 33, 33)" }}>{this.props.language !== "eng" ? "修改套券" : "Edit"}</Button>
                                                                                    <Button id="clickToDelClassroomBtn" onClick={() => this.handlePackageDelClick(packagesOnList.id)}>{this.props.language !== "eng" ? "刪除套券" : "Delete"}</Button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                    :
                                                                    this.state.displayingTokenUsage === 'Tutor Rental' ?
                                                                        this.props.allPackagesWithRightUsage.map((packagesOnList: any) =>
                                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                                                <div className="contentBlockOfTutorRentalTokenOnListingPage">
                                                                                    <div className="aligner"><AssignmentIndIcon id="listingPackageIcon" /></div>
                                                                                    <div className="listingPackagePrice">HKD$ {packagesOnList.price}</div>
                                                                                    <div className="listingPackageNameContainer">
                                                                                        <div className="listingPackageName">{packagesOnList.package_name}</div>
                                                                                    </div>
                                                                                    {packagesOnList.token_based_package ?
                                                                                        <div className="listingPackageContent">{this.props.language !== "eng" ? "有效" : "Active"} :{packagesOnList.token_amount} {this.props.language !== "eng" ? "張" : "Token"}</div>
                                                                                        :
                                                                                        <div className="listingPackageContent">{this.props.language !== "eng" ? "有效" : "Active"} :{packagesOnList.validate_period} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                                    }
                                                                                    <div className="editDelButtonListingRow">
                                                                                        <Button id="clickToEditPackageBtn" startIcon={<EditIcon />} onClick={() => this.handlePackageEditClick(packagesOnList.id)} style={{ color: "rgb(255, 170, 43)" }}>{this.props.language !== "eng" ? "修改套券" : "Edit"}</Button>
                                                                                        <Button id="clickToDelClassroomBtn" onClick={() => this.handlePackageDelClick(packagesOnList.id)}>{this.props.language !== "eng" ? "刪除套券" : "Delete"}</Button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                        : null
                                            }
                                        </div>
                                    </div>
                                </div>}
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state: IRootState) => {
    return {
        language: state.auth.language,
        allAgeGroups: state.packagesManage.allAgeGroups,
        allGenres: state.packagesManage.allGenres,
        allTokenUsages: state.packagesManage.allTokenUsages,
        allPackagesWithRightUsage: state.packagesManage.allPackagesWithRightUsage,
        allTerms: state.packagesManage.allTerms,
        allSites: state.packagesManage.allSites,
        uniquePackageNameCheckResult: state.packagesManage.uniquePackageNameCheckResult,
        loading: state.packagesManage.loading
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        triggerLoading: async () => dispatch(await loading()),
        handlePageInit: async () => dispatch(await pageInit()),
        handleLoadPackages: async (tokenUsageID: number) => dispatch(await loadAllPackagesWithUsageID(tokenUsageID)),
        handleCreatePackage: async (packageDetails: any) => dispatch(await createPackage(packageDetails)),
        handleEditPackage: async (packageDetails: any, changeAllTargetedSoldPackage: boolean) => dispatch(await updatePackage(packageDetails, changeAllTargetedSoldPackage)),
        handleDelPackage: async (packageID: number) => dispatch(await delPackage(packageID)),
        uniquePackageNameCheck: async (packageName: string) => dispatch(await uniquePackageNameCheck(packageName)),
        handleMassEditGenrePackage: async (targetChangingPackage:any, changeAllTargetedSoldPackage:boolean, changePackageType:boolean, changeBothSoldPackageAndPackageType:boolean) => dispatch(await massUpdatePackageGenre(targetChangingPackage,changeAllTargetedSoldPackage,changePackageType,changeBothSoldPackageAndPackageType))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PackagesManage);