



export function newOccpancyDetailsUpdateSubmittedWithFailedUniqueCheck (failedResponse: any) {
    return {
        type: "NEW_OCCUPANCY_DETAILS_UPDATE_SUBMITTED_WITH_FAILED_UNIQUE_CHECK" as "NEW_OCCUPANCY_DETAILS_UPDATE_SUBMITTED_WITH_FAILED_UNIQUE_CHECK",
        duplicatedLatestSchedulingGroup: failedResponse.USGDetails,
        newOccupancyDetailsTempStorage: failedResponse.newOccupancyDetails
    }
}

export function handleUSGConfirmationBoxDeclineClick () {
    return {
        type: "USG_CONFIRMATION_DECLINE_CLICK_HANDLED" as "USG_CONFIRMATION_DECLINE_CLICK_HANDLED"
    }
}

export function handleUSGConfirmationUseUSGClick () {
    return {
        type: "USG_CONFIRMATION_USE_CLICK_HANDLED" as "USG_CONFIRMATION_USE_CLICK_HANDLED"
    }
}

export function handleUSGConfirmationNewUSGClick () {
    return {
        type: "USG_CONFIRMATION_CREATE_CLICK_HANDLED" as "USG_CONFIRMATION_CREATE_CLICK_HANDLED"
    }
}

export function newOccupancyDetailsUpdateSubmitted () {
    return {
        type: "NEW_OCCUPANCY_DETAILS_UPDATE_SUBMITTED" as "NEW_OCCUPANCY_DETAILS_UPDATE_SUBMITTED"
    }
}

export function occupancyParticipationRelatedInfoLoaded (dataReceived:any) {
    return {
        type: "OCCUPANCY_PARTICIPATION_RELATED_INFO_LOADED" as "OCCUPANCY_PARTICIPATION_RELATED_INFO_LOADED",
        customerParticipationInfos: dataReceived.customerParticipationInfos,
        relatedCustomerPackagesInfos: dataReceived.relatedCustomerPackagesInfos,
        relatedCustomerInfos: dataReceived.relatedCustomerInfos,
        absentReasons : dataReceived.absentReasons
    }
}

export function cxlClassParticipationWithReasonProcessed () {
    return {
        type:"CXL_CLASS_PARTICIPATION_WITH_REASON_PROCESSED" as "CXL_CLASS_PARTICIPATION_WITH_REASON_PROCESSED"
    }
}
export function backwardCxlClassParticipationWithReasonProcessed () {
    return {
        type:"BACKWARD_CXL_CLASS_PARTICIPATION_WITH_REASON_PROCESSED" as "BACKWARD_CXL_CLASS_PARTICIPATION_WITH_REASON_PROCESSED"
    }
}
export function delRentalPrivateOccupancyBookingWithReasonCompleted() {
    return {
        type: "DEL_RENTAL_PRIVATE_OCCUPANCY_BOOKING_WITH_REASON_COMPLETED" as "DEL_RENTAL_PRIVATE_OCCUPANCY_BOOKING_WITH_REASON_COMPLETED"
    }
}
export function delCustomerParticipationByStaffWihtoutReason() {
    return {
        type: "DEL_CUSTOMER_PARTICIPATION_BY_STAFF_WITHOUT_REASON" as "DEL_CUSTOMER_PARTICIPATION_BY_STAFF_WITHOUT_REASON"
    }
}

export function resetRoomOccupancyIndividualBox() {
    return {
        type: "ROOM_OCCUPANCY_INDIVIDUAL_BOX_RESET" as "ROOM_OCCUPANCY_INDIVIDUAL_BOX_RESET"
    }
}



type RoomOccupancyIndividualBoxActionsCreators =  
                                typeof newOccpancyDetailsUpdateSubmittedWithFailedUniqueCheck |
                                typeof handleUSGConfirmationBoxDeclineClick |
                                typeof handleUSGConfirmationUseUSGClick |
                                typeof handleUSGConfirmationNewUSGClick |
                                typeof newOccupancyDetailsUpdateSubmitted |
                                typeof occupancyParticipationRelatedInfoLoaded |
                                typeof cxlClassParticipationWithReasonProcessed |
                                typeof backwardCxlClassParticipationWithReasonProcessed |
                                typeof delRentalPrivateOccupancyBookingWithReasonCompleted |
                                typeof delCustomerParticipationByStaffWihtoutReason |
                                typeof resetRoomOccupancyIndividualBox;

export type IRoomOccupancyIndividualBoxActions = ReturnType<RoomOccupancyIndividualBoxActionsCreators>;