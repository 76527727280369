import React from "react";
import { IRootState, ThunkDispatch } from "../store";
import { connect } from "react-redux";
import './Schedules.css'
import Calendar from 'react-calendar';
import moment from "moment";
import { Right } from "react-bootstrap/lib/Media";
import { height } from "@material-ui/system";
import { loadOccupancyOfNewDateSelected, schedulePageInit, newOccupancySubmission, newOccupancySubmissionWithUSGConfirmation, newOccupancySubmissionWithNewUSGAfterConfirmation, loadOccupancyOf7DaysSelected } from "./thunks";
import { handleUSGConfirmationBoxDeclineClick, loading } from './actions'
import ListIcon from '@material-ui/icons/List';
import { Button } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import RoomOccupancyIndividualBox from '../RoomOccupancyIndividualBox/RoomOccupancyIndividualBox'
import IUSGConfirmationBox from './USGConfirmationBox/USGConfirmationBox'
import IDisplayCxlOccupancyWindow from './DisplayCxlOccupancyWindow/DisplayCxlOccupancyWindow'
import IAddNewPrivateClassWindow from './AddNewPrivateClassWindow/AddNewPrivateClassWindow'
import IAddNewRoomRentalWindow from './AddNewRoomRentalWindow/AddNewRoomRentalWindow'
import Schedule7Days from './Schedules7Days'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';




export interface ISchedulesProps {
    language: string,
    tutorInfos: any,
    genreTypeLists: any,
    openingHourList: any,
    siteList: any,
    classroomList: any,
    occupancyOfTheDay: any,
    cxlOccupancyOfTheDay: any,
    occupancyOf7Days: any,
    ageGroupLists: any,
    tutorListsOfDay: any,
    tutorListsOfDayForCxl: any,
    tutorListsOf7Days: any,
    roomRentingPrivateCustomerList: any,
    roomRentingPrivateCustomerListForCxl: any,
    roomRentingPrivateCustomerListOf7Days: any,
    duplicatedLatestSchedulingGroup: any,
    newOccupancyDetailsTempStorage: any,
    cloneWeekID: any,
    loading: boolean,
    triggerLoading: () => void,
    load7DaysSchedule: (date: string, siteID: number) => void,
    dateChange: (date: string, siteID: number) => void,
    scheduleInit: (siteID: number) => void,
    newRoomOccupancySubmission: (newOccupancy: any, newUniqueSchedulingGroupInfo: any) => void,
    handleUSGConfirmationDeclineClick: () => void,
    handleUSGConfirmationUseUSGClick: (newOccupancy: any, USGID: number) => void,
    handleUSGConfirmationNewUSGClick: (newOccupancy: any, newUniqueSchedulingGroupInfo: any) => void

}
export interface ISchedulesState {
    dateShowing: string
    calendarDate: Date,
    showing1Day: boolean,
    newClassStartingTime: string | null,
    newClassStartingTimeList: any[],
    newClassDurationList: any[],
    newClassDuration: string | null,
    newClassRoomInput: string | null,
    newClassGenre: string | null,
    newClassAgeGroup: string | null,
    newClassCapacity: number | null,
    // newClassRoomInput: number | null,
    // newClassGenre: number | null,
    // newClassAgeGroup: number | null,
    newClassTutorList: [] | null,
    // newClassTutor: number | null,
    newClassTutor: string | null,
    showOccupancyIndividualBox: boolean,
    selectedOccupancyId: number | null,
    showAddNewPrivateClassWindow: boolean,
    showAddNewRoomRentalWindow: boolean,
    showDisplayCxlOccupancyWindow: boolean,
    showingSiteID: number
}

class Schedule extends React.Component<ISchedulesProps, ISchedulesState> {
    constructor(props: ISchedulesProps) {
        super(props);
        this.state = {
            dateShowing: moment().format('YYYY-MM-DD'),
            calendarDate: new Date(),
            showing1Day: true,
            // newClassStartingTime: null,
            newClassStartingTime: '',
            newClassStartingTimeList: [],
            newClassDurationList: [],
            // newClassDuration: null,
            // newClassRoomInput: null,
            // newClassGenre: null,
            // newClassAgeGroup: null,
            newClassDuration: '',
            newClassRoomInput: '',
            newClassGenre: '',
            newClassAgeGroup: '',
            newClassTutorList: null,
            newClassCapacity: 0,
            // newClassTutor: null,
            newClassTutor: '',
            showOccupancyIndividualBox: false,
            selectedOccupancyId: null,
            showAddNewPrivateClassWindow: false,
            showAddNewRoomRentalWindow: false,
            showDisplayCxlOccupancyWindow: false,
            showingSiteID: 0
        }
    }

    private handleDateChange = async (datePicked: any) => {
        let dateGoodFormat = moment(`${datePicked}`).format('YYYY-MM-DD');
        await this.setState({
            dateShowing: dateGoodFormat,
            calendarDate: datePicked
        })
        this.props.triggerLoading();
        setTimeout(async () => await this.props.dateChange(this.state.dateShowing, this.state.showingSiteID), 1000)
        // this.props.dateChange(this.state.dateShowing, this.state.showingSiteID);

        let newClassStartingTime = [];
        let todayOpeningTime = this.props.openingHourList[moment(`${this.state.dateShowing}`).isoWeekday()];
        let endOfDay = moment(`${this.state.dateShowing}${todayOpeningTime.split('-')[1]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
        for (let i = moment(`${this.state.dateShowing}${todayOpeningTime.split('-')[0]}+0800`, 'YYYY-MM-DD HH:mmZZZZ'); i <= endOfDay; i = i.add(15, 'minutes')) {
            newClassStartingTime.push(i.format('HH:mm'))
        };
        await this.setState({
            newClassStartingTimeList: newClassStartingTime
        })
    }
    private handleLastDay = async () => {
        let dateGoodFormat = moment(`${this.state.dateShowing}`).subtract(1, 'days').format('YYYY-MM-DD');
        await this.setState({
            dateShowing: dateGoodFormat,
            calendarDate: new Date(`${dateGoodFormat}`)
        })
        this.props.triggerLoading();
        await setTimeout(async () => await this.props.dateChange(this.state.dateShowing, this.state.showingSiteID), 1000)

        let newClassStartingTime = [];
        let todayOpeningTime = this.props.openingHourList[moment(`${this.state.dateShowing}`).isoWeekday()];
        let endOfDay = moment(`${this.state.dateShowing}${todayOpeningTime.split('-')[1]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
        for (let i = moment(`${this.state.dateShowing}${todayOpeningTime.split('-')[0]}+0800`, 'YYYY-MM-DD HH:mmZZZZ'); i <= endOfDay; i = i.add(15, 'minutes')) {
            newClassStartingTime.push(i.format('HH:mm'))
        };
        await this.setState({
            newClassStartingTimeList: newClassStartingTime
        })
    }
    private handleNextDay = async () => {
        let dateGoodFormat = moment(`${this.state.dateShowing}`).add(1, 'days').format('YYYY-MM-DD');
        await this.setState({
            dateShowing: dateGoodFormat,
            calendarDate: new Date(`${dateGoodFormat}`)
        })

        this.props.triggerLoading();
        await setTimeout(async () => await this.props.dateChange(this.state.dateShowing, this.state.showingSiteID), 1000)

        let newClassStartingTime = [];
        let todayOpeningTime = this.props.openingHourList[moment(`${this.state.dateShowing}`).isoWeekday()];
        let endOfDay = moment(`${this.state.dateShowing}${todayOpeningTime.split('-')[1]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
        for (let i = moment(`${this.state.dateShowing}${todayOpeningTime.split('-')[0]}+0800`, 'YYYY-MM-DD HH:mmZZZZ'); i <= endOfDay; i = i.add(15, 'minutes')) {
            newClassStartingTime.push(i.format('HH:mm'))
        };
        await this.setState({
            newClassStartingTimeList: newClassStartingTime
        })
    }
    // private handle7DaysScheduleReload = async () => {

    //     await this.props.load7DaysSchedule(this.state.dateShowing);

    // }
    private handleLastWeek = async () => {
        await this.setState({
            dateShowing: moment(this.state.dateShowing).subtract(7, 'days').format('YYYY-MM-DD'),
            calendarDate: new Date(moment(this.state.dateShowing).subtract(7, 'days').toISOString())
        })
        this.props.triggerLoading();
        setTimeout(async () => await this.props.load7DaysSchedule(this.state.dateShowing, this.state.showingSiteID), 1000)

    }
    private handleNextWeek = async () => {
        await this.setState({
            dateShowing: moment(this.state.dateShowing).add(7, 'days').format('YYYY-MM-DD'),
            calendarDate: new Date(moment(this.state.dateShowing).add(7, 'days').toISOString())

        })
        this.props.triggerLoading();
        setTimeout(async () => await this.props.load7DaysSchedule(this.state.dateShowing, this.state.showingSiteID), 1000)
    }
    private handleShowingScale = async () => {
        if (this.state.showing1Day) {
            this.props.triggerLoading();
            setTimeout(async () => await this.props.load7DaysSchedule(this.state.dateShowing, this.state.showingSiteID), 1000)
        } else {
            this.props.triggerLoading();
            setTimeout(async () => await this.props.dateChange(this.state.dateShowing, this.state.showingSiteID), 1000)
        }
        await this.setState({
            showing1Day: !this.state.showing1Day
        })
    }
    private handleStringTimeInput = async (event: any) => {
        await this.setState({
            newClassStartingTime: event.target.value
        })
        let occupancies: any = []
        this.props.occupancyOfTheDay.map((occupancy: any) => {
            if (occupancy.classroom_id === this.state.newClassRoomInput) {
                occupancies.push(occupancy)
            }
        })
        if (occupancies.length === 0) {
            //CASE 無已有課堂

            let todayOpeningTime = this.props.openingHourList[moment(`${this.state.dateShowing}`).isoWeekday()];
            let startOfCal = moment(`${this.state.dateShowing}${this.state.newClassStartingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
            let endOfDay = moment(`${this.state.dateShowing}${todayOpeningTime.split('-')[1]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
            let officeHoursLeft = moment.duration(endOfDay.diff(startOfCal)).asMinutes();
            let newClassDurations = []
            for (let i = 30; i <= officeHoursLeft; i = i + 15) {
                newClassDurations.push(`${i} min`);
            }
            await this.setState({
                newClassDurationList: newClassDurations
            })
        } else {
            let occupanciesOnwards: any = []
            for (let occupancy of occupancies) {
                if (new Date(occupancy.start_time) > new Date(`${this.state.dateShowing} ${this.state.newClassStartingTime}+0800`)) {
                    occupanciesOnwards.push(occupancy);
                }
            }
            let theNextOccupancy: any = []
            for (let occupancy of occupanciesOnwards) {
                if (theNextOccupancy.length === 0) {
                    theNextOccupancy = occupancy
                } else {
                    if (new Date(occupancy.start_time) < new Date(theNextOccupancy.start_time)) {
                        theNextOccupancy = occupancy
                    }
                }
            }
            console.log(theNextOccupancy)
            if (theNextOccupancy.length === 0) {
                //已有課堂不影響
                let todayOpeningTime = this.props.openingHourList[moment(`${this.state.dateShowing}`).isoWeekday()];
                let startOfCal = moment(`${this.state.dateShowing}${this.state.newClassStartingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
                let endOfDay = moment(`${this.state.dateShowing}${todayOpeningTime.split('-')[1]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
                let officeHoursLeft = moment.duration(endOfDay.diff(startOfCal)).asMinutes();
                let newClassDurations = []
                for (let i = 30; i <= officeHoursLeft; i = i + 15) {
                    await newClassDurations.push(`${i} min`);
                    console.log(i)
                }
                console.log("newClassDurations 01", officeHoursLeft, newClassDurations)
                await this.setState({
                    newClassDurationList: newClassDurations
                })
            }

            if (theNextOccupancy.start_time) {
                //已有課堂有影響
                let startOfNextOccupancy = moment(theNextOccupancy.start_time);
                let newClassStart = moment(`${this.state.dateShowing} ${this.state.newClassStartingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
                let result = moment.duration(startOfNextOccupancy.diff(newClassStart)).asMinutes();
                if (result < 30) {
                    console.log("newClassDurations 02", theNextOccupancy.start_time, startOfNextOccupancy, newClassStart, result)
                    await this.setState({
                        newClassDurationList: []
                    })
                } else {
                    let newClassDurations = []
                    for (let i = 30; i <= result; i = i + 15) {
                        newClassDurations.push(`${i} min`);
                    }
                    console.log("newClassDurations 03", result, newClassDurations)
                    await this.setState({
                        newClassDurationList: newClassDurations
                    })
                }
            }
        }
    }
    private handleNewClassRoomInput = async (event: any) => {
        await this.setState({
            newClassRoomInput: event.target.value
        })
        let occupancies: any = []
        this.props.occupancyOfTheDay.map((occupancy: any) => {
            if (occupancy.classroom_id === this.state.newClassRoomInput) {
                occupancies.push(occupancy)
            }
        })

        let tempNewClassStartingTimeList: any = []
        let todayOpeningTime = this.props.openingHourList[moment(`${this.state.dateShowing}`).isoWeekday()];
        let endOfDay = moment(`${this.state.dateShowing}${todayOpeningTime.split('-')[1]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
        for (let i = moment(`${this.state.dateShowing}${todayOpeningTime.split('-')[0]}+0800`, 'YYYY-MM-DD HH:mmZZZZ'); i <= endOfDay; i = i.add(15, 'minutes')) {
            tempNewClassStartingTimeList.push(i.format('HH:mm'))
        };

        let duplicatedTime: any = []
        occupancies.map((occupancy: any) => {
            let startTime = moment(occupancy.start_time);
            let endTime = moment(occupancy.end_time);
            for (let i = 0; i < tempNewClassStartingTimeList.length; i++) {
                // SPACE OCCUPYING CONDITION HERE
                if (moment(`${this.state.dateShowing} ${tempNewClassStartingTimeList[i]}+0800`, 'YYYY-MM-DD HH:mmZZZZ') >= startTime && moment(`${this.state.dateShowing} ${tempNewClassStartingTimeList[i]}+0800`, 'YYYY-MM-DD HH:mmZZZZ') < endTime) {
                    duplicatedTime.push(tempNewClassStartingTimeList[i]);
                }
            }
        })
        console.log(duplicatedTime)

        for (let i = 0; i < duplicatedTime.length; i++) {
            tempNewClassStartingTimeList = tempNewClassStartingTimeList.filter((time: any) => time !== duplicatedTime[i])
        }

        console.log(tempNewClassStartingTimeList)
        await this.setState({
            newClassStartingTimeList: tempNewClassStartingTimeList
        })
    }

    private handleGenreInput = async (event: any) => {
        await this.setState({
            newClassGenre: event.target.value
        })
    }

    private handleAgeGroupInput = async (event: any) => {
        await this.setState({
            newClassAgeGroup: event.target.value
        })

        if (this.state.newClassGenre && this.state.newClassAgeGroup) {
            let tutorForGenre = this.props.tutorInfos.filter((tutor: any) => tutor.genre_id.includes(this.state.newClassGenre));
            let suitableTutors = tutorForGenre.filter((tutor: any) => tutor.age_group_id.includes(this.state.newClassAgeGroup));
            this.setState({
                newClassTutorList: suitableTutors
            })
        }
    }
    private handleDurationInput = async (event: any) => {
        await this.setState({
            newClassDuration: event.target.value
        })
    }
    private handleTutorInput = async (event: any) => {
        await this.setState({
            newClassTutor: event.target.value
        })
    }
    private handleCapacityInput = async (event: any) => {
        await this.setState({
            newClassCapacity: event.target.value
        })
    }
    private handleNewPublicClassSubmit = async () => {
        if (this.state.newClassRoomInput && this.state.newClassStartingTime && this.state.newClassDuration && this.state.newClassGenre && this.state.newClassAgeGroup && this.state.newClassTutor) {

            let startMoment = moment(`${this.state.dateShowing} ${this.state.newClassStartingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
            let endMoment = await moment(`${this.state.dateShowing} ${this.state.newClassStartingTime}+0800`, 'YYYY-MM-DD HH:mmZZZZ').add(parseInt(this.state.newClassDuration), 'minutes');
            let newOccupancyInfo = {
                site_id: this.props.openingHourList.id,
                classroom_id: this.state.newClassRoomInput,
                start_time: startMoment.format('YYYY-MM-DD HH:mmZZ'),
                end_time: endMoment.format('YYYY-MM-DD HH:mmZZ'),
                room_rental: false,
                private_class: false,
                fully_occupying_space: true,
                age_group_id: this.state.newClassAgeGroup,
                genre_id: this.state.newClassGenre,
                tutor_id: this.state.newClassTutor,
                date: this.state.dateShowing,
                capacity: (this.state.newClassCapacity === 0 ? null : this.state.newClassCapacity)
            }
            let newUniqueSchedulingGroupInfo = {
                site_id: this.props.openingHourList.id,
                classroom_id: this.state.newClassRoomInput,
                start_time: startMoment.format('HH:mm'),
                end_time: endMoment.format('HH:mm'),
                day_of_week: moment(`${this.state.dateShowing}`).day(),
                fully_occupying: true,
                age_group_id: this.state.newClassAgeGroup,
                genre_id: this.state.newClassGenre,
                tutor_id: this.state.newClassTutor,
            }
            await this.props.newRoomOccupancySubmission(newOccupancyInfo, newUniqueSchedulingGroupInfo);
            this.props.triggerLoading();
            await setTimeout(async () => await this.props.dateChange(this.state.dateShowing, this.state.showingSiteID), 1000)
            await this.setState({
                newClassRoomInput: "",
                newClassStartingTime: "",
                newClassDuration: "",
                newClassGenre: "",
                newClassAgeGroup: "",
                newClassTutor: "",
                newClassCapacity: 0
            })
        } else {
            alert('Please Enter All Required Information.')
        }
    }

    private handleShowIndividualBox = async (occupancyId: number) => {
        if (this.state.selectedOccupancyId) {
            this.props.triggerLoading();
            setTimeout(async () => await this.props.dateChange(this.state.dateShowing, this.state.showingSiteID), 1000)
            await this.setState({
                showOccupancyIndividualBox: !this.state.showOccupancyIndividualBox,
                selectedOccupancyId: null
            })
        } else {

            await this.setState({
                showOccupancyIndividualBox: !this.state.showOccupancyIndividualBox,
                selectedOccupancyId: occupancyId
            })
        }
    }

    private handleReloadSchedule = async () => {
        this.props.triggerLoading();
        setTimeout(async () => await this.props.dateChange(this.state.dateShowing, this.state.showingSiteID), 1000)
    }

    private handleUSGConfirmationBoxDecline = async () => {
        this.props.handleUSGConfirmationDeclineClick();
    }

    private handleUSGConfirmationBoxProceedWithUSG = async () => {
        await this.props.handleUSGConfirmationUseUSGClick(this.props.newOccupancyDetailsTempStorage, this.props.duplicatedLatestSchedulingGroup.id);
        this.props.triggerLoading();
        setTimeout(async () => await this.props.dateChange(this.state.dateShowing, this.state.showingSiteID), 1000)
    }

    private handleUSGConfirmationBoxProceedWithNewUSG = async () => {
        await this.props.handleUSGConfirmationNewUSGClick(this.props.newOccupancyDetailsTempStorage, this.props.duplicatedLatestSchedulingGroup)
        this.props.triggerLoading();
        setTimeout(async () => await this.props.dateChange(this.state.dateShowing, this.state.showingSiteID), 1000)
    }

    private handleAddNewPrivateClassClick = async () => {
        await this.setState({
            showAddNewPrivateClassWindow: !this.state.showAddNewPrivateClassWindow
        })
    }

    private handleAddNewRoomRentalClick = async () => {
        await this.setState({
            showAddNewRoomRentalWindow: !this.state.showAddNewRoomRentalWindow
        })
    }

    private handleExitFromAddNewPrivateClassWindow = async () => {
        this.props.triggerLoading();
        setTimeout(async () => await this.props.dateChange(this.state.dateShowing, this.state.showingSiteID), 1000)
    }

    private handleExitFromAddNewRoomRentalWindow = async () => {
        this.props.triggerLoading();
        setTimeout(async () => await this.props.dateChange(this.state.dateShowing, this.state.showingSiteID), 1000)
    }

    private handleShowDisplayCxlOccupancyWindow = async () => {
        await this.setState({
            showDisplayCxlOccupancyWindow: !this.state.showDisplayCxlOccupancyWindow
        })
    }

    private handleSiteChange = async (event: any) => {
        await this.setState({
            showingSiteID: event.target.value
        })
        this.props.scheduleInit(this.state.showingSiteID)

    }

    // private handleScroll = () => {
    //     const node = ReactDOM.findDOMNode(this);
    //     const mainTableBody = node.querySelector('mainTableBodyForMoreThanFourRooms')
    // }
    async componentWillMount() {
        if (this.props.openingHourList.length === 0 || this.state.showingSiteID === 0) {
            let clientToken = localStorage.getItem('token');
            let tokenPayload: any = {}
            if (clientToken) {
                tokenPayload = await parseJwt(clientToken)
            }
            await this.setState({
                showingSiteID: tokenPayload.siteID
            })
        }
        this.props.scheduleInit(this.state.showingSiteID)
    }


    render() {
        let clientToken = localStorage.getItem('token');
        let tokenPayload: any = {}
        if (clientToken) {
            tokenPayload = parseJwt(clientToken)
        }
        let timeListingArray = [];
        // let newClassStartingTime = [];

        let topTimePix = 38;
        let topTimeLinePix = 49;
        let tableHeight = 0;
        let startOfDay: any = moment();
        let tableWidth = vw(65.6133)

        if (this.props.openingHourList.length !== 0) {
            let todayOpeningTime = this.props.openingHourList[moment(`${this.state.dateShowing}`).isoWeekday()];
            startOfDay = moment(`${this.state.dateShowing}${todayOpeningTime.split('-')[0]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
            // let hourCounting = moment(`${this.state.dateShowing}${todayOpeningTime.split('-')[0]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
            let endOfDay = moment(`${this.state.dateShowing}${todayOpeningTime.split('-')[1]}+0800`, 'YYYY-MM-DD HH:mmZZZZ');
            let openingHours = moment.duration(endOfDay.diff(startOfDay)).asMinutes()

            for (let i = moment(`${this.state.dateShowing}${todayOpeningTime.split('-')[0]}+0800`, 'YYYY-MM-DD HH:mmZZZZ'); i <= endOfDay; i = i.add(30, 'minutes')) {

                tableHeight = topTimeLinePix + 1
                let smallTimeArr = [i.format('HH:mm'), topTimePix, topTimeLinePix];
                topTimePix += 100;
                topTimeLinePix += 100;
                timeListingArray.push(smallTimeArr);
            };




        }

        return (

            <div className="col-12">
                {!this.state.showing1Day ?

                    <Schedule7Days handleShowingScale={this.handleShowingScale}
                        dateSelected={this.state.dateShowing} classroomList={this.props.classroomList} openingHourList={this.props.openingHourList}
                        occupancyOf7Days={this.props.occupancyOf7Days} roomRentingPrivateCustomerListOf7Days={this.props.roomRentingPrivateCustomerListOf7Days}
                        tutorListsOf7Days={this.props.tutorListsOf7Days} tutorInfos={this.props.tutorInfos} genreTypeLists={this.props.genreTypeLists}
                        ageGroupLists={this.props.ageGroupLists} cloneWeekID={this.props.cloneWeekID} handleGoLastWeek={this.handleLastWeek}
                        handleGoNextWeek={this.handleNextWeek} showingSiteID={this.state.showingSiteID} loading={this.props.loading} />

                    :
                    <div className="row">
                        {this.state.showOccupancyIndividualBox && this.state.selectedOccupancyId ? <RoomOccupancyIndividualBox allSites={this.props.siteList} loading={this.props.loading} dateShowing={this.state.dateShowing} tutorList={this.props.tutorListsOfDay} tutorInfos={this.props.tutorInfos} selectedOccupancyId={this.state.selectedOccupancyId} reloadSchedule={this.handleReloadSchedule} handleExit={() => { if (this.state.selectedOccupancyId) { this.handleShowIndividualBox(this.state.selectedOccupancyId) } }} /> : null}
                        {/* {this.props.duplicatedLatestSchedulingGroup.length === 0 ? null : <IUSGConfirmationBox duplicatedLatestSchedulingGroup={this.props.duplicatedLatestSchedulingGroup} handleDecline={()=> console.log('declined')} handleProceedWithUSG={()=> console.log('proceed according to suggestion')} handleProceedWithNewUSG ={()=> console.log('proceed with new USG')}/>} */}
                        <div className="col-3 cssBase01" id="leftCol">
                            <div className="row">
                                <div className="col-12 aligner">
                                    <Calendar
                                        onChange={this.handleDateChange}
                                        value={this.state.calendarDate}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-1"></div>
                                <div className="col-10 aligner">
                                    {!this.state.showing1Day ?
                                        <div className="w-100 ">
                                            <div className="showWeekDayBtn01 active">{this.props.language !== "eng" ? "7日" : "7 Days"}</div>
                                            <div className="showWeekDayBtn02" onClick={this.handleShowingScale}>{this.props.language !== "eng" ? "1日" : "1 Day"}</div>
                                        </div>
                                        :
                                        <div className="w-100">
                                            <div className="showWeekDayBtn01" onClick={this.handleShowingScale}>{this.props.language !== "eng" ? "7日" : "7 Days"}</div>
                                            <div className="showWeekDayBtn02 active">{this.props.language !== "eng" ? "1日" : "1 Day"}</div>
                                        </div>
                                    }

                                </div>
                                <div className="col-1"></div>

                            </div>
                            <div className="row">
                                <div className="col-1">

                                </div>
                                <div className="col-10">
                                    <div className="functionBarBase">
                                        <h5 id="newClassFormTitle">{this.props.language !== "eng" ? "新增 一般課堂 佔用時段" : "New Group Class"}</h5>

                                        {this.props.language !== "eng" ?
                                            <TextField className="Inputs w-100" id="newOccupancyDate" label="日期" variant="standard" value={this.state.dateShowing} />
                                            :
                                            <TextField className="Inputs w-100" id="newOccupancyDate" label="Date" variant="standard" value={this.state.dateShowing} />
                                        }


                                        <FormControl className="selectInputs newOccupancyInputLeft">
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                {this.props.language !== "eng" ? "課室" : "Room"}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={this.state.newClassRoomInput}
                                                onChange={this.handleNewClassRoomInput}
                                            >
                                                <MenuItem value="">{this.props.language !== "eng" ? "未選" : "None"}</MenuItem>
                                                {this.props.classroomList.map((room: any) =>
                                                    <MenuItem value={room.id}>{room.classroom}</MenuItem>
                                                )}
                                                {/* <MenuItem value={'M'}>M</MenuItem>
                                            <MenuItem value={'F'}>F</MenuItem>
                                            <MenuItem value={'-'}>Other</MenuItem> */}
                                            </Select>
                                        </FormControl>
                                        <FormControl className="selectInputs newOccupancyInputRight">
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                {this.props.language !== "eng" ? "開始時間" : "Start"}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                // defaultValue={this.state.newClassStartingTime}
                                                value={this.state.newClassStartingTime}
                                                onChange={this.handleStringTimeInput}
                                            >
                                                {this.state.newClassStartingTimeList.map((time: any) =>
                                                    <MenuItem value={time}>{time}</MenuItem>
                                                )}
                                                {/* <MenuItem value={'M'}>M</MenuItem>
                                            <MenuItem value={'F'}>F</MenuItem>
                                            <MenuItem value={'-'}>Other</MenuItem> */}
                                            </Select>
                                        </FormControl>
                                        <FormControl className="selectInputs newOccupancyInputLeft">
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                {this.props.language !== "eng" ? "佔用時長" : "Duration"}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                // defaultValue={this.state.newClassDuration}
                                                value={this.state.newClassDuration}
                                                onChange={this.handleDurationInput}
                                            >
                                                {this.state.newClassDurationList.map((duration: any) =>
                                                    <MenuItem value={duration}>{duration}</MenuItem>

                                                )}

                                            </Select>
                                        </FormControl>
                                        <FormControl className="selectInputs newOccupancyInputRight">
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                {this.props.language !== "eng" ? "種類" : "Sport"}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                // defaultValue={this.state.newClassGenre}
                                                value={this.state.newClassGenre}
                                                onChange={this.handleGenreInput}
                                            >
                                                {this.props.genreTypeLists.map((genre: any) =>
                                                    !genre.active ? null :
                                                        <MenuItem value={genre.id}>{genre.genre}</MenuItem>
                                                )}

                                                {/* <MenuItem value={'M'}>15 min</MenuItem>
                                            <MenuItem value={'F'}>30 min</MenuItem>
                                            <MenuItem value={'-'}>45 min</MenuItem>
                                            <MenuItem value={'-'}>60 min</MenuItem> */}
                                            </Select>
                                        </FormControl>
                                        <FormControl className="selectInputs newOccupancyInputLeft">
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                {this.props.language !== "eng" ? "年齢組別" : "Age Group"}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                // defaultValue={this.state.newClassAgeGroup}
                                                value={this.state.newClassAgeGroup}
                                                onChange={this.handleAgeGroupInput}
                                            >
                                                {this.props.ageGroupLists.map((ageGroup: any) =>
                                                    !ageGroup.active ? null :
                                                        <MenuItem value={ageGroup.id}>{ageGroup.age_group}</MenuItem>
                                                )}

                                            </Select>
                                        </FormControl>
                                        <FormControl className="selectInputs newOccupancyInputRight">
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                {this.props.language !== "eng" ? "導師" : "Tutor"}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                // defaultValue={this.state.tutor_sex}
                                                value={this.state.newClassTutor}
                                                onChange={this.handleTutorInput}
                                            >
                                                {!this.state.newClassTutorList ? null :
                                                    this.state.newClassTutorList.map((tutor: any) =>
                                                        <MenuItem value={tutor.tutor_id}>{tutor.name_eng}</MenuItem>
                                                    )}

                                            </Select>
                                        </FormControl>
                                        <FormControl className="selectInputs ">
                                            {
                                                this.props.language !== "eng" ?
                                                    <TextField type="number" label="課堂人數限制（如適用）" value={this.state.newClassCapacity === 0 ? "" : this.state.newClassCapacity} onChange={this.handleCapacityInput} />
                                                    :
                                                    <TextField type="number" label="Class CAP (Optional)" value={this.state.newClassCapacity === 0 ? "" : this.state.newClassCapacity} onChange={this.handleCapacityInput} />

                                            }
                                        </FormControl>

                                        {
                                            tokenPayload.post === 'BOSS' ?
                                                <Button className="w-100" id="normalClassBtn" variant="contained" startIcon={<AddBoxIcon />} onClick={this.handleNewPublicClassSubmit}>{this.props.language !== "eng" ? "新增 一般課堂 佔用時段" : "Create Group Class"}</Button>
                                                :
                                                this.state.showingSiteID === tokenPayload.siteID ?
                                                    <Button className="w-100" id="normalClassBtn" variant="contained" startIcon={<AddBoxIcon />} onClick={this.handleNewPublicClassSubmit}>{this.props.language !== "eng" ? "新增 一般課堂 佔用時段" : "Create Group Class"}</Button>
                                                    :
                                                    <Button className="w-100" id="normalClassBtnDisabled" variant="contained" startIcon={<AddBoxIcon />} onClick={this.handleNewPublicClassSubmit} disabled>{this.props.language !== "eng" ? "新增 一般課堂 佔用時段" : "Create Group Class"}</Button>

                                        }
                                    </div>
                                </div>
                                <div className="col-1">

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-1"></div>
                                <div className="col-10">
                                    <div id="addRentalBtn">
                                        <div className="inlineDivider">
                                            {
                                                tokenPayload.post === 'BOSS' ?
                                                    <Button className="w-100 h-100" id="privateBtn" variant="contained" startIcon={<AddBoxIcon />} onClick={this.handleAddNewPrivateClassClick}>{this.props.language !== "eng" ? "新增 私人課堂 佔用時段" : "Create Private Class"}</Button>
                                                    :
                                                    this.state.showingSiteID === tokenPayload.siteID ?
                                                        <Button className="w-100 h-100" id="privateBtn" variant="contained" startIcon={<AddBoxIcon />} onClick={this.handleAddNewPrivateClassClick}>{this.props.language !== "eng" ? "新增 私人課堂 佔用時段" : "Create Private Class"}</Button>
                                                        :
                                                        <Button className="w-100 h-100" id="privateBtnDisabled" variant="contained" startIcon={<AddBoxIcon />} onClick={this.handleAddNewPrivateClassClick} disabled>{this.props.language !== "eng" ? "新增 私人課堂 佔用時段" : "Create Private Class"}</Button>
                                            }
                                        </div>
                                        <div className="spacerBtwAddOccupancyBtn"></div>
                                        <div className="inlineDivider">
                                            {
                                                tokenPayload.post === 'BOSS' ?
                                                    <Button className="w-100 h-100" id="rentalBtn" variant="contained" startIcon={<AddBoxIcon />} onClick={this.handleAddNewRoomRentalClick}>{this.props.language !== "eng" ? "新增 租用課堂 佔用時段" : "Create Rental Slot"}</Button>
                                                    :
                                                    this.state.showingSiteID === tokenPayload.siteID ?
                                                        <Button className="w-100 h-100" id="rentalBtn" variant="contained" startIcon={<AddBoxIcon />} onClick={this.handleAddNewRoomRentalClick}>{this.props.language !== "eng" ? "新增 租用課堂 佔用時段" : "Create Rental Slot"}</Button>
                                                        :
                                                        <Button className="w-100 h-100" id="rentalBtnDisabled" variant="contained" startIcon={<AddBoxIcon />} onClick={this.handleAddNewRoomRentalClick} disabled>{this.props.language !== "eng" ? "新增 租用課堂 佔用時段" : "Create Rental Slot"}</Button>
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div className="col-1"></div>
                            </div>

                        </div>
                        <div className="col-9">
                            <div className="row">
                                <div className="col-4 cssBase02 topInfoCol">
                                    <div id="bigDate">{moment(this.state.dateShowing).format('Do MMM (ddd)')}</div>
                                    <div id="switchDayBtn">
                                        <div id="lastDay"><div className="nextLastAligner" onClick={this.handleLastDay}><ChevronLeftIcon /></div></div>
                                        <div id="nextDay"><div className="nextLastAligner" onClick={this.handleNextDay}><ChevronRightIcon /></div></div>
                                    </div>
                                </div>
                                <div className="col-5 cssBase02 topInfoCol">
                                    <div className="centerControlPanelContainer">
                                        <FormControl variant="outlined" id="siteEditor">
                                            <InputLabel id="demo-simple-select-outlined-label">{this.props.language !== "eng" ? "分校" : "Site"}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={this.state.showingSiteID}
                                                onChange={this.handleSiteChange}

                                            >
                                                {
                                                    this.props.siteList.length === 0 ? null :
                                                        this.props.siteList.map((site: any) =>
                                                            <MenuItem value={site.id}>{site.site_key}</MenuItem>
                                                        )
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="col-3 cssBase02 topInfoCol">
                                    {
                                        this.props.cxlOccupancyOfTheDay.length === 0 ?
                                            <div className="showCxlOccupancyDisabled">
                                                <div className="inlineDisplay">{this.props.language !== "eng" ? "顯示已取消的課室佔用" : "Cxl Room Occupancies"}</div> <div className="cxlOccupancyNumberCountDisabled">{this.props.cxlOccupancyOfTheDay.length}</div>
                                            </div>
                                            :
                                            <div className="showCxlOccupancy" onClick={this.handleShowDisplayCxlOccupancyWindow}>
                                                <div className="inlineDisplay">{this.props.language !== "eng" ? "顯示已取消的課室佔用" : "Cxl Room Occupancies"}</div> <div className="cxlOccupancyNumberCount">{this.props.cxlOccupancyOfTheDay.length}</div>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 cssBase04">
                                    <div id="tableHeadContainer">
                                        <ul id="tableBase">
                                            <li className="scheduleTimeCol">
                                                <div className="tableHeads" >{this.props.language !== "eng" ? "時間" : "Time"}</div>
                                            </li>
                                            {
                                                this.props.classroomList.map((classroom: any) =>

                                                    <li className="scheduleHeadCol" style={{ width: (tableWidth / this.props.classroomList.length) }}>
                                                        <div className="tableHeads">
                                                            {classroom.classroom}
                                                        </div>

                                                    </li>)
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 cssBase03">
                                    {
                                        this.props.loading ?
                                            <div className="loadingContainerRow">
                                                <div className="inlineDisplay">
                                                    <div className="loadingContainer">
                                                        <CircularProgress id="loadingCircle" />
                                                        <div className="loadingLabel">Loading ...</div>
                                                    </div>
                                                </div>
                                            </div>
                                            :

                                            <div className="tableBody">
                                                {this.props.duplicatedLatestSchedulingGroup.length === 0 ? null : <IUSGConfirmationBox duplicatedLatestSchedulingGroup={this.props.duplicatedLatestSchedulingGroup} handleDecline={this.handleUSGConfirmationBoxDecline} handleProceedWithUSG={this.handleUSGConfirmationBoxProceedWithUSG} handleProceedWithNewUSG={this.handleUSGConfirmationBoxProceedWithNewUSG} />}
                                                {this.state.showAddNewPrivateClassWindow ? <IAddNewPrivateClassWindow dateShowing={this.state.dateShowing} handleCloseClick={this.handleAddNewPrivateClassClick} handleExitClick={this.handleExitFromAddNewPrivateClassWindow} /> : null}
                                                {this.state.showAddNewRoomRentalWindow ? <IAddNewRoomRentalWindow dateShowing={this.state.dateShowing} handleCloseClick={this.handleAddNewRoomRentalClick} handleExitClick={this.handleExitFromAddNewRoomRentalWindow} /> : null}
                                                {this.state.showDisplayCxlOccupancyWindow ? <IDisplayCxlOccupancyWindow handleCloseClick={this.handleShowDisplayCxlOccupancyWindow} cxlOccupancyOfTheDay={this.props.cxlOccupancyOfTheDay} classroomList={this.props.classroomList} tutorInfos={this.props.tutorInfos} tutorListsForCxl={this.props.tutorListsOfDayForCxl} /> : null}
                                                {timeListingArray.length === 0 ? null :
                                                    <div>
                                                        <ul className="mainBodyTime" style={{ height: tableHeight }}>
                                                            <li className="timeBlock">
                                                                {timeListingArray.length === 0 ? null :
                                                                    timeListingArray.map((time: any[]) =>
                                                                        <div>
                                                                            <div className="time" style={{ top: time[1], right: 27 }}>{time[0]}</div>
                                                                            <div className="timeLine" style={{ top: time[2], right: 0 }}></div>
                                                                        </div>

                                                                    )
                                                                }
                                                            </li>
                                                        </ul>
                                                        {this.props.classroomList.length === 0 ? null :

                                                            // FOR TABLE WITH LESS THAN 4 CLASSROOMS
                                                            <ul className="mainBodyBG" style={{ height: tableHeight, width: tableWidth }}>

                                                                {this.props.classroomList.map((classroom: any, index: number) =>
                                                                    <li className='roomCol' style={{ width: (tableWidth / this.props.classroomList.length) }} key={`a${index}`}>
                                                                        {this.props.occupancyOfTheDay.length === 0 ? null :
                                                                            <div>

                                                                                {this.props.occupancyOfTheDay.map((occupancy: any, i: number) =>
                                                                                    <div key={`b${i}`}>
                                                                                        {/* {console.log(this.props.occupancyOfTheDay.length, occupancy, classroom.id)} */}
                                                                                        {occupancy.classroom_id !== classroom.id ? null :

                                                                                            moment.duration(moment(`${occupancy.start_time}`).diff(startOfDay)).asMinutes() === 0 ?
                                                                                                <div>
                                                                                                    {/* THE BELOW CASES ARE FOR TOP ROW OCCUPANCY */}
                                                                                                    {occupancy.room_rental && this.props.tutorListsOfDay ?
                                                                                                        <div className="classBlock" style={{ top: 50, width: (tableWidth / this.props.classroomList.length) - 1, height: (((moment.duration(moment(`${occupancy.end_time}`).diff(moment(`${occupancy.start_time}`))).asMinutes()) / 15) * 50) - 1 }}>
                                                                                                            <div className="classCard" style={{ background: '#000000' }} onClick={() => { this.handleShowIndividualBox(occupancy.id) }}>
                                                                                                                <div className="classInfoContainer">
                                                                                                                    <div className="classInfoTime"> {moment(occupancy.start_time).format('HH:mm')} - {moment(occupancy.end_time).format('HH:mm')} </div>
                                                                                                                    <div className="classInfoGenre">{this.props.language !== "eng" ? "課室租用" : "Rental"}</div>
                                                                                                                    <div className="classInfoCustomer" style={{ color: '#000000' }}>{this.props.language !== "eng" ? "顧客" : "Customer"} : #{occupancy.customer_id} {(this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === occupancy.customer_id)).name_eng ? (this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === occupancy.customer_id)).name_eng : (this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === occupancy.customer_id)).name_chi}</div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                        occupancy.private_class ?
                                                                                                            <div className="classBlock" style={{ top: (((moment.duration(moment(`${occupancy.start_time}`).diff(startOfDay)).asMinutes()) / 15) + 1) * 50, width: (tableWidth / this.props.classroomList.length) - 1, height: (((moment.duration(moment(`${occupancy.end_time}`).diff(moment(`${occupancy.start_time}`))).asMinutes()) / 15) * 50) - 1 }}>
                                                                                                                <div className="classCard" style={{ background: '#555555' }} onClick={() => { this.handleShowIndividualBox(occupancy.id) }}>
                                                                                                                    <div className="classInfoContainer">
                                                                                                                        <div className="classInfoTime"> {moment(occupancy.start_time).format('HH:mm')} - {moment(occupancy.end_time).format('HH:mm')} </div>
                                                                                                                        <div className="classInfoGenre">{this.props.language !== "eng" ? "私人課堂" : "Private"}</div>
                                                                                                                        <div className="classInfoCustomer" style={{ color: '#555555' }}>
                                                                                                                            {this.props.language !== "eng" ? "導師" : "Tutor"}:{(this.props.tutorInfos.find((tutor: any) => tutor.tutor_id === ((this.props.tutorListsOfDay.find((tutorList: any) => tutorList.room_occupancy_detail_id === occupancy.id)).tutor_id))).name_eng} | {this.props.language !== "eng" ? "顧客" : "Customer"} : #{occupancy.customer_id} {(this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === occupancy.customer_id)).name_eng ? (this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === occupancy.customer_id)).name_eng : (this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === occupancy.customer_id)).name_chi}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div className="classBlock" style={{ top: 50, width: (tableWidth / this.props.classroomList.length) - 1, height: (((moment.duration(moment(`${occupancy.end_time}`).diff(moment(`${occupancy.start_time}`))).asMinutes()) / 15) * 50) - 1 }}>
                                                                                                                <div className="classCard" style={{ background: (this.props.genreTypeLists.find((genre: any) => genre.id === occupancy.genre_id)).represent_color }} onClick={() => { this.handleShowIndividualBox(occupancy.id) }}>
                                                                                                                    <div className="classInfoContainer">
                                                                                                                        {
                                                                                                                            !occupancy.mark_attendance_for_group_class ?
                                                                                                                                <div className="finishAttnTickingIndicator" >
                                                                                                                                    <div className="flexer">
                                                                                                                                        <RadioButtonUncheckedIcon /> <div className="attnTickIndicator">{this.props.language !== "eng" ? "無點名" : "N/A"}</div>
                                                                                                                                    </div>
                                                                                                                                    <div className="headcountDisplay">{this.props.language !== "eng" ? "容量" : "CAP"} : {occupancy.participationCount} / {occupancy.capacity ? occupancy.capacity : classroom.capacity}</div>
                                                                                                                                </div>
                                                                                                                                :
                                                                                                                                occupancy.notFullyMarkedAttn ?
                                                                                                                                    <div className="finishAttnTickingIndicator" >
                                                                                                                                        <div className="flexer">
                                                                                                                                            <ErrorOutlineIcon /> <div className="attnTickIndicator">{this.props.language !== "eng" ? "未完成" : "ING"}</div>
                                                                                                                                        </div>
                                                                                                                                        <div className="headcountDisplay">{this.props.language !== "eng" ? "容量" : "CAP"} : {occupancy.participationCount} / {occupancy.capacity ? occupancy.capacity : classroom.capacity}</div>
                                                                                                                                    </div>
                                                                                                                                    :
                                                                                                                                    <div className="finishAttnTickingIndicator" >
                                                                                                                                        <div className="flexer">
                                                                                                                                            <CheckCircleOutlineIcon /> <div className="attnTickIndicator">{this.props.language !== "eng" ? "已完成" : "Ticked"}</div>
                                                                                                                                        </div>
                                                                                                                                        <div className="headcountDisplay">{this.props.language !== "eng" ? "容量" : "CAP"} : {occupancy.participationCount} / {occupancy.capacity ? occupancy.capacity : classroom.capacity}</div>
                                                                                                                                    </div>
                                                                                                                        }
                                                                                                                        <div className="classInfoTime"> {moment(occupancy.start_time).format('HH:mm')} - {moment(occupancy.end_time).format('HH:mm')} </div>
                                                                                                                        <div className="classInfoGenre">{(this.props.genreTypeLists.find((genre: any) => genre.id === occupancy.genre_id)).genre}</div>
                                                                                                                        <div className="classInfoAge">{(this.props.ageGroupLists.find((ageGroup: any) => ageGroup.id === occupancy.age_group_id)).age_group}</div>
                                                                                                                        <div className="classInfoTutor" style={{ color: (this.props.genreTypeLists.find((genre: any) => genre.id === occupancy.genre_id)).represent_color }}>{(this.props.tutorInfos.find((tutor: any) => tutor.tutor_id === ((this.props.tutorListsOfDay.find((tutorList: any) => tutorList.room_occupancy_detail_id === occupancy.id)).tutor_id))).name_eng}</div>
                                                                                                                        {/* <div className="classInfoStudentCount" style={{ color: (this.props.genreTypeLists.find((genre: any) => genre.id === occupancy.genre_id)).represent_color }}>7/10</div> */}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                    }
                                                                                                </div>
                                                                                                :
                                                                                                <div>
                                                                                                    {/* THE BELOW CASES ARE FOR NON-TOP ROW OCCUPANCY */}
                                                                                                    {!this.props.tutorListsOfDay ? null :
                                                                                                        occupancy.room_rental ?
                                                                                                            <div className="classBlock" style={{ top: (((moment.duration(moment(`${occupancy.start_time}`).diff(startOfDay)).asMinutes()) / 15) + 1) * 50, width: (tableWidth / this.props.classroomList.length) - 1, height: (((moment.duration(moment(`${occupancy.end_time}`).diff(moment(`${occupancy.start_time}`))).asMinutes()) / 15) * 50) - 1 }}>
                                                                                                                <div className="classCard" style={{ background: '#000000' }} onClick={() => { this.handleShowIndividualBox(occupancy.id) }}>
                                                                                                                    <div className="classInfoContainer">
                                                                                                                        <div className="classInfoTime"> {moment(occupancy.start_time).format('HH:mm')} - {moment(occupancy.end_time).format('HH:mm')} </div>
                                                                                                                        <div className="classInfoGenre">{this.props.language !== "eng" ? "課室租用" : "Rental"}</div>
                                                                                                                        <div className="classInfoCustomer" style={{ color: '#000000' }}>{this.props.language !== "eng" ? "顧客" : "Customer"}：#{occupancy.customer_id} {(this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === occupancy.customer_id)).name_eng ? (this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === occupancy.customer_id)).name_eng : (this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === occupancy.customer_id)).name_chi}</div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            occupancy.private_class ?
                                                                                                                <div className="classBlock" style={{ top: (((moment.duration(moment(`${occupancy.start_time}`).diff(startOfDay)).asMinutes()) / 15) + 1) * 50, width: (tableWidth / this.props.classroomList.length) - 1, height: (((moment.duration(moment(`${occupancy.end_time}`).diff(moment(`${occupancy.start_time}`))).asMinutes()) / 15) * 50) - 1 }}>
                                                                                                                    <div className="classCard" style={{ background: '#555555' }} onClick={() => { this.handleShowIndividualBox(occupancy.id) }}>
                                                                                                                        <div className="classInfoContainer">
                                                                                                                            <div className="classInfoTime"> {moment(occupancy.start_time).format('HH:mm')} - {moment(occupancy.end_time).format('HH:mm')} </div>
                                                                                                                            <div className="classInfoGenre">{this.props.language !== "eng" ? "私人課堂" : "Private"}</div>
                                                                                                                            <div className="classInfoCustomer" style={{ color: '#555555' }}>
                                                                                                                                {this.props.language !== "eng" ? "導師" : "Tutor"}導師：{(this.props.tutorInfos.find((tutor: any) => tutor.tutor_id === ((this.props.tutorListsOfDay.find((tutorList: any) => tutorList.room_occupancy_detail_id === occupancy.id)).tutor_id))).name_eng} | {this.props.language !== "eng" ? "顧客" : "Customer"}：#{occupancy.customer_id} {(this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === occupancy.customer_id)).name_eng ? (this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === occupancy.customer_id)).name_eng : (this.props.roomRentingPrivateCustomerList.find((customer: any) => customer.id === occupancy.customer_id)).name_chi}
                                                                                                                            </div>                                                                                                            </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                :
                                                                                                                this.props.genreTypeLists.length === 0 ? null :
                                                                                                                    this.props.tutorListsOfDay.length === 0 ? null :
                                                                                                                        <div className="classBlock" style={{ top: (((moment.duration(moment(`${occupancy.start_time}`).diff(startOfDay)).asMinutes()) / 15) + 1) * 50, width: (tableWidth / this.props.classroomList.length) - 1, height: (((moment.duration(moment(`${occupancy.end_time}`).diff(moment(`${occupancy.start_time}`))).asMinutes()) / 15) * 50) - 1 }}>
                                                                                                                            <div className="classCard" style={{ background: (this.props.genreTypeLists.find((genre: any) => genre.id === occupancy.genre_id)).represent_color }} onClick={() => { this.handleShowIndividualBox(occupancy.id) }}>
                                                                                                                                <div className="classInfoContainer">
                                                                                                                                    {
                                                                                                                                        !occupancy.mark_attendance_for_group_class ?
                                                                                                                                            <div className="finishAttnTickingIndicator" >
                                                                                                                                                <div className="flexer">
                                                                                                                                                    <RadioButtonUncheckedIcon /> <div className="attnTickIndicator">{this.props.language !== "eng" ? "無點名" : "N/A"}</div>
                                                                                                                                                </div>
                                                                                                                                                <div className="headcountDisplay">{this.props.language !== "eng" ? "容量" : "CAP"} : {occupancy.participationCount} / {occupancy.capacity ? occupancy.capacity : classroom.capacity}</div>
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            occupancy.notFullyMarkedAttn ?
                                                                                                                                                <div className="finishAttnTickingIndicator" >
                                                                                                                                                    <div className="flexer">
                                                                                                                                                        <ErrorOutlineIcon /> <div className="attnTickIndicator">{this.props.language !== "eng" ? "未完成" : "ING"}</div>
                                                                                                                                                    </div>
                                                                                                                                                    <div className="headcountDisplay">{this.props.language !== "eng" ? "容量" : "CAP"} : {occupancy.participationCount} / {occupancy.capacity ? occupancy.capacity : classroom.capacity}</div>
                                                                                                                                                </div>
                                                                                                                                                :
                                                                                                                                                <div className="finishAttnTickingIndicator" >
                                                                                                                                                    <div className="flexer">
                                                                                                                                                        <CheckCircleOutlineIcon /> <div className="attnTickIndicator">{this.props.language !== "eng" ? "已完成" : "Ticked"}</div>
                                                                                                                                                    </div>
                                                                                                                                                    <div className="headcountDisplay">{this.props.language !== "eng" ? "容量" : "CAP"} : {occupancy.participationCount} / {occupancy.capacity ? occupancy.capacity : classroom.capacity}</div>
                                                                                                                                                </div>
                                                                                                                                    }
                                                                                                                                    <div className="classInfoTime"> {moment(occupancy.start_time).format('HH:mm')} - {moment(occupancy.end_time).format('HH:mm')} </div>
                                                                                                                                    <div className="classInfoGenre">{(this.props.genreTypeLists.find((genre: any) => genre.id === occupancy.genre_id)).genre}</div>
                                                                                                                                    <div className="classInfoAge">{(this.props.ageGroupLists.find((ageGroup: any) => ageGroup.id === occupancy.age_group_id)).age_group}</div>
                                                                                                                                    <div className="classInfoTutor" style={{ color: (this.props.genreTypeLists.find((genre: any) => genre.id === occupancy.genre_id)).represent_color }}>{(this.props.tutorInfos.find((tutor: any) => tutor.tutor_id === ((this.props.tutorListsOfDay.find((tutorList: any) => tutorList.room_occupancy_detail_id === occupancy.id)).tutor_id))).name_eng}</div>
                                                                                                                                    {/* <div className="classInfoStudentCount" style={{ color: (this.props.genreTypeLists.find((genre: any) => genre.id === occupancy.genre_id)).represent_color }}>7/10</div> */}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                        }


                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                        }
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        }
                                                    </div>
                                                }

                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        language: state.auth.language,
        ageGroupLists: state.schedule.ageGroupList,
        genreTypeLists: state.schedule.genreTypeList,
        openingHourList: state.schedule.openingTimeList,
        siteList: state.schedule.siteList,
        classroomList: state.schedule.classroomList,
        occupancyOfTheDay: state.schedule.occupancyOfTheShowingDate,
        cxlOccupancyOfTheDay: state.schedule.cxlOccupancyOfTheShowingDate,
        occupancyOf7Days: state.schedule.occupancyOf7Days,
        tutorInfos: state.schedule.tutorInfos,
        tutorListsOfDay: state.schedule.tutorListsOfDay,
        tutorListsOfDayForCxl: state.schedule.tutorListsOfDayForCxl,
        tutorListsOf7Days: state.schedule.tutorListsOf7Days,
        roomRentingPrivateCustomerList: state.schedule.roomRentingPrivateCustomerList,
        roomRentingPrivateCustomerListForCxl: state.schedule.roomRentingPrivateCustomerListForCxl,
        roomRentingPrivateCustomerListOf7Days: state.schedule.roomRentingPrivateCustomerListOf7Days,
        duplicatedLatestSchedulingGroup: state.schedule.duplicatedLatestSchedulingGroup,
        newOccupancyDetailsTempStorage: state.schedule.newOccupancyDetailsTempStorage,
        cloneWeekID: state.schedule.cloneWeekID,
        loading: state.schedule.loading
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        triggerLoading: () => dispatch(loading()),
        dateChange: async (date: string, siteID: number) => dispatch(await loadOccupancyOfNewDateSelected(date, siteID)),
        scheduleInit: async (siteID: number) => dispatch(await schedulePageInit(siteID)),
        newRoomOccupancySubmission: async (newOccupancy: any, newUniqueSchedulingGroupInfo: any) => dispatch(await newOccupancySubmission(newOccupancy, newUniqueSchedulingGroupInfo)),
        handleUSGConfirmationDeclineClick: () => dispatch(handleUSGConfirmationBoxDeclineClick()),
        handleUSGConfirmationUseUSGClick: async (newOccupancyDetails: any, USGID: number) => dispatch(await newOccupancySubmissionWithUSGConfirmation(newOccupancyDetails, USGID)),
        handleUSGConfirmationNewUSGClick: async (newOccupancyDetails: any, newUniqueSchedulingGroupInfo: any) => dispatch(await newOccupancySubmissionWithNewUSGAfterConfirmation(newOccupancyDetails, newUniqueSchedulingGroupInfo)),
        load7DaysSchedule: async (date: string, siteID: number) => dispatch(await loadOccupancyOf7DaysSelected(date, siteID))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Schedule)


function vw(v: any) {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return (v * w) / 100;
}

export function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};
let clientToken = localStorage.getItem('token');
let tokenPayload: any = {}
if (clientToken) {
    tokenPayload = parseJwt(clientToken)
}