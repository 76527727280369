import { ThunkDispatch } from '../../store';
import { allStockCategoryLoaded , stockCategoryCreated, stockCategoryUpdated, stockCategoryActiveSettingModified} from './actions';

export async function loadStockCategoryData() {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/stockManage/loadAllProductCategories`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(allStockCategoryLoaded(result.allStockCategories));
        }
    }
}
export async function createStockCategory(stockCategoryDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/stockManage/createNewProductCategory`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            stockCategoryDetails: stockCategoryDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(stockCategoryCreated());
        }
    }
}
export async function updateStockCategory(stockCategoryDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/stockManage/updateProductCategoryDetails`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            stockCategoryDetails: stockCategoryDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(stockCategoryUpdated());
        }
    }
}
export async function delStockCategory(stockCategoryID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/stockManage/modifyProductCategoryActiveSetting`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            stockCategoryID: stockCategoryID
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(stockCategoryActiveSettingModified());
        }
    }
}
