import React from 'react';
import './StaffAccManage.css';
import GavelIcon from '@material-ui/icons/Gavel';
import { IRootState, ThunkDispatch } from '../../store';
import { loadAllStaffDetails, staffManagePageInit, submitNewStaffDetails, saveEditedStaffDetails, resetStaffLoginDetails } from './thunks';
import { connect } from 'react-redux';
import { Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import PersonIcon from '@material-ui/icons/Person';
import CreateNewStaffBox from "./CreateNewStaffBox";
import StaffDetailsDisplayBox from './StaffDetailsDisplayBox';
import CircularProgress from '@material-ui/core/CircularProgress';

export interface IStaffAccManageProps {
    pageInit: () => void,
    loadAllStaffDetails: () => void,
    handleCreateNewStaffDetails: (newStaffDetails: any) => void,
    handleSaveStaffDetails: (newStaffDetails: any) => void,
    handleResetStaffLoginDetails: (staffUSerID: number) => void,
    language: string,
    allStaffDetails: any[],
    allEmploymentStatus: any[],
    allSites: any[],
    loading: boolean
}

export interface IStaffAccManageState {
    selectedStaffID: number,
    creatingNewStaff: boolean
}

class StaffAccManage extends React.Component<IStaffAccManageProps, IStaffAccManageState> {
    constructor(props: IStaffAccManageProps) {
        super(props);
        this.state = {
            selectedStaffID: 0,
            creatingNewStaff: false
        }
    }

    private handleCreateNewStaffClick = async () => {
        await this.setState({
            creatingNewStaff: !this.state.creatingNewStaff
        })
    }
    private handleConfirmAddNewStaff = async (newStaffDetails: any) => {
        await this.props.handleCreateNewStaffDetails(newStaffDetails);
        await this.setState({
            creatingNewStaff: false
        })
        setTimeout(async () => await this.props.loadAllStaffDetails(), 1000)
    }
    private handleSelectStaffClick = async (staffID: number | null) => {
        if (staffID) {
            await this.setState({
                selectedStaffID: staffID
            })
        } else {
            await this.setState({
                selectedStaffID: 0
            })
        }
    }

    private handleConfirmSaveStaff = async (newStaffDetails: any) => {
        await this.props.handleSaveStaffDetails(newStaffDetails);
        setTimeout(async () => await this.props.loadAllStaffDetails(), 1000)

    }

    private handleConfirmResetStaffLoginDetails = async (staffUserID: number) => {
        await this.props.handleResetStaffLoginDetails(staffUserID);
    }

    componentWillMount() {

        this.props.pageInit();
    }

    render() {
        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="systemMainDisplayArea row">
                        <div className="systemManagePageTitle col-lg-12"> <GavelIcon id="systemManPageTitleIcon" /> {this.props.language !== "eng" ? "員工帳戶管理" : "Staff Manage"}</div>
                        {
                            !this.state.creatingNewStaff ? null : <CreateNewStaffBox allSites={this.props.allSites} employmentStatusList={this.props.allEmploymentStatus} handleExit={this.handleCreateNewStaffClick} confirmAddNewStaffDetails={(newStaffDetails: any) => this.handleConfirmAddNewStaff(newStaffDetails)} language={this.props.language} />
                        }
                        {
                            this.state.selectedStaffID === 0 ? null : <StaffDetailsDisplayBox loading={this.props.loading} confirmResetStaffLoginDetails={(staffUserID: number) => this.handleConfirmResetStaffLoginDetails(staffUserID)} selectedStaffDetails={this.props.allStaffDetails.find((staffDetails: any) => staffDetails.id === this.state.selectedStaffID)} employmentStatusList={this.props.allEmploymentStatus} allSiteList={this.props.allSites} handleExit={() => this.handleSelectStaffClick(null)} confirmSaveStaffDetails={(newStaffDetails: any) => this.handleConfirmSaveStaff(newStaffDetails)} language={this.props.language} />
                        }
                        <div className="staffListingArea col-lg-12">
                            {
                                this.state.selectedStaffID === 0 ?
                                    <div className="staffListingAreaTitleRow row">
                                        <div className="staffListingAreaTitle col-lg-10 col-sm-8">{this.props.language !== "eng" ? "員工列表" : "Staff List"} <div className="staffListingAreaSubtitle"> {this.props.language !== "eng" ? ` - 共 ${this.props.allStaffDetails.length}位` : ` -  Count ${this.props.allStaffDetails.length}`}</div></div>
                                        <Button id="createStaffBtn" className="col-lg-2 col-sm-4" startIcon={<AddIcon />} onClick={this.handleCreateNewStaffClick}> {this.props.language !== "eng" ? "新增員工" : "New Staff"} </Button>
                                    </div>
                                    :
                                    <div className="staffListingAreaTitleRow row">
                                        <div className="staffListingAreaTitle col-lg-10 col-sm-8">{this.props.language !== "eng" ? "員工列表" : "Staff List"} <div className="staffListingAreaSubtitle"> {this.props.language !== "eng" ? ` - 共 ${this.props.allStaffDetails.length}位` : ` -  Count ${this.props.allStaffDetails.length}`}</div></div>
                                        <Button id="createStaffBtn" className="col-lg-2 col-sm-4" startIcon={<AddIcon />}> {this.props.language !== "eng" ? "新增員工" : "New Staff"} </Button>
                                    </div>
                            }
                            {
                                this.props.loading ?
                                    <div className="loadingContainerRow">
                                        <div className="inlineDisplay">
                                            <div className="loadingContainer">
                                                <CircularProgress id="loadingCircle" />
                                                <div className="loadingLabel">Loading ...</div>
                                            </div>
                                        </div>
                                    </div>
                                    :

                                    this.props.allStaffDetails.map((staff: any) =>
                                        <div className="paymentRecordRow row">
                                            <div className="col-xl-2 col-lg-4 offset-lg-0 col-md-12 offset-md-0 col-sm-12 offset-sm-0 staffIconIDAligner">
                                                <div className="paymentIconContainer ">
                                                    <div className="paymentIconBG">
                                                        <PersonIcon id="paymentListingIcon" />
                                                    </div>
                                                </div>
                                                <div className="staffIDContainer">
                                                    <div className="paymentPurchaseID">{this.props.language !== "eng" ? "員工" : "Staff"}#{staff.id}</div>
                                                    <div className="paymentPurchaseIDTitle">{this.props.language !== "eng" ? "分校" : "Site"} : {(this.props.allSites.find((site: any) => site.id === staff.site_id)).site_key}</div>
                                                </div>
                                            </div>
                                            <div className="paymentPurchaseIDContainer col-xl-2 col-lg-4 offset-lg-0 col-md-6 offset-md-0 col-sm-12 offset-sm-2">
                                                <div className="paymentPurchaseID">{staff.name_eng}</div>
                                                <div className="paymentPurchaseIDTitle">{staff.name_chi}</div>
                                            </div>
                                            <div className="paymentCustomerNameContainer col-xl-2 col-lg-4 offset-lg-0 col-md-6 offset-md-0 col-sm-12 offset-sm-2">
                                                <div className="paymentPurchaseID">{staff.tel}</div>
                                                {/* <div className="paymentPurchaseIDTitle"> {staff.email} </div> */}
                                                <div className="paymentPurchaseIDTitle"> {this.props.language !== "eng"? "聯絡電話" : "Tel"} </div>
                                            </div>
                                            <div className="staffEmploymentDateContainer col-xl-2 offset-xl-0 col-lg-4 offset-lg-4 col-md-6 offset-md-0 col-sm-12 offset-sm-2">
                                                <div className="paymentPurchaseID"> {moment(staff.date_of_employment).format('YYYY-MM-DD')}</div>
                                                <div className="paymentPurchaseIDTitle"> {(this.props.allEmploymentStatus.find((employment: any) => employment.id === staff.employment_status_id)).status}</div>
                                            </div>
                                            <div className="paymentPurchaseIDContainer col-xl-3 col-lg-4 offset-lg-0 col-md-6 offset-md-0 col-sm-12 offset-sm-2">
                                                <div className="paymentPurchaseID">{this.props.language !== "eng" ? "週業額：" : "Weekly Rev."}$ {(staff.weekly_purchase_sum).toFixed(2)}</div>
                                                <div className="paymentTypeIndicator">

                                                    {this.props.language !== "eng" ? "月業額：" : "Monthly Rev."}${(staff.monthly_purchase_sum).toFixed(2)}</div>
                                            </div>
                                            <Button id="paymentDetailsBtn" className="col-xl-1 col-lg-12 col-md-12 col-sm-12" onClick={() => this.handleSelectStaffClick(staff.id)}>。。。</Button>
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state: IRootState) => {
    return {
        allStaffDetails: state.staffAccManage.allStaffDetails,
        allEmploymentStatus: state.staffAccManage.allEmploymentStatus,
        allSites: state.staffAccManage.allSites,
        loading: state.staffAccManage.loading,
        language: state.auth.language
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        pageInit: async () => dispatch(await staffManagePageInit()),
        loadAllStaffDetails: async () => dispatch(await loadAllStaffDetails()),
        handleCreateNewStaffDetails: async (newStaffDetails: any) => dispatch(await submitNewStaffDetails(newStaffDetails)),
        handleSaveStaffDetails: async (newStaffDetails: any) => dispatch(await saveEditedStaffDetails(newStaffDetails)),
        handleResetStaffLoginDetails: async (staffUserID: number) => dispatch(await resetStaffLoginDetails(staffUserID))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffAccManage);