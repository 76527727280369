import React from "react";
import './StockAttributesManage.css'
import { connect } from "react-redux";
import { IRootState, ThunkDispatch } from "../../store";
import { loadStockAttributeData, createStockAttribute, updateStockAttribute, delStockAttribute } from './thunks';
import { Button, TextField } from "@material-ui/core";
import { ChromePicker } from 'react-color';
import EditIcon from '@material-ui/icons/Edit';
// import { classroomDeleteFailedNoticed } from "./actions";
import BeenhereIcon from '@material-ui/icons/Beenhere';
import CircularProgress from '@material-ui/core/CircularProgress';



export interface IStockAttributeManageProps {
    loadAllStockAttributes: () => void,
    updateStockAttributeDetails: (stockAttributeDetails: any) => void,
    createNewStockAttributeDetails: (stockAttributeDetails: any) => void,
    delStockAttributeDetails: (stockAttributeID: number) => void,
    // exitFromNoticeWindow: () => void,
    // updateSiteDetails: (siteDetails:any) => void,
    loading: boolean,
    allStockAttributes: any,
    language: string
    // delClassroomFailed: boolean
}
export interface IStockAttributeManageState {
    selectedStockAttributeID: number,
    selectedStockAttributeDetails: any,
    addNewStockAttribute: boolean,
}
class StockAttributeManage extends React.Component<IStockAttributeManageProps, IStockAttributeManageState> {
    constructor(props: IStockAttributeManageProps) {
        super(props);
        this.state = {
            selectedStockAttributeID: 0,
            selectedStockAttributeDetails: '',
            addNewStockAttribute: false,
        }
    }

    private handleEditStockAttributeClick = async (stockAttributeID: number) => {
        let stockAttributeDetails = this.props.allStockAttributes.filter((stockAttribute: any) => stockAttribute.id === stockAttributeID)
        await this.setState({
            selectedStockAttributeID: stockAttributeID,
            selectedStockAttributeDetails: stockAttributeDetails[0]
        })
    }
    private handleStockAttributeNameInput = (field: string) => async (event: any) => {
        await this.setState({
            selectedStockAttributeDetails: {
                ...this.state.selectedStockAttributeDetails,
                [field]: event.target.value
            }
        })
    }

    private handleSaveStockAttributeDetails = async () => {
        this.props.updateStockAttributeDetails(this.state.selectedStockAttributeDetails);
        setTimeout(async () => await this.props.loadAllStockAttributes(), 1000);
        await this.setState({
            selectedStockAttributeID: 0,
            selectedStockAttributeDetails: ''
        })
    }

    private handleAddNewStockAttributeDetails = async () => {
        await this.setState({
            addNewStockAttribute: true,
            selectedStockAttributeDetails: {
                attribute: '',
                compensate: true,
            }
        })
    }
    private handleSaveNewStockAttributeDetails = async () => {
        if (this.state.selectedStockAttributeDetails.attribute === '') {
            window.alert('請輸入產品特性！')
        } else {
            await this.props.createNewStockAttributeDetails(this.state.selectedStockAttributeDetails);
            await this.setState({
                selectedStockAttributeID: 0,
                selectedStockAttributeDetails: '',
                addNewStockAttribute: false,
            })
            setTimeout(async () => await this.props.loadAllStockAttributes(), 1000);
            // this.props.loadAllStockAttributes();
        }
    }

    private handleDelStockAttributeDetails = async (stockAttributeID: number) => {
        let confirmation = await window.confirm('你確定要刪除此產品特性嗎？');
        if (confirmation) {
            await this.props.delStockAttributeDetails(stockAttributeID);
            this.props.loadAllStockAttributes();
        }
    }


    componentWillMount() {
        if (this.props.allStockAttributes) {
            this.props.loadAllStockAttributes();
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="systemMainDisplayArea">
                        <div className="systemManagePageTitle"> <BeenhereIcon id="systemManPageTitleIcon" /> {this.props.language !== "eng" ? "產品特性管理" : "Stock Attribute Manage"}</div>
                        {
                            this.props.loading ?
                                <div className="loadingContainerRow">
                                    <div className="inlineDisplay">
                                        <div className="loadingContainer">
                                            <CircularProgress id="loadingCircle" />
                                            <div className="loadingLabel">Loading ...</div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="siteDisplayArea">
                                    <div className="siteTitle">{this.props.language !== "eng" ? "產品特性種類" : "Stock Attributes"}</div>
                                    <div>
                                        {//RENDER ALL ACTIVE
                                            this.props.allStockAttributes.map((stockAttribute: any) =>
                                                !stockAttribute.active ? null :
                                                    <div className="classroomRow">
                                                        <div className="classroomNameBox">
                                                            <div className="containerOnSitesMan">
                                                                <div className="inlineDisplay">{this.props.language !== "eng" ? "產品特性" : "Attributes"} : </div>
                                                                {
                                                                    this.state.selectedStockAttributeID !== stockAttribute.id ?
                                                                        <div className="inlineDisplay detailsDisplayField"> {stockAttribute.attribute} </div>
                                                                        :
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedStockAttributeDetails.attribute} onChange={this.handleStockAttributeNameInput("attribute")} />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="classroomNameBox">
                                                            <div className="containerOnSitesMan">
                                                                <div className="inlineDisplay">{this.props.language !== "eng" ? "排列次序" : "Order"} : </div>
                                                                {
                                                                    this.state.selectedStockAttributeID !== stockAttribute.id ?
                                                                        <div className="inlineDisplay detailsDisplayField"> {stockAttribute.ranking} </div>
                                                                        :
                                                                        <TextField type="number" className="detailsEditingInputs" variant="outlined" value={this.state.selectedStockAttributeDetails.ranking} onChange={this.handleStockAttributeNameInput("ranking")} />
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="classroomCapacityBox">
                                                            <div className="containerOnSitesMan">
                                                                <div className="inlineDisplay">{this.props.language !== "eng" ? "狀態" : "State"} :  </div>
                                                                {
                                                                    this.state.selectedStockAttributeID !== stockAttribute.id ?
                                                                        <div className="inlineDisplay detailsDisplayField"> {this.props.language !== "eng" ? "現正生效" : "Active"} </div>
                                                                        :
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.props.language !== "eng" ? "現正生效" : "Active"} disabled />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="editClassroomBtn">
                                                            {
                                                                this.state.selectedStockAttributeID !== stockAttribute.id ?
                                                                    <div className="btnContainer">
                                                                        <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={() => this.handleEditStockAttributeClick(stockAttribute.id)}>{this.props.language !== "eng" ? "修改" : "Edit"}</Button>
                                                                        <Button id="clickToDelClassroomBtn" onClick={() => this.handleDelStockAttributeDetails(stockAttribute.id)}>{this.props.language !== "eng" ? "隱藏產品特性" : "Inactivate "}</Button>
                                                                    </div>
                                                                    :
                                                                    <div className="btnContainer">
                                                                        <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveStockAttributeDetails} >{this.props.language !== "eng" ? "儲存產品特性" : "Save "}</Button>
                                                                    </div>
                                                            }
                                                        </div>

                                                    </div>
                                            )
                                        }
                                        {//RENDER ALL NOT ACTIVE
                                            this.props.allStockAttributes.map((stockAttribute: any) =>
                                                stockAttribute.active ? null :
                                                    <div className="classroomRow">
                                                        <div className="classroomNameBox">
                                                            <div className="containerOnSitesMan">
                                                                <div className="inlineDisplay">{this.props.language !== "eng" ? "產品特性" : "Attributes"} : </div>
                                                                {
                                                                    this.state.selectedStockAttributeID !== stockAttribute.id ?
                                                                        <div className="inlineDisplay detailsDisplayField"> {stockAttribute.attribute} </div>
                                                                        :
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedStockAttributeDetails.attribute} onChange={this.handleStockAttributeNameInput("attribute")} />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="classroomNameBox">
                                                            <div className="containerOnSitesMan">
                                                                <div className="inlineDisplay">{this.props.language !== "eng" ? "排列次序" : "Order"} : </div>
                                                                {
                                                                    this.state.selectedStockAttributeID !== stockAttribute.id ?
                                                                        <div className="inlineDisplay detailsDisplayField"> {stockAttribute.ranking} </div>
                                                                        :
                                                                        <TextField type="number" className="detailsEditingInputs" variant="outlined" value={this.state.selectedStockAttributeDetails.ranking} onChange={this.handleStockAttributeNameInput("ranking")} />
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="classroomCapacityBox">
                                                            <div className="containerOnSitesMan">
                                                                <div className="inlineDisplay">{this.props.language !== "eng" ? "狀態" : "State"} :  </div>
                                                                {
                                                                    this.state.selectedStockAttributeID !== stockAttribute.id ?
                                                                        <div className="inlineDisplay detailsDisplayField"> {this.props.language !== "eng" ? "已隱藏" : "Inactive"} </div>
                                                                        :
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.props.language !== "eng" ? "已隱藏" : "Inactive"} disabled />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="editClassroomBtn">
                                                            {
                                                                this.state.selectedStockAttributeID !== stockAttribute.id ?
                                                                    <div className="btnContainer">
                                                                        <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={() => this.handleEditStockAttributeClick(stockAttribute.id)}>{this.props.language !== "eng" ? "修改" : "Edit"}</Button>
                                                                        <Button id="clickToDelClassroomBtn" onClick={() => this.handleDelStockAttributeDetails(stockAttribute.id)}>{this.props.language !== "eng" ? "回復產品特性" : "Activate "}</Button>
                                                                    </div>
                                                                    :
                                                                    <div className="btnContainer">
                                                                        <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveStockAttributeDetails} >{this.props.language !== "eng" ? "儲存產品特性" : "Save "}</Button>
                                                                    </div>
                                                            }
                                                        </div>

                                                    </div>
                                            )
                                        }

                                    </div>
                                    {
                                        !this.state.addNewStockAttribute ?
                                            <div className="addClassroomRow">
                                                <Button id="clickToAddNewClassroomBtn" onClick={() => this.handleAddNewStockAttributeDetails()}>{this.props.language !== "eng" ? "新增產品特性" : "New Attribute "}</Button>
                                            </div>
                                            :
                                            <div className="classroomRow">
                                                <div className="classroomNameBox">
                                                    <div className="containerOnSitesMan">
                                                        <div className="inlineDisplay">{this.props.language !== "eng" ? "產品特性" : "Attributes"} : </div>
                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedStockAttributeDetails.attribute} onChange={this.handleStockAttributeNameInput("attribute")} />
                                                    </div>
                                                </div>
                                                <div className="classroomNameBox">
                                                    <div className="containerOnSitesMan">
                                                        <div className="inlineDisplay">{this.props.language !== "eng" ? "排列次序" : "Order"} : </div>
                                                        {
                                                            <TextField type="number" className="detailsEditingInputs" variant="outlined" value={this.state.selectedStockAttributeDetails.ranking} onChange={this.handleStockAttributeNameInput("ranking")} />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="classroomCapacityBox">
                                                </div>
                                                <div className="editClassroomBtn">
                                                    <div className="btnContainer">
                                                        <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveNewStockAttributeDetails} >{this.props.language !== "eng" ? "儲存產品特性" : "Save "}</Button>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                        }
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        allStockAttributes: state.stockAttributeManage.allStockAttributes,
        loading: state.stockAttributeManage.loading,
        language: state.auth.language
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        loadAllStockAttributes: async () => dispatch(await loadStockAttributeData()),
        updateStockAttributeDetails: async (stockAttributeDetails: any) => dispatch(await updateStockAttribute(stockAttributeDetails)),
        createNewStockAttributeDetails: async (stockAttributeDetails: any) => dispatch(await createStockAttribute(stockAttributeDetails)),
        delStockAttributeDetails: async (stockAttributeID: number) => dispatch(await delStockAttribute(stockAttributeID)),
        // exitFromNoticeWindow: async () => dispatch(await classroomDeleteFailedNoticed()),
        // updateSiteDetails: async (siteDetails:any) => dispatch(await updateSiteDetails(siteDetails))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StockAttributeManage)
