import {IMassTokenEditManageState} from './state';
import {IMassTokenEditManageActions} from './actions'

const initialState = {
    allMassTokenEditRecords: [],
    loading: true,
    finishingCreateNewMassTokenEditRecord: false
}

export const massTokenEditManageReducer = (state: IMassTokenEditManageState = initialState, actions: IMassTokenEditManageActions) => {
    switch (actions.type) {

        case "ALL_MASS_TOKEN_EDIT_RECORDS_LOADED":
            return {
                ...state,
                loading: false,
                allMassTokenEditRecords: actions.massTokenEditRecords
            }
        case "NEW_MASS_TOKEN_EDIT_RECORD_CREATED":
            return {
                ...state,
                loading: false,
                finishingCreateNewMassTokenEditRecord: true
            }
        case "LOADING":
            return {
                ...state,
                loading: true
            }
        default: 
        return state;
    }
}