import { ThunkDispatch } from '../../store';
import { allDiscountsLoaded, discountCreated, discountUpdated, discountActiveSettingModified} from './actions';

export async function loadDiscountsData() {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/loadAllDiscounts`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(allDiscountsLoaded(result.allDiscounts));
        }
    }
}
export async function createDiscount(discountDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/createNewDiscount`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            discountDetails: discountDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(discountCreated());
        }
    }
}
export async function updateDiscount(discountDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/updateDiscountDetails`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            discountDetails: discountDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(discountUpdated());
        }
    }
}
export async function updateDiscountActiveSetting(discountID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/modifyDiscountActiveSetting`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            discountID: discountID
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(discountActiveSettingModified());
        }
    }
}
