import { ISpecificPurchaseDetailsBoxState } from "./state";
import { ISpecificPurchaseDetailsBoxActions } from "./actions";

const initialState = {
    purchaseDetails: {},
    paymentDetails: {},
    purchasedPackages: [],
    purchasedProducts: [],
    usedDiscounts:[],
    processedTermsList: [],
    loading: false
}

export const specificPurchaseDetailsBoxReducer = (state: ISpecificPurchaseDetailsBoxState = initialState, actions: ISpecificPurchaseDetailsBoxActions) => {

    switch (actions.type) {
        case "SPECIFIC_PURCHASE_DETAILS_LOADED":
            return {
                ...state,
                purchaseDetails: actions.purchaseDetails,
                paymentDetails: actions.paymentDetails,
                purchasedPackages: actions.purchasedPackages,
                purchasedProducts: actions.purchasedProducts,
                usedDiscounts : actions.usedDiscounts,
                processedTermsList: actions.suitableTerms,
                loading: false
            }

        case "LOADING":
            return {
                ...state,
                loading: true
            }
        
            case "UNMOUNT_CLEAR":
                return initialState

        default:
            return state;
    }
}