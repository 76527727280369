import React from "react";
import './AddNewBookingWindow.css'
import { Button, FormControl, InputLabel, Select, MenuItem, TextField } from "@material-ui/core";
import { IRootState, ThunkDispatch } from "../../store";
import { connect } from "react-redux";
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { searchCustomerInfo, loadAvailablePackagesForAddingNewParticipation, loadClassOccupanciesWithSameUSG, createNewClassParticipationDetails } from './thunks';
import { resetAddNewBookingWindow } from './actions';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import moment from "moment";
import { customerGroupingIDUpdated } from "../../Customers/actions";


export interface IAddNewBookingWindowProps {
    // duplicatedLatestSchedulingGroup: any,
    language: string,
    tutorInfos: any,
    ageGroups: any,
    genres: any,
    classroomLists: any,
    openingHourLists: any,
    customerSearchingResult: any[],
    roomOccupancyDetails: any,
    occupancyParticipationDetails: any[],
    availablePackagesOfCustomer: any,
    classOccupancyWithSameUSGID: any[],
    occupancyTutorList: any,
    allSites: any[],
    handleCloseClick: () => void,
    handleResetClick: () => void,
    handleExitClick: () => void,
    // handleProceedWithUSG: () => void,
    // handleProceedWithNewUSG: () => void
    handleCustomerSearch: (searchMethod: string, searchInput: any) => void,
    handleCustomerClick: (customerID: number, ageGroupID: number, genreID: number, siteID: number, previousBookingID: number) => void,
    handleLoadClassOccupanciesWithSameUSGID: (USGID: number, endTime: any, customerID: number) => void,
    handleSubmitNewParticipationDetails: (customerID: number, roomOccupancyIDs: any, staffID: number, packageID: number) => void

}

export interface IAddNewBookingWindowState {
    searchingMethod: string,
    searchingInput: string,
    selectedCustomerID: number,
    selectedPackageID: number,
    selectedPackageDetails: any
    windowNav: string,
    repeatBooking: boolean,
    repeatBookOccupancyIDs: any,
    repeatPreventer: boolean

}

class IAddNewBookingWindow extends React.Component<IAddNewBookingWindowProps, IAddNewBookingWindowState> {
    constructor(props: IAddNewBookingWindowProps) {
        super(props);
        this.state = {
            searchingMethod: 'customerID',
            searchingInput: '',
            selectedCustomerID: 0,
            selectedPackageID: 0,
            selectedPackageDetails: '',
            windowNav: 'searchPage',
            repeatBooking: false,
            repeatBookOccupancyIDs: [],
            repeatPreventer: false
        }
    }


    private handleSearchingMethodChange = async (event: any) => {
        await this.setState({
            searchingMethod: event.target.value
        })
    }

    private handleSearchingInputChange = async (event: any) => {
        await this.setState({
            searchingInput: event.target.value
        })
    }
    private handleSearchEnterPress = async (event: any) => {
        event.preventDefault();
        this.props.handleCustomerSearch(this.state.searchingMethod, this.state.searchingInput);

    }

    private handleSearchClick = async () => {
        this.props.handleCustomerSearch(this.state.searchingMethod, this.state.searchingInput);
    }

    private handleSelectCustomerClick = async (customerID: number) => {
        await this.setState({
            selectedCustomerID: customerID,
            windowNav: 'availablePackageDisplayPage'
        })

        this.props.handleCustomerClick(this.state.selectedCustomerID, this.props.roomOccupancyDetails.age_group_id, this.props.roomOccupancyDetails.genre_id, this.props.roomOccupancyDetails.site_id, (this.props.roomOccupancyDetails.consumed_package_id ? this.props.roomOccupancyDetails.consumed_package_id[0] : 0))
    }

    private handleCloseClick = () => {
        this.props.handleCloseClick();
        this.props.handleResetClick();
    }

    private handleBackToSearchClick = async () => {
        await this.setState({
            windowNav: 'searchPage'
        })
    }

    private handlePackageSelectionClick = async (packageID: number, packageDetails: any) => {
        await this.setState({
            selectedPackageID: packageID,
            selectedPackageDetails: packageDetails
        })

        this.props.handleLoadClassOccupanciesWithSameUSGID(this.props.roomOccupancyDetails.unique_scheduling_group_id, this.props.roomOccupancyDetails.end_time, this.state.selectedCustomerID)
    }

    private handleDeselectPackageClick = async () => {
        await this.setState({
            selectedPackageID: 0,
            selectedPackageDetails: '',
            repeatBooking: false,
            repeatBookOccupancyIDs: []
        })
    }

    private handleDecideRepeatBookingClick = async () => {
        await this.setState({
            repeatBooking: !this.state.repeatBooking,
            repeatBookOccupancyIDs: []
        })
    }

    private handleSelectOccupancyForRepeatBooking = async (occupancy: any) => {
        let newRepeatBookingOccupancyIDs = this.state.repeatBookOccupancyIDs;
        let newSelectedOccupancyQuickData = { id: occupancy.id, full: occupancy.full };
        if (newRepeatBookingOccupancyIDs.find((targetOccupancy: any) => targetOccupancy.id === newSelectedOccupancyQuickData.id)) {
            newRepeatBookingOccupancyIDs = newRepeatBookingOccupancyIDs.filter((quickData: any) => quickData.id !== occupancy.id);
            await this.setState({
                repeatBookOccupancyIDs: newRepeatBookingOccupancyIDs
            })
        } else {
            // let newSelectedOccupancyQuickData = {id: occupancy.id, full:occupancyID.full}
            newRepeatBookingOccupancyIDs.push(newSelectedOccupancyQuickData)
            await this.setState({
                repeatBookOccupancyIDs: newRepeatBookingOccupancyIDs
            })
        }
    }
    private handleSubmitNewParticipationDetails = async () => {
        await this.setState({
            repeatPreventer: true
        });
        let confirmation = true
        let bookingCount = (await this.props.occupancyParticipationDetails.filter((participation: any) => !participation.absent_reason_id)).length
        console.log(bookingCount)
        console.log(this.props.roomOccupancyDetails.capacity)
        if (bookingCount >= (this.props.roomOccupancyDetails.capacity ? this.props.roomOccupancyDetails.capacity : (this.props.classroomLists.find((classroom: any) => classroom.id === this.props.roomOccupancyDetails.classroom_id)).capacity) || this.state.repeatBookOccupancyIDs.find((repeatBookingQuickData: any) => repeatBookingQuickData.full)) {
            confirmation = window.confirm('選擇要預約的課堂已達人數上限，是否要繼續？')
        }
        if (confirmation) {
            let roomOccupancyNeedToBeProcessed = [this.props.roomOccupancyDetails.id];
            for (let quickData of this.state.repeatBookOccupancyIDs) {
                roomOccupancyNeedToBeProcessed.push(quickData.id);
            }
            //GET STAFF ID FROM TOKEN

            let clientToken = localStorage.getItem('token');
            let tokenPayload: any = {}
            if (clientToken) {
                tokenPayload = parseJwt(clientToken)
                console.log(tokenPayload)
            }
            await this.props.handleSubmitNewParticipationDetails(this.state.selectedCustomerID, roomOccupancyNeedToBeProcessed, tokenPayload.id, this.state.selectedPackageID);
            await this.props.handleCloseClick();
            await this.props.handleResetClick();
            await this.props.handleExitClick();
        } else {
            await this.setState({
                repeatPreventer: false
            });
        }
    }

    render() {
        let alreadyInClassResult: any[] = []
        if (this.props.customerSearchingResult.length !== 0 && this.props.occupancyParticipationDetails.length !== 0) {
            for (let customer of this.props.occupancyParticipationDetails) {
                alreadyInClassResult.push(customer.customer_id);
            }

        }
        let selectedCustomerDetails = null
        if (this.props.customerSearchingResult.length !== 0 && this.state.selectedCustomerID !== 0) {
            selectedCustomerDetails = this.props.customerSearchingResult.find((customer: any) =>
                customer.id === this.state.selectedCustomerID
            )
        }
        let gotTokenLeft = true
        if (this.state.selectedPackageDetails.token_based_package && this.state.repeatBookOccupancyIDs.length >= this.state.selectedPackageDetails.token_amount - 1) {
            gotTokenLeft = false
        }

        return (
            <div className="baseAreaOfAddNewBookingWindow">
                <div className="navDisplayRow">
                    {this.state.windowNav === 'searchPage' ?
                        <h3 className="addNewBookingWindowTitle">{this.props.language !== "eng" ? "搜尋顧客" : "Search Customer"}</h3>
                        :
                        <h3 className="addNewBookingWindowTitle">{this.props.language !== "eng" ? "選擇要使用的套券:" : "Choose Target Package for Booking:"}</h3>
                    }
                    <CloseIcon id="addNewBookingWindowCloseBtn" onClick={this.handleCloseClick} />
                </div>
                {this.state.windowNav === 'searchPage' ?
                    <div className="searchRow">
                        <FormControl id="searchOptionInAddNewBookingWindow">
                            <InputLabel >
                                {this.props.language !== "eng" ? "方法" : "Method"}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.searchingMethod}
                                onChange={(e) => this.handleSearchingMethodChange(e)}
                            // labelWidth={labelWidth}
                            >
                                {/* <MenuItem value="">
                                <em>未選</em>
                            </MenuItem> */}
                                <MenuItem value='customerID'>{this.props.language !== "eng" ? "顧客編號 :" : "Customer ID :"}</MenuItem>
                                <MenuItem value='tel'>{this.props.language !== "eng" ? "顧客電話 :" : "TEL :"}</MenuItem>

                            </Select>
                        </FormControl>
                        <FormControl id="searchingInput">
                            <TextField
                                label={this.props.language !== "eng" ? "請輸入..." : "Please Enter..."}
                                value={this.state.searchingInput}
                                onChange={(e) => this.handleSearchingInputChange(e)}
                                onKeyUp={(e) => (e.keyCode === 13 ? this.handleSearchEnterPress(e) : null)}

                            />
                        </FormControl>
                        <div id="searchBtnContainerOnAddNewBookingWindow">
                            <Button startIcon={<SearchIcon />} id="searchBtnOnAddNewBookingWindow" onClick={this.handleSearchClick}>{this.props.language !== "eng" ? "搜尋" : "Search"}</Button>
                        </div>
                    </div>
                    :
                    <div className="searchRow2">

                        <div className="customerResultRow2">
                            {selectedCustomerDetails ?
                                <div className="customerDetailDisplayRow">
                                    <div className="customerDetails customerDetailsID"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "顧客編號 :" : "Customer ID :"}{selectedCustomerDetails.id}</div></div></div>
                                    <div className="customerDetails customerDetailsNAME"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "姓名 :" : "Name :"}{selectedCustomerDetails.name_eng ? selectedCustomerDetails.name_eng : selectedCustomerDetails.name_chi}</div></div></div>
                                    <div className="customerDetails customerDetailsSEX"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "性別 :" : "SEX :"}{selectedCustomerDetails.sex}</div></div></div>
                                    <div className="customerDetails customerDetailsTEL"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "電話 :" : "TEL :"}{selectedCustomerDetails.tel}</div></div></div>
                                </div>
                                : null
                            }
                        </div>
                        <div id="searchBtnContainerOnAddNewBookingWindow">
                            {this.state.windowNav === 'searchPage' ?
                                <Button startIcon={<SearchIcon />} id="searchBtnOnAddNewBookingWindow" onClick={this.handleSearchClick}>{this.props.language !== "eng" ? "搜尋" : "Search"}</Button>
                                :
                                <Button startIcon={<SearchIcon />} id="searchBtnOnAddNewBookingWindow" onClick={this.handleBackToSearchClick}>{this.props.language !== "eng" ? "返回搜尋" : "Back to Search"}</Button>

                            }
                        </div>
                    </div>
                }

                <div className="searchResultTitle">
                    {this.props.language !== "eng" ?
                        this.state.windowNav === 'searchPage' ?
                            '搜尋結果 :'
                            :
                            this.state.selectedPackageID === 0 ?
                                '顧客可用套券 :'
                                :
                                '即將使用套券 :'
                        :
                        this.state.windowNav === 'searchPage' ?
                            'Search Result :'
                            :
                            this.state.selectedPackageID === 0 ?
                                'Usable Package :'
                                :
                                'Using Package :'}

                </div>

                <div className="fixedHeightScrollingAreaOnAddNewBookingWindow">
                    {
                        this.state.windowNav === 'searchPage' ?
                            //SEARCH CUSTOMER RESULT DISPLAY AREA
                            this.props.customerSearchingResult.length === 0 ?
                                <div className="noneRow"> <div>{this.props.language !== "eng" ? "- 無顧客符合搜尋條件 -" : "- No Match Result-"}</div> </div>
                                :
                                alreadyInClassResult.length === 0 ?
                                    //課堂未有booking
                                    this.props.customerSearchingResult.map((customer: any) =>

                                        <div className="customerResultRow">
                                            <div className="customerDetails customerDetailsID"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "顧客編號 :" : "Customer ID :"}{customer.id}</div></div></div>
                                            <div className="customerDetails customerDetailsNAME"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "姓名 :" : "Name :"}{customer.name_eng ? customer.name_eng : customer.name_chi}</div></div></div>
                                            <div className="customerDetails customerDetailsSEX"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "性別 :" : "SEX :"}{customer.sex}</div></div></div>
                                            <div className="customerDetails customerDetailsTEL"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "電話 :" : "TEL :"}{customer.tel}</div></div></div>
                                            <div className="selectBtnContainer">

                                                {
                                                    //即日不計預約count
                                                    moment(this.props.roomOccupancyDetails.start_time).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ?
                                                        <Button id="selectCustomerBtn" onClick={() => this.handleSelectCustomerClick(customer.id)}>{this.props.language !== "eng" ? "選擇顧客" : "Select"}</Button>
                                                        :
                                                        customer.vipPackageOwnership ?
                                                            //是VIP
                                                            (customer.future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count >= 8 && moment(this.props.roomOccupancyDetails.start_time).diff(moment()) > 0 && moment(this.props.roomOccupancyDetails.start_time).diff(moment('2020-05-31 23:59+0800', 'YYYY-MM-DD HH:mmZZ')) > 0 ?
                                                                <Button id="selectCustomerBtn" disabled >{this.props.language !== "eng" ? "已達VIP預約上限" : "MAX BOOKING COUNT"}({(customer.future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count})</Button>
                                                                :
                                                                <Button id="selectCustomerBtn" onClick={() => this.handleSelectCustomerClick(customer.id)}>{this.props.language !== "eng" ? `選擇VIP顧客(${(customer.future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count})` : `Select Customer(${(customer.future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count})`}</Button>

                                                            :
                                                            //不是VIP
                                                            (customer.future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count >= 5 && moment(this.props.roomOccupancyDetails.start_time).diff(moment()) > 0 && moment(this.props.roomOccupancyDetails.start_time).diff(moment('2020-05-31 23:59+0800', 'YYYY-MM-DD HH:mmZZ')) > 0 ?
                                                                <Button id="selectCustomerBtn" disabled >{this.props.language !== "eng" ? "已達預約上限" : "MAX BOOKING COUNT"}({(customer.future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count})</Button>
                                                                :
                                                                <Button id="selectCustomerBtn" onClick={() => this.handleSelectCustomerClick(customer.id)}>{this.props.language !== "eng" ? `選擇顧客(${(customer.future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count})` : `Select Customer(${(customer.future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count})`}</Button>
                                                }
                                            </div>
                                        </div>
                                    )
                                    :

                                    this.props.customerSearchingResult.map((customer: any) =>

                                        <div className="customerResultRow">
                                            <div className="customerDetails customerDetailsID"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "顧客編號 :" : "Customer ID :"}{customer.id}</div></div></div>
                                            <div className="customerDetails customerDetailsNAME"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "姓名 :" : "Name :"}{customer.name_eng ? customer.name_eng : customer.name_chi}</div></div></div>
                                            <div className="customerDetails customerDetailsSEX"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "性別 :" : "SEX :"}{customer.sex}</div></div></div>
                                            <div className="customerDetails customerDetailsTEL"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "電話 :" : "TEL :"}{customer.tel}</div></div></div>
                                            <div className="selectBtnContainer">
                                                {
                                                    alreadyInClassResult.find((inClassCustomerID: any) => inClassCustomerID === customer.id) ?
                                                        //顧客已在課堂
                                                        <Button id="selectCustomerBtn" disabled >{this.props.language !== "eng" ? "已在課堂" : "IN CLASS!"}({(customer.future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count})</Button>
                                                        :
                                                        //顧客不在課堂

                                                        moment(this.props.roomOccupancyDetails.start_time).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ?
                                                            <Button id="selectCustomerBtn" onClick={() => this.handleSelectCustomerClick(customer.id)}>{this.props.language !== "eng" ? "選擇顧客" : "Select"}</Button>
                                                            :
                                                            customer.vipPackageOwnership ?
                                                                //是VIP
                                                                (customer.future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count >= 8 && moment(this.props.roomOccupancyDetails.start_time).diff(moment()) > 0 && moment(this.props.roomOccupancyDetails.start_time).diff(moment('2020-05-31 23:59+0800', 'YYYY-MM-DD HH:mmZZ')) > 0 ?
                                                                    <Button id="selectCustomerBtn" disabled >{this.props.language !== "eng" ? "已達VIP預約上限" : "MAX BOOKING COUNT"}({(customer.future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count})</Button>
                                                                    :
                                                                    <Button id="selectCustomerBtn" onClick={() => this.handleSelectCustomerClick(customer.id)}>{this.props.language !== "eng" ? `選擇VIP顧客(${(customer.future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count})` : `Select Customer(${(customer.future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count})`}</Button>

                                                                :
                                                                //不是VIP
                                                                (customer.future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count >= 5 && moment(this.props.roomOccupancyDetails.start_time).diff(moment()) > 0 && moment(this.props.roomOccupancyDetails.start_time).diff(moment('2020-05-31 23:59+0800', 'YYYY-MM-DD HH:mmZZ')) > 0 ?
                                                                    <Button id="selectCustomerBtn" disabled >{this.props.language !== "eng" ? "已達預約上限" : "MAX BOOKING COUNT"}({(customer.future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count})</Button>
                                                                    :
                                                                    <Button id="selectCustomerBtn" onClick={() => this.handleSelectCustomerClick(customer.id)}>{this.props.language !== "eng" ? `選擇顧客(${(customer.future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count})` : `Select Customer(${(customer.future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count})`}</Button>
                                                }


                                            </div>
                                        </div>
                                    )
                            :
                            //CASE: DISPLAY SELECTED PACKAGE AND BOOKING METHOD
                            this.state.selectedPackageID !== 0 ?
                                <div className="selectedPackagesWindowDisplayArea">
                                    <div className="searchRow2">
                                        <div className="selectedPackageDisplayRow">
                                            <div className="packageDetails packageID"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "課程編號 :" : "Package ID :"}{this.state.selectedPackageDetails.id}</div></div></div>
                                            <div className="packageDetails packageNAME"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "套券名稱 :" : "Package :"}{this.state.selectedPackageDetails.package_name}</div></div></div>
                                            {this.state.selectedPackageDetails.token_based_package ?
                                                <div className="packageDetails packageTOKEN"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{this.state.selectedPackageDetails.token_amount} {this.props.language !== "eng" ? "張" : ""}</div></div></div>
                                                :
                                                <div className="packageDetails packageTOKEN"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "有效未用套券數 : - 張" : "Non Token Based"}</div></div></div>
                                            }

                                            {this.state.selectedPackageDetails.end_date ?
                                                <div className="packageDetails packageENDDATE"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "有效期至:" : "Valid Tru :"}{moment(this.state.selectedPackageDetails.end_date).subtract(1, 'day').format("YYYY-MM-DD")}</div></div></div>
                                                :
                                                <div className="packageDetails packageENDDATE"><div className="detailsAligner"><div className="inline">{this.props.language !== "eng" ? "未啟用" : "NEW"}</div></div></div>

                                            }
                                            <div className="packageDetails"><div className="detailsAligner"><CloseIcon id="deselectPackageBtn" onClick={this.handleDeselectPackageClick} /></div></div>
                                        </div>
                                    </div>
                                    <div className="bookingMethodRow">
                                        {!this.state.repeatBooking ?
                                            <div id="bookingSingleClassBtn"><div className="detailsAligner2"><div>{this.props.language !== "eng" ? "此為一次性操作" : "1-Time Operation"}</div></div></div>
                                            :
                                            <div id="bookingSingleClassBtn2" onClick={this.handleDecideRepeatBookingClick}><div className="detailsAligner2"><div>{this.props.language !== "eng" ? "此為一次性操作" : "1-Time Operation"}</div></div></div>
                                        }
                                        {
                                            this.props.roomOccupancyDetails.private_class ?
                                                <div id="bookingMultipleClassBtn"><div className="detailsAligner2"><div>{this.props.language !== "eng" ? "需要重覆性操作" : "Repeatable"}</div></div></div>
                                                :
                                                this.state.repeatBooking ?
                                                    <div id="bookingMultipleClassBtn"><div className="detailsAligner2"><div>{this.props.language !== "eng" ? "需要重覆性操作" : "Repeatable"}</div></div></div>
                                                    :
                                                    <div id="bookingMultipleClassBtn2" onClick={this.handleDecideRepeatBookingClick}><div className="detailsAligner2"><div>{this.props.language !== "eng" ? "需要重覆性操作" : "Repeatable"}</div></div></div>
                                        }
                                    </div>
                                    <div className="classOccupanciesWithSameUSGDisplay">
                                        {/* CLASS OCCUPANCY WITH SAME USG DISPLAY AREA */}
                                        {
                                            !this.state.repeatBooking ?
                                                null :
                                                this.props.classOccupancyWithSameUSGID.length === 0 ?
                                                    <div className="noneRow"> <div>{this.props.language !== "eng" ? "- 無重覆課堂 -" : "- No Applicable Class -"}</div> </div>
                                                    :
                                                    this.props.classOccupancyWithSameUSGID.map((occupancy: any) =>
                                                        occupancy.customer_in_class ?
                                                            //DISABLED CAUSE CUSTOMER IN CLASS
                                                            <div className="contentBlockOfClassOccupancySameUSG" style={{ background: `rgba(200,200,200,0.9)` }}>
                                                                <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                <div className="alignGenreAgeGroupRow">
                                                                    <div className="listingTitleOfOccupancySameUSG">{(this.props.genres.find((genre: any) => genre.id === occupancy.genre_id)).genre}|</div>
                                                                    <div className="listingTitleOfOccupancySameUSG">{(this.props.ageGroups.find((age: any) => age.id === occupancy.age_group_id)).age_group}</div>
                                                                </div>
                                                                <div className="listingTitleOfOccupancySameUSG2">{this.props.tutorInfos.find((tutorDetail: any) => tutorDetail.tutor_id === this.props.occupancyTutorList.tutor_id).name_eng}</div>
                                                                <div className="listingTitleOfOccupancySameUSG2">{occupancy.head_count} / {occupancy.capacity ? occupancy.capacity : (this.props.classroomLists.find((room: any) => room.id === occupancy.classroom_id)).capacity}</div>

                                                                <div className="listingDetailsOfOccupancySameUSG">{this.props.language !== "eng" ? "日期" : "Date"}: {moment(`${occupancy.start_time}`).format('YYYY-MM-DD')}</div>
                                                                <div className="listingDetailsOfOccupancySameUSG">{this.props.language !== "eng" ? "時間 " : "Time "}: {moment(`${occupancy.start_time}`).format('HH:mm')} - {moment(`${occupancy.end_time}`).format('HH:mm')}</div>
                                                                <div className="listingDetailsOfOccupancySameUSG">{this.props.language !== "eng" ? "顧客已在課堂" : "Customer Booked"}</div>

                                                            </div>
                                                            :
                                                            this.state.repeatBookOccupancyIDs.find((targetOccupancy: any) => targetOccupancy.id === occupancy.id) ?
                                                                //CHOSEN
                                                                <div className="contentBlockOfClassOccupancySameUSG" onClick={() => this.handleSelectOccupancyForRepeatBooking(occupancy)} style={{ background: `${(this.props.genres.find((genre: any) => genre.id === occupancy.genre_id)).represent_color}` }}>
                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                    <div className="alignGenreAgeGroupRow">
                                                                        <div className="listingTitleOfOccupancySameUSG">{(this.props.genres.find((genre: any) => genre.id === occupancy.genre_id)).genre}|</div>
                                                                        <div className="listingTitleOfOccupancySameUSG">{(this.props.ageGroups.find((age: any) => age.id === occupancy.age_group_id)).age_group}</div>
                                                                    </div>
                                                                    <div className="listingTitleOfOccupancySameUSG2">{this.props.tutorInfos.find((tutorDetail: any) => tutorDetail.tutor_id === this.props.occupancyTutorList.tutor_id).name_eng}</div>
                                                                    <div className="listingTitleOfOccupancySameUSG2">{occupancy.head_count} / {occupancy.capacity ? occupancy.capacity : (this.props.classroomLists.find((room: any) => room.id === occupancy.classroom_id)).capacity}</div>

                                                                    <div className="listingDetailsOfOccupancySameUSG">{this.props.language !== "eng" ? "日期" : "Date"} : {moment(`${occupancy.start_time}`).format('YYYY-MM-DD')}</div>
                                                                    <div className="listingDetailsOfOccupancySameUSG">{this.props.language !== "eng" ? "時間 " : "Time "} : {moment(`${occupancy.start_time}`).format('HH:mm')} - {moment(`${occupancy.end_time}`).format('HH:mm')}</div>
                                                                    <div className="listingDetailsOfOccupancySameUSG">- -</div>


                                                                </div>
                                                                :
                                                                gotTokenLeft ?
                                                                    5 - 1 - ((this.props.customerSearchingResult.find((customer: any) => customer.id === this.state.selectedCustomerID)).future_booking_count.find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id)).booking_count - this.state.repeatBookOccupancyIDs.length <= 0 ?
                                                                        //DISABLED CAUSE EXCEED BOOKING LIMIT
                                                                        <div className="contentBlockOfClassOccupancySameUSG" style={{ background: `rgba(200,200,200,0.9)` }}>
                                                                            <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                            <div className="alignGenreAgeGroupRow">
                                                                                <div className="listingTitleOfOccupancySameUSG">{(this.props.genres.find((genre: any) => genre.id === occupancy.genre_id)).genre}|</div>
                                                                                <div className="listingTitleOfOccupancySameUSG">{(this.props.ageGroups.find((age: any) => age.id === occupancy.age_group_id)).age_group}</div>
                                                                            </div>
                                                                            <div className="listingTitleOfOccupancySameUSG2">{this.props.tutorInfos.find((tutorDetail: any) => tutorDetail.tutor_id === this.props.occupancyTutorList.tutor_id).name_eng}</div>
                                                                            <div className="listingTitleOfOccupancySameUSG2">{occupancy.head_count} / {occupancy.capacity ? occupancy.capacity : (this.props.classroomLists.find((room: any) => room.id === occupancy.classroom_id)).capacity}</div>

                                                                            <div className="listingDetailsOfOccupancySameUSG">{this.props.language !== "eng" ? "日期" : "Date"} : {moment(`${occupancy.start_time}`).format('YYYY-MM-DD')}</div>
                                                                            <div className="listingDetailsOfOccupancySameUSG">{this.props.language !== "eng" ? "時間 " : "Time "} : {moment(`${occupancy.start_time}`).format('HH:mm')} - {moment(`${occupancy.end_time}`).format('HH:mm')}</div>
                                                                            <div className="listingDetailsOfOccupancySameUSG">- -</div>


                                                                        </div>
                                                                        :
                                                                        this.state.selectedPackageDetails.end_date ?
                                                                            moment(`${occupancy.start_time}`) <= moment(this.state.selectedPackageDetails.end_date) ?
                                                                                //NON CHOSEN
                                                                                <div className="contentBlockOfClassOccupancySameUSG" onClick={() => this.handleSelectOccupancyForRepeatBooking(occupancy)} >
                                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                    <div className="alignGenreAgeGroupRow">
                                                                                        <div className="listingTitleOfOccupancySameUSG">{(this.props.genres.find((genre: any) => genre.id === occupancy.genre_id)).genre}|</div>
                                                                                        <div className="listingTitleOfOccupancySameUSG">{(this.props.ageGroups.find((age: any) => age.id === occupancy.age_group_id)).age_group}</div>
                                                                                    </div>
                                                                                    <div className="listingTitleOfOccupancySameUSG2">{this.props.tutorInfos.find((tutorDetail: any) => tutorDetail.tutor_id === this.props.occupancyTutorList.tutor_id).name_eng}</div>
                                                                                    <div className="listingTitleOfOccupancySameUSG2">{occupancy.head_count} / {occupancy.capacity ? occupancy.capacity : (this.props.classroomLists.find((room: any) => room.id === occupancy.classroom_id)).capacity}</div>

                                                                                    <div className="listingDetailsOfOccupancySameUSG">{this.props.language !== "eng" ? "日期" : "Date"} : {moment(`${occupancy.start_time}`).format('YYYY-MM-DD')}</div>
                                                                                    <div className="listingDetailsOfOccupancySameUSG">{this.props.language !== "eng" ? "時間 " : "Time "} : {moment(`${occupancy.start_time}`).format('HH:mm')} - {moment(`${occupancy.end_time}`).format('HH:mm')}</div>
                                                                                    <div className="listingDetailsOfOccupancySameUSG">- -</div>


                                                                                </div>
                                                                                :
                                                                                //DISABLED CAUSE EXCEED END DATE
                                                                                <div className="contentBlockOfClassOccupancySameUSG" style={{ background: `rgba(200,200,200,0.9)` }}>
                                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                    <div className="alignGenreAgeGroupRow">
                                                                                        <div className="listingTitleOfOccupancySameUSG">{(this.props.genres.find((genre: any) => genre.id === occupancy.genre_id)).genre}|</div>
                                                                                        <div className="listingTitleOfOccupancySameUSG">{(this.props.ageGroups.find((age: any) => age.id === occupancy.age_group_id)).age_group}</div>
                                                                                    </div>
                                                                                    <div className="listingTitleOfOccupancySameUSG2">{this.props.tutorInfos.find((tutorDetail: any) => tutorDetail.tutor_id === this.props.occupancyTutorList.tutor_id).name_eng}</div>
                                                                                    <div className="listingTitleOfOccupancySameUSG2">{occupancy.head_count} / {occupancy.capacity ? occupancy.capacity : (this.props.classroomLists.find((room: any) => room.id === occupancy.classroom_id)).capacity}</div>

                                                                                    <div className="listingDetailsOfOccupancySameUSG">{this.props.language !== "eng" ? "日期" : "Date"} : {moment(`${occupancy.start_time}`).format('YYYY-MM-DD')}</div>
                                                                                    <div className="listingDetailsOfOccupancySameUSG">{this.props.language !== "eng" ? "時間 " : "Time "} : {moment(`${occupancy.start_time}`).format('HH:mm')} - {moment(`${occupancy.end_time}`).format('HH:mm')}</div>
                                                                                    <div className="listingDetailsOfOccupancySameUSG">- -</div>


                                                                                </div>
                                                                            :
                                                                            moment(`${occupancy.start_time}`) <= moment(this.props.roomOccupancyDetails.start_time).add(this.state.selectedPackageDetails.validate_period, 'day') ?
                                                                                //NON CHOSEN
                                                                                <div className="contentBlockOfClassOccupancySameUSG" onClick={() => this.handleSelectOccupancyForRepeatBooking(occupancy)} >
                                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                    <div className="alignGenreAgeGroupRow">
                                                                                        <div className="listingTitleOfOccupancySameUSG">{(this.props.genres.find((genre: any) => genre.id === occupancy.genre_id)).genre}|</div>
                                                                                        <div className="listingTitleOfOccupancySameUSG">{(this.props.ageGroups.find((age: any) => age.id === occupancy.age_group_id)).age_group}</div>
                                                                                    </div>
                                                                                    <div className="listingTitleOfOccupancySameUSG2">{this.props.tutorInfos.find((tutorDetail: any) => tutorDetail.tutor_id === this.props.occupancyTutorList.tutor_id).name_eng}</div>
                                                                                    <div className="listingTitleOfOccupancySameUSG2">{occupancy.head_count} / {occupancy.capacity ? occupancy.capacity : (this.props.classroomLists.find((room: any) => room.id === occupancy.classroom_id)).capacity}</div>

                                                                                    <div className="listingDetailsOfOccupancySameUSG">{this.props.language !== "eng" ? "日期" : "Date"} : {moment(`${occupancy.start_time}`).format('YYYY-MM-DD')}</div>
                                                                                    <div className="listingDetailsOfOccupancySameUSG">{this.props.language !== "eng" ? "時間 " : "Time "} : {moment(`${occupancy.start_time}`).format('HH:mm')} - {moment(`${occupancy.end_time}`).format('HH:mm')}</div>
                                                                                    <div className="listingDetailsOfOccupancySameUSG">- -</div>


                                                                                </div>
                                                                                :
                                                                                //DISABLED CAUSE EXCEED END DATE
                                                                                <div className="contentBlockOfClassOccupancySameUSG" style={{ background: `rgba(200,200,200,0.9)` }}>
                                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                                    <div className="alignGenreAgeGroupRow">
                                                                                        <div className="listingTitleOfOccupancySameUSG">{(this.props.genres.find((genre: any) => genre.id === occupancy.genre_id)).genre}|</div>
                                                                                        <div className="listingTitleOfOccupancySameUSG">{(this.props.ageGroups.find((age: any) => age.id === occupancy.age_group_id)).age_group}</div>
                                                                                    </div>
                                                                                    <div className="listingTitleOfOccupancySameUSG2">{this.props.tutorInfos.find((tutorDetail: any) => tutorDetail.tutor_id === this.props.occupancyTutorList.tutor_id).name_eng}</div>
                                                                                    <div className="listingTitleOfOccupancySameUSG2">{occupancy.head_count} / {occupancy.capacity ? occupancy.capacity : (this.props.classroomLists.find((room: any) => room.id === occupancy.classroom_id)).capacity}</div>

                                                                                    <div className="listingDetailsOfOccupancySameUSG">{this.props.language !== "eng" ? "日期" : "Date"} : {moment(`${occupancy.start_time}`).format('YYYY-MM-DD')}</div>
                                                                                    <div className="listingDetailsOfOccupancySameUSG">{this.props.language !== "eng" ? "時間 " : "Time "} : {moment(`${occupancy.start_time}`).format('HH:mm')} - {moment(`${occupancy.end_time}`).format('HH:mm')}</div>
                                                                                    <div className="listingDetailsOfOccupancySameUSG">- -</div>


                                                                                </div>
                                                                    :
                                                                    //DISABLED CAUSE RUN OUT OF TOKEN
                                                                    <div className="contentBlockOfClassOccupancySameUSG" style={{ background: `rgba(200,200,200,0.9)` }}>
                                                                        <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                        <div className="alignGenreAgeGroupRow">
                                                                            <div className="listingTitleOfOccupancySameUSG">{(this.props.genres.find((genre: any) => genre.id === occupancy.genre_id)).genre}|</div>
                                                                            <div className="listingTitleOfOccupancySameUSG">{(this.props.ageGroups.find((age: any) => age.id === occupancy.age_group_id)).age_group}</div>
                                                                        </div>
                                                                        <div className="listingTitleOfOccupancySameUSG2">{this.props.tutorInfos.find((tutorDetail: any) => tutorDetail.tutor_id === this.props.occupancyTutorList.tutor_id).name_eng}</div>
                                                                        <div className="listingTitleOfOccupancySameUSG2">{occupancy.head_count} / {occupancy.capacity ? occupancy.capacity : (this.props.classroomLists.find((room: any) => room.id === occupancy.classroom_id)).capacity}</div>

                                                                        <div className="listingDetailsOfOccupancySameUSG">{this.props.language !== "eng" ? "日期" : "Date"} : {moment(`${occupancy.start_time}`).format('YYYY-MM-DD')}</div>
                                                                        <div className="listingDetailsOfOccupancySameUSG">{this.props.language !== "eng" ? "時間 " : "Time "} : {moment(`${occupancy.start_time}`).format('HH:mm')} - {moment(`${occupancy.end_time}`).format('HH:mm')}</div>
                                                                        <div className="listingDetailsOfOccupancySameUSG">- -</div>

                                                                        {/* <div className="listingPackageOfCustomerName">{(this.props.tutorInfos.find((tutor:any)=> tutor.id === occupancy.age_group_id)).age_group}</div> */}

                                                                        {/* <div className="listingPackageOfCustomerContent">有效未用票券數：{eachPackage.token_amount} 張</div>

                                                        <div className="listingPackageOfCustomerContent">有效期至：{moment(eachPackage.end_date).format("YYYY-MM-DD")}</div> */}

                                                                    </div>




                                                    )
                                        }
                                    </div>
                                    <div className="confirmBtnRow">
                                        {
                                            this.state.repeatPreventer ?
                                                <Button id="confirmAddBookingBtn" disabled>{this.props.language !== "eng" ? "處理中" : "Processing"}({this.state.repeatBookOccupancyIDs.length + 1})</Button>
                                                :
                                                <Button id="confirmAddBookingBtn" onClick={this.handleSubmitNewParticipationDetails}>{this.props.language !== "eng" ? "完成預約操作" : "Confirm Booking"}({this.state.repeatBookOccupancyIDs.length + 1})</Button>
                                        }
                                    </div>
                                </div>

                                :
                                //CASE: CUSTOMER AVAILABLE PACKAGES DISPLAY AREA
                                this.props.availablePackagesOfCustomer.unusedPackages.length === 0
                                    && this.props.availablePackagesOfCustomer.usingTokenBasedPackages.length === 0
                                    && this.props.availablePackagesOfCustomer.usingNonTokenBasedPackages.length === 0 ?
                                    <div className="noneRow"> <div>{this.props.language !== "eng" ? "- 此顧客無可用套券 -" : "- No Applicable Package -"}</div> </div>
                                    :

                                    <div >
                                        {/* DISPLAY USING TOKEN PACKAGES */}
                                        {

                                            this.props.availablePackagesOfCustomer.usingTokenBasedPackages.map((eachPackage: any) =>
                                                eachPackage.token_based_package ?
                                                    (this.props.customerSearchingResult.find((customer) => customer.id === this.state.selectedCustomerID)).vipPackageOwnership && ((this.props.customerSearchingResult.find((customer) => customer.id === this.state.selectedCustomerID)).future_booking_count).find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id).booking_count >= 5 ?
                                                        eachPackage.vip_package ?
                                                            //DISPLAY VIP TOKEN PACKAGE
                                                            moment(`${this.props.roomOccupancyDetails.start_time}`).diff(moment(eachPackage.end_date)) <= 0 ?
                                                                moment(`${this.props.roomOccupancyDetails.start_time}`).diff(moment(eachPackage.start_date)) > 0 ?

                                                                    <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>
                                                                        <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                        <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>
                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期" : "Creation Date"}：{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>


                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至:" : "Valid Tru :"}{moment(eachPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期 :" : "Valid Period :"}{moment(eachPackage.end_date).diff(moment(), 'days') + 1} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                                    </div>
                                                                    :
                                                                    <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>
                                                                        <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                        <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>
                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期 :" : "Creation Date :"}{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>


                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至:" : "Valid Tru :"}{moment(eachPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期 :" : "Valid Period :"}{moment(eachPackage.end_date).diff(moment(), 'days') + 1} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                                    </div>
                                                                :
                                                                <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" style={{ background: `rgba(200,200,200,0.7)` }}>
                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                    <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期 :" : "Creation Date :"}{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>


                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至:" : "Valid Tru :"}{moment(eachPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期 :" : "Valid Period :"}{moment(eachPackage.end_date).diff(moment(), 'days') + 1} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>
                                                                </div>
                                                            :
                                                            //NOT TO DISPLAY NON VIP TOKEN PACKAGE
                                                            null
                                                        :
                                                        //DISPLAY NORMAL PACKAGE
                                                        moment(`${this.props.roomOccupancyDetails.start_time}`).diff(moment(eachPackage.end_date)) <= 0 ?
                                                            moment(`${this.props.roomOccupancyDetails.start_time}`).diff(moment(eachPackage.start_date)) > 0 ?

                                                                <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>
                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                    <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期" : "Creation Date"}：{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>


                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至:" : "Valid Tru :"}{moment(eachPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期 :" : "Valid Period :"}{moment(eachPackage.end_date).diff(moment(), 'days') + 1} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                                </div>
                                                                :
                                                                <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>
                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                    <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期 :" : "Creation Date :"}{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>


                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至:" : "Valid Tru :"}{moment(eachPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期 :" : "Valid Period :"}{moment(eachPackage.end_date).diff(moment(), 'days') + 1} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                                </div>
                                                            :
                                                            <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" style={{ background: `rgba(200,200,200,0.7)` }}>
                                                                <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>
                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期 :" : "Creation Date :"}{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>


                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至:" : "Valid Tru :"}{moment(eachPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期 :" : "Valid Period :"}{moment(eachPackage.end_date).diff(moment(), 'days') + 1} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                            </div>
                                                    : null
                                            )
                                        }
                                        {/* DISPLAY USING NON-TOKEN PACKAGES */}
                                        {
                                            this.props.availablePackagesOfCustomer.usingNonTokenBasedPackages.map((eachPackage: any) =>
                                                !eachPackage.token_based_package ?
                                                    (this.props.customerSearchingResult.find((customer) => customer.id === this.state.selectedCustomerID)).vipPackageOwnership && ((this.props.customerSearchingResult.find((customer) => customer.id === this.state.selectedCustomerID)).future_booking_count).find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id).booking_count >= 5 ?
                                                        eachPackage.vip_package ?
                                                            //DISPLAY VIP NON-TOKEN PACKAGE
                                                            moment(`${this.props.roomOccupancyDetails.start_time}`) <= moment(eachPackage.end_date) ?
                                                                moment(`${this.props.roomOccupancyDetails.start_time}`) > moment(eachPackage.start_date) ?

                                                                    <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>
                                                                        <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                        <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"} -</div>
                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期 :" : "Creation Date :"}{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>


                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至:" : "Valid Tru :"}{moment(eachPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期 :" : "Valid Period :"}{moment(eachPackage.end_date).diff(moment(), 'days') + 1} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                                    </div>
                                                                    :
                                                                    <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>
                                                                        <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                        <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"} -</div>
                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期 :" : "Creation Date :"}{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>


                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至:" : "Valid Tru :"}{moment(eachPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期 :" : "Valid Period :"}{moment(eachPackage.end_date).diff(moment(), 'days') + 1} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                                    </div>
                                                                :
                                                                <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" style={{ background: `rgba(200,200,200,0.7)` }}>
                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                    <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期 :" : "Creation Date :"}{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>


                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至:" : "Valid Tru :"}{moment(eachPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期 :" : "Valid Period :"}{moment(eachPackage.end_date).diff(moment(), 'days') + 1} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                                </div>
                                                            :
                                                            //NOT TO DISPLAY NON VIP NON-TOKEN PACKAGE
                                                            null
                                                        :
                                                        //DISPLAY NORMAL NON-TOKEN PACKAGE
                                                        moment(`${this.props.roomOccupancyDetails.start_time}`) <= moment(eachPackage.end_date) ?
                                                            moment(`${this.props.roomOccupancyDetails.start_time}`) > moment(eachPackage.start_date) ?

                                                                <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>
                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                    <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"} -</div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期 :" : "Creation Date :"}{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>


                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至:" : "Valid Tru :"}{moment(eachPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期 :" : "Valid Period :"}{moment(eachPackage.end_date).diff(moment(), 'days') + 1} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                                </div>
                                                                :
                                                                <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>
                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                    <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"} -</div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期 :" : "Creation Date :"}{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>


                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至:" : "Valid Tru :"}{moment(eachPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期 :" : "Valid Period :"}{moment(eachPackage.end_date).diff(moment(), 'days') + 1} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                                </div>
                                                            :
                                                            <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" style={{ background: `rgba(200,200,200,0.7)` }}>
                                                                <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>
                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期 :" : "Creation Date :"}{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>


                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期至:" : "Valid Tru :"}{moment(eachPackage.end_date).subtract(1, 'day').format("YYYY-MM-DD")} </div>
                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效期 :" : "Valid Period :"}{moment(eachPackage.end_date).diff(moment(), 'days') + 1} {this.props.language !== "eng" ? "日" : "Day"}</div>
                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                            </div>
                                                    : null
                                            )
                                        }
                                        {/* DISPLAY UNUSED PACKAGES */}
                                        {this.props.availablePackagesOfCustomer.unusedPackages.map((eachPackage: any) =>
                                            eachPackage.id < 0 ?
                                                this.props.availablePackagesOfCustomer.unusedPackages.length === 1 && this.props.availablePackagesOfCustomer.usingTokenBasedPackages.length === 0 && this.props.availablePackagesOfCustomer.usingNonTokenBasedPackages.length === 0 ?
                                                    //無套券預約
                                                    <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>

                                                        <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                        <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>
                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期 :" : "Creation Date :"}{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>

                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "未使用" : "Not In Use"}</div>
                                                        <div className="listingPackageOfCustomerContent">- -</div>
                                                        <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                    </div>
                                                    : null
                                                :
                                                (this.props.customerSearchingResult.find((customer) => customer.id === this.state.selectedCustomerID)).vipPackageOwnership && ((this.props.customerSearchingResult.find((customer) => customer.id === this.state.selectedCustomerID)).future_booking_count).find((siteBooking: any) => siteBooking.site_id === this.props.roomOccupancyDetails.site_id).booking_count >= 5 ?
                                                    eachPackage.vip_package ?
                                                        eachPackage.validate_period ?
                                                            !eachPackage.token_based_package ?
                                                                //DISPLAY UNUSED VIP NON-TOKEN PACKAGE
                                                                <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>

                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                    <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期 :" : "Creation Date :"}{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>

                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "未使用" : "Not In Use"}</div>
                                                                    <div className="listingPackageOfCustomerContent">- -</div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                                </div>
                                                                :
                                                                //DISPLAY UNUSED VIP TOKEN PACKAGE
                                                                <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>
                                                                    <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                    <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期 :" : "Creation Date :"}{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>

                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "未使用" : "Not In Use"}</div>
                                                                    <div className="listingPackageOfCustomerContent">- -</div>
                                                                    <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                                </div>
                                                            :
                                                            <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>
                                                                <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>
                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期 :" : "Creation Date :"}{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>

                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "未使用" : "Not In Use"}</div>
                                                                <div className="listingPackageOfCustomerContent">- -</div>
                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                            </div>
                                                        :
                                                        //NOT TO DISPLAY NON VIP PACKAGE
                                                        null
                                                    :
                                                    eachPackage.validate_period ?
                                                        !eachPackage.token_based_package ?
                                                            //DISPLAY NORMAL UNUSED NON-TOKEN PACKAGE
                                                            <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>

                                                                <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>
                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期 :" : "Creation Date :"}{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>

                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "未使用" : "Not In Use"}</div>
                                                                <div className="listingPackageOfCustomerContent">- -</div>
                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                            </div>
                                                            :
                                                            //DISPLAY NORMAL UNUSED TOKEN PACKAGE
                                                            <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>
                                                                <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                                <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>
                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期 :" : "Creation Date :"}{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>

                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "未使用" : "Not In Use"}</div>
                                                                <div className="listingPackageOfCustomerContent">- -</div>
                                                                <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                            </div>
                                                        :
                                                        <div className="contentBlockOfClassTokenOfCustomerOnAddBookingWindow" onClick={() => this.handlePackageSelectionClick(eachPackage.id, eachPackage)}>
                                                            <div className="aligner"><PeopleAltIcon id="listingPackageOfCustomerIcon" /></div>
                                                            <div className="listingPackageOfCustomerName">{eachPackage.package_name}</div>

                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "有效未用套券數 :" : "Token Left :"}{eachPackage.token_amount} {this.props.language !== "eng" ? "張" : ""}</div>
                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "創立日期 :" : "Creation Date :"}{moment(eachPackage.created_at).format("YYYY-MM-DD")} {this.props.language !== "eng" ? "日" : ""}</div>

                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "未使用" : "Not In Use"}</div>
                                                            <div className="listingPackageOfCustomerContent">- -</div>
                                                            <div className="listingPackageOfCustomerContent">{this.props.language !== "eng" ? "限用分校 :" : "Site :"}{this.props.language !== "eng" ? eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "不限" : eachPackage.site ? this.props.allSites.find((site: any) => site.id === eachPackage.site).site_key : "Unlimited"} </div>

                                                        </div>
                                        )}
                                    </div>
                    }

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        language: state.auth.language,
        tutorInfos: state.schedule.tutorInfos,
        ageGroups: state.schedule.ageGroupList,
        genres: state.schedule.genreTypeList,
        classroomLists: state.schedule.classroomList,
        openingHourLists: state.schedule.openingTimeList,
        customerSearchingResult: state.addNewBookingWindow.customerSearchingResult,
        availablePackagesOfCustomer: state.addNewBookingWindow.availablePackagesOfCustomer,
        classOccupancyWithSameUSGID: state.addNewBookingWindow.classOccupancyWithSameUSGID
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        handleCustomerSearch: async (searchMethod: string, searchInput: any) => dispatch(await searchCustomerInfo(searchMethod, searchInput)),
        handleResetClick: () => dispatch(resetAddNewBookingWindow()),
        handleCustomerClick: async (customerID: number, ageGroupID: number, genreID: number, siteID: number, previousBookingID: number) => dispatch(await loadAvailablePackagesForAddingNewParticipation(customerID, ageGroupID, genreID, siteID, previousBookingID)),
        handleLoadClassOccupanciesWithSameUSGID: async (USGID: number, endTime: any, customerID: number) => dispatch(await loadClassOccupanciesWithSameUSG(USGID, endTime, customerID)),
        handleSubmitNewParticipationDetails: async (customerID: number, roomOccupancyIDs: any, staffID: number, packageID: number) => dispatch(await createNewClassParticipationDetails(customerID, roomOccupancyIDs, staffID, packageID))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IAddNewBookingWindow);

function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};