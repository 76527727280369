import { IStaffAcLoginSetUpState } from "./state";
import { IStaffAcLoginSetUpActions } from "./actions";

const initialState = {
    staffDetails: [],
    loading: false,
    loginDetailsUpdated: false
}

export const staffAcLoginSetUpReducer = (state: IStaffAcLoginSetUpState = initialState, actions: IStaffAcLoginSetUpActions) => {
    switch (actions.type) {
        case "REQUIRED_STAFF_DETAILS_LOADED":
            return {
                ...state,
                staffDetails: actions.staffDetails,
                loading: false,
                loginDetailsUpdated: false
            }
        case "LOGIN_DETAILS_UPDATED":
            return {
                ...state,
                loading: false,
                loginDetailsUpdated: true
            }
        case "LOGIN_DETAILS_PROCESSING":
            return {
                ...state,
                loading: true,
                loginDetailsUpdated: false
            }

        default:
            return state;
    }
}