import { IIncomeListingState} from './state';
import { IIncomeListingActions} from './actions';

const initialState = {
    allPaymentsWithinPeriod: [],
    allPaymentCustomer: [],
    allSites: [],
    graphData: [],
    loading: true
}

export const incomeListingReducer = (state: IIncomeListingState = initialState , actions: IIncomeListingActions) => {
    switch (actions.type) {
        case "ALL_PAYMENTS_LOADED":
            return {
                ...state,
                allPaymentsWithinPeriod: actions.allPayments,
                allPaymentCustomer: actions.allCustomers,
                allSites: actions.allSites,
                graphData: actions.graphData,
                loading: false
            }
        case "LOADING":
            return {
                ...state,
                loading: true
            }
        default:
            return state
    }
}