import { IAddNewBookingWindowState } from "./state";
import { IAddNewBookingWindowActions } from "./actions";
import { AccordionCollapse } from "react-bootstrap";

const initialState = {
    customerSearchingResult:[],
    availablePackagesOfCustomer: {
        unusedPackages:[],
        usingTokenBasedPackages: [],
        usingNonTokenBasedPackages: [],
    },
    classOccupancyWithSameUSGID: []

}

export const addNewBookingWindowReducer = (state: IAddNewBookingWindowState = initialState, actions: IAddNewBookingWindowActions) => {

    switch (actions.type) {

        case "CUSTOMER_SEARCH_RESULT_LOADED":
            return {
                ...state,
                customerSearchingResult: actions.customerSearchResult
            }
        case "ADD_NEW_BOOKING_WINDOW_RESET":
            return initialState
            
        case "AVAILABLE_PACKAGES_OF_CUSTOMER_LOADED" :
            return {
                ...state,
                availablePackagesOfCustomer: actions.availablePackages
            }
        case "CLASS_OCCUPANCIES_WITH_SAME_USG_LOADED" :
            return {
                ...state,
                classOccupancyWithSameUSGID: actions.classOccupancies
            }
        case "NEW_CLASS_PARTICIPATION_DETAIL_CREATED" :
            return {
                ...state
            }
        
        default:
            return state;
    }
}