import React from "react";
import { IRootState } from "../store";
import { connect } from "react-redux";
import './Tutors.css'
import { Table, Button } from "@material-ui/core";
import MoreIcon from '@material-ui/icons/More';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TutorIndividualBox from "../TutorIndividualBox/TutorIndividualBox";
import TutorAddBox from "../TutorAddBox/TutorAddBox";
import AddIcon from '@material-ui/icons/Add';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import RemoveIcon from '@material-ui/icons/Remove';
import ListIcon from '@material-ui/icons/List';
import { height } from "@material-ui/system";

export interface ITutorsProps {
    allTutorInfo: any,
    employmentStatusLists: any,
    genreTypeLists: any,
    language: string
}
export interface ITutorState {
    showTutorIndividualBox: boolean,
    showTutorAddBox: boolean,
    selectedTutorId: number | null,
    showHideTutor: boolean
}

class Tutors extends React.Component<ITutorsProps, ITutorState> {
    constructor(props: ITutorsProps) {
        super(props);
        this.state = {
            showTutorIndividualBox: false,
            showTutorAddBox: false,
            selectedTutorId: null,
            showHideTutor: false
        }
    }

    private handleDetailsClick = async (tutorId: number) => {
        if (!this.state.selectedTutorId) {

            await this.setState({
                showTutorIndividualBox: !this.state.showTutorIndividualBox,
                selectedTutorId: tutorId
            })
        } else {


            await this.setState({
                showTutorIndividualBox: !this.state.showTutorIndividualBox,
                selectedTutorId: null
            })
        }
    }
    private handleAddTutorClick = async () => {
        this.setState({
            showTutorAddBox: !this.state.showTutorAddBox
        })
    }
    private handleShowHidedTutorClick = async () => {
        this.setState({
            showHideTutor: !this.state.showHideTutor
        })
    }

    render() {
        let displayTutors = []
        if (this.state.showHideTutor) {
            displayTutors = this.props.allTutorInfo.filter((tutor: any) => tutor.mark_delete === true);
        } else {
            displayTutors = this.props.allTutorInfo.filter((tutor: any) => tutor.mark_delete === false);
        }

        return (
            <div className="col-12">


                <div className="row" >

                    <div className="col-12">
                        <div className="row" >
                            <div className="col-12" >
                                <div>
                                    <div className="row" >
                                        <div className="col-12" >
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="tutorSimplifiedHeadCard">
                                                        <h3 id="tableHeading"> 
                                                        {this.props.language !== "eng" ? "導師列表" : "Tutors"}

                                                        </h3>

                                                    </div>
                                                    <div id="functionBarArea row">
                                                        {/* <div id="searchIconAligner">
                                                            <SearchIcon />
                                                        </div> */}
                                                        {/* <InputBase
                                                            placeholder="Search…"
                                                            inputProps={{ 'aria-label': 'search' }}
                                                            className="searchBar"
                                                        /> */}
                                                        {!this.state.showHideTutor ?
                                                            <Button id="newTutorBtn" className="col-6" variant="contained" color="default" startIcon={<ListIcon />} onClick={this.handleShowHidedTutorClick}><div className="customBtn">
                                                                {this.props.language !== "eng" ? "現役導師" : "Active Tutors"}
                                                                </div></Button>
                                                            :
                                                            <Button id="newTutorBtn" className="col-6" variant="contained" color="default" startIcon={<ListIcon />} onClick={this.handleShowHidedTutorClick}><div className="customBtn">
                                                                {this.props.language !== "eng" ? "退役導師" : "Hidden Tutors"}
                                                                </div></Button>
                                                        }
                                                        <Button id="newTutorBtn" className="col-6" variant="contained" color="primary" startIcon={<AddIcon />} onClick={this.handleAddTutorClick}><div className="customBtn">
                                                            {this.props.language !== "eng" ? "新增導師" : "New Tutor"}
                                                            </div></Button>

                                                    </div>
                                                </div>
                                                {/* <div className="col-3 aligner">
                                                <Button id="newTutorBtn" variant="contained" color="primary" startIcon={<AddIcon />} onClick={this.handleAddTutorClick}><div className="customBtn">New Tutor</div></Button>

                                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-12" >
                        {this.state.showTutorIndividualBox && this.state.selectedTutorId ? <TutorIndividualBox handleExit={() => { if (this.state.selectedTutorId) { this.handleDetailsClick(this.state.selectedTutorId) } }} selectedTutorId={this.state.selectedTutorId} /> : null}
                        {this.state.showTutorAddBox ? <TutorAddBox handleAddExit={this.handleAddTutorClick} /> : null}
                        <div className="tutorDisplayArea">
                            <Table >
                                <thead>
                                    <tr className="tutorTableHead row">
                                        <td className="tutorSimInfo col-2">{this.props.language !== "eng" ? "#導師" : "#Tutor"}</td>
                                        <td className="tutorSimInfo  col-3">{this.props.language !== "eng" ? "姓名" : "Name"}</td>
                                        <td className="tutorSimInfo col-1">{this.props.language !== "eng" ? "性別" : "Sex"}</td>
                                        <td className="tutorSimInfo col-3">{this.props.language !== "eng" ? "教授運動種類" : "Sport Type"}</td>
                                        <td className="tutorSimInfo col-3">{this.props.language !== "eng" ? "雇用模式" : "Hiring Type"}</td>
                                    </tr>
                                </thead>
                                <tbody >

                                    {displayTutors.map((tutor: any) =>

                                        <tr className="tutorSimplifiedCard row">
                                            <td className="col-2"><div className="tutorSimInfo lilSpace">{tutor.tutor_id}</div></td>
                                            <td className="tutorSimInfo col-3"><div className="tutorName">{tutor.name_eng}</div></td>
                                            <td className="tutorSimInfo  col-1">{tutor.sex}</td>
                                            <td className="tutorSimInfo col-3">{this.props.language !== "eng" ? `${tutor.genre_id.length} 種` : `${tutor.genre_id.length} Type`}</td>
                                            <td className="tutorSimInfo col-3">{(this.props.employmentStatusLists.find((employmentStatus: any) => employmentStatus.id == tutor.employment_status_id)).status}</td>
                                            <td className="detailsBtn col-6"> <Button variant="contained" className="w-100" color="primary" startIcon={<MoreIcon />} onClick={() => this.handleDetailsClick(tutor.tutor_id)}>{this.props.language !== "eng" ? "詳情" : "Details"}</Button></td>
                                            <td className="payrollBtn col-6"> <Button variant="contained" className="w-100" color="default" startIcon={<ReceiptIcon />} disabled>{this.props.language !== "eng" ? "薪資" : "Payroll"}</Button></td>

                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            <div className="tableEnder">
                                <div className="tableEnderContent">{this.props.language !== "eng" ? `共 ${displayTutors.length} 位導師.` : `${displayTutors.length} Tutors Loaded.`}</div>
                            </div>


                            


                        </div>

                    </div>


                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        allTutorInfo: state.tutors.allTutorInfo,
        employmentStatusLists: state.tutors.employmentStatusLists,
        genreTypeLists: state.tutors.genreTypeList,
        language: state.auth.language

    }
}


export default connect(mapStateToProps, {})(Tutors)