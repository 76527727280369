
import React from "react";
import { IRootState, ThunkDispatch } from "../../store";
import { connect } from "react-redux";
import { loadCustomerPurchasePaymentHistory, resendTargetPurchaseEmailReceiptToCustomer, voidTargetedPreviousPayment, voidTargetedPreviousPurchase } from './thunks'
import './CustomerPurchasePaymentHistory.css'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { Button } from "@material-ui/core";
import AddNewPayment from './AddNewPayment';
import CircularProgress from '@material-ui/core/CircularProgress';
import SpecificPurchaseDetailsBox from "../SpecificPurchaseDetailsBox/SpecificPurchaseDetailsBox";

import moment from "moment";
import { backFromDeclineOfVoidPurchase } from "./actions";

export interface ICustomerPurchasePaymentHistoryProps {
    language: string,
    genreTypesBank: any,
    ageGroupsBank: any,
    customerUserID: number,
    customerPurchasesList: any[],
    customerPayments: any[],
    purchasedPackagesList: any[],
    purchasedProductsList: any[],
    staffId: number,
    showDeclineVoidPurchaseBox: boolean,
    showEmailResentConfirmationBox: boolean,
    loading: boolean,
    loadCustomerPurchasePaymentHistory: (customerUserID: number) => void,
    handleDeleteTargetPayment: (paymentID: number, staffUserID: number) => void,
    handleDeleteTargetPurchase: (purchaseID: number, customerUserID: number) => void,
    handleBackFromVoidDeclination: () => void,
    triggerDisplaySpecificPurchaseDetailsBox : (purchaseID:number) => void,
    handleResendPurchaseEmailToCustomer: (purchaseID:number, customerUserID:number, purchasedProductData: any[])=> void
}

export interface ICustomerPurchasePaymentHistoryState {
    //PAGE DISPLAY TRIGGER
    showPaymentBox: boolean,
    selectedPurchaseID: number,

}

class CustomerPurchasePaymentHistory extends React.Component<ICustomerPurchasePaymentHistoryProps, ICustomerPurchasePaymentHistoryState>{
    constructor(props: ICustomerPurchasePaymentHistoryProps) {
        super(props);
        this.state = {
            showPaymentBox: false,
            selectedPurchaseID: 0,
        }
    }
    private handleSelectPurchaseRecord = async (purchaseID: number) => {
        if (this.state.selectedPurchaseID === purchaseID) {
            await this.setState({
                selectedPurchaseID: 0
            })
        } else {
            await this.setState({
                selectedPurchaseID: purchaseID
            })
        }
    }
    private refreshFromAddPaymentExit = async () => {
        await setTimeout(async () => await this.props.loadCustomerPurchasePaymentHistory(this.props.customerUserID), 1000)
        await this.setState({
            showPaymentBox: false
        })
    }
    private handleEnterAddNewPayment = async () => {
        await this.setState({
            showPaymentBox: true
        })
    }
    private handleDelTargetPayment = async (paymentID: number) => {
        let confirmation = window.confirm('你確定要 VOID 此付款單嗎？')
        if (confirmation) {
            await this.props.handleDeleteTargetPayment(paymentID, this.props.staffId);
            await setTimeout(async () => await this.props.loadCustomerPurchasePaymentHistory(this.props.customerUserID), 1000)
        }

    }

    private handleDelTargetPurchase = async (purchaseID: number) => {
        let confirmation = window.confirm('你確定要 VOID 此選購單嗎？')
        if (confirmation) {
            await this.props.handleDeleteTargetPurchase(purchaseID, this.props.customerUserID);
            await setTimeout(async () => await this.props.loadCustomerPurchasePaymentHistory(this.props.customerUserID), 1000)

        }
    }
    private handleBackFromDeclineVoidBtn = async () => {
        this.props.handleBackFromVoidDeclination();
    }

    private handleTriggerSpecificPurchaseDetailsBox = async (purchaseID:number) => {
        this.props.triggerDisplaySpecificPurchaseDetailsBox(purchaseID);
        // await this.setState({
        //     showSpecificPurchaseDetailsBox: !this.state.showSpecificPurchaseDetailsBox
        // })
    }
    private handleResendPurchaseEmailReceiptClick = async (purchaseID:number) => {
        let targetPurchaseDetails = await this.props.customerPurchasesList.find((purchase:any)=> purchase.id === purchaseID);
        let purchasedProductDetails = [];
        if(targetPurchaseDetails.purchased_product_ids){
            for(let productID of targetPurchaseDetails.purchased_product_ids){
                let targetProductDetails = await this.props.purchasedProductsList.find((product:any)=>product.id === productID);
                purchasedProductDetails.push(targetProductDetails);
            }
        }
        this.props.handleResendPurchaseEmailToCustomer(purchaseID, this.props.customerUserID, purchasedProductDetails);
    }


    async componentWillMount() {
        await this.props.loadCustomerPurchasePaymentHistory(this.props.customerUserID)
        if (this.props.customerPurchasesList.length !== 0) {
            await this.setState({
                selectedPurchaseID: this.props.customerPurchasesList[0].id
            })
        }
    }
    render() {
        return (
            <div className="row">
                <div className="col-12">
                    {!this.props.showDeclineVoidPurchaseBox ?
                        null :
                        <div className="declineVoidPurchaseBox">
                            <div className="noticeRow">
                                <div className="declineVoidPurchaseNotice">
                                    {this.props.language !== "eng"? "此選購單的內容已啟用 ! 無法進行VOID操作 !" : "Package/Products Already Used. VOID Prohibited !"}
                            </div>
                            </div>
                            <div className="BtnRow">
                                <Button id="backFromDeclineBtn" onClick={this.handleBackFromDeclineVoidBtn}>{this.props.language !== "eng"? "取消該操作" : "Abandon Void"}</Button>
                            </div>
                        </div>
                    }
                    
                    {this.state.showPaymentBox ?
                        <AddNewPayment customerUserID={this.props.customerUserID} loadCustomerPurchasePaymentHistory={this.props.loadCustomerPurchasePaymentHistory} selectedPurchaseID={this.state.selectedPurchaseID} handleExit={this.refreshFromAddPaymentExit} />
                        :
                        <div className="contentFixSizePackagesBlock">
                            <div className="blockTitleRow2">

                                <h3 className="blockTitle">{this.props.language !== "eng"? "顧客過往消費紀錄" : "Customer Purchase Histories"}</h3>
                            </div>
                            <div className="purchaseHistoryWindow">
                                <div className="listHeadRow">
                                    <div className="inlineDisplay purchaseRecord_IDTitle">{this.props.language !== "eng"? "選購編號" : "Purchase ID"}#</div>
                                    <div className="inlineDisplay purchaseRecord_PackageSummaryTitle">{this.props.language !== "eng"? "內容撮要" : "Summary"}</div>
                                    <div className="inlineDisplay purchaseRecord_AmountPayableTitle">{this.props.language !== "eng"? "選購總值" : "Grand-Total"}</div>
                                    <div className="inlineDisplay purchaseRecord_PurchaseStatusTitle">{this.props.language !== "eng"? "付款狀態" : "Payment"}</div>
                                    <div className="inlineDisplay purchaseRecord_ProcessPurchaseStaffIDTitle">{this.props.language !== "eng"? "員工編號" : "Staff"}</div>
                                    <div className="inlineDisplay purchaseRecord_CreateDateTitle">{this.props.language !== "eng"? "選購日期" : "Purchase Date"}</div>

                                </div>
                                {
                                    this.props.loading ?
                                        <div className="noPurchaseRow">
                                            <div className="loadingContainerRow">
                                                <div className="inlineDisplay">
                                                    <div className="loadingContainer">
                                                        <CircularProgress id="loadingCircle" />
                                                        <div className="loadingLabel">Loading ...</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        this.props.customerPurchasesList.length === 0 ?
                                            <div className="noPurchaseRow">
                                                <div className="noPurchaseNotice"> - {this.props.language !== "eng"? "目前無選購紀錄" : "No Applicable Purchase History"} - </div>
                                            </div>

                                            :

                                            this.props.customerPurchasesList.map((purchase: any) =>

                                                <div className="purchaseRecordRow" >
                                                    {
                                                        this.state.selectedPurchaseID === purchase.id ?
                                                            //EXTEND ROW
                                                            purchase.void ?
                                                                //VOID PURCHASE ROW
                                                                <div>
                                                                    <div className="PurchaseRecordSimplifiedRow" onClick={() => this.handleSelectPurchaseRecord(purchase.id)}>
                                                                        <ExpandMoreIcon id="PurchaseHistoryRetractRowVoidIcon" />
                                                                        <div className="inlineDisplay purchaseRecord_ID">{purchase.id}</div>
                                                                        {
                                                                                purchase.purchased_package_ids ?
                                                                                    <div className="inlineDisplay purchaseRecord_PackageSummary">{' | ' + purchase.purchased_package_ids.map((purchasedPackage: any) => (this.props.purchasedPackagesList.filter((packageInfo: any) => packageInfo.id === purchasedPackage))[0].package_name + ' | ')}</div>
                                                                                    :
                                                                                    <div className="inlineDisplay purchaseRecord_PackageSummary">{' | ' + purchase.purchased_product_ids.map((purchasedProduct: any) => (this.props.purchasedProductsList.filter((productInfo: any) => productInfo.id === purchasedProduct))[0].product_name + ' | ')}</div>
                                                                            }
                                                                        <div className="inlineDisplay purchaseRecord_AmountPayable">$ {purchase.amount_payable}</div>

                                                                        <div className="inlineDisplay purchaseRecord_PurchaseStatusVoided">{this.props.language !== "eng"? "已 VOID" : "VOIDED"} </div>

                                                                        <div className="inlineDisplay purchaseRecord_ProcessPurchaseStaffID">{purchase.process_staff_id}</div>
                                                                        <div className="inlineDisplay purchaseRecord_CreateDate">{moment(`${purchase.created_at}`).format('YYYY/MM/DD hh:mm a')}</div>
                                                                    </div>
                                                                    <div className="paymentSumUpSection">
                                                                        <div className="paymentSumUpTopRow">
                                                                            <div className="paymentSumUpTitle">
                                                                                * {this.props.language !== "eng"? "此選購付款詳情" : "Payment Details of Target Purchase"}
                                                                        </div>
                                                                            <div className="paymentSumUpCount">
                                                                                {
                                                                                    this.props.language ===  "chi" ? 
                                                                                    `- 共 ${(this.props.customerPayments.filter((payment: any) => payment.purchase_id === purchase.id)).length} 則`
                                                                                    :
                                                                                    `- Count $${(this.props.customerPayments.filter((payment: any) => payment.purchase_id === purchase.id)).length}`
                                                                                }
                                                                        </div>
                                                                        </div>
                                                                        <div className="paymentTitleRow">
                                                                            <div className="inlineDisplay paymentIDTitle">{this.props.language !== "eng"? "付款編號" : "Payment ID"}#</div>
                                                                            <div className="inlineDisplay paymentProcessStaffTitle">{this.props.language !== "eng"? "員工編號" : "Staff"}#</div>
                                                                            <div className="inlineDisplay paymentMethodTitle">{this.props.language !== "eng"? "付款方式" : "Payment Method"}</div>
                                                                            <div className="inlineDisplay paymentAmountTitle">{this.props.language !== "eng"? "付款額" : "Payment Amount"}</div>
                                                                            <div className="inlineDisplay paymentDateTitle">{this.props.language !== "eng"? "付款日期" : "Payment Date"}</div>
                                                                        </div>
                                                                        <div className="fixHeightDisplayPaymentWindow">
                                                                            {
                                                                                this.props.customerPayments.map((payment: any) =>
                                                                                    payment.purchase_id === purchase.id ?
                                                                                        payment.void ?
                                                                                            <div className="paymentRecordSimplifiedRow">
                                                                                                <ReceiptIcon />
                                                                                                <div className="inlineDisplay paymentID">{payment.id}</div>
                                                                                                <div className="inlineDisplay paymentProcessStaff">{payment.process_staff_id}</div>
                                                                                                <div className="inlineDisplay paymentMethod">{payment.payment_method}</div>
                                                                                                <div className="inlineDisplay paymentAmount">${payment.amount_received}</div>
                                                                                                <div className="inlineDisplay paymentDate">{moment(`${payment.created_at}`).format('YYYY/MM/DD hh:mm a')}</div>
                                                                                                <Button id="managePaymentVoidedBtn" disabled onClick={() => this.handleDelTargetPayment(payment.id)}>{this.props.language !== "eng"? "已 VOID" : "VOIDED"}</Button>
                                                                                            </div>
                                                                                            :
                                                                                            <div className="paymentRecordSimplifiedRow">
                                                                                                <ReceiptIcon />
                                                                                                <div className="inlineDisplay paymentID">{payment.id}</div>
                                                                                                <div className="inlineDisplay paymentProcessStaff">{payment.process_staff_id}</div>
                                                                                                <div className="inlineDisplay paymentMethod">{payment.payment_method}</div>
                                                                                                <div className="inlineDisplay paymentAmount">${payment.amount_received}</div>
                                                                                                <div className="inlineDisplay paymentDate">{moment(`${payment.created_at}`).format('YYYY/MM/DD hh:mm a')}</div>
                                                                                                <Button id="managePaymentBtn" onClick={() => this.handleDelTargetPayment(payment.id)}>{this.props.language !== "eng"? "VOID 此付款單" : "VOID Payment"}</Button>
                                                                                            </div>
                                                                                        : null
                                                                                )
                                                                            }


                                                                        </div>
                                                                        <div className="managePurchaseBtnRow"><Button id="managePurchaseBtn" onClick={()=>this.handleTriggerSpecificPurchaseDetailsBox(purchase.id)}>{this.props.language !== "eng"? "查看此選購詳情" : "Purchase Details"}</Button><Button id="managePurchaseVoidedBtn" disabled>{this.props.language !== "eng"? "已VOID此選購" : "VOIDED Purchase"}</Button></div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                purchase.fully_paid ?
                                                                    //FULLY PAID ROW
                                                                    <div>
                                                                        <div className="PurchaseRecordSimplifiedRow" onClick={() => this.handleSelectPurchaseRecord(purchase.id)}>
                                                                            <ExpandMoreIcon id="PurchaseHistoryRetractRowPaidIcon" />
                                                                            <div className="inlineDisplay purchaseRecord_ID">{purchase.id}</div>
                                                                            {
                                                                                purchase.purchased_package_ids ?
                                                                                    <div className="inlineDisplay purchaseRecord_PackageSummary">{' | ' + purchase.purchased_package_ids.map((purchasedPackage: any) => (this.props.purchasedPackagesList.filter((packageInfo: any) => packageInfo.id === purchasedPackage))[0].package_name + ' | ')}</div>
                                                                                    :
                                                                                    <div className="inlineDisplay purchaseRecord_PackageSummary">{' | ' + purchase.purchased_product_ids.map((purchasedProduct: any) => (this.props.purchasedProductsList.filter((productInfo: any) => productInfo.id === purchasedProduct))[0].product_name + ' | ')}</div>
                                                                            }
                                                                            <div className="inlineDisplay purchaseRecord_AmountPayable">$ {purchase.amount_payable}</div>
                                                                            {purchase.fully_paid ?
                                                                                <div className="inlineDisplay purchaseRecord_PurchaseStatus">{this.props.language !== "eng"? "已完成付款" : "Clear"} </div> :
                                                                                <div className="inlineDisplay purchaseRecord_PurchaseUnpaidStatus">{this.props.language !== "eng"? "未完成付款" : "Not Clear"}</div>
                                                                            }
                                                                            <div className="inlineDisplay purchaseRecord_ProcessPurchaseStaffID">{purchase.process_staff_id}</div>
                                                                            <div className="inlineDisplay purchaseRecord_CreateDate">{moment(`${purchase.created_at}`).format('YYYY/MM/DD hh:mm a')}</div>
                                                                        </div>
                                                                        <div className="paymentSumUpSection">
                                                                            <div className="paymentSumUpTopRow">
                                                                                <div className="paymentSumUpTitle">
                                                                                    * {this.props.language !== "eng"? "此選購付款詳情" : "Payment Details of Target Purchase"}
                                                                        </div>
                                                                                <div className="paymentSumUpCount">
                                                                                    {
                                                                                        this.props.language !== "eng" ?
                                                                                        `- 共 ${(this.props.customerPayments.filter((payment: any) => payment.purchase_id === purchase.id)).length} 則`
                                                                                        :
                                                                                        ` - Count ${(this.props.customerPayments.filter((payment: any) => payment.purchase_id === purchase.id)).length}`
                                                                                    }
                                                                        </div>
                                                                            </div>
                                                                            <div className="paymentTitleRow">
                                                                                <div className="inlineDisplay paymentIDTitle">{this.props.language !== "eng"? "付款編號" : "Payment ID"}#</div>
                                                                                <div className="inlineDisplay paymentProcessStaffTitle">{this.props.language !== "eng"? "員工編號" : "Staff"}#</div>
                                                                                <div className="inlineDisplay paymentMethodTitle">{this.props.language !== "eng"? "付款方式" : "Payment Method"}</div>
                                                                                <div className="inlineDisplay paymentAmountTitle">{this.props.language !== "eng"? "付款額" : "Payment Amount"}</div>
                                                                                <div className="inlineDisplay paymentDateTitle">{this.props.language !== "eng"? "付款日期" : "Payment Date"}</div>
                                                                            </div>
                                                                            <div className="fixHeightDisplayPaymentWindow">
                                                                                {
                                                                                    this.props.customerPayments.map((payment: any) =>
                                                                                        payment.purchase_id === purchase.id ?
                                                                                            payment.void ?
                                                                                                <div className="paymentRecordSimplifiedRow">
                                                                                                    <ReceiptIcon />
                                                                                                    <div className="inlineDisplay paymentID">{payment.id}</div>
                                                                                                    <div className="inlineDisplay paymentProcessStaff">{payment.process_staff_id}</div>
                                                                                                    <div className="inlineDisplay paymentMethod">{payment.payment_method}</div>
                                                                                                    <div className="inlineDisplay paymentAmount">${payment.amount_received}</div>
                                                                                                    <div className="inlineDisplay paymentDate">{moment(`${payment.created_at}`).format('YYYY/MM/DD hh:mm a')}</div>
                                                                                                    <Button id="managePaymentVoidedBtn" disabled onClick={() => this.handleDelTargetPayment(payment.id)}>{this.props.language !== "eng"? "已 VOID" : "VOIDED"}</Button>
                                                                                                </div>
                                                                                                :
                                                                                                <div className="paymentRecordSimplifiedRow">
                                                                                                    <ReceiptIcon />
                                                                                                    <div className="inlineDisplay paymentID">{payment.id}</div>
                                                                                                    <div className="inlineDisplay paymentProcessStaff">{payment.process_staff_id}</div>
                                                                                                    <div className="inlineDisplay paymentMethod">{payment.payment_method}</div>
                                                                                                    <div className="inlineDisplay paymentAmount">${payment.amount_received}</div>
                                                                                                    <div className="inlineDisplay paymentDate">{moment(`${payment.created_at}`).format('YYYY/MM/DD hh:mm a')}</div>
                                                                                                    <Button id="managePaymentBtn" onClick={() => this.handleDelTargetPayment(payment.id)}>{this.props.language !== "eng"? "VOID 此付款單" : "VOID Payment"}</Button>
                                                                                                </div>
                                                                                            : null
                                                                                    )
                                                                                }


                                                                            </div>
                                                                            <div className="managePurchaseBtnRow">
                                                                                <Button id="managePurchaseBtn" onClick={()=>this.handleResendPurchaseEmailReceiptClick(purchase.id)}>{this.props.language !== "eng"? "重新發送電子收據" : "Re-Send Email"}</Button>
                                                                                <Button id="managePurchaseBtn" onClick={()=>this.handleTriggerSpecificPurchaseDetailsBox(purchase.id)}>{this.props.language !== "eng"? "查看此選購詳情" : "Purchase Details"}</Button>
                                                                                <Button id="managePurchaseBtn" onClick={() => this.handleDelTargetPurchase(purchase.id)}>{this.props.language !== "eng"? "VOID 此選購" : "VOID Purchase"}</Button></div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    //NOT FULLY PAID ROW
                                                                    <div>
                                                                        <div className="PurchaseRecordSimplifiedRow" onClick={() => this.handleSelectPurchaseRecord(purchase.id)}>
                                                                            <ExpandMoreIcon id="PurchaseHistoryRetractRowUnPaidIcon" />
                                                                            <div className="inlineDisplay purchaseRecord_ID">{purchase.id}</div>
                                                                            {
                                                                                purchase.purchased_package_ids ?
                                                                                    <div className="inlineDisplay purchaseRecord_PackageSummary">{' | ' + purchase.purchased_package_ids.map((purchasedPackage: any) => (this.props.purchasedPackagesList.filter((packageInfo: any) => packageInfo.id === purchasedPackage))[0].package_name + ' | ')}</div>
                                                                                    :
                                                                                    <div className="inlineDisplay purchaseRecord_PackageSummary">{' | ' + purchase.purchased_product_ids.map((purchasedProduct: any) => (this.props.purchasedProductsList.filter((productInfo: any) => productInfo.id === purchasedProduct))[0].product_name + ' | ')}</div>
                                                                            }
                                                                            <div className="inlineDisplay purchaseRecord_AmountPayable">$ {purchase.amount_payable}</div>
                                                                            {purchase.fully_paid ?
                                                                                <div className="inlineDisplay purchaseRecord_PurchaseStatus">{this.props.language !== "eng"? "已完成付款" : "Clear"} </div> :
                                                                                <div className="inlineDisplay purchaseRecord_PurchaseUnpaidStatus">{this.props.language !== "eng"? "未完成付款" : "Not Clear"}</div>
                                                                            }
                                                                            <div className="inlineDisplay purchaseRecord_ProcessPurchaseStaffID">{purchase.process_staff_id}</div>
                                                                            <div className="inlineDisplay purchaseRecord_CreateDate">{moment(`${purchase.created_at}`).format('YYYY/MM/DD hh:mm a')}</div>
                                                                        </div>
                                                                        <div className="paymentSumUpSection">
                                                                            <div className="paymentSumUpTopRow">
                                                                                <div className="paymentSumUpTitle">
                                                                                    * {this.props.language !== "eng"? "此選購付款詳情" : "Payment Details of Target Purchase"}
                                                                        </div>
                                                                                <div className="paymentSumUpCount">
                                                                                    {
                                                                                        this.props.language !== "eng" ?
                                                                                        `- 共 ${(this.props.customerPayments.filter((payment: any) => payment.purchase_id === purchase.id)).length} 則`
                                                                                        :
                                                                                        `- Count ${(this.props.customerPayments.filter((payment: any) => payment.purchase_id === purchase.id)).length}`
                                                                                    }
                                                                        </div>
                                                                            </div>
                                                                            <div className="paymentTitleRow">
                                                                                <div className="inlineDisplay paymentIDTitle">{this.props.language !== "eng"? "付款編號" : "Payment ID"}#</div>
                                                                                <div className="inlineDisplay paymentProcessStaffTitle">{this.props.language !== "eng"? "員工編號" : "Staff"}#</div>
                                                                                <div className="inlineDisplay paymentMethodTitle">{this.props.language !== "eng"? "付款方式" : "Payment Method"}</div>
                                                                                <div className="inlineDisplay paymentAmountTitle">{this.props.language !== "eng"? "付款額" : "Payment Amount"}</div>
                                                                                <div className="inlineDisplay paymentDateTitle">{this.props.language !== "eng"? "付款日期" : "Payment Date"}</div>
                                                                            </div>
                                                                            <div className="fixHeightDisplayPaymentWindow">

                                                                                {
                                                                                    !purchase.hasPayment ?
                                                                                        <div className="noPaymentRecordRow">
                                                                                            <div className="noPayment">
                                                                                                <div className="noPaymentDescription">
                                                                                                    - {this.props.language !== "eng"? "目前無付款紀錄" : "No Applicable Payment Record"} -
                                                                            </div>
                                                                                                <Button id="clickToChooseNewTermsBtn" onClick={this.handleEnterAddNewPayment}>
                                                                                                    {this.props.language !== "eng"? "新增付款" : "New Payment"}
                                                                            </Button>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        this.props.customerPayments.map((payment: any) =>
                                                                                            payment.purchase_id === purchase.id ?
                                                                                                payment.void ?
                                                                                                    <div className="paymentRecordSimplifiedRow">
                                                                                                        <ReceiptIcon />
                                                                                                        <div className="inlineDisplay paymentID">{payment.id}</div>
                                                                                                        <div className="inlineDisplay paymentProcessStaff">{payment.process_staff_id}</div>
                                                                                                        <div className="inlineDisplay paymentMethod">{payment.payment_method}</div>
                                                                                                        <div className="inlineDisplay paymentAmount">${payment.amount_received}</div>
                                                                                                        <div className="inlineDisplay paymentDate">{moment(`${payment.created_at}`).format('YYYY/MM/DD hh:mm a')}</div>
                                                                                                        <Button id="managePaymentVoidedBtn" disabled onClick={() => this.handleDelTargetPayment(payment.id)}>{this.props.language !== "eng"? "已 VOID" : "VOIDED"}</Button>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="paymentRecordSimplifiedRow">
                                                                                                        <ReceiptIcon />
                                                                                                        <div className="inlineDisplay paymentID">{payment.id}</div>
                                                                                                        <div className="inlineDisplay paymentProcessStaff">{payment.process_staff_id}</div>
                                                                                                        <div className="inlineDisplay paymentMethod">{payment.payment_method}</div>
                                                                                                        <div className="inlineDisplay paymentAmount">${payment.amount_received}</div>
                                                                                                        <div className="inlineDisplay paymentDate">{moment(`${payment.created_at}`).format('YYYY/MM/DD hh:mm a')}</div>
                                                                                                        <Button id="managePaymentBtn" onClick={() => this.handleDelTargetPayment(payment.id)}>{this.props.language !== "eng"? "VOID 此付款單" : "VOID Payment"}</Button>
                                                                                                    </div>
                                                                                                : null
                                                                                        )
                                                                                }
                                                                            </div>
                                                                            {
                                                                                purchase.hasPayment ?
                                                                                    <div className="managePurchaseBtnRow"><Button id="managePurchaseBtn" onClick={()=>this.handleTriggerSpecificPurchaseDetailsBox(purchase.id)}>{this.props.language !== "eng"? "查看此選購詳情" : "Purchase Details"}</Button><Button id="managePurchaseBtn" onClick={() => this.handleDelTargetPurchase(purchase.id)}>{this.props.language !== "eng"? "VOID 此選購" : "VOID Purchase"}</Button><Button id="clickToChooseNewTermsBtn2" onClick={this.handleEnterAddNewPayment}>{this.props.language !== "eng"? "新增付款" : "New Payment"}</Button></div>
                                                                                    :
                                                                                    <div className="managePurchaseBtnRow"><Button id="managePurchaseBtn" onClick={()=>this.handleTriggerSpecificPurchaseDetailsBox(purchase.id)}>{this.props.language !== "eng"? "查看此選購詳情" : "Purchase Details"}</Button><Button id="managePurchaseBtn" onClick={() => this.handleDelTargetPurchase(purchase.id)}>{this.props.language !== "eng"? "VOID 此選購" : "VOID Purchase"}</Button></div>

                                                                            }
                                                                        </div>
                                                                    </div>
                                                            :
                                                            purchase.void ?
                                                                <div className="PurchaseRecordSimplifiedRow" onClick={() => this.handleSelectPurchaseRecord(purchase.id)}>
                                                                    <ChevronRightIcon id="PurchaseHistoryRetractRowVoidIcon" />
                                                                    <div className="inlineDisplay purchaseRecord_ID">{purchase.id}</div>
                                                                    {
                                                                        purchase.purchased_package_ids ?
                                                                            <div className="inlineDisplay purchaseRecord_PackageSummary">{' | ' + purchase.purchased_package_ids.map((purchasedPackage: any) => (this.props.purchasedPackagesList.filter((packageInfo: any) => packageInfo.id === purchasedPackage))[0].package_name + ' | ')}</div>
                                                                            :
                                                                            <div className="inlineDisplay purchaseRecord_PackageSummary">{' | ' + purchase.purchased_product_ids.map((purchasedProduct: any) => (this.props.purchasedProductsList.filter((productInfo: any) => productInfo.id === purchasedProduct))[0].product_name + ' | ')}</div>
                                                                    }
                                                                    <div className="inlineDisplay purchaseRecord_AmountPayable">$ {purchase.amount_payable}</div>
                                                                    <div className="inlineDisplay purchaseRecord_PurchaseStatusVoided">{this.props.language !== "eng"? "已 VOID" : "VOIDED"}</div>
                                                                    <div className="inlineDisplay purchaseRecord_ProcessPurchaseStaffID">{purchase.process_staff_id}</div>
                                                                    <div className="inlineDisplay purchaseRecord_CreateDate">{moment(`${purchase.created_at}`).format('YYYY/MM/DD hh:mm a')}</div>
                                                                </div>
                                                                :
                                                                purchase.fully_paid ?
                                                                    //Basic Simple Row Version
                                                                    <div className="PurchaseRecordSimplifiedRow" onClick={() => this.handleSelectPurchaseRecord(purchase.id)}>
                                                                        <ChevronRightIcon id="PurchaseHistoryRetractRowPaidIcon" />
                                                                        <div className="inlineDisplay purchaseRecord_ID">{purchase.id}</div>
                                                                        {
                                                                            purchase.purchased_package_ids ?
                                                                                <div className="inlineDisplay purchaseRecord_PackageSummary">{' | ' + purchase.purchased_package_ids.map((purchasedPackage: any) => (this.props.purchasedPackagesList.filter((packageInfo: any) => packageInfo.id === purchasedPackage))[0].package_name + ' | ')}</div>
                                                                                :
                                                                                <div className="inlineDisplay purchaseRecord_PackageSummary">{' | ' + purchase.purchased_product_ids.map((purchasedProduct: any) => (this.props.purchasedProductsList.filter((productInfo: any) => productInfo.id === purchasedProduct))[0].product_name + ' | ')}</div>
                                                                        }
                                                                        <div className="inlineDisplay purchaseRecord_AmountPayable">$ {purchase.amount_payable}</div>
                                                                        {purchase.fully_paid ?
                                                                            <div className="inlineDisplay purchaseRecord_PurchaseStatus">{this.props.language !== "eng"? "已完成付款" : "Clear"}</div> :
                                                                            <div className="inlineDisplay purchaseRecord_PurchaseUnpaidStatus">{this.props.language !== "eng"? "未完成付款" : "Not Clear"}</div>
                                                                        }
                                                                        <div className="inlineDisplay purchaseRecord_ProcessPurchaseStaffID">{purchase.process_staff_id}</div>
                                                                        <div className="inlineDisplay purchaseRecord_CreateDate">{moment(`${purchase.created_at}`).format('YYYY/MM/DD hh:mm a')}</div>
                                                                    </div>
                                                                    :
                                                                    <div className="PurchaseRecordSimplifiedRow" onClick={() => this.handleSelectPurchaseRecord(purchase.id)}>
                                                                        <ChevronRightIcon id="PurchaseHistoryRetractRowUnPaidIcon" />
                                                                        <div className="inlineDisplay purchaseRecord_ID">{purchase.id}</div>
                                                                        {
                                                                            purchase.purchased_package_ids ?
                                                                                <div className="inlineDisplay purchaseRecord_PackageSummary">{' | ' + purchase.purchased_package_ids.map((purchasedPackage: any) => (this.props.purchasedPackagesList.filter((packageInfo: any) => packageInfo.id === purchasedPackage))[0].package_name + ' | ')}</div>
                                                                                :
                                                                                <div className="inlineDisplay purchaseRecord_PackageSummary">{' | ' + purchase.purchased_product_ids.map((purchasedProduct: any) => (this.props.purchasedProductsList.filter((productInfo: any) => productInfo.id === purchasedProduct))[0].product_name + ' | ')}</div>
                                                                        }
                                                                        <div className="inlineDisplay purchaseRecord_AmountPayable">$ {purchase.amount_payable}</div>
                                                                        {purchase.fully_paid ?
                                                                            <div className="inlineDisplay purchaseRecord_PurchaseStatus">{this.props.language !== "eng"? "已完成付款" : "Clear"} </div> :
                                                                            <div className="inlineDisplay purchaseRecord_PurchaseUnpaidStatus">{this.props.language !== "eng"? "未完成付款" : "Not Clear"}</div>
                                                                        }
                                                                        <div className="inlineDisplay purchaseRecord_ProcessPurchaseStaffID">{purchase.process_staff_id}</div>
                                                                        <div className="inlineDisplay purchaseRecord_CreateDate">{moment(`${purchase.created_at}`).format('YYYY/MM/DD hh:mm a')}</div>
                                                                    </div>
                                                    }
                                                </div>
                                            )}
                            </div>
                        </div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        langauge:state.auth.language,
        genreTypesBank: state.tutors.genreTypeList,
        ageGroupsBank: state.tutors.ageGroupList,
        staffId: state.auth.staffId,
        customerPurchasesList: state.customerPurchasePaymentHistory.customerPurchasesList,
        customerPayments: state.customerPurchasePaymentHistory.customerPayments,
        purchasedPackagesList: state.customerPurchasePaymentHistory.purchasedPackagesList,
        showDeclineVoidPurchaseBox: state.customerPurchasePaymentHistory.showDeclineVoidPurchaseBox,
        purchasedProductsList: state.customerPurchasePaymentHistory.purchasedProductList,
        loading: state.customerPurchasePaymentHistory.loading,
        showEmailResentConfirmationBox: state.customerPurchasePaymentHistory.showEmailResentConfirmationBox
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        // handleSaveAdd: async (mainBody: any) => dispatch(await addThisTutorInfos(mainBody))
        loadCustomerPurchasePaymentHistory: async (customerUserID: number) => dispatch(await loadCustomerPurchasePaymentHistory(customerUserID)),
        handleDeleteTargetPayment: async (paymentID: number, staffUserID: number) => dispatch(await voidTargetedPreviousPayment(paymentID, staffUserID)),
        handleDeleteTargetPurchase: async (purchaseID: number, customerUserID: number) => dispatch(await voidTargetedPreviousPurchase(purchaseID, customerUserID)),
        handleBackFromVoidDeclination: async () => dispatch(await backFromDeclineOfVoidPurchase()),
        handleResendPurchaseEmailToCustomer: async(purchaseID:number, customerUserID:number, purchasedProductData: any[])=>  dispatch(await resendTargetPurchaseEmailReceiptToCustomer(customerUserID, purchaseID, purchasedProductData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPurchasePaymentHistory);
// export default CustomerPurchasePaymentHistory;