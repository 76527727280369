

export function allCustomerPurchasePaymentHistoryLoaded(customerPurchases:any[], customerPayments:any[], purchasedPackagesList:any[], purchasedProductsList: any[]) {
    return {
        type: "ALL_CUSTOMER_PURCHASE_PAYMENT_HISTORY_LOADED" as "ALL_CUSTOMER_PURCHASE_PAYMENT_HISTORY_LOADED",
        customerPurchases,
        customerPayments,
        purchasedPackagesList,
        purchasedProductsList
    }
}

export function newPaymentRecorded () {
    return {
        type: "NEW_PAYMENT_RECORDED" as "NEW_PAYMENT_RECORDED"
    }
}

export function targetedPreviousPaymentVoided () {
    return {
        type: "TARGETED_PREVIOUS_PAYMENT_VOIDED" as "TARGETED_PREVIOUS_PAYMENT_VOIDED"
    }
}

export function targetedPreviousPurchaseVoided () {
    return {
        type:"TARGETED_PREVIOUS_PURCHASE_VOIDED" as "TARGETED_PREVIOUS_PURCHASE_VOIDED"
    }
}

export function targetedPreviousPurchaseVoidFailed () {
    return {
        type:"TARGETED_PREVIOUS_PURCHASE_VOID_FAILED" as "TARGETED_PREVIOUS_PURCHASE_VOID_FAILED"
    }
}
export function backFromDeclineOfVoidPurchase () {
    return {
        type:"BACK_FROM_DECLINE_OF_VOID_PURCHASE" as "BACK_FROM_DECLINE_OF_VOID_PURCHASE"
    }
}
export function purchaseEmailReceiptResent(){
    return {
        type:"PURCHASE_EMAIL_RECEIPT_RESENT" as "PURCHASE_EMAIL_RECEIPT_RESENT"
    }
}
export function backFromEmailResentConfirmationBox() {
    return {
        type: "BACK_FROM_EMAIL_RESENT_CONFIRMATION_BOX" as "BACK_FROM_EMAIL_RESENT_CONFIRMATION_BOX"
    }
}




type CustomerPurchasePaymentHistoryActionsCreators =    typeof allCustomerPurchasePaymentHistoryLoaded |
                                                        typeof newPaymentRecorded |
                                                        typeof targetedPreviousPaymentVoided |
                                                        typeof targetedPreviousPurchaseVoided |
                                                        typeof targetedPreviousPurchaseVoidFailed |
                                                        typeof backFromDeclineOfVoidPurchase |
                                                        typeof purchaseEmailReceiptResent |
                                                        typeof backFromEmailResentConfirmationBox;

export type ICustomerPurchasePaymentHistoryActions = ReturnType<CustomerPurchasePaymentHistoryActionsCreators>;