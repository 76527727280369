import {ThunkDispatch} from '../../store';
import { allStaffsLoaded, pageInitInformationLoaded, newStaffDetailsCreated, staffDetailsSaved, staffLoginDetailsReset } from './actions';

export async function loadAllStaffDetails() {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/loadAllStaffDetails`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(allStaffsLoaded(result.allStaffsDetails));
        }
    }
}

export async function staffManagePageInit () {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/staffManagePageInit`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(pageInitInformationLoaded(result.allEmploymentStatus, result.allSites ,result.allStaffsDetails));
        }
    }
}

export async function submitNewStaffDetails (newStaffDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/createNewStaffAcc`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            newStaffDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(newStaffDetailsCreated());
        }
    }
}

export async function saveEditedStaffDetails (newStaffDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/updateStaffDetails`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            newStaffDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(staffDetailsSaved());
        }
    }
}

export async function resetStaffLoginDetails(staffUserID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/resetLoginDetailsForStaffs`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            staffUserID
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(staffLoginDetailsReset());
        }
    }
}