import { ThunkDispatch } from "../../store";
import { customerSearchResultLoaded ,availablePackagesOfCustomerLoaded, classOccupanciesWithSameUSGLoaded ,newClassParticipationDetailCreated} from "./actions";

export async function searchCustomerInfo (searchMethod:string,searchInput: any) {
    
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customerParticipation/loadCustomerInfoBySearchingInput?searchMethod=${searchMethod}&searchInput=${searchInput}`, {
        method: 'GET',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        }
    })
    let result : any= await res.json();

    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        
        return async (dispatch:ThunkDispatch) => {
            
            dispatch(customerSearchResultLoaded(result.customerSearchResult));
            console.log(result)
        }
    }
}

export async function loadAvailablePackagesForAddingNewParticipation (customerID:number, ageGroupID: number, genreID: number, siteID:number, previousBookingID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customerParticipation/loadCustomerPackagesforAddingParticipationUse?customerID=${customerID}&ageGroupID=${ageGroupID}&genreID=${genreID}&siteID=${siteID}&previousBookingID=${previousBookingID}`, {
        method: 'GET',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        }
    });

    let result = await res.json();

    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        
        return async (dispatch:ThunkDispatch) => {
            
            dispatch(availablePackagesOfCustomerLoaded(result));
            console.log(result)
        }
    }

}

export async function loadClassOccupanciesWithSameUSG (USGID:number, endTime:any, customerID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customerParticipation/loadClassOccupancyAfterWithSameUSGID?USGID=${USGID}&endTime=${endTime}&customerID=${customerID}`, {
        method: 'GET',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        }
    });

    let result = await res.json();

    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        
        return async (dispatch:ThunkDispatch) => {
            dispatch(classOccupanciesWithSameUSGLoaded(result.classOccupancies));
            console.log(result)
        }
    }
}

export async function createNewClassParticipationDetails (customerID:number, roomOccupancyIDs: any, staffID: number, packageID: number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customerParticipation/createNewClassParticipationDetails`, {
        method: 'POST',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        },
        body: JSON.stringify({
            customerID: customerID,
            roomOccupancyIDs: roomOccupancyIDs,
            staffID: staffID,
            packageID: packageID
        })
    });

    let result = await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        
        return async (dispatch:ThunkDispatch) => {
            dispatch(newClassParticipationDetailCreated());
            console.log(result)
        }
    }
}

