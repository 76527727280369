import { ThunkDispatch } from '../../store';
import { allAttendanceLoaded , attendanceCreated, attendanceUpdated, attendanceActiveSettingModified} from './actions';

export async function loadAttendanceData() {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/loadAllAttendances`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(allAttendanceLoaded(result.allAttendances));
        }
    }
}
export async function createAttendance(attendanceDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/createNewAttendance`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            attendanceDetails: attendanceDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(attendanceCreated());
        }
    }
}
export async function updateAttendance(attendanceDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/updateAttendanceDetails`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            attendanceDetails: attendanceDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(attendanceUpdated());
        }
    }
}
export async function delAttendance(attendanceID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/delAttendance`, {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            attendanceID: attendanceID
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(attendanceActiveSettingModified());
        }
    }
}
