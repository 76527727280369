import { IRoomOccupancyIndividualBoxState } from "./state";
import { IRoomOccupancyIndividualBoxActions } from "./actions";
import { AccordionCollapse } from "react-bootstrap";

const initialState = {
    submittedEditingOccupancyNewDetails: [],
    repeatedUSGMatchWithSubmittedOccupancyNewDetails: [],
    groupClassCustomerParticipationInfos: [],
    relatedCustomerPackagesInfos: [],
    relatedCustomerInfos: [],
    absentReasons: []
}

export const roomOccupancyIndividualBoxReducer = (state: IRoomOccupancyIndividualBoxState = initialState, actions: IRoomOccupancyIndividualBoxActions) => {

    switch (actions.type) {

        case "NEW_OCCUPANCY_DETAILS_UPDATE_SUBMITTED_WITH_FAILED_UNIQUE_CHECK":
            return {
                ...state,
                repeatedUSGMatchWithSubmittedOccupancyNewDetails: [actions.duplicatedLatestSchedulingGroup],
                submittedEditingOccupancyNewDetails: [actions.newOccupancyDetailsTempStorage]
            }

        case "USG_CONFIRMATION_DECLINE_CLICK_HANDLED":
            return {
                ...state,
                repeatedUSGMatchWithSubmittedOccupancyNewDetails: [],
                submittedEditingOccupancyNewDetails: []
            }

        case "USG_CONFIRMATION_USE_CLICK_HANDLED":
            return {
                ...state,
                repeatedUSGMatchWithSubmittedOccupancyNewDetails: [],
                submittedEditingOccupancyNewDetails: []
            }

        case "USG_CONFIRMATION_CREATE_CLICK_HANDLED":
            return {
                ...state,
                repeatedUSGMatchWithSubmittedOccupancyNewDetails: [],
                submittedEditingOccupancyNewDetails: []
            }

        case "NEW_OCCUPANCY_DETAILS_UPDATE_SUBMITTED":
            return {
                ...state,
                repeatedUSGMatchWithSubmittedOccupancyNewDetails: [],
                submittedEditingOccupancyNewDetails: []
            }

        case "OCCUPANCY_PARTICIPATION_RELATED_INFO_LOADED":
            return {
                ...state,
                groupClassCustomerParticipationInfos: actions.customerParticipationInfos,
                relatedCustomerPackagesInfos: actions.relatedCustomerPackagesInfos,
                relatedCustomerInfos: actions.relatedCustomerInfos,
                absentReasons: actions.absentReasons
            }

        case "CXL_CLASS_PARTICIPATION_WITH_REASON_PROCESSED":
            return {
                ...state
            }

        case "BACKWARD_CXL_CLASS_PARTICIPATION_WITH_REASON_PROCESSED":
            return {
                ...state
            }

        case "DEL_RENTAL_PRIVATE_OCCUPANCY_BOOKING_WITH_REASON_COMPLETED":
            return {
                ...state
            }
        case "DEL_CUSTOMER_PARTICIPATION_BY_STAFF_WITHOUT_REASON":
            return {
                ...state
            }
        case "ROOM_OCCUPANCY_INDIVIDUAL_BOX_RESET":
            return {
                ...state,
                groupClassCustomerParticipationInfos: [],
                relatedCustomerPackagesInfos: [],
                relatedCustomerInfos: [],
                absentReasons: []
            }

        default:
            return state;
    }
}