import React from "react";
import { IRootState, ThunkDispatch } from "../store";
import { connect } from "react-redux";
import './TutorEditBox.css'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import PublishIcon from '@material-ui/icons/Publish';
import { Button } from "@material-ui/core";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import moment from "moment";
import { updateThisTutorInfos } from "./thunks";



export interface ITutorEditBoxProps {
    language: string,
    allTutorInfo: any,
    selectedTutorId: number,
    genreTypesBank: any,
    ageGroupsBank: any,
    employmentStatusBank: any,
    handleEditExit: () => void,
    handleSaveEdit: (mainBody: any) => void

}

export interface ITutorEditBoxState {
    showEditBox: boolean,
    tutorDisplaying: number
    tutor_name_eng: string,
    tutor_name_chi: string,
    tutor_sex: string,
    tutor_tel: string,
    tutor_email: string,
    tutor_address: string,
    tutor_DOB: string,
    tutor_nationality: string,
    tutor_identification_doc_type: string,
    tutor_identification_doc_number: string,
    tutor_identification_doc_issue_date: string,
    tutor_identification_doc_expiry_date: string,
    tutor_remarks: string,
    tutor_mark_delete: boolean,
    tutor_age_group_id: number[],
    tutor_genre_id: number[],
    tutor_date_of_employment: string,
    tutor_employment_status_id: number,
    tutor_user_id: number,
    tutor_tutor_id: number

}

class TutorEditBox extends React.Component<ITutorEditBoxProps, ITutorEditBoxState> {
    constructor(props: ITutorEditBoxProps) {
        super(props);
        this.state = {
            showEditBox: true,
            tutorDisplaying: -1,
            tutor_name_eng: '-',
            tutor_name_chi: '-',
            tutor_sex: '-',
            tutor_tel: '-',
            tutor_email: '-',
            tutor_address: '-',
            tutor_DOB: '-',
            tutor_nationality: '-',
            tutor_identification_doc_type: '-',
            tutor_identification_doc_number: '-',
            tutor_identification_doc_issue_date: '-',
            tutor_identification_doc_expiry_date: '-',
            tutor_remarks: '-',
            tutor_mark_delete: false,
            tutor_age_group_id: [],
            tutor_genre_id: [],
            tutor_date_of_employment: '-',
            tutor_employment_status_id: 0,
            tutor_user_id: 0,
            tutor_tutor_id: 0
        }
    }

    componentWillMount() {
        let thisTutorInfo = this.props.allTutorInfo.find((tutor: any) => tutor.tutor_id == this.props.selectedTutorId);

        if (this.props.selectedTutorId != this.state.tutorDisplaying) {
            this.setState({
                showEditBox: true,
                tutorDisplaying: this.props.selectedTutorId,
                tutor_name_eng: (thisTutorInfo.name_eng ? thisTutorInfo.name_eng : null),
                tutor_name_chi: (thisTutorInfo.name_chi ? thisTutorInfo.name_chi : null),
                tutor_sex: thisTutorInfo.sex,
                tutor_tel: thisTutorInfo.tel,
                tutor_email: (thisTutorInfo.email ? thisTutorInfo.email : null),
                tutor_address: (thisTutorInfo.address ? thisTutorInfo.address : null),
                tutor_DOB: (thisTutorInfo.date_of_birth ? moment(thisTutorInfo.date_of_birth).format('YYYY-MM-DD') : ''),
                tutor_nationality: (thisTutorInfo.nationality ? thisTutorInfo.nationality : null),
                tutor_identification_doc_type: (thisTutorInfo.identification_doc_type ? thisTutorInfo.identification_doc_type : null),
                tutor_identification_doc_number: (thisTutorInfo.identification_doc_number ? thisTutorInfo.identification_doc_number : null),
                tutor_identification_doc_issue_date: (thisTutorInfo.identification_doc_issue_date ? moment(thisTutorInfo.identification_doc_issue_date).format('YYYY-MM-DD') : ''),
                tutor_identification_doc_expiry_date: (thisTutorInfo.identification_doc_expiry_date ? moment(thisTutorInfo.identification_doc_expiry_date).format('YYYY-MM-DD') : ''),
                tutor_remarks: (thisTutorInfo.remarks ? thisTutorInfo.remarks : null),
                tutor_mark_delete: thisTutorInfo.mark_delete,
                tutor_age_group_id: (thisTutorInfo.age_group_id ? thisTutorInfo.age_group_id : []),
                tutor_genre_id: (thisTutorInfo.genre_id ? thisTutorInfo.genre_id : []),
                tutor_date_of_employment: moment(thisTutorInfo.date_of_employment).format('YYYY-MM-DD'),
                tutor_employment_status_id: thisTutorInfo.employment_status_id,
                tutor_user_id: thisTutorInfo.user_id,
                tutor_tutor_id: thisTutorInfo.tutor_id
            })
        }
    }

    private handleInput1 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            tutor_name_eng: event.currentTarget.value
        })
    }

    private handleInput2 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            tutor_name_chi: event.currentTarget.value
        })
    }
    private handleInput3 = (event: any) => {
        this.setState({
            tutor_sex: event.target.value
        })
    }
    private handleInput4 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            tutor_tel: event.currentTarget.value
        })
    }
    private handleInput5 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            tutor_email: event.currentTarget.value
        })
    }
    private handleInput6 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            tutor_address: event.currentTarget.value
        })
    }
    private handleInput7 = (event: any) => {

        let result = this.state.tutor_genre_id.find((genreId: any) => genreId === parseInt(event.currentTarget.value));

        if (result) {
            let newTutorGenreId = this.state.tutor_genre_id.filter((id: number) => id !== parseInt(event.currentTarget.value))
            this.setState({
                tutor_genre_id: newTutorGenreId
            })
        } else {
            let newTutorGenreId = this.state.tutor_genre_id;
            newTutorGenreId.push(parseInt(event.currentTarget.value));
            this.setState({
                tutor_genre_id: newTutorGenreId
            })
        }
    }
    private handleInput8 = (event: any) => {
        this.setState({
            tutor_nationality: event.currentTarget.value
        })
    }
    private handleInput9 = (event: any) => {
        this.setState({
            tutor_identification_doc_type: event.target.value
        })
    }
    private handleInput10 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let DOBISOString = moment(event.currentTarget.value).toISOString()
        this.setState({
            tutor_DOB: moment(DOBISOString).format('YYYY-MM-DD')
        })
    }
    private handleInput11 = (event: any) => {
        this.setState({
            tutor_identification_doc_number: event.currentTarget.value
        })
    }
    private handleInput12 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let ISOString = moment(event.currentTarget.value).toISOString()
        this.setState({
            tutor_identification_doc_issue_date: moment(ISOString).format('YYYY-MM-DD')
        })
    }
    private handleInput13 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let ISOString = moment(event.currentTarget.value).toISOString()
        this.setState({
            tutor_identification_doc_expiry_date: moment(ISOString).format('YYYY-MM-DD')
        })
    }
    private handleInput14 = (event: any) => {
        this.setState({
            tutor_remarks: event.currentTarget.value
        })
    }
    private handleInput15 = (event: any) => {

        let result = this.state.tutor_age_group_id.find((ageGroupId: any) => ageGroupId === parseInt(event.currentTarget.value));

        if (result) {
            let newTutorAgeGroupId = this.state.tutor_age_group_id.filter((id: number) => id !== parseInt(event.currentTarget.value))
            this.setState({
                tutor_age_group_id: newTutorAgeGroupId
            })
        } else {
            let newTutorAgeGroupId = this.state.tutor_age_group_id;
            newTutorAgeGroupId.push(parseInt(event.currentTarget.value));
            this.setState({
                tutor_age_group_id: newTutorAgeGroupId
            })
        }
    }
    private handleInput16 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let ISOString = moment(event.currentTarget.value).toISOString()
        this.setState({
            tutor_date_of_employment: moment(ISOString).format('YYYY-MM-DD')
        })
    }
    private handleInput17 = (event: any) => {
        this.setState({
            tutor_employment_status_id: event.target.value
        })
    }
    private handleDeniedChanges = () => {
        let thisTutorInfo = this.props.allTutorInfo.find((tutor: any) => tutor.tutor_id == this.props.selectedTutorId);
        if (this.state.tutor_name_eng === thisTutorInfo.name_eng &&
            this.state.tutor_name_chi === thisTutorInfo.name_chi &&
            this.state.tutor_sex === thisTutorInfo.sex &&
            this.state.tutor_tel === thisTutorInfo.tel &&
            this.state.tutor_email === thisTutorInfo.email &&
            this.state.tutor_address === thisTutorInfo.address &&
            this.state.tutor_DOB === moment(thisTutorInfo.date_of_birth).format('YYYY-MM-DD') &&
            this.state.tutor_nationality === thisTutorInfo.nationality &&
            this.state.tutor_identification_doc_type === thisTutorInfo.identification_doc_type &&
            this.state.tutor_identification_doc_number === thisTutorInfo.identification_doc_number &&
            this.state.tutor_identification_doc_issue_date === moment(thisTutorInfo.identification_doc_issue_date).format('YYYY-MM-DD') &&
            this.state.tutor_identification_doc_expiry_date === moment(thisTutorInfo.identification_doc_expiry_date).format('YYYY-MM-DD') &&
            this.state.tutor_remarks === thisTutorInfo.remarks &&
            this.state.tutor_mark_delete === thisTutorInfo.mark_delete &&
            this.state.tutor_age_group_id === thisTutorInfo.age_group_id &&
            this.state.tutor_genre_id === thisTutorInfo.genre_id &&
            this.state.tutor_date_of_employment === moment(thisTutorInfo.date_of_employment).format('YYYY-MM-DD') &&
            this.state.tutor_employment_status_id === thisTutorInfo.employment_status_id &&
            this.state.tutor_user_id === thisTutorInfo.user_id) {
            this.props.handleEditExit(); //this case without any change.
        } else {
            let res = window.confirm('Discard Changes?');
            if (res) {
                this.props.handleEditExit();
            }
        }
    }

    private handleSave = async () => {
        let mainBody = {
            name_eng: this.state.tutor_name_eng,
            name_chi: (this.state.tutor_name_chi === ''),
            sex: this.state.tutor_sex,
            tel: this.state.tutor_tel,
            email: this.state.tutor_email,
            address: this.state.tutor_address,
            date_of_birth: (this.state.tutor_DOB === "" ? null : this.state.tutor_DOB),
            nationality: this.state.tutor_nationality,
            identification_doc_type: this.state.tutor_identification_doc_type,
            identification_doc_number: this.state.tutor_identification_doc_number,
            identification_doc_issue_date: (this.state.tutor_identification_doc_issue_date === "" ? null : this.state.tutor_identification_doc_issue_date),
            identification_doc_expiry_date: (this.state.tutor_identification_doc_expiry_date === "" ? null : this.state.tutor_identification_doc_expiry_date),
            remarks: this.state.tutor_remarks,
            mark_delete: this.state.tutor_mark_delete,
            age_group_id: this.state.tutor_age_group_id,
            genre_id: this.state.tutor_genre_id,
            date_of_employment: this.state.tutor_date_of_employment,
            employment_status_id: this.state.tutor_employment_status_id,
            user_id: this.state.tutor_user_id,
            tutor_id: this.state.tutor_tutor_id
        }
        await this.props.handleSaveEdit(mainBody);
        this.props.handleEditExit();


    }


    render() {
        let thisTutorInfo = this.props.allTutorInfo.find((tutor: any) => tutor.tutor_id == this.props.selectedTutorId);
        let thisTutorDOB = moment(thisTutorInfo.date_of_birth).format('YYYY-MM-DD')
        return (
            <div>
                <div className="displayInArea2">
                    <form>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 appHeader">
                                    <div className="navHeading">{this.props.language !== "eng" ? "更改導師資料" : "Edit Tutor Info"}</div>
                                    <ArrowBackIcon id="backBtn" onClick={this.handleDeniedChanges} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-2 col-md-12">
                                </div>
                                <div className="col-lg-10 col-md-12 leftDivider">
                                    <div className="topDivider">
                                        <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={this.handleSave}>儲存導師資料</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="fixedHeightWindowContainerOnAddTutorBox">
                                <div className="row">
                                    <div className="col-lg-2 col-md-12">
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                <div className="sectionHeader">
                                                    {this.props.language !== "eng" ? "導師資料" : "Tutor Info"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                <div className="tutorPicOutLine">
                                                    <div className="tutorPic"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                <div className="sectionSubHeading">
                                                    {thisTutorInfo.name_eng}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                <div className="row">
                                                    <div className="col-6 ">
                                                        <div className="row">
                                                            <div className="col-12 sectionAligner2">
                                                                <div className="dataCounting">- -</div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 sectionAligner2">
                                                                <div className="content">{this.props.language !== "eng" ? "班次" : "Classes"}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 " id="students">
                                                        <div className="row">
                                                            <div className="col-12 sectionAligner2">
                                                                <div className="dataCounting">- -</div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 sectionAligner2">
                                                                <div className="content">{this.props.language !== "eng" ? "人次" : "Students"}</div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                <div id="highlightedSection1">
                                                    <FormControl component="fieldset" >
                                                        <FormLabel component="legend">{this.props.language !== "eng" ? "允許教授運動種類" : "Applicable Sports"}</FormLabel>
                                                        <FormGroup row >
                                                            {this.props.genreTypesBank.map(
                                                                (genre: any) =>
                                                                    !genre.active ? null :
                                                                        <div>
                                                                            {this.state.tutor_genre_id.includes(genre.id) ?

                                                                                <FormControlLabel control={<Checkbox checked onChange={this.handleInput7} />}
                                                                                    label={genre.genre}
                                                                                    value={genre.id}
                                                                                    className="noChange"
                                                                                />
                                                                                :
                                                                                <FormControlLabel control={<Checkbox onChange={this.handleInput7} />}
                                                                                    label={genre.genre}
                                                                                    value={genre.id}
                                                                                    className="noChange"
                                                                                />
                                                                            }
                                                                        </div>
                                                            )}
                                                        </FormGroup>
                                                        <FormHelperText>{this.props.language !== "eng" ? "向下滾動" : "Roll Down"}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                <div id="highlightedSection2">
                                                    <FormControl component="fieldset" >
                                                        <FormLabel component="legend">{this.props.language !== "eng" ? "允許教授顧客年齡" : "Applicable Age Group"}</FormLabel>
                                                        <FormGroup row>
                                                            {this.props.ageGroupsBank.map(
                                                                (ageGroup: any) =>
                                                                    !ageGroup.active ? null :

                                                                        <div>
                                                                            {this.state.tutor_age_group_id.includes(ageGroup.id) ?
                                                                                <FormControlLabel control={<Checkbox checked onChange={this.handleInput15} />}
                                                                                    label={ageGroup.age_group}
                                                                                    value={ageGroup.id}
                                                                                    className="noChange"
                                                                                />
                                                                                :
                                                                                <FormControlLabel control={<Checkbox onChange={this.handleInput15} />}
                                                                                    label={ageGroup.age_group}
                                                                                    value={ageGroup.id}
                                                                                    className="noChange"
                                                                                />
                                                                            }
                                                                        </div>
                                                            )}

                                                        </FormGroup>
                                                        <FormHelperText>{this.props.language !== "eng" ? "向下滾動" : "Roll Down"}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="col-lg-5 col-md-12 leftDivider">
                                        {/* <div className="row">
                                        <div className="col-12 sectionAligner">
                                            <div className="sectionHeader">
                                                General Information
                                        </div>
                                        </div>
                                    </div> */}
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                {this.state.tutor_name_eng == thisTutorInfo.name_eng ?
                                                    this.props.language !== "eng" ?
                                                        <TextField className="Inputs" id="englishName" label="英文姓名" variant="outlined" defaultValue={thisTutorInfo.name_eng} onChange={this.handleInput1} />
                                                        :
                                                        <TextField className="Inputs" id="englishName" label="Name in English" variant="outlined" defaultValue={thisTutorInfo.name_eng} onChange={this.handleInput1} />
                                                    :
                                                    this.props.language !== "eng" ?
                                                        <TextField className="Inputs changed" id="englishName" label="英文姓名" variant="outlined" defaultValue={thisTutorInfo.name_eng} onChange={this.handleInput1} />
                                                        :
                                                        <TextField className="Inputs changed" id="englishName" label="Name in English" variant="outlined" defaultValue={thisTutorInfo.name_eng} onChange={this.handleInput1} />
                                                }


                                            </div>
                                            <div className="col-12 sectionAligner" id="chiName">
                                                {this.state.tutor_name_chi == thisTutorInfo.name_chi ?
                                                    this.props.language !== "eng" ?
                                                        <TextField className="Inputs" id="chineseName" label="中文姓名" variant="outlined" defaultValue={thisTutorInfo.name_chi} onChange={this.handleInput2} />
                                                        :
                                                        <TextField className="Inputs" id="chineseName" label="Name in Chinese" variant="outlined" defaultValue={thisTutorInfo.name_chi} onChange={this.handleInput2} />
                                                    :
                                                    this.props.language !== "eng" ?
                                                        <TextField className="Inputs changed" id="chineseName" label="中文姓名" variant="outlined" defaultValue={thisTutorInfo.name_chi} onChange={this.handleInput2} />
                                                        :
                                                        <TextField className="Inputs changed" id="chineseName" label="Name in Chinese" variant="outlined" defaultValue={thisTutorInfo.name_chi} onChange={this.handleInput2} />
                                                }
                                            </div>
                                            <div className="col-12 sectionAligner">
                                                {this.state.tutor_sex == thisTutorInfo.sex ?
                                                    <FormControl id="ageInput" className="selectInputs">
                                                        <InputLabel id="demo-simple-select-outlined-label">
                                                            {this.props.language !== "eng" ? "性別" : "SEX"}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            defaultValue={this.state.tutor_sex}
                                                            onChange={this.handleInput3}
                                                        >

                                                            <MenuItem value={'M'}>M</MenuItem>
                                                            <MenuItem value={'F'}>F</MenuItem>
                                                            <MenuItem value={'-'}>{this.props.language !== "eng" ? "其他" : "Other"}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    :
                                                    <FormControl id="ageInput" className="selectInputs changed">
                                                        <InputLabel id="demo-simple-select-outlined-label">
                                                            {this.props.language !== "eng" ? "性別" : "SEX"}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            defaultValue={this.state.tutor_sex}
                                                            onChange={this.handleInput3}
                                                        >

                                                            <MenuItem value={'M'}>M</MenuItem>
                                                            <MenuItem value={'F'}>F</MenuItem>
                                                            <MenuItem value={'-'}>{this.props.language !== "eng" ? "其他" : "Other"}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                {this.state.tutor_tel == thisTutorInfo.tel ?
                                                    this.props.language !== "eng" ?
                                                        <TextField className="Inputs" id="chineseName" label="聯絡電話" variant="outlined" defaultValue={thisTutorInfo.tel} onChange={this.handleInput4} />
                                                        :
                                                        <TextField className="Inputs" id="chineseName" label="Tel" variant="outlined" defaultValue={thisTutorInfo.tel} onChange={this.handleInput4} />
                                                    :
                                                    this.props.language !== "eng" ?
                                                        <TextField className="Inputs changed" id="chineseName" label="聯絡電話" variant="outlined" defaultValue={thisTutorInfo.tel} onChange={this.handleInput4} />
                                                        :
                                                        <TextField className="Inputs changed" id="chineseName" label="Tel" variant="outlined" defaultValue={thisTutorInfo.tel} onChange={this.handleInput4} />

                                                }
                                            </div>
                                            <div className="col-12 sectionAligner">
                                                {this.state.tutor_email == thisTutorInfo.email ?
                                                    <TextField className="Inputs" id="email" label="Email" variant="outlined" value={this.state.tutor_email} onChange={this.handleInput5} />
                                                    :
                                                    <TextField className="Inputs changed" id="email" label="Email" variant="outlined" value={this.state.tutor_email} onChange={this.handleInput5} />

                                                }
                                            </div>
                                            <div className="col-12 sectionAligner">
                                                {this.state.tutor_date_of_employment === moment(thisTutorInfo.date_of_employment).format('YYYY-MM-DD') ?
                                                    this.props.language !== "eng" ?
                                                        <TextField
                                                            id="DateOfBirth"
                                                            className="Inputs"
                                                            label="雇用日期"
                                                            type="date"
                                                            defaultValue={moment(`${thisTutorInfo.date_of_employment}`).format("YYYY-MM-DD")}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handleInput16}
                                                        />
                                                        :
                                                        <TextField
                                                            id="DateOfBirth"
                                                            className="Inputs"
                                                            label="Employment Date"
                                                            type="date"
                                                            defaultValue={moment(`${thisTutorInfo.date_of_employment}`).format("YYYY-MM-DD")}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handleInput16}
                                                        />
                                                    :
                                                    this.props.language !== "eng" ?
                                                        <TextField
                                                            id="DateOfBirth"
                                                            className="Inputs changed"
                                                            label="雇用日期"
                                                            type="date"
                                                            defaultValue={moment(`${thisTutorInfo.date_of_employment}`).format("YYYY-MM-DD")}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handleInput16}
                                                        />
                                                        :
                                                        <TextField
                                                            id="DateOfBirth"
                                                            className="Inputs changed"
                                                            label="Employment Date"
                                                            type="date"
                                                            defaultValue={moment(`${thisTutorInfo.date_of_employment}`).format("YYYY-MM-DD")}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handleInput16}
                                                        />
                                                }

                                            </div>

                                            <div className="col-12 sectionAligner">
                                                {this.state.tutor_employment_status_id == thisTutorInfo.employment_status_id ?
                                                    <FormControl id="ageInput" className="selectInputs">
                                                        <InputLabel id="demo-simple-select-outlined-label">
                                                            {this.props.language !== "eng" ? "雇用模式" : "Employment Type"}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            defaultValue={this.state.tutor_employment_status_id}
                                                            onChange={this.handleInput17}
                                                        >
                                                            {this.props.employmentStatusBank.map(
                                                                (status: any) => <MenuItem value={status.id}>{status.status}</MenuItem>)}
                                                        </Select>
                                                    </FormControl>
                                                    :
                                                    <FormControl id="ageInput" className="selectInputs changed">
                                                        <InputLabel id="demo-simple-select-outlined-label">
                                                            {this.props.language !== "eng" ? "雇用模式" : "Employment Type"}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            defaultValue={this.state.tutor_employment_status_id}
                                                            onChange={this.handleInput17}
                                                        >
                                                            {this.props.employmentStatusBank.map(
                                                                (status: any) => <MenuItem value={status.id}>{status.status}</MenuItem>)}
                                                        </Select>
                                                    </FormControl>
                                                }
                                            </div>

                                            <div className="col-12 sectionAligner">
                                                {this.state.tutor_address == thisTutorInfo.address ?
                                                    this.props.language !== "eng" ?
                                                        <TextField className="Inputs" id="address" label="聯絡地址" variant="outlined" multiline={true} rows="2" defaultValue={thisTutorInfo.address} onChange={this.handleInput6} />
                                                        :
                                                        <TextField className="Inputs" id="address" label="Address" variant="outlined" multiline={true} rows="2" defaultValue={thisTutorInfo.address} onChange={this.handleInput6} />
                                                    :
                                                    this.props.language !== "eng" ?
                                                        <TextField className="Inputs changed" id="address" label="聯絡地址" variant="outlined" multiline={true} rows="2" defaultValue={thisTutorInfo.address} onChange={this.handleInput6} />
                                                        :
                                                        <TextField className="Inputs changed" id="address" label="Address" variant="outlined" multiline={true} rows="2" defaultValue={thisTutorInfo.address} onChange={this.handleInput6} />

                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-12 leftDivider">
                                        {/* <div className="row">
                                        <div className="col-12 sectionAligner">
                                            <div className="sectionHeader">
                                                Documentation Information
                                        </div>
                                        </div>
                                    </div> */}
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                {this.state.tutor_DOB === moment(thisTutorInfo.date_of_birth).format('YYYY-MM-DD') ?
                                                    this.props.language !== "eng" ?
                                                        <TextField
                                                            id="issueDate"
                                                            className="Inputs"
                                                            label="出生日期"
                                                            type="date"
                                                            defaultValue={moment(`${thisTutorInfo.date_of_birth}`).format("YYYY-MM-DD")}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handleInput10}
                                                        />
                                                        :
                                                        <TextField
                                                            id="issueDate"
                                                            className="Inputs"
                                                            label="DOB"
                                                            type="date"
                                                            defaultValue={moment(`${thisTutorInfo.date_of_birth}`).format("YYYY-MM-DD")}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handleInput10}
                                                        />
                                                    :
                                                    this.props.language !== "eng" ?
                                                        <TextField
                                                            id="issueDate"
                                                            className="Inputs changed"
                                                            label="出生日期"
                                                            type="date"
                                                            defaultValue={moment(`${thisTutorInfo.date_of_birth}`).format("YYYY-MM-DD")}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handleInput10}
                                                        />
                                                        :
                                                        <TextField
                                                            id="issueDate"
                                                            className="Inputs changed"
                                                            label="DOB"
                                                            type="date"
                                                            defaultValue={moment(`${thisTutorInfo.date_of_birth}`).format("YYYY-MM-DD")}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handleInput10}
                                                        />
                                                }

                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                {this.state.tutor_nationality === thisTutorInfo.nationality ?
                                                    this.props.language !== "eng" ?
                                                        <TextField className="Inputs" id="nationality" label="國籍簡寫" variant="outlined" defaultValue={thisTutorInfo.nationality} onChange={this.handleInput8} />
                                                        :
                                                        <TextField className="Inputs" id="nationality" label="Nationality" variant="outlined" defaultValue={thisTutorInfo.nationality} onChange={this.handleInput8} />
                                                    :
                                                    this.props.language !== "eng" ?
                                                        <TextField className="Inputs changed" id="nationality" label="國籍簡寫" variant="outlined" defaultValue={thisTutorInfo.nationality} onChange={this.handleInput8} />
                                                        :
                                                        <TextField className="Inputs changed" id="nationality" label="Nationality" variant="outlined" defaultValue={thisTutorInfo.nationality} onChange={this.handleInput8} />}
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                {this.state.tutor_identification_doc_type === thisTutorInfo.identification_doc_type ?
                                                    <FormControl id="ageInput" className="selectInputs">
                                                        <InputLabel id="demo-simple-select-outlined-label">
                                                            {this.props.language !== "eng" ? "身份證明文件" : "Identification Document"}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            defaultValue={this.state.tutor_identification_doc_type}
                                                            onChange={this.handleInput9}
                                                        >
                                                            <MenuItem value={'HKID'}>HKID</MenuItem>
                                                            <MenuItem value={'PASSPORT'}>PASSPORT</MenuItem>
                                                            <MenuItem value={'-'}>{this.props.language !== "eng" ? "其他" : "Other"}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    :
                                                    <FormControl id="ageInput" className="selectInputs changed">
                                                        <InputLabel id="demo-simple-select-outlined-label">
                                                            {this.props.language !== "eng" ? "身份證明文件" : "Identification Document"}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            defaultValue={this.state.tutor_identification_doc_type}
                                                            onChange={this.handleInput9}
                                                        >
                                                            <MenuItem value={'HKID'}>HKID</MenuItem>
                                                            <MenuItem value={'PASSPORT'}>PASSPORT</MenuItem>
                                                            <MenuItem value={'-'}>{this.props.language !== "eng" ? "其他" : "Other"}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                {this.state.tutor_identification_doc_number === thisTutorInfo.identification_doc_number ?
                                                    this.props.language !== "eng" ?
                                                        <TextField className="Inputs" id="englishName" label="文件編號" variant="outlined" defaultValue={thisTutorInfo.identification_doc_number} onChange={this.handleInput11} />
                                                        :
                                                        <TextField className="Inputs" id="englishName" label="Document Number" variant="outlined" defaultValue={thisTutorInfo.identification_doc_number} onChange={this.handleInput11} />
                                                    :
                                                    this.props.language !== "eng" ?
                                                        <TextField className="Inputs changed" id="englishName" label="文件編號" variant="outlined" defaultValue={thisTutorInfo.identification_doc_number} onChange={this.handleInput11} />
                                                        :
                                                        <TextField className="Inputs changed" id="englishName" label="Document Number" variant="outlined" defaultValue={thisTutorInfo.identification_doc_number} onChange={this.handleInput11} />

                                                }
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                {this.state.tutor_identification_doc_issue_date === moment(thisTutorInfo.identification_doc_issue_date).format('YYYY-MM-DD') ?
                                                    this.props.language !== "eng" ?
                                                        <TextField
                                                            id="issueDate"
                                                            className="Inputs"
                                                            label="簽發日期"
                                                            type="date"
                                                            defaultValue={moment(`${thisTutorInfo.identification_doc_issue_date}`).format("YYYY-MM-DD")}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handleInput12}
                                                        />
                                                        :
                                                        <TextField
                                                            id="issueDate"
                                                            className="Inputs"
                                                            label="Issue Date"
                                                            type="date"
                                                            defaultValue={moment(`${thisTutorInfo.identification_doc_issue_date}`).format("YYYY-MM-DD")}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handleInput12}
                                                        />
                                                    :
                                                    this.props.language !== "eng" ?
                                                        <TextField
                                                            id="issueDate"
                                                            className="Inputs changed"
                                                            label="簽發日期"
                                                            type="date"
                                                            defaultValue={moment(`${thisTutorInfo.identification_doc_issue_date}`).format("YYYY-MM-DD")}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handleInput12}
                                                        />
                                                        :
                                                        <TextField
                                                            id="issueDate"
                                                            className="Inputs changed"
                                                            label="Issue Date"
                                                            type="date"
                                                            defaultValue={moment(`${thisTutorInfo.identification_doc_issue_date}`).format("YYYY-MM-DD")}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handleInput12}
                                                        />
                                                }
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner">
                                                {this.state.tutor_identification_doc_expiry_date === moment(thisTutorInfo.identification_doc_expiry_date).format('YYYY-MM-DD') ?
                                                    this.props.language !== "eng" ?
                                                        <TextField
                                                            id="issueDate"
                                                            className="Inputs"
                                                            label="到期日"
                                                            type="date"
                                                            defaultValue={moment(`${thisTutorInfo.identification_doc_expiry_date}`).format("YYYY-MM-DD")}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handleInput13}
                                                        />
                                                        :
                                                        <TextField
                                                            id="issueDate"
                                                            className="Inputs"
                                                            label="Exp Date"
                                                            type="date"
                                                            defaultValue={moment(`${thisTutorInfo.identification_doc_expiry_date}`).format("YYYY-MM-DD")}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handleInput13}
                                                        /> :
                                                    this.props.language !== "eng" ?
                                                        <TextField
                                                            id="issueDate"
                                                            className="Inputs changed"
                                                            label="到期日"
                                                            type="date"
                                                            defaultValue={moment(`${thisTutorInfo.identification_doc_expiry_date}`).format("YYYY-MM-DD")}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handleInput13}
                                                        />
                                                        :
                                                        <TextField
                                                            id="issueDate"
                                                            className="Inputs changed"
                                                            label="Exp Date"
                                                            type="date"
                                                            defaultValue={moment(`${thisTutorInfo.identification_doc_expiry_date}`).format("YYYY-MM-DD")}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={this.handleInput13}
                                                        />
                                                }
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-12 sectionAligner" id="formLastRowAlign">
                                                {/* {this.state.tutor_remarks} */}
                                                {this.state.tutor_remarks === thisTutorInfo.remarks ?
                                                    this.props.language !== "eng" ?
                                                        <TextField className="Inputs" id="englishName" label="備注" variant="outlined" multiline={true} rows="6" defaultValue={thisTutorInfo.remarks}  onChange={this.handleInput14} />
                                                        :
                                                        <TextField className="Inputs" id="englishName" label="Remarks" variant="outlined" multiline={true} rows="6" defaultValue={thisTutorInfo.remarks}  onChange={this.handleInput14} />
                                                    :
                                                    this.props.language !== "eng" ?
                                                        <TextField className="Inputs changed" id="englishName" label="備注" variant="outlined" multiline={true} rows="6" defaultValue={thisTutorInfo.remarks}  onChange={this.handleInput14} />
                                                        :
                                                        <TextField className="Inputs changed" id="englishName" label="Remarks" variant="outlined" multiline={true} rows="6" defaultValue={thisTutorInfo.remarks}  onChange={this.handleInput14} />

                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
                <div className="topClass2" onClick={this.handleDeniedChanges}></div>
            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        language: state.auth.language,
        allTutorInfo: state.tutors.allTutorInfo,
        genreTypesBank: state.tutors.genreTypeList,
        ageGroupsBank: state.tutors.ageGroupList,
        employmentStatusBank: state.tutors.employmentStatusLists
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        handleSaveEdit: async (mainBody: any) => dispatch(await updateThisTutorInfos(mainBody))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorEditBox);