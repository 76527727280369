import { ThunkDispatch } from "../store";
import { allTutorLoaded } from "./actions";

export async function tutorPageInit () {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/tutor/loadAllTutorInfos`, {
        method: 'GET',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        }
    })
    let result : any= await res.json()
    console.log(result);
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        
        return async (dispatch:ThunkDispatch) => {
            
            dispatch(allTutorLoaded(result.data,result.employmentStatuses,result.genreTypes, result.ageGroups));

        }
    }
}



