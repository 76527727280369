import { ThunkDispatch } from "../store";
import { allCustomerLoaded } from "../Customers/actions";

export async function addThisTutorInfos (data:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customer/addThisCustomerInfos`, {
        method: 'POST',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        },
        body: JSON.stringify(data)
    })
    let result : any= await res.json()
    console.log(result);
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            
            console.log(result);
        }
    } else {
        const res02 = await fetch(`${process.env.REACT_APP_API_SERVER}/customer/loadAllCustomerInfos`, {
            method: 'GET',
            headers: {
                "Content-Type":"application/json",
                Authorization:`Bearer ${localStorage.getItem('token')}`,
            }
        })
        let result02 = await res02.json();
        return async (dispatch:ThunkDispatch) => {
            
            dispatch(allCustomerLoaded(result02.result,result02.totalCustomers[0].count, result02.allCustomerGroupings));

        }
    }
}



