

export function allTargetedPackageDetailsLoaded (packageDetails:any) {
    return {
        type: "ALL_TARGETED_PACKAGE_DETAILS_LOADED" as "ALL_TARGETED_PACKAGE_DETAILS_LOADED",
        packageDetails : packageDetails.allPackagesDetails
    }
}

type InvoiceGeneratorActionsCreators = typeof allTargetedPackageDetailsLoaded

export type IInvoiceGeneratorActions = ReturnType<InvoiceGeneratorActionsCreators>