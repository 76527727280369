import React from "react";
import './GroupingManage.css'
import { connect } from "react-redux";
import { IRootState, ThunkDispatch } from "../../store";
import { loadGroupingsData, updateAgeGroupActiveSetting, createAgeGroup, updateAgeGroup, updateGenre, createGenre, updateGenreActiveSetting } from './thunks';
import { Button, TextField } from "@material-ui/core";
import { ChromePicker } from 'react-color';
import EditIcon from '@material-ui/icons/Edit';
// import { classroomDeleteFailedNoticed } from "./actions";
import CategoryIcon from '@material-ui/icons/Category';
import CircularProgress from '@material-ui/core/CircularProgress';



export interface IGroupingManageProps {
    loadAllGroupings: () => void,
    updateAgeGroupDetails: (ageGroupDetails: any) => void,
    createNewAgeGroupDetails: (ageGroupDetails: any) => void,
    delAgeGroupDetails: (ageGroupID: number) => void,
    updateGenreDetails: (ageGroupDetails: any) => void,
    createNewGenreDetails: (ageGroupDetails: any) => void,
    delGenreDetails: (ageGroupID: number) => void,
    // exitFromNoticeWindow: () => void,
    // updateSiteDetails: (siteDetails:any) => void,
    language: string,
    allAgeGroups: any,
    allGenres: any,
    loading: boolean
    // delClassroomFailed: boolean
}
export interface IGroupingManageState {
    selectedAgeGroupID: number,
    selectedAgeGroupDetails: any
    addNewAgeGroup: boolean,
    selectedGenreID: number,
    selectedGenreDetails: any,
    addNewGenre: boolean,
    editRepresentColor: boolean,
    colorPickerCoordinates: any
}
class GroupingManage extends React.Component<IGroupingManageProps, IGroupingManageState> {
    constructor(props: IGroupingManageProps) {
        super(props);
        this.state = {
            selectedAgeGroupID: 0,
            selectedAgeGroupDetails: '',
            addNewAgeGroup: false,
            selectedGenreID: 0,
            selectedGenreDetails: '',
            addNewGenre: false,
            editRepresentColor: false,
            colorPickerCoordinates: ''
        }
    }

    private handleEditAgeGroupClick = async (ageGroupID: number) => {
        let ageGroupDetails = this.props.allAgeGroups.filter((ageGroup: any) => ageGroup.id === ageGroupID)
        await this.setState({
            selectedAgeGroupID: ageGroupID,
            selectedAgeGroupDetails: ageGroupDetails[0]
        })
    }
    private handleAgeGroupNameInput = async (event: any) => {
        await this.setState({
            selectedAgeGroupDetails: {
                ...this.state.selectedAgeGroupDetails,
                age_group: event.target.value
            }
        })
    }

    private handleSaveAgeGroupDetails = async () => {
        this.props.updateAgeGroupDetails(this.state.selectedAgeGroupDetails);
        await this.setState({
            selectedAgeGroupID: 0,
            selectedAgeGroupDetails: ''
        })
        setTimeout(async () => await this.props.loadAllGroupings(), 1000);
    }
    private handleAddNewAgeGroupDetails = async () => {
        await this.setState({
            addNewAgeGroup: true,
            selectedAgeGroupDetails: {
                age_group: '',
                active: true
            }
        })
    }
    private handleSaveNewAgeGroupDetails = async () => {
        if (this.state.selectedAgeGroupDetails.age_group === '') {
            window.alert('請輸入年齡組別名稱！')
        } else {
            await this.props.createNewAgeGroupDetails(this.state.selectedAgeGroupDetails);
            await this.setState({
                selectedAgeGroupID: 0,
                selectedAgeGroupDetails: '',
                addNewAgeGroup: false,
            })
            setTimeout(async () => await this.props.loadAllGroupings(), 1000);

        }
    }

    private handleDelAgeGroupDetails = async (classroomID: number) => {
        let confirmation = await window.confirm('你確定要隱藏此年齡組別嗎？');
        if (confirmation) {
            await this.props.delAgeGroupDetails(classroomID);
            setTimeout(async () => await this.props.loadAllGroupings(), 1000);

        }
    }

    private handleEditGenreClick = async (genreID: number) => {
        let genreDetails = this.props.allGenres.filter((genre: any) => genre.id === genreID)
        await this.setState({
            selectedGenreID: genreID,
            selectedGenreDetails: genreDetails[0]
        })
    }
    private handleGenreNameInput = async (event: any) => {
        await this.setState({
            selectedGenreDetails: {
                ...this.state.selectedGenreDetails,
                genre: event.target.value
            }
        })
    }

    private handleSaveGenreDetails = async () => {
        this.props.updateGenreDetails(this.state.selectedGenreDetails);
        await this.setState({
            selectedGenreID: 0,
            selectedGenreDetails: ''
        })
        setTimeout(async () => await this.props.loadAllGroupings(), 1000);

    }
    private handleAddNewGenreDetails = async () => {
        await this.setState({
            addNewGenre: true,
            selectedGenreDetails: {
                genre: '',
                active: true,
                represent_color: "#FFFFFF"
            }
        })
    }
    private handleSaveNewGenreDetails = async () => {
        if (this.state.selectedAgeGroupDetails.age_group === '') {
            window.alert('請輸入運動種類名稱！')
        } else {
            await this.props.createNewGenreDetails(this.state.selectedGenreDetails);
            await this.setState({
                selectedGenreID: 0,
                selectedGenreDetails: '',
                addNewGenre: false,
            })
            setTimeout(async () => await this.props.loadAllGroupings(), 1000);

        }
    }

    private handleDelGenreDetails = async (genreID: number) => {
        let confirmation = await window.confirm('你確定要隱藏此運動種類嗎？');
        if (confirmation) {
            await this.props.delGenreDetails(genreID);
            setTimeout(async () => await this.props.loadAllGroupings(), 1000);

        }
    }

    private handleEditColorClick = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        let positionDetails = event.currentTarget.getBoundingClientRect();


        await this.setState({
            colorPickerCoordinates: { x: positionDetails.left, y: positionDetails.top, width: positionDetails.width },
        })

        await this.setState({
            editRepresentColor: !this.state.editRepresentColor
        })
    }
    private handleRepresentColorChange = async (event: any) => {
        await this.setState({
            selectedGenreDetails: {
                ...this.state.selectedGenreDetails,
                represent_color: event.hex
            }
        })
    }

    componentWillMount() {
        this.props.loadAllGroupings();
    }

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="systemMainDisplayArea row">
                        <div className="systemManagePageTitle col-12"> <CategoryIcon id="systemManPageTitleIcon" /> {this.props.language !== "eng" ? "分類管理" : "Categories Manage"}</div>
                        {

                            this.props.loading ?
                                <div className="loadingContainerRow">
                                    <div className="inlineDisplay">
                                        <div className="loadingContainer">
                                            <CircularProgress id="loadingCircle" />
                                            <div className="loadingLabel">Loading ...</div>
                                        </div>
                                    </div>
                                </div>

                                :
                                <div className="siteDisplayArea col-12">
                                    <div className="siteTitle">{this.props.language !== "eng" ? "年齡組別詳情" : "Age Groups Details"}</div>
                                    <div className="groupingFixedHeightBox">
                                        {//RENDER ALL ACTIVE
                                            this.props.allAgeGroups.map((ageGroup: any) =>
                                                !ageGroup.active ? null :
                                                    <div className="classroomRow row">
                                                        <div className="classroomNameBox col-xl-6 col-lg-6 col-md-6 mb-1">
                                                            <div className="containerOnSitesMan row">
                                                                <div className="inlineDisplayNoMargin col-3">{this.props.language !== "eng" ? "年齡組別" : "Age"} : </div>
                                                                {
                                                                    this.state.selectedAgeGroupID !== ageGroup.id ?
                                                                        <div className="inlineDisplayNoMargin  detailsDisplayField col-9"> {ageGroup.age_group} </div>
                                                                        :
                                                                        <div className="col-9">
                                                                            <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedAgeGroupDetails.age_group} onChange={this.handleAgeGroupNameInput} />
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="classroomCapacityBox col-xl-6 col-lg-6 col-md-6">
                                                            <div className="containerOnSitesMan row">
                                                                <div className="inlineDisplayNoMargin col-3">{this.props.language !== "eng" ? "狀態" : "State"} :  </div>
                                                                {
                                                                    this.state.selectedAgeGroupID !== ageGroup.id ?
                                                                        <div className="inlineDisplayNoMargin col-9 detailsDisplayField"> {this.props.language !== "eng" ? ageGroup.active ? "現正生效" : "已隱藏" : ageGroup.active ? "Active" : "Inactive"} </div>
                                                                        :
                                                                        <div className="col-9">
                                                                            <TextField className="detailsEditingInputs" variant="outlined" value={this.props.language !== "eng" ? this.state.selectedAgeGroupDetails.active ? "現正生效" : "已隱藏" : this.state.selectedAgeGroupDetails.active ? "Active" : "Inactive"} disabled />
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="editClassroomBtn">
                                                            {
                                                                this.state.selectedAgeGroupID !== ageGroup.id ?
                                                                    <div className="btnContainer">
                                                                        <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={() => this.handleEditAgeGroupClick(ageGroup.id)}>{this.props.language !== "eng" ? "修改" : "Edit"}</Button>
                                                                        <Button id="clickToDelClassroomBtn" onClick={() => this.handleDelAgeGroupDetails(ageGroup.id)}>{this.props.language !== "eng" ? "隱藏組別" : "Inactivate"}</Button>
                                                                    </div>
                                                                    :
                                                                    <div className="btnContainer">
                                                                        <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveAgeGroupDetails} >{this.props.language !== "eng" ? "儲存組別" : "Save"}</Button>
                                                                    </div>
                                                            }
                                                        </div>

                                                    </div>
                                            )
                                        }
                                        {//RENDER ALL INACTIVE
                                            this.props.allAgeGroups.map((ageGroup: any) =>
                                                ageGroup.active ? null :
                                                    <div className="classroomRow col-xl-6 col-lg-6 col-md-6 mb-1">
                                                        <div className="classroomNameBox">
                                                            <div className="containerOnSitesMan row">
                                                                <div className="inlineDisplayNoMargin col-3">{this.props.language !== "eng" ? "年齡組別" : "Age"}</div>
                                                                {
                                                                    this.state.selectedAgeGroupID !== ageGroup.id ?
                                                                        <div className="inlineDisplayNoMargin col-9 detailsDisplayField"> {ageGroup.age_group} </div>
                                                                        :
                                                                        <div className="col-9">
                                                                            <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedAgeGroupDetails.age_group} onChange={this.handleAgeGroupNameInput} />
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="classroomCapacityBox col-xl-6 col-lg-6 col-md-6 mb-1">
                                                            <div className="containerOnSitesMan row">
                                                                <div className="inlineDisplayNoMargin col-3">{this.props.language !== "eng" ? "狀態" : "State"} :  </div>
                                                                {
                                                                    this.state.selectedAgeGroupID !== ageGroup.id ?
                                                                        <div className="inlineDisplayNoMargin col-9 detailsDisplayField"> {this.props.language !== "eng" ? ageGroup.active ? "現正生效" : "已隱藏" : ageGroup.active ? "Active" : "Inactive"} </div>
                                                                        :
                                                                        <div className="col-9">
                                                                            <TextField className="detailsEditingInputs" variant="outlined" value={this.props.language !== "eng" ? this.state.selectedAgeGroupDetails.active ? "現正生效" : "已隱藏" : this.state.selectedAgeGroupDetails.active ? "Active" : "Inactive"} disabled />
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="editClassroomBtn">
                                                            {
                                                                this.state.selectedAgeGroupID !== ageGroup.id ?
                                                                    <div className="btnContainer">
                                                                        <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={() => this.handleEditAgeGroupClick(ageGroup.id)}>{this.props.language !== "eng" ? "修改" : "Edit"}</Button>
                                                                        <Button id="clickToRecoverBtn" onClick={() => this.handleDelAgeGroupDetails(ageGroup.id)}>{this.props.language !== "eng" ? "恢復組別" : "Activate"}</Button>
                                                                    </div>
                                                                    :
                                                                    <div className="btnContainer">
                                                                        <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveAgeGroupDetails} >{this.props.language !== "eng" ? "儲存組別" : "Save"}</Button>
                                                                    </div>
                                                            }
                                                        </div>

                                                    </div>
                                            )
                                        }
                                    </div>
                                    {
                                        !this.state.addNewAgeGroup ?
                                            <div className="addClassroomRow">
                                                <Button id="clickToAddNewClassroomBtn" onClick={() => this.handleAddNewAgeGroupDetails()}>{this.props.language !== "eng" ? "新增組別" : "Create Age Group"}</Button>
                                            </div>
                                            :
                                            <div className="classroomRow row">
                                                <div className="classroomNameBox col-xl-6 col-lg-6 col-md-12 mb-1">
                                                    <div className="containerOnSitesMan row">
                                                        <div className="inlineDisplayNoMargin col-3">{this.props.language !== "eng" ? "年齡組別" : "Age"}</div>
                                                        <TextField className="detailsEditingInputs col-9" variant="outlined" value={this.state.selectedAgeGroupDetails.classroom} onChange={this.handleAgeGroupNameInput} />
                                                    </div>
                                                </div>

                                                <div className="editClassroomBtn col-xl-2 col-lg-3 col-md-12">
                                                    <div className="btnContainer">
                                                        <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveNewAgeGroupDetails} >{this.props.language !== "eng" ? "儲存組別" : "Save"}</Button>
                                                    </div>
                                                </div>

                                            </div>
                                    }


                                </div>

                        }

                        {

                            <div className="siteDisplayArea col-12">
                                <div className="siteTitle">{this.props.language !== "eng" ? "運動種類詳情" : "Sport Category Details"}</div>
                                <div className="groupingFixedHeightBox ">
                                    {//RENDER ALL ACTIVE
                                        this.props.allGenres.map((genre: any) =>
                                            !genre.active ? null :
                                                <div className="classroomRow row">
                                                    <div className="genreNameBox col-xl-4 col-md-12 mb-1">
                                                        <div className="containerOnSitesMan row">
                                                            <div className="inlineDisplayNoMargin col-4">{this.props.language !== "eng" ? "運動種類" : "Sport"} : </div>
                                                            {
                                                                this.state.selectedGenreID !== genre.id ?
                                                                    <div className="inlineDisplayNoMargin detailsDisplayField col-8"> {genre.genre} </div>
                                                                    :
                                                                    <div className="col-8">
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedGenreDetails.genre} onChange={this.handleGenreNameInput} />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="genreColorBox col-xl-4 col-md-12 mb-1">
                                                        <div className="containerOnSitesMan row">
                                                            <div className="inlineDisplayNoMargin col-4">{this.props.language !== "eng" ? "代表顏色" : "Colour"} : </div>
                                                            {
                                                                this.state.selectedGenreID !== genre.id ?
                                                                    <div className="inlineDisplayNoMargin detailsDisplayField col-8" style={{ background: genre.represent_color, color: '#FFFFFF' }}> {genre.represent_color} </div>
                                                                    :
                                                                    <div className="col-8">
                                                                        <div className=" detailsDisplayField colorRepresentField" style={{ background: this.state.selectedGenreDetails.represent_color, color: '#FFFFFF' }} onClick={this.handleEditColorClick}> {this.state.selectedGenreDetails.represent_color} {this.state.editRepresentColor ? "(按此關閉色板)" : null}</div>
                                                                    </div>
                                                            }
                                                            {
                                                                this.state.selectedGenreID === genre.id && this.state.editRepresentColor ?
                                                                    <div id="colorPickerContainer" style={{ top: 0, left: (this.state.colorPickerCoordinates.x - 540) }}>
                                                                        <ChromePicker color={this.state.selectedGenreDetails.represent_color} onChange={(e) => this.handleRepresentColorChange(e)} />
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="genreStatusBox col-xl-4 col-md-12 mb-1">
                                                        <div className="containerOnSitesMan row">
                                                            <div className="inlineDisplayNoMargin col-4">{this.props.language !== "eng" ? "狀態" : "State"} :  </div>
                                                            {
                                                                this.state.selectedGenreID !== genre.id ?
                                                                    <div className="inlineDisplayNoMargin detailsDisplayField col-8"> {this.props.language !== "eng" ? genre.active ? "現正生效" : "已隱藏" : genre.active ? "Active" : "Inactive"}</div>
                                                                    :
                                                                    <div className="col-8">
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.props.language !== "eng" ? this.state.selectedGenreDetails.active ? "現正生效" : "已隱藏" : this.state.selectedAgeGroupDetails.active ? "Active" : "Inactive"} disabled />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="editClassroomBtn">
                                                        {
                                                            this.state.selectedGenreID !== genre.id ?
                                                                <div className="btnContainer">
                                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={() => this.handleEditGenreClick(genre.id)}>{this.props.language !== "eng" ? "修改" : "Edit"}</Button>
                                                                    <Button id="clickToDelClassroomBtn" onClick={() => this.handleDelGenreDetails(genre.id)}>{this.props.language !== "eng" ? "隱藏組別" : "Inactivate"}</Button>
                                                                </div>
                                                                :
                                                                <div className="btnContainer">
                                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveGenreDetails} >{this.props.language !== "eng" ? "儲存組別" : "Save"}</Button>
                                                                </div>
                                                        }
                                                    </div>

                                                </div>
                                        )
                                    }

                                    {//RENDER ALL INACTIVE
                                        this.props.allGenres.map((genre: any) =>
                                            genre.active ? null :
                                                <div className="classroomRow row">
                                                    <div className="genreNameBox col-xl-4 col-md-12 mb-1">
                                                        <div className="containerOnSitesMan row">
                                                            <div className="inlineDisplayNoMargin col-4">{this.props.language !== "eng" ? "運動種類" : "Sport"} : </div>
                                                            {
                                                                this.state.selectedGenreID !== genre.id ?
                                                                    <div className="inlineDisplayNoMargin col-8 detailsDisplayField"> {genre.genre} </div>
                                                                    :
                                                                    <div className="col-8">
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedGenreDetails.genre} onChange={this.handleGenreNameInput} />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="genreColorBox col-xl-4 col-md-12 mb-1">
                                                        <div className="containerOnSitesMan row">
                                                            <div className="inlineDisplayNoMargin col-4">{this.props.language !== "eng" ? "代表顏色" : "Colour"} : </div>
                                                            {
                                                                this.state.selectedGenreID !== genre.id ?
                                                                    <div className="inlineDisplayNoMargin col-8 detailsDisplayField" style={{ background: genre.represent_color, color: '#FFFFFF' }}> {genre.represent_color} </div>
                                                                    :
                                                                    <div className="col-8">
                                                                        <div className=" detailsDisplayField colorRepresentField" style={{ background: this.state.selectedGenreDetails.represent_color, color: '#FFFFFF' }} onClick={this.handleEditColorClick}> {this.state.selectedGenreDetails.represent_color} {this.state.editRepresentColor ? "(按此關閉色板)" : null}</div>
                                                                    </div>
                                                            }
                                                            {
                                                                this.state.selectedGenreID === genre.id && this.state.editRepresentColor ?
                                                                    <div id="colorPickerContainer" style={{ top: 0, left: (this.state.colorPickerCoordinates.x - 540) }}>
                                                                        <ChromePicker color={this.state.selectedGenreDetails.represent_color} onChange={(e) => this.handleRepresentColorChange(e)} />
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="genreStatusBox col-xl-4 col-md-12 mb-1">
                                                        <div className="containerOnSitesMan row">
                                                            <div className="inlineDisplayNoMargin col-4">{this.props.language !== "eng" ? "狀態" : "State"} :  </div>
                                                            {
                                                                this.state.selectedGenreID !== genre.id ?
                                                                    <div className="inlineDisplayNoMargin col-8 detailsDisplayField">{this.props.language !== "eng" ? genre.active ? "現正生效" : "已隱藏" : genre.active ? "Active" : "Inactive"}</div>
                                                                    :
                                                                    <div className="col-8">
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.props.language !== "eng" ? this.state.selectedGenreDetails.active ? "現正生效" : "已隱藏" : this.state.selectedAgeGroupDetails.active ? "Active" : "Inactive"} disabled />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="editClassroomBtn">
                                                        {
                                                            this.state.selectedGenreID !== genre.id ?
                                                                <div className="btnContainer">
                                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={() => this.handleEditGenreClick(genre.id)}>{this.props.language !== "eng" ? "修改" : "Edit"}</Button>
                                                                    <Button id="clickToRecoverBtn" onClick={() => this.handleDelGenreDetails(genre.id)}>{this.props.language !== "eng" ? "恢復種類" : "Activate"}</Button>
                                                                </div>
                                                                :
                                                                <div className="btnContainer">
                                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveGenreDetails} >{this.props.language !== "eng" ? "儲存種類" : "Save"}</Button>
                                                                </div>
                                                        }
                                                    </div>

                                                </div>
                                        )
                                    }

                                </div>
                                {
                                    !this.state.addNewGenre ?
                                        <div className="addClassroomRow">
                                            <Button id="clickToAddNewClassroomBtn" onClick={() => this.handleAddNewGenreDetails()}>{this.props.language !== "eng" ? "新增種類" : "Create Sport Type"}</Button>
                                        </div>
                                        :
                                        <div className="classroomRow row">
                                            <div className="classroomNameBox col-xl-5 col-md-6 mb-1">
                                                <div className="containerOnSitesMan row">
                                                    <div className="inlineDisplayNoMargin col-4">{this.props.language !== "eng" ? "運動種類" : "Sport"} : </div>
                                                    <div className="col-8">
                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedAgeGroupDetails.classroom} onChange={this.handleGenreNameInput} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="genreColorBox col-xl-5 col-md-6 mb-1">
                                                <div className="containerOnSitesMan row">
                                                    <div className="inlineDisplayNoMargin col-4">{this.props.language !== "eng" ? "代表顏色" : "Colour"} : </div>
                                                    <div className=" col-8 detailsDisplayField colorRepresentField" style={{ background: this.state.selectedGenreDetails.represent_color, color: '#FFFFFF' }} onClick={this.handleEditColorClick}> {this.state.selectedGenreDetails.represent_color} {this.state.editRepresentColor ? "(按此關閉色板)" : null}</div>

                                                    {
                                                        this.state.editRepresentColor && this.state.addNewGenre ?
                                                            <div id="colorPickerContainer" style={{ top: 0, left: (this.state.colorPickerCoordinates.x - 540) }}>
                                                                <ChromePicker color={this.state.selectedGenreDetails.represent_color} onChange={(e) => this.handleRepresentColorChange(e)} />
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="editClassroomBtn">
                                                <div className="btnContainer">
                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveNewGenreDetails} >{this.props.language !== "eng" ? "儲存種類" : "Save"}</Button>
                                                </div>
                                            </div>

                                        </div>
                                }


                            </div>

                        }
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        allAgeGroups: state.groupingManage.allAgeGroups,
        allGenres: state.groupingManage.allGenres,
        loading: state.groupingManage.loading,
        language: state.auth.language
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        loadAllGroupings: async () => dispatch(await loadGroupingsData()),
        updateAgeGroupDetails: async (ageGroupDetails: any) => dispatch(await updateAgeGroup(ageGroupDetails)),
        createNewAgeGroupDetails: async (ageGroupDetails: any) => dispatch(await createAgeGroup(ageGroupDetails)),
        delAgeGroupDetails: async (ageGroupID: number) => dispatch(await updateAgeGroupActiveSetting(ageGroupID)),
        updateGenreDetails: async (genreDetails: any) => dispatch(await updateGenre(genreDetails)),
        createNewGenreDetails: async (genreDetails: any) => dispatch(await createGenre(genreDetails)),
        delGenreDetails: async (genreID: number) => dispatch(await updateGenreActiveSetting(genreID)),
        // exitFromNoticeWindow: async () => dispatch(await classroomDeleteFailedNoticed()),
        // updateSiteDetails: async (siteDetails:any) => dispatch(await updateSiteDetails(siteDetails))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupingManage)
