import { IDiscountsManageState } from './state';
import { IDiscountsManageActions } from './actions';

const initialState = {
    allDiscounts: [],
    loading: true
}

export const discountsManageReducer = (state: IDiscountsManageState = initialState, actions: IDiscountsManageActions) => {
    switch (actions.type) {

        case "ALL_DISCOUNTS_LOADED":
            return {
                ...state,
                loading:false,
                allDiscounts: actions.discounts,
            }
        case "DISCOUNT_CREATED":
            return {
                ...state,
                loading:true

            }
        case "DISCOUNT_UPDATED":
            return {
                ...state,
                loading:true

            }
        case "DISCOUNT_ACTIVE_SETTING_MODIFIED":
            return {
                ...state,
                loading:true

            }

        default:
            return state;
    }
}