import React from 'react';
import './MassTokenEditManage.css';
import GavelIcon from '@material-ui/icons/Gavel';
import { IRootState, ThunkDispatch } from '../../store';
import { loadAllMassTokenEditRecords, submitNewMassTokenEditRecord } from './thunks';
import { connect } from 'react-redux';
import { Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import ListIcon from '@material-ui/icons/List';
import CreateMassTokenEditBox from "./CreateMassTokenEditBox";
import { loading } from './actions';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import CircularProgress from '@material-ui/core/CircularProgress';


export interface IMassTokenEditManageProps {
    // pageInit: () => void,
    loadAllMassTokenEditRecords: () => void,
    handleCreateMassTokenEditRecord: (massEditDetails: any) => void,
    triggerLoading: () => void,
    // handleSaveStaffDetails: (newStaffDetails: any) => void,
    // handleResetStaffLoginDetails: (staffUSerID:number) => void,
    language: string,
    allMassTokenEditRecords: any[],
    finishingCreateNewMassTokenEditRecord: boolean,
    allSites: any[],
    loading: boolean
}

export interface IMassTokenEditManageState {
    creatingNewMassEdit: boolean
}

class MassTokenEditManage extends React.Component<IMassTokenEditManageProps, IMassTokenEditManageState> {
    constructor(props: IMassTokenEditManageProps) {
        super(props);
        this.state = {
            creatingNewMassEdit: false
        }
    }

    private handleCreateNewStaffClick = async () => {
        await this.setState({
            creatingNewMassEdit: !this.state.creatingNewMassEdit
        })
    }
    private handleConfirmAddNewStaff = async (newMassEdit: any) => {
        await this.props.triggerLoading();
        await this.props.handleCreateMassTokenEditRecord(newMassEdit);
        await this.props.loadAllMassTokenEditRecords();
        // await this.setState({
        //     creatingNewStaff: false
        // })
    }


    // private handleConfirmSaveStaff = async (newStaffDetails:any) => {
    //     await this.props.handleCreateMassTokenEditRecord(newStaffDetails);
    //     await this.props.loadAllMassTokenEditRecords();
    // }

    // private handleConfirmResetStaffLoginDetails = async (staffUserID:number) => {
    //     await this.props.handleResetStaffLoginDetails(staffUserID);
    // }

    async componentWillMount() {
        await this.props.loadAllMassTokenEditRecords();

        // this.props.pageInit();
    }


    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="systemMainDisplayArea row">
                        <div className="systemManagePageTitle "> <AllInclusiveIcon id="systemManPageTitleIcon" /> {this.props.language !== "eng" ? "集體套劵管理" : "Mass Packages Manage"}</div>
                        {
                            !this.state.creatingNewMassEdit ? null :
                                <CreateMassTokenEditBox
                                    // employmentStatusList={this.props.allEmploymentStatus} 
                                    handleExit={this.handleCreateNewStaffClick}
                                    confirmAddNewMassEdit={(newMassEdit: any) => this.handleConfirmAddNewStaff(newMassEdit)}
                                    finishing={this.props.finishingCreateNewMassTokenEditRecord} />
                        }

                        <div className="staffListingArea col-12">
                            <div className="staffListingAreaTitleRow row">
                                <div className="staffListingAreaTitle col-lg-9 col-md-12">{this.props.language !== "eng" ? "集體套劵管理紀錄" : "Mass Package Edit Records"} <div className="staffListingAreaSubtitle">{this.props.language !== "eng" ? ` - 共 ${this.props.allMassTokenEditRecords.length} 項 - ` : ` - ${this.props.allMassTokenEditRecords.length} Records - `}</div></div>
                                <div className="col-lg-3 col-md-12">
                                    <Button id="createMassEditBtn" startIcon={<AddIcon />} onClick={this.handleCreateNewStaffClick}> {this.props.language !== "eng" ? "新增集體改動" : "Create Mass Edit"} </Button>
                                </div>
                            </div>

                            {
                                this.props.loading ?
                                    <div className="loadingContainerRow">
                                        <div className="inlineDisplay">
                                            <div className="loadingContainer">
                                                <CircularProgress id="loadingCircle" />
                                                <div className="loadingLabel">Loading ...</div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    this.props.allMassTokenEditRecords.map((record: any) =>
                                        <div className="paymentRecordRow row">
                                            <div className="vAligner col-xl-3 col-lg-4 col-md-12 col-sm-12">
                                                <div className="paymentIconContainer">
                                                    <div className="paymentIconBG">
                                                        <ListIcon id="paymentListingIcon" />
                                                    </div>
                                                </div>
                                                <div className="MassTokenEditIDContainer">
                                                    <div className="paymentPurchaseID">{this.props.language !== "eng" ? "改動操作" : " Mass Edit"} - #{record.id}</div>
                                                    <div className="paymentPurchaseIDTitle">{this.props.language !== "eng" ? "操作編號" : " Edit ID"}</div>
                                                </div>
                                            </div>
                                            <div className="MassTokenEditDaysContainer col-xl-1 offset-xl-0 col-lg-4 col-md-5 offset-md-1 col-sm-12 offset-sm-2">
                                                <div className="paymentPurchaseID"> {this.props.language !== "eng" ? "改動" : " Edit"} : {this.props.language !== "eng" ? record.edit_days >= 0 ? `+${record.edit_days} 日` : `${record.edit_days} 日` : record.edit_days >= 0 ? `+${record.edit_days}` : `${record.edit_days}`}</div>
                                                <div className="paymentPurchaseIDTitle">{this.props.language !== "eng" ? "改動日數" : "Edit Days"}</div>
                                            </div>
                                            <div className="staffEmploymentDateContainer col-xl-3 offset-xl-0 col-lg-4 col-md-6 offset-md-0 col-sm-12 offset-sm-2">
                                                <div className="paymentPurchaseID">{`" ${record.edit_remarks} "`}</div>
                                                <div className="paymentPurchaseIDTitle">{this.props.language !== "eng" ? "改動範圍" : "Site Param"} : {this.props.language !== "eng" ? record.edit_range_site_id ? `只改 ${(this.props.allSites.find((site: any) => site.id === record.edit_range_site_id)).site_key} 分校適用套券` : "只改不限分校套券" : record.edit_range_site_id ? `Edit ${(this.props.allSites.find((site: any) => site.id === record.edit_range_site_id)).site_key} Applicable Packages` : "Edit Unlimited Packages"} </div>
                                            </div>
                                            <div className="paymentPurchaseIDContainer col-xl-2 offset-xl-0 col-lg-4 offset-lg-4 col-md-5 offset-md-1 col-sm-12 offset-sm-2">
                                                <div className="paymentPurchaseID">{this.props.language !== "eng" ? "改動員工" : "ID"} - #{record.edit_staff_id}</div>
                                                <div className="paymentPurchaseIDTitle">{this.props.language !== "eng" ? "改動員工" : "Process Staff"}</div>
                                            </div>
                                            <div className="paymentCustomerNameContainer col-xl-3 offset-xl-0 col-lg-4 col-md-6 offset-md-0 col-sm-12 offset-sm-2">
                                                <div className="paymentPurchaseID">{this.props.language !== "eng" ? "改動套劵規模" : "Edit Scale"} : {record.edit_scale} {this.props.language !== "eng" ? "個" : ""}</div>
                                                <div className="paymentPurchaseIDTitle">{this.props.language !== "eng" ? "操作日" : "Date"} : {moment(record.created_at).format('YYYY-MM-DD HH:mm')}</div>

                                                {/* <div className="paymentTypeIndicator">月業額：${(staff.monthly_purchase_sum).toFixed(2)}</div> */}
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state: IRootState) => {
    return {
        allMassTokenEditRecords: state.massTokenEditManage.allMassTokenEditRecords,
        finishingCreateNewMassTokenEditRecord: state.massTokenEditManage.finishingCreateNewMassTokenEditRecord,
        loading: state.massTokenEditManage.loading,
        allSites: state.sitesClassroomsManage.allSites,
        language: state.auth.language
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        // pageInit: async () => dispatch(await staffManagePageInit()),
        loadAllMassTokenEditRecords: async () => dispatch(await loadAllMassTokenEditRecords()),
        handleCreateMassTokenEditRecord: async (massEditDetails: any) => dispatch(await submitNewMassTokenEditRecord(massEditDetails)),
        triggerLoading: async () => dispatch(await loading())
        // handleSaveStaffDetails: async (newStaffDetails: any) => dispatch(await saveEditedStaffDetails(newStaffDetails)),
        // handleResetStaffLoginDetails: async (staffUserID:number) => dispatch(await resetStaffLoginDetails(staffUserID))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MassTokenEditManage);