
export function customerPackageUsagesHistoryRelatedDataLoaded (dataResult: any) {
    return {
        type: "CUSTOMER_PACKAGE_USAGES_HISTORY_RELATED_DATA_LOADED" as "CUSTOMER_PACKAGE_USAGES_HISTORY_RELATED_DATA_LOADED",
        customerPackages: dataResult.customerPackages,
        relatedParticipation: dataResult.relatedParticipation,
        relatedClassOccupancy: dataResult.relatedClassOccupancy,
        relatedPrivateRentalOccupancy: dataResult.relatedPrivateRentalOccupancy,
        relatedTutorListOfOccupancy : dataResult.relatedTutorListOfOccupancy,
        requiredTutorInfo : dataResult.requiredTutorInfo,
        requiredPurchasedPackageDetails: dataResult.requiredPurchasedPackageDetails,
        siteList: dataResult.siteList
    }
}


type customerPackageUsageHistoryActionsCreators = typeof customerPackageUsagesHistoryRelatedDataLoaded;

export type ICustomerPackageUsageHistoryActions = ReturnType<customerPackageUsageHistoryActionsCreators>;