import React from 'react';
import { Button, TextField } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';


export interface ICreateNewStaffBoxProps {
    handleExit: () => void,
    confirmAddNewStaffDetails: (newStaffDetails: any) => void,
    employmentStatusList: any[],
    allSites: any[],
    language: string
}
export interface ICreateNewStaffBoxState {
    createStaffSteps: any[],
    activeStep: number,
    newStaffDetails: any
}

class CreateNewStaffBox extends React.Component<ICreateNewStaffBoxProps, ICreateNewStaffBoxState> {
    constructor(props: ICreateNewStaffBoxProps) {
        super(props);
        this.state = {
            createStaffSteps: (this.props.language !== "eng" ? ['輸入員工基本資料', '輸入員工身份資料', '輸入顧用資料', '確認新員工詳情'] : ['Staff Basic Details', 'Identification Info', 'Employment Details', 'Confirm Staff Details']),
            activeStep: 0,
            newStaffDetails: {
                name_chi: "",
                name_eng: "",
                sex: "",
                tel: "",
                email: "",
                address: "",
                date_of_birth: "",
                nationality: "",
                identification_doc_type: "",
                identification_doc_number: "",
                identification_doc_issue_date: "",
                identification_doc_expiry_date: "",
                remarks: "",
                mark_delete: false,
                employment_status_id: 0,
                date_of_employment: "",
                is_boss: false,
                site_id: 1
            }

        }
    }
    private handleDeclineCreateNewStaff = async () => {
        let confirmation = window.confirm((this.props.language !== "eng" ? '你確定要取消新增員工嗎？' : "Confirmation on the Declination of adding this New Staff ?"));
        if (confirmation) {
            await this.props.handleExit();
        }
    }
    private handlePreviousStepClick = async () => {
        await this.setState({
            activeStep: this.state.activeStep - 1
        })
    }

    private handleNextStepClick = async () => {
        await this.setState({
            activeStep: this.state.activeStep + 1
        })
    }

    private handleNewStaffDetailsInput = (field: string) => async (event: any) => {

        if (field === 'is_boss') {
            await this.setState({
                newStaffDetails: {
                    ...this.state.newStaffDetails,
                    [field]: !this.state.newStaffDetails[field]
                }
            })
            return;
        }
        await this.setState({
            newStaffDetails: {
                ...this.state.newStaffDetails,
                [field]: event.target.value
            }
        })
    }

    private handleConfirmAddClick = async () => {
        let newStaffDetails = this.state.newStaffDetails;
        if (newStaffDetails.address === '') {
            newStaffDetails.address = null
        }
        if (newStaffDetails.identification_doc_type === '') {
            newStaffDetails.identification_doc_type = null
        }
        if (newStaffDetails.identification_doc_number === '') {
            newStaffDetails.identification_doc_number = null
        }
        if (newStaffDetails.identification_doc_issue_date === '') {
            newStaffDetails.identification_doc_issue_date = null
        }
        if (newStaffDetails.identification_doc_expiry_date === '') {
            newStaffDetails.identification_doc_expiry_date = null
        }
        if (newStaffDetails.remarks === '') {
            newStaffDetails.remarks = null
        }
        await this.props.confirmAddNewStaffDetails(newStaffDetails);
    }

    render() {
        return (
            <div className="createStaffArea row">
                <div className="staffListingAreaTitleRow col-12">
                    <div className="staffListingAreaTitle">{this.props.language !== "eng" ? "新增員工：" : "Create New Staff"} </div>
                    <Button id="cxlCreateStaffBtn" startIcon={<CloseIcon />} onClick={this.handleDeclineCreateNewStaff}> {this.props.language !== "eng" ? "取消新增：" : "Back"} </Button>
                </div>
                <div className="stepsIndicatorRow col-12">
                    <Stepper id="stepIconColor" activeStep={this.state.activeStep} alternativeLabel>
                        {this.state.createStaffSteps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                <div className="fixedHeightWindowOnCreateNewStaffBox col-12">
                    <div className="mainInputRow row">
                        {
                            this.state.activeStep === 0 ?
                                //FIRST STEP
                                <div className="tokenContentInputContainer col-12">
                                    <div className="inputRowOnAddPackageComponent  row">
                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">-{this.props.language !== "eng" ? "中文姓名：" : "Chi. Name"} :</div>
                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                            <TextField className="packageNameInput" variant="outlined" value={this.state.newStaffDetails.name_chi} onChange={this.handleNewStaffDetailsInput('name_chi')} />
                                        </div>
                                    </div>
                                    <div className="inputRowOnAddPackageComponent row">
                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "英文姓名：" : "Eng. Name"} :</div>
                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                            <TextField className="packageNameInput" variant="outlined" value={this.state.newStaffDetails.name_eng} onChange={this.handleNewStaffDetailsInput('name_eng')} />
                                        </div>
                                    </div>
                                    <div className="inputRowOnAddPackageComponent row">
                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "員工性別：" : "SEX"} :</div>
                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                className="packageNameInput"
                                                onChange={this.handleNewStaffDetailsInput('sex')}
                                                variant="outlined"
                                            >
                                                <MenuItem value={'M'}>M</MenuItem>
                                                <MenuItem value={'F'}>F</MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="inputRowOnAddPackageComponent row">
                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "聯絡電話：" : "TEL"} :</div>
                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                            <TextField className="packageNameInput" variant="outlined" value={this.state.newStaffDetails.tel} onChange={this.handleNewStaffDetailsInput('tel')} />
                                        </div>
                                    </div>
                                    <div className="inputRowOnAddPackageComponent row">
                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "聯絡電郵：" : "Email"} :</div>
                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                            <TextField className="packageNameInput" variant="outlined" value={this.state.newStaffDetails.email} onChange={this.handleNewStaffDetailsInput('email')} />
                                        </div>
                                    </div>
                                    <div className="inputRowOnAddPackageComponent row">
                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">-{this.props.language !== "eng" ? "聯絡地址：" : "Address"} :</div>
                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                            <TextField className="packageNameInput" variant="outlined" value={this.state.newStaffDetails.address} onChange={this.handleNewStaffDetailsInput('address')} />
                                        </div>
                                    </div>
                                </div>
                                :
                                this.state.activeStep === 1 ?
                                    <div className="tokenContentInputContainer col-12">
                                        <div className="inputRowOnAddPackageComponent row">
                                            <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "出生日期：" : "DOB."} :</div>
                                            <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                <TextField className="packageNameInput" variant="outlined" type="date" value={this.state.newStaffDetails.date_of_birth} onChange={this.handleNewStaffDetailsInput('date_of_birth')} />
                                            </div>
                                        </div>
                                        <div className="inputRowOnAddPackageComponent row">
                                            <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "國藉簡稱：" : "Nationality"} :</div>
                                            <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                <TextField className="packageNameInput" variant="outlined" value={this.state.newStaffDetails.nationality} onChange={this.handleNewStaffDetailsInput('nationality')} />
                                            </div>
                                        </div>
                                        <div className="inputRowOnAddPackageComponent row">
                                            <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">-{this.props.language !== "eng" ? "身份文件：" : "Identification Doc."} :</div>
                                            <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">

                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    className="packageNameInput"
                                                    onChange={this.handleNewStaffDetailsInput('identification_doc_type')}
                                                    variant="outlined"
                                                >
                                                    <MenuItem value={'HKID'}>HKID</MenuItem>
                                                    <MenuItem value={'PASSPORT'}>PASSPORT</MenuItem>
                                                    <MenuItem value={'OTHER'}>OTHER</MenuItem>
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="inputRowOnAddPackageComponent row">
                                            <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">-{this.props.language !== "eng" ? "文件編號" : " Doc. Number"} :</div>
                                            <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                <TextField className="packageNameInput" variant="outlined" value={this.state.newStaffDetails.identification_doc_number} onChange={this.handleNewStaffDetailsInput('identification_doc_number')} />
                                            </div>
                                        </div>
                                        <div className="inputRowOnAddPackageComponent row">
                                            <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">-{this.props.language !== "eng" ? "發出日期" : " Issue Date"} :</div>
                                            <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                <TextField className="packageNameInput" variant="outlined" type="date" value={this.state.newStaffDetails.identification_doc_issue_date} onChange={this.handleNewStaffDetailsInput('identification_doc_issue_date')} />
                                            </div>
                                        </div>
                                        <div className="inputRowOnAddPackageComponent row">
                                            <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">-{this.props.language !== "eng" ? "到期日期" : " Exp. Date"} :</div>
                                            <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                <TextField className="packageNameInput" variant="outlined" type="date" value={this.state.newStaffDetails.identification_doc_expiry_date} onChange={this.handleNewStaffDetailsInput('identification_doc_expiry_date')} />
                                            </div>
                                        </div>
                                        <div className="inputRowOnAddPackageComponent row">
                                            <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">-{this.props.language !== "eng" ? "其他備注" : " Remarks"} :</div>
                                            <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                <TextField className="packageNameInput" variant="outlined" type="date" multiline rows={4} value={this.state.newStaffDetails.remarks} onChange={this.handleNewStaffDetailsInput('remarks')} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    this.state.activeStep === 2 ?
                                        <div className="tokenContentInputContainer col-12">
                                            <div className="inputRowOnAddPackageComponent row">
                                                <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "顧用模式" : " Employment Type"} :</div>
                                                <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">

                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    className="packageNameInput"
                                                    onChange={this.handleNewStaffDetailsInput('employment_status_id')}
                                                    variant="outlined"
                                                >
                                                    {
                                                        this.props.employmentStatusList.map((status: any) =>
                                                            <MenuItem value={status.id}>{status.status}</MenuItem>
                                                        )
                                                    }

                                                </Select>
                                                </div>
                                            </div>
                                            <div className="inputRowOnAddPackageComponent row">
                                                <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "顧用日期" : " Employment Date"} :</div>
                                                <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                    <TextField className="packageNameInput" variant="outlined" type="date" value={this.state.newStaffDetails.date_of_employment} onChange={this.handleNewStaffDetailsInput('date_of_employment')} />
                                                </div>
                                            </div>
                                            <div className="inputRowOnAddPackageComponent row">
                                                <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*超級權限 :</div>
                                                <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                    <FormControl variant="outlined" className="packageTokenBasedPackageInput">
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={this.state.newStaffDetails.is_boss}
                                                                    onChange={this.handleNewStaffDetailsInput('is_boss')}
                                                                    name="tokenBasedPackage"
                                                                    color="primary"
                                                                />
                                                            }

                                                            label="超級權限"
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className="inputRowOnAddPackageComponent row">
                                                <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "派駐分校" : "Site"} :</div>
                                                <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        className="packageNameInput"
                                                        value={this.state.newStaffDetails.site_id}
                                                        onChange={this.handleNewStaffDetailsInput('site_id')}
                                                        variant="outlined"

                                                    >
                                                        {
                                                            this.props.allSites.map((site: any) =>
                                                                <MenuItem value={site.id}>{site.site_key}</MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                </div>
                                            </div>

                                        </div>
                                        :
                                        this.state.activeStep === 3 ?
                                            <div className="tokenContentInputContainer col-12">
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">-{this.props.language !== "eng" ? "中文姓名：" : "Chi. Name"} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                        <TextField disabled className="packageNameInput" variant="outlined" value={this.state.newStaffDetails.name_chi} onChange={this.handleNewStaffDetailsInput('name_chi')} />
                                                    </div>
                                                </div>
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "英文姓名：" : "Eng. Name"} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                        <TextField disabled className="packageNameInput" variant="outlined" value={this.state.newStaffDetails.name_eng} onChange={this.handleNewStaffDetailsInput('name_eng')} />
                                                    </div>
                                                </div>
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "員工性別：" : "SEX"} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">

                                                    <Select
                                                        disabled
                                                        value={this.state.newStaffDetails.sex}
                                                        labelId="demo-simple-select-outlined-label"
                                                        className="packageNameInput"
                                                        onChange={this.handleNewStaffDetailsInput('sex')}
                                                        variant="outlined"
                                                    >
                                                        <MenuItem value={'M'}>M</MenuItem>
                                                        <MenuItem value={'F'}>F</MenuItem>
                                                    </Select>                                    
                                                    </div>
                                                    </div>
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "聯絡電話" : "TEL"} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                        <TextField disabled className="packageNameInput" variant="outlined" value={this.state.newStaffDetails.tel} onChange={this.handleNewStaffDetailsInput('tel')} />
                                                    </div>
                                                </div>
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "聯絡電郵" : "Email"} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                        <TextField disabled className="packageNameInput" variant="outlined" value={this.state.newStaffDetails.email} onChange={this.handleNewStaffDetailsInput('email')} />
                                                    </div>
                                                </div>
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">-{this.props.language !== "eng" ? "聯絡地址" : "Address"} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                        <TextField disabled className="packageNameInput" variant="outlined" value={this.state.newStaffDetails.address} onChange={this.handleNewStaffDetailsInput('address')} />
                                                    </div>
                                                </div>
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "出生日期" : "DOB."} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                        <TextField disabled className="packageNameInput" variant="outlined" type="date" value={this.state.newStaffDetails.date_of_birth} onChange={this.handleNewStaffDetailsInput('date_of_birth')} />
                                                    </div>
                                                </div>
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "國藉簡稱" : "Nationality"} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                        <TextField disabled className="packageNameInput" variant="outlined" value={this.state.newStaffDetails.nationality} onChange={this.handleNewStaffDetailsInput('nationality')} />
                                                    </div>
                                                </div>
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">-{this.props.language !== "eng" ? "身份文件" : "Identification Doc."} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                    <Select
                                                        disabled
                                                        labelId="demo-simple-select-outlined-label"
                                                        className="packageNameInput"
                                                        onChange={this.handleNewStaffDetailsInput('identification_doc_type')}
                                                        variant="outlined"
                                                        value={this.state.newStaffDetails.identification_doc_type}
                                                    >
                                                        <MenuItem value={'HKID'}>HKID</MenuItem>
                                                        <MenuItem value={'PASSPORT'}>PASSPORT</MenuItem>
                                                        <MenuItem value={'OTHER'}>OTHER</MenuItem>
                                                    </Select>                                    
                                                    </div>
                                                    </div>
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">-{this.props.language !== "eng" ? "文件編號" : " Doc. Number"} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                        <TextField disabled className="packageNameInput" variant="outlined" value={this.state.newStaffDetails.identification_doc_number} onChange={this.handleNewStaffDetailsInput('identification_doc_number')} />
                                                    </div>
                                                </div>
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">-{this.props.language !== "eng" ? "發出日期" : " Issue Date"} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                        <TextField disabled className="packageNameInput" variant="outlined" type="date" value={this.state.newStaffDetails.identification_doc_issue_date} onChange={this.handleNewStaffDetailsInput('identification_doc_issue_date')} />
                                                    </div>
                                                </div>
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">-{this.props.language !== "eng" ? "到期日期" : " Exp. Date"} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                        <TextField disabled className="packageNameInput" variant="outlined" type="date" value={this.state.newStaffDetails.identification_doc_expiry_date} onChange={this.handleNewStaffDetailsInput('identification_doc_expiry_date')} />
                                                    </div>
                                                </div>
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">-{this.props.language !== "eng" ? "其他備注" : " Remarks"} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                        <TextField disabled className="packageNameInput" variant="outlined" type="date" multiline rows={4} value={this.state.newStaffDetails.remarks} onChange={this.handleNewStaffDetailsInput('remarks')} />
                                                    </div>
                                                </div>
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "顧用模式" : " Employment Type"} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                    <Select
                                                        disabled
                                                        labelId="demo-simple-select-outlined-label"
                                                        className="packageNameInput"
                                                        onChange={this.handleNewStaffDetailsInput('employment_status_id')}
                                                        variant="outlined"
                                                        value={this.state.newStaffDetails.employment_status_id}
                                                    >
                                                        {
                                                            this.props.employmentStatusList.map((status: any) =>
                                                                <MenuItem value={status.id}>{status.status}</MenuItem>
                                                            )
                                                        }

                                                    </Select>
                                                    </div>
                                                </div>
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "顧用日期" : " Employment Date"} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                        <TextField disabled className="packageNameInput" variant="outlined" type="date" value={this.state.newStaffDetails.date_of_employment} onChange={this.handleNewStaffDetailsInput('date_of_employment')} />
                                                    </div>
                                                </div>
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "超級權限" : "SAuth"} :</div>
                                                    {
                                                        this.props.language !== "eng" ?
                                                            <FormControl variant="outlined" className="packageTokenBasedPackageInput">
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            disabled
                                                                            checked={this.state.newStaffDetails.is_boss}
                                                                            onChange={this.handleNewStaffDetailsInput('is_boss')}
                                                                            name="tokenBasedPackage"
                                                                            color="primary"
                                                                        />
                                                                    }

                                                                    label="超級權限"
                                                                />
                                                            </FormControl>
                                                            :
                                                            <FormControl variant="outlined" className="packageTokenBasedPackageInput">
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            disabled
                                                                            checked={this.state.newStaffDetails.is_boss}
                                                                            onChange={this.handleNewStaffDetailsInput('is_boss')}
                                                                            name="tokenBasedPackage"
                                                                            color="primary"
                                                                        />
                                                                    }

                                                                    label="SAuth"
                                                                />
                                                            </FormControl>
                                                    }
                                                </div>
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">*{this.props.language !== "eng" ? "派駐分校" : "Site"} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        className="packageNameInput"
                                                        value={this.state.newStaffDetails.site_id}
                                                        onChange={this.handleNewStaffDetailsInput('site_id')}
                                                        variant="outlined"
                                                        disabled
                                                    >
                                                        {this.props.allSites.map((site: any) =>
                                                            <MenuItem value={site.id}>{site.site_key}</MenuItem>
                                                        )}
                                                    </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                        }
                    </div>
                </div>

                <div className="modifyStepsRow">
                    {this.state.activeStep === 0 ? <Button id="stepButtonNoShow" disabled>{this.props.language !== "eng" ? "上一步" : "Back"}</Button> : <Button id="stepButton" onClick={this.handlePreviousStepClick}>{this.props.language !== "eng" ? "上一步" : "Back"}</Button>}
                    {this.state.activeStep === 0 && this.state.newStaffDetails.name_eng !== '' && this.state.newStaffDetails.sex !== '' && this.state.newStaffDetails.tel !== '' && this.state.newStaffDetails.email !== '' ?
                        <Button id="stepButton" onClick={this.handleNextStepClick}>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>
                        :
                        this.state.activeStep === 1 && this.state.newStaffDetails.date_of_birth !== '' && this.state.newStaffDetails.nationality !== '' ?
                            <Button id="stepButton" onClick={this.handleNextStepClick}>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>
                            :
                            this.state.activeStep === 2 && this.state.newStaffDetails.employment_status_id !== 0 && this.state.newStaffDetails.date_of_employment !== '' && this.state.newStaffDetails.site_id !== 0 ?
                                <Button id="stepButton" onClick={this.handleNextStepClick}>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>
                                :
                                this.state.activeStep === 3 ?
                                    <Button id="stepButton" onClick={this.handleConfirmAddClick}>{this.props.language !== "eng" ? "確定新增員工" : "Create New Staff"}</Button>
                                    :
                                    <Button id="stepButtonDisabled" onClick={this.handleNextStepClick} disabled>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>


                    }
                </div>

            </div>
        )
    }
}

export default CreateNewStaffBox;