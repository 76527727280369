import React from "react";
import "./ProcessNoTokenBookingWindow.css";
import moment from "moment";
import { Button } from "@material-ui/core";
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';


export interface IProcessNoTokenBookingWindowProps {
    language: string,
    previousNoTokenBookings: any[],
    newlyPurchasedToken: any[],
    ageGroupLists: any[],
    genreLists: any[],
    handleSubmit: (processData:any) => void
}

export interface IProcessNoTokenBookingWindowState {
    selectedPackageID:number
}

class IProcessNoTokenBookingWindow extends React.Component<IProcessNoTokenBookingWindowProps, IProcessNoTokenBookingWindowState>{
    constructor(props: IProcessNoTokenBookingWindowProps) {
        super(props);
        this.state = {
            selectedPackageID: 0
        }
    }

    private handlePackageClick = async(packageID:number) => {
        if(this.state.selectedPackageID === packageID){
            await this.setState({
                selectedPackageID: 0
            })
        } else {
            await this.setState({
                selectedPackageID: packageID
            })
        }
    }

    private handleSubmitClick = async () => {
        let processData = {
            previousNoTokenBooking: this.props.previousNoTokenBookings[0],
            selectedPackageID: this.state.selectedPackageID
        }
        await this.props.handleSubmit(processData);
    }


    render() {
        return (
            <div className="processNoTokenBookingWindow">
                <div className="titleRow">
                    <div className="blockTitleOnProcessNoTokenBookingWindow">{this.props.language !== "eng"? "發現有未處理的無套劵課堂預約" : "Handle Previous No-Package Booking"}</div>
                </div>
                <div className="previousNoTokenBookingRow">
                    <div className="previousNoTokenBookingDetailsCard">
                        <div className="previousNoTokenBookingDetailsCol">
                            <div className="previousNoTokenBookingDetails">{this.props.language !== "eng"? "日期" : "Date"} : {moment(this.props.previousNoTokenBookings[0].classDetails.start_time).format('YYYY-MM-DD')}</div>
                            <div className="previousNoTokenBookingDetails">{this.props.language !== "eng"? "課堂時間" : "Class Time"} : {moment(this.props.previousNoTokenBookings[0].classDetails.start_time).format('HH:mm')} - {moment(this.props.previousNoTokenBookings[0].classDetails.end_time).format('HH:mm')}</div>
                        </div>
                        <div className="previousNoTokenBookingDetailsCol">
                            <div className="previousNoTokenBookingDetails">{this.props.language !== "eng"? "課堂種類" : "Sport"} : {(this.props.genreLists.find((genre: any) => genre.id === this.props.previousNoTokenBookings[0].classDetails.genre_id)).genre} | {(this.props.ageGroupLists.find((age: any) => age.id === this.props.previousNoTokenBookings[0].classDetails.age_group_id)).age_group}</div>
                            <div className="previousNoTokenBookingDetails">{this.props.language !== "eng"? "導師名稱" : "Tutor"} : {this.props.previousNoTokenBookings[0].classDetails.tutorNameEng ? this.props.previousNoTokenBookings[0].classDetails.tutorNameEng : this.props.previousNoTokenBookings[0].classDetails.tutorNameChi}</div>
                        </div>
                        <div className="previousNoTokenBookingDetailsCol">
                            <div className="previousNoTokenBookingDetails">{this.props.language !== "eng"? "出席狀況" : "Attendance"} : {this.props.previousNoTokenBookings[0].absent === null ? " - 未有點名紀錄" : !this.props.previousNoTokenBookings[0].absent ? " - 已出席" : this.props.previousNoTokenBookings[0].absent_reason_id ? " - 已請假" : " - 已缺席"}</div>
                            <div className="previousNoTokenBookingDetails">{this.props.language !== "eng"? "處理員工" : "Process Staff"} : # {this.props.previousNoTokenBookings[0].process_staff_id}</div>
                        </div>
                    </div>
                </div>
                <div className="titleRow">
                    <div className="blockTitleOnProcessNoTokenBookingWindow">{this.props.language !== "eng"? "以下顯示於是次選購可供選擇用以補充以上紀錄的課程套券" : "Applicable Package for Handling This Booking"}</div>
                </div>
                <div className="suitableTokenDisplayRow">
                    {
                        this.props.newlyPurchasedToken.map((tokenPackage:any)=>
                        this.state.selectedPackageID === tokenPackage.id ? 
                            <div className="selectedPackageBlockOnProcessNoTokenBookingWindow" onClick={(e)=>this.handlePackageClick(tokenPackage.id)}>
                                <div className="packageIconContainer">
                                    <ConfirmationNumberIcon id="packageIconOnProcessNoTokenBookingWindow"/>
                                </div>
                                <div className="packageDetailsOnProcessNoTokenBookingWindow">{tokenPackage.package_name}</div>
                                <div className="packageDetailsOnProcessNoTokenBookingWindow">{tokenPackage.token_based_package? "扣數類形套券" : "到期類形套券"}</div>
                                <div className="packageDetailsOnProcessNoTokenBookingWindow">{this.props.language !== "eng"? "有效套券數" : "Active Token"} : {tokenPackage.token_amount}</div>
                            </div>
                            :
                            <div className="packageBlockOnProcessNoTokenBookingWindow" onClick={(e)=>this.handlePackageClick(tokenPackage.id)}>
                                <div className="packageIconContainer">
                                    <ConfirmationNumberIcon id="packageIconOnProcessNoTokenBookingWindow"/>
                                </div>
                                <div className="packageDetailsOnProcessNoTokenBookingWindow">{tokenPackage.package_name}</div>
                                <div className="packageDetailsOnProcessNoTokenBookingWindow">{tokenPackage.token_based_package? "扣數類形套券" : "到期類形套券"}</div>
                                <div className="packageDetailsOnProcessNoTokenBookingWindow">{this.props.language !== "eng"? "有效套券數" : "Active Token"} : {tokenPackage.token_amount}</div>

                            </div>
                        )
                    }
                </div>
                <div className="confirmBtnRowOnProcessNoTokenBookingWindow">
                    <Button id="confirmBtnOnProcessNoTokenBookingWindow" onClick={this.handleSubmitClick}>{this.props.language !== "eng"? "確定完成操作" : "Finish Process"}</Button>
                </div>
            </div>
        )
    }
}

export default IProcessNoTokenBookingWindow