import React from "react";
import { IRootState, ThunkDispatch } from "../store";
import { connect } from "react-redux";
import './CustomerAddBox.css'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import PublishIcon from '@material-ui/icons/Publish';
import { Button } from "@material-ui/core";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import moment from "moment";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CloseIcon from '@material-ui/icons/Close';

import { addThisTutorInfos } from "./thunks";




export interface ITutorAddBoxProps {
    genreTypesBank: any,
    ageGroupsBank: any,
    employmentStatusBank: any,
    handleAddExit: () => void,
    handleSaveAdd: (mainBody: any) => void
    staffId: number,
}

export interface ITutorAddBoxState {
    showAddBox: boolean,
    customer_name_eng: string,
    customer_name_chi: string,
    customer_sex: string,
    customer_tel: string,
    customer_email: string,
    referringCustomerID: number
}

class CustomerAddBox extends React.Component<ITutorAddBoxProps, ITutorAddBoxState> {
    constructor(props: ITutorAddBoxProps) {
        super(props);
        this.state = {
            showAddBox: true,
            customer_name_eng: '-',
            customer_name_chi: '-',
            customer_sex: '-',
            customer_tel: '-',
            customer_email: '-',
            referringCustomerID: 0

        }
    }

    private handleInput1 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            customer_name_eng: event.currentTarget.value
        })
    }

    private handleInput2 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            customer_name_chi: event.currentTarget.value
        })
    }
    private handleInput3 = (event: any) => {
        this.setState({
            customer_sex: event.target.value
        })
    }
    private handleInput4 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            customer_tel: event.currentTarget.value
        })
    }
    private handleInput5 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            customer_email: event.currentTarget.value
        })
    }

    private handleInput6 = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if(parseInt(event.currentTarget.value) > 0) {
            await this.setState({
                referringCustomerID: parseInt(event.currentTarget.value)
            })
        } else {
            await this.setState({
                referringCustomerID: 0
            })
        }
    }

    private handleDeniedChanges = () => {
        if (this.state.customer_name_eng !== '-',
            this.state.customer_name_chi === '-',
            this.state.customer_sex === '-',
            this.state.customer_tel === '-',
            this.state.customer_email === '-') {
            this.props.handleAddExit(); //this case without any change.
        } else {
            let res = window.confirm('Discard Changes?');
            if (res) {
                this.props.handleAddExit();
            }
        }
    }

    private handleSave = async () => {
        //JWT PAYLOAD
        function parseJwt(token: string) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        };
        let clientToken = localStorage.getItem('token');
        let tokenPayload: any = {}
        if (clientToken) {
            tokenPayload = parseJwt(clientToken)
        }
        if (this.state.customer_name_eng !== '-' &&
            this.state.customer_name_chi !== '-' &&
            this.state.customer_sex !== '-' &&
            this.state.customer_tel !== '-' &&
            this.state.customer_email !== '-') {
            //[CASE] without empty field
            let mainBody = {
                name_eng: this.state.customer_name_eng,
                name_chi: this.state.customer_name_chi,
                sex: this.state.customer_sex,
                tel: this.state.customer_tel,
                email: this.state.customer_email,
                intake_date: new Date(),
                intake_staff_id: tokenPayload.id,
                referral_code: makeReferralCode(10),
                referringCustomerID: this.state.referringCustomerID
            }
            await this.props.handleSaveAdd(mainBody);
            this.props.handleAddExit();

        } else {
            alert('Please Fill in the Required Fields.')
        }


    }


    render() {
        return (
            <div>
                <div className="baseAreaOfAddNewCustomer">
                    <div className="navDisplayRow">
                        <h3 className="addNewBookingWindowTitle">新增顧客</h3>
                        <CloseIcon id="addNewBookingWindowCloseBtn" onClick={this.handleDeniedChanges} />
                    </div>
                    <form className="newCustomerInputFormContainer">
                        <div>
                            
                            <div className="row">

                                <div className="col-12 leftDivider">
                                    <div className="row">
                                        <div className="col-12 sectionAligner">
                                            <div className="sectionHeader">
                                                基礎資料
                                        </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 sectionAligner">
                                            <TextField className="Inputs " id="englishName" label="*顧客英文姓名" variant="outlined" onChange={this.handleInput1} />
                                        </div>
                                        <div className="col-12 sectionAligner" id="chiName">
                                            <TextField className="Inputs " id="chineseName" label="*顧客中文姓名" variant="outlined" onChange={this.handleInput2} />
                                        </div>
                                        <div className="col-12 sectionAligner">
                                            <FormControl id="ageInput" className="selectInputs ">
                                                <InputLabel id="demo-simple-select-outlined-label">
                                                    *性別
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    onChange={this.handleInput3}
                                                >
                                                    <MenuItem value={'M'}>M</MenuItem>
                                                    <MenuItem value={'F'}>F</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 sectionAligner">
                                            <TextField className="Inputs " id="tel" label="*聯絡電話" variant="outlined" onChange={this.handleInput4} />
                                        </div>
                                        <div className="col-12 sectionAligner">
                                            <TextField className="Inputs " id="email" label="*Email" variant="outlined" onChange={this.handleInput5} />
                                        </div>
                                        <div className="col-12 sectionAligner">
                                            <TextField className="Inputs " id="email" label="Referral 顧客 ID" variant="outlined" onChange={this.handleInput6} />
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="row">

                                <div className="col-12 leftDivider">
                                    <div className="topDivider">
                                        <Button id="clickToAddNewCustomerBtn" startIcon={<PlaylistAddCheckIcon />} onClick={this.handleSave}>確定新增顧客</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <div className="topClass2" onClick={this.handleDeniedChanges}></div> */}
            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        genreTypesBank: state.tutors.genreTypeList,
        ageGroupsBank: state.tutors.ageGroupList,
        employmentStatusBank: state.tutors.employmentStatusLists,
        staffId: state.auth.staffId
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        handleSaveAdd: async (mainBody: any) => dispatch(await addThisTutorInfos(mainBody))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAddBox);

function makeReferralCode(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}