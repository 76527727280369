import { ThunkDispatch } from "../store";
import { scheduleLoaded, occupancyOfTheDateLoaded, newOccpancySubmittedWithFailedUniqueCheck, handleUSGConfirmationUseUSGClick, handleUSGConfirmationNewUSGClick, occupancyOf7DaysLoaded, occupancyOf7DaysCloned } from "../Schedule/actions";

export async function schedulePageInit(siteID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/schedule/loadBasicInfo?siteID=${siteID}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,

        }
    })
    let result: any = await res.json()
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    } else {
        console.log("result.classrooms", result.classrooms);
        return async (dispatch: ThunkDispatch) => {

            dispatch(scheduleLoaded(result.openingTimes, result.sites,result.classrooms, result.occupancyOfTheDay, result.cxlOccupancyOfTheDay, result.genres, result.ageGroups, result.tutors, result.tutorList, result.cxlTutorLists,result.roomRentingPrivateCustomerList, result.roomRentingPrivateCustomerListForCxl));

        }
    }
}
export async function loadOccupancyOfNewDateSelected(date: string, siteID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/schedule/loadOccupancyOfDate?date=${date}&siteID=${siteID}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    })
    let result: any = await res.json()
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    } else {

        return async (dispatch: ThunkDispatch) => {
            dispatch(occupancyOfTheDateLoaded(result.occupancyOfTheDay, result.cxlOccupancyOfTheDay,result.tutorList, result.tutorListForCxl, result.roomRentingPrivateCustomerList, result.roomRentingPrivateCustomerListForCxl));
        }


    }
}
export async function loadOccupancyOf7DaysSelected(date: string, siteID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/schedule/loadOccupancyOf7Days?date=${date}&siteID=${siteID}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    })
    let result: any = await res.json()
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {

            console.log(result);
        }
    } else {

        return async (dispatch: ThunkDispatch) => {
            dispatch(occupancyOf7DaysLoaded(result.occupancyOf7Days, result.tutorListOf7Days, result.roomRentingPrivateCustomerListOf7Days, result.cloneWeekID));
        }


    }
}

export async function clone7DaysClassOccupancies(date:string, siteID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/schedule/clone7DaysClassOccupancy`, {
        method:'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            date: date,
            siteID: siteID
        })
    }) 

    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
            return async (dispatch: ThunkDispatch) => {
                dispatch(occupancyOf7DaysCloned());
            }
        
    }
}

export async function newOccupancySubmission(newOccupancyDetails: any, newUniqueSchedulingGroupInfo: any) {

    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/schedule/addNewOccupancyAndTutorList`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            newOccupancyDetails: newOccupancyDetails,
            newUniqueSchedulingGroupInfo: newUniqueSchedulingGroupInfo
        })
    })
    let result: any = await res.json()
    if (res.status === 500) {
        return () => {

            console.log(result);
        }
    } else {
        if (result.result === 'Normal') {
            return () => {
                // loadOccupancyOfNewDateSelected(newOccupancyDetails.date, )
            }
        } else {
            return async (dispatch: ThunkDispatch) => {
                dispatch(newOccpancySubmittedWithFailedUniqueCheck(result));
            }
        }
    }
}

export async function newOccupancySubmissionWithUSGConfirmation (newOccupancyDetails:any, newUniqueSchedulingGroupID : number) {

    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/schedule/addNewOccupancyAndTutorListWithUSGConfirmed`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            newOccupancyDetails: newOccupancyDetails,
            USGID: newUniqueSchedulingGroupID
        })
    })

    let result: any = await res.json();

    if (res.status === 500) {
        return () => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(handleUSGConfirmationUseUSGClick());
        }
    }
}

export async function newOccupancySubmissionWithNewUSGAfterConfirmation (newOccupancyDetails:any, newUniqueSchedulingGroupInfo : any) {

    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/schedule/addNewOccpancyAndTutorListWithNewUSGAfterConfirmation`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            newOccupancyDetails: newOccupancyDetails,
            newUniqueSchedulingGroupInfo: newUniqueSchedulingGroupInfo
        })
    })

    let result: any = await res.json();

    if (res.status === 500) {
        return () => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(handleUSGConfirmationNewUSGClick());
        }
    }
}



