import { ThunkDispatch } from '../../store';
import { allTermsLoaded, termCreated, termUpdated, termActiveSettingModified} from './actions';

export async function loadTermsData() {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/loadAllTerms`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(allTermsLoaded(result.allTerms));
        }
    }
}
export async function createTerm(termDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/createNewTerm`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            termDetails: termDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(termCreated());
        }
    }
}
export async function updateTerm(termDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/updateTermDetails`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            termDetails: termDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(termUpdated());
        }
    }
}
export async function updateTermActiveSetting(termID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/modifyTermActiveSetting`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            termID: termID
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(termActiveSettingModified());
        }
    }
}
