export function homePageDataLoad (graphData: any) {
    return {
        type: "HOME_PAGE_DATA_LOADED" as "HOME_PAGE_DATA_LOADED",
        graphData
    }
}
export function graphRefresh(){
    return{
        type: "GRAPH_REFRESHING" as "GRAPH_REFRESHING"
    }
}

type HomeActionsCreators = typeof homePageDataLoad | typeof graphRefresh;

export type IHomeActions = ReturnType<HomeActionsCreators>;