import React from 'react';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import { IRootState, ThunkDispatch } from '../../store';
import { connect } from 'react-redux';
import moment from 'moment';
import { loadIncomeOfSiteOfSelectedDate } from './thunks';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import { Button } from 'react-bootstrap'
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { loading } from './actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LineChart, Line, Tooltip } from 'recharts';
import SpecificPurchaseDetailsBox from '../../Customers/SpecificPurchaseDetailsBox/SpecificPurchaseDetailsBox';

export interface IIncomeListingProps {
    language: string,
    allPaymentsWithinPeriod: any[],
    allPaymentCustomer: any[],
    allSites: any[],
    graphData: any[],
    loading: boolean,
    loadPaymentsOfSelectedDate: (startDate: string, endDate: string, siteID: number) => void,
    triggerLoading: () => void
}

export interface IIncomeListingState {
    selectedStartDate: string,
    selectedEndDate: string,
    siteID: number,
    selectedIncomeType: string,
    //SpecificPurchaseDetails
    showSpecificPurchaseDetailsBox: boolean,
    selectedPurchaseIDForSpecificPurchaseDetailsBox: number,
    selectedCustomerID: number
}

class IncomeListing extends React.Component<IIncomeListingProps, IIncomeListingState> {
    constructor(props: IIncomeListingProps) {
        super(props);
        this.state = {
            selectedStartDate: moment(`${moment().format('YYYY-MM-DD')} 00:00:00+08`).toISOString(),
            selectedEndDate: moment(`${moment().format('YYYY-MM-DD')} 23:59:00+08`).toISOString(),
            siteID: 0,
            selectedIncomeType: 'all',
            showSpecificPurchaseDetailsBox: false,
            selectedPurchaseIDForSpecificPurchaseDetailsBox: 0,
            selectedCustomerID: 0
        }
    }

    private handleStartDateInput = async (date: Date | null) => {
        await this.setState({
            selectedStartDate: moment(`${date}`).toISOString()
        })
    }
    private handleEndDateInput = async (date: Date | null) => {
        await this.setState({
            selectedEndDate: moment(`${date}`).toISOString()
        })
    }
    private handleSubmitSelectedDate = async () => {
        await this.props.triggerLoading();
        await setTimeout(async () => await this.props.loadPaymentsOfSelectedDate(this.state.selectedStartDate, this.state.selectedEndDate, this.state.siteID), 1000)
        // await this.props.loadPaymentsOfSelectedDate(this.state.selectedStartDate, this.state.selectedEndDate, this.state.siteID);
        await this.setState({
            selectedIncomeType: 'all'
        })
    }

    private handleIncomeTypeSelect = async (paymentMethod: any) => {
        await this.setState({
            selectedIncomeType: paymentMethod
        })
    }

    private handleSiteDisplay = async (siteID: number) => {
        await this.setState({
            siteID: siteID
        })
        await this.props.triggerLoading();
        await setTimeout(async () => await this.props.loadPaymentsOfSelectedDate(this.state.selectedStartDate, this.state.selectedEndDate, this.state.siteID), 1000)

    }

    async componentWillMount() {
        let clientToken = localStorage.getItem('token');
        let tokenPayload: any = {}
        if (clientToken) {
            tokenPayload = parseJwt(clientToken)
        }
        if (tokenPayload.post !== 'BOSS' && this.state.siteID === 0) {
            await this.setState({
                siteID: tokenPayload.siteID
            })
        }
        this.props.loadPaymentsOfSelectedDate(this.state.selectedStartDate, this.state.selectedEndDate, this.state.siteID)
    }

    private handleTriggerDisplaySpecificPurchaseDetailsBoxFromPaymentHistory = async (purchaseID: number, customerID: number) => {
        await this.setState({
            showSpecificPurchaseDetailsBox: !this.state.showSpecificPurchaseDetailsBox,
            selectedPurchaseIDForSpecificPurchaseDetailsBox: purchaseID,
            selectedCustomerID: customerID
        })
    }

    render() {
        let clientToken = localStorage.getItem('token');
        let tokenPayload: any = {}
        if (clientToken) {
            tokenPayload = parseJwt(clientToken)
        }

        let totalIncomeOfSelectedPaymentMethods = 0;
        let totalIncomeOfPeriod = 0;
        let renderPaymentTypeArr: any[] = [];
        let selectedPaymentCount = 0
        if (this.state.selectedIncomeType !== 'all') {
            let correctTypePayments = this.props.allPaymentsWithinPeriod.filter((payment: any) => payment.payment_method === this.state.selectedIncomeType);
            selectedPaymentCount = correctTypePayments.length
        }
        for (let payment of this.props.allPaymentsWithinPeriod) {
            if (!renderPaymentTypeArr.find((paymentMethod: any) => paymentMethod === payment.payment_method)) {
                renderPaymentTypeArr.push(payment.payment_method);
            }
            if (this.state.selectedIncomeType !== 'all' && payment.payment_method === this.state.selectedIncomeType) {
                totalIncomeOfSelectedPaymentMethods += parseFloat(payment.amount_received)
            }
            totalIncomeOfPeriod += parseFloat(payment.amount_received)
        }

        //INCOME LISTING CHART GENERATION
        let largestData = Math.max(...this.props.graphData);
        // let ratio = 400 / largestData
        // const data = [{ name: 'Page A', uv: 400, pv: 2400, amt: 2400 },{name:"Page B", uv: 200, pv: 4800, amt: 4800}];
        const data: any[] = [];
        for (let dataset of this.props.graphData) {
            let dataForGraph = {
                "name": dataset.date,
                "income": dataset.income
            }
            data.push(dataForGraph)
        }
        console.log(data)

        const renderLineChart = (
            <LineChart width={vw(16)} height={90} data={data}>
                <Tooltip label="{name}" labelFormatter={(name) => data[parseInt(`${name}`)].name} />
                <Line type="monotone" dataKey="income" stroke="#0062cc" activeDot />
            </LineChart>
        );

        return (
            <div className="row">
                {this.state.showSpecificPurchaseDetailsBox ?
                    <div className="wholeScreen">
                        <SpecificPurchaseDetailsBox selectedPurchaseID={this.state.selectedPurchaseIDForSpecificPurchaseDetailsBox} siteLists={this.props.allSites} handleExit={(purchaseID: number) => this.handleTriggerDisplaySpecificPurchaseDetailsBoxFromPaymentHistory(purchaseID, 0)} customerDetails={this.props.allPaymentCustomer.find((customer: any) => customer.id === this.state.selectedCustomerID)} language={this.props.language}/>
                    </div>
                    : null}
                <div className="col-9">

                    <div className="incomeListingMainDisplayArea">
                        {
                            tokenPayload.post !== 'BOSS' ?
                                <div className="incomeListingSelectIncomeTypeRow">
                                    {
                                        this.props.allSites.length === 0 ? null :
                                            <div className="incomeTypeBtn">
                                                <div className="selectedTypeBtnDisplay">{(this.props.allSites.find((site: any) => site.id === tokenPayload.siteID)).site_key}</div>

                                            </div>

                                    }
                                </div>
                                :
                                <div className="incomeListingSelectIncomeTypeRow">
                                    <div className="incomeTypeBtn">
                                        {
                                            this.state.siteID === 0 ?
                                                <div className="selectedTypeBtnDisplay">{this.props.language !== "eng" ? "全部分校" : "All Sites"}</div>
                                                :
                                                <div className="typeBtnDisplay" onClick={() => this.handleSiteDisplay(0)}>{this.props.language !== "eng" ? "全部分校" : "All Sites"}</div>
                                        }
                                    </div>
                                    {
                                        this.props.allSites.length === 0 ? null :
                                            this.props.allSites.map((site: any) =>
                                                <div className="incomeTypeBtn">
                                                    {
                                                        this.state.siteID === site.id ?

                                                            <div className="selectedTypeBtnDisplay">{site.site_key}</div>
                                                            :
                                                            <div className="typeBtnDisplay" onClick={() => this.handleSiteDisplay(site.id)}>{site.site_key}</div>
                                                    }
                                                </div>
                                            )
                                    }
                                </div>
                        }
                        <div className="incomeListingSelectIncomeTypeRow">
                            <div className="incomeTypeBtn">
                                {
                                    this.state.selectedIncomeType === 'all' ?
                                        <div className="selectedTypeBtnDisplay">{this.props.language !== "eng" ? "全部收入" : "All Sales"}</div>
                                        :
                                        <div className="typeBtnDisplay" onClick={() => this.handleIncomeTypeSelect('all')}>{this.props.language !== "eng" ? "全部收入" : "All Sales"}</div>
                                }
                            </div>
                            {
                                renderPaymentTypeArr.length === 0 ? null :
                                    renderPaymentTypeArr.map((paymentMethod: any) =>
                                        <div className="incomeTypeBtn">
                                            {
                                                this.state.selectedIncomeType === `${paymentMethod}` ?

                                                    <div className="selectedTypeBtnDisplay">{paymentMethod}</div>
                                                    :
                                                    <div className="typeBtnDisplay" onClick={() => this.handleIncomeTypeSelect(`${paymentMethod}`)}>{paymentMethod}</div>
                                            }
                                        </div>
                                    )
                            }
                        </div>
                        {
                            this.props.loading ?
                                <div className="incomeListingMainAreaContainer">
                                    <div className="loadingContainerRow">
                                        <div className="inlineDisplay">
                                            <div className="loadingContainer">
                                                <CircularProgress id="loadingCircle" />
                                                <div className="loadingLabel">Loading ...</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="incomeListingMainAreaContainer">
                                    {
                                        this.props.allPaymentsWithinPeriod.length === 0 ?
                                            <div className="noPaymentWithinPeriodContainerRow">
                                                <div> - {this.props.language !== "eng" ? "無可顯示收入項目" : "No Applicable Sales Record"} -</div>
                                            </div>
                                            :
                                            this.props.allPaymentsWithinPeriod.map((payment: any) =>
                                                this.state.selectedIncomeType !== 'all' ?
                                                    payment.payment_method !== this.state.selectedIncomeType ? null :
                                                        <div className="paymentRecordRow">
                                                            <div className="paymentIconContainer">
                                                                <div className="paymentIconBG">
                                                                    <ReceiptOutlinedIcon id="paymentListingIcon" />
                                                                </div>
                                                            </div>
                                                            <div className="paymentPurchaseIDContainer">
                                                                <div className="paymentPurchaseID">{this.props.language !== "eng" ? "選購" : "Sales"} - #{payment.purchase_id}</div>
                                                                <div className="paymentPurchaseIDTitle">{this.props.language !== "eng" ? "選購編號" : "Sales ID"}</div>
                                                            </div>
                                                            <div className="paymentPurchaseIDContainer">
                                                                <div className="paymentPurchaseID">{this.props.language !== "eng" ? "付款" : "Payment"} - #{payment.id}</div>
                                                                <div className="paymentPurchaseIDTitle">{this.props.language !== "eng" ? "收款分校" : "Site"} : {this.props.allSites.find((site: any) => site.id === payment.site_id).site_key}</div>
                                                            </div>
                                                            <div className="paymentCustomerNameContainer">
                                                                <div className="paymentPurchaseID">{this.props.allPaymentCustomer.find((customer: any) => customer.id === payment.customer_id).name_eng ? this.props.allPaymentCustomer.find((customer: any) => customer.id === payment.customer_id).name_eng : this.props.allPaymentCustomer.find((customer: any) => customer.id === payment.customer_id).name_chi}</div>
                                                                <div className="paymentPurchaseIDTitle"> # {this.props.allPaymentCustomer.find((customer: any) => customer.id === payment.customer_id).id}</div>
                                                            </div>
                                                            <div className="paymentPurchaseIDContainer">
                                                                <div className="paymentPurchaseID">{moment(payment.created_at).format('DD/MM hh:mma')}</div>
                                                                <div className="paymentPurchaseIDTitle">{this.props.language !== "eng" ? "處理員工" : "Staff"} - #{payment.process_staff_id}</div>
                                                            </div>
                                                            <div className="paymentPurchaseIDContainer">
                                                                <div className="paymentPurchaseID">$ {payment.amount_received}</div>
                                                                <div className="paymentTypeIndicator">
                                                                    {
                                                                        payment.payment_method === 'Cash' ?
                                                                            <FiberManualRecordIcon id="paymentTypeColorIndicator1" /> :
                                                                            payment.payment_method === 'Credit Card' ?
                                                                                <FiberManualRecordIcon id="paymentTypeColorIndicator2" /> :
                                                                                payment.payment_method === 'EPS' ?
                                                                                    <FiberManualRecordIcon id="paymentTypeColorIndicator3" /> :
                                                                                    payment.payment_method === 'Bank Transfer' ?
                                                                                        <FiberManualRecordIcon id="paymentTypeColorIndicator4" /> :
                                                                                        payment.payment_method === 'Bank Deposit Slip' ?
                                                                                            <FiberManualRecordIcon id="paymentTypeColorIndicator5" /> :
                                                                                            payment.payment_method === 'Other' ?
                                                                                                <FiberManualRecordIcon id="paymentTypeColorIndicator6" /> : null
                                                                    }
                                                                    {payment.payment_method}</div>
                                                            </div>
                                                            <Button id="paymentDetailsBtn" onClick={() => this.handleTriggerDisplaySpecificPurchaseDetailsBoxFromPaymentHistory(payment.purchase_id, payment.customer_id)}>。。。</Button>
                                                        </div>
                                                    :
                                                    <div className="paymentRecordRow">
                                                        <div className="paymentIconContainer">
                                                            <div className="paymentIconBG">
                                                                <ReceiptOutlinedIcon id="paymentListingIcon" />
                                                            </div>
                                                        </div>
                                                        <div className="paymentPurchaseIDContainer">
                                                            <div className="paymentPurchaseID">{this.props.language !== "eng" ? "選購" : "Sales"} - #{payment.purchase_id}</div>
                                                            <div className="paymentPurchaseIDTitle">{this.props.language !== "eng" ? "選購編號" : "Sales ID"}</div>
                                                        </div>
                                                        <div className="paymentPurchaseIDContainer">
                                                            <div className="paymentPurchaseID">{this.props.language !== "eng" ? "付款" : "Payment"} - #{payment.id}</div>
                                                            <div className="paymentPurchaseIDTitle">{this.props.language !== "eng" ? "收款分校" : "Site"} : {this.props.allSites.find((site: any) => site.id === payment.site_id).site_key}</div>
                                                        </div>
                                                        <div className="paymentCustomerNameContainer">
                                                            <div className="paymentPurchaseID">{this.props.allPaymentCustomer.find((customer: any) => customer.id === payment.customer_id).name_eng ? this.props.allPaymentCustomer.find((customer: any) => customer.id === payment.customer_id).name_eng : this.props.allPaymentCustomer.find((customer: any) => customer.id === payment.customer_id).name_chi}</div>
                                                            <div className="paymentPurchaseIDTitle"> # {this.props.allPaymentCustomer.find((customer: any) => customer.id === payment.customer_id).id}</div>
                                                        </div>
                                                        <div className="paymentPurchaseIDContainer">
                                                            <div className="paymentPurchaseID">{moment(payment.created_at).format('DD/MM hh:mma')}</div>
                                                            <div className="paymentPurchaseIDTitle">{this.props.language !== "eng" ? "處理員工" : "Staff"} - #{payment.process_staff_id}</div>
                                                        </div>
                                                        <div className="paymentPurchaseIDContainer">
                                                            <div className="paymentPurchaseID">$ {payment.amount_received}</div>
                                                            <div className="paymentTypeIndicator">
                                                                {
                                                                    payment.payment_method === 'Cash' ?
                                                                        <FiberManualRecordIcon id="paymentTypeColorIndicator1" /> :
                                                                        payment.payment_method === 'Credit Card' ?
                                                                            <FiberManualRecordIcon id="paymentTypeColorIndicator2" /> :
                                                                            payment.payment_method === 'EPS' ?
                                                                                <FiberManualRecordIcon id="paymentTypeColorIndicator3" /> :
                                                                                payment.payment_method === 'Bank Transfer' ?
                                                                                    <FiberManualRecordIcon id="paymentTypeColorIndicator4" /> :
                                                                                    payment.payment_method === 'Bank Deposit Slip' ?
                                                                                        <FiberManualRecordIcon id="paymentTypeColorIndicator5" /> :
                                                                                        payment.payment_method === 'Other' ?
                                                                                            <FiberManualRecordIcon id="paymentTypeColorIndicator6" /> : null
                                                                }
                                                                {payment.payment_method}</div>
                                                        </div>
                                                        <Button id="paymentDetailsBtn" onClick={() => this.handleTriggerDisplaySpecificPurchaseDetailsBoxFromPaymentHistory(payment.purchase_id, payment.customer_id)}>。。。</Button>

                                                    </div>

                                            )
                                    }
                                </div>
                        }
                    </div>
                </div>
                <div className="col-3">
                    <div className="incomeListingSideDisplayArea">
                        <div className="incomeListingSideDisplaySection">
                            <div className="incomeListingSectionHeader"> <div>{this.props.language !== "eng" ? "設定統計期" : "Statistical Period"} </div><Button id="incomeListingConfirmBtn" onClick={this.handleSubmitSelectedDate}>{this.props.language !== "eng" ? "統計" : "CALC."}</Button>
                            </div>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <FormControl id="selectIncomeListingDateInputContainer" >
                                    {
                                        tokenPayload.post === 'STAFF' ?
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="selectIncomeListingDateInputs"
                                                label={this.props.language !== "eng"?"統計起始日":"Start Date"}
                                                value={this.state.selectedStartDate}
                                                onChange={this.handleStartDateInput}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                disabled
                                            />
                                            :
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="selectIncomeListingDateInputs"
                                                label={this.props.language !== "eng"?"統計起始日":"Start Date"}
                                                value={this.state.selectedStartDate}
                                                onChange={this.handleStartDateInput}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}

                                            />
                                    }
                                </FormControl>
                                <FormControl id="selectIncomeListingDateInputContainer">
                                    {
                                        tokenPayload.post === 'STAFF' ?
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="selectIncomeListingDateInputs"
                                                label={this.props.language !== "eng"?"統計結尾日":"End Date"}
                                                value={this.state.selectedEndDate}
                                                onChange={this.handleEndDateInput}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                disabled
                                            />
                                            :

                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="selectIncomeListingDateInputs"
                                                label={this.props.language !== "eng"?"統計結尾日":"End Date"}
                                                value={this.state.selectedEndDate}
                                                onChange={this.handleEndDateInput}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                    }
                                </FormControl>

                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="incomeListingSideDisplaySection">
                            <div className="incomeListingSideDisplayRowIndicator">
                                <div className="incomeListingSectionHeader"> {this.props.language !== "eng" ? "收入總計" : "Sales Revenue"} <div className="paymentCountOnIncomeListing">{this.props.language !== "eng" ? ` - 共 ${this.props.allPaymentsWithinPeriod.length} 項` : ` - Count ${this.props.allPaymentsWithinPeriod.length} `}</div></div>
                                <div className="incomeTotalDisplay"> $ {totalIncomeOfPeriod.toFixed(2)}</div>
                                <div className="incomeTotalCurrencyDisplay">{this.props.language !== "eng" ? "港元計算" : "HK Dollar"}</div>

                            </div>
                        </div>
                        {
                            this.state.selectedIncomeType === "all" ? null :
                                <div className="incomeListingSideDisplaySection">
                                    <div className="incomeListingSideDisplayRowIndicator">
                                        <div className="incomeListingSectionHeader"> {this.props.language !== "eng" ? "已選收入總計" : "Payment SubTotal"} <div className="paymentCountOnIncomeListing">{this.props.language !== "eng" ? ` - 共 ${selectedPaymentCount} 項` : ` - Count ${selectedPaymentCount} `}</div></div>
                                        <div className="incomeTotalDisplay"> $ {totalIncomeOfSelectedPaymentMethods.toFixed(2)}</div>
                                        <div className="incomeTotalCurrencyDisplay">{this.props.language !== "eng" ? "港元計算" : "HK Dollar"}</div>

                                    </div>
                                </div>
                        }
                        <div className="incomeListingSideDisplaySection">
                            <div className="incomeListingSectionHeader"> {this.props.language !== "eng" ? "近七天業績趨勢" : "7 Days Performance Trend"} </div>

                            {
                                renderLineChart
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state: IRootState) => {
    return {
        allPaymentsWithinPeriod: state.incomeListing.allPaymentsWithinPeriod,
        allPaymentCustomer: state.incomeListing.allPaymentCustomer,
        allSites: state.incomeListing.allSites,
        graphData: state.incomeListing.graphData,
        loading: state.incomeListing.loading,
        langauge: state.auth.passwordTyped
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        loadPaymentsOfSelectedDate: async (startDate: string, endDate: string, siteID: number) => dispatch(await loadIncomeOfSiteOfSelectedDate(startDate, endDate, siteID)),
        triggerLoading: () => dispatch(loading())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IncomeListing)


function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};
// let clientToken = localStorage.getItem('token');
// let tokenPayload: any = {}
// if (clientToken) {
//     tokenPayload = parseJwt(clientToken)
// }
function vw(v: any) {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return (v * w) / 100;
}