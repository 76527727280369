


export function customerSearchResultLoaded (searchResult: any) {
    return {
        type: "CUSTOMER_SEARCH_RESULT_LOADED" as "CUSTOMER_SEARCH_RESULT_LOADED",
        customerSearchResult: searchResult
    }
}
export function resetAddNewBookingWindow () {
    return {
        type: "ADD_NEW_BOOKING_WINDOW_RESET" as "ADD_NEW_BOOKING_WINDOW_RESET"
    }
}

export function availablePackagesOfCustomerLoaded (availablePackages:any) {
    return {
        type: "AVAILABLE_PACKAGES_OF_CUSTOMER_LOADED" as "AVAILABLE_PACKAGES_OF_CUSTOMER_LOADED",
        availablePackages
    }
}



export function newPrivateClassDetailCreated () {
    return {
        type: "NEW_PRIVATE_CLASS_DETAIL_CREATED" as "NEW_PRIVATE_CLASS_DETAIL_CREATED"
    }
}

type addNewPrivateClassWindowActionsCreators =  
                                typeof customerSearchResultLoaded |
                                typeof resetAddNewBookingWindow |
                                typeof availablePackagesOfCustomerLoaded |
                                typeof newPrivateClassDetailCreated;
                                

export type IAddNewPrivateClassWindowActions = ReturnType<addNewPrivateClassWindowActionsCreators>;