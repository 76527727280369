import React from 'react';
import './StockManageMain.css';
import GavelIcon from '@material-ui/icons/Gavel';
import { IRootState, ThunkDispatch } from '../../store';
import { loadAllUniqueStockNameBasicInfo, submitNewStockToInventory } from './thunks';
import { connect } from 'react-redux';
import { Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import ListIcon from '@material-ui/icons/List';
import AddStockBox from "./AddStockBox";
import { loading } from './actions';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import CircularProgress from '@material-ui/core/CircularProgress';
import MoreVertIcon from '@material-ui/icons/MoreVert';


export interface IStockManageMainProps {
    // pageInit: () => void,
    handleLoadAllUniqueStockNameBasicInfo: () => void,
    handleAddNewStockToInventory: (newStockDetails: any) => void,
    triggerLoading: () => void,
    // handleSaveStaffDetails: (newStaffDetails: any) => void,
    // handleResetStaffLoginDetails: (staffUSerID:number) => void,
    language: string,
    uniqueStockBasicDetails: any[],
    finishingCreateNewInventoryRecord: boolean,
    allSites: any[],
    allAttributeTypes: any[],
    allCategoryTypes: any[],
    loading: boolean
}

export interface IStockManageMainState {
    creatingNewInventory: boolean,
    selectedProductBasicDetails: any
}

class StockManageMain extends React.Component<IStockManageMainProps, IStockManageMainState> {
    constructor(props: IStockManageMainProps) {
        super(props);
        this.state = {
            creatingNewInventory: false,
            selectedProductBasicDetails: ""
        }
    }

    private handleCreateNewInventory = async () => {
        await this.setState({
            creatingNewInventory: !this.state.creatingNewInventory
        })
        if(!this.state.creatingNewInventory){
            await this.setState({
                selectedProductBasicDetails: ""
            })
        }
    }
    private handleConfirmAddNewStockToInventory = async (newStockDetails: any) => {
        await this.props.triggerLoading();
        await this.props.handleAddNewStockToInventory(newStockDetails);
        await this.props.handleLoadAllUniqueStockNameBasicInfo();

    }

    private handleAddStockHotKey = async (stockDetails:any) => {
        await this.setState({
            selectedProductBasicDetails: stockDetails,
            creatingNewInventory: true
        })
    }


    // private handleConfirmSaveStaff = async (newStaffDetails:any) => {
    //     await this.props.handleCreateMassTokenEditRecord(newStaffDetails);
    //     await this.props.loadAllMassTokenEditRecords();
    // }

    // private handleConfirmResetStaffLoginDetails = async (staffUserID:number) => {
    //     await this.props.handleResetStaffLoginDetails(staffUserID);
    // }

    async componentWillMount() {
        await this.props.handleLoadAllUniqueStockNameBasicInfo();

        // this.props.pageInit();
    }


    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="systemMainDisplayArea">
                        <div className="systemManagePageTitle"> <AllInclusiveIcon id="systemManPageTitleIcon" /> {this.props.language !== "eng" ? "存倉管理" : "Stock Manage"}</div>
                        {
                            !this.state.creatingNewInventory ? null :
                                <AddStockBox
                                    // employmentStatusList={this.props.allEmploymentStatus} 
                                    handleExit={this.handleCreateNewInventory}
                                    confirmAddNewStockToInventory={(newStockDetails: any) => this.handleConfirmAddNewStockToInventory(newStockDetails)}
                                    finishing={this.props.finishingCreateNewInventoryRecord} selectedProductBasicDetails={this.state.selectedProductBasicDetails}
                                    />
                        }

                        <div className="productManageArea">
                            <div className="staffListingAreaTitleRow">
                                <div className="staffListingAreaTitle">{this.props.language !== "eng" ? "存倉貨品" : "Stocking List"} <div className="staffListingAreaSubtitle">{this.props.language !== "eng" ? ` - 共 ${this.props.uniqueStockBasicDetails.length} 項 ` : ` - Count ${this.props.uniqueStockBasicDetails.length}`}</div></div>
                                <Button id="createMassEditBtn" startIcon={<AddIcon />} onClick={this.handleCreateNewInventory}> {this.props.language !== "eng" ? "新增新種類存倉貨品" : "Create New Stock "} </Button>
                            </div>
                            <div className="inventoryProductTitleRow">

                                <div className="inventoryProductNameTitle">
                                    <div className="productContent">{this.props.language !== "eng" ? "存倉產品名稱" : "Stock Name"}</div>
                                    {/* <div className="paymentPurchaseIDTitle">操作編號</div> */}
                                </div>
                                <div className="inventoryProductSKU">
                                    <div className="productContent">SKU</div>
                                    {/* <div className="paymentPurchaseIDTitle">改動日數</div> */}
                                </div>
                                <div className="inventoryProductCategory">
                                    <div className="productContent">{this.props.language !== "eng" ? "種類" : "Category"}</div>
                                    {/* <div className="paymentPurchaseIDTitle">改動範圍 : {record.edit_range_site_id ? `只改 ${(this.props.allSites.find((site:any)=> site.id === record.edit_range_site_id)).site_key} 分校適用套券` : "只改不限分校套券"} </div> */}
                                </div>
                                <div className="inventoryProductQuantity">
                                    <div className="productContent">{this.props.language !== "eng" ? "存倉" : "Stock"}</div>
                                    {/* <div className="paymentPurchaseIDTitle">改動範圍 : {record.edit_range_site_id ? `只改 ${(this.props.allSites.find((site:any)=> site.id === record.edit_range_site_id)).site_key} 分校適用套券` : "只改不限分校套券"} </div> */}
                                </div>
                                <div className="inventoryProductPrice">
                                    <div className="productContent">{this.props.language !== "eng" ? "目前售價" : "Price"}</div>
                                    {/* <div className="paymentPurchaseIDTitle"> 改動員工 </div> */}
                                </div>
                                <div className="inventoryProductAttribute">
                                    <div className="productContent">{this.props.language !== "eng" ? "產品特性" : "Attribute"}</div>
                                    {/* <div className="paymentPurchaseIDTitle"> 改動員工 </div> */}
                                </div>
                                <div className="inventoryProductAction">
                                    <div className="btnAligner">
                                        <div className="productContent">{this.props.language !== "eng" ? "管理動作" : "Action"}</div>
                                    </div>
                                    {/* <div className="paymentPurchaseIDTitle"> 改動員工 </div> */}
                                </div>
                                {/* <div className="paymentCustomerNameContainer">
                                                <div className="paymentPurchaseID">改動套劵規模 : {record.edit_scale} 個</div>
                                                <div className="paymentPurchaseIDTitle">操作日 : {moment(record.created_at).format('YYYY-MM-DD HH:mm')}</div>

                                                <div className="paymentTypeIndicator">月業額：${(staff.monthly_purchase_sum).toFixed(2)}</div>
                                            </div> */}
                            </div>

                            {
                                this.props.loading ?
                                    <div className="loadingContainerRow">
                                        <div className="inlineDisplay">
                                            <div className="loadingContainer">
                                                <CircularProgress id="loadingCircle" />
                                                <div className="loadingLabel">Loading ...</div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    this.props.uniqueStockBasicDetails.map((SKU: any) =>
                                        <div className="inventoryProductRow">
                                            <div className="productNameContainer">
                                                <div className="productNameAligner">
                                                    <div className="productIconContainer">
                                                        <div className="productIconBG">
                                                            <ListIcon id="productListingIcon" />
                                                        </div>
                                                    </div>
                                                    {/* className="inventoryProductName" */}
                                                    <div >
                                                        <div className="productContent">{SKU.product_name}</div>
                                                        {/* <div className="paymentPurchaseIDTitle">操作編號</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="inventoryProductSKU">
                                                <div className="productContent">{SKU.sku}</div>
                                                {/* <div className="paymentPurchaseIDTitle">改動日數</div> */}
                                            </div>
                                            <div className="inventoryProductCategory">
                                                <div className="productContent">{(this.props.allCategoryTypes.find((category: any) => category.id === SKU.productCategoryID)).category}</div>
                                                {/* <div className="paymentPurchaseIDTitle">改動範圍 : {record.edit_range_site_id ? `只改 ${(this.props.allSites.find((site:any)=> site.id === record.edit_range_site_id)).site_key} 分校適用套券` : "只改不限分校套券"} </div> */}
                                            </div>
                                            <div className="inventoryProductQuantity">
                                                <div className="productContent">{`${SKU.quantity} / ${SKU.totalBatchSize}`}</div>
                                                {/* <div className="paymentPurchaseIDTitle">改動範圍 : {record.edit_range_site_id ? `只改 ${(this.props.allSites.find((site:any)=> site.id === record.edit_range_site_id)).site_key} 分校適用套券` : "只改不限分校套券"} </div> */}
                                            </div>
                                            <div className="inventoryProductPrice">
                                                <div className="productContent">{`$${SKU.marked_price}`}</div>
                                                {/* <div className="paymentPurchaseIDTitle"> 改動員工 </div> */}
                                            </div>
                                            <div className="inventoryProductAttribute">
                                                {
                                                    SKU.productAttributes.map((attribute: any, index: number) =>
                                                        index > 1 ? null :
                                                            <div className="productContentInlineDisplay">{(this.props.allAttributeTypes.find((attributeType: any) => attributeType.id === attribute.attribute_id)).attribute} : <div className="productContentInlineDisplayThin">{attribute.value} </div></div>
                                                    )
                                                }
                                                {
                                                    SKU.productAttributes.length > 2 ?
                                                        <div className="productContentInlineDisplay">{SKU.productAttributes.length - 2} More ...</div> : null
                                                }
                                                {/* <div className="paymentPurchaseIDTitle"> 改動員工 </div> */}
                                            </div>
                                            <div className="inventoryProductAction">
                                                <div className="btnAligner">
                                                    <div className="productActionBtn" onClick={()=>this.handleAddStockHotKey(SKU)}><div className="btnAligner"><AddIcon id="productActionBtn" /></div></div>
                                                    <div className="productActionBtn"><div className="btnAligner"><MoreVertIcon id="productActionBtn" /></div></div>
                                                </div>
                                                {/* <div className="paymentPurchaseIDTitle"> 改動員工 </div> */}
                                            </div>
                                            {/* <div className="paymentCustomerNameContainer">
                                                <div className="paymentPurchaseID">改動套劵規模 : {record.edit_scale} 個</div>
                                                <div className="paymentPurchaseIDTitle">操作日 : {moment(record.created_at).format('YYYY-MM-DD HH:mm')}</div>

                                                <div className="paymentTypeIndicator">月業額：${(staff.monthly_purchase_sum).toFixed(2)}</div>
                                            </div> */}
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state: IRootState) => {
    return {
        uniqueStockBasicDetails: state.stockManageMain.uniqueStockBasicDetails,
        finishingCreateNewInventoryRecord: state.stockManageMain.finishingCreateNewInventoryRecord,
        loading: state.stockManageMain.loading,
        allSites: state.stockManageMain.allSites,
        allAttributeTypes: state.stockManageMain.allAttributeTypes,
        allCategoryTypes: state.stockManageMain.allCategoryTypes,
        language: state.auth.language
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        // pageInit: async () => dispatch(await staffManagePageInit()),
        handleLoadAllUniqueStockNameBasicInfo: async () => dispatch(await loadAllUniqueStockNameBasicInfo()),
        handleAddNewStockToInventory: async (newStockDetails: any) => dispatch(await submitNewStockToInventory(newStockDetails)),
        triggerLoading: async () => dispatch(await loading())
        // handleSaveStaffDetails: async (newStaffDetails: any) => dispatch(await saveEditedStaffDetails(newStaffDetails)),
        // handleResetStaffLoginDetails: async (staffUserID:number) => dispatch(await resetStaffLoginDetails(staffUserID))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StockManageMain);