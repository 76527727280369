import React from "react";
import './Stocks.css';
import StockManageMain from './StockManageMain/StockManageMain';
import StockAttributeManage from './StockAttributesManage/StockAttributesManage';
import StockCategoryManage from './StockCategoriesManage/StockCategoriesManage';

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import CategoryIcon from '@material-ui/icons/Category';
import GavelIcon from '@material-ui/icons/Gavel';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import { connect } from "react-redux";
import { IRootState } from "../store";

export interface IStocksProps {
    language: string
}
export interface IStocksState {
    systemNavPosition: string
}

class Stocks extends React.Component<IStocksProps, IStocksState> {
    constructor(props: IStocksProps) {
        super(props);
        this.state = {
            systemNavPosition: 'stockManageMain'
        }
    }
    private handleNavBtnClick = async (navPosition: string) => {
        await this.setState({
            systemNavPosition: navPosition
        })
    }


    render() {
        function parseJwt(token: string) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        };
        let clientToken = localStorage.getItem('token');
        let tokenPayload: any = {}
        if (clientToken) {
            tokenPayload = parseJwt(clientToken)
        }
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-2" id="systemLeftNavBar">
                        <div className="systemNavBarDisplayArea">
                            <div className="systemNavBarTitleArea" >
                                <div className="systemNavBarTitle">{this.props.language !== "eng" ? "存倉管理列表" : "Stock Manage List"}</div>
                            </div>

                            <div className="systemNavBtnArea">
                                {
                                    this.state.systemNavPosition === 'stockManageMain' ?
                                        <div className="systemNavBtnActive">
                                            <HomeWorkIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "存倉列表" : "Stock List"}</div>
                                        </div>
                                        :
                                        <div className="systemNavBtn" onClick={() => this.handleNavBtnClick('stockManageMain')}>
                                            <HomeWorkIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "存倉列表" : "Stock List"}</div>
                                        </div>
                                }
                                {
                                    this.state.systemNavPosition === 'stockAttributeManage' ?
                                        <div className="systemNavBtnActive">
                                            <AssignmentIndIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "產品特性管理" : "Stock Attribute Manage"}</div>
                                        </div>
                                        :
                                        <div className="systemNavBtn" onClick={() => this.handleNavBtnClick('stockAttributeManage')}>
                                            <AssignmentIndIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "產品特性管理" : "Stock Attribute Manage"}</div>
                                        </div>
                                }
                                {
                                    this.state.systemNavPosition === 'stockCategoryManage' ?
                                        <div className="systemNavBtnActive">
                                            <AssignmentIndIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "產品種類管理" : "Product Cat. Manage"}</div>
                                        </div>
                                        :
                                        <div className="systemNavBtn" onClick={() => this.handleNavBtnClick('stockCategoryManage')}>
                                            <AssignmentIndIcon id="systemNavBtnIcon" /> <div className="systemNavBtnLabel">{this.props.language !== "eng" ? "產品種類管理" : "Product Cat. Manage"}</div>
                                        </div>
                                }

                            </div>

                        </div>
                    </div>
                    <div className="col-10" id="systemMainBG">
                        {this.state.systemNavPosition !== 'stockManageMain' ? null : <StockManageMain />}
                        {this.state.systemNavPosition !== 'stockAttributeManage' ? null : <StockAttributeManage />}
                        {this.state.systemNavPosition !== 'stockCategoryManage' ? null : <StockCategoryManage />}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        language: state.auth.language
    }
}
export default connect(mapStateToProps, {})(Stocks)
// export default Stocks