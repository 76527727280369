import React from 'react';
import { Button, TextField } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import EditIcon from '@material-ui/icons/Edit';
import RedoIcon from '@material-ui/icons/Redo';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';


export interface IStaffDetailsDisplayBoxProps {
    handleExit: () => void,
    confirmSaveStaffDetails: (newStaffDetails: any) => void,
    confirmResetStaffLoginDetails: (staffUserID: number) => void,
    employmentStatusList: any[],
    allSiteList: any[],
    selectedStaffDetails: any,
    loading: boolean,
    language: string
}
export interface IStaffDetailsDisplayBoxState {
    createStaffSteps: any[],
    activeStep: number,
    newStaffDetails: any,
    editStaffDetails: boolean
}

class StaffDetailsDisplayBox extends React.Component<IStaffDetailsDisplayBoxProps, IStaffDetailsDisplayBoxState> {
    constructor(props: IStaffDetailsDisplayBoxProps) {
        super(props);
        this.state = {
            createStaffSteps: (this.props.language !== "eng" ? ['輸入員工基本資料', '輸入員工身份資料', '輸入顧用資料', '確認新員工詳情'] : ['Staff Basic Details', 'Documentation Info', 'Employment Details', 'Confirm Staff Details']),
            activeStep: 0,
            editStaffDetails: false,
            newStaffDetails: {
                name_chi: "",
                name_eng: "",
                sex: "",
                tel: "",
                email: "",
                address: "",
                date_of_birth: "",
                nationality: "",
                identification_doc_type: "",
                identification_doc_number: "",
                identification_doc_issue_date: "",
                identification_doc_expiry_date: "",
                remarks: "",
                mark_delete: false,
                employment_status_id: 0,
                date_of_employment: "",
                is_boss: false,
                site_id: 1
            }

        }
    }
    private handleDeclineCreateNewStaff = async () => {
        await this.props.handleExit();
    }
    private handleEditStaffDetailsClick = async () => {
        if (!this.state.editStaffDetails) {
            await this.setState({
                newStaffDetails: {
                    ...this.props.selectedStaffDetails,
                    name_chi: (this.props.selectedStaffDetails.name_chi ? this.props.selectedStaffDetails.name_chi : ""),
                    name_eng: (this.props.selectedStaffDetails.name_eng ? this.props.selectedStaffDetails.name_eng : ""),
                    sex: (this.props.selectedStaffDetails.sex),
                    tel: (this.props.selectedStaffDetails.tel),
                    email: (this.props.selectedStaffDetails.email ? this.props.selectedStaffDetails.email : ""),
                    address: (this.props.selectedStaffDetails.address ? this.props.selectedStaffDetails.address : ""),
                    date_of_birth: (this.props.selectedStaffDetails.date_of_birth ? this.props.selectedStaffDetails.date_of_birth : ""),
                    nationality: (this.props.selectedStaffDetails.nationality ? this.props.selectedStaffDetails.nationality : ""),
                    identification_doc_type: (this.props.selectedStaffDetails.identification_doc_type ? this.props.selectedStaffDetails.identification_doc_type : ""),
                    identification_doc_number: (this.props.selectedStaffDetails.identification_doc_number ? this.props.selectedStaffDetails.identification_doc_number : ""),
                    identification_doc_issue_date: (this.props.selectedStaffDetails.identification_doc_issue_date ? this.props.selectedStaffDetails.identification_doc_issue_date : ""),
                    identification_doc_expiry_date: (this.props.selectedStaffDetails.identification_doc_expiry_date ? this.props.selectedStaffDetails.identification_doc_expiry_date : ""),
                    remarks: (this.props.selectedStaffDetails.remarks ? this.props.selectedStaffDetails.remarks : ""),
                    mark_delete: false,
                    employment_status_id: (this.props.selectedStaffDetails.employment_status_id),
                    date_of_employment: (this.props.selectedStaffDetails.date_of_employment),
                    is_boss: (this.props.selectedStaffDetails.is_boss),
                    site_id: (this.props.selectedStaffDetails.site_id)
                }
            })
        }
        await this.setState({
            editStaffDetails: !this.state.editStaffDetails
        })
    }

    private handleNewStaffDetailsInput = (field: string) => async (event: any) => {

        if (field === 'is_boss') {
            await this.setState({
                newStaffDetails: {
                    ...this.state.newStaffDetails,
                    [field]: !this.state.newStaffDetails[field]
                }
            })
            return;
        }
        if (field === 'date_of_birth' || field === 'identification_doc_issue_date' || field === 'identification_doc_expiry_date' || field === 'date_of_employment') {
            await this.setState({
                newStaffDetails: {
                    ...this.state.newStaffDetails,
                    [field]: moment(event.target.value).toISOString()
                }
            })
        } else {
            await this.setState({
                newStaffDetails: {
                    ...this.state.newStaffDetails,
                    [field]: event.target.value
                }
            })
        }
    }

    private handleConfirmSaveClick = async () => {
        if (this.state.newStaffDetails.name_eng === '' || this.state.newStaffDetails.sex === '' ||
            this.state.newStaffDetails.tel === '' || this.state.newStaffDetails.email === "" ||
            this.state.newStaffDetails.date_of_birth === "" || this.state.newStaffDetails.nationality === "" ||
            this.state.newStaffDetails.employment_status_id === 0 || this.state.newStaffDetails.date_of_employment === "" || this.state.newStaffDetails.site_id === 0) {

            window.alert((this.props.language !== "eng" ? '請填寫全部必要項目' : "Please Entre All Required Information."))
        } else {
            let newStaffDetails = this.state.newStaffDetails;
            if (newStaffDetails.name_chi === '') {
                newStaffDetails.name_chi = null
            }
            if (newStaffDetails.address === '') {
                newStaffDetails.address = null
            }
            if (newStaffDetails.identification_doc_type === '') {
                newStaffDetails.identification_doc_type = null
            }
            if (newStaffDetails.identification_doc_number === '') {
                newStaffDetails.identification_doc_number = null
            }
            if (newStaffDetails.identification_doc_issue_date === '') {
                newStaffDetails.identification_doc_issue_date = null
            }
            if (newStaffDetails.identification_doc_expiry_date === '') {
                newStaffDetails.identification_doc_expiry_date = null
            }
            if (newStaffDetails.remarks === '') {
                newStaffDetails.remarks = null
            }
            await this.props.confirmSaveStaffDetails(newStaffDetails);
            await this.setState({
                editStaffDetails: false
            })
        }
    }

    private handleResetLoginDetailsClick = async (staffUserID: number) => {
        let confirmation = window.confirm((this.props.language !== "eng" ? '確定要重設此員工的登入密碼嗎？' : "Confirmation on Resetting Target Staff Login Information ?"));
        if (confirmation) {
            await this.props.confirmResetStaffLoginDetails(staffUserID);
            window.alert((this.props.language !== "eng" ? '已重設此員工的登入密碼。' : "Logic Information of Target Staff Has Been Reset."))
        }
    }

    render() {
        return (
            <div className="createStaffArea row">
                <div className="staffListingAreaTitleRow col-12">
                    <div className="staffListingAreaTitle">{this.props.language !== "eng" ? "員工詳細資料" : "Staff Details"} </div>
                    {
                        this.state.editStaffDetails ?
                            <div className="inlineDisplay">
                                <Button startIcon={<CloseIcon />} onClick={this.handleEditStaffDetailsClick} > {this.props.language !== "eng" ? "取消修改資料" : "Decline Edit"} </Button>
                                <Button startIcon={<EditIcon />} onClick={this.handleConfirmSaveClick} style={{ color: 'white', background: "rgb(0, 128, 203)" }}> {this.props.language !== "eng" ? "儲存修改資料" : "Save Detailst"} </Button>
                            </div>
                            :
                            <div className="inlineDisplay">
                                <Button startIcon={<EditIcon />} onClick={this.handleEditStaffDetailsClick} style={{ color: 'white', background: "rgb(0, 128, 203)" }}> {this.props.language !== "eng" ? "修改員工資料" : "Edit Staff Details"} </Button>
                                <Button startIcon={<RedoIcon />} style={{ color: 'white', background: "rgb(255, 183, 59)" }} onClick={() => this.handleResetLoginDetailsClick(this.props.selectedStaffDetails.user_id)}> {this.props.language !== "eng" ? "重設登入資料" : "Reset Login Info"} </Button>
                                <Button id="cxlCreateStaffBtn" startIcon={<CloseIcon />} onClick={this.handleDeclineCreateNewStaff}> {this.props.language !== "eng" ? "返回" : "Back"} </Button>
                            </div>

                    }
                </div>
                {
                    this.props.loading ?
                        <div className="loadingContainerRow">
                            <div className="inlineDisplay">
                                <div className="loadingContainer">
                                    <CircularProgress id="loadingCircle" />
                                    <div className="loadingLabel">Loading ...</div>
                                </div>
                            </div>
                        </div>
                        :


                        <div className="fixedHeightWindowOnStaffDetailsDisplayBox col-12">
                            <div className="staffDetailsRow row">
                                <div className="staffDetailsSection col-xl-4 col-lg-12">
                                    <div className="staffDetailsSectionTitle">
                                        {this.props.language !== "eng" ? "基本資料" : "Basic Information"}
                                    </div>
                                    <div className="staffDetailsContainer">
                                        <div className="staffDetailLabel">
                                            -{this.props.language !== "eng" ? "中文姓名" : "Chi. Name"} :
                                        </div>
                                        {
                                            this.state.editStaffDetails ?
                                                <TextField className="staffDetailsEditingInputs" variant="outlined" value={this.state.newStaffDetails.name_chi} onChange={this.handleNewStaffDetailsInput('name_chi')} />
                                                :
                                                <div className="staffDetails">
                                                    {this.props.selectedStaffDetails.name_chi ? this.props.selectedStaffDetails.name_chi : '- -'}
                                                </div>
                                        }
                                    </div>
                                    <div className="staffDetailsContainer">
                                        <div className="staffDetailLabel">
                                            *{this.props.language !== "eng" ? "英文姓名" : "Eng. Name"} :
                                        </div>
                                        {
                                            this.state.editStaffDetails ?
                                                <TextField className="staffDetailsEditingInputs" variant="outlined" value={this.state.newStaffDetails.name_eng} onChange={this.handleNewStaffDetailsInput('name_eng')} />
                                                :
                                                <div className="staffDetails">
                                                    {this.props.selectedStaffDetails.name_eng}
                                                </div>
                                        }
                                    </div>
                                    <div className="staffDetailsContainer">
                                        <div className="staffDetailLabel">
                                            *{this.props.language !== "eng" ? "員工性別" : "SEX"} :
                                        </div>
                                        {
                                            this.state.editStaffDetails ?
                                                <FormControl variant="outlined" className="editStaffSelectInput">
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={this.state.newStaffDetails.sex}
                                                        onChange={this.handleNewStaffDetailsInput('sex')}
                                                    >
                                                        <MenuItem value={'M'}>M</MenuItem>
                                                        <MenuItem value={'F'}>F</MenuItem>

                                                    </Select>
                                                </FormControl>
                                                :
                                                <div className="staffDetails">
                                                    {this.props.selectedStaffDetails.sex}
                                                </div>
                                        }
                                    </div>
                                    <div className="staffDetailsContainer">
                                        <div className="staffDetailLabel">
                                            *{this.props.language !== "eng" ? "聯絡電話" : "TEL"} :
                                        </div>
                                        {
                                            this.state.editStaffDetails ?
                                                <TextField className="staffDetailsEditingInputs" variant="outlined" value={this.state.newStaffDetails.tel} onChange={this.handleNewStaffDetailsInput('tel')} />
                                                :
                                                <div className="staffDetails">
                                                    {this.props.selectedStaffDetails.tel}
                                                </div>
                                        }
                                    </div>
                                    <div className="staffDetailsContainer">
                                        <div className="staffDetailLabel">
                                            *{this.props.language !== "eng" ? "聯絡電郵" : "Email"} :
                                        </div>
                                        {
                                            this.state.editStaffDetails ?
                                                <TextField className="staffDetailsEditingInputs" variant="outlined" value={this.state.newStaffDetails.email} onChange={this.handleNewStaffDetailsInput('email')} />
                                                :
                                                <div className="staffDetails">
                                                    {this.props.selectedStaffDetails.email}
                                                </div>
                                        }
                                    </div>
                                    <div className="staffDetailsContainer">
                                        <div className="staffDetailLabel">
                                            -{this.props.language !== "eng" ? "聯絡地址" : "Address"} :
                                        </div>
                                        {
                                            this.state.editStaffDetails ?
                                                <TextField className="staffDetailsEditingInputs" variant="outlined" value={this.state.newStaffDetails.address} onChange={this.handleNewStaffDetailsInput('address')} />
                                                :
                                                <div className="staffDetails">
                                                    {this.props.selectedStaffDetails.address ? this.props.selectedStaffDetails.address : "- -"}
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="staffDetailsSection col-xl-4 col-lg-12">
                                    <div className="staffDetailsSectionTitle">
                                        {this.props.language !== "eng" ? "身份資料" : "Identification Information"}
                                    </div>
                                    <div className="staffDetailsContainer">
                                        <div className="staffDetailLabel">
                                            *{this.props.language !== "eng" ? "出生日期" : "DOB"} :
                                        </div>
                                        {
                                            this.state.editStaffDetails ?
                                                <TextField className="staffDetailsEditingInputs" type="date" variant="outlined" value={moment(this.state.newStaffDetails.date_of_birth).format('YYYY-MM-DD')} onChange={this.handleNewStaffDetailsInput('date_of_birth')} />
                                                :
                                                <div className="staffDetails">
                                                    {moment(this.props.selectedStaffDetails.date_of_birth).format('YYYY/MM/DD')}
                                                </div>
                                        }
                                    </div>
                                    <div className="staffDetailsContainer">
                                        <div className="staffDetailLabel">
                                            *{this.props.language !== "eng" ? "國藉簡稱" : "Nationality"} :
                                        </div>
                                        {
                                            this.state.editStaffDetails ?
                                                <TextField className="staffDetailsEditingInputs" variant="outlined" value={this.state.newStaffDetails.nationality} onChange={this.handleNewStaffDetailsInput('nationality')} />
                                                :
                                                <div className="staffDetails">
                                                    {this.props.selectedStaffDetails.nationality ? this.props.selectedStaffDetails.nationality : '- -'}
                                                </div>
                                        }
                                    </div>
                                    <div className="staffDetailsContainer">
                                        <div className="staffDetailLabel">
                                            -{this.props.language !== "eng" ? "身份文件" : "Identification Doc."} :
                                        </div>
                                        {
                                            this.state.editStaffDetails ?
                                                <FormControl variant="outlined" className="editStaffSelectInput">
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={this.state.newStaffDetails.identification_doc_type}
                                                        onChange={this.handleNewStaffDetailsInput('identification_doc_type')}
                                                    >
                                                        <MenuItem value={'HKID'}>HKID</MenuItem>
                                                        <MenuItem value={'PASSPORT'}>PASSPORT</MenuItem>

                                                    </Select>
                                                </FormControl>
                                                :
                                                <div className="staffDetails">
                                                    {this.props.selectedStaffDetails.identification_doc_type ? this.props.selectedStaffDetails.identification_doc_type : "- -"}
                                                </div>
                                        }
                                    </div>
                                    <div className="staffDetailsContainer">
                                        <div className="staffDetailLabel">
                                            -{this.props.language !== "eng" ? "文件編號" : "Doc. No."} :
                                        </div>
                                        {
                                            this.state.editStaffDetails ?
                                                <TextField className="staffDetailsEditingInputs" variant="outlined" value={this.state.newStaffDetails.identification_doc_number} onChange={this.handleNewStaffDetailsInput('identification_doc_number')} />
                                                :
                                                <div className="staffDetails">
                                                    {this.props.selectedStaffDetails.identification_doc_number ? this.props.selectedStaffDetails.identification_doc_number : '- -'}
                                                </div>
                                        }
                                    </div>
                                    <div className="staffDetailsContainer">
                                        <div className="staffDetailLabel">
                                            -{this.props.language !== "eng" ? "簽發日期" : "Issue Date"} :
                                        </div>
                                        {
                                            this.state.editStaffDetails ?
                                                <TextField className="staffDetailsEditingInputs" type="date" variant="outlined" value={moment(this.state.newStaffDetails.identification_doc_issue_date).format('YYYY-MM-DD')} onChange={this.handleNewStaffDetailsInput('identification_doc_issue_date')} />
                                                :
                                                <div className="staffDetails">
                                                    {this.props.selectedStaffDetails.identification_doc_issue_date ? moment(this.props.selectedStaffDetails.identification_doc_issue_date).format('YYYY/MM/DD') : "- -"}
                                                </div>
                                        }
                                    </div>
                                    <div className="staffDetailsContainer">
                                        <div className="staffDetailLabel">
                                            -{this.props.language !== "eng" ? "到期日期" : "Exp.Date"} :
                                        </div>
                                        {
                                            this.state.editStaffDetails ?
                                                <TextField className="staffDetailsEditingInputs" type="date" variant="outlined" value={moment(this.state.newStaffDetails.identification_doc_expiry_date).format('YYYY-MM-DD')} onChange={this.handleNewStaffDetailsInput('identification_doc_expiry_date')} />
                                                :
                                                <div className="staffDetails">
                                                    {this.props.selectedStaffDetails.identification_doc_expiry_date ? moment(this.props.selectedStaffDetails.identification_doc_expiry_date).format('YYYY/MM/DD') : "- -"}
                                                </div>
                                        }
                                    </div>
                                </div>

                                <div className="staffDetailsSection col-xl-4 col-lg-12">
                                    <div className="staffDetailsSectionTitle">
                                        {this.props.language !== "eng" ? "顧用資料" : "Employment Details"}
                                    </div>
                                    <div className="staffDetailsContainer">
                                        <div className="staffDetailLabel">
                                            *{this.props.language !== "eng" ? "顧用日期" : "Employment Date"} :
                                        </div>
                                        {
                                            this.state.editStaffDetails ?
                                                <TextField className="staffDetailsEditingInputs" type="date" variant="outlined" value={moment(this.state.newStaffDetails.date_of_employment).format('YYYY-MM-DD')} onChange={this.handleNewStaffDetailsInput('date_of_employment')} />
                                                :
                                                <div className="staffDetails">
                                                    {moment(this.props.selectedStaffDetails.date_of_employment).format('YYYY/MM/DD')}
                                                </div>
                                        }
                                    </div>
                                    <div className="staffDetailsContainer">
                                        <div className="staffDetailLabel">
                                            *{this.props.language !== "eng" ? "顧用模式" : "Employment Type"} :
                                        </div>
                                        {
                                            this.state.editStaffDetails ?
                                                <FormControl variant="outlined" className="editStaffSelectInput">
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={this.state.newStaffDetails.employment_status_id}
                                                        onChange={this.handleNewStaffDetailsInput('employment_status_id')}
                                                    >
                                                        {
                                                            this.props.employmentStatusList.map((status: any) =>
                                                                <MenuItem value={status.id}>{status.status}</MenuItem>
                                                            )
                                                        }

                                                    </Select>
                                                </FormControl> :
                                                <div className="staffDetails">
                                                    {this.props.selectedStaffDetails.employment_status_id ? (this.props.employmentStatusList.find((status: any) => status.id === this.props.selectedStaffDetails.employment_status_id)).status : '- -'}
                                                </div>
                                        }
                                    </div>
                                    <div className="staffDetailsContainer">
                                        <div className="staffDetailLabel">
                                            *{this.props.language !== "eng" ? "派駐分校" : "Site"} :
                                        </div>
                                        {
                                            this.state.editStaffDetails ?
                                                <FormControl variant="outlined" className="editStaffSelectInput">
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={this.state.newStaffDetails.site_id}
                                                        onChange={this.handleNewStaffDetailsInput('site_id')}
                                                    >
                                                        {
                                                            this.props.allSiteList.map((site: any) =>
                                                                <MenuItem value={site.id}>{site.site_key}</MenuItem>
                                                            )
                                                        }

                                                    </Select>
                                                </FormControl>
                                                :
                                                <div className="staffDetails">
                                                    {(this.props.allSiteList.find((site: any) => site.id === this.props.selectedStaffDetails.site_id)).site_key}
                                                </div>
                                        }
                                    </div>
                                    <div className="staffDetailsContainer">
                                        <div className="staffDetailLabel">
                                            *{this.props.language !== "eng" ? "超級權限" : "SAuth"} :
                                        </div>
                                        {
                                            this.state.editStaffDetails ?
                                                <FormControl variant="outlined" className="packageTokenBasedPackageInput">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={this.state.newStaffDetails.is_boss}
                                                                onChange={this.handleNewStaffDetailsInput('is_boss')}
                                                                name="tokenBasedPackage"
                                                                color="primary"
                                                            />
                                                        }

                                                        label={this.props.language !== "eng" ? "超級權限" : "SAuth"}
                                                    />
                                                </FormControl>
                                                :
                                                <div className="staffDetails">
                                                    {this.props.selectedStaffDetails.is_boss ? '有' : '無'}
                                                </div>
                                        }
                                    </div>

                                    <div className="staffDetailsContainer">
                                        <div className="staffDetailLabel">
                                            -{this.props.language !== "eng" ? "員工備注" : "Remarks"} :
                                        </div>
                                        {
                                            this.state.editStaffDetails ?
                                                <TextField className="staffDetailsEditingInputs" multiline rows={7} variant="outlined" value={this.state.newStaffDetails.remarks} onChange={this.handleNewStaffDetailsInput('remarks')} />
                                                :
                                                <div className="staffRemarksDetails">
                                                    {this.props.selectedStaffDetails.remarks ? this.props.selectedStaffDetails.remarks : '- -'}
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                }

            </div>
        )
    }
}

export default StaffDetailsDisplayBox;