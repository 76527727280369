import { ThunkDispatch } from '../../store';
import { allRequiredDataLoadedForPackageManageInit, allPackagesWithRightUsageLoaded, packageCreated, packageUpdated, packageDeleted, uniquePackageNameChecked, finishMassUpdatingPackageGenre } from './actions';


export async function pageInit() {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/packageManagePageInit`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(allRequiredDataLoadedForPackageManageInit(result.allAgeGroups, result.allGenres, result.allTokenUsages, result.allPackages, result.allTerms, result.allSites));
        }
    }
}

export async function loadAllPackagesWithUsageID(usageID: number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/loadAllPackagesWithRightUsage?packageUsageID=${usageID}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(allPackagesWithRightUsageLoaded(result.result));
        }
    }
}

export async function createPackage(packageDetails: any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/createNewPackage`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            packageDetails: packageDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(packageCreated());
        }
    }
}

export async function updatePackage(packageDetails: any, changeAllTargetedSoldPackage: boolean) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/updatePackageDetails`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            packageDetails: packageDetails,
            changeAllTargetedSoldPackage: changeAllTargetedSoldPackage
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(packageUpdated());
        }
    }
}

export async function delPackage(packageID: any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/delPackage`, {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            packageID: packageID
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(packageDeleted());
        }
    }
}

export async function uniquePackageNameCheck(packageName: string) {
    packageName = packageName.replace("&", "%26");
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/uniquePackageNameCheck?packageName=${(packageName)}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(uniquePackageNameChecked(result.uniqueCheckResult));
        }
    }
}

export async function massUpdatePackageGenre(targetChangingPackage: any, changeAllTargetedSoldPackage: boolean, changePackageType:boolean, changeBothSoldPackageAndPackageType:boolean) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/system/updatePackageGenreOnly`, {

        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body:JSON.stringify({
            targetChangingPackages: targetChangingPackage,
            changeAllTargetedSoldPackage,
            changePackageType,
            changeBothSoldPackageAndPackageType
        })

    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(finishMassUpdatingPackageGenre(result.result))
        }
    }
}