import { IScheduleState } from "./state";
import { IScheduleActions } from "./actions";
import { AccordionCollapse } from "react-bootstrap";

const initialState = {
    openingTimeList: [],
    siteList: [],
    classroomList: [],
    occupancyOfTheShowingDate: [],
    cxlOccupancyOfTheShowingDate: [],
    occupancyOf7Days: [],
    genreTypeList: [],
    ageGroupList: [],
    tutorInfos: [],
    tutorListsOfDay: [],
    tutorListsOfDayForCxl: [],
    tutorListsOf7Days: [],
    roomRentingPrivateCustomerList: [],
    roomRentingPrivateCustomerListForCxl: [],
    roomRentingPrivateCustomerListOf7Days: [],
    duplicatedLatestSchedulingGroup: [],
    newOccupancyDetailsTempStorage: [],
    cloneWeekID: 0,
    loading: true
}

export const scheduleReducer = (state: IScheduleState = initialState, actions: IScheduleActions) => {

    switch (actions.type) {

        case "SCHEDULE_LOADED":
            return {
                ...state,
                openingTimeList: actions.openingTimeList,
                siteList: actions.siteList,
                classroomList: actions.classroomList,
                occupancyOfTheShowingDate: actions.occupancyOfTheDate,
                cxlOccupancyOfTheShowingDate: actions.cxlOccupancyOfTheDay,
                genreTypeList: actions.genreTypeList,
                ageGroupList: actions.ageGroupList,
                tutorInfos: actions.tutorInfos,
                tutorListsOfDay: actions.tutorLists,
                tutorListsOfDayForCxl: actions.TutorListsForCxl,
                roomRentingPrivateCustomerList: actions.roomRentingPrivateCustomerList,
                roomRentingPrivateCustomerListForCxl: actions.roomRentingPrivateCustomerListForCxl,
                loading: false

            }
        case "OCCUPANCY_OF_THE_DATE_LOADED":
            return {
                ...state,
                occupancyOfTheShowingDate: actions.occupancyOfTheDate,
                cxlOccupancyOfTheShowingDate: actions.cxlOccupancyOfTheDay,
                tutorListsOfDay: actions.tutorListArray,
                tutorListsOfDayForCxl: actions.TutorListsForCxl,
                roomRentingPrivateCustomerList: actions.roomRentingPrivateCustomerList,
                roomRentingPrivateCustomerListForCxl: actions.roomRentingPrivateCustomerListForCxl,
                loading: false
            }

        case "NEW_OCCUPANCY_SUBMITTED_WITH_FAILED_UNIQUE_CHECK":
            return {
                ...state,
                duplicatedLatestSchedulingGroup: actions.duplicatedLatestSchedulingGroup,
                newOccupancyDetailsTempStorage: actions.newOccupancyDetailsTempStorage,
                loading: false

            }

        case "USG_CONFIRMATION_DECLINE_CLICK_HANDLED":
            return {
                ...state,
                duplicatedLatestSchedulingGroup: [],
                newOccupancyDetailsTempStorage: [],
                loading: false

            }

        case "USG_CONFIRMATION_USE_CLICK_HANDLED":
            return {
                ...state,
                duplicatedLatestSchedulingGroup: [],
                newOccupancyDetailsTempStorage: [],
                loading: true
            }

        case "USG_CONFIRMATION_CREATE_CLICK_HANDLED":
            return {
                ...state,
                duplicatedLatestSchedulingGroup: [],
                newOccupancyDetailsTempStorage: [],
                loading: true
            }
        case "OCCUPANCY_OF_7_DAYS_LOADED":
            return {
                ...state,
                occupancyOf7Days: actions.occupancyOf7Days,
                tutorListsOf7Days: actions.tutorListArrayOf7Days,
                roomRentingPrivateCustomerListOf7Days: actions.roomRentingPrivateCustomerListOf7Days,
                cloneWeekID: actions.cloneWeekID,
                loading: false

            }
        case "OCCUPANCY_OF_7_DAYS_CLONED":
            return {
                ...state,
                loading: true
            }
        case "LOADING":
            return {
                ...state,
                loading: true
            }

        default:
            return state;
    }
}