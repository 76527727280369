import React from "react";
import './SitesClassroomsManage.css'
import { connect } from "react-redux";
import { IRootState, ThunkDispatch } from "../../store";
import { loadSitesClassroomsData, updateClassroomDetails, createClassroomDetails, delClassroomDetails, updateSiteDetails, creteSiteDetails } from './thunks';
import { Button, TextField } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import { classroomDeleteFailedNoticed } from "./actions";
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import CreateSiteBox from './CreateSiteBox';



export interface ISitesClassroomsManageProps {
    loadAllSitesClassrooms: () => void,
    updateClassroomDetails: (classroomDetails: any) => void,
    createNewClassroomDetails: (classroomDetails: any) => void,
    delClassroomDetails: (classroomID: number) => void,
    exitFromNoticeWindow: () => void,
    updateSiteDetails: (siteDetails: any) => void,
    createNewSiteDetails: (siteDetails: any) => void,
    language: string,
    allSites: any,
    allClassrooms: any,
    delClassroomFailed: boolean,
    loading: boolean
}
export interface ISitesClassroomsManageState {
    selectedClassroomID: number,
    selectedClassroomDetails: any
    addNewClassroom: boolean,
    selectedSiteID: number,
    selectedSiteDetails: any,
    displayingSiteID: number,
    addNewSite: boolean,
}
class SitesClassroomsManage extends React.Component<ISitesClassroomsManageProps, ISitesClassroomsManageState> {
    constructor(props: ISitesClassroomsManageProps) {
        super(props);
        this.state = {
            selectedClassroomID: 0,
            selectedClassroomDetails: '',
            addNewClassroom: false,
            selectedSiteID: 0,
            selectedSiteDetails: '',
            displayingSiteID: 1,
            addNewSite: false
        }
    }

    private handleEditClassroomClick = async (classroomID: number) => {
        let classroomDetails = this.props.allClassrooms.filter((classroom: any) => classroom.id === classroomID)
        await this.setState({
            selectedClassroomID: classroomID,
            selectedClassroomDetails: classroomDetails[0]
        })
    }
    private handleClassroomNameInput = async (event: any) => {
        await this.setState({
            selectedClassroomDetails: {
                ...this.state.selectedClassroomDetails,
                classroom: event.target.value
            }
        })
    }
    private handleClassroomCapacityInput = async (event: any) => {
        let cap = parseFloat(event.target.value)
        if (event.target.value === "") {
            cap = 0
        }
        await this.setState({
            selectedClassroomDetails: {
                ...this.state.selectedClassroomDetails,
                capacity: cap
            }
        })
    }
    private handleSaveClassroomDetails = async () => {
        this.props.updateClassroomDetails(this.state.selectedClassroomDetails);
        await this.setState({
            selectedClassroomID: 0,
            selectedClassroomDetails: ''
        })
        setTimeout(async () => await this.props.loadAllSitesClassrooms(), 1000);
    }
    private handleAddNewClassroomDetails = async (siteID: number) => {
        await this.setState({
            addNewClassroom: true,
            selectedClassroomDetails: {
                site_id: siteID,
                classroom: '',
                capacity: 0
            }
        })
    }
    private handleSaveNewClassroomDetails = async () => {
        await this.props.createNewClassroomDetails(this.state.selectedClassroomDetails);
        await this.setState({
            selectedClassroomID: 0,
            selectedClassroomDetails: '',
            addNewClassroom: false,
        });
        setTimeout(async () => await this.props.loadAllSitesClassrooms(), 1000);

    }

    private handleDelClassroomDetails = async (classroomID: number) => {
        let confirmation = await window.confirm('你確定要刪除此課室嗎？');
        if (confirmation) {
            await this.props.delClassroomDetails(classroomID);
            setTimeout(async () => await this.props.loadAllSitesClassrooms(), 1000);

        }
    }
    private handleExitFromDeleteFailedNoticeWindow = async () => {
        await this.props.exitFromNoticeWindow();
    }
    private handleEditSiteClick = async (siteID: number) => {
        let siteDetails = this.props.allSites.filter((site: any) => site.id === siteID);

        await this.setState({
            selectedSiteID: siteID,
            selectedSiteDetails: siteDetails[0]
        })
    }
    private handleSiteNameInput = async (event: any) => {
        await this.setState({
            selectedSiteDetails: {
                ...this.state.selectedSiteDetails,
                sites: event.target.value
            }
        })
    }
    private handleSiteKeyInput = async (event: any) => {
        await this.setState({
            selectedSiteDetails: {
                ...this.state.selectedSiteDetails,
                site_key: event.target.value
            }
        })
    }
    private handleMonOpeningTimeInput = async (event: any) => {
        await this.setState({
            selectedSiteDetails: {
                ...this.state.selectedSiteDetails,
                office_hour_mon: event.target.value
            }
        })
    }
    private handleTueOpeningTimeInput = async (event: any) => {
        await this.setState({
            selectedSiteDetails: {
                ...this.state.selectedSiteDetails,
                office_hour_tue: event.target.value
            }
        })
    }
    private handleWedOpeningTimeInput = async (event: any) => {
        await this.setState({
            selectedSiteDetails: {
                ...this.state.selectedSiteDetails,
                office_hour_wed: event.target.value
            }
        })
    }
    private handleThuOpeningTimeInput = async (event: any) => {
        await this.setState({
            selectedSiteDetails: {
                ...this.state.selectedSiteDetails,
                office_hour_thu: event.target.value
            }
        })
    }
    private handleFriOpeningTimeInput = async (event: any) => {
        await this.setState({
            selectedSiteDetails: {
                ...this.state.selectedSiteDetails,
                office_hour_fri: event.target.value
            }
        })
    }
    private handleSatOpeningTimeInput = async (event: any) => {
        await this.setState({
            selectedSiteDetails: {
                ...this.state.selectedSiteDetails,
                office_hour_sat: event.target.value
            }
        })
    }
    private handleSunOpeningTimeInput = async (event: any) => {
        await this.setState({
            selectedSiteDetails: {
                ...this.state.selectedSiteDetails,
                office_hour_sun: event.target.value
            }
        })
    }
    private handleSaveSiteClick = async () => {
        await this.props.updateSiteDetails(this.state.selectedSiteDetails);
        await this.setState({
            selectedSiteID: 0,
            selectedSiteDetails: ''
        });
        setTimeout(async () => await this.props.loadAllSitesClassrooms(), 1000);
    }

    private handleExitFromAddSiteBox = async () => {
        await this.setState({
            addNewSite: false
        })
    }

    private handleConfirmAddNewSite = async (newSiteDetails: any) => {
        this.props.createNewSiteDetails(newSiteDetails);
        await this.setState({
            addNewSite: false
        });
        this.props.loadAllSitesClassrooms();

    }
    private handleTriggerAddNewSiteBtnClick = async () => {
        await this.setState({
            addNewSite: true
        })
    }

    private handleDisplaySiteSelect = async (siteID: number) => {
        await this.setState({
            displayingSiteID: siteID
        })
    }

    componentWillMount() {
        this.props.loadAllSitesClassrooms();
    }

    render() {
        let clientToken = localStorage.getItem('token');
        let tokenPayload: any = {}
        if (clientToken) {
            tokenPayload = parseJwt(clientToken)
        }

        let displayingSiteDetails = this.props.allSites.find((site: any) => site.id === this.state.displayingSiteID);
        return (
            <div className="row">
                {!this.props.delClassroomFailed ? null :
                    <div className="delFailedNoticeWindow">
                        <div className="noticeOnSitesMan"> <div>{this.props.language !== "eng" ? "因有課堂佔用此課室，因此無法刪除！" : "Occupied by Class, Could not Delete!"}</div> </div>
                        <div className="backBtnOnSitesManNoticeWindow"> <Button id="clickToBackBtn" onClick={this.handleExitFromDeleteFailedNoticeWindow}>{this.props.language !== "eng" ? "取消操作" : "Decline Change"}</Button>
                        </div>
                    </div>
                }
                <div className="col-12">
                    {
                        this.state.addNewSite ?
                            <CreateSiteBox handleExit={this.handleExitFromAddSiteBox} confirmAddNewMassEdit={(siteDetails: any) => this.handleConfirmAddNewSite(siteDetails)} language={this.props.language} />

                            :


                            <div className="systemMainDisplayArea">
                                <div className="systemManagePageTitle"> <HomeWorkIcon id="systemManPageTitleIcon" /> {this.props.language !== "eng" ? "場地管理" : "Site Manage"}</div>
                                <div className="allSiteBarContainer">
                                    <div className="allSiteBar">
                                        {
                                            this.props.allSites.map((site: any) =>
                                                this.state.displayingSiteID === site.id ?
                                                    <div className="displayingSiteBtnOnSiteManage">
                                                        <div className="siteBtnDisplaying" onClick={() => this.handleDisplaySiteSelect(site.id)}>{site.site_key}</div>
                                                    </div>
                                                    :
                                                    <div className="displayingSiteBtnOnSiteManage" onClick={() => this.handleDisplaySiteSelect(site.id)}>
                                                        <div className="siteBtnDisplay" onClick={() => this.handleDisplaySiteSelect(site.id)}>{site.site_key}</div>

                                                    </div>
                                            )
                                        }
                                        {
                                            tokenPayload.post !== 'BOSS' ? null :
                                                <div className="displayingSiteBtnOnSiteManage">
                                                    <div className="addSiteBtnDisplay" onClick={this.handleTriggerAddNewSiteBtnClick}><AddIcon />{this.props.language !== "eng" ? "新增分校" : "Create New Site"}</div>
                                                </div>
                                        }
                                    </div>
                                </div>
                                {
                                    this.props.loading ?
                                        <div className="loadingContainerRow">
                                            <div className="inlineDisplay">
                                                <div className="loadingContainer">
                                                    <CircularProgress id="loadingCircle" />
                                                    <div className="loadingLabel">Loading ...</div>
                                                </div>
                                            </div>
                                        </div>

                                        :
                                        // this.props.allSites.map((site: any) =>
                                        <div className="siteDisplayArea">
                                            <div className="siteTitle">{this.props.language !== "eng" ? "分校詳情" : "Site Details"}</div>
                                            <div className="siteInformationBox row">
                                                <div className="col-lg-12">
                                                    <div className="siteInfoRow1 row">
                                                        <div className="inlineDisplayNoMargin  col-lg-5 col-md-12 mb-4">
                                                            <div className="containerOnSitesMan">
                                                                <div className="inlineDisplay siteNameLabelOnSitesMan">{this.props.language !== "eng" ? "分校名稱" : "Site Name"} : </div>

                                                                {
                                                                    this.state.selectedSiteID !== displayingSiteDetails.id ?
                                                                        <div className="inlineDisplay detailsDisplayField"> {displayingSiteDetails.sites} </div>
                                                                        :
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedSiteDetails.sites} onChange={this.handleSiteNameInput} />

                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="inlineDisplayNoMargin col-lg-5 col-md-12 mb-2">
                                                            <div className="containerOnSitesMan">
                                                                <div className="inlineDisplay siteKeyLabelOnSitesMan">{this.props.language !== "eng" ? "分校簡稱" : "Site Key"} :</div>

                                                                {
                                                                    this.state.selectedSiteID !== displayingSiteDetails.id ?
                                                                        <div className="inlineDisplayNoMargin detailsDisplayField col-lg-9 col-md-8 col-sm-7"> {displayingSiteDetails.site_key} </div>
                                                                        :
                                                                        <TextField className=" inlineDisplayNoMargin detailsEditingInputs col-lg-9 col-md-8 col-sm-7" variant="outlined" value={this.state.selectedSiteDetails.site_key} onChange={this.handleSiteKeyInput} />

                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="editSiteBtn col-lg-2 col-md-12">
                                                            <div className="btnContainer">
                                                                {
                                                                    this.state.selectedSiteID !== displayingSiteDetails.id ?
                                                                        <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={() => this.handleEditSiteClick(displayingSiteDetails.id)} >{this.props.language !== "eng" ? "編緝分校" : "Edit Site"}</Button>
                                                                        :
                                                                        <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveSiteClick} >{this.props.language !== "eng" ? "儲存分校" : "Save Site"}</Button>

                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="siteInfoRow2 row">
                                                        <div className="openingTimeTitle col-12">{this.props.language !== "eng" ? "營運時間" : "Operation Hr"} : {this.state.selectedSiteID !== displayingSiteDetails.id ? null : <div className="inlineDisplay inputNote">{this.props.language !== "eng" ? " (示例 : 09:30-21:30) 最細單位為三十分鐘" : " (e.g. : 09:30 - 21:30) Accurate to 30 Min"}</div>}</div>
                                                        <div className="openingTimeBox col-lg-2 col-md-12 mb-4">
                                                            <div className="siteOpeningTimeLabelOnSitesMan">{this.props.language !== "eng" ? "星期一" : "Mon"}</div>
                                                            <div className="containerOnSitesMan">
                                                                {
                                                                    this.state.selectedSiteID !== displayingSiteDetails.id ?
                                                                        <div className="inlineDisplay detailsDisplayField w-100"> {displayingSiteDetails.office_hour_mon} </div>
                                                                        :
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedSiteDetails.office_hour_mon} onChange={this.handleMonOpeningTimeInput} />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="openingTimeBox col-lg-2 col-md-12 mb-4">
                                                            <div className="siteOpeningTimeLabelOnSitesMan">{this.props.language !== "eng" ? "星期二" : "Tue"}</div>
                                                            <div className="containerOnSitesMan">
                                                                {
                                                                    this.state.selectedSiteID !== displayingSiteDetails.id ?
                                                                        <div className="inlineDisplay detailsDisplayField w-100"> {displayingSiteDetails.office_hour_tue} </div>
                                                                        :
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedSiteDetails.office_hour_tue} onChange={this.handleTueOpeningTimeInput} />
                                                                }
                                                            </div>                                            </div>
                                                        <div className="openingTimeBox col-lg-2 col-md-12 mb-4">
                                                            <div className="siteOpeningTimeLabelOnSitesMan">{this.props.language !== "eng" ? "星期三" : "Wed"}</div>
                                                            <div className="containerOnSitesMan">
                                                                {
                                                                    this.state.selectedSiteID !== displayingSiteDetails.id ?
                                                                        <div className="inlineDisplay detailsDisplayField w-100"> {displayingSiteDetails.office_hour_wed} </div>
                                                                        :
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedSiteDetails.office_hour_wed} onChange={this.handleWedOpeningTimeInput} />
                                                                }
                                                            </div>                                            </div>
                                                        <div className="openingTimeBox col-lg-2 col-md-12 mb-4">
                                                            <div className="siteOpeningTimeLabelOnSitesMan">{this.props.language !== "eng" ? "星期四" : "Thu"}</div>
                                                            <div className="containerOnSitesMan">
                                                                {
                                                                    this.state.selectedSiteID !== displayingSiteDetails.id ?
                                                                        <div className="inlineDisplay detailsDisplayField w-100"> {displayingSiteDetails.office_hour_thu} </div>
                                                                        :
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedSiteDetails.office_hour_thu} onChange={this.handleThuOpeningTimeInput} />
                                                                }
                                                            </div>                                            </div>
                                                        <div className="openingTimeBox col-lg-2 col-md-12 mb-4">
                                                            <div className="siteOpeningTimeLabelOnSitesMan">{this.props.language !== "eng" ? "星期五" : "Fri"}</div>
                                                            <div className="containerOnSitesMan">
                                                                {
                                                                    this.state.selectedSiteID !== displayingSiteDetails.id ?
                                                                        <div className="inlineDisplay detailsDisplayField w-100"> {displayingSiteDetails.office_hour_fri} </div>
                                                                        :
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedSiteDetails.office_hour_fri} onChange={this.handleFriOpeningTimeInput} />
                                                                }
                                                            </div>                                            </div>
                                                        <div className="openingTimeBox col-lg-2 col-md-12 mb-4">
                                                            <div className="siteOpeningTimeLabelOnSitesMan">{this.props.language !== "eng" ? "星期六" : "Sat"}</div>
                                                            <div className="containerOnSitesMan">
                                                                {
                                                                    this.state.selectedSiteID !== displayingSiteDetails.id ?
                                                                        <div className="inlineDisplay detailsDisplayField w-100"> {displayingSiteDetails.office_hour_sat} </div>
                                                                        :
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedSiteDetails.office_hour_sat} onChange={this.handleSatOpeningTimeInput} />
                                                                }
                                                            </div>                                            </div>
                                                        <div className="openingTimeBox col-lg-2 col-md-12 mb-4">
                                                            <div className="siteOpeningTimeLabelOnSitesMan">{this.props.language !== "eng" ? "星期日" : "Sun"}</div>
                                                            <div className="containerOnSitesMan">
                                                                {
                                                                    this.state.selectedSiteID !== displayingSiteDetails.id ?
                                                                        <div className="inlineDisplay detailsDisplayField w-100"> {displayingSiteDetails.office_hour_sun} </div>
                                                                        :
                                                                        <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedSiteDetails.office_hour_sun} onChange={this.handleSunOpeningTimeInput} />
                                                                }
                                                            </div>                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="siteTitle">{this.props.language !== "eng" ? "分校課室詳情" : "Site Room Details"}</div>
                                            <div className="classroomsInformationBox row">
                                                {
                                                    this.props.allClassrooms.map((classroom: any) =>
                                                        classroom.site_id !== displayingSiteDetails.id ? null :
                                                            <div className="col-12">
                                                                <div className="classroomRow row">
                                                                    <div className="classroomNameBox col-lg-5 col-md-6 col-sm-12 mb-2">
                                                                        <div className="containerOnSitesMan row">
                                                                            <div className="inlineDisplayNoMargin col-lg-3 col-md-4 col-sm-5">{this.props.language !== "eng" ? "課室名稱" : "Room"} : </div>
                                                                            {
                                                                                this.state.selectedClassroomID !== classroom.id ?
                                                                                    <div className="inlineDisplayNoMargin detailsDisplayField col-lg-9 col-md-8 col-sm-7"> {classroom.classroom} </div>
                                                                                    :
                                                                                    <TextField className="detailsEditingInputs inlineDisplayNoMargin col-lg-9 col-md-8 col-sm-7" variant="outlined" value={this.state.selectedClassroomDetails.classroom} onChange={this.handleClassroomNameInput} />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="classroomCapacityBox col-lg-5 col-md-6 col-sm-12 mb-2">
                                                                        <div className="containerOnSitesMan row">

                                                                            <div className="inlineDisplayNoMargin col-lg-3 col-md-4 col-sm-5">{this.props.language !== "eng" ? "課室容量" : "Cap"} : </div>
                                                                            {
                                                                                this.state.selectedClassroomID !== classroom.id ?
                                                                                    <div className="inlineDisplayNoMargin detailsDisplayField col-lg-9 col-md-8 col-sm-7"> {classroom.capacity} </div>
                                                                                    :
                                                                                    <TextField className="detailsEditingInputs inlineDisplayNoMargin col-lg-9 col-md-8 col-sm-7" variant="outlined" value={this.state.selectedClassroomDetails.capacity} onChange={this.handleClassroomCapacityInput} />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="editClassroomBtn col-lg-2 col-md-12 col-sm-12 ">
                                                                        <div className="row">
                                                                        {
                                                                            this.state.selectedClassroomID !== classroom.id ?
                                                                                <div className="btnContainer col-6">
                                                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={() => this.handleEditClassroomClick(classroom.id)}>{this.props.language !== "eng" ? "修改" : "Edit"}</Button>
                                                                                    <Button id="clickToDelClassroomBtn" onClick={() => this.handleDelClassroomDetails(classroom.id)}>{this.props.language !== "eng" ? "刪除課室" : "Delete"}</Button>
                                                                                </div>
                                                                                :
                                                                                <div className="btnContainer col-6">
                                                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveClassroomDetails} >{this.props.language !== "eng" ? "儲存課室" : "Save"}</Button>
                                                                                </div>
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    )
                                                }
                                                {
                                                    !this.state.addNewClassroom ?
                                                        <div className="addClassroomRow">
                                                            <Button id="clickToAddNewClassroomBtn" onClick={() => this.handleAddNewClassroomDetails(displayingSiteDetails.id)}>{this.props.language !== "eng" ? "新增課室" : "Create Room"}</Button>
                                                        </div>
                                                        :
                                                        <div className="classroomRow">
                                                            <div className="classroomNameBox">
                                                                <div className="containerOnSitesMan">
                                                                    <div className="inlineDisplay">{this.props.language !== "eng" ? "課室名稱" : "Room"} : </div>
                                                                    <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedClassroomDetails.classroom} onChange={this.handleClassroomNameInput} />
                                                                </div>
                                                            </div>
                                                            <div className="classroomCapacityBox">
                                                                <div className="containerOnSitesMan">
                                                                    <div className="inlineDisplay">{this.props.language !== "eng" ? "課室容量" : "Cap"} : </div>
                                                                    <TextField className="detailsEditingInputs" variant="outlined" value={this.state.selectedClassroomDetails.capacity} onChange={this.handleClassroomCapacityInput} />
                                                                </div>
                                                            </div>
                                                            <div className="editClassroomBtn">
                                                                <div className="btnContainer">
                                                                    <Button id="clickToEditClassroomBtn" startIcon={<EditIcon />} onClick={this.handleSaveNewClassroomDetails} >{this.props.language !== "eng" ? "儲存課室" : "Save"}</Button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                }

                                            </div>

                                        </div>

                                }
                            </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        allSites: state.sitesClassroomsManage.allSites,
        allClassrooms: state.sitesClassroomsManage.allClassrooms,
        delClassroomFailed: state.sitesClassroomsManage.delClassroomFailed,
        loading: state.sitesClassroomsManage.loading,
        language: state.auth.language
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        createNewSiteDetails: async (siteDetails: any) => dispatch(await creteSiteDetails(siteDetails)),
        loadAllSitesClassrooms: async () => dispatch(await loadSitesClassroomsData()),
        updateClassroomDetails: async (classroomDetails: any) => dispatch(await updateClassroomDetails(classroomDetails)),
        createNewClassroomDetails: async (classroomDetails: any) => dispatch(await createClassroomDetails(classroomDetails)),
        delClassroomDetails: async (classroomID: number) => dispatch(await delClassroomDetails(classroomID)),
        exitFromNoticeWindow: async () => dispatch(await classroomDeleteFailedNoticed()),
        updateSiteDetails: async (siteDetails: any) => dispatch(await updateSiteDetails(siteDetails))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SitesClassroomsManage)


function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};
// let clientToken = localStorage.getItem('token');
// let tokenPayload: any = {}
// if (clientToken) {
//     tokenPayload = parseJwt(clientToken)
// }