import { IRootState } from './store';
import { RouteProps, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as React from 'react';
import { PropertyDescriptorParsingType } from 'html2canvas/dist/types/css/IPropertyDescriptor';

interface IPrivateRouteProps extends RouteProps {
    authenticated: boolean;
    // privateRole:{ admin: boolean } | null;
    // level: string | null
}

const PurePrivateRoute: React.FC<IPrivateRouteProps> = ({ component, authenticated, ...rest }: IPrivateRouteProps) => {
    const Component = component;
    if (Component == null) {
        return null;
    }
    let render: (props: any) => JSX.Element
    if (authenticated) {
        // if(privateRole && privateRole.admin === true && level === 'admin'){

        render = (props: any) => (
            <Component {...props} />
        )
        // } else {
        //     render = (props:any)=>(
        //         <Redirect to={ {
        //             pathname: '/',
        //             state: { from: props.location }
        //         } } />
        //     )
        // }
    } else {
        render = (props: any) => (
            <Redirect to={{
                pathname: '/',
                state: { from: props.location }
            }} />
        )
    }
    return <Route {...rest} render={render} />
};

export const PrivateRoute = connect((state: IRootState) => ({
    // authenticated: (localStorage.getItem('token')? true : false),
    authenticated: state.auth.authenticated,
    // level: state.auth.level


}))(PurePrivateRoute);