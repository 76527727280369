import React from 'react';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import RoomIcon from '@material-ui/icons/Room';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { Button, TextField } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


export interface IAddPackageComponentProps {
    language: string,
    uniquePackageNameCheckResult: any,
    allTokenUsages: any,
    allAgeGroups: any,
    allGenres: any,
    allTerms: any,
    allSites: any,
    confirmAddTokenPackage: (newPackageDetails: any) => Promise<void>,
    uniquePackageNameCheck: (packageName: string) => void

}

export interface IAddPackageComponentState {
    createPackageSteps: any[],
    activeStep: number
    packageType: any,
    addGenre: boolean,
    newPackageDetails: any
}

class AddPackageComponent extends React.Component<IAddPackageComponentProps, IAddPackageComponentState> {
    constructor(props: IAddPackageComponentProps) {
        super(props);
        this.state = {
            createPackageSteps: (this.props.language !== "eng" ? ['選擇套券種類', '輸入套券名稱', '輸入套券內容', '設定掛勾條款', '確認新套券詳情'] : ['Package Type', 'Package Name', 'Package Details', 'Package Terms', 'Confirm Package']),
            activeStep: 0,
            packageType: '',
            addGenre: false,
            newPackageDetails: {
                package_name: "",
                token_usage_id: 0,
                age_group_id: 0,         //CLASS
                genre_id: 0,             //CLASS
                token_amount: 1,            //CLASS PRIVATE RENTAL TUTOR
                validate_period: 30,        //CLASS PRIVATE RENTAL TUTOR
                effective_time: 0,       //PRIVATE RENTAL
                price: "0.00",                 //CLASS PRIVATE RENAL TUTOR
                site_id: 0,              //CLASS PRIVATE RENTAL TUTOR
                token_based_package: true,  //CLASS
                package_terms: null         //CLASS PRIVATE RENTAL TUTOR
            }

        }
    }
    private handleSelectUsageType = async (usageType: any) => {
        await this.setState({
            packageType: usageType,
            newPackageDetails: {
                ...this.state.newPackageDetails,
                token_usage_id: usageType.id
            }
        })
        if (usageType.usage === "Private Class Booking") {
            await this.setState({
                newPackageDetails: {
                    ...this.state.newPackageDetails,
                    effective_time: 0
                }
            })
        }
        if (usageType.usage === "Classroom Rental") {
            await this.setState({
                newPackageDetails: {
                    ...this.state.newPackageDetails,
                    effective_time: 0
                }
            })
        }
    }
    private handlePreviousStepClick = async () => {
        await this.setState({
            activeStep: this.state.activeStep - 1
        })
    }

    private handleNextStepClick = async () => {
        await this.setState({
            activeStep: this.state.activeStep + 1
        })
    }

    private handlePackageNameInput = async (event: any) => {
        await this.setState({
            newPackageDetails: {
                ...this.state.newPackageDetails,
                package_name: event.target.value
            }
        })
    }
    private handleAgeGroupInput = async (event: any) => {

        await this.setState({
            newPackageDetails: {
                ...this.state.newPackageDetails,
                age_group_id: event.target.value
            }
        })

    }
    private handleSiteInput = async (event: any) => {

        await this.setState({
            newPackageDetails: {
                ...this.state.newPackageDetails,
                site_id: event.target.value
            }
        })

    }
    private handleAddNewGenre = async () => {
        await this.setState({
            addGenre: true
        })
    }

    private handleGenreInput = async (event: any) => {
        if (this.state.newPackageDetails.genre_id !== 0) {
            let newGenreIDs = this.state.newPackageDetails.genre_id;
            newGenreIDs.push(event.target.value)
            await this.setState({
                addGenre: false,
                newPackageDetails: {
                    ...this.state.newPackageDetails,
                    genre_id: newGenreIDs
                }
            })
        } else {
            await this.setState({
                addGenre: false,
                newPackageDetails: {
                    ...this.state.newPackageDetails,
                    genre_id: [event.target.value]
                }
            })
        }
    }

    private handleDelGenre = async (genreID: number) => {
        let newGenreIDs = this.state.newPackageDetails.genre_id;
        newGenreIDs = await newGenreIDs.filter((genre: any) => genre !== genreID);

        if (newGenreIDs.length === 0) {
            await this.setState({
                newPackageDetails: {
                    ...this.state.newPackageDetails,
                    genre_id: 0
                }
            })
        } else {
            await this.setState({
                newPackageDetails: {
                    ...this.state.newPackageDetails,
                    genre_id: newGenreIDs
                }
            })
        }
    }
    private handleTokenAmountInput = async (event: any) => {
        let value = parseInt(`${event.target.value}`)
        if (value > 0) {
            await this.setState({
                newPackageDetails: {
                    ...this.state.newPackageDetails,
                    token_amount: value
                }
            })
        } else {
            await this.setState({
                newPackageDetails: {
                    ...this.state.newPackageDetails,
                    token_amount: 0
                }
            })
        }
    }
    private handleTokenBasedPackageChange = async (event: any) => {
        if (this.state.newPackageDetails.token_based_package) {
            await this.setState({
                newPackageDetails: {
                    ...this.state.newPackageDetails,
                    token_based_package: !this.state.newPackageDetails.token_based_package,
                    token_amount: 1
                }
            })
        } else {
            await this.setState({
                newPackageDetails: {
                    ...this.state.newPackageDetails,
                    token_based_package: !this.state.newPackageDetails.token_based_package,
                }
            })
        }

    }

    private handleEffectiveTimeInput = async (event: any) => {
        let value = parseInt(`${event.target.value}`)
        if (value > 0) {
            await this.setState({
                newPackageDetails: {
                    ...this.state.newPackageDetails,
                    effective_time: value
                }
            })
        } else {
            await this.setState({
                newPackageDetails: {
                    ...this.state.newPackageDetails,
                    effective_time: 0
                }
            })
        }
    }
    private handleValidatePeriodInput = async (event: any) => {
        let value = parseInt(`${event.target.value}`)
        if (value > 0) {
            await this.setState({
                newPackageDetails: {
                    ...this.state.newPackageDetails,
                    validate_period: value
                }
            })
        } else {
            await this.setState({
                newPackageDetails: {
                    ...this.state.newPackageDetails,
                    validate_period: 0
                }
            })
        }
    }
    private handlePriceInput = async (event: any) => {
        let value = parseFloat(`${event.target.value}`)
        if (value > 0) {
            await this.setState({
                newPackageDetails: {
                    ...this.state.newPackageDetails,
                    price: value.toFixed(2)
                }
            })
        } else {
            await this.setState({
                newPackageDetails: {
                    ...this.state.newPackageDetails,
                    price: "0.00"
                }
            })
        }
    }

    private handleTermsClick = async (termID: number) => {
        if (this.state.newPackageDetails.package_terms) {
            if (this.state.newPackageDetails.package_terms.includes(termID)) {
                let newTerms = await this.state.newPackageDetails.package_terms.filter((term: any) => term !== termID)
                if (newTerms.length !== 0) {
                    await this.setState({
                        newPackageDetails: {
                            ...this.state.newPackageDetails,
                            package_terms: newTerms
                        }
                    })
                } else {
                    await this.setState({
                        newPackageDetails: {
                            ...this.state.newPackageDetails,
                            package_terms: null
                        }
                    })
                }
            } else {
                let newTerms = this.state.newPackageDetails.package_terms
                newTerms.push(termID)
                await this.setState({
                    newPackageDetails: {
                        ...this.state.newPackageDetails,
                        package_terms: newTerms
                    }
                })
            }
        } else {
            await this.setState({
                newPackageDetails: {
                    ...this.state.newPackageDetails,
                    package_terms: [termID]
                }
            })
        }
    }
    private handleConfirmAddClick = async () => {
        let newPackageDetails = this.state.newPackageDetails
        if (newPackageDetails.age_group_id === 0) {
            newPackageDetails = {
                ...newPackageDetails,
                age_group_id: null
            }
        }
        if (newPackageDetails.effective_time === 0) {
            newPackageDetails = {
                ...newPackageDetails,
                effective_time: null
            }
        }
        if (newPackageDetails.genre_id === 0) {
            newPackageDetails = {
                ...newPackageDetails,
                genre_id: null
            }
        }
        if (newPackageDetails.site_id === 0) {
            newPackageDetails = {
                ...newPackageDetails,
                site_id: null
            }
        }
        this.props.confirmAddTokenPackage(newPackageDetails);
    }

    componentDidUpdate() {
        if (this.state.newPackageDetails.package_name !== this.props.uniquePackageNameCheckResult.packageName) {
            this.props.uniquePackageNameCheck(this.state.newPackageDetails.package_name);
        }
    }

    render() {
        return (
            <div className="systemMainDisplayArea row">
                <div className="systemManagePageTitle col-12"> <ConfirmationNumberIcon id="systemManPageTitleIcon" /> {this.props.language !== "eng" ? "套券管理" : "Packages Manage"}</div>
                <div className="newPackageTitleRow col-12"><div>{this.props.language !== "eng" ? "新增套券" : "Create Package"}</div></div>
                <div className="stepsIndicatorRow col-12">
                    <Stepper id="stepIconColor" activeStep={this.state.activeStep} alternativeLabel>
                        {this.state.createPackageSteps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                <div className="fixedHeightWindowOnNewPackageComponent col-12">
                    <div className="mainInputRow row">
                        {
                            this.state.activeStep === 0 ?
                                //FIRST STEP
                                // <div className="usageOptionContainer">
                                this.props.allTokenUsages.map((usage: any) =>
                                    usage.usage === 'Class Participation' ?
                                        this.state.packageType === usage ?
                                            <div className="col-lg-3  col-md-6 col-sm-12">
                                                <div className="contentBlockOfClassTokenOnListingPage">
                                                    <div className="aligner"><PeopleAltIcon id="listingPackageIcon" /></div>
                                                    <div className="tokenUsage">{this.props.language !== "eng" ? "課堂套券" : "Group Class"}</div>
                                                </div>
                                            </div>
                                            :
                                            <div className="col-lg-3  col-md-6 col-sm-12">
                                                <div className="usageBlockNotChosen" onClick={() => this.handleSelectUsageType(usage)}>
                                                    <div className="aligner"><PeopleAltIcon id="listingPackageIcon" /></div>
                                                    <div className="tokenUsage">{this.props.language !== "eng" ? "課堂套券" : "Group Class"}</div>
                                                </div>
                                            </div>
                                        :
                                        usage.usage === 'Private Class Booking' ?
                                            this.state.packageType === usage ?
                                                <div className="col-lg-3  col-md-6 col-sm-12">
                                                    <div className="contentBlockOfPrivateClassTokenOnListingPage">
                                                        <div className="aligner"><PersonIcon id="listingPackageIcon" /></div>
                                                        <div className="tokenUsage">{this.props.language !== "eng" ? "私人堂套券" : "Private Class"}</div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="col-lg-3  col-md-6 col-sm-12">
                                                    <div className="usageBlockNotChosen" onClick={() => this.handleSelectUsageType(usage)}>
                                                        <div className="aligner"><PersonIcon id="listingPackageIcon" /></div>
                                                        <div className="tokenUsage">{this.props.language !== "eng" ? "私人堂套券" : "Private Class"}</div>
                                                    </div>
                                                </div>
                                            :
                                            usage.usage === 'Classroom Rental' ?
                                                this.state.packageType === usage ?
                                                    <div className="col-lg-3  col-md-6 col-sm-12">
                                                        <div className="contentBlockOfRoomRentalTokenOnListingPage">
                                                            <div className="aligner"><RoomIcon id="listingPackageIcon" /></div>
                                                            <div className="tokenUsage">{this.props.language !== "eng" ? "場地套券" : "Room Rental"}</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-lg-3  col-md-6 col-sm-12">
                                                        <div className="usageBlockNotChosen" onClick={() => this.handleSelectUsageType(usage)}>
                                                            <div className="aligner"><RoomIcon id="listingPackageIcon" /></div>
                                                            <div className="tokenUsage">{this.props.language !== "eng" ? "場地套券" : "Room Rental"}</div>
                                                        </div>
                                                    </div>
                                                :
                                                usage.usage === 'Tutor Rental' ?
                                                    this.state.packageType === usage ?
                                                        <div className="col-lg-3  col-md-6 col-sm-12">
                                                            <div className="contentBlockOfTutorRentalTokenOnListingPage">
                                                                <div className="aligner"><AssignmentIndIcon id="listingPackageIcon" /></div>
                                                                <div className="tokenUsage">{this.props.language !== "eng" ? "導師套券" : "Tutor Hiring"}</div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="col-lg-3  col-md-6 col-sm-12">
                                                            <div className="usageBlockNotChosen" onClick={() => this.handleSelectUsageType(usage)}>
                                                                <div className="aligner"><AssignmentIndIcon id="listingPackageIcon" /></div>
                                                                <div className="tokenUsage">{this.props.language !== "eng" ? "導師套券" : "Tutor Hiring"}</div>
                                                            </div>
                                                        </div>
                                                    : null
                                )

                                // </div>
                                :
                                this.state.activeStep === 1 ?
                                    //SECOND STEP
                                    <div className="tokenNameInputContainer col-12">
                                        <div className="inputRowOnAddPackageComponent row">
                                            <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "套券名稱" : "Package"} :</div>
                                            <div className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-5">
                                                <TextField className="packageNameInput" variant="outlined" value={this.state.newPackageDetails.package_name} onChange={this.handlePackageNameInput} />
                                            </div>
                                            <div className="col-1">
                                                {
                                                    this.state.newPackageDetails.package_name !== "" && this.props.uniquePackageNameCheckResult.unique ?
                                                        <CheckCircleIcon id="newProductFormGoodIndicator" />
                                                        :
                                                        <CheckCircleIcon id="newProductFormBadIndicator" />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    this.state.activeStep === 2 ?
                                        //THIRD STEP
                                        <div className="tokenContentInputContainer col-12">
                                            {
                                                this.state.packageType.usage === 'Class Participation' ?
                                                    <div className="inputRowOnAddPackageComponent row">
                                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "適用年齡組別" : "Applicable Age"} :</div>
                                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">

                                                            <FormControl variant="outlined" className="packageNameInput">
                                                                <Select
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="demo-simple-select-outlined"
                                                                    value={this.state.newPackageDetails.age_group_id}
                                                                    onChange={this.handleAgeGroupInput}
                                                                >
                                                                    <MenuItem value={0}>{this.props.language !== "eng" ? "不限" : "Unlimited"}</MenuItem>
                                                                    {
                                                                        this.props.allAgeGroups.map((ageGroup: any) =>
                                                                            <MenuItem value={ageGroup.id}>{ageGroup.age_group}</MenuItem>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                this.state.packageType.usage === 'Class Participation' ?
                                                    <div className="inputRowOnAddPackageComponent row">
                                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "適用運動種類" : "Applicable Sport"} :</div>
                                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                            {
                                                                this.state.newPackageDetails.genre_id === 0 ?
                                                                    <div className="genreListingRow">
                                                                        <div className="suitableGenreDisplay">
                                                                            <div className="genreContainer">
                                                                                <AllInclusiveIcon /><div className="genreName">{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="suitableGenreDisplay" onClick={this.handleAddNewGenre}>
                                                                            <div className="genreContainer" >
                                                                                <AddIcon /><div className="genreName">{this.props.language !== "eng" ? "新增" : "Add"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="genreListingRow">
                                                                        {this.state.newPackageDetails.genre_id.map((genreID: number) =>
                                                                            <div className="suitableGenreDisplay" onClick={() => this.handleDelGenre(genreID)}>
                                                                                <div className="genreContainer">
                                                                                    <ClearIcon /><div className="genreName">{(this.props.allGenres.filter((genre: any) => genre.id === genreID))[0].genre}</div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        <div className="suitableGenreDisplay" onClick={this.handleAddNewGenre}>
                                                                            <div className="genreContainer" >
                                                                                <AddIcon /><div className="genreName">{this.props.language !== "eng" ? "新增" : "Add"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                this.state.packageType.usage === 'Class Participation' && this.state.addGenre ?

                                                    <div className="inputRowOnAddPackageComponent row">
                                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "新增運動種類" : "Add Sport Type"} :</div>
                                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">

                                                            <FormControl variant="outlined" className="packageNameInput">
                                                                <Select
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="demo-simple-select-outlined"
                                                                    onChange={this.handleGenreInput}
                                                                >
                                                                    {
                                                                        this.props.allGenres.map((genre: any) =>
                                                                            this.state.newPackageDetails.genre_id === 0 ?
                                                                                <MenuItem value={genre.id}>{genre.genre}</MenuItem>
                                                                                :
                                                                                this.state.newPackageDetails.genre_id.find((genreID: number) => genreID === genre.id) ?
                                                                                    null :
                                                                                    <MenuItem value={genre.id}>{genre.genre}</MenuItem>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                this.state.packageType.usage === 'Class Participation' ?
                                                    <div className="inputRowOnAddPackageComponent row">
                                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "扣數類型套券" : "Token Based Package"} :</div>
                                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">

                                                            {
                                                                this.props.language !== "eng" ?

                                                                    <FormControl variant="outlined" className="packageTokenBasedPackageInput">
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={this.state.newPackageDetails.token_based_package}
                                                                                    onChange={this.handleTokenBasedPackageChange}
                                                                                    name="tokenBasedPackage"
                                                                                    color="primary"
                                                                                />
                                                                            }

                                                                            label="扣數類型套券"
                                                                        />
                                                                    </FormControl>
                                                                    :
                                                                    <FormControl variant="outlined" className="packageTokenBasedPackageInput">
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={this.state.newPackageDetails.token_based_package}
                                                                                    onChange={this.handleTokenBasedPackageChange}
                                                                                    name="tokenBasedPackage"
                                                                                    color="primary"
                                                                                />
                                                                            }

                                                                            label="Token Based Package"
                                                                        />
                                                                    </FormControl>
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="inputRowOnAddPackageComponent row">
                                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "扣數類型套券" : "Token Based Package"} :</div>
                                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                            {
                                                                this.props.language !== "eng" ?
                                                                    <FormControl variant="outlined" className="packageTokenBasedPackageInput">
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={this.state.newPackageDetails.token_based_package}
                                                                                    onChange={this.handleTokenBasedPackageChange}
                                                                                    name="tokenBasedPackage"
                                                                                    color="primary"
                                                                                    disabled
                                                                                />
                                                                            }

                                                                            label="扣數類型套券"
                                                                        />
                                                                    </FormControl>
                                                                    :
                                                                    <FormControl variant="outlined" className="packageTokenBasedPackageInput">
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={this.state.newPackageDetails.token_based_package}
                                                                                    onChange={this.handleTokenBasedPackageChange}
                                                                                    name="tokenBasedPackage"
                                                                                    color="primary"
                                                                                    disabled
                                                                                />
                                                                            }

                                                                            label="Token Based Package"
                                                                        />
                                                                    </FormControl>
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                this.state.newPackageDetails.token_based_package ?
                                                    <div className="inputRowOnAddPackageComponent row">
                                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "內含套券數量" : "Token Included"} :</div>
                                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                            <FormControl variant="outlined" className="packageNameInput">
                                                                <TextField variant="outlined" value={this.state.newPackageDetails.token_amount} onChange={this.handleTokenAmountInput} />
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="inputRowOnAddPackageComponent row">
                                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "內含套券數量" : "Token Included"} :</div>
                                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                            <FormControl variant="outlined" className="packageNameInput">
                                                                <TextField variant="outlined" value={this.state.newPackageDetails.token_amount} onChange={this.handleTokenAmountInput} disabled />
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                this.state.packageType.usage === 'Private Class Booking' || this.state.packageType.usage === 'Classroom Rental' ?
                                                    <div className="inputRowOnAddPackageComponent row">
                                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "套券生效時長" : "Effective Duration"} :</div>
                                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                            <FormControl variant="outlined" className="packageNameInput">
                                                                {
                                                                    this.props.language !== "eng" ?
                                                                        this.state.newPackageDetails.effective_time === 0 || this.state.newPackageDetails.effective_time >= 45 && this.state.newPackageDetails.effective_time % 15 === 0 ?
                                                                            <TextField variant="outlined" type="number" helperText="1.準確至十五分鐘 2.最少45分鐘 (e.g 一個半小時 請輸入 -> 90)" value={this.state.newPackageDetails.effective_time} onChange={this.handleEffectiveTimeInput} placeholder="以分鐘作單位" />
                                                                            :
                                                                            <TextField variant="outlined" type="number" helperText="1.準確至十五分鐘 2.最少45分鐘 (e.g 一個半小時 請輸入 -> 90)" value={this.state.newPackageDetails.effective_time} onChange={this.handleEffectiveTimeInput} placeholder="以分鐘作單位" error />
                                                                        :
                                                                        this.state.newPackageDetails.effective_time === 0 || this.state.newPackageDetails.effective_time >= 45 && this.state.newPackageDetails.effective_time % 15 === 0 ?
                                                                            <TextField variant="outlined" type="number" helperText="1.Accurate to 15 Min. 2.Minimum 45 Min. (e.g 1.5Hr Please Enter -> 90)" value={this.state.newPackageDetails.effective_time} onChange={this.handleEffectiveTimeInput} placeholder="以分鐘作單位" />
                                                                            :
                                                                            <TextField variant="outlined" type="number" helperText="1.Accurate to 15 Min. 2.Minimum 45 Min. (e.g 1.5Hr Please Enter -> 90)" value={this.state.newPackageDetails.effective_time} onChange={this.handleEffectiveTimeInput} placeholder="以分鐘作單位" error />
                                                                }

                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "套券有效日數" : "Activate Duration"} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                        <FormControl variant="outlined" className="packageNameInput">
                                                            <TextField variant="outlined" value={`${this.state.newPackageDetails.validate_period} `} onChange={this.handleValidatePeriodInput} />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "設定套券價格" : "Package Price"} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                        <FormControl variant="outlined" className="packageNameInput">
                                                            <TextField variant="outlined" value={`${this.state.newPackageDetails.price}`} onChange={this.handlePriceInput} />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                <div className="inputRowOnAddPackageComponent row">
                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "設定分校限制" : "Site Limitation"} :</div>
                                                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                        <FormControl variant="outlined" className="packageNameInput">
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                value={this.state.newPackageDetails.site_id}
                                                                onChange={this.handleSiteInput}
                                                            >
                                                                <MenuItem value={0}>{this.props.language !== "eng" ? "不限" : "Unlimited"}</MenuItem>
                                                                {
                                                                    this.props.allSites.map((site: any) =>
                                                                        <MenuItem value={site.id}>{site.site_key}</MenuItem>
                                                                    )
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        :
                                        this.state.activeStep === 3 ?
                                            <div className="tokenTermsInputContainer">
                                                {
                                                    this.props.allTerms.map((term: any) =>
                                                        term.active && !term.base_terms ?
                                                            this.state.newPackageDetails.package_terms && this.state.newPackageDetails.package_terms.includes(term.id) ?
                                                                <div className="inputRowOnAddPackageComponent row" onClick={() => this.handleTermsClick(term.id)}>
                                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "條款" : "Terms"} :</div>
                                                                    <div className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-5">
                                                                        <div className="inlineDisplay selectedTermDetailsDisplayFieldOnAddPackageComponent"> {term.content} </div>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <CheckIcon id="displaySelectedTermIndicator" />
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="inputRowOnAddPackageComponent row" onClick={() => this.handleTermsClick(term.id)}>
                                                                    <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "條款" : "Terms"} :</div>
                                                                    <div className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-5">
                                                                        <div className="inlineDisplay termDetailsDisplayFieldOnAddPackageComponent"> {term.content} </div>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <CheckIcon id="hiddenSelectedTermIndicator" />
                                                                    </div>
                                                                </div>
                                                            : null
                                                    )
                                                }
                                            </div>
                                            :
                                            this.state.activeStep === 4 ?
                                                <div className="tokenTermsInputContainer">
                                                    <div className="inputRowOnAddPackageComponent row">
                                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "暫定套券種類" : "Package Type"} :</div>
                                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                            <div className="packageContentDisplayOnAddPackageComponent" > {this.state.packageType.usage === 'Class Participation' ? this.props.language !== "eng" ? "課堂套券" : "Group Class Package" : this.state.packageType.usage === 'Private Class Booking' ? this.props.language !== "eng" ? "私人堂套券" : "Private Class Package" : this.state.packageType.usage === 'Classroom Rental' ? this.props.language !== "eng" ? "場地套券" : "Room Rental Package" : this.state.packageType.usage === "Tutor Rental" ? "導稱套券" : null}  </div>
                                                        </div>
                                                    </div>
                                                    <div className="inputRowOnAddPackageComponent row">
                                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "暫定套券名稱" : "Package Name"} :</div>
                                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                            <div className="packageContentDisplayOnAddPackageComponent" > {this.state.newPackageDetails.package_name}  </div>
                                                        </div>
                                                    </div>
                                                    {this.state.packageType.usage === 'Class Participation' ?
                                                        <div className="inputRowOnAddPackageComponent row">
                                                            <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "適用年齡組別" : "Applicable Age"} :</div>
                                                            <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                            <div className="packageContentDisplayOnAddPackageComponent" > {
                                                                this.state.newPackageDetails.age_group_id === 0 ? this.props.language !== "eng" ? "不限" : "Unlimited" :
                                                                    (this.props.allAgeGroups.filter((ageGroup: any) => ageGroup.id === this.state.newPackageDetails.age_group_id))[0].age_group
                                                            }  </div>
                                                            </div>
                                                        </div>
                                                        : null}
                                                    {this.state.packageType.usage === 'Class Participation' ?
                                                        <div className="inputRowOnAddPackageComponent row">
                                                            <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "適用運動種類" : "Applicable Sport"} :</div>
                                                            {
                                                                this.state.newPackageDetails.genre_id === 0 ?
                                                                    <div className="genreListingRow">
                                                                        <div className="suitableGenreDisplay">
                                                                            <div className="genreContainer">
                                                                                <AllInclusiveIcon /><div className="genreName">{this.props.language !== "eng" ? "不限" : "Unlimited"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="genreListingRow">
                                                                        {this.state.newPackageDetails.genre_id.map((genreID: number) =>
                                                                            <div className="suitableGenreDisplay" onClick={() => this.handleDelGenre(genreID)}>
                                                                                <div className="genreContainer">
                                                                                    <div className="genreName">{(this.props.allGenres.filter((genre: any) => genre.id === genreID))[0].genre}</div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                            }
                                                        </div>
                                                        : null}
                                                    {
                                                        <div className="inputRowOnAddPackageComponent row">
                                                            <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "扣數類型套券" : "Token Based Package"} :</div>
                                                            {
                                                                this.props.language !== "eng" ?

                                                                    <FormControl variant="outlined" className="packageTokenBasedPackageInput">
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={this.state.newPackageDetails.token_based_package}
                                                                                    onChange={this.handleTokenBasedPackageChange}
                                                                                    name="tokenBasedPackage"
                                                                                    color="primary"
                                                                                    disabled
                                                                                />
                                                                            }

                                                                            label="扣數類型套券"
                                                                        />
                                                                    </FormControl>
                                                                    :
                                                                    <FormControl variant="outlined" className="packageTokenBasedPackageInput">
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch
                                                                                    checked={this.state.newPackageDetails.token_based_package}
                                                                                    onChange={this.handleTokenBasedPackageChange}
                                                                                    name="tokenBasedPackage"
                                                                                    color="primary"
                                                                                    disabled
                                                                                />
                                                                            }

                                                                            label="Token Based Package"
                                                                        />
                                                                    </FormControl>
                                                            }
                                                        </div>

                                                    }
                                                    <div className="inputRowOnAddPackageComponent row">
                                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "內容套券數量" : "Package Token"} :</div>
                                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                            <div className="packageContentDisplayOnAddPackageComponent" > {this.state.newPackageDetails.token_amount} {this.props.language !== "eng" ? "張" : ""} </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.packageType.usage === "Classroom Rental" || this.state.packageType.usage === "Private Class Booking" ?
                                                            <div className="inputRowOnAddPackageComponent row">
                                                                <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "套券生效時長" : "Effective Duration"} :</div>
                                                                <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                                    <div className="packageContentDisplayOnAddPackageComponent" > {this.state.newPackageDetails.effective_time} {this.props.language !== "eng" ? "分鐘" : "Min"} </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    <div className="inputRowOnAddPackageComponent row">
                                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "套券有效日數" : "Activate Duration"} :</div>
                                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                            <div className="packageContentDisplayOnAddPackageComponent" > {this.state.newPackageDetails.validate_period} {this.props.language !== "eng" ? "日" : "Days"} </div>
                                                        </div>
                                                    </div>
                                                    <div className="inputRowOnAddPackageComponent row">
                                                        <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "暫定套券價格" : "Package Price"} :</div>
                                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                            <div className="packageContentDisplayOnAddPackageComponent" > $ {this.state.newPackageDetails.price} </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        <div className="inputRowOnAddPackageComponent row">
                                                            <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "設定分校限制" : "Site Limitation"} :</div>
                                                            <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                            <div className="packageContentDisplayOnAddPackageComponent" > {
                                                                this.state.newPackageDetails.site_id === 0 ? this.props.language !== "eng" ? "不限" : "Unlimited" :
                                                                    (this.props.allSites.filter((site: any) => site.id === this.state.newPackageDetails.site_id))[0].site_key
                                                            }  </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.newPackageDetails.package_terms ?
                                                            <div className="inputRowOnAddPackageComponent row">
                                                                <div className="tokenNameLabel col-xl-2 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 offset-md-1  col-sm-5 offset-sm-1 col-xs-5 offset-xs-1">{this.props.language !== "eng" ? "暫定掛勾條款" : "Package Terms"} :</div>
                                                                <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6 col-xs-6">
                                                                <div className="packageContentDisplayOnAddPackageComponent">
                                                                    {this.state.newPackageDetails.package_terms.map((termID: any) =>
                                                                        <div className="termIndividualContainer">
                                                                            - {(this.props.allTerms.filter((term: any) => term.id === termID))[0].content}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                :
                                                null

                        }
                    </div>
                </div>
                <div className="modifyStepsRow">
                    {this.state.activeStep === 0 ? <Button id="stepButtonNoShow" disabled>{this.props.language !== "eng" ? "上一步" : "Back"}</Button> : <Button id="stepButton" onClick={this.handlePreviousStepClick}>{this.props.language !== "eng" ? "上一步" : "Back"}</Button>}
                    {this.state.activeStep === 0 && this.state.packageType !== '' ?
                        <Button id="stepButton" onClick={this.handleNextStepClick}>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>
                        :
                        this.state.activeStep === 1 && this.state.newPackageDetails.package_name !== '' && this.props.uniquePackageNameCheckResult.unique ?
                            <Button id="stepButton" onClick={this.handleNextStepClick}>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>
                            :
                            this.state.activeStep === 2 && (this.state.newPackageDetails.token_usage_id === 3 ? this.state.newPackageDetails.effective_time !== 0 && this.state.newPackageDetails.effective_time % 15 === 0 && this.state.newPackageDetails.effective_time >= 45 : true) && (this.state.newPackageDetails.token_usage_id === 2 ? this.state.newPackageDetails.effective_time !== 0 && this.state.newPackageDetails.effective_time % 15 === 0 && this.state.newPackageDetails.effective_time >= 45 : true) ?
                                <Button id="stepButton" onClick={this.handleNextStepClick}>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>
                                :
                                this.state.activeStep === 3 ?
                                    <Button id="stepButton" onClick={this.handleNextStepClick}>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>
                                    :
                                    this.state.activeStep === 4 ?
                                        <Button id="stepButton" onClick={this.handleConfirmAddClick}>{this.props.language !== "eng" ? "確定新增套券" : "Create New Package"}</Button>
                                        :
                                        <Button id="stepButtonDisabled" onClick={this.handleNextStepClick} disabled>{this.props.language !== "eng" ? "下一步" : "Next"}</Button>


                    }
                </div>
            </div>
        )
    }
}

export default AddPackageComponent