

export function allGroupingLoaded(ageGroups:any, genres:any) {
    return {
        type: "ALL_GROUPINGS_LOADED" as "ALL_GROUPINGS_LOADED",
        ageGroups,
        genres
    }
}
export function ageGroupCreated () {
    return {
        type: "AGE_GROUP_CREATED" as "AGE_GROUP_CREATED"
    }
}
export function ageGroupUpdated () {
    return {
        type:"AGE_GROUP_UPDATED" as "AGE_GROUP_UPDATED"
    }
}
export function ageGroupActiveSettingModified () {
    return {
        type: "AGE_GROUP_ACTIVE_SETTING_MODIFIED" as "AGE_GROUP_ACTIVE_SETTING_MODIFIED"
    }
}
export function genreCreated () {
    return {
        type: "GENRE_CREATED" as "GENRE_CREATED"
    }
}
export function genreUpdated () {
    return {
        type:"GENRE_UPDATED" as "GENRE_UPDATED"
    }
}
export function genreActiveSettingModified () {
    return {
        type: "GENRE_ACTIVE_SETTING_MODIFIED" as "GENRE_ACTIVE_SETTING_MODIFIED"
    }
}


type GroupingManageActionsCreators = typeof allGroupingLoaded |
                                    typeof ageGroupCreated |
                                    typeof ageGroupUpdated |
                                    typeof ageGroupActiveSettingModified |
                                    typeof genreCreated |
                                    typeof genreUpdated |
                                    typeof genreActiveSettingModified;
                                            

export type IGroupingManageActions = ReturnType<GroupingManageActionsCreators>