import { ThunkDispatch } from '../../store';
import { allStockAttributeLoaded , stockAttributeCreated, stockAttributeUpdated, stockAttributeActiveSettingModified} from './actions';

export async function loadStockAttributeData() {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/stockManage/loadAllStockAttributes`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(allStockAttributeLoaded(result.allStockAttributes));
        }
    }
}
export async function createStockAttribute(stockAttributeDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/stockManage/createNewStockAttribute`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            stockAttributeDetails: stockAttributeDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(stockAttributeCreated());
        }
    }
}
export async function updateStockAttribute(stockAttributeDetails:any) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/stockManage/updateStockAttributeDetails`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            stockAttributeDetails: stockAttributeDetails
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(stockAttributeUpdated());
        }
    }
}
export async function delStockAttribute(stockAttributeID:number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/stockManage/modifyStockAttributeActiveSetting`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
            stockAttributeID: stockAttributeID
        })
    });
    let result = await res.json();
    if (res.status === 500) {
        return async (dispatch: ThunkDispatch) => {
            console.log(result);
        }
    } else {
        return async (dispatch: ThunkDispatch) => {
            dispatch(stockAttributeActiveSettingModified());
        }
    }
}
