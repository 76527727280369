import { IStockAttributeManageState } from './state';
import { IStockAttributeManageActions } from './actions';

const initialState = {
    allStockAttributes: [],
    loading: true
}

export const stockAttributeManageReducer = (state: IStockAttributeManageState = initialState, actions: IStockAttributeManageActions) => {
    switch (actions.type) {

        case "ALL_STOCK_ATTRIBUTES_LOADED":
            return {
                ...state,
                loading: false,
                allStockAttributes: actions.stockAttributes
            }
        case "STOCK_ATTRIBUTE_CREATED":
            return {
                ...state,
                loading: true

            }
        case "STOCK_ATTRIBUTE_UPDATED":
            return {
                ...state,
                loading: true

            }
        case "STOCK_ATTRIBUTE_ACTIVE_SETTING_MODIFIED":
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }
}