

export function allSitesClassroomsLoaded(sites:any, classrooms:any) {
    return {
        type: "ALL_SITES_CLASSROOMS_LOADED" as "ALL_SITES_CLASSROOMS_LOADED",
        sites,
        classrooms
    }
}
export function classroomUpdated() {
    return {
        type:"CLASSROOM_UPDATED" as "CLASSROOM_UPDATED"
    }
}
export function classroomCreated() {
    return {
        type: "CLASSROOM_CREATED" as "CLASSROOM_CREATED"
    }
}
export function classroomDeleted() {
    return {
        type: "CLASSROOM_DELETED" as "CLASSROOM_DELETED"
    }
}
export function classroomDeleteFailed () {
    return {
        type: "CLASSROOM_DELETE_FAILED" as "CLASSROOM_DELETE_FAILED"
    }
}
export function classroomDeleteFailedNoticed () {
    return {
        type: "CLASSROOM_DELETE_FAILED_NOTICED" as "CLASSROOM_DELETE_FAILED_NOTICED"
    }
}
export function siteUpdated () {
    return {
        type: "SITE_UPDATED" as "SITE_UPDATED"
    }
}

export function siteCreated() {
    return {
        type: "SITE_CREATED" as "SITE_CREATED"
    }
}

type SitesClassroomsManageActionsCreators = typeof allSitesClassroomsLoaded |
                                            typeof classroomUpdated |
                                            typeof classroomCreated |
                                            typeof classroomDeleted |
                                            typeof classroomDeleteFailed |
                                            typeof classroomDeleteFailedNoticed |
                                            typeof siteUpdated |
                                            typeof siteCreated;

export type ISitesClassroomsManageActions = ReturnType<SitesClassroomsManageActionsCreators>