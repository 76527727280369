import { ThunkDispatch } from "../../store";

export async function tickAttnUpdateClassParticipationDetails (participationArr: any[],staffUserID: number) {
    const res = await fetch(`${process.env.REACT_APP_API_SERVER}/customerParticipation/updateAttendanceRecord`, {
        method: 'PUT',
        headers: {
            "Content-Type":"application/json",
            Authorization:`Bearer ${localStorage.getItem('token')}`,

        },
        body: JSON.stringify({
            participationArr: participationArr,
            staffUserID : staffUserID
        })
    });

    let result = await res.json();
    if(res.status === 500){
        return async (dispatch:ThunkDispatch) => {
            console.log(result);
        }
    } else {
        
        return async (dispatch:ThunkDispatch) => {
            console.log(result)
        }
    }
}