import React from "react";
import './AttendanceWindow.css'
import { Button } from "@material-ui/core";
import { IRootState, ThunkDispatch } from "../../store";
import { connect } from "react-redux";
import CloseIcon from '@material-ui/icons/Close';
import { tickAttnUpdateClassParticipationDetails } from './thunks';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import moment from "moment";


export interface IAttendanceWindowProps {
    language: string,
    classParticipantsDetails: any[],
    customerBookingCount: number,
    customerInfos: any[],
    relatedPackageInfo: any[]
    handleCloseClick: () => void,
    handleExitClick: () => void,
    handleSubmitNewParticipationDetails: (participationArr: any[], staffUserID: number) => void
}

export interface IAttendanceWindowState {
    classParticipantsDetails: any[],

}

class IAttendanceWindow extends React.Component<IAttendanceWindowProps, IAttendanceWindowState> {
    constructor(props: IAttendanceWindowProps) {
        super(props);
        this.state = {
            classParticipantsDetails: this.props.classParticipantsDetails,

        }
    }

    private handleCloseClick = async () => {
        await this.setState({
            classParticipantsDetails: [],

        })
        this.props.handleCloseClick();
    }


    private handleSubmitAttnParticipationDetails = async () => {

        //GET STAFF ID FROM TOKEN

        let clientToken = localStorage.getItem('token');
        let tokenPayload: any = {}
        if (clientToken) {
            tokenPayload = parseJwt(clientToken)
            console.log(tokenPayload)
        }
        await this.props.handleSubmitNewParticipationDetails(this.state.classParticipantsDetails, tokenPayload.id);
        await this.props.handleCloseClick();
        await this.props.handleExitClick();
    }

    private handleAttendanceChange = async (customerID: number) => {
        let newClassParticipationDetails = this.state.classParticipantsDetails;
        for (let i = 0; i < this.state.classParticipantsDetails.length; i++) {
            if (newClassParticipationDetails[i].customer_id === customerID) {
                let targetParticipationDetail = newClassParticipationDetails[i];
                if (targetParticipationDetail.absent === null) {
                    targetParticipationDetail.absent = true
                } else {
                    if (targetParticipationDetail.absent) {
                        targetParticipationDetail.absent = false
                    } else {
                        targetParticipationDetail.absent = null
                    }
                }

                newClassParticipationDetails.splice(i, 1, targetParticipationDetail);
                await this.setState({
                    classParticipantsDetails: newClassParticipationDetails
                })
                break;
            }
        }
    }

    render() {
        let presentHeadCount = this.state.classParticipantsDetails.filter((participation: any) => participation.absent === false)

        return (
            <div className="baseAreaOfAddNewBookingWindow">
                <div className="navDisplayRow">
                    <h3 className="addNewBookingWindowTitle">{this.props.language !== "eng" ? "課堂點名 - 出席人數 :" : "Mark Attendance :"}{presentHeadCount.length} / {this.props.customerBookingCount}</h3>
                    <CloseIcon id="addNewBookingWindowCloseBtn" onClick={this.handleCloseClick} />
                </div>

                <div className="fixedHeightScrollingAreaOnAddNewBookingWindow">

                    {this.state.classParticipantsDetails.map((participant: any) =>
                        participant.absent_reason_id ?
                            null :
                            participant.absent === null ?
                                <div className="customerRow3OnAttendancePage" onClick={() => this.handleAttendanceChange(participant.customer_id)}>
                                    <div className="previewContentBlockAligner">
                                        <PeopleAltIcon id="previewPackageIcon" />
                                        <div className="customerNumOnAttnPage"># {(this.props.customerInfos.find((customer: any) => customer.id === participant.customer_id)).id}</div>
                                        <div className="customerNameOnAttnPage">{(this.props.customerInfos.find((customer: any) => customer.id === participant.customer_id)).name_eng}</div>
                                        <div className="customerAttnContent">
                                            <div className="customerAttnContentAligner">
                                                {
                                                    participant.no_token_booking ?
                                                        <div className="inlineElement"> <div>{this.props.language !== "eng" ? "使用套券：無套劵預約" : "Package : Pre-Booking"}</div> </div>
                                                        :
                                                        <div className="inlineElement">{(this.props.relatedPackageInfo.find((relatedPackage: any) => relatedPackage.id === participant.customer_package_id)).package_name}</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="presentIndicator">
                                            <div className="inlineElement">{this.props.language !== "eng" ? "未點名" : "-"}</div>
                                        </div>
                                        {/* <div className="previewPackagePrice">HKD$ {packagesOnList.price}</div> */}
                                    </div>
                                </div>
                                :
                                participant.absent ?
                                    <div className="customerRow2OnAttendancePage" onClick={() => this.handleAttendanceChange(participant.customer_id)}>
                                        <div className="previewContentBlockAligner">
                                            <PeopleAltIcon id="previewPackageIcon" />
                                            <div className="customerNumOnAttnPage"># {(this.props.customerInfos.find((customer: any) => customer.id === participant.customer_id)).id}</div>
                                            <div className="customerNameOnAttnPage">{(this.props.customerInfos.find((customer: any) => customer.id === participant.customer_id)).name_eng}</div>
                                            <div className="customerAttnContent">
                                                <div className="customerAttnContentAligner">
                                                    {
                                                        participant.no_token_booking ?
                                                            <div className="inlineElement"> <div>{this.props.language !== "eng" ? "使用套券：無套劵預約" : "Package : Pre-Booking"}</div> </div>
                                                            :
                                                            <div className="inlineElement">{(this.props.relatedPackageInfo.find((relatedPackage: any) => relatedPackage.id === participant.customer_package_id)).package_name}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="presentIndicator">
                                                <div className="inlineElement">{this.props.language !== "eng" ? "缺席" : "Absent"}</div>
                                            </div>
                                            {/* <div className="previewPackagePrice">HKD$ {packagesOnList.price}</div> */}
                                        </div>
                                    </div>
                                    :
                                    <div className="customerRow1OnAttendancePage" onClick={() => this.handleAttendanceChange(participant.customer_id)}>
                                        <div className="previewContentBlockAligner">
                                            <PeopleAltIcon id="previewPackageIcon" />
                                            <div className="customerNumOnAttnPage"># {(this.props.customerInfos.find((customer: any) => customer.id === participant.customer_id)).id}</div>
                                            <div className="customerNameOnAttnPage">{(this.props.customerInfos.find((customer: any) => customer.id === participant.customer_id)).name_eng}</div>
                                            <div className="customerAttnContent">
                                                <div className="customerAttnContentAligner">
                                                    {
                                                        participant.no_token_booking ?
                                                            <div className="inlineElement"> <div>{this.props.language !== "eng" ? "使用套券：無套劵預約" : "Package : Pre-Booking"}</div> </div>
                                                            :
                                                            <div className="inlineElement">{(this.props.relatedPackageInfo.find((relatedPackage: any) => relatedPackage.id === participant.customer_package_id)).package_name}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="presentIndicator">
                                                <div className="inlineElement">{this.props.language !== "eng" ? "出席" : "Present"}</div>
                                            </div>
                                            {/* <div className="previewPackagePrice">HKD$ {packagesOnList.price}</div> */}
                                        </div>
                                    </div>
                    )}


                </div>
                <div className="confirmBtnRow">
                    <Button id="confirmAddBookingBtn" onClick={this.handleSubmitAttnParticipationDetails}>{this.props.language !== "eng" ? "完成點名操作" : "Confirm Attendance"}</Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IRootState) => {
    return {
        language: state.auth.language
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
    return {
        handleSubmitNewParticipationDetails: async (participationArr: any[], staffUserID: number) => dispatch(await tickAttnUpdateClassParticipationDetails(participationArr, staffUserID))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IAttendanceWindow);

function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};